import React, { useEffect, useState } from 'react';
import { Avatar, Box, Divider, Hidden, HStack, Icon, Input, Pressable, ScrollView, Text, VStack, FlatList } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import { RootStackScreensEnum } from '../../navigation/enum';
import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { AdminStackParamList } from '../../types/Stacks';
import { useAppSelector } from '../../store/hooks';
import { selectAllPatients } from '../../store/hospital/patients/patients-slice';
import { useDebounce } from '../../utils/debounce';
import { selectAllUsers } from '../../store/hospital/users/users-slice';
import { IUser } from '../../types/IUser';
import { IPatient } from '../../types/IPatient';

interface IMyComponentProps {
  data: IPatient[];
  patientIdParam: string;
}

const MyComponent = ({ data, patientIdParam }: IMyComponentProps) => {
  const navigation = useNavigation<NativeStackNavigationProp<AdminStackParamList, RootStackScreensEnum.PatientList>>();
  const users = useAppSelector((state) => selectAllUsers(state.hospital.users));
  return (
    <Box backgroundColor={'white'} borderRadius={'xl'}>
      <FlatList
        data={data}
        renderItem={({ item }: { item: IPatient }) => {
          const patientUser: IUser | undefined = users.find((user: IUser) => user.id === item.patientUserId);
          return (
            <Pressable
              style={{
                borderRadius: 4,
                backgroundColor: patientIdParam === item.id ? '#DBEAFE' : 'white'
              }}
              onPress={() => navigation.navigate(RootStackScreensEnum.MedicalRecordScreen, { patientId: item.id })}
              flex={1}
              key={item.id.toString()}>
              <HStack
                px={6}
                py={3}
                space={2}
                borderBottomWidth={1}
                alignItems='center'
                _light={{ borderBottomColor: 'coolGray.200' }}
                _dark={{ borderBottomColor: 'coolGray.700' }}>
                <Avatar source={patientUser?.imageUrl ? { uri: patientUser?.imageUrl } : undefined} height={10} width={10}>
                  {patientUser?.lastName.charAt(0).toUpperCase()}
                </Avatar>
                <Text ml={2} fontWeight='medium' fontSize='md' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }}>
                  {patientUser?.firstName + ' ' + patientUser?.lastName}
                </Text>
              </HStack>
            </Pressable>
          );
        }}
        keyExtractor={(item: IPatient) => item.id.toString()}
      />
    </Box>
  );
};
const MedicalRecordList = () => {
  const patients = useAppSelector((state) => selectAllPatients(state.hospital.patients));
  const users = useAppSelector((state) => selectAllUsers(state.hospital.users));
  const route = useRoute();
  const [searchText, setSearchText] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<IPatient[]>([]);
  const textSearch = useDebounce(searchText, 1000);
  const [patientId, setPatientId] = useState('');
  const navigation = useNavigation<any>();

  useEffect(() => {
    const patientIdParam = route.params as { patientId: string };
    if (patientIdParam?.patientId) {
      setPatientId(patientIdParam.patientId);
    } else {
      navigation.navigate(RootStackScreensEnum.MedicalRecordScreen, { patientId: searchResults[0]?.id });
    }
  }, [route.params, searchResults.length, patients.length]);

  const onSearchTextChange = (text: string) => {
    setSearchText(text);
  };

  useEffect(() => {
    const searchValue = searchText.toLowerCase();
    const arr = patients.filter((item) => {
      // Find the user by firstName + lastName
      const foundNameUsers = users.filter((user: IUser) => {
        const fullName = user.firstName + ' ' + user.lastName;
        return fullName.toLowerCase().includes(searchValue);
      });

      // Find the user by email
      const foundEmailUsers = users.filter((user: IUser) => {
        return user.email.toLowerCase().includes(searchValue);
      });

      // Return the list of patients that has patientUserId in the list of found users
      return foundNameUsers.some((user) => user.id === item.patientUserId) || foundEmailUsers.some((user) => user.id === item.patientUserId);
    });
    setSearchResults(arr);
  }, [textSearch]);
  return (
    <VStack mt={4} flex={1} rounded={{ md: 'sm' }}>
      <ContactData searchText={searchText} onSearchTextChange={onSearchTextChange} />
      <ScrollView showsHorizontalScrollIndicator={false}>
        <MyComponent patientIdParam={patientId} data={searchResults || []} />
      </ScrollView>
    </VStack>
  );
};
export default MedicalRecordList;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const ContactData = ({ onSearchTextChange = () => {}, searchText }: any) => {
  return (
    <>
      <Text ml={3} mb={2} fontSize={'2xl'} fontWeight={'bold'} color='#4EA0F7'>
        Select Patient
      </Text>
      <Hidden till='md'>
        <VStack space='4'>
          <HStack flexWrap={'wrap'} flex={1} justifyContent='space-between'>
            <Input
              rounded={'2xl'}
              w={'90%'}
              value={searchText}
              // ref={searchTextRef}
              onChangeText={onSearchTextChange}
              // onChangeText={(value) => (searchTextRef.current.value = value)}
              mx={3}
              py={2}
              pl={3}
              _light={{
                bg: 'white',
                borderColor: 'coolGray.300'
              }}
              _dark={{
                bg: 'coolGray.800',
                borderColor: 'coolGray.500'
              }}
              size='md'
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name='search' />}
                  size={6}
                  ml={3}
                  _light={{
                    color: 'coolGray.400'
                  }}
                  _dark={{
                    color: 'coolGray.300'
                  }}
                />
              }
              placeholder='Search'
            />
          </HStack>
          <VStack
            _light={{
              bg: 'white'
            }}
            _dark={{
              bg: 'coolGray.800'
            }}
            divider={<Divider />}
            borderRadius={{ md: 'sm' }}></VStack>
        </VStack>
      </Hidden>
    </>
  );
};
