import { AuthStackParamList } from '../types/Stacks';
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackScreensEnum } from './enum';
import SignUp from '../screens/AuthScreen/SignUp';
import SignIn from '../screens/AuthScreen/SignIn';
import OtpVerification from '../screens/AuthScreen/OtpVerification';
import ForgotPassword from '../screens/AuthScreen/ForgotPassword';
import ResetPassword from '../screens/AuthScreen/ResetPassword';
import React, { useEffect } from 'react';
import { useAuth } from '../components/auth/AmplifyAuthProvider';
import { RootStackParamList } from './types';
import SignUpDoctor from '../screens/AuthScreen/SignUpDoctor';
import Roles from '../components/auth/role';

const AuthStack = createNativeStackNavigator<AuthStackParamList>();
type IAuthStackScreenProps = NativeStackScreenProps<RootStackParamList, RootStackScreensEnum.Auth>;

const AuthStackScreen = ({ navigation }: IAuthStackScreenProps) => {
  const { isSignedIn, checkRole } = useAuth();

  // Navigate to MainStack if user is signed in
  useEffect(() => {
    (async () => {
      const role = await checkRole();
      if (isSignedIn) {
        if (role === Roles.Admin) {
          navigation.replace(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.Admin, params: { screen: RootStackScreensEnum.Dashboard } });
        }
        if (role === Roles.Doctor) {
          navigation.replace(RootStackScreensEnum.Main, {
            screen: RootStackScreensEnum.Doctor,
            params: { screen: RootStackScreensEnum.DashboardDoctor }
          });
        }
        if (role === Roles.Patient) {
          navigation.replace(RootStackScreensEnum.Main, {
            screen: RootStackScreensEnum.Patient,
            params: { screen: RootStackScreensEnum.DashboardPatient }
          });
        }
      }
    })();
  }, [isSignedIn, navigation, checkRole]);

  return (
    <AuthStack.Navigator screenOptions={{ headerShown: false }} initialRouteName={RootStackScreensEnum.SignIn}>
      <AuthStack.Screen name={RootStackScreensEnum.SignIn} component={SignIn} />
      <AuthStack.Screen name={RootStackScreensEnum.SignUp} component={SignUp} />
      <AuthStack.Screen name={RootStackScreensEnum.SignUpDoctor} component={SignUpDoctor} />
      <AuthStack.Screen name={RootStackScreensEnum.OtpVerification} component={OtpVerification} />
      <AuthStack.Screen name={RootStackScreensEnum.ForgotPassword} component={ForgotPassword} />
      <AuthStack.Screen name={RootStackScreensEnum.ResetPassword} component={ResetPassword} />
    </AuthStack.Navigator>
  );
};

export default AuthStackScreen;
