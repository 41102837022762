import React, { useEffect, useState } from 'react';
import { IDepartment } from '../../types/IDepartment';
import { gqlGetAllDepartments } from '../../utils/graphql/department_utils';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Button, Center, Hidden, HStack, Icon, Image, Modal, Spinner, Text, VStack } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import CreateAppointmentForm from './CreateAppointmentForm';
import { IDoctorWithUser } from '../../types/IDoctorWithUser';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import axios from 'axios';

interface FormModalProps {
  onCancel: any;
  showModal: boolean;
}

const FormModal = ({ onCancel, showModal }: FormModalProps) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [showFailModal, setShowFailModal] = useState(false);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<IDepartment | undefined>();
  const [messageNotAvailableDoctor, setMessageNotAvailableDoctor] = useState<string>('');
  const [availableDoctor, setAvailableDoctor] = useState<IDoctorWithUser>();
  const [confirming, setConfirming] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    (async () => {
      const departmentList = await gqlGetAllDepartments();
      setDepartments(departmentList);
    })();
  }, []);

  const openDialog = () => {
    setDialogVisible(true);
    setSelectedDepartment(departments.find((department) => department.id === formik.values.department));
  };
  const handleCloseModal = () => {
    setShowFailModal(false);
  };

  const createAppointment = async () => {
    if (!user?.id || !availableDoctor?.id) {
      return;
    }
    const data = {
      doctorID: availableDoctor?.user.id,
      date: formik.values.date,
      hour: formik.values.time,
      description: formik.values.description,
      patientID: user.id
    };
    setConfirming(true);
    const ret = await axios.post('/appointment/create', data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user?.idToken}` }
    });
    if (ret.status === 201 && ret.data) {
      setDialogVisible(false);
      setConfirming(false);
      onCancel(availableDoctor, formik.values);
    } else {
      console.log('error');
      setConfirming(false);
    }
  };
  const validationSchema = Yup.object().shape({
    description: Yup.string()
    // department: Yup.string().required('Select department'),
  });
  const initialValues = {
    department: '',
    dayOfWeek: '',
    date: '',
    time: null,
    description: ''
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!values.department || !values.date || !values.time) {
        return;
      }
      const filter = {
        departmentID: values.department,
        date: values.date,
        time: values.time,
        description: values.description
      };
      const ret = await axios.post('/doctor/available-doctor', filter, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user?.idToken}` }
      });
      if (ret.status === 200 && ret.data) {
        setAvailableDoctor(ret.data);
        setMessageNotAvailableDoctor('');
        openDialog();
      } else {
        setMessageNotAvailableDoctor('No doctor available');
        console.log('error');
        setShowFailModal(true);
      }
    }
  });

  return (
    <>
      <Modal isOpen={showModal} onClose={onCancel}>
        <Modal.Content w={{ base: '90%', md: '60%' }} maxW={'800px'}>
          <form onSubmit={formik.handleSubmit}>
            <Modal.CloseButton />
            <Modal.Header>
              <Text fontSize={'xl'} fontWeight={'medium'}>
                Create Appointment
              </Text>
            </Modal.Header>
            <Modal.Body p={8}>
              <CreateAppointmentForm formik={formik} departments={departments} messageNotAvailableDoctor={messageNotAvailableDoctor} />
            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <Button variant='ghost' colorScheme='blueGray' onPress={onCancel}>
                  Cancel
                </Button>
                <Button isDisabled={formik.isSubmitting} onPress={() => formik.handleSubmit()} bg={'primary.600'}>
                  {formik.isSubmitting && <Spinner size='sm' color='white' mr={2} />}
                  {!formik.isSubmitting && <Text color={'white'}>Create</Text>}
                </Button>
              </Button.Group>
            </Modal.Footer>
          </form>
        </Modal.Content>
      </Modal>

      <Modal isOpen={dialogVisible} onClose={() => setDialogVisible(false)}>
        <Modal.Content maxWidth='400px'>
          <Modal.Header>
            <Text fontWeight={'bold'} fontSize={{ base: '16', md: '20' }} color={'coolGray.900'}>
              Confirm
            </Text>
          </Modal.Header>
          <VStack space={4} p={4}>
            <HStack space={4}>
              {availableDoctor?.user.imageUrl ? (
                <Image source={{ uri: availableDoctor.user.imageUrl }} rounded={'full'} w={12} h={12} />
              ) : (
                <Box rounded={'full'} bg={'coolGray.600'} w={12} h={12}>
                  <Text fontSize={'xl'} fontWeight={'bold'} color={'white'}>
                    {availableDoctor?.user.lastName.charAt(0).toUpperCase()}
                  </Text>
                </Box>
              )}
              <VStack>
                <Text fontSize={16} fontWeight={'medium'}>
                  Dr. {availableDoctor?.user?.lastName}
                </Text>
                {/*<Text fontSize={16} fontWeight={'medium'}>*/}
                {/*  {availableDoctor?.specialist}*/}
                {/*</Text>*/}
                <Text fontSize={16} fontWeight={'medium'}>
                  Date: {formik.values.date}
                </Text>
                <Text fontSize={16} fontWeight={'medium'}>
                  Time: {formik.values.time}h
                </Text>
                <Text fontSize={16} fontWeight={'medium'}>
                  Department: {selectedDepartment?.name}
                </Text>
              </VStack>
            </HStack>
            <Button.Group space={2} justifyContent={'end'}>
              <Button variant='ghost' colorScheme='blueGray' onPress={() => setDialogVisible(false)}>
                <Text color={'coolGray.900'} fontSize={14} fontWeight={'medium'}>
                  Cancel
                </Text>
              </Button>
              <Button bg={'primary.600'} onPress={createAppointment}>
                {confirming && <Spinner></Spinner>}
                {!confirming && (
                  <Text color={'white'} fontSize={{ base: '14', md: '16' }} fontWeight={'bold'}>
                    Confirm
                  </Text>
                )}
              </Button>
            </Button.Group>
          </VStack>
        </Modal.Content>
      </Modal>
      <Modal isOpen={showFailModal} onClose={handleCloseModal}>
        <Modal.Content maxWidth='700px' maxHeight='1600px'>
          <Modal.CloseButton />
          <Modal.Header fontSize={'3xl'} fontWeight={'bold'}>
            Appointment Failed !!!
          </Modal.Header>
          <Modal.Body>
            <VStack>
              <Text fontSize='xl'>Unfortunately, all doctors are busy at the selected time.</Text>
              <Text fontSize='xl' mt={2}>
                Please choose a different time for your appointment.
              </Text>
            </VStack>
          </Modal.Body>
          <Modal.Footer>
            <Button
              mb={2}
              bg={'primary.600'}
              rounded={'2xl'}
              alignSelf={'center'}
              width={'15%'}
              alignItems={'center'}
              height={10}
              onPress={handleCloseModal}>
              <Text color={'white'} fontSize={20} fontWeight={'bold'}>
                OK
              </Text>
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};

const ModalButton = ({ onHide }: any) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Center>
      <Button
        variant='ghost'
        py={{ base: '2', md: '4' }}
        px={{ base: '4', md: '6' }}
        mt={4}
        bg={'#4485FD'}
        rounded={{ base: 'lg', md: '2xl' }}
        shadow={9}
        _light={{
          _text: { color: 'white' },
          _icon: { color: 'coolGray.800' }
        }}
        _dark={{
          _text: { color: 'coolGray.50' },
          _icon: { color: 'coolGray.50' }
        }}
        _text={{
          fontSize: 'xl',
          fontWeight: 'medium',
          textTransform: 'uppercase'
        }}
        _hover={{
          _light: {
            bg: 'primary.800'
          },
          _dark: {
            bg: 'coolGray.800'
          }
        }}
        _pressed={{
          _light: {
            bg: 'primary.600'
          },
          _dark: {
            bg: 'coolGray.800'
          }
        }}
        leftIcon={<Icon size='4' mr='2' as={FontAwesome} name={'calendar'} color={'white'} />}
        onPress={() => setShowModal(true)}>
        <Text color={'white'} fontSize={{ base: '16', md: '20' }} fontWeight={'bold'}>
          Create appointment
        </Text>
      </Button>
      {showModal && (
        <FormModal
          onCancel={(data: any, formik: any) => {
            console.log(data);
            setShowModal(false);
            if (data && formik) {
              onHide(data, formik);
            }
          }}
          showModal={showModal}
        />
      )}
    </Center>
  );
};
export default ModalButton;
