import React from 'react';
import { VStack } from 'native-base';
import SearchInput from '../../components/SearchInput';
import Wrapper from '../../components/Wrapper';
import DashboardLayoutDoctor from '../../layouts/DashboardLayoutDoctor';
import AppointmentWithPatient from '../../components/AppointmentWithPatient';

const PatientAppointment = () => {
  return (
    <DashboardLayoutDoctor displayScreenTitle={true} title='Appointment' rightPanelMobileHeader={true} rightPanelHasLocation={true}>
      <VStack px={{ base: 4, md: 0, lg: 8 }}>
        <Wrapper>
          <SearchInput placeholder='Example: ...' />
        </Wrapper>
        <Wrapper>
          <AppointmentWithPatient />
        </Wrapper>
      </VStack>
    </DashboardLayoutDoctor>
  );
};

export default PatientAppointment;
