import React, { useEffect } from 'react';
import { Box, Divider, HStack, Icon, IconButton, Input, Pressable, Text, VStack } from 'native-base';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import { RootStackScreensEnum } from '../../navigation/enum';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { DoctorStackParamList } from '../../types/Stacks';
import { useAppSelector } from '../../store/hooks';
import { selectAllPatients } from '../../store/hospital/patients/patients-slice';
import { useDebounce } from '../../utils/debounce';
import { selectAllUsers } from '../../store/hospital/users/users-slice';
import { IUser } from '../../types/IUser';
import { IPatient } from '../../types/IPatient';
import DashboardLayoutDoctor from '../../layouts/DashboardLayoutDoctor';
// eslint-disable-next-line react-native/split-platform-components
import CreateRecordModal from './Component/CreateRecordModal';

interface IMyComponentProps {
  data: IPatient[];
}

const patients = [
  {
    id: 1,
    fullName: 'John Smith',
    status: 'Delivered',
    medicalRecord: '',
    email: 'john.smith@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 2,
    fullName: 'Emily Johnson',
    status: 'Delivered',
    medicalRecord: '',
    email: 'emily.johnson@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 3,
    fullName: 'Michael Davis',
    status: 'Delivered',
    medicalRecord: '',
    email: 'michael.davis@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 4,
    fullName: 'Emma Wilson',
    status: 'Delivered',
    medicalRecord: '',
    email: 'emma.wilson@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 5,
    fullName: 'Daniel Anderson',
    status: 'Delivered',
    medicalRecord: '',
    email: 'daniel.anderson@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 6,
    fullName: 'Olivia Martinez',
    status: 'Delivered',
    medicalRecord: '',
    email: 'olivia.martinez@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 7,
    fullName: 'William Taylor',
    status: 'Delivered',
    medicalRecord: '',
    email: 'william.taylor@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 8,
    fullName: 'Sophia Hernandez',
    status: 'Delivered',
    medicalRecord: '',
    email: 'sophia.hernandez@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 9,
    fullName: 'James Clark',
    status: 'Delivered',
    medicalRecord: '',
    email: 'james.clark@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 10,
    fullName: 'Ava Lewis',
    status: 'Delivered',
    medicalRecord: '',
    email: 'ava.lewis@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 11,
    fullName: 'Benjamin Walker',
    status: 'Delivered',
    medicalRecord: '',
    email: 'benjamin.walker@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 12,
    fullName: 'Mia Hall',
    status: 'Delivered',
    medicalRecord: '',
    email: 'mia.hall@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 13,
    fullName: 'Ethan Turner',
    status: 'Delivered',
    medicalRecord: '',
    email: 'ethan.turner@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 14,
    fullName: 'Isabella White',
    status: 'Delivered',
    medicalRecord: '',
    email: 'isabella.white@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 15,
    fullName: 'Jacob Garcia',
    status: 'Delivered',
    medicalRecord: '',
    email: 'jacob.garcia@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 16,
    fullName: 'Sofia Thompson',
    status: 'Delivered',
    medicalRecord: '',
    email: 'sofia.thompson@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 17,
    fullName: 'Alexander Martinez',
    status: 'Delivered',
    medicalRecord: '',
    email: 'alexander.martinez@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 18,
    fullName: 'Charlotte Johnson',
    status: 'Delivered',
    medicalRecord: '',
    email: 'charlotte.johnson@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 19,
    fullName: 'Liam Wilson',
    status: 'Delivered',
    medicalRecord: '',
    email: 'liam.wilson@example.com',
    checkInTime: '22-03-2023'
  },
  {
    id: 20,
    fullName: 'Scarlett Anderson',
    status: 'Delivered',
    medicalRecord: '',
    email: 'scarlett.anderson@example.com',
    checkInTime: '22-03-2023'
  }
];

const MyComponent = ({ data }: IMyComponentProps) => {
  const navigation = useNavigation<NativeStackNavigationProp<DoctorStackParamList, RootStackScreensEnum.patientList_Doctor>>();
  const users = useAppSelector((state) => selectAllUsers(state.hospital.users));

  return (
    <Box backgroundColor={'white'} borderRadius={'xl'}>
      {patients.map((item) => (
        <Pressable flex={1} key={item.id.toString()}>
          <HStack
            px={6}
            py={4}
            space={2}
            borderBottomWidth={1}
            alignItems='center'
            _light={{ borderBottomColor: 'coolGray.200' }}
            _dark={{ borderBottomColor: 'coolGray.700' }}>
            <Text
              fontWeight='medium'
              fontSize='sm'
              lineHeight='21'
              _light={{ color: 'coolGray.500' }}
              _dark={{ color: 'coolGray.400' }}
              w='6%'
              numberOfLines={1}
              // onPress={() => navigation.navigate(RootStackScreensEnum.PatientDetails, { patientId: item.id })}
            >
              {item.id}
            </Text>
            <Text fontWeight='medium' fontSize='sm' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }} w='16%'>
              {item.fullName}
            </Text>
            <Text fontWeight='medium' fontSize='sm' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }} w='12%'>
              {item.status}
            </Text>
            <Text fontWeight='medium' fontSize='sm' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }} w='16%'>
              {item.medicalRecord}
            </Text>
            <Text fontWeight='medium' fontSize='sm' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }} w='16%'>
              {item.email}
            </Text>
            <Text fontWeight='medium' fontSize='sm' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }} w='12%'>
              {item.checkInTime}
            </Text>

            <HStack justifyContent={'space-between'} w={'6%'}>
              <IconButton
                variant='ghost'
                colorScheme='light'
                icon={<Icon size='5' _light={{ color: '#0564C8' }} _dark={{ color: 'secondary.500' }} name={'chatbox'} as={Ionicons} />}
              />
              <IconButton
                variant='ghost'
                colorScheme='light'
                p={0}
                icon={<Icon size='5' _light={{ color: '#0564C8' }} _dark={{ color: 'secondary.500' }} name={'call'} as={Ionicons} />}
              />
            </HStack>
          </HStack>
        </Pressable>
      ))}
    </Box>
  );
};
export default function PatientList() {
  const patients = useAppSelector((state) => selectAllPatients(state.hospital.patients));
  const users = useAppSelector((state) => selectAllUsers(state.hospital.users));
  const [searchText, setSearchText] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<IPatient[]>([]);
  const textSearch = useDebounce(searchText, 1000);

  const onSearchTextChange = (text: string) => {
    setSearchText(text);
  };

  useEffect(() => {
    const searchValue = searchText.toLowerCase();
    const arr = patients.filter((item) => {
      // Find the user by firstName + lastName
      const foundNameUsers = users.filter((user: IUser) => {
        const fullName = user.firstName + ' ' + user.lastName;
        return fullName.toLowerCase().includes(searchValue);
      });

      // Find the user by email
      const foundEmailUsers = users.filter((user: IUser) => {
        return user.email.toLowerCase().includes(searchValue);
      });

      // Return the list of patients that has patientUserId in the list of found users
      return foundNameUsers.some((user) => user.id === item.patientUserId) || foundEmailUsers.some((user) => user.id === item.patientUserId);
    });
    console.log(arr);
    setSearchResults(arr);
  }, [textSearch]);
  return (
    <>
      <DashboardLayoutDoctor
        title='Dashboard'
        displayMenuButton
        displayScreenTitle={false}
        displayAlternateMobileHeader
        rightPanelMobileHeader={true}>
        <VStack flex={1} rounded={{ md: 'sm' }}>
          <ContactData searchText={searchText} onSearchTextChange={onSearchTextChange} />
          <MyComponent data={searchResults || []} />
        </VStack>
      </DashboardLayoutDoctor>
    </>
  );
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
const ContactData = ({ onSearchTextChange = () => {}, searchText }: any) => {
  const navigation = useNavigation();
  return (
    <>
      <HStack alignItems={'center'} w={'100%'}>
        <Pressable mt={1} ml={'4'} mr={3} onPress={() => navigation.goBack()}>
          <Icon as={Ionicons} name='arrow-back' size='xl' color={'coolGray.700'} />
        </Pressable>
        <Text w={'95%'} fontSize='20' fontWeight='bold' _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }}>
          My Patients List
        </Text>
      </HStack>
      <VStack space='4'>
        <HStack ml={5} mt={5} flexWrap={'wrap'} flex={1} justifyContent='space-between'>
          <Input
            rounded={'full'}
            value={searchText}
            onChangeText={onSearchTextChange}
            py={2}
            pl={3}
            _light={{
              bg: 'white',
              borderColor: 'coolGray.300'
            }}
            _dark={{
              bg: 'coolGray.800',
              borderColor: 'coolGray.500'
            }}
            size='md'
            InputLeftElement={
              <Icon
                as={<MaterialIcons name='search' />}
                size={6}
                ml={3}
                _light={{
                  color: 'coolGray.400'
                }}
                _dark={{
                  color: 'coolGray.300'
                }}
              />
            }
            placeholder='Search'
          />
          <CreateRecordModal />
        </HStack>
        <VStack
          _light={{
            bg: 'white'
          }}
          _dark={{
            bg: 'coolGray.800'
          }}
          divider={<Divider />}
          borderRadius={{ md: 'sm' }}>
          <HStack alignItems='center' px='6' pt='6' pb='4' space={2}>
            <Text fontWeight='bold' fontSize='sm' textAlign='left' color='coolGray.500' lineHeight='17.5' w='6%'>
              ID
            </Text>
            <Text fontWeight='bold' fontSize='sm' textAlign='left' color='coolGray.500' lineHeight='17.5' w='16%'>
              Name
            </Text>
            <Text fontWeight='bold' fontSize='sm' textAlign='left' color='coolGray.500' lineHeight='17.5' w='12%'>
              Status
            </Text>
            <Text fontWeight='bold' fontSize='sm' textAlign='left' color='coolGray.500' lineHeight='17.5' w='16%'>
              Medical Record
            </Text>
            <Text fontWeight='bold' fontSize='sm' textAlign='left' color='coolGray.500' lineHeight='17.5' w='16%'>
              Email
            </Text>
            <Text fontWeight='bold' fontSize='sm' textAlign='left' color='coolGray.500' lineHeight='17.5' w='12%'>
              Date check in
            </Text>
            <Text fontWeight='bold' fontSize='sm' textAlign='left' color='coolGray.500' lineHeight='17.5' w='6%'>
              Action
            </Text>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};
