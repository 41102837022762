export default {
  defaultProps: {
    _light: {
      bg: 'coolGray.200'
    },
    _dark: {
      bg: 'coolGray.700'
    }
  }
};
