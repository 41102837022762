import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { HStack, IconButton, Input, Modal, Pressable, Select, Text, View } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';

const statuses = [
  { label: 'Preparing for examination', value: 'Preparing for examination' },
  { label: 'Undergoing treatment', value: 'Undergoing treatment' },
  { label: 'Recovering', value: 'Recovering' },
  { label: 'Fully recovered', value: 'Fully recovered' },
  { label: 'Stable condition', value: 'Stable condition' },
  { label: 'Critical condition', value: 'Critical condition' },
  { label: 'In palliative care', value: 'In palliative care' }
];

const diseases = [
  { label: 'Common cold', value: 'Common cold' },
  { label: 'Influenza', value: 'Influenza' },
  { label: 'Pneumonia', value: 'Pneumonia' },
  { label: 'Bronchitis', value: 'Bronchitis' },
  { label: 'Asthma', value: 'Asthma' },
  { label: 'Diabetes', value: 'Diabetes' },
  { label: 'Hypertension', value: 'Hypertension' },
  { label: 'Migraine', value: 'Migraine' },
  { label: 'Gastritis', value: 'Gastritis' },
  { label: 'Peptic ulcer', value: 'Peptic ulcer' },
  { label: 'Allergies', value: 'Allergies' },
  { label: 'Arthritis', value: 'Arthritis' },
  { label: 'Depression', value: 'Depression' },
  { label: 'Anxiety', value: 'Anxiety' },
  { label: 'Insomnia', value: 'Insomnia' },
  { label: 'Eczema', value: 'Eczema' },
  { label: 'Psoriasis', value: 'Psoriasis' },
  { label: 'Cancer', value: 'Cancer' },
  { label: 'Stroke', value: 'Stroke' },
  { label: 'Heart disease', value: 'Heart disease' }
];

const medicineList = [
  { label: 'Paracetamol', value: 'Paracetamol' },
  { label: 'Ibuprofen', value: 'Ibuprofen' },
  { label: 'Aspirin', value: 'Aspirin' },
  { label: 'Amoxicillin', value: 'Amoxicillin' },
  { label: 'Omeprazole', value: 'Omeprazole' },
  { label: 'Simvastatin', value: 'Simvastatin' },
  { label: 'Metformin', value: 'Metformin' },
  { label: 'Lisinopril', value: 'Lisinopril' },
  { label: 'Ventolin', value: 'Ventolin' },
  { label: 'Levothyroxine', value: 'Levothyroxine' },
  { label: 'Atorvastatin', value: 'Atorvastatin' },
  { label: 'Gabapentin', value: 'Gabapentin' },
  { label: 'Metoprolol', value: 'Metoprolol' },
  { label: 'Sertraline', value: 'Sertraline' },
  { label: 'Loratadine', value: 'Loratadine' },
  { label: 'Diazepam', value: 'Diazepam' },
  { label: 'Cetirizine', value: 'Cetirizine' },
  { label: 'Codeine', value: 'Codeine' },
  { label: 'Tramadol', value: 'Tramadol' },
  { label: 'Warfarin', value: 'Warfarin' }
];

const CreateRecordModal = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [fullName, setFullName] = useState('');
  const [status, setStatus] = useState('');
  const [email, setEmail] = useState('');
  const [checkInDate, setCheckInDate] = useState(new Date());
  const [disease, setDisease] = useState('');
  const [medicine, setMedicine] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [instruction, setInstruction] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const handlePress = () => {
    setModalVisible(true);
  };

  const handleSave = () => {
    if (!fullName || !status || !email || !checkInDate || !disease || !medicine || quantity === 0 || !instruction) {
      setErrorMessage('Please fill in all fields');
    } else {
      const record = {
        fullName,
        status,
        email,
        checkInDate,
        disease,
        medicine,
        quantity,
        instruction
      };
      setSuccessMessage('Record saved successfully');
      console.log(record);
      handleClearInputs();
      setModalVisible(false);
    }
  };

  const handleClearInputs = () => {
    setFullName('');
    setStatus('');
    setEmail('');
    setCheckInDate(new Date());
    setDisease('');
    setMedicine('');
    setQuantity(0);
    setInstruction('');
  };

  const handleCancel = () => {
    handleClearInputs();
    setModalVisible(false);
  };

  const showDatePickerModal = async () => {
    // ...
  };

  const handleQuantityIncrease = () => {
    setQuantity(quantity + 1);
  };

  const handleQuantityDecrease = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <>
      <Pressable
        shadow={3}
        rounded='20'
        _hover={{ backgroundColor: 'primary.400' }}
        p={1}
        alignItems='center'
        justifyContent='center'
        backgroundColor='primary.600'
        onPress={handlePress}>
        <Text color='white' fontSize='16'>
          Create Record
        </Text>
      </Pressable>

      <Modal isOpen={modalVisible} animationPreset='slide' onClose={handleCancel}>
        <Modal.Content maxWidth='900px' maxHeight='700px'>
          <Modal.CloseButton onPress={handleCancel} />

          <Modal.Header>Create Record</Modal.Header>

          <Modal.Body>
            <Input placeholder='Full Name' value={fullName} onChangeText={(value) => setFullName(value)} marginBottom={4} />
            <Select
              selectedValue={status}
              minWidth={200}
              accessibilityLabel='Select Status'
              placeholder='Select Status'
              marginBottom={4}
              onValueChange={(value) => setStatus(value)}>
              {statuses.map((item) => (
                <Select.Item key={item.value} label={item.label} value={item.value} />
              ))}
            </Select>
            <Input placeholder='Email' value={email} onChangeText={(value) => setEmail(value)} marginBottom={4} />
            <TouchableOpacity onPress={showDatePickerModal}>
              <View>
                <Text>Select Check-In Date</Text>
                <Text>{checkInDate.toLocaleDateString()}</Text>
              </View>
            </TouchableOpacity>
            <Select
              selectedValue={disease}
              minWidth={200}
              accessibilityLabel='Select Disease'
              placeholder='Select Disease'
              marginBottom={4}
              onValueChange={(value) => setDisease(value)}>
              {diseases.map((item) => (
                <Select.Item key={item.value} label={item.label} value={item.value} />
              ))}
            </Select>

            <Select
              selectedValue={medicine}
              minWidth={200}
              accessibilityLabel='Select Medicine'
              placeholder='Select Medicine'
              marginBottom={4}
              onValueChange={(value) => setMedicine(value)}
              // multiple
            >
              {medicineList.map((item) => (
                <Select.Item key={item.value} label={item.label} value={item.value} />
              ))}
            </Select>

            <HStack space={2} alignItems='center' marginBottom={4}>
              <Text>Quantity: </Text>
              <IconButton icon={<MaterialIcons name='remove' size={24} color='black' />} onPress={handleQuantityDecrease} />
              <Text>{quantity}</Text>
              <IconButton icon={<MaterialIcons name='add' size={24} color='black' />} onPress={handleQuantityIncrease} />
            </HStack>

            <Input placeholder='Instruction' value={instruction} onChangeText={(value) => setInstruction(value)} marginBottom={4} />
          </Modal.Body>

          <Modal.Footer>
            <TouchableOpacity onPress={handleCancel} style={{ marginRight: 16 }}>
              <Text style={{ color: 'red' }}>Cancel</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={handleSave}>
              <Text style={{ color: 'blue' }}>Save</Text>
            </TouchableOpacity>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default CreateRecordModal;
