import { API, graphqlOperation } from 'aws-amplify';
import { listAppointments } from '../../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import { ListAppointmentsQuery } from '../../API';

export const gqlGetAppointmentByDoctorID = async (doctorID: string) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get appointment by doctor ID ${retry} time(s)`);
    }

    try {
      const ret = (await API.graphql(
        graphqlOperation(listAppointments, {
          filter: {
            doctorID: {
              eq: doctorID
            }
          }
        })
      )) as GraphQLResult<ListAppointmentsQuery>;
      return ret.data?.listAppointments?.items;
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to get appointment by doctor ID');
  }

  return null;
};
