import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Hidden, HStack, Icon, Image, Input, Pressable, Text, VStack } from 'native-base';
import { Feather, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { Category } from '../../components/CategoriesPatient';
import DashboardLayoutPatient from '../../layouts/DashboardLayoutPatient';
import { NavigationProp, useNavigation, useRoute } from '@react-navigation/native';
import { RootStackScreensEnum } from '../../navigation/enum';
import SearchInput from '../../components/SearchInput';
import { Animated } from 'react-native';
import { PatientStackParamList } from '../../types/Stacks';
import ChatBoxAI from '../ChatBoxAI';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Patient } from '../../layouts/HeaderPatient';
import ModalButton from './ModalButton';
import ShowMeetingList from './ShowMeetingList';
import CardMobilePatient from './CardMobilePatient';
import ImageSlide from './ImageSlide';
import ServiceCard from './ServiceCard';

type Icon = {
  iconName: string;
  iconText: string;
  route?: RootStackScreensEnum;
};

const services: Category[] = [
  {
    id: 1,
    imageUri: require('../../assets/images/doctor-blue.jpg'),
    text: 'Doctor List',
    icon: 'doctor',
    iconColor: 'customBlue'
  },
  {
    id: 2,
    imageUri: require('../../assets/images/doctor-green.jpg'),
    text: 'Health Store',
    icon: 'store',
    iconColor: 'primary.600'
  },
  {
    id: 3,
    imageUri: require('../../assets/images/doctor-turquoise.jpg'),
    text: `Doctor's Appointment`,
    icon: 'calendar',
    iconColor: 'customTurquoise'
  },
  {
    id: 4,
    imageUri: require('../../assets/images/doctor-pink.jpg'),
    text: `Health care services`,
    icon: 'heart',
    iconColor: 'red.600'
  }
];

const footerIcons: Icon[] = [
  { iconName: 'home', iconText: 'Home', route: RootStackScreensEnum.DashboardPatient },
  { iconName: 'clipboard', iconText: 'Records', route: RootStackScreensEnum.PatientHistory },
  { iconName: 'user', iconText: 'Profile', route: RootStackScreensEnum.PatientProfile },
  { iconName: 'settings', iconText: 'Settings', route: RootStackScreensEnum.ChangePassword }
];

const sites = [
  {
    id: 1,
    text: 'FAQ',
    url: 'https://www.google.com'
  },
  {
    id: 2,
    text: 'Blog',
    url: 'https://www.google.com'
  },
  {
    id: 3,
    text: 'Term & Conditions',
    url: 'https://www.google.com'
  },
  {
    id: 4,
    text: 'Privacy Policy',
    url: 'https://www.google.com'
  },
  {
    id: 5,
    text: 'Promo',
    url: 'https://www.google.com'
  },
  {
    id: 6,
    text: 'Career',
    url: 'https://www.google.com'
  }
];

const contacts = [
  {
    id: 1,
    text: 'Phone: +62 21 12345678'
  },
  {
    id: 2,
    text: 'Email: example@gmail.com'
  }
];

const MobileFooter = () => {
  const route = useRoute();
  // @ts-ignore
  const navigation = useNavigation<NativeStackNavigationProp<PatientStackParamList, RootStackScreensEnum.Patient>>();
  const onPressCategory = (category: Patient) => {
    switch (category.iconText) {
      case 'Records':
        // @ts-ignore
        navigation.navigate(RootStackScreensEnum.Patient, { screen: RootStackScreensEnum.PatientHistory });
        break;
      case 'Profile':
        // @ts-ignore
        // navigation.navigate(RootStackScreensEnum.Patient, { screen: RootStackScreensEnum.PatientProfile });
        navigation.navigate(RootStackScreensEnum.Patient, { screen: RootStackScreensEnum.DashboardPatient });
        break;
      case 'Settings':
        // @ts-ignore
        navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.ChangePassword });
        break;
      default:
        // @ts-ignore
        navigation.navigate(RootStackScreensEnum.Patient, { screen: RootStackScreensEnum.DashboardPatient });
        break;
    }
  };
  return (
    <HStack
      justifyContent='space-between'
      safeAreaBottom
      h='12'
      width='100%'
      position='absolute'
      left='0'
      right='0'
      bottom='0'
      overflow='hidden'
      alignSelf='center'
      borderTopLeftRadius='20'
      borderTopRightRadius='20'
      _light={{ backgroundColor: 'coolGray.50' }}
      _dark={{ backgroundColor: 'coolGray.800' }}>
      {footerIcons.map((item, index) => {
        return (
          <Button
            w={'25%'}
            onPress={() => onPressCategory(item)}
            key={index}
            variant='ghost'
            colorScheme='coolGray'
            _stack={{
              flexDirection: 'column'
            }}
            backgroundColor={item.route === route.name ? 'primary.100' : 'transparent'}
            startIcon={
              <Icon
                as={Feather}
                name={item.iconName}
                size='6'
                _light={{
                  color: item.route === route.name ? '#0564C8' : '#2D2D2D'
                }}
                _dark={{
                  color: index === 0 ? 'primary.500' : 'coolGray.400'
                }}
              />
            }
            _text={{
              fontSize: 'xs',
              _light: {
                color: item.route === route.name ? '#0564C8' : '#2D2D2D'
              },
              _dark: {
                color: index === 0 ? 'primary.500' : 'coolGray.400'
              }
            }}>
            {item.iconText}
          </Button>
        );
      })}
    </HStack>
  );
};

const Footer = () => {
  return (
    <Box h='80' mb={-8} py={10} pl={64} _light={{ backgroundColor: 'coolGray.700' }} _dark={{ backgroundColor: 'coolGray.900' }}>
      <HStack width='100vw' space={40}>
        <VStack>
          <Image source={require('../../assets/logoApp.png')} size={40} alt='Alternate Text' />
        </VStack>
        <VStack>
          <Text fontWeight={'medium'} fontSize={'lg'} color={'coolGray.50'} mb={4}>
            Site Map
          </Text>
          {sites.map((item) => {
            return (
              <Pressable key={item.id} mb={2} onPress={() => console.log(item.url)}>
                <Text color={'coolGray.50'}>{item.text}</Text>
              </Pressable>
            );
          })}
        </VStack>
        <VStack>
          <Text fontWeight={'medium'} fontSize={'lg'} color={'coolGray.50'} mb={4}>
            Contact Us
          </Text>
          {contacts.map((item) => {
            return (
              <Text key={item.id} mb={2} color={'coolGray.50'}>
                {item.text}
              </Text>
            );
          })}
        </VStack>
      </HStack>
    </Box>
  );
};

const IntroductionServices = () => {
  const animatedValueCallWithDoctorLeft = useRef(new Animated.Value(-500)).current;

  useEffect(() => {
    Animated.timing(animatedValueCallWithDoctorLeft, {
      toValue: 10,
      duration: 3000,
      useNativeDriver: false
    }).start();
  }, [animatedValueCallWithDoctorLeft]);

  const animatedValueCallWithDoctorRight = useRef(new Animated.Value(2500)).current;

  useEffect(() => {
    Animated.timing(animatedValueCallWithDoctorRight, {
      toValue: 10,
      duration: 3000,
      useNativeDriver: false
    }).start();
  }, [animatedValueCallWithDoctorRight]);

  const animatedValueHealthStoreLeft = useRef(new Animated.Value(-500)).current;

  useEffect(() => {
    Animated.timing(animatedValueHealthStoreLeft, {
      toValue: 10,
      duration: 4000,
      useNativeDriver: false
    }).start();
  }, [animatedValueHealthStoreLeft]);

  const animatedValueHealthStoreRight = useRef(new Animated.Value(2500)).current;

  useEffect(() => {
    Animated.timing(animatedValueHealthStoreRight, {
      toValue: 10,
      duration: 4000,
      useNativeDriver: false
    }).start();
  }, [animatedValueHealthStoreRight]);

  const animatedValueAppointmentLeft = useRef(new Animated.Value(-400)).current;

  useEffect(() => {
    Animated.timing(animatedValueAppointmentLeft, {
      toValue: 10,
      duration: 5000,
      useNativeDriver: false
    }).start();
  }, [animatedValueAppointmentLeft]);

  const animatedValueAppointmentRight = useRef(new Animated.Value(2500)).current;

  useEffect(() => {
    Animated.timing(animatedValueAppointmentRight, {
      toValue: 10,
      duration: 5000,
      useNativeDriver: false
    }).start();
  }, [animatedValueAppointmentRight]);

  const animatedValueServiceLeft = useRef(new Animated.Value(-400)).current;

  useEffect(() => {
    Animated.timing(animatedValueServiceLeft, {
      toValue: 10,
      duration: 6000,
      useNativeDriver: false
    }).start();
  }, [animatedValueServiceLeft]);

  const animatedValueServiceRight = useRef(new Animated.Value(2500)).current;

  useEffect(() => {
    Animated.timing(animatedValueServiceRight, {
      toValue: 10,
      duration: 6000,
      useNativeDriver: false
    }).start();
  }, [animatedValueServiceRight]);
  return (
    <>
      <HStack h={'450'} justifyContent={'space-between'} alignItems={'center'}>
        <Animated.View
          style={{
            marginLeft: animatedValueCallWithDoctorLeft,
            width: '40%'
          }}>
          <VStack space={6}>
            <Text fontSize={{ base: '20', md: '36' }} fontWeight={'bold'}>
              Call With Doctor
            </Text>
            <Text fontSize={{ base: '16', md: '24' }} fontWeight={{ base: 'medium', md: '' }}>
              Need professional advice when experiencing health problems? No matter where you are or at any time, specialists are always ready to and
              and and answer all your questions.
            </Text>
            <Text fontSize={{ base: '16', md: '24' }}>Doctors are online:</Text>
            <HStack space={2} alignItems={'center'}>
              <Box w={{ base: 12, md: 20 }} h={{ base: 12, md: 20 }} rounded='full' borderWidth={1} borderColor={'gray.700'}>
                <Image flex='1' source={require('../assets/doctor.png')} alt='Alternate Text' rounded={'full'} />
              </Box>
              <Box w={{ base: 12, md: 20 }} h={{ base: 12, md: 20 }} rounded='full' borderWidth={1} borderColor={'gray.700'}>
                <Image flex='1' source={require('../assets/doctor.png')} alt='Alternate Text' rounded={'full'} />
              </Box>
              <Box w={{ base: 12, md: 20 }} h={{ base: 12, md: 20 }} rounded='full' borderWidth={1} borderColor={'gray.700'}>
                <Image flex='1' source={require('../assets/doctor.png')} alt='Alternate Text' rounded={'full'} />
              </Box>
              <Text>+ 10 more</Text>
            </HStack>
          </VStack>
        </Animated.View>
        <Animated.View
          style={{
            marginLeft: animatedValueCallWithDoctorRight,
            height: '100%',
            width: '50%'
          }}>
          <Image source={require('../../assets/images/chat-with-doctor.png')} alt='image base' h={'100%'} resizeMode={'contain'} />
        </Animated.View>
      </HStack>
      <HStack h={'450'} justifyContent={'space-between'} alignItems={'center'} pt={10}>
        <Animated.View
          style={{
            marginLeft: animatedValueHealthStoreLeft,
            height: '100%',
            width: '50%'
          }}>
          <Image source={require('../../assets/images/medicine-cart.jpg')} alt='image base' h={'100%'} resizeMode={'contain'} />
        </Animated.View>
        <Animated.View
          style={{
            marginLeft: animatedValueHealthStoreRight,
            width: '40%'
          }}>
          <VStack space={6}>
            <Text fontSize={{ base: '20', md: '36' }} fontWeight={'bold'}>
              Health Store
            </Text>
            <Text fontSize={{ base: '16', md: '24' }} fontWeight={{ base: 'medium', md: '' }}>
              Easily order genuine medicines and health care products online at economical prices. We deliver to your doorsteps.
            </Text>
            <HStack>
              <Button
                rounded='full'
                variant='ghost'
                px={6}
                py={2}
                bg={'primary.600'}
                _text={{
                  color: 'white',
                  fontSize: '16',
                  fontWeight: 'medium'
                }}
                _hover={{
                  bg: 'primary.500',
                  shadow: 5
                }}
                _pressed={{
                  bg: 'primary.600'
                }}>
                More details
              </Button>
            </HStack>
          </VStack>
        </Animated.View>
      </HStack>
      <HStack h={'450'} justifyContent={'space-between'} alignItems={'center'} pt={10}>
        <Animated.View
          style={{
            marginLeft: animatedValueAppointmentLeft,
            width: '40%'
          }}>
          <VStack space={6}>
            <Text fontSize={{ base: '20', md: '36' }} fontWeight={'bold'}>
              Doctor&apos;s Appointment
            </Text>
            <Text fontSize={{ base: '16', md: '24' }} fontWeight={{ base: 'medium', md: '' }}>
              Leave contact information and we will contact you to confirm the appointment.
            </Text>
            <HStack>
              <Button
                rounded='full'
                variant='ghost'
                px={6}
                py={2}
                bg={'primary.600'}
                _text={{
                  color: 'white',
                  fontSize: '16',
                  fontWeight: 'medium'
                }}
                _hover={{
                  bg: 'primary.500',
                  shadow: 5
                }}
                _pressed={{
                  bg: 'primary.600'
                }}>
                Book now
              </Button>
            </HStack>
          </VStack>
        </Animated.View>
        <Animated.View
          style={{
            marginLeft: animatedValueAppointmentRight,
            height: '100%',
            width: '50%'
          }}>
          <Image source={require('../../assets/images/book-an-appointment.jpg')} alt='image base' h={'100%'} resizeMode={'contain'} />
        </Animated.View>
      </HStack>
      <HStack h={'450'} justifyContent={'space-between'} alignItems={'center'} pt={10} pb={12}>
        <Animated.View
          style={{
            marginLeft: animatedValueServiceLeft,
            height: '100%',
            width: '50%'
          }}>
          <Image source={require('../../assets/images/healthcare-services.png')} alt='image base' h={'100%'} resizeMode={'contain'} />
        </Animated.View>
        <Animated.View
          style={{
            marginLeft: animatedValueServiceRight,
            width: '40%'
          }}>
          <VStack space={6}>
            <Text fontSize={{ base: '20', md: '36' }} fontWeight={'bold'}>
              Health Care Services
            </Text>
            <Text fontSize={{ base: '16', md: '24' }} fontWeight={{ base: 'medium', md: '' }}>
              Learn more about our healthcare services, from the common cold to chronic illnesses - the friendly doctors will take care of you and
              your family with care.
            </Text>
            <HStack>
              <Button
                rounded='full'
                variant='ghost'
                px={6}
                py={2}
                bg={'primary.600'}
                _text={{
                  color: 'white',
                  fontSize: '16',
                  fontWeight: 'medium'
                }}
                _hover={{
                  bg: 'primary.500',
                  shadow: 5
                }}
                _pressed={{
                  bg: 'primary.600'
                }}>
                Learn more
              </Button>
            </HStack>
          </VStack>
        </Animated.View>
      </HStack>
    </>
  );
};

const DashboardPatient = () => {
  const [meetingList, setMeetingList] = useState<any>([]);
  const onHide = (item: any, formik: any) => {
    setMeetingList((prev: any) => {
      return [...prev, { ...item, formik }];
    });
  };
  return (
    <>
      <Hidden till={'md'}>
        <Box shadow={9} position='absolute' bottom={4} right={4} zIndex={1}>
          <ChatBoxAI />
        </Box>
      </Hidden>
      <DashboardLayoutPatient
        title='Dashboard'
        displayMenuButton
        displayScreenTitle={false}
        displayAlternateMobileHeader
        rightPanelMobileHeader={true}
        rightPanelHasLocation={true}>
        <Hidden till={'md'}>
          <>
            <HStack mx={-8} mt={-8} w={'calc(100% + 64px)'} h={'600'} position={'relative'} justifyContent={'center'}>
              <Image
                position={'absolute'}
                source={require('../../assets/images/hospital.jpg')}
                w={{ base: '100%', md: '100%' }}
                h={{ base: '100%', md: '100%' }}
                alt='image base'
              />
              <VStack zIndex={100} mt={'150'} alignItems={'center'} w={'100%'}>
                <Text
                  mx={10}
                  mb={5}
                  textTransform={'uppercase'}
                  fontSize={{ base: '2xl', md: '4xl' }}
                  color={'white'}
                  textAlign={'center'}
                  fontWeight={'bold'}>
                  Comprehensive health care medical base
                </Text>
                <Box>
                  <SearchInput placeholder={'Search for a doctor'} />
                </Box>
                <Box mt={2}>
                  <ModalButton onHide={onHide} />
                </Box>
              </VStack>
            </HStack>
            <VStack
              px={{ base: 0, md: 32 }}
              pt={{ base: 0, md: 8 }}
              pb={{ base: 0, md: 8 }}
              _light={{ bg: 'white' }}
              _dark={{ bg: 'coolGray.800' }}
              rounded={{ md: 'sm' }}
              w={'100%'}>
              <ShowMeetingList MeetingListProps={meetingList} />
              <ServiceCard />
              <IntroductionServices />
            </VStack>
            <Footer />
          </>
        </Hidden>
        <Hidden from={'md'}>
          <>
            <VStack space='2' w={'100%'}>
              <ImageSlide />
              <Input
                my={3}
                alignSelf={'center'}
                width={'90%'}
                height={'15%'}
                rounded={'lg'}
                flex={1}
                py={3}
                _stack={{
                  px: 3
                }}
                _light={{
                  placeholderTextColor: 'coolGray.400',
                  size: '14'
                }}
                _dark={{
                  bg: 'coolGray.700',
                  borderColor: 'coolGray.500',
                  color: 'coolGray.100'
                }}
                InputRightElement={
                  <Icon
                    as={<MaterialIcons name='search' />}
                    size={7}
                    _light={{
                      color: 'coolGray.700'
                    }}
                    _dark={{
                      color: 'coolGray.300'
                    }}
                  />
                }
                placeholder='Search...'
              />
              <Box>
                <CardMobilePatient />
              </Box>
            </VStack>
            <Box w={'100%'}>
              <ServiceCard />
            </Box>
          </>
        </Hidden>
      </DashboardLayoutPatient>
      <Hidden from={'md'}>
        <MobileFooter />
      </Hidden>
    </>
  );
};

export default DashboardPatient;
