import React, { useEffect, useState } from 'react';
import { Box, Center, Button, FormControl, Input, Hidden, Spinner, HStack, Image, Pressable, Text, VStack } from 'native-base';
import GuestLayout from '../../layouts/GuestLayout';
import * as Yup from 'yup';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import { AuthStackParamList } from '../../types/Stacks';
import { RootStackScreensEnum } from '../../navigation/enum';
import { Formik } from 'formik';

interface IPinInputProps {
  email: string;
}

function PinInput({ email }: IPinInputProps) {
  const { errorMessage, successMessage, setErrorMessage, setSuccessMessage, codeVerify } = useAuth();
  const navigation = useNavigation<NativeStackNavigationProp<AuthStackParamList, RootStackScreensEnum.OtpVerification>>();

  {
    /*  // Reset errorMessage and successMessage on unmount*/
  }
  useEffect(() => {
    return () => {
      setErrorMessage('');
      setSuccessMessage('');
    };
  }, [setErrorMessage, setSuccessMessage]);

  const initialValues = {
    verificationCode: ''
  };

  const validationSchema = Yup.object().shape({
    verificationCode: Yup.string()
      .required('Code is required')
      .matches(/^\d{6}$/, 'Invalid code')
  });

  const onSubmit = async (values: any) => {
    const { verificationCode } = values;
    try {
      await codeVerify(verificationCode, email);

      // Navigate to sign in page
      navigation.replace(RootStackScreensEnum.SignIn);
    } catch (error) {
      console.log('Code verification error', error);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ touched, handleChange, handleBlur, handleSubmit, isSubmitting, dirty, values, errors }) => (
        <VStack space={4}>
          {/*BEGIN: Success or error message. Success message in green, error message in red*/}
          {successMessage && (
            <Box bg='green.400' px={4} py={3} rounded='md' _text={{ color: 'white' }}>
              {successMessage}
            </Box>
          )}
          {errorMessage && (
            <Box bg='red.400' px={4} py={3} rounded='md' _text={{ color: 'white' }}>
              {errorMessage}
            </Box>
          )}
          {/* END: Success or error message */}
          <FormControl isInvalid={'verificationCode' in errors && touched.verificationCode}>
            <FormControl.Label>Verification code</FormControl.Label>
            <Input
              onKeyPress={(event) => {
                if (event.nativeEvent.key === 'Enter') {
                  handleSubmit();
                }
              }}
              onBlur={handleBlur('email')}
              placeholder='Enter your verification code'
              onChangeText={handleChange('verificationCode')}
              value={values.verificationCode}
            />
            <FormControl.ErrorMessage>{errors.verificationCode}</FormControl.ErrorMessage>
          </FormControl>
          <Button
            w={'50%'}
            h={'10'}
            rounded={'xl'}
            alignSelf={'center'}
            disabled={isSubmitting || !dirty}
            onPress={() => handleSubmit()}
            bg='#66CDAA'
            _text={{ color: 'white', fontSize: 'xl', fontWeight: 'bold', fontFamily: 'Sketch_Block' }}>
            {isSubmitting ? (
              <Spinner accessibilityLabel='Loading' size='lg' color={'white'} fontWeight={'bold'} fontFamily={'Sketch_Block'} />
            ) : (
              <Text fontSize={'xl'} fontWeight={'bold'} fontFamily={'Sketch_Block'} color={'white'}>
                Verify
              </Text>
            )}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}

function Header() {
  return (
    <HStack space='2' px='1' mt='4' mb='5' style={{ alignItems: 'center' }}>
      {/*<IconButton*/}
      {/*    p={0}*/}
      {/*    icon={*/}
      {/*        <Icon*/}
      {/*            alignItems="center"*/}
      {/*            justifyContent="center"*/}
      {/*            size="6"*/}
      {/*            as={MaterialIcons}*/}
      {/*            name="keyboard-backspace"*/}
      {/*            color="coolGray.50"*/}
      {/*        />*/}
      {/*    }*/}
      {/*    onPress={() => {*/}
      {/*        // back button Logic*/}
      {/*    }}*/}
      {/*/>*/}
      {/*<Text color="coolGray.50" fontSize="lg">*/}
      {/*    OTP Verification*/}
      {/*</Text>*/}
    </HStack>
  );
}

function SideContainerWeb() {
  return (
    <Center
      flex='1'
      _light={{ bg: '#E0FFFF' }}
      _dark={{ bg: 'primary.700' }}
      px={{ base: '4', md: '8' }}
      borderTopLeftRadius={{ md: 'xl' }}
      borderBottomLeftRadius={{ md: 'xl' }}>
      <VStack style={{ alignItems: 'center' }}>
        <Image source={require('../../assets/logoApp.png')} height='400' width='400' alt='Alternate Text' />
        {/*<Text*/}
        {/*  fontSize='7xl'*/}
        {/*  fontWeight='bold'*/}
        {/*  color='white'*/}
        {/*  fontFamily='patrickhand_regular'*/}
        {/*>*/}
        {/*    TELEDOC*/}
        {/*</Text>*/}
      </VStack>
    </Center>
  );
}

interface IMainTextProps {
  email: string;
}

function MainText({ email }: IMainTextProps) {
  return (
    <VStack space={{ base: '3', md: '4' }}>
      <Text fontSize='5xl' fontWeight='bold' fontFamily='Patrickhand_Regular' _dark={{ color: '#66CDAA' }} _light={{ color: '#66CDAA' }}>
        Check Your Email
      </Text>
      <HStack space='2' style={{ alignItems: 'center' }} mt={5}>
        <Text _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.400' }} fontSize='md'>
          We have sent the OTP CODE confirm to
          <Text fontWeight='bold' _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.400' }} fontSize='lg'>
            {' ' + email}
          </Text>
        </Text>
      </HStack>
    </VStack>
  );
}

function AccountLink() {
  const navigation = useNavigation<NativeStackNavigationProp<AuthStackParamList, RootStackScreensEnum.OtpVerification>>();
  const onPressSignIn = () => {
    navigation.replace(RootStackScreensEnum.SignIn);
  };
  return (
    <HStack mt='28' space='1' safeAreaBottom style={{ alignItems: 'center' }} justifyContent='center'>
      <Text _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.400' }} fontSize='sm' fontWeight='normal'>
        Already have an account?
      </Text>

      <Pressable onPress={onPressSignIn}>
        <Text
          fontWeight='bold'
          textDecoration='none'
          fontSize='sm'
          _light={{
            color: '#66CDAA'
          }}
          _dark={{
            color: '#66CDAA'
          }}>
          Sign In
        </Text>
      </Pressable>
    </HStack>
  );
}

interface IResendLinkProps {
  email: string;
}
function ResendCode({ email }: IResendLinkProps) {
  const { errorMessage, successMessage, setErrorMessage, setSuccessMessage, resendSignUp } = useAuth();
  const [isResending, setIsResending] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timeLeft !== null && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTimeLeft) => (prevTimeLeft !== null ? prevTimeLeft - 1 : null));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timeLeft]);

  useEffect(() => {
    setTimeLeft(60);
    return () => {
      setErrorMessage('');
      setSuccessMessage('');
      setTimeLeft(null);
    };
  }, [setErrorMessage, setSuccessMessage]);

  const handleResendCode = async () => {
    if (isResending || timeLeft !== 0) return;
    setIsResending(true);
    try {
      await resendSignUp(email);
      setTimeLeft(60);
    } catch (error) {
      console.log('Error sending link', error);
    }
    setIsResending(false);
  };
  return (
    <VStack mt={10}>
      <HStack>
        <Text _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.400' }} fontSize='md'>
          Didn’t receive the code?{' '}
        </Text>
        <Pressable alignSelf='center' onPress={handleResendCode} disabled={isResending || timeLeft !== 0}>
          <Text _light={{ color: '#66CDAA' }} _dark={{ color: '#66CDAA' }} fontWeight='bold' textDecoration='none' fontSize='lg'>
            {isResending ? 'Resending...' : timeLeft !== null && timeLeft > 0 ? `Please retry after (${timeLeft}s)` : 'RESEND CODE'}
          </Text>
        </Pressable>
      </HStack>
      <Box alignSelf={'center'} mt={10}>
        {errorMessage ? <Text color='red.500'>{errorMessage}</Text> : null}
        {/*{successMessage ? <Text color='green.500'>{successMessage}</Text> : null}*/}
      </Box>
    </VStack>
  );
}

type IOtpVerificationProps = NativeStackScreenProps<AuthStackParamList, RootStackScreensEnum.OtpVerification>;
export default function OtpVerification({ route }: IOtpVerificationProps) {
  const { email } = route.params;
  return (
    <GuestLayout>
      <Hidden from='md'>
        <Header />
      </Hidden>

      <Hidden till='md'>
        <SideContainerWeb />
      </Hidden>
      <Box
        py={{ base: '8', md: '8' }}
        px={{ base: '4', md: '8' }}
        _light={{ bg: 'white' }}
        _dark={{ bg: 'coolGray.800' }}
        flex='1'
        borderTopRightRadius={{ md: 'xl' }}
        borderBottomRightRadius={{ md: 'xl' }}>
        <VStack justifyContent='space-between' flex='1' space='24'>
          <Box>
            <MainText email={email} />
            <VStack space={{ base: '12', md: '8' }} mt='6'>
              <FormControl>
                <PinInput email={email} />
                <FormControl.HelperText mt='8'>
                  <ResendCode email={email} />
                </FormControl.HelperText>
              </FormControl>
            </VStack>
          </Box>
          <AccountLink />
        </VStack>
      </Box>
    </GuestLayout>
  );
}
