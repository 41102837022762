import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Divider,
  Hidden,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu as MenuNB,
  Pressable,
  ScrollView,
  Spinner,
  Text,
  TextArea,
  View,
  VStack,
  Tooltip,
  Button,
  Popover,
  useClipboard
} from 'native-base';
import { AntDesign, Feather, Fontisto, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { IMessage } from '../../types/IMessage';
import {
  chatRoomActions,
  selectActiveChatRoom,
  selectActiveChatRoomNeedsScrollToBottom,
  selectAllMessages
} from '../../store/chat/chat-room/chat-room-slice';
import { useChat } from './ChatProvider';
import { Audio } from 'expo-av';
import axios from 'axios';
import { Animated } from 'react-native';
import AnimatedValue = Animated.AnimatedValue;
import { generate_uuid } from '../../utils/uuid_generator';
import * as ImagePicker from 'expo-image-picker';
import { Storage } from 'aws-amplify';
import { Menu, Item, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const imageUri = require('../../assets/images/robot.png');

// eslint-disable-next-line @typescript-eslint/no-var-requires
const avatarUri = {
  uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.40549212462773987.jpg'
};

function ChatHeader() {
  // const navigation = useNavigation<NativeStackNavigationProp<MainStackParamList, RootStackScreensEnum.ChatScreen>>();
  // const onPressBack = () => {
  //   navigation.navigate(RootStackScreensEnum.ChatList);
  // };
  const callAudio = async () => {
    // @ts-ignore
    // global.sfCallP2P('0102014', true);
    // setTimeout(() => {
    //   document.querySelector<HTMLElement>('flt-glass-pane')!.style.visibility = 'visible';
    // }, 2000);
  };

  const callVideo = async () => {
    // @ts-ignore
    // global.sfCallP2P('0102014', false);
    // setTimeout(() => {
    //   document.querySelector<HTMLElement>('flt-glass-pane')!.style.visibility = 'visible';
    // }, 2000);
  };

  return (
    <HStack borderTopRadius='sm' _light={{ bg: 'white' }} _dark={{ bg: 'coolGray.900' }} style={{ alignItems: 'center' }} space='3' px='4' py='2'>
      {/*<IconButton onPress={onPressBack} variant='unstyled' p='0' icon={<Icon size='6' as={AntDesign} name={'arrowleft'} color='coolGray.50' />} />*/}
      <Avatar source={imageUri} size='12' />
      <Box>
        <Text color='coolGray.900' fontSize='lg'>
          Medical assistant
        </Text>
        <Text fontSize='sm' _light={{ color: 'coolGray.700' }} _dark={{ color: 'coolGray.400' }}>
          {/*First 50 characters of the prompt*/}
          {/*{medicalAssistantPrompt.length > 50 ? medicalAssistantPrompt.substring(0, 50) + '...' : medicalAssistantPrompt}*/}
        </Text>
      </Box>
      <HStack style={{ alignItems: 'center' }} space='3' ml='auto' pr={1}>
        {/*<IconButton variant='unstyled' p='0' icon={<Icon size='6' as={MaterialIcons} name={'search'} color='coolGray.50' />} />*/}
        <Tooltip label='Start audio call'>
          <Pressable
            p={4}
            style={{ alignItems: 'center' }}
            justifyContent='center'
            size={'10'}
            rounded='full'
            _hover={{ bg: '#E4EBE4' }}
            _light={{ bg: 'white' }}
            _dark={{ bg: 'primary.500' }}
            onPress={() => callAudio()}>
            <Icon as={<MaterialIcons name='call' />} size={7} color='#0564C8' />
          </Pressable>
        </Tooltip>
        <Tooltip label='Start video call'>
          <Pressable
            p={4}
            style={{ alignItems: 'center' }}
            justifyContent='center'
            size={'10'}
            rounded='full'
            _hover={{ bg: '#E4EBE4' }}
            _light={{ bg: 'white' }}
            _dark={{ bg: 'primary.500' }}
            onPress={() => callVideo()}>
            <Icon as={<Feather name='video' />} size={7} color='#0564C8' />
          </Pressable>
        </Tooltip>
        <Tooltip label='Option'>
          <Pressable
            p={4}
            style={{ alignItems: 'center' }}
            justifyContent='center'
            size={'10'}
            rounded='full'
            _hover={{ bg: '#E4EBE4' }}
            _light={{ bg: 'white' }}
            _dark={{ bg: 'primary.500' }}>
            <Icon as={<MaterialIcons name='more-vert' />} size={7} color='#0564C8' />
          </Pressable>
        </Tooltip>
      </HStack>
    </HStack>
  );
}

type ChatItemProps = {
  message: IMessage;
  handleContextMenu: (event: any, message: IMessage) => void;
  onShowOriginal: (message: IMessage) => void;
};

function ChatItemComponent(props: ChatItemProps) {
  const { message, handleContextMenu, onShowOriginal } = props;
  const { user } = useAuth();
  const isMe = message.userID === user?.id;

  if (!message.id || (!message.text && !message.image)) {
    return <></>;
  }

  const getMessage = () => {
    if (message.translatedText && message.isTranslated) {
      return message.translatedText;
    } else {
      return message.text;
    }
  };

  const messageWithImage = () => {
    return (
      <>
        <Text
          fontSize='md'
          fontStyle={message.isTranslated ? 'italic' : 'normal'}
          _light={{
            color: !isMe ? 'black' : 'white'
          }}
          _dark={{
            color: !isMe ? 'coolGray.200' : 'coolGray.100'
          }}>
          {getMessage()}
        </Text>
        <Image src={message.image ?? ''} alt={'image'} w={40} h={40}></Image>
      </>
    );
  };

  return (
    <Box py={2} w={'100%'}>
      <HStack alignItems={'end'} flexDirection={!isMe ? 'row' : 'row-reverse'} w={'100%'}>
        <Box px={2}>
          <Image size='8' resizeMode='cover' alt='alternative text' borderRadius='full' source={!isMe ? imageUri : avatarUri} />
        </Box>
        <div onContextMenu={(event) => handleContextMenu(event, message)} style={{ maxWidth: '80%', minWidth: '224px' }}>
          <VStack
            borderRadius='16'
            pt='2'
            pb='1'
            px='5'
            space='0.5'
            _light={{
              bg: !isMe ? '#E4EBE4' : '#2563EB'
            }}
            _dark={{
              bg: !isMe ? 'coolGray.700' : 'coolGray.600'
            }}>
            {message.image ? (
              messageWithImage()
            ) : (
              <Text
                fontSize={{ base: 'md', md: 'lg' }}
                fontStyle={message.isTranslated ? 'italic' : 'normal'}
                _light={{
                  color: !isMe ? 'black' : 'white'
                }}
                _dark={{
                  color: !isMe ? 'coolGray.200' : 'coolGray.100'
                }}>
                {getMessage()}
              </Text>
            )}
            {message.isTranslated && (
              <HStack justifyContent='flex-end' style={{ alignItems: 'center' }}>
                <Pressable onPress={() => onShowOriginal(message)}>
                  <Text
                    fontSize='xs'
                    _light={{
                      color: !isMe ? '#2563EB' : 'coolGray.50'
                    }}
                    _dark={{
                      color: !isMe ? '#2563EB' : 'coolGray.50'
                    }}>
                    Show original
                  </Text>
                </Pressable>
              </HStack>
            )}
            <HStack justifyContent='flex-end' style={{ alignItems: 'center' }}>
              <Text
                fontSize='xs'
                _light={{
                  color: !isMe ? 'coolGray.700' : 'coolGray.50'
                }}
                _dark={{
                  color: !isMe ? 'coolGray.100' : 'coolGray.50'
                }}>
                {message.createdAt !== '' && formatDistanceToNow(new Date(message.createdAt), { addSuffix: true })}
              </Text>
            </HStack>
          </VStack>
        </div>
      </HStack>
    </Box>
  );
}

type SoundWaveAnimationProps = {
  cancelRecording: () => void;
  sendRecording: () => void;
  isRecording: boolean;
  toggleRecording: (isRecord: boolean) => void;
  isToggling: boolean;
  isSending: boolean;
};

const SoundWaveAnimation = (props: SoundWaveAnimationProps) => {
  const { cancelRecording, sendRecording, isRecording, toggleRecording, isToggling, isSending = false } = props;
  const wave1 = useRef(new Animated.Value(0)).current;
  const wave2 = useRef(new Animated.Value(0)).current;
  const wave3 = useRef(new Animated.Value(0)).current;
  const wave4 = useRef(new Animated.Value(0)).current;
  const [waveArray, setWaveArray] = useState<string[]>([]);

  useEffect(() => {
    setWaveArray(new Array(12).fill(''));
  }, []);

  useEffect(() => {
    const animateWave = (wave: AnimatedValue, duration = 0) => {
      Animated.loop(
        Animated.sequence([
          Animated.timing(wave, {
            toValue: 1,
            duration: 700 + duration,
            useNativeDriver: true
          }),
          Animated.timing(wave, {
            toValue: 0,
            duration: 700 + duration,
            useNativeDriver: true
          })
        ])
      ).start();
    };

    animateWave(wave1);
    animateWave(wave2, 100);
    animateWave(wave3, 200);
    animateWave(wave4, 500);
  }, [wave1, wave2, wave3, wave4, isRecording]);

  const waveStyle = {
    backgroundColor: '#0564C8',
    borderRadius: 10,
    height: 30,
    width: '4%',
    marginHorizontal: '1%',
    transform: [{ scaleY: wave1 }]
  };

  const waveStyle1 = {
    backgroundColor: '#0564C8',
    borderRadius: 10,
    height: 30,
    width: '4%',
    marginHorizontal: '1%',
    transform: [{ scaleY: wave2 }]
  };

  const waveStyle2 = {
    backgroundColor: '#0564C8',
    borderRadius: 10,
    height: 30,
    width: '4%',
    marginHorizontal: '1%',
    transform: [{ scaleY: wave3 }]
  };

  const waveStyle3 = {
    backgroundColor: '#0564C8',
    borderRadius: 10,
    height: 30,
    width: '4%',
    marginHorizontal: '1%',
    transform: [{ scaleY: wave4 }]
  };

  return (
    <VStack space={3}>
      <HStack justifyContent={'right'} style={{ alignItems: 'center' }}>
        <Pressable
          isDisabled={isSending}
          rounded='full'
          _hover={{ bg: '#E4EBE4' }}
          p={1}
          style={{ alignItems: 'center' }}
          justifyContent='center'
          onPress={() => {
            cancelRecording();
          }}>
          <Icon as={<MaterialCommunityIcons name='close' />} size={4} color={'black'} />
        </Pressable>
      </HStack>
      <HStack justifyContent={'center'} style={{ alignItems: 'center' }}>
        <Tooltip label={isRecording ? 'Stop' : 'Record'}>
          <Pressable
            isDisabled={isSending}
            p={2}
            style={{ alignItems: 'center' }}
            justifyContent='center'
            rounded='full'
            _hover={{ bg: '#E4EBE4' }}
            _light={{ bg: isRecording ? '#ffdad8' : 'white' }}
            _dark={{ bg: 'primary.500' }}
            onPress={() => toggleRecording(isRecording)}>
            {isToggling ? (
              <Spinner></Spinner>
            ) : (
              <Icon as={<Ionicons name={isRecording ? 'stop' : 'mic-outline'} />} size={5} color={isRecording ? '#ff4444' : 'coolGray.900'} />
            )}
          </Pressable>
        </Tooltip>
        {isRecording ? (
          <HStack flex={1} justifyContent={'center'} style={{ alignItems: 'center' }}>
            <Animated.View style={waveStyle2} />
            <Animated.View style={waveStyle} />
            <Animated.View style={waveStyle2} />
            <Animated.View style={waveStyle1} />
            <Animated.View style={waveStyle} />
            <Animated.View style={waveStyle3} />
            <Animated.View style={waveStyle2} />
            <Animated.View style={waveStyle3} />
            <Animated.View style={waveStyle1} />
            <Animated.View style={waveStyle1} />
            <Animated.View style={waveStyle} />
            <Animated.View style={waveStyle3} />
          </HStack>
        ) : (
          <HStack flex={1} justifyContent={'center'} style={{ alignItems: 'center' }}>
            {waveArray.map((item, index) => {
              return (
                <View
                  key={index}
                  style={{
                    backgroundColor: '#9ca3af',
                    borderRadius: 10,
                    height: 30,
                    width: '4%',
                    marginHorizontal: '1%'
                  }}></View>
              );
            })}
          </HStack>
        )}
      </HStack>
      <HStack flex={1} justifyContent={'right'} style={{ alignItems: 'center' }}>
        <Button bgColor={'#0564C8'} py={1} w={16} onPress={sendRecording}>
          {isSending ? <Spinner color={'white'}></Spinner> : <Text color={'white'}>Send</Text>}
        </Button>
      </HStack>
    </VStack>
  );
};

type ModalRecordButtonProps = {
  convertRecordingToText: (text: string) => void;
};

const ModalRecordButton = (props: ModalRecordButtonProps) => {
  const audioRecorder = useRef(new Audio.Recording());
  const [audioPermission, setAudioPermission] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isClickBackDrop, setIsClickBackDrop] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isToggling, setIsToggling] = useState(false);
  const [audioBlobUri, setAudioBlobUri] = useState<string | null>('');
  const fileName = generate_uuid();

  // Initial Load to get the audio permission
  useEffect(() => {
    getPermission();
  }, []);

  useEffect(() => {
    (async () => {
      if (audioBlobUri && isSending) {
        const response = await fetch(audioBlobUri);
        const blob = await response.blob();
        const fileOfBlob = new File([blob], `audio-${fileName}`);
        const formData = new FormData();
        formData.append('audio_data', fileOfBlob);
        await axios
          .post('https://meeting.sfvmeet.com/transcribe', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            timeout: 10000
          })
          .then((res) => {
            if (!res?.data) {
              setIsSending(false);
              return;
            }
            const messageText = res.data.text.trim();
            if (!messageText) {
              setIsSending(false);
              return;
            }
            props.convertRecordingToText(messageText);
            setIsSending(false);
          })
          .catch((err) => {
            console.log(err);
            setIsSending(false);
          });
        setIsOpen(false);
        resetAudioRecorder();
      }
    })();
  }, [isSending, audioBlobUri]);

  // Function to get the audio permission
  const getPermission = async () => {
    const getAudioPerm = await Audio.requestPermissionsAsync();
    setAudioPermission(getAudioPerm.granted);
  };

  // Function to start recording
  const startRecording = async () => {
    setIsOpen(true);
    setAudioBlobUri('');
    try {
      // Check if user has given the permission to record
      if (audioPermission) {
        try {
          // Prepare the Audio Recorder
          await audioRecorder.current.prepareToRecordAsync(Audio.RecordingOptionsPresets.HIGH_QUALITY);

          // Start recording
          await audioRecorder.current.startAsync();
        } catch (error) {
          console.log(error);
        }
      } else {
        // If user has not given the permission to record, then ask for permission
        getPermission();
      }
      setIsRecording(true);
      setIsToggling(false);
    } catch (error) {
      setIsRecording(false);
      /* empty */
    }
  };

  // Reset the Audio Recorder
  const resetAudioRecorder = () => {
    audioRecorder.current = new Audio.Recording();
  };

  // Function to stop recording
  const stopRecording = async () => {
    try {
      // Stop recording
      await audioRecorder.current.stopAndUnloadAsync();

      // Get the recorded URI here
      const result = audioRecorder.current.getURI();
      setAudioBlobUri(result);
      resetAudioRecorder();
      setIsRecording(false);
      setIsToggling(false);
    } catch (error) {
      resetAudioRecorder();
      setIsRecording(false);
      setIsToggling(false);
      /* empty */
    }
  };

  const cancelRecording = () => {
    stopRecording();
    setIsOpen(false);
  };

  const sendRecording = () => {
    stopRecording();
    setIsSending(true);
  };

  const toggleRecording = (isRecord: boolean) => {
    setIsToggling(true);
    if (isRecord) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const changeBorderStyle = (isArrow = false) => {
    if (!isClickBackDrop) {
      return {
        borderColor: '#D4D4D4'
      };
    }
    return isArrow
      ? {
          boxShadow: '4px 4px 0 0 #5AC8FA'
        }
      : {
          boxShadow: '0 0 0 4px #5AC8FA'
        };
  };

  return (
    <Popover
      trigger={(triggerProps) => {
        return (
          <Tooltip label='Send audio clip'>
            <Pressable
              {...triggerProps}
              size={12}
              style={{ alignItems: 'center' }}
              justifyContent='center'
              rounded='full'
              _hover={{ bg: '#E4EBE4' }}
              _light={{ bg: 'white' }}
              _dark={{ bg: 'primary.500' }}
              onPress={startRecording}>
              <Icon as={<Ionicons name='mic-outline' />} size={8} color={'coolGray.900'} />
            </Pressable>
          </Tooltip>
        );
      }}
      placement={'top right'}
      isOpen={isOpen}
      onClose={() => {
        setIsClickBackDrop(true);
        setTimeout(() => {
          setIsClickBackDrop(false);
        }, 2000);
      }}>
      <Popover.Content style={changeBorderStyle()} accessibilityLabel='Record' w='72'>
        <Popover.Arrow style={changeBorderStyle(true)}></Popover.Arrow>
        <Popover.Body>
          <SoundWaveAnimation
            cancelRecording={cancelRecording}
            sendRecording={sendRecording}
            isRecording={isRecording}
            toggleRecording={toggleRecording}
            isToggling={isToggling}
            isSending={isSending}
          />
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};

type ContextMenuProps = {
  message: IMessage;
};

type HandleItemClickedProps = {
  id?: string;
  event?: any;
  props?: ContextMenuProps;
  data?: any;
  triggerEvent?: any;
};

function MainContent() {
  const messages = useAppSelector(selectAllMessages);
  const scrollViewRef = React.useRef<MutableRefObject<any>>();
  const refInput = React.useRef<any>();
  const { sendMessage, waitingForResponse, sendMessageWithImage } = useChat();
  const [imageBase64, setImageBase64] = useState<string>('');
  const [blobImage, setBlobImage] = useState<Blob>();
  const activeChatRoom = useAppSelector(selectActiveChatRoom);
  const [isVicunaModel, setIsVicunaModel] = useState(false);
  const needsScrollToBottom = useAppSelector(selectActiveChatRoomNeedsScrollToBottom);
  const { onCopy } = useClipboard();
  const dispatch = useAppDispatch();

  const MENU_ID = 'menu';
  const { show } = useContextMenu({
    id: MENU_ID
  });

  const handleContextMenu = (event: any, message: IMessage) => {
    show({
      event,
      props: {
        message
      }
    });
  };

  const handleItemClick = async ({ id, event, props }: HandleItemClickedProps) => {
    switch (id) {
      case 'copy':
        if (!props?.message) break;
        if (props.message.isTranslated) {
          await onCopy(props.message.translatedText ?? '');
          break;
        }
        await onCopy(props.message.text ?? '');
        break;
      case 'translate':
        if (!props?.message) break;
        dispatch(chatRoomActions.updateMessage({ id: props.message.id, changes: { isTranslated: true } }));
        dispatch(chatRoomActions.setNeedsScrollToBottom(false));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (activeChatRoom?.chatRoomName.includes('Vicuna')) {
      setIsVicunaModel(true);
    } else {
      setIsVicunaModel(false);
    }
  }, [activeChatRoom]);

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    } else if (e.key === 'Enter' && e.shiftKey) {
      // Shift + Enter
      refInput.current += '\n';
    }
  };

  const handleSend = async () => {
    if (waitingForResponse) {
      return;
    }

    const messageText: string = refInput?.current?.value?.trim();
    if (!messageText && !imageBase64) {
      return;
    }
    if (isVicunaModel) {
      try {
        let awsImageUri = '';
        let imgStr = '';
        let messageTextWithImage = messageText;
        if (imageBase64) {
          const res = await Storage.put(`my-chat-image-filename${generate_uuid()}.jpg`, blobImage, {
            level: 'public',
            contentType: 'image/jpeg',
            progressCallback(uploadProgress) {
              console.log('PROGRESS--', uploadProgress.loaded + '/' + uploadProgress.total);
            }
          });
          const result = await Storage.get(res.key);
          awsImageUri = result.substring(0, result.indexOf('?'));
          imgStr = imageBase64.split(';base64,').pop() ?? '';
          messageTextWithImage = messageTextWithImage.concat(` \n<img src="data:image/jpeg;base64,${imgStr}">`);
        }
        setImageBase64('');
        sendMessageWithImage(messageTextWithImage, awsImageUri);
      } catch (e) {
        console.log(e);
      }
    } else {
      sendMessage(messageText);
    }
    // @ts-ignore
    refInput.current?.clear();
    // @ts-ignore
    refInput.current?.focus();
  };

  const convertRecordingToText = (text: string) => {
    refInput.current.value = text;
  };
  const handleValueChange = (text: string) => {
    refInput.current.value = text;
  };

  const fetchImageUri = async (uri: string) => {
    const respone = await fetch(uri);
    return await respone.blob();
  };

  useEffect(() => {
    (async () => {
      if (!imageBase64) {
        return;
      }
      const blob = await fetchImageUri(imageBase64);
      setBlobImage(blob);
    })();
  }, [imageBase64]);

  const openImagePickerAsync = useCallback(async () => {
    const pickerResult = await ImagePicker.launchImageLibraryAsync();
    if (pickerResult.canceled || !pickerResult.assets[0].uri) {
      return;
    }
    setImageBase64(pickerResult.assets[0].uri);
  }, []);

  const onShowOriginal = (message: IMessage) => {
    dispatch(chatRoomActions.updateMessage({ id: message.id, changes: { isTranslated: false } }));
    dispatch(chatRoomActions.setNeedsScrollToBottom(false));
  };

  return (
    <>
      {messages?.length && messages.length > 0 ? (
        <Box flex='1' _light={{ bg: 'white' }} _dark={{ bg: 'coolGray.800' }} rounded={{ md: 'sm' }} h={'100%'}>
          <Hidden till='md'>
            <ChatHeader />
          </Hidden>
          <Divider />

          <Menu id={MENU_ID}>
            <Item id='copy' onClick={handleItemClick}>
              Copy
            </Item>
            <Item id='translate' onClick={handleItemClick}>
              Translate
            </Item>
          </Menu>

          <VStack
            flex={1}
            px={{ base: 4, md: 4 }}
            // py={{ base: 4, md: 10 }}
            _light={{
              bg: { md: 'white' }
            }}
            _dark={{
              bg: { md: 'coolGray.800' }
            }}
            space={{ base: 2, md: 3 }}>
            {messages?.length && messages.length > 0 ? (
              <ScrollView
                ref={scrollViewRef}
                onContentSizeChange={() => {
                  if (needsScrollToBottom) {
                    // @ts-ignore
                    scrollViewRef.current?.scrollToEnd({ animated: true });
                  } else {
                    setTimeout(() => {
                      dispatch(chatRoomActions.setNeedsScrollToBottom(true));
                    }, 1000);
                  }
                }}
                style={{ height: 550 }}
                showsVerticalScrollIndicator={false}>
                {messages.map((item, index) => {
                  return item ? (
                    <ChatItemComponent message={item} key={item?.id} handleContextMenu={handleContextMenu} onShowOriginal={onShowOriginal} />
                  ) : (
                    <></>
                  );
                })}
              </ScrollView>
            ) : (
              <></>
            )}
          </VStack>

          <Box w='100%' borderBottomRadius={{ md: 4 }} _light={{ bg: 'white' }} _dark={{ bg: 'coolGray.800' }}>
            <Divider />
            {imageBase64 !== '' && (
              <Box px={8} py={4} ml={1}>
                <Box w={20} h={20} position={'relative'}>
                  <Pressable
                    position={'absolute'}
                    right={-8}
                    top={-8}
                    zIndex={10}
                    p={1}
                    style={{ alignItems: 'center' }}
                    justifyContent='center'
                    size={5}
                    rounded='full'
                    _hover={{ bg: '#E4EBE4' }}
                    _light={{ bg: 'coolGray.300' }}
                    _dark={{ bg: 'primary.500' }}
                    onPress={() => setImageBase64('')}>
                    <Icon as={<MaterialIcons name='close' />} size={4} color='coolGray.900' />
                  </Pressable>
                  <Image src={imageBase64} alt={'preview-image'} w={'full'} h={'full'}></Image>
                </Box>
              </Box>
            )}
            <HStack style={{ alignItems: 'center' }} space={1} py='4' px={{ base: 4, md: 8 }}>
              {/*<Hidden till='md'>*/}
              {/*  <HStack style={{ alignItems: 'center' }} space='3' justifyContent={'space-between'}>*/}
              {/*<Tooltip label='Choose icon'>*/}
              {/*  <Pressable p={4} style={{ alignItems: 'center' }} justifyContent='center' size={'6'} rounded='full' _hover={{ bg: '#E4EBE4'}} _light={{ bg: 'white' }} _dark={{ bg: 'primary.500' }}>*/}
              {/*    <Icon as={<Fontisto name='smiley' />} size={5} color='coolGray.700' />*/}
              {/*  </Pressable>*/}
              {/*</Tooltip>*/}
              {/*<Tooltip label='Send picture'>*/}
              {/*  <Pressable p={4} style={{ alignItems: 'center' }} justifyContent='center' size={'6'} rounded='full' _hover={{ bg: '#E4EBE4'}} _light={{ bg: 'white' }} _dark={{ bg: 'primary.500' }}>*/}
              {/*    <Icon as={<MaterialCommunityIcons name='camera-outline' />} size={6} color='coolGray.700' />*/}
              {/*  </Pressable>*/}
              {/*</Tooltip>*/}
              {/*<Tooltip label='Attach file'>*/}
              {/*  <Pressable p={4} style={{ alignItems: 'center' }} justifyContent='center' size={'6'} rounded='full' _hover={{ bg: '#E4EBE4'}} _light={{ bg: 'white' }} _dark={{ bg: 'primary.500' }}>*/}
              {/*    <Icon as={<MaterialIcons name='attach-file' />} size={5} color='coolGray.700' />*/}
              {/*  </Pressable>*/}
              {/*</Tooltip>*/}
              {/*  </HStack>*/}
              {/*</Hidden>*/}
              <Hidden from='md'>
                <MenuNB
                  p='0'
                  trigger={(triggerProps) => {
                    return (
                      <IconButton
                        variant='ghost'
                        p='0'
                        {...triggerProps}
                        icon={<Icon size='6' color='coolGray.700' name='pluscircle' as={AntDesign} />}
                      />
                    );
                  }}
                  placement='top left'>
                  <MenuNB.Item _dark={{ bg: 'coolGray.900' }}>
                    <HStack space='2' style={{ alignItems: 'center' }}>
                      <IconButton p={0} variant='unstyled' icon={<Icon color='coolGray.700' as={MaterialIcons} name='attach-file' size='6' />} />
                      <Text bold={true}>Attach File</Text>
                    </HStack>
                  </MenuNB.Item>
                  <MenuNB.Item _dark={{ bg: 'coolGray.900' }}>
                    <HStack space='2' style={{ alignItems: 'center' }}>
                      <IconButton
                        p={0}
                        variant='unstyled'
                        icon={<Icon color='coolGray.700' as={MaterialCommunityIcons} name='camera-outline' size='6' />}
                      />
                      <Text bold={true}>Camera</Text>
                    </HStack>
                  </MenuNB.Item>
                  <MenuNB.Item _dark={{ bg: 'coolGray.900' }}>
                    <HStack space='2' style={{ alignItems: 'center' }}>
                      <IconButton p={0.5} variant='unstyled' icon={<Icon color='coolGray.700' as={Fontisto} name='smiley' size='5' />} />
                      <Text bold={true}>Emotion</Text>
                    </HStack>
                  </MenuNB.Item>
                </MenuNB>
              </Hidden>
              {/*TODO (MinhLuan): handle enter key and shift + enter key*/}
              <TextArea
                multiline={true}
                h={{ base: 10, md: 12 }}
                scrollEnabled={false}
                flex={1}
                autoCompleteType='off'
                _android={{ py: '0.5', px: '4' }}
                py='2'
                px='4'
                size='xl'
                variant='outline'
                rounded='md'
                placeholder='Type a message'
                placeholderTextColor='coolGray.400'
                ref={refInput}
                onChangeText={(value) => handleValueChange(value)}
                onKeyPress={handleKeyPress}
                _light={{
                  bg: 'coolGray.100',
                  borderColor: 'white',
                  color: 'black'
                }}
                _dark={{
                  bg: 'coolGray.700',
                  borderColor: 'coolGray.800',
                  color: 'white'
                }}
              />

              {isVicunaModel && (
                <HStack style={{ alignItems: 'center' }}>
                  <Tooltip label='Attach file'>
                    <Pressable
                      p={4}
                      style={{ alignItems: 'center' }}
                      justifyContent='center'
                      size={12}
                      rounded='full'
                      _hover={{ bg: '#E4EBE4' }}
                      _light={{ bg: 'white' }}
                      _dark={{ bg: 'primary.500' }}
                      onPress={openImagePickerAsync}>
                      <Icon as={<AntDesign name='picture' />} size={7} color='coolGray.700' />
                    </Pressable>
                  </Tooltip>
                </HStack>
              )}

              <HStack style={{ alignItems: 'center' }}>
                <ModalRecordButton convertRecordingToText={convertRecordingToText} />
              </HStack>

              <HStack style={{ alignItems: 'center' }}>
                <Tooltip label='Press enter to send'>
                  <Pressable
                    isDisabled={waitingForResponse}
                    onPress={handleSend}
                    size={12}
                    style={{ alignItems: 'center' }}
                    justifyContent='center'
                    rounded='full'
                    _hover={{ bg: '#E4EBE4' }}
                    _light={{ bg: 'white' }}
                    _dark={{ bg: 'primary.500' }}>
                    {waitingForResponse ? (
                      <Spinner accessibilityLabel='Waiting for response' />
                    ) : (
                      <Icon as={<MaterialCommunityIcons name='send' />} size={7} color='#0564C8' />
                    )}
                  </Pressable>
                </Tooltip>
              </HStack>
            </HStack>
          </Box>
        </Box>
      ) : (
        <Box
          justifyContent={'center'}
          style={{ alignItems: 'center' }}
          flex='1'
          _light={{ bg: 'white' }}
          _dark={{ bg: 'coolGray.800' }}
          rounded={{ md: 'sm' }}>
          <Spinner size={36}></Spinner>
        </Box>
      )}
    </>
  );
}

const ChatScreen = () => {
  const { user } = useAuth();

  if (!user) {
    // Spinner
    return (
      <Box flex='1' _light={{ bg: 'white' }} _dark={{ bg: 'coolGray.800' }} rounded={{ md: 'sm' }}>
        <Spinner accessibilityLabel='User is not logged in' />
      </Box>
    );
  }

  return <View h={'100%'}>{<MainContent />}</View>;
};

export default ChatScreen;
