import React, { useEffect, useState } from 'react';
import { Avatar, Box, Divider, FlatList, Hidden, HStack, Icon, Image, Input, Pressable, ScrollView, Text, Tooltip, VStack } from 'native-base';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import DashboardLayout from '../../layouts/DashboardLayout';
import { RootStackScreensEnum } from '../../navigation/enum';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { AdminStackParamList, MainStackParamList } from '../../types/Stacks';
import { useAppSelector } from '../../store/hooks';
import { selectAllPatients } from '../../store/hospital/patients/patients-slice';
import { useDebounce } from '../../utils/debounce';
import { selectAllUsers } from '../../store/hospital/users/users-slice';
import { IUser } from '../../types/IUser';
import { IPatient } from '../../types/IPatient';
import { formatDate } from '../../utils/time_utils';
import SearchBarWeb from './SearchBarWeb';

interface IMyComponentProps {
  data: IPatient[];
}

const MyComponent = ({ data }: IMyComponentProps) => {
  const navigation = useNavigation<NativeStackNavigationProp<AdminStackParamList, RootStackScreensEnum.PatientList>>();
  const users = useAppSelector((state) => selectAllUsers(state.hospital.users));

  return (
    <>
      {/*Show on Web browser*/}
      <Hidden till={'md'}>
        <Box backgroundColor={'white'} mx={5}>
          <FlatList
            data={data}
            keyExtractor={(item: IPatient) => item.id.toString()}
            renderItem={({ item, index }) => {
              const patientUser: IUser | undefined = users.find((user: IUser) => user.id === item.patientUserId);
              return (
                <Pressable
                  _hover={{ bg: '#E4EBE4' }}
                  flex={1}
                  key={item.id.toString()}
                  onPress={() => navigation.navigate(RootStackScreensEnum.PatientDetails, { patientId: item.id })}>
                  <HStack
                    px={6}
                    py={4}
                    space={2}
                    borderBottomWidth={1}
                    alignItems='center'
                    _light={{ borderBottomColor: 'coolGray.200' }}
                    _dark={{ borderBottomColor: 'coolGray.700' }}>
                    <Box w={'3%'}>
                      <Avatar source={patientUser?.imageUrl ? { uri: patientUser?.imageUrl } : undefined} height={10} width={10}>
                        {patientUser?.lastName.charAt(0).toUpperCase()}
                      </Avatar>
                    </Box>
                    <Text
                      w='6%'
                      fontWeight='medium'
                      fontSize='md'
                      lineHeight='21'
                      _light={{ color: 'coolGray.500' }}
                      _dark={{ color: 'coolGray.400' }}
                      numberOfLines={1}>
                      {(index + 1).toString().padStart(3, '0')}
                    </Text>
                    <Text
                      fontWeight='medium'
                      fontSize='md'
                      lineHeight='21'
                      _light={{ color: 'coolGray.500' }}
                      _dark={{ color: 'coolGray.400' }}
                      w='10%'>
                      {item?.status ? item.status.replace('_', ' ') : '---'}
                    </Text>
                    <Text
                      fontWeight='medium'
                      fontSize='md'
                      lineHeight='21'
                      _light={{ color: 'coolGray.500' }}
                      _dark={{ color: 'coolGray.400' }}
                      w='20%'>
                      {patientUser?.email}
                    </Text>
                    <Text
                      fontWeight='medium'
                      fontSize='md'
                      lineHeight='21'
                      _light={{ color: 'coolGray.500' }}
                      _dark={{ color: 'coolGray.400' }}
                      w='16%'>
                      {patientUser?.firstName + ' ' + patientUser?.lastName}
                    </Text>
                    <Text
                      fontWeight='medium'
                      fontSize='md'
                      lineHeight='21'
                      _light={{ color: 'coolGray.500' }}
                      _dark={{ color: 'coolGray.400' }}
                      w='16%'>
                      {item?.checkInTime ? formatDate(item.checkInTime) : '---'}
                    </Text>
                    <Text
                      fontWeight='medium'
                      fontSize='md'
                      lineHeight='21'
                      _light={{ color: 'coolGray.500' }}
                      _dark={{ color: 'coolGray.400' }}
                      w='16%'>
                      {item?.doctorID ?? '---'}
                    </Text>
                    <HStack justifyContent={'space-between'} w={'4%'}>
                      <Pressable
                        p={2}
                        alignItems='center'
                        justifyContent='center'
                        _hover={{ bg: '#E4EBE4' }}
                        // _light={{ bg: 'white' }}
                        _dark={{ bg: 'primary.500' }}>
                        <Icon size='6' _light={{ color: '#0564C8' }} _dark={{ color: 'secondary.500' }} name={'chatbox'} as={Ionicons} />
                      </Pressable>
                      <Pressable
                        p={2}
                        alignItems='center'
                        justifyContent='center'
                        _hover={{ bg: '#E4EBE4' }}
                        // _light={{ bg: 'white' }}
                        _dark={{ bg: 'primary.500' }}>
                        <Icon size='6' _light={{ color: '#0564C8' }} _dark={{ color: 'secondary.500' }} name={'call'} as={Ionicons} />
                      </Pressable>
                    </HStack>
                  </HStack>
                </Pressable>
              );
            }}
          />
        </Box>
      </Hidden>
      {/*Show on Mobile*/}
      <Hidden from={'md'}>
        <Box backgroundColor={'#fff'} width={'100%'} rounded={'sm'} py={4}>
          <FlatList
            w={'95%'}
            m={'auto'}
            data={data}
            keyExtractor={(item: IPatient) => item.id.toString()}
            renderItem={({ item }) => {
              const patientUser: IUser | undefined = users.find((user: IUser) => user.id === item.patientUserId);
              return (
                <>
                  <Pressable
                    shadow={3}
                    borderWidth={1}
                    borderColor={'coolGray.200'}
                    rounded={'xl'}
                    flex={1}
                    mb={3}
                    p={3}
                    key={item.id.toString()}
                    onPress={() => navigation.navigate(RootStackScreensEnum.PatientDetails, { patientId: item.id })}>
                    <HStack px={2} alignItems='Center'>
                      <Box ml={2} backgroundColor={'#fff'}>
                        <Image
                          source={patientUser?.imageUrl ? { uri: patientUser?.imageUrl } : undefined}
                          rounded={'md'}
                          size={20}
                          accessibilityLabel={patientUser?.lastName.charAt(0).toUpperCase()}
                          alt={patientUser?.lastName.charAt(0).toUpperCase()}
                        />
                      </Box>
                      <VStack
                        ml={4}
                        space={2}
                        alignItems='start'
                        _light={{ borderBottomColor: 'coolGray.200' }}
                        _dark={{ borderBottomColor: 'coolGray.700' }}>
                        <Text fontWeight='bold' fontSize='16' lineHeight='21' _light={{ color: 'coolGray.900' }} _dark={{ color: 'coolGray.400' }}>
                          {patientUser?.firstName + ' ' + patientUser?.lastName}
                        </Text>
                        <HStack>
                          <Text
                            fontWeight='medium'
                            fontSize='14'
                            lineHeight='21'
                            _light={{ color: 'coolGray.500' }}
                            _dark={{ color: 'coolGray.400' }}>
                            Status:{' '}
                          </Text>
                          <Text fontSize='14' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }}>
                            {item?.status ? item.status.replace('_', ' ') : '---'}
                          </Text>
                        </HStack>
                        <HStack>
                          <Text
                            fontWeight='medium'
                            fontSize='14'
                            lineHeight='21'
                            _light={{ color: 'coolGray.500' }}
                            _dark={{ color: 'coolGray.400' }}>
                            Email:{' '}
                          </Text>
                          <Text fontSize='14' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }}>
                            {patientUser && patientUser.email && patientUser.email.length > 20
                              ? `${patientUser.email.slice(0, 20)}...`
                              : patientUser?.email}
                          </Text>
                        </HStack>
                      </VStack>
                    </HStack>
                  </Pressable>
                </>
              );
            }}
          />
        </Box>
      </Hidden>
    </>
  );
};
export default function PatientList() {
  const patients = useAppSelector((state) => selectAllPatients(state.hospital.patients));
  const users = useAppSelector((state) => selectAllUsers(state.hospital.users));
  const [textInput, setTextInput] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<IPatient[]>([]);
  const textSearch = useDebounce(textInput, 1000);
  const handleSearchTextChange = (text: string) => {
    setTextInput(text);
  };

  useEffect(() => {
    const searchValue = textInput.toLowerCase();
    let arr: IPatient[] = [];
    if (patients.length === 0 || users.length === 0) {
      return setSearchResults([]);
    }
    arr = patients.filter((item) => {
      // Find the user by firstName + lastName
      const foundNameUsers = users.filter((user: IUser) => {
        const fullName = user.firstName + ' ' + user.lastName;
        return fullName.toLowerCase().includes(searchValue);
      });

      // Find the user by email
      const foundEmailUsers = users.filter((user: IUser) => {
        return user.email.toLowerCase().includes(searchValue);
      });

      // Return the list of patients that has patientUserId in the list of found users
      return foundNameUsers.some((user) => user.id === item.patientUserId) || foundEmailUsers.some((user) => user.id === item.patientUserId);
    });
    setSearchResults(arr);
  }, [textSearch, patients, users]);

  return (
    <>
      <DashboardLayout title='' displayMenuButton displayScreenTitle={false} displayAlternateMobileHeader rightPanelMobileHeader={true}>
        <ContactData
          textInput={textInput}
          setTextInput={setTextInput}
          // searchTextRef={searchTextRef}
          handleSearchTextChange={handleSearchTextChange}
        />
        <ScrollView flex={1} showsHorizontalScrollIndicator={false}>
          <MyComponent data={searchResults || []} />
        </ScrollView>
      </DashboardLayout>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const ContactData = ({ handleSearchTextChange = () => {}, textInput, setTextInput }: any) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const handleToggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    setTextInput('');
  };

  const navigation = useNavigation<NativeStackNavigationProp<MainStackParamList, RootStackScreensEnum.Admin>>();
  return (
    <>
      <Hidden till={'md'}>
        <>
          <HStack mt={{ base: 5, md: 5 }} w={{ base: '100%', md: '50%' }} alignItems={'center'}>
            <Text ml={5} mb={5} fontSize={'2xl'} fontWeight={'bold'}>
              Patients List
            </Text>
          </HStack>
          <VStack>
            <HStack mb={4} ml={5} mr={{ base: '5', md: 0 }} flexWrap={'wrap'} flex={1} justifyContent='space-between'>
              <Input
                rounded={'xl'}
                value={textInput}
                onChangeText={handleSearchTextChange}
                py={2}
                pl={3}
                _light={{
                  bg: 'white',
                  borderColor: 'coolGray.300'
                }}
                _dark={{
                  bg: 'coolGray.800',
                  borderColor: 'coolGray.500'
                }}
                h={{ base: '10', md: '10' }}
                w={{ base: '100%', md: '15%' }}
                InputLeftElement={
                  <Icon
                    as={<MaterialIcons name='search' />}
                    size={6}
                    ml={3}
                    _light={{
                      color: 'coolGray.400'
                    }}
                    _dark={{
                      color: 'coolGray.300'
                    }}
                  />
                }
                placeholder='Search'
              />
            </HStack>
            <VStack
              mx={5}
              flex={1}
              _light={{
                bg: 'white'
              }}
              _dark={{
                bg: 'coolGray.800'
              }}
              divider={<Divider />}
              borderRadius={{ md: 'sm' }}>
              <HStack alignItems='center' px='6' pt='6' pb='4' space={2} rounded={'md'}>
                <Text fontWeight='bold' fontSize='md' textAlign='left' color='coolGray.500' lineHeight='17.5' w='3%'></Text>
                <Text fontWeight='bold' fontSize='md' textAlign='left' color='coolGray.500' lineHeight='17.5' w='6%'>
                  ID
                </Text>
                <Text fontWeight='bold' fontSize='md' textAlign='left' color='coolGray.500' lineHeight='17.5' w='10%'>
                  Status
                </Text>
                <Text fontWeight='bold' fontSize='md' textAlign='left' color='coolGray.500' lineHeight='17.5' w='20%'>
                  Email
                </Text>
                <Text fontWeight='bold' fontSize='md' textAlign='left' color='coolGray.500' lineHeight='17.5' w='16%'>
                  Name
                </Text>
                <Text fontWeight='bold' fontSize='md' textAlign='left' color='coolGray.500' lineHeight='17.5' w='16%'>
                  Date check in
                </Text>
                <Text fontWeight='bold' fontSize='md' textAlign='left' color='coolGray.500' lineHeight='17.5' w='16%'>
                  Doctor Assigned
                </Text>
                <Text
                  // ml="1px"
                  fontWeight='bold'
                  fontSize='md'
                  textAlign='left'
                  color='coolGray.500'
                  lineHeight='17.5'
                  w='4%'>
                  Action
                </Text>
              </HStack>
            </VStack>
          </VStack>
        </>
      </Hidden>
      <Hidden from={'md'}>
        <>
          <HStack alignItems={'center'} justifyContent={'space-between'} height={'64px'} w={'100%'}>
            {!isSearchOpen && (
              <HStack alignItems={'center'} w={'100%'}>
                <Pressable
                  mt={1}
                  ml={'4'}
                  onPress={() => navigation.navigate(RootStackScreensEnum.Admin, { screen: RootStackScreensEnum.Dashboard })}
                  mr={1}>
                  <Icon as={Ionicons} name='arrow-back' size='xl' color={'coolGray.700'} />
                </Pressable>
                <Text w={'95%'} fontSize='20' fontWeight='bold' _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }}>
                  Patient List
                </Text>
                <Tooltip label='Filter by chat content'>
                  <Pressable
                    onPress={handleToggleSearch}
                    w={'5%'}
                    mr={4}
                    p={4}
                    alignItems='center'
                    justifyContent='center'
                    size={'10'}
                    rounded='full'
                    _hover={{ bg: '#E4EBE4' }}>
                    <Icon as={<MaterialIcons name='search' />} size={8} color='coolGray.900' />
                  </Pressable>
                </Tooltip>
                {/*</Hidden>*/}
              </HStack>
            )}
            <Box w={'100%'} px={2}>
              {isSearchOpen && (
                <SearchBarWeb textInput={textInput} handleSearchTextChange={handleSearchTextChange} handleToggleSearch={handleToggleSearch} />
              )}
            </Box>
          </HStack>
          <Divider />
        </>
      </Hidden>
    </>
  );
};
