export enum RootStackScreensEnum {
  Main = 'Main',
  Auth = 'Auth',
  Admin = 'Admin',
  Patient = 'Patient',
  Doctor = 'Doctor',
  Splash = 'Splash',
  SignUp = 'SignUp',
  SignUpDoctor = 'SignUpDoctor',
  SignIn = 'SignIn',
  Logout = 'Logout',
  Profile = 'Profile',
  ChangePassword = 'ChangePassword',
  Language = 'Language',
  Notifications = 'Notifications',
  DisableAccount = 'DisableAccount',
  OtpVerification = 'OtpVerification',
  ForgotPassword = 'ForgotPassword',
  ResetPassword = 'ResetPassword',
  Dashboard = 'Dashboard',
  DashboardPatient = 'DashboardPatient',
  PatientHistory = 'PatientHistory',
  PatientProfile = 'PatientProfile',
  QAndAScreen = 'QAndAScreen',
  DashboardDoctor = 'DashboardDoctor',
  ChatTotal = 'ChatTotal',
  ChatTotalMobile = 'ChatTotalMobile',
  ChatList = 'ChatList',
  DoctorListPatient = 'DoctorListPatient',
  DoctorDetailsPatient = 'DoctorDetailsPatient',
  ChatScreen = 'ChatScreen',
  ChatScreenMobile = 'ChatScreenMobile',
  Contacts = 'Contacts',
  ContactScreenMobile = 'ContactScreenMobile',
  MedicalDetailMobile = 'MedicalDetailMobile',
  ChatWithDoctor = 'ChatWithDoctor',
  HealthStore = 'HealthStore',
  AppointmentList = 'AppointmentList',
  PatientList = 'PatientList',
  MedicalRecordScreen = 'MedicalRecordScreen',
  DicomViewer = 'DicomViewer',
  MedicalRecordDetailMobile = 'MedicalRecordDetailMobile',
  DoctorList = 'DoctorList',
  DoctorDetails = 'DoctorDetails',
  DoctorTopDetailMobile = 'DoctorTopDetailMobile',
  PatientDetails = 'PatientDetails',
  FhirPatientDetails = 'FhirPatientDetails',
  patientList_Doctor = 'patientList_Doctor',
  ChatWithPatient = 'ChatWithPatient',
  PatientAppointment = 'PatientAppointment'
}
