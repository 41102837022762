import React from 'react';
import { Box, Hidden } from 'native-base';
import MedicalRecordDetail from '../AdminScreen/MedicalRecordDetail';
import MedicalRecordDetailMobile from '../AdminScreen/MedicalRecordDetailMobile';

const PatientHistory = () => {
  return (
    <>
      <Hidden till={'md'}>
        <Box width={'100%'} height={'100%'}>
          <MedicalRecordDetail />
        </Box>
      </Hidden>
      <Hidden from={'md'}>
        <Box width={'100%'} height={'100%'}>
          <MedicalRecordDetailMobile />
        </Box>
      </Hidden>
    </>
  );
};

export default PatientHistory;
