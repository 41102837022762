import { getDoctor, listDoctors } from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { GetDoctorQuery, ListDoctorsQuery, OnCreateDoctorSubscription, OnDeleteDoctorSubscription, OnUpdateDoctorSubscription } from '../../API';
import { GraphQLResult } from '@aws-amplify/api';
import { cloneSubscribeDoctor } from '../../types/IDoctor';
import { Unsubscribe } from '@reduxjs/toolkit';
import { AppDispatch } from '../../store';
import { IDoctorWithUser } from '../../types/IDoctorWithUser';
import { onCreateDoctor, onDeleteDoctor, onUpdateDoctor } from '../../graphql/subscriptions';
import { doctorsActions } from '../../store/hospital/doctors/doctors-slice';
import { gqlGetUserWithDoctorID } from './user_utils';
import { cloneUser } from '../../types/IUser';

export type ListDoctorsWithUser = {
  listDoctors?: {
    __typename: 'ModelUserConnection';
    items: Array<
      | (IDoctorWithUser & {
          _deleted?: boolean | null;
        })
      | null
    >;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
};

const listDoctorsWithUser = /* GraphQL */ `
  query ListDoctorsWithUser($filter: ModelDoctorFilterInput, $limit: Int, $nextToken: String) {
    listDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        specialist
        doctorUserId
        createdAt
        user {
          id
          firstName
          lastName
          email
          phoneNumber
          imageUrl
          address
          userSFUserId
          _deleted
        }
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;

export const gqlGetAllDoctorsWithUser = async (): Promise<IDoctorWithUser[]> => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get all doctors with user ${retry} time(s)`);
    }

    try {
      let nextToken = null;
      const allDoctorsWithUsers = [];
      do {
        const results = (await API.graphql(
          graphqlOperation(listDoctorsWithUser, {
            limit: 1000,
            nextToken
          })
        )) as GraphQLResult<ListDoctorsWithUser>;
        const doctors = results.data?.listDoctors?.items.filter((doctor) => doctor && !doctor._deleted && !doctor.user?._deleted);
        nextToken = results.data?.listDoctors?.nextToken;
        if (doctors && doctors.length > 0) {
          allDoctorsWithUsers.push(...doctors);
        }
      } while (nextToken);

      return allDoctorsWithUsers as IDoctorWithUser[];
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to get all doctors with user');
  }

  return [];
};

export const gqlGetDoctor = async (doctorId: string) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log('Retrying getDoctor...');
    }

    try {
      const doctor = (await API.graphql(graphqlOperation(getDoctor, { id: doctorId }))) as GraphQLResult<GetDoctorQuery>;

      if (doctor.data?.getDoctor) {
        return !doctor.data.getDoctor._deleted ? doctor.data.getDoctor : null;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to get doctor');
  }

  return null;
};

// A class that subscribes to the onCreateDoctor, onUpdateDoctor, and onDeleteDoctor subscriptions
export class DoctorSubscription {
  _onCreateDoctorSubscription: { unsubscribe: Unsubscribe } | null = null;
  _onUpdateDoctorSubscription: { unsubscribe: Unsubscribe } | null = null;
  _onDeleteDoctorSubscription: { unsubscribe: Unsubscribe } | null = null;

  constructor(private _dispatch: AppDispatch) {}

  // Subscribe to the onCreateDoctor subscription
  private _subscribeToOnCreateDoctorSubscription() {
    // Subscribe to the onCreateDoctor subscription
    this._onCreateDoctorSubscription = (API.graphql(graphqlOperation(onCreateDoctor)) as any).subscribe({
      next: async ({ value }: { value: GraphQLResult<OnCreateDoctorSubscription> }) => {
        if (value?.data?.onCreateDoctor) {
          const createdDoctor = cloneSubscribeDoctor(value.data.onCreateDoctor);

          // Fetch the user data for the doctor
          const userBelongingToDoctor = await gqlGetUserWithDoctorID(createdDoctor.id);

          if (userBelongingToDoctor) {
            this._dispatch(
              doctorsActions.addDoctor({
                ...createdDoctor,
                user: cloneUser(userBelongingToDoctor)
              })
            );
          } else {
            console.warn('User belonging to doctor not found: ', createdDoctor);
          }
        }
      },
      error: (error: any) => {
        console.log('onCreateDoctor subscription error: ', error);
      }
    });
  }

  // Subscribe to the onUpdateDoctor subscription
  private _subscribeToOnUpdateDoctorSubscription() {
    this._onUpdateDoctorSubscription = (API.graphql(graphqlOperation(onUpdateDoctor)) as any).subscribe({
      next: ({ value }: { value: GraphQLResult<OnUpdateDoctorSubscription> }) => {
        if (value?.data?.onUpdateDoctor) {
          const updatedDoctor = cloneSubscribeDoctor(value.data.onUpdateDoctor);
          console.log('onUpdateDoctor: ', updatedDoctor);
          this._dispatch(
            doctorsActions.updateDoctor({
              id: updatedDoctor.id,
              changes: {
                ...updatedDoctor
              }
            })
          );

          // TODO (MinhLuan): do we need to update the active chat room?
        }
      },
      error: (error: any) => {
        console.log('onUpdateDoctor subscription error: ', error);
      }
    });
  }

  // Subscribe to the onDeleteDoctor subscription
  private _subscribeToOnDeleteDoctorSubscription() {
    this._onDeleteDoctorSubscription = (API.graphql(graphqlOperation(onDeleteDoctor)) as any).subscribe({
      next: ({ value }: { value: GraphQLResult<OnDeleteDoctorSubscription> }) => {
        if (value?.data?.onDeleteDoctor) {
          const deletedDoctor = cloneSubscribeDoctor(value.data.onDeleteDoctor);
          console.log('onDeleteDoctor: ', deletedDoctor);
          this._dispatch(doctorsActions.removeDoctor(deletedDoctor.id));

          // TODO (MinhLuan): do we need to change the active chat room?
        }
      },
      error: (error: any) => {
        console.log('onDeleteDoctor subscription error: ', error);
      }
    });
  }

  // Subscribe to all subscriptions
  async subscribe() {
    this._subscribeToOnCreateDoctorSubscription();
    this._subscribeToOnUpdateDoctorSubscription();
    this._subscribeToOnDeleteDoctorSubscription();

    // Query for all doctors with the user data
    const doctors = await gqlGetAllDoctorsWithUser();
    if (doctors && doctors.length > 0) {
      // In the initial state, there is no need to check for the active chat room. Just add all chat rooms to the store.
      this._dispatch(doctorsActions.addDoctors(doctors));
    } else {
      console.log('DoctorSubscription: no chat rooms found');
    }

    // TODO (MinhLuan): maybe we need to check again if the data after subscription is the same as the data from the query
    // Maybe there is some delay between the query and the subscription
  }

  // Unsubscribe from all subscriptions
  unsubscribe() {
    if (this._onCreateDoctorSubscription) {
      this._onCreateDoctorSubscription.unsubscribe();
    }
    if (this._onUpdateDoctorSubscription) {
      this._onUpdateDoctorSubscription.unsubscribe();
    }
    if (this._onDeleteDoctorSubscription) {
      this._onDeleteDoctorSubscription.unsubscribe();
    }
  }
}

export const gqlListAllDoctors = async () => {
  let retry = 0;
  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get all doctors ${retry} time(s)`);
    }

    try {
      const allDoctors = [];
      let nextToken = null;
      do {
        const result = (await API.graphql(
          graphqlOperation(listDoctors, {
            limit: 1000,
            nextToken
          })
        )) as GraphQLResult<ListDoctorsQuery>;
        const queriedDoctors = result.data?.listDoctors?.items.filter((doctor) => doctor && !doctor._deleted);
        nextToken = result.data?.listDoctors?.nextToken;
        if (queriedDoctors && queriedDoctors.length > 0) {
          allDoctors.push(...queriedDoctors);
        }
      } while (nextToken);

      return allDoctors;
    } catch (error) {
      console.log(error);
      retry++;
    }

    if (retry >= 3) {
      throw new Error('Failed to get all doctors');
    }
  }

  return null;
};
