import { Doctor, OnCreateDoctorSubscription } from '../API';
// import { cloneObject } from './object_clone';
// import { keys } from 'ts-transformer-keys';

export type IDoctor = Omit<
  Doctor,
  'updatedAt' | 'owner' | '_version' | '_deleted' | '_lastChangedAt' | '__typename' | 'user' | 'patients' | 'medicalRecords'
>;

type SubscribeDoctor = NonNullable<OnCreateDoctorSubscription['onCreateDoctor']>;
export const cloneSubscribeDoctor = (userToken: SubscribeDoctor): IDoctor => {
  return {
    id: userToken.id,
    specialist: userToken.specialist,
    doctorUserId: userToken.doctorUserId,
    departmentID: userToken.departmentID,
    createdAt: userToken.createdAt
  };
};
