import React, { useEffect, useState } from 'react';
import { Avatar, Box, Divider, HStack, Icon, Input, Pressable, Spinner, Text, Tooltip } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectAllChatRooms } from '../../store/chat/chat-rooms/chat-rooms-slice';
import { selectAllDoctors } from '../../store/hospital/doctors/doctors-slice';
import { IChatRoom } from '../../types/IChatRoom';
import { FlatList } from 'react-native';
import { useDebounce } from '../../utils/debounce';

import { IDoctorWithUser } from '../../types/IDoctorWithUser';
import { RootStackScreensEnum } from '../../navigation/enum';
import { useNavigation, useRoute } from '@react-navigation/native';
import { chatRoomContactActions } from '../../store/chat/chat-room-contact/chat-room-contact-slice';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import AdminMobileFooter from '../AdminScreen/AdminMobileFooter';

interface IChatItemProps {
  doctorWithUser: IDoctorWithUser;
  contactId: string;
  selectedContactId: string;
  setSelectedId: (id: string) => void;
}

const ContactItem = ({ doctorWithUser, contactId, selectedContactId, setSelectedId }: IChatItemProps) => {
  const navigation = useNavigation<any>();
  // const activeChatRoomID = useAppSelector(selectActiveChatRoomID);
  // const dispatch = useAppDispatch();

  const goToChatScreen = () => {
    // First, set the active chat room
    // dispatch(setActiveChatRoom(doctorWithUser.id));
  };

  return (
    <Pressable
      // onPress={() => setSelectedId(doctorWithUser.id)}
      onPress={() => navigation.navigate(RootStackScreensEnum.Contacts, { contactId: doctorWithUser.id })}
      style={{
        borderRadius: 8,
        backgroundColor: contactId === doctorWithUser.id ? '#DBEAFE' : 'white'
      }}>
      <HStack pl={2} w='100%' space={2} style={{ alignItems: 'center' }} py={2}>
        <Avatar source={doctorWithUser.user.imageUrl ? { uri: doctorWithUser.user.imageUrl } : undefined} height={10} width={10}>
          {doctorWithUser.user.firstName.charAt(0).toUpperCase()}
        </Avatar>
        <HStack style={{ alignItems: 'center' }} justifyContent={'space-between'}>
          <Text fontSize='md' fontWeight='medium' _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }}>
            {doctorWithUser.user.firstName} {doctorWithUser.user.lastName}
          </Text>
        </HStack>
      </HStack>
    </Pressable>
  );
};

const ContactList = () => {
  const { user } = useAuth();
  const chatRooms = useAppSelector(selectAllChatRooms);
  const doctorsWithUser = useAppSelector((state) => selectAllDoctors(state.hospital.doctors));
  const [textInput, setTextInput] = useState('');
  const [searchResults, setSearchResults] = useState<IChatRoom[]>([]);
  const textSearch = useDebounce(textInput, 1000);
  const [selectedContactId, setSelectedContactId] = useState<string>('');
  const route = useRoute();
  const [contactId, setContactId] = useState('');
  const navigation = useNavigation<any>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const contactIdParam = route.params as { contactId: string };
    if (contactIdParam?.contactId) {
      setContactId(contactIdParam.contactId);
      dispatch(chatRoomContactActions.resetChatRoom());
    } else {
      navigation.navigate(RootStackScreensEnum.Contacts, { contactId: doctorsWithUser[0].id });
    }
  }, [route.params]);

  useEffect(() => {
    console.log('doctorsWithUser: ', doctorsWithUser);
  }, [doctorsWithUser]);

  useEffect(() => {
    if (chatRooms) {
      setSearchResults(chatRooms);
    }
  }, [chatRooms]);

  useEffect(() => {
    const results = chatRooms.filter((chatRoom) => {
      return chatRoom.lastMessageText?.toLowerCase().includes(textInput.toLowerCase());
    });
    setSearchResults(results);
  }, [textSearch, chatRooms]);

  const handleSearchTextChange = (text: string) => {
    setTextInput(text);
  };

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const handleToggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    setTextInput('');
    setSearchResults(chatRooms);
  };

  const setSelectedId = (id: string) => {
    setSelectedContactId(id);
  };

  if (!user) {
    return (
      <Box
        flex='1'
        _light={{ bg: 'white' }}
        _dark={{ bg: 'coolGray.800' }}
        rounded={{ md: 'sm' }}
        style={{ alignItems: 'center' }}
        justifyContent='center'>
        <Spinner accessibilityLabel='Loading' size='lg' />
      </Box>
    );
  }

  return (
    <>
      <HStack style={{ alignItems: 'center' }} justifyContent={'space-between'} height={'64px'} w={'100%'}>
        {!isSearchOpen && (
          <HStack style={{ alignItems: 'center' }} w={'100%'}>
            <Text w={'95%'} fontSize='xl' fontWeight='bold' pl={4} _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }}>
              Contacts
            </Text>
            {/*<Hidden till='md'>*/}
            <Tooltip label='Filter by chat content'>
              <Pressable
                onPress={handleToggleSearch}
                w={'5%'}
                mr={4}
                p={4}
                style={{ alignItems: 'center' }}
                justifyContent='center'
                size={'10'}
                rounded='full'
                _hover={{ bg: '#E4EBE4' }}>
                <Icon as={<MaterialIcons name='search' />} size={8} color='coolGray.900' />
              </Pressable>
            </Tooltip>
            {/*</Hidden>*/}
          </HStack>
        )}
        <Box w={'100%'} px={2}>
          {isSearchOpen && (
            <SearchBarWeb textInput={textInput} handleSearchTextChange={handleSearchTextChange} handleToggleSearch={handleToggleSearch} />
          )}
        </Box>
      </HStack>
      <Divider />

      <Box px={{ base: 4, md: 2 }} py={{ base: 4, md: 2 }} _light={{ bg: 'white' }} _dark={{ bg: 'coolGray.800' }} rounded={{ md: 'sm' }} flex={1}>
        <FlatList
          showsVerticalScrollIndicator={false}
          data={doctorsWithUser}
          renderItem={({ item }) => (
            <ContactItem selectedContactId={selectedContactId} setSelectedId={setSelectedId} doctorWithUser={item} contactId={contactId} />
          )}
          keyExtractor={(item) => item.id}
          ItemSeparatorComponent={() => <Divider />}></FlatList>
      </Box>
      <AdminMobileFooter />
    </>
  );
};
export default ContactList;

const SearchBarWeb = ({
  handleSearchTextChange = () => {
    // do nothing
  },
  handleToggleSearch = () => {
    // close search input and clear search text
  },
  textInput
}: any) => {
  return (
    <HStack justifyContent='space-between' style={{ alignItems: 'center' }} space={4}>
      <Input
        width={'90%'}
        onChangeText={handleSearchTextChange}
        value={textInput}
        rounded={'2xl'}
        flex={1}
        py={3}
        _stack={{
          px: 3
        }}
        _light={{
          placeholderTextColor: 'coolGray.400'
        }}
        _dark={{
          bg: 'coolGray.700',
          borderColor: 'coolGray.500',
          color: 'coolGray.100'
        }}
        InputRightElement={
          <Tooltip label='Close'>
            <Pressable p={4} style={{ alignItems: 'center' }} justifyContent='center' size={'8'} rounded='full' _hover={{ bg: '#E4EBE4' }}>
              <Icon onPress={handleToggleSearch} as={<MaterialIcons name='close' />} size={6} color='coolGray.900' />
            </Pressable>
          </Tooltip>
        }
        placeholder='Search'
        fontWeight='medium'
        fontSize='md'
      />
    </HStack>
  );
};
