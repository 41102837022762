import { Box, Button, Divider, HStack, Image, Modal, View, VStack } from 'native-base';
import MedicalList from './MedicalList';
import LabTestResults from './LabTestResults';
import React, { useState } from 'react';
import { useAppSelector } from '../../../store/hooks';

const ComparedImagesModalButton = () => {
  const [showModalCompare, setShowModalCompare] = useState(false);
  const [imageLeft, setImageLeft] = useState('');
  const [imageRight, setImageRight] = useState('');
  const [recordLeft, setRecordLeft] = useState('');
  const [recordRight, setRecordRight] = useState('');
  const selectedMedicalRecordIdLeft = useAppSelector((state) => state.hospital.imagesComparationModal.selectedMedicalRecordIdLeft);
  const selectedMedicalRecordIdRight = useAppSelector((state) => state.hospital.imagesComparationModal.selectedMedicalRecordIdRight);

  const setSelectedImageLeft = (image: string) => {
    setImageLeft(image);
  };

  const setSelectedImageRight = (image: string) => {
    setImageRight(image);
  };

  const setFirstRecordLeft = (recordId: string) => {
    setRecordLeft(recordId);
  };

  const setFirstRecordRight = (recordId: string) => {
    setRecordRight(recordId);
  };

  return (
    <>
      <Button onPress={() => setShowModalCompare(true)}>Compare</Button>
      <Modal isOpen={showModalCompare} onClose={() => setShowModalCompare(false)} maxWidth={'96%'} maxHeight={'100%'} m={'auto'}>
        <Modal.Content maxWidth={'full'} maxHeight={'full'} h={'full'} w={'full'} p={0}>
          <Modal.CloseButton />
          <Modal.Header>Compare Test Images</Modal.Header>
          <Modal.Body p={0}>
            {/*height of header of modal: 52px + border-bottom: 1px*/}
            <HStack h={'calc(100vh - 53px)'} w={'full'}>
              <VStack h={'full'} w={'50%'}>
                <View h='30%' w={'full'}>
                  <HStack width={'full'} height={'full'}>
                    <Box w={['35%', '35%', '25%']} height={'100%'}>
                      <MedicalList
                        setFirstItemId={setFirstRecordLeft}
                        firstItemId={recordLeft}
                        selectedMedicalRecordId={selectedMedicalRecordIdLeft}
                      />
                    </Box>
                    <Divider orientation='vertical' />
                    <Box width={['65%', '65%', '75%']} height={'100%'}>
                      <LabTestResults
                        setSelectedImage={setSelectedImageLeft}
                        image={imageLeft}
                        selectedMedicalRecordId={selectedMedicalRecordIdLeft}
                      />
                    </Box>
                  </HStack>
                </View>
                <Box h={'70%'} w={'full'}>
                  {imageLeft && (
                    <Box w={'full'} h={'full'}>
                      <Image w={'95%'} h={'95%'} source={{ uri: imageLeft }} m={'auto'} alt={'image to compare'}></Image>
                    </Box>
                  )}
                </Box>
              </VStack>

              <VStack h={'full'} w={'50%'}>
                <View h='30%' w={'full'}>
                  <HStack alignSelf={'center'} width={'full'} height={'full'}>
                    <Box w={['35%', '35%', '25%']} height={'100%'}>
                      <MedicalList
                        setFirstItemId={setFirstRecordRight}
                        firstItemId={recordRight}
                        isLeft={false}
                        selectedMedicalRecordId={selectedMedicalRecordIdRight}
                      />
                    </Box>
                    <Divider orientation='vertical' />
                    <Box width={['65%', '65%', '75%']} height={'100%'}>
                      <LabTestResults
                        setSelectedImage={setSelectedImageRight}
                        image={imageRight}
                        selectedMedicalRecordId={selectedMedicalRecordIdRight}
                      />
                    </Box>
                  </HStack>
                </View>
                <Box h={'70%'} w={'full'}>
                  {imageRight && (
                    <Box w={'full'} h={'full'}>
                      <Image w={'95%'} h={'95%'} source={{ uri: imageRight }} m={'auto'} alt={'image to compare'}></Image>
                    </Box>
                  )}
                </Box>
              </VStack>
            </HStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ComparedImagesModalButton;
