import React, { createContext, useContext, useEffect } from 'react';
import { StropheReducerState } from '../../hooks/useStrophe/strophe.types';

const ConnectionContext = createContext<StropheReducerState>({
  setCredentials: () => {
    return;
  },
  connect: () => {
    return;
  },
  disconnect: (reason: string) => {
    return;
  },
  connecting: false,
  connected: false,
  disconnecting: false,
  disconnected: false,
  connFail: false,
  reason: null
});

interface IConnectionProviderProps {
  strophe: any;
  children: React.ReactNode;
}

// Wrap your app with <ConnectionProvider />
export const ConnectionProvider = (props: IConnectionProviderProps) => {
  const conn = useProvideConnection(props);
  return <ConnectionContext.Provider value={conn}>{props.children}</ConnectionContext.Provider>;
};

// Access auth values and functions with custom useAuth hook
export const useConnection = () => useContext(ConnectionContext);

const useProvideConnection = (props: IConnectionProviderProps) => {
  return props.strophe;
};
