import React, { useCallback, useEffect, useState } from 'react';
import { Entypo, MaterialIcons } from '@expo/vector-icons';
import { generate_uuid } from '../../utils/uuid_generator';
import axios from 'axios';
import { addChatRoom, setActiveChatRoom } from '../../store/chat/chat-rooms/chat-rooms-slice';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectAllAgents } from '../../store/agents/agents-slice';
import { getFriendlyLanguage } from '../../utils/prompt_utils';
import { Icon, Modal, Pressable, Select, Text, Tooltip, View } from 'native-base';

const ModalLanguageForm = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const agents = useAppSelector(selectAllAgents);

  const createChatRoomHandler = useCallback(
    async (agentID: string) => {
      if (!user) {
        console.warn('User is not logged in, this should not happen');
        return;
      }
      // Create a chat room ID which is compatible with DynamoDB (UUID v4)
      const chatRoomID = generate_uuid();
      const ret = await axios.post(
        '/chat_rooms/create',
        {
          chatRoomID: chatRoomID,
          userID: user.id,
          agentID: agentID
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.idToken}`
          },
          timeout: 10000
        }
      );
      if (ret.status === 201 && ret.data && ret.data.event === 'chatRoomCreated' && ret.data.data) {
        console.log('Chat room created successfully');
        dispatch(
          addChatRoom({
            ...ret.data.data
          })
        );
        // Set the active chat room to the newly created chat room
        dispatch(setActiveChatRoom(chatRoomID));
      }
    },
    [user]
  );

  const [showLanguageForm, setShowLanguageForm] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');

  // Print selected language
  useEffect(() => {
    console.log('Selected language:', selectedLanguage);
  }, [selectedLanguage]);

  useEffect(() => {
    if (agents.length > 0) {
      const defaultSelectedLanguage = agents.find((agent) => agent.language === 'English')?.id ?? '';
      setSelectedLanguage(defaultSelectedLanguage);
    }
  }, [agents]);

  const handleCreateChatRoom = async () => {
    setShowLanguageForm(false);
    // Continue with creating the chat room using the selected language
    // @ts-ignore
    await createChatRoomHandler(selectedLanguage);
  };
  return (
    <View>
      <Tooltip label={'New chat room'}>
        <Pressable
          shadow={9}
          position='absolute'
          bottom={{ base: 20, md: 4 }}
          right={8}
          zIndex={1}
          rounded='full'
          _hover={{ bg: '#4EA0F7' }}
          _light={{ bg: '#0564C8' }}
          _dark={{ bg: 'primary.500' }}
          p={3}
          size={{ base: 12, md: 16 }}
          alignItems='center'
          justifyContent='center'
          onPress={() => setShowLanguageForm(true)}>
          <Icon as={<Entypo name='new-message' />} size={6} color={'white'} />
        </Pressable>
      </Tooltip>
      <Modal isOpen={showLanguageForm} onClose={() => setShowLanguageForm(false)}>
        <Modal.Content>
          <Modal.Body>
            <Text fontSize={16} mb={4} fontWeight={'bold'}>
              Select language
            </Text>
            <Tooltip label='Close'>
              <Pressable
                px={4}
                right={2}
                top={2}
                position={'absolute'}
                alignItems='center'
                justifyContent='center'
                size={'8'}
                rounded='full'
                _hover={{ bg: '#E4EBE4' }}>
                <Icon onPress={() => setShowLanguageForm(false)} as={<MaterialIcons name='close' />} size={6} color='coolGray.900' />
              </Pressable>
            </Tooltip>
            <Select
              dropdownIcon={<MaterialIcons name='arrow-drop-down' size={24} color='black' />}
              mt={2}
              placeholder={'--Choose language--'}
              selectedValue={selectedLanguage}
              onValueChange={(value) => setSelectedLanguage(value)}>
              {agents.map((agent) => {
                return <Select.Item color={'black'} key={agent.id} label={getFriendlyLanguage(agent.language) ?? ''} value={agent.id} />;
              })}
            </Select>
            <Pressable
              mt={8}
              mb={4}
              rounded='full'
              _hover={{ bg: '#4EA0F7' }}
              _light={{ bg: '#0564C8' }}
              _dark={{ bg: 'primary.500' }}
              p={3}
              w={24}
              h={10}
              alignSelf={'center'}
              alignItems='center'
              justifyContent='center'
              onPress={handleCreateChatRoom}>
              <Text fontSize={12} fontWeight={'bold'} color={'white'}>
                CREATE
              </Text>
            </Pressable>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </View>
  );
};
export default ModalLanguageForm;
