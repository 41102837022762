import { combineReducers } from '@reduxjs/toolkit';
import patientsSlice from './hospital/patients/patients-slice';
import doctorsSlice from './hospital/doctors/doctors-slice';
import patientsDiseasesSlice from './hospital/patients-diseases-slice';
import usersSlice from './hospital/users/users-slice';
import medicalRecordsSlice from './hospital/medical-record/medical-records-slice';
import imagesComparationModalSlice from './hospital/medical-record/images-comparation-modal-slice';

const hospitalReducers = combineReducers({
  patients: patientsSlice.reducer,
  doctors: doctorsSlice.reducer,
  patientsDiseases: patientsDiseasesSlice.reducer,
  users: usersSlice.reducer,
  medicalRecords: medicalRecordsSlice.reducer,
  imagesComparationModal: imagesComparationModalSlice.reducer
});

export default hospitalReducers;
