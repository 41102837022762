import { API, graphqlOperation } from 'aws-amplify';
import { getDepartment, listDepartments } from '../../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import { DeleteDepartmentMutation, GetDepartmentQuery, ListDepartmentsQuery } from '../../API';
import { deleteDepartment } from '../../graphql/mutations';
import { cloneDepartment, IDepartment } from '../../types/IDepartment';

export const gqlGetDepartment = async (id: string) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get department ${retry} time(s)`);
    }

    try {
      const ret = (await API.graphql(
        graphqlOperation(getDepartment, {
          id
        })
      )) as GraphQLResult<GetDepartmentQuery>;
      return ret.data?.getDepartment && !ret.data.getDepartment?._deleted ? ret.data.getDepartment : null;
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to get department');
  }

  return null;
};

export const gqlGetAllDepartments = async (): Promise<IDepartment[]> => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get all departments ${retry} time(s)`);
    }

    try {
      let nextToken = null;
      const allDepartments = [];
      do {
        const ret = (await API.graphql(
          graphqlOperation(listDepartments, {
            limit: 1000,
            nextToken
          })
        )) as GraphQLResult<ListDepartmentsQuery>;
        if (ret.data?.listDepartments?.items) {
          allDepartments.push(...ret.data.listDepartments.items.filter((department) => department && !department._deleted).map(
            (department) => cloneDepartment(department!)
          ));
          nextToken = ret.data.listDepartments.nextToken;
        }
      } while (nextToken);

      return allDepartments as IDepartment[];
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to get all departments');
  }

  return [];
};

export const gqlRemoveDepartment = async (id: string) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to remove department ${retry} time(s)`);
    }

    try {
      const department = await gqlGetDepartment(id);
      if (department) {
        let deleteRetry = 0;

        while (deleteRetry < 3) {
          if (deleteRetry > 0) {
            console.log(`Retrying to delete department ${deleteRetry} time(s)`);
          }

          try {
            const ret = (await API.graphql(
              graphqlOperation(deleteDepartment, {
                input: {
                  id,
                  _version: department._version
                }
              })
            )) as GraphQLResult<DeleteDepartmentMutation>;

            if (ret.data?.deleteDepartment) {
              return ret.data.deleteDepartment.id;
            } else {
              throw new Error('Failed to delete department');
            }
          } catch (error) {
            console.log(error);
            deleteRetry++;
          }
        }

        if (deleteRetry >= 3) {
          throw new Error('Failed to delete department');
        }
      }
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to remove department');
  }

  return null;
};
