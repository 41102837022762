import React from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import { Box, Divider, Hidden, HStack } from 'native-base';
import ContactList from './ContactList';
import ContactScreen from './ContactScreen';
import AdminMobileFooter from '../AdminScreen/AdminMobileFooter';
import ContactListMobile from './ContactListMobile';

const ContactChat = () => {
  return (
    <>
      <DashboardLayout title='' displayMenuButton displayScreenTitle={false} displayAlternateMobileHeader rightPanelMobileHeader={true}>
        <Hidden till={'md'}>
          <HStack alignSelf={'center'} width={'full'} height={'full'}>
            <Box w={['35%', '35%', '25%']} height={'100%'}>
              <ContactList />
            </Box>
            <Divider orientation='vertical' />
            <Box width={['65%', '65%', '75%']} height={'100%'}>
              <ContactScreen />
            </Box>
          </HStack>
        </Hidden>
        <Hidden from={'md'}>
          <Box w={'100%'} height={'100%'} alignSelf={'center'}>
            <ContactListMobile />
          </Box>
        </Hidden>
      </DashboardLayout>
      <AdminMobileFooter />
    </>
  );
};

export default ContactChat;
