import { getPatient, listPatients } from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { GetPatientQuery, ListPatientsQuery, UpdatePatientMutation } from '../../API';
import { patientsActions } from '../../store/hospital/patients/patients-slice';
import { IPatient } from '../../types/IPatient';
import { updatePatient } from '../../graphql/mutations';

export const graphQLGetPatient = async (patientId: { filter: { patientUserId: { eq: string } } }) => {
  const patient = (await API.graphql(graphqlOperation(getPatient, { id: patientId }))) as GraphQLResult<GetPatientQuery>;

  if (patient.data?.getPatient) {
    return !patient.data.getPatient._deleted ? patient.data.getPatient : null;
  } else {
    return null;
  }
};

export const gqlGetPatientByID = async (patientID: string) => {
  let retry = 0;
  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get patient by ID ${retry} time(s)`);
    }

    try {
      const result = (await API.graphql(
        graphqlOperation(getPatient, {
          id: patientID
        })
      )) as GraphQLResult<GetPatientQuery>;

      if (result.data?.getPatient) {
        return result.data.getPatient && !result.data.getPatient._deleted ? result.data.getPatient : null;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      retry++;
    }

    if (retry >= 3) {
      throw new Error('Failed to get patient by ID');
    }
  }

  return null;
};

export const graphQLGetPatientByUserID = async (userID: string) => {
  const patient = (await API.graphql(
    graphqlOperation(listPatients, {
      filter: {
        patientUserId: {
          eq: userID
        }
      }
    })
  )) as GraphQLResult<ListPatientsQuery>;

  if (patient.data?.listPatients?.items && patient.data.listPatients.items.length > 0) {
    return !patient.data.listPatients.items[0]?._deleted ? patient.data.listPatients.items[0] : null;
  } else {
    return null;
  }
};

export const graphQLFetchPatientsAndDispatch = async (dispatch: any) => {
  const patients = (await API.graphql(graphqlOperation(listPatients))) as GraphQLResult<ListPatientsQuery>;

  if (patients.data?.listPatients?.items) {
    const patientsData = patients.data.listPatients.items
      .filter((patient) => patient !== null && !patient._deleted)
      .map((patient) => {
        return {
          id: patient!.id,
          patientUserId: patient!.patientUserId,
          checkInTime: patient!.checkInTime,
          status: patient!.status,
          ethnicity: patient!.ethnicity,
          healthHistory: patient!.healthHistory,
          allergies: patient!.allergies,
          immunizations: patient!.immunizations,
          doctorID: 'unknown' // TODO (MinhLuan): doctors and patients are now many-to-many, so we need to update this
        };
      }) as IPatient[];

    dispatch(patientsActions.addPatients(patientsData));
  } else {
    console.log('No patients found');
    return [];
  }
};

export const gqlUpdatePatient = async (updatedFields: IPatient) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to update patient ${retry} time(s)`);
    }

    try {
      const patient = await gqlGetPatientByID(updatedFields.id);

      if (patient) {
        let updateRetry = 0;

        while (updateRetry < 3) {
          if (updateRetry > 0) {
            console.log(`Retrying to update patient ${updateRetry} time(s)`);
          }

          try {
            const ret = (await API.graphql(
              graphqlOperation(updatePatient, {
                input: {
                  ...updatedFields,
                  _version: patient._version
                }
              })
            )) as GraphQLResult<UpdatePatientMutation>;

            if (ret.data?.updatePatient) {
              return ret.data.updatePatient;
            } else {
              throw new Error('Failed to update patient');
            }
          } catch (error) {
            console.log(error);
            updateRetry++;
          }
        }

        if (updateRetry >= 3) {
          throw new Error('Failed to update patient');
        }
      } else {
        console.log('Patient not found');
        retry++;
      }
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to update patient');
  }

  return null;
};
