import { API, graphqlOperation } from 'aws-amplify';
import { getMedicalRecord, medicalRecordsByDoctorID, medicalRecordsByPatientID } from '../../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import {
  CreateMedicalRecordMutation,
  DeleteMedicalRecordMutation,
  GetMedicalRecordQuery,
  MedicalRecordsByDoctorIDQuery,
  MedicalRecordsByPatientIDQuery
} from '../../API';
import { createMedicalRecord, deleteMedicalRecord } from '../../graphql/mutations';
import { ICreateMedicalRecordInput } from '../../types/IMedicalRecord';

export const gqlGetMedicalRecord = async (id: string) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get medical record ${retry} time(s)`);
    }

    try {
      const ret = (await API.graphql(
        graphqlOperation(getMedicalRecord, {
          id
        })
      )) as GraphQLResult<GetMedicalRecordQuery>;
      return ret.data?.getMedicalRecord && !ret.data.getMedicalRecord?._deleted ? ret.data.getMedicalRecord : null;
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to get medical record');
  }

  return null;
};

export const gqlGetMedicalRecordByPatientID = async (patientID: string) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get medical record by patient id ${retry} time(s)`);
    }

    try {
      let nextToken = null;
      const allMedicalRecords = [];
      do {
        const ret = (await API.graphql(
          graphqlOperation(medicalRecordsByPatientID, {
            patientID,
            nextToken
          })
        )) as GraphQLResult<MedicalRecordsByPatientIDQuery>;
        if (ret.data?.medicalRecordsByPatientID?.items) {
          allMedicalRecords.push(...ret.data.medicalRecordsByPatientID.items.filter((medicalRecord) => !medicalRecord?._deleted));
          nextToken = ret.data.medicalRecordsByPatientID.nextToken;
        }
      } while (nextToken);

      return allMedicalRecords;
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to get medical record by patient id');
  }

  return null;
};

export const gqlGetMedicalRecordByDoctorID = async (doctorID: string) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get medical record by doctor id ${retry} time(s)`);
    }

    try {
      let nextToken = null;
      const allMedicalRecords = [];
      do {
        const ret = (await API.graphql(
          graphqlOperation(medicalRecordsByDoctorID, {
            doctorID,
            nextToken
          })
        )) as GraphQLResult<MedicalRecordsByDoctorIDQuery>;
        if (ret.data?.medicalRecordsByDoctorID?.items) {
          allMedicalRecords.push(...ret.data.medicalRecordsByDoctorID.items.filter((medicalRecord) => !medicalRecord?._deleted));
          nextToken = ret.data.medicalRecordsByDoctorID.nextToken;
        }
      } while (nextToken);

      return allMedicalRecords;
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to get medical record by doctor id');
  }

  return null;
};

export const gqlCreateMedicalRecord = async (medicalRecord: ICreateMedicalRecordInput) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to add medical record ${retry} time(s)`);
    }

    try {
      const ret = (await API.graphql(
        graphqlOperation(createMedicalRecord, {
          input: medicalRecord
        })
      )) as GraphQLResult<CreateMedicalRecordMutation>;

      if (ret.data?.createMedicalRecord) {
        return ret.data?.createMedicalRecord;
      } else {
        throw new Error('Failed to add medical record');
      }
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to add medical record');
  }

  return null;
};

// Delete medical record
export const gqlRemoveMedicalRecord = async (medicalRecordID: string) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to remove medical record ${retry} time(s)`);
    }

    try {
      const medicalRecord = await gqlGetMedicalRecord(medicalRecordID);
      if (medicalRecord) {
        let deleteRetry = 0;

        while (deleteRetry < 3) {
          if (deleteRetry > 0) {
            console.log(`Retrying to delete medical record ${deleteRetry} time(s)`);
          }

          try {
            const ret = (await API.graphql(
              graphqlOperation(deleteMedicalRecord, {
                input: {
                  id: medicalRecordID,
                  _version: medicalRecord._version
                }
              })
            )) as GraphQLResult<DeleteMedicalRecordMutation>;

            if (ret.data?.deleteMedicalRecord) {
              return ret.data.deleteMedicalRecord.id;
            } else {
              throw new Error('Failed to delete medical record');
            }
          } catch (error) {
            console.log(error);
            deleteRetry++;
          }
        }

        if (deleteRetry >= 3) {
          throw new Error('Failed to delete medical record');
        }
      }
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to remove medical record');
  }

  return null;
};
