import React, { useEffect, useState } from 'react';
import { Button, HStack, Icon, Image, Modal, Pressable, Spinner, Text, VStack } from 'native-base';
import { AntDesign } from '@expo/vector-icons';
import { FlatList, TouchableOpacity } from 'react-native';
import axios from 'axios';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import { TestTypeEnum } from '../../enum/TestTypeEnum';
import { DetectionTypeEnum } from '../../enum/DetectionTypeEnum';
import { DetectionStatusEnum } from '../../enum/DetectionStatusEnum';
import { Storage } from 'aws-amplify';
import Roles from '../../components/auth/role';

type ImageModalProps = {
  images: string[];
  type: string;
};

const ImageModal = ({ images, type }: ImageModalProps) => {
  const [showModal, setShowModal] = useState(false);
  const [showDetectedModal, setShowDetectedModal] = useState(false);
  const [showNotDetectedModal, setShowNotDetectedModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const [isDetecting, setIsDetecting] = useState(false);
  const [typeToDetect, setTypeToDetect] = useState<DetectionTypeEnum>(DetectionTypeEnum.Colonoscopy);
  const [detectionUrl, setDetectionUrl] = useState<string>('');
  const [role, setRole] = useState<Roles>(Roles.Patient);
  const { user, checkRole } = useAuth();

  useEffect(() => {
    (async () => {
      if (user && checkRole) {
        const role = (await checkRole()) as Roles;
        setRole(role);
      }
    })();
  }, [user, checkRole]);

  useEffect(() => {
    if (type) {
      switch (type) {
        case TestTypeEnum.Xray:
          setTypeToDetect(DetectionTypeEnum.Lung);
          break;
        case TestTypeEnum.MRI:
          setTypeToDetect(DetectionTypeEnum.Brain_MRI);
          break;
        case TestTypeEnum.Prostate:
          setTypeToDetect(DetectionTypeEnum.Prostate);
          break;
        default:
          setTypeToDetect(DetectionTypeEnum.Colonoscopy);
      }
    }
  }, [type]);

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };
  const handleOpenModal = (index: number) => {
    setCurrentImageIndex(index);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const renderItem = ({ item, index }: { item: string, index: number }) => (
    <TouchableOpacity onPress={() => handleOpenModal(index)}>
      <Image
        source={{ uri: item }}
        width={{ base: '64px', md: '100px' }}
        height={{ base: '64px', md: '100px' }}
        mr={{ base: '4', md: '8' }}
        borderRadius={10}
        alt={'lab test image'}
      />
    </TouchableOpacity>
  );

  const getAllObjectsInBucket = async () => {
    const PAGE_SIZE = 1000;
    let nextToken: string | undefined = undefined;
    let allObjects: any[] = [];
    let response;
    do {
      response = await Storage.list('', {
        pageSize: PAGE_SIZE,
        nextToken: nextToken
      });
      allObjects = [...allObjects, ...response.results];
      if (response.hasNextToken) {
        nextToken = response.nextToken;
      } else {
        nextToken = undefined;
      }
    } while (response.hasNextToken);
    return allObjects;
  };

  const detect = async () => {
    setIsDetecting(true);
    const urlImageSplitted = images[currentImageIndex].split('public/');
    const fileNameWithoutExtend = urlImageSplitted[1].split('.jpg')[0];
    const fileNameDetect = `${fileNameWithoutExtend}_detect.jpg`;
    try {
      // Get all objects in S3
      const listResult = await getAllObjectsInBucket();
      let isDetected = false;
      // Check if the file exists in S3
      await Promise.all(
        listResult.map((result) => {
          if (result.key === fileNameDetect) {
            isDetected = true;
          }
        })
      );
      if (isDetected) {
        const oldDetectedUrl = `${urlImageSplitted[0]}public/${fileNameDetect}`;
        setIsDetecting(false);
        setShowDetectedModal(true);
        setDetectionUrl(oldDetectedUrl);
        return;
      }
    } catch (err) {
      setIsDetecting(false);
    }
    try {
      const data = {
        image: images[currentImageIndex],
        type: typeToDetect
      };
      // Call detect API
      const detectionRes = await axios.post('/detect/image', data, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user?.idToken}` },
        timeout: 10000
      });
      setIsDetecting(false);
      if (detectionRes && detectionRes.status === 200 && detectionRes.data) {
        if (detectionRes.data.status === DetectionStatusEnum.Have_Object_Detect) {
          setShowDetectedModal(true);
          setDetectionUrl(detectionRes.data.link);
        } else {
          setShowNotDetectedModal(true);
        }
      }
    } catch (err) {
      setIsDetecting(false);
      console.log(err);
    }
  };

  return (
    <>
      <FlatList data={images} horizontal keyExtractor={(item) => item} renderItem={renderItem} />
      <Modal isOpen={showModal} onClose={handleCloseModal}>
        <Modal.Content bgColor='rgba(0, 0, 0, 0.5)' maxWidth={{ base: '100%', md: '60%' }} height={{ base: '100%', md: '65%' }} position={'relative'}>
          <Modal.CloseButton />
          <VStack h={'100%'}>
            <Pressable
              onHoverOut={handleMouseLeave}
              onHoverIn={handleMouseEnter}
              justifyContent={'center'}
              alignItems={'center'}
              m={'auto'}
              h={'80%'}
              w={'100%'}>
              <Image
                source={{ uri: images[currentImageIndex] ?? 'https://example.com/default.jpg' }}
                width={{ base: '90%', md: '100%' }}
                height={{ base: '90%', md: '100%' }}
                resizeMode={'contain'}
                borderRadius={10}
                alt={'lab test image'}
              />
            </Pressable>
            {role !== Roles.Patient && (
              <HStack justifyContent={'center'} mb={4}>
                <Button
                  onPress={detect}
                  isDisabled={isDetecting}
                  backgroundColor={'#4EA0F7'}
                  _hover={{ backgroundColor: '#0564C8' }}
                  rounded={'lg'}
                  pl={4}
                  pr={3}>
                  <Text fontSize={20} fontWeight={'bold'} color={'white'}>
                    Anomaly Detect {isDetecting && <Spinner />}
                  </Text>
                </Button>
              </HStack>
            )}
          </VStack>
          <Button
            onPress={handlePrevImage}
            bgColor='rgba(0, 0, 0, 0.2)'
            position={'absolute'}
            left={10}
            top={'40%'}
            _hover={{ opacity: 1 }}
            opacity={opacity}>
            <Icon as={AntDesign} name='left' size={12} />
          </Button>
          <Button
            onPress={handleNextImage}
            bgColor='rgba(0, 0, 0, 0.2)'
            position={'absolute'}
            right={10}
            top={'40%'}
            _hover={{ opacity: 1 }}
            opacity={opacity}>
            <Icon as={AntDesign} name='right' size={12} />
          </Button>
        </Modal.Content>
      </Modal>
      <Modal isOpen={showDetectedModal} onClose={() => setShowDetectedModal(false)}>
        <Modal.Content bgColor='rgba(0, 0, 0, 0.5)' maxWidth={'60%'} w={'60%'} height={'65%'} maxHeight={'65%'}>
          <Modal.CloseButton />
          <VStack h={'100%'} w={'100%'} justifyContent={'center'} alignItems={'center'} space={3}>
            <Text fontSize={20} color={'white'}>
              Cancer Detection Image
            </Text>
            {detectionUrl !== '' && (
              <Image
                source={{ uri: detectionUrl }}
                style={{ width: '700px', height: '500px', resizeMode: 'cover', borderRadius: 10 }}
                alt={'detected image'}
              />
            )}
          </VStack>
        </Modal.Content>
      </Modal>
      <Modal isOpen={showNotDetectedModal} onClose={() => setShowNotDetectedModal(false)}>
        <Modal.Content p={4}>
          <Modal.CloseButton />
          <VStack space={3} mt={4}>
            <HStack justifyContent={'center'}>
              <Text fontSize={20} color={'black'}>
                Nothing unusual was detected
              </Text>
            </HStack>
            <HStack justifyContent={'end'}>
              <Button onPress={() => setShowNotDetectedModal(false)} py={2} px={4}>
                OK
              </Button>
            </HStack>
          </VStack>
        </Modal.Content>
      </Modal>
    </>
  );
};
export default ImageModal;
