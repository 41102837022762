import React from 'react';
import { Box, Hidden, HStack, Icon, IconButton, MoonIcon, Pressable, StatusBar, SunIcon, Text, useColorMode, VStack } from 'native-base';

import { AntDesign, Ionicons } from '@expo/vector-icons';

import Sidebar from '../components/Sidebar';
import SidebarAccount from '../components/SidebarAccount';
import SidebarAdmin from '../components/SidebarAdmin';
import SidebarPodcastScreen from '../components/SidebarPodcastScreen';
import SidebarTopPerformingStocks from '../components/SidebarTopPerformingStocks';
import Header from './Header';
import MenuProfile from '../components/MenuProfile';
import AdminMobileFooter from '../screens/AdminScreen/AdminMobileFooter';

type DashboardLayoutProps = {
  scrollable?: boolean;
  displayScreenTitle?: boolean;
  displaySidebar?: boolean;
  displayBackButton?: boolean;
  showIcons?: boolean;
  displaySearchButton?: boolean;
  displayNotificationButton?: boolean;
  displayMenuButton?: boolean;
  displayAlternateMobileHeader?: boolean;
  maxWidth?: number;
  header?: {
    searchbar: boolean;
  };
  mobileHeader?: {
    backButton: boolean;
  };
  title: string;
  subTitle?: string;
  children: React.ReactNode;
  showGroupInfoHeader?: boolean;
  displayBackIcon?: boolean;
  rightPanelMobileHeader?: boolean;
};

type MainContentProps = DashboardLayoutProps;

type MobileHeaderProps = {
  title: string;
  subTitle?: string;
  backButton: boolean;
  rightPanel?: boolean;
};

function MainContent(props: MainContentProps) {
  return (
    <VStack maxW={props.maxWidth} flex={1} width='100%'>
      {props.displayScreenTitle && (
        <Hidden till='md'>
          <HStack mb='4' space={4}>
            <Pressable
              onPress={() => {
                console.log('hello');
              }}>
              <Icon size='6' pt='0.5' as={AntDesign} name={'arrowleft'} _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }} />
            </Pressable>
            <VStack>
              <Text fontSize='lg' fontWeight='medium' _dark={{ color: 'coolGray.50' }} _light={{ color: 'coolGray.800' }}>
                {props.title}
              </Text>
              <Text _dark={{ color: 'coolGray.50' }} _light={{ color: 'coolGray.800' }} fontSize='sm' fontWeight='normal'>
                {props.subTitle}
              </Text>
            </VStack>
          </HStack>
        </Hidden>
      )}
      {props.children}
    </VStack>
  );
}

export function MobileHeader(props: MobileHeaderProps) {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box
      px='2'
      py={'4px'}
      _dark={{ bg: 'coolGray.900', borderColor: 'coolGray.800' }}
      _light={{
        bg: '#fff',
        borderColor: 'coolGray.200'
      }}
      bg='amber.300'>
      <HStack space='2' justifyContent='space-between'>
        <HStack flex='1' space='2' justifyContent='space-between' style={{ alignItems: 'center' }}>
          <>
            <HStack style={{ alignItems: 'center' }} space='1'>
              {/*<Icon as={<Ionicons name='menu' />} size={6} color={'black'} />*/}
              <Box flex={1}>
                <Text color={'#4485FD'} fontSize={24} fontFamily={'Sketch_Block'} fontWeight={'bole'}>
                  Teledoc
                </Text>
              </Box>
              <VStack>
                <Text color='coolGray.50' fontSize='lg'>
                  {props.title}
                </Text>
                {props.subTitle ? (
                  <Text color='coolGray.50' fontSize='sm' fontWeight='normal'>
                    {props.subTitle}
                  </Text>
                ) : undefined}
              </VStack>
            </HStack>
            {/* right panel */}

            <HStack space='1'>
              <IconButton
                variant='unstyled'
                colorScheme='light'
                onPress={toggleColorMode}
                _icon={{ color: '#4485FD' }}
                icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
              />
              <MenuProfile />
            </HStack>
          </>
        </HStack>
      </HStack>
    </Box>
  );
}

function getSidebar(title: string) {
  switch (title) {
    case '':
      return <SidebarAdmin />;
    case 'Account':
      return <SidebarAccount />;
    case 'Podcasts':
      return <SidebarPodcastScreen />;
    case 'Video Library':
      return <SidebarPodcastScreen />;
    case 'Playlist':
      return <SidebarPodcastScreen />;
    case 'Dashboard':
      return <SidebarTopPerformingStocks />;

    default:
      return <Sidebar />;
  }
}

export default function DashboardLayout({
  displayScreenTitle = true,
  displaySidebar = true,
  header = {
    searchbar: false
  },
  maxWidth = 2000,
  mobileHeader = {
    backButton: true
  },
  ...props
}: DashboardLayoutProps) {
  const [isSidebarVisible, setIsSidebarVisible] = React.useState(true);

  function toggleSidebar() {
    setIsSidebarVisible(!isSidebarVisible);
  }

  return (
    <>
      <StatusBar translucent backgroundColor='transparent' barStyle='light-content' />
      <Box safeAreaTop _light={{ bg: 'primary.900' }} _dark={{ bg: 'coolGray.900' }} />
      <VStack flex={1} _light={{ bg: 'coolGray.50' }} _dark={{ bg: 'customGray' }}>
        <Hidden from='md'>
          <MobileHeader
            title={props.title}
            subTitle={props.subTitle}
            backButton={mobileHeader.backButton}
            rightPanel={props.rightPanelMobileHeader}
          />
        </Hidden>
        <Hidden till='md'>
          <Header
            toggleSidebar={toggleSidebar}
            title={props.title}
            subTitle={props.subTitle}
            menuButton={displaySidebar}
            searchbar={header.searchbar}
          />
        </Hidden>

        <Box
          flex={1}
          safeAreaBottom
          flexDirection={{ base: 'column', md: 'row' }}
          _light={{
            borderTopColor: 'coolGray.200'
          }}
          _dark={{
            bg: 'coolGray.700',
            borderTopColor: 'coolGray.700'
          }}>
          {isSidebarVisible && displaySidebar && <Hidden till='md'>{getSidebar(props.title)}</Hidden>}

          <Hidden till='md'>
            {/*<ScrollView flex={1} p={{ md: 8 }} contentContainerStyle={{ alignItems: 'center', flexGrow: 1 }} showsVerticalScrollIndicator={true}>*/}
            <MainContent {...props} displayScreenTitle={displayScreenTitle} maxWidth={maxWidth} />
            {/*</ScrollView>*/}
          </Hidden>

          <Hidden from='md'>
            <MainContent {...props} displayScreenTitle={displayScreenTitle} />
          </Hidden>
        </Box>
      </VStack>
    </>
  );
}
