// import React, { useEffect, useState } from 'react';
// import { Button, Modal, Text, VStack } from 'native-base';
// import WaitingRoomModalContent from './WaitingRoomModalContent';
// import io, { Socket } from 'socket.io-client';
// import { useAuth } from '../../components/auth/AmplifyAuthProvider';
//
// const WaitingRoomModal = () => {
//   const [showModal, setShowModal] = useState(false);
//   const [socket, setSocket] = useState<Socket | null>(null);
//   const { user } = useAuth();
//
//   const handleOpenModal = () => {
//     setShowModal(true);
//   };
//
//   const handleCloseModal = () => {
//     setShowModal(false);
//   };
//
//   useEffect(() => {
//     if (!user) {
//       console.warn('No user found, not connecting to waiting room service');
//       return;
//     }
//
//     if (showModal) {
//       if (!socket) {
//         const newSocket = io('/waiting_room', {
//           transports: ['websocket'],
//           auth: {
//             token: user.idToken
//           }
//         });
//         setSocket(newSocket);
//         console.log('newSocket', newSocket);
//       }
//     } else {
//       console.log('disconnecting socket');
//       if (socket) {
//         socket.disconnect();
//         setSocket(null);
//       }
//     }
//   }, [showModal, socket]);
//
//   useEffect(() => {
//     if (!socket || !user) {
//       return;
//     }
//
//     socket.on('connect', () => {
//       // Request to join the waiting room
//       socket.emit('request_join_room', {
//         meetingID: '1234567890',
//         userID: user.id
//       });
//     });
//
//     socket.on('meeting_available', (data) => {
//       console.log('meeting_available', data);
//       socket.emit('request_join_meeting', {
//         meetingID: '1234567890',
//         userID: user.id
//       });
//
//       // Beside, we call flutter to join the meeting
//       // TODO (Tri Nguyen): Call flutter to join the meeting
//     });
//
//     socket.on('meeting_not_available', (data) => {
//       // TODO (Lanh Nguyen): Show the UI to ask the user to wait for a while
//       const { meetingID, waitingUserCount } = data;
//     });
//   }, [socket]);
//
//   // TODO (Tri Nguyen): when the meeting is ended by the user, we need to call the socket to leave the meeting
//   // useEffect(() => {
//   //
//   // }, []);
//
//   return (
//     <>
//       {/* Your existing content */}
//       <Button
//         onPress={handleOpenModal}
//         rounded='lg'
//         px={6}
//         py={2}
//         bg={'secondary.500'}
//         w={20}
//         _hover={{
//           bg: 'secondary.500',
//           shadow: 5
//         }}
//         _pressed={{
//           bg: 'secondary.600'
//         }}>
//         <Text color={'white'} fontWeight={'medium'}>
//           Join
//         </Text>
//       </Button>
//
//       <Modal isOpen={showModal} onClose={handleCloseModal}>
//         <Modal.Content maxWidth='900px' maxHeight='1600px'>
//           <Modal.CloseButton />
//           <Modal.Header fontSize={'3xl'} fontWeight={'bold'}>
//             Waiting Room
//           </Modal.Header>
//           <Modal.Body>
//             <VStack>
//               <WaitingRoomModalContent />
//             </VStack>
//           </Modal.Body>
//         </Modal.Content>
//       </Modal>
//     </>
//   );
// };
//
// export default WaitingRoomModal;
// dựa vào WaitingRoomModal component này để làm cái modal đơn giản
// thông báo đến người dùng là bạn chưa tạo meeting nào cả hãy tạo meeting mới
import React, { useState } from 'react';
import { Button, Modal, Text, VStack, Box, HStack, Icon } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const NoMeetingModal = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {/* Your existing content */}
      <Button
        shadow={3}
        onPress={handleOpenModal}
        rounded='lg'
        px={{ base: 3, md: 6 }}
        py={2}
        bg={'#4485FD'}
        w={{ base: '72px', md: '80px' }}
        h={{ base: '28px', md: '' }}
        _hover={{
          bg: 'primary.600',
          shadow: 5
        }}
        _pressed={{
          bg: 'primary.700'
        }}>
        <HStack alignItems={'center'}>
          <MaterialCommunityIcons mr={2} name='motion-play-outline' size={20} color='#fff' />
          {/*<Text color={'white'} fontWeight={'medium'}>*/}
          {/*  Join*/}
          {/*</Text>*/}
        </HStack>
      </Button>

      <Modal isOpen={showModal} onClose={handleCloseModal}>
        <Modal.Content maxWidth='400px' maxHeight='300px'>
          <Modal.CloseButton />
          <Modal.Header>
            <Text fontSize={{ base: '16', md: '20' }} fontWeight={'bold'} color={'coolGray.900'}>
              No create meeting !
            </Text>
          </Modal.Header>
          <Modal.Body>
            <VStack>
              <Box mx={{ base: '2', md: '4' }}>
                <Text fontSize={{ base: '14', md: '16' }} fontWeight='medium'>
                  You have not created any meeting yet. Please create a new meeting to proceed.
                </Text>
              </Box>
            </VStack>
          </Modal.Body>
          <Button mb={3} bg={'#4485FD'} rounded={'lg'} alignSelf={'center'} width={'20'} height={8}
                  onPress={handleCloseModal}>
            <Text color={'white'} fontSize={{ base: '16', md: '20' }} fontWeight={'bold'}>
              OK
            </Text>
          </Button>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default NoMeetingModal;
