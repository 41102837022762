import React, { useEffect, useState } from 'react';
import { Box, Divider, FlatList, HStack, Pressable, Text, VStack } from 'native-base';
import { FontAwesome, FontAwesome5, Fontisto, Foundation, MaterialCommunityIcons, SimpleLineIcons } from '@expo/vector-icons';
import { useAppSelector } from '../../store/hooks';
import { selectAllMedicalRecords } from '../../store/hospital/medical-record/medical-records-slice';
import { IMedicalRecord } from '../../types/IMedicalRecord';
import { formatDate } from '../../utils/time_utils';
import { gqlGetTestResultsByMedicalRecordID } from '../../utils/graphql/test_result_utils';
import { ITestResult } from '../../types/ITestResult';
import LabTestImageModal from './LabTestImageModal';
import ComparedImagesModalButton from './ComparedTestImagesModal/ComparedImagesModalButton';

const MedicalDetail = () => {
  const medicalRecordList = useAppSelector((state) => selectAllMedicalRecords(state.hospital.medicalRecords));
  const selectedMedicalRecordId = useAppSelector((state) => state.hospital.medicalRecords.selectedMedicalRecordId);
  const isFhirResource = useAppSelector((state) => state.hospital.medicalRecords.isFhirResource);
  const [record, setRecord] = useState<IMedicalRecord>(medicalRecordList[0]);
  const [labTestResult, setLabTestResult] = useState<ITestResult[]>();

  useEffect(() => {
    (async () => {
      if (!record) return;
      const recordFhir: any = { ...record };
      if (recordFhir.observationList) {
        if (recordFhir.observationList.length > 0) {
          const labTestList = recordFhir.observationList.map((item: any) => {
            return {
              id: item.id,
              result: item.valueQuantity ? item.valueQuantity.value : item.valueString,
              testType: item.code.coding[0].display,
              timestamp: item.effectiveDateTime
            };
          });
          setLabTestResult(labTestList);
        } else {
          setLabTestResult([]);
        }
      } else {
        const testResultList = await gqlGetTestResultsByMedicalRecordID(record.id);
        setLabTestResult(testResultList);
      }
    })();
  }, [record]);

  useEffect(() => {
    if (medicalRecordList.length > 0 && selectedMedicalRecordId) {
      const selectedRecord = medicalRecordList.find((item) => item.id === selectedMedicalRecordId);
      if (selectedRecord) {
        setRecord(selectedRecord);
      }
    }
  }, [medicalRecordList, selectedMedicalRecordId]);

  const noInfo = 'No information';

  return (
    <>
      <VStack w={'100%'} backgroundColor={'white'} rounded={'lg'} space='2' h={'100%'} py={5}>
        <VStack space={6} px={{ base: 5, md: 10 }}>
          <Text fontWeight={'bold'} fontSize={'xl'}>
            {/*Created at [item.timestamp]*/}
            {/*Created at {record && formatDate(record.timestamp)}*/}
            {/*Created at 21 August 2020, 12:45 AM*/}
          </Text>
          <VStack space={2}>
            <HStack>
              <HStack w={'50%'}>
                <Box w={16}>
                  <SimpleLineIcons name='speedometer' size={30} color='#4EA0F7' />
                </Box>
                <Text color={'coolGray.500'} fontSize={'lg'}>
                  Blood Pressure: {record?.bloodPressure ? `${record.bloodPressure} mmHg` : noInfo}
                </Text>
              </HStack>
              <HStack w={'50%'}>
                <Box w={16}>
                  <FontAwesome name='heartbeat' size={30} color='#4EA0F7' />
                </Box>
                <Text color={'coolGray.500'} fontSize={'lg'}>
                  Pulse Rate: {record?.pulseRate ? `${record.pulseRate} bpm` : noInfo}
                </Text>
              </HStack>
            </HStack>
            <HStack>
              <HStack w={'50%'}>
                <Box w={16}>
                  <FontAwesome5 name='lungs' size={30} color='#4EA0F7' />
                </Box>
                <Text color={'coolGray.500'} fontSize={'lg'}>
                  Respiratory Rate: {record?.respiratoryRate ? `${record.respiratoryRate} bpm` : noInfo}
                </Text>
              </HStack>
              <HStack w={'50%'}>
                <Box w={16}>
                  <FontAwesome5 name='temperature-high' size={30} color='#4EA0F7' />
                </Box>
                <Text color={'coolGray.500'} fontSize={'lg'}>
                  Body Temperature: {record?.bodyTemperature ? `${record.bodyTemperature} °C` : noInfo}
                </Text>
              </HStack>
            </HStack>
          </VStack>
        </VStack>

        <Divider />

        <VStack px={10} pt={5} pb={2}>
          <Text fontWeight={'bold'} fontSize={'xl'}>
            Details Information
          </Text>
          <HStack mt={4}>
            <VStack mt={2} justifyContent={'space-between'}>
              <HStack mb={3} alignItems={'center'}>
                <Box w={8}>
                  <FontAwesome5 name='head-side-cough' size={24} color='#4EA0F7' />
                </Box>
                <Text ml={3} color={'coolGray.500'} fontSize={'lg'}>
                  Symptoms: {record?.symptoms ? record.symptoms : noInfo}
                </Text>
              </HStack>
              <HStack mb={3} alignItems={'center'}>
                <Box w={8}>
                  <FontAwesome5 name='notes-medical' size={24} color='#4EA0F7' />
                </Box>
                <Text ml={3} color={'coolGray.500'} fontSize={'lg'}>
                  Diagnosis: {record?.diagnosis ? record.diagnosis : noInfo}
                </Text>
              </HStack>
              <HStack mb={3} alignItems={'center'}>
                <Box w={8}>
                  <FontAwesome5 name='capsules' size={24} color='#4EA0F7' />
                </Box>
                <Text ml={3} color={'coolGray.500'} fontSize={'lg'}>
                  Medication: {isFhirResource ? noInfo : 'Prozac (Fluoxetine), Lexapro (Escitalopram), Zoloft (Sertraline), Cymbalta (Duloxetine)'}
                </Text>
              </HStack>
              <HStack mb={3} alignItems={'center'}>
                <Box w={8}>
                  <MaterialCommunityIcons name='doctor' size={24} color='#4EA0F7' />
                </Box>
                <Text ml={3} color={'coolGray.500'} fontSize={'lg'}>
                  {record?.dischargeInstruction}
                  Discharge Instruction: {record?.dischargeInstruction ? record.dischargeInstruction : noInfo}
                </Text>
              </HStack>
            </VStack>
          </HStack>
        </VStack>

        <Divider />

        <VStack space={2} mt={5} px={5}>
          {!isFhirResource && <ComparedImagesModalButton />}
          <Text fontWeight={'bold'} fontSize={'xl'} ml={19}>
            Lab Test Results
          </Text>
          <FlatList
            data={labTestResult}
            renderItem={({ item }) => (
              <Pressable rounded={'lg'} px={4} mb={2} mx={5}>
                <VStack>
                  <HStack alignItems={'center'} py={2}>
                    <Box w={8}>
                      <Fontisto name='blood-test' size={24} color='#4EA0F7' />
                    </Box>
                    <VStack ml={2}>
                      <Text fontWeight={'bold'} color={'black'} fontSize={'lg'}>
                        {item.testType}
                      </Text>
                      <Text color={'black'} fontSize={'lg'}>
                        {item && formatDate(item.timestamp)}
                      </Text>
                      <Text fontWeight={'medium'} color={'black'} fontSize={'lg'}>
                        {item.result}
                      </Text>
                    </VStack>
                  </HStack>
                  {item.images && item.images.length > 0 && <LabTestImageModal images={item.images} type={item.testType} />}
                </VStack>
              </Pressable>
            )}
            keyExtractor={(item) => item.id.toString()}
            showsHorizontalScrollIndicator={false}
            ItemSeparatorComponent={() => <Box w={4} />}
          />
        </VStack>

        <Divider />

        <VStack px={10} space={4} mb={{ base: '12', md: '0' }}>
          <Text fontWeight={'bold'} fontSize={'xl'}>
            Billing Information
          </Text>
          <HStack>
            <Box w={8}>
              <Foundation name='clipboard-notes' size={24} color='#4EA0F7' />
            </Box>
            <Text color={'coolGray.600'} fontSize={'md'}>
              {record?.billingInfo ? `Total of $ ${record.billingInfo}` : noInfo}
            </Text>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};
export default MedicalDetail;
