import React from 'react';
import { Box, Button, Divider, Icon, ScrollView, Text, VStack } from 'native-base';
import { Entypo, Ionicons } from '@expo/vector-icons';
import { NavigationProp, useNavigation, useRoute } from '@react-navigation/native';
import { RootStackScreensEnum } from '../navigation/enum';
import { RootStackParamList } from '../navigation/types';

type Icon = {
  iconName: string;
  iconText: string;
  route: RootStackScreensEnum;
};
export type Account = {
  iconName: string;
  iconText: string;
};

const list: Icon[] = [
  { iconName: 'key-outline', iconText: 'Change Password', route: RootStackScreensEnum.ChangePassword },
  { iconName: 'md-person-outline', iconText: 'Profile', route: RootStackScreensEnum.Profile },
  { iconName: 'earth-outline', iconText: 'Language', route: RootStackScreensEnum.Language },
  { iconName: 'ios-notifications-outline', iconText: 'Notifications', route: RootStackScreensEnum.Notifications },
  { iconName: 'lock-closed-outline', iconText: 'Disable Account', route: RootStackScreensEnum.DisableAccount }
];
export default function Sidebar() {
  const route = useRoute();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const onPressCategory = (category: Account) => {
    switch (category.iconText) {
      case 'Change Password':
        navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.ChangePassword });
        break;
      case 'Profile':
        navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.Profile });
        break;
      case 'Language':
        navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.Language });
        break;
      case 'Notifications':
        navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.Notifications });
        break;
      case 'Disable Account':
        navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.DisableAccount });
        break;
      default:
        navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.Profile });
        break;
    }
  };
  return (
    <Box
      width={56}
      borderRightWidth='1'
      display='flex'
      _light={{ bg: 'coolGray.50', borderRightColor: 'coolGray.200' }}
      _dark={{ bg: 'coolGray.900', borderRightColor: 'coolGray.800' }}>
      <Text fontSize='24' color='#0564C8' px='4' py='3' fontWeight={'bold'}>
        Settings
      </Text>
      <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
        <VStack px='2' py='4' justifyContent={'center'}>
          {list.map((item, idx) => {
            return (
              <Button
                onPress={() => onPressCategory(item)}
                key={idx}
                variant='ghost'
                justifyContent='flex-start'
                py='3'
                px='2'
                backgroundColor={item.route === route.name ? 'primary.100' : 'transparent'}
                _light={{
                  _text: { color: item.route === route.name ? '#0564C8' : '#2D2D2D' },
                  _icon: { color: item.route === route.name ? '#0564C8' : '#2D2D2D' }
                }}
                _dark={{
                  _text: { color: 'coolGray.50' },
                  _icon: { color: 'coolGray.50' }
                }}
                _text={{
                  fontSize: 'md',
                  fontWeight: 'medium'
                }}
                _hover={{
                  _text: {
                    _light: {
                      color: '#0564C8'
                    },
                    _dark: {
                      color: 'primary.500'
                    }
                  },

                  _icon: {
                    _light: {
                      color: '#0564C8'
                    },
                    _dark: {
                      color: 'primary.500'
                    }
                  },
                  _light: {
                    bg: 'primary.100'
                  },
                  _dark: {
                    bg: 'coolGray.800'
                  }
                }}
                leftIcon={<Icon size='7' mr='2' as={Ionicons} name={item.iconName} />}>
                {item.iconText}
              </Button>
            );
          })}
        </VStack>
      </ScrollView>
      <Divider _dark={{ bgColor: 'coolGray.800' }} />
      <Box px={2}>
        <Button
          onPress={() => navigation.goBack()}
          variant='ghost'
          justifyContent='flex-start'
          py='3'
          px='2'
          _light={{
            _text: { color: '#2D2D2D' },
            _icon: { color: '#2D2D2D' }
          }}
          _dark={{
            _text: { color: 'coolGray.50' },
            _icon: { color: 'coolGray.50' }
          }}
          _text={{
            fontSize: 'md',
            fontWeight: 'medium'
          }}
          _hover={{
            _text: {
              _light: {
                color: '#0564C8'
              },
              _dark: {
                color: 'primary.500'
              }
            },

            _icon: {
              _light: {
                color: '#0564C8'
              },
              _dark: {
                color: 'primary.500'
              }
            },
            _light: {
              bg: 'primary.100'
            },
            _dark: {
              bg: 'coolGray.800'
            }
          }}
          leftIcon={<Icon size='7' mr='2' as={Entypo} name='back' />}>
          Back
        </Button>
      </Box>
    </Box>
  );
}
