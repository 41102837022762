import { createEntityAdapter, createSlice, PayloadAction, Update } from '@reduxjs/toolkit';
import { IUser } from '../../../types/IUser';

const usersEntityAdapter = createEntityAdapter<IUser>({
  selectId: (user) => user.id
});

const initialState = usersEntityAdapter.getInitialState();
type IUsersState = typeof initialState;

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addUser: (state: IUsersState, action: PayloadAction<IUser>) => {
      usersEntityAdapter.upsertOne(state, action.payload);
    },
    addUsers: (state: IUsersState, action: PayloadAction<IUser[]>) => {
      usersEntityAdapter.upsertMany(state, action.payload);
    },
    removeUser: (state: IUsersState, action: PayloadAction<string>) => {
      usersEntityAdapter.removeOne(state, action.payload);
    },
    removeUsers: (state: IUsersState, action: PayloadAction<string[]>) => {
      usersEntityAdapter.removeMany(state, action.payload);
    },
    updateUser: (state: IUsersState, action: PayloadAction<Update<IUser>>) => {
      usersEntityAdapter.updateOne(state, action.payload);
    },
    updateUsers: (state: IUsersState, action: PayloadAction<Update<IUser>[]>) => {
      usersEntityAdapter.updateMany(state, action.payload);
    },
    resetUsers: () => {
      return initialState;
    }
  }
});

export const selectAllUsers = usersEntityAdapter.getSelectors().selectAll;
export const selectUserById = usersEntityAdapter.getSelectors().selectById;

export const usersActions = usersSlice.actions;
export default usersSlice;
