import { API, graphqlOperation } from 'aws-amplify';
import { GetUserQuery, ListUsersQuery, UpdateUserMutation, UserByDoctorIDQuery } from '../../API';
import { GraphQLResult } from '@aws-amplify/api';
import { getUser, listUsers, userByDoctorID } from '../../graphql/queries';
import { IUser } from '../../types/IUser';
import { usersActions } from '../../store/hospital/users/users-slice';
import { updateUser } from '../../graphql/mutations';

export const graphQLGetUser = async (userID: string) => {
  const user = (await API.graphql(graphqlOperation(getUser, { id: userID }))) as GraphQLResult<GetUserQuery>;

  if (user.data?.getUser) {
    return !user.data.getUser._deleted ? user.data.getUser : null;
  } else {
    return null;
  }
};

export const graphQLFetchUsersAndDispatch = async (dispatch: any) => {
  const users = (await API.graphql(graphqlOperation(listUsers))) as GraphQLResult<ListUsersQuery>;

  if (users.data?.listUsers?.items) {
    const usersData = users.data.listUsers.items
      .filter((user) => user !== null && !user._deleted)
      .map((user) => {
        return {
          id: user!.id,
          email: user!.email,
          firstName: user!.firstName,
          lastName: user!.lastName,
          address: user!.address,
          birthday: user!.birthday,
          gender: user!.gender,
          phoneNumber: user!.phoneNumber,
          imageUrl: user!.imageUrl,
          userSFUserId: user!.userSFUserId
        } as IUser;
      });

    dispatch(usersActions.addUsers(usersData));
  } else {
    console.log('No users found');
    return [];
  }
};

export const graphQLUpdateUserImage = async (userID: string, imageUrl: string) => {
  // In order to update a user, we need to pass the version of the user. Query the user first to get the version.
  const queryUser = await graphQLGetUser(userID);
  if (queryUser === null) {
    return null;
  }
  console.log('queryUser', queryUser);

  const user = (await API.graphql(
    graphqlOperation(updateUser, {
      input: {
        id: userID,
        imageUrl: imageUrl,
        _version: queryUser._version
      }
    })
  )) as GraphQLResult<UpdateUserMutation>;

  if (user.data?.updateUser) {
    return user.data.updateUser;
  } else {
    return null;
  }
};

export const gqlGetUserWithDoctorID = async (doctorID: string) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get user with doctor ID ${retry} time(s)`);
    }

    try {
      const user = (await API.graphql(
        graphqlOperation(userByDoctorID, {
          userDoctorId: doctorID
        })
      )) as GraphQLResult<UserByDoctorIDQuery>;

      if (user.data?.userByDoctorID?.items && user.data.userByDoctorID.items.length > 0) {
        return !user.data.userByDoctorID.items[0]!._deleted ? user.data.userByDoctorID.items[0] : null;
      } else {
        return null;
      }
    } catch (error) {
      console.log('Error getting user with doctor ID', error);
      retry++;
    }
  }
};
