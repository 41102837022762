import React, { useEffect, useRef } from 'react';
import { Hidden, HStack, Icon, Image, Pressable, Text, VStack } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import { Animated, ImageSourcePropType } from 'react-native';
export type Course = {
  backgroundColor: string;
  id: number;
  name?: string;
  chapter?: string;
  imageUri: ImageSourcePropType;
};

const ResumeCourses = ({ courses }: { courses: Course[] }) => {
  return (
    <VStack justifyContent={'center'} alignSelf={'center'} width={{ base: '90%', md: '48%' }} mx={{ base: 0, md: 4 }}>
      {courses.map((item: Course) => {
        return (
          <Pressable key={item.id}>
            <Card course={item} />
          </Pressable>
        );
      })}
    </VStack>
  );
};

const Card = ({ course }: { course: Course }) => {
  const animatedValue = useRef(new Animated.Value(-1400)).current;
  // To do animation on the card
  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: 10,
      duration: 3000,
      useNativeDriver: false
    }).start();
  }, [animatedValue]);
  return (
    <VStack p={{ base: 2, md: 4 }} width={{ base: '100%', md: '100%' }} height={{ base: '100%', md: '100%' }}>
      <HStack
        rounded={'2xl'}
        height={{ base: '100%', md: '100%' }}
        backgroundColor={course.backgroundColor}
        px='3'
        py='2'
        justifyContent='space-between'
        alignItems='Center'>
        <VStack ml={2}>
          <Text
            ml={{ base: 0, md: 5 }}
            fontSize={{ base: 'md', md: '2xl' }}
            _light={{ color: 'white' }}
            _dark={{ color: 'coolGray.100' }}
            fontWeight='bold'>
            {course.chapter}
          </Text>
          <Text
            ml={{ base: 0, md: 5 }}
            fontSize={{ base: 'sm', md: '2xl' }}
            fontWeight='extrabold'
            _light={{ color: 'white' }}
            _dark={{ color: 'coolGray.100' }}>
            {course.name}
          </Text>
          <Hidden till={'md'}>
            <Icon
              ml={{ base: 0, md: 5 }}
              size='6'
              pt='0.5'
              as={FontAwesome}
              name={'line-chart'}
              _light={{ color: 'white' }}
              _dark={{ color: 'coolGray.50' }}
            />
          </Hidden>
        </VStack>
        <Image rounded={'full'} borderColor={'#fff'} borderWidth={2} size={{ base: '10', md: '20' }} source={course.imageUri} alt='Alternate Text' />
      </HStack>
    </VStack>
    // </Animated.View>
  );
};

export default ResumeCourses;
