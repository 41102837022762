import React from 'react';
import Title from './Title';
import { Box, Button, FlatList, HStack, Icon, Image, Text, VStack } from 'native-base';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { ImageSourcePropType } from 'react-native';

export type Doctor = {
  id: number;
  name: string;
  imageUri: ImageSourcePropType;
  isOnline: boolean;
  department?: string;
  workTime?: string;
  rating?: number;
  cost?: string;
  costReduced?: string;
};

const doctors: Doctor[] = [
  {
    id: 2,
    name: 'Dr. Rama Dani Putra',
    imageUri: require('../screens/assets/doctor.png'),
    isOnline: true,
    department: 'General Practitioner',
    workTime: '7 years',
    rating: 100,
    cost: 'Rp25.000',
    costReduced: 'Rp17.000'
  },
  {
    id: 3,
    name: 'Dr. Rama Dani Putra',
    imageUri: require('../screens/assets/doctor.png'),
    isOnline: true,
    department: 'General Practitioner',
    workTime: '16 years',
    rating: 99,
    cost: 'Rp60.000',
    costReduced: ''
  }
];

const RecommendedDoctors = () => {
  return (
    <>
      <Title title='Recommended Doctors' subTitle='Consult online with our available doctors' hasSeeAllButton={true} />
      <FlatList
        data={doctors}
        showsVerticalScrollIndicator={true}
        renderItem={({ item }: { item: Doctor }) => {
          return <Card doctor={item} />;
        }}
        ItemSeparatorComponent={() => <Box h={{ base: '8', md: '12' }} />}
      />
    </>
  );
};

const Card = ({ doctor }: { doctor: Doctor }) => {
  return (
    <HStack>
      <HStack mr={{ base: '4', md: '8' }}>
        <Box h='40' overflow='hidden' rounded='sm' w='32' position='relative' borderWidth={1} borderColor={'gray.200'}>
          <Box
            w='10px'
            h='10px'
            bg={doctor.isOnline ? 'green.600' : 'gray.400'}
            position='absolute'
            top='2'
            right='2'
            zIndex='100'
            rounded='full'></Box>
          <Image flex='1' source={doctor.imageUri} alt='Alternate Text' />
        </Box>
      </HStack>

      <HStack flex='1' justifyContent='space-between' flexWrap='wrap'>
        <VStack>
          <Text fontWeight='medium' fontSize='md'>
            {doctor.name}
          </Text>
          <Text>{doctor.department}</Text>
          <HStack mt='2' mb='3'>
            <HStack alignItems='center' bg='gray.100' px='3' py='1' mr='2' rounded='md'>
              <Icon as={MaterialCommunityIcons} name='briefcase' size='4' mr='2' color='gray.700' />
              <Text fontWeight='medium'>{doctor.workTime}</Text>
            </HStack>
            <HStack alignItems='center' bg='gray.100' px='3' py='1' mr='2' rounded='md'>
              <Icon as={MaterialIcons} name='thumb-up' size='4' mr='2' color='gray.700' />
              <Text fontWeight='medium'>{doctor.rating}%</Text>
            </HStack>
          </HStack>
          <VStack justifyContent='flex-end' mt='4'>
            <HStack>
              <Text fontWeight='bold'>{doctor.cost}</Text>
              {doctor.costReduced && (
                <Box
                  bgColor='green.500'
                  borderWidth={1}
                  borderStyle='dashed'
                  borderColor='white'
                  ml='2'
                  rounded='full'
                  w='5'
                  h='5'
                  justifyContent='center'
                  alignItems='center'>
                  <Text fontSize='xs' color='white'>
                    %
                  </Text>
                </Box>
              )}
            </HStack>
            {doctor.costReduced && (
              <Text textDecorationLine='line-through' color='gray.400'>
                {doctor.costReduced}
              </Text>
            )}
          </VStack>
        </VStack>

        <VStack justifyContent='flex-end' mt='3'>
          <Button variant='ghost' py='1' px='10' borderWidth={1} borderColor='red.500' rounded='md' bg='red.600'>
            <Text color='white'>Chat</Text>
          </Button>
        </VStack>
      </HStack>
    </HStack>
  );
};

export default RecommendedDoctors;
