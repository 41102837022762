import React, { useState } from 'react';
import { Box, Divider, HStack, Icon, Image, Input, Pressable, ScrollView, Text, Tooltip, VStack } from 'native-base';
import DashboardLayoutPatient from '../../layouts/DashboardLayoutPatient';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import ModalButton from './ModalButton';
import ShowMeetingList from './ShowMeetingList';
import SearchBarPatient from './SearchBarPatient';
import { RootStackScreensEnum } from '../../navigation/enum';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { MainStackParamList } from '../../types/Stacks';
import { Dimensions } from 'react-native';

const AppointmentList = () => {
  const navigation = useNavigation<NativeStackNavigationProp<MainStackParamList, RootStackScreensEnum.Patient>>();
  const [meetingList, setMeetingList] = useState<any>([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [textInput, setTextInput] = useState('');
  const screenWidth = Dimensions.get('window').width;
  const handleSearchTextChange = (text: string) => {
    setTextInput(text);
  };

  const handleToggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    setTextInput('');
  };
  const onHide = (item: any, formik: any) => {
    setMeetingList((prev: any) => {
      return [...prev, { ...item, formik }];
    });
  };
  return (
    <>
      <HStack alignItems={'center'} backgroundColor={'#fff'} justifyContent={'space-between'} height={'64px'} w={'100%'}>
        {!isSearchOpen && (
          <HStack alignItems={'center'} w={'100%'}>
            <Pressable
              mt={1}
              ml={'4'}
              onPress={() => navigation.navigate(RootStackScreensEnum.Patient, { screen: RootStackScreensEnum.DashboardPatient })}
              mr={1}>
              <Icon as={Ionicons} name='arrow-back' size='xl' color={'coolGray.700'} />
            </Pressable>
            <Text w={'95%'} fontSize='20' fontWeight='bold' _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }}>
              Appointment List
            </Text>
            <Pressable
              onPress={handleToggleSearch}
              w={'5%'}
              mr={4}
              p={4}
              alignItems='center'
              justifyContent='center'
              size={'10'}
              rounded='full'
              _hover={{ bg: '#E4EBE4' }}>
              <Icon as={<MaterialIcons name='search' />} size={8} color='coolGray.900' />
            </Pressable>
          </HStack>
        )}
        <Box w={'100%'} px={2}>
          {isSearchOpen && (
            <SearchBarPatient textInput={textInput} handleSearchTextChange={handleSearchTextChange} handleToggleSearch={handleToggleSearch} />
          )}
        </Box>
      </HStack>
      <Divider />
      <VStack h={'100%'} space='2' w={'100%'} backgroundColor={'#fff'}>
        <Image
          source={{
            uri: 'https://images.unsplash.com/photo-1522241112606-b5d35a468795?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
          }}
          style={{ width: screenWidth, height: 200 }}
        />
        <Box mt={5} mb={3}>
          <ModalButton onHide={onHide} />
        </Box>
        <ScrollView h={'100%'} showsVerticalScrollIndicator={false} mb={8}>
          <ShowMeetingList MeetingListProps={meetingList} />
        </ScrollView>
      </VStack>
    </>
  );
};

export default AppointmentList;
