import React, { useEffect } from 'react';
import { Box, FlatList, Hidden, HStack, Image, Pressable, ScrollView, Text, VStack } from 'native-base';
import { RootStackScreensEnum } from '../../navigation/enum';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { AdminStackParamList } from '../../types/Stacks';
import { useAppSelector } from '../../store/hooks';
import { selectAllPatients } from '../../store/hospital/patients/patients-slice';
import { useDebounce } from '../../utils/debounce';
import { selectAllUsers } from '../../store/hospital/users/users-slice';
import { IUser } from '../../types/IUser';
import { IPatient } from '../../types/IPatient';

interface IMyComponentProps {
  data: IPatient[];
}

const MyComponent = ({ data }: IMyComponentProps) => {
  const navigation = useNavigation<NativeStackNavigationProp<AdminStackParamList, RootStackScreensEnum.PatientList>>();
  const users = useAppSelector((state) => selectAllUsers(state.hospital.users));
  const firstFiveDoctors = data.slice(0, 5);
  return (
    <>
      {/*Show on Mobile*/}
      <Hidden from={'md'}>
        <Box backgroundColor={'white'} width={'100%'} rounded={'sm'} py={4}>
          <FlatList
            showsHorizontalScrollIndicator={false}
            horizontal={true}
            w={'95%'}
            data={firstFiveDoctors}
            m={'auto'}
            keyExtractor={(item: IPatient) => item.id.toString()}
            renderItem={({ item }) => {
              const patientUser: IUser | undefined = users.find((user: IUser) => user.id === item.patientUserId);
              return (
                <Pressable
                  shadow={3}
                  borderWidth={1}
                  borderColor={'coolGray.200'}
                  rounded={'xl'}
                  flex={1}
                  mx={3}
                  mb={1}
                  p={3}
                  key={item.id.toString()}
                  onPress={() => navigation.navigate(RootStackScreensEnum.PatientDetails, { patientId: item.id })}>
                  <HStack alignItems='Center'>
                    <Box backgroundColor={'#fff'}>
                      <Image
                        source={patientUser?.imageUrl ? { uri: patientUser?.imageUrl } : undefined}
                        rounded={'md'}
                        size={20}
                        accessibilityLabel={patientUser?.lastName.charAt(0).toUpperCase()}
                        alt={patientUser?.lastName.charAt(0).toUpperCase()}
                      />
                    </Box>
                    <VStack
                      ml={4}
                      space={2}
                      alignItems='start'
                      _light={{ borderBottomColor: 'coolGray.200' }}
                      _dark={{ borderBottomColor: 'coolGray.700' }}>
                      <Text fontWeight='bold' fontSize='16' lineHeight='21' _light={{ color: 'coolGray.900' }} _dark={{ color: 'coolGray.400' }}>
                        {patientUser?.firstName + ' ' + patientUser?.lastName}
                      </Text>
                      <HStack>
                        <Text fontWeight='medium' fontSize='14' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }}>
                          Status: {''}
                        </Text>
                        <Text fontSize='14' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }}>
                          {item?.status ? item.status.replace('_', ' ') : '---'}
                        </Text>
                      </HStack>
                      <HStack>
                        <Text fontWeight='medium' fontSize='14' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }}>
                          Email: {''}
                        </Text>
                        <Text fontSize='14' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }}>
                          {patientUser && patientUser.email && patientUser.email.length > 20
                            ? `${patientUser.email.slice(0, 20)}...`
                            : patientUser?.email}
                        </Text>
                      </HStack>
                    </VStack>
                  </HStack>
                </Pressable>
              );
            }}
          />
        </Box>
      </Hidden>
    </>
  );
};
const RecentPatientMobile = () => {
  const patients = useAppSelector((state) => selectAllPatients(state.hospital.patients));
  const users = useAppSelector((state) => selectAllUsers(state.hospital.users));
  const [searchText, setSearchText] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<IPatient[]>([]);
  const textSearch = useDebounce(searchText, 1000);

  useEffect(() => {
    const searchValue = searchText.toLowerCase();
    const arr = patients.filter((item) => {
      // Find the user by firstName + lastName
      const foundNameUsers = users.filter((user: IUser) => {
        const fullName = user.firstName + ' ' + user.lastName;
        return fullName.toLowerCase().includes(searchValue);
      });

      // Find the user by email
      const foundEmailUsers = users.filter((user: IUser) => {
        return user.email.toLowerCase().includes(searchValue);
      });

      // Return the list of patients that has patientUserId in the list of found users
      return foundNameUsers.some((user) => user.id === item.patientUserId) || foundEmailUsers.some((user) => user.id === item.patientUserId);
    });
    console.log(arr);
    setSearchResults(arr);
  }, [textSearch]);
  return (
    <>
      <VStack>
        <HStack backgroundColor={'#fff'} justifyContent={'space-between'} alignItems={'center'} px={4} py={2}>
          <Text fontSize={'20'} fontWeight={'bold'}>
            Recent Patients
          </Text>
          <Text fontSize={'14'} color={'#4485FD'}>
            View All
          </Text>
        </HStack>
        <ScrollView flex={1} showsHorizontalScrollIndicator={false}>
          <MyComponent data={searchResults || []} />
        </ScrollView>
      </VStack>
    </>
  );
};
export default RecentPatientMobile;
