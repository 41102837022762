import React, { useState } from 'react';
import { Box, Divider, HStack, Icon, Input, Pressable, ScrollView, Text, VStack } from 'native-base';
import { Entypo, FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { selectContact } from '../../store/chat/contacts/contacts-slice';
import { IMessage } from '../../types/IMessage';
import { selectAllMessages } from '../../store/chat/chat-room/chat-room-slice';

const contactId = '9d3f0e7f-dcbd-4e56-a5e8-87b8154e9edf';

function ChatItemComponent(props: IMessage) {
  const { user } = useAuth();
  const isMe = props.userID === user?.id;
  const contact = useAppSelector((state) => selectContact(state, contactId));

  return (
    <Box flexDirection={!isMe ? 'row' : 'row-reverse'}>
      {!contact?.avatar ? (
        <VStack
          minW='32'
          maxW={{ base: '268', md: '396' }}
          borderRadius='sm'
          pt='2'
          pb='1'
          px='2'
          space='0.5'
          _light={{
            bg: !isMe ? 'coolGray.200' : 'primary.500'
          }}
          _dark={{
            bg: !isMe ? 'coolGray.700' : 'coolGray.600'
          }}>
          <Text
            fontSize='sm'
            _light={{
              color: !isMe ? 'coolGray.700' : 'coolGray.50'
            }}
            _dark={{
              color: !isMe ? 'coolGray.200' : 'coolGray.100'
            }}>
            {props.text}
          </Text>
          <HStack space='1' justifyContent='flex-end' alignItems='center'>
            <Text
              fontSize='xs'
              _light={{
                color: !isMe ? 'coolGray.700' : 'coolGray.50'
              }}
              _dark={{
                color: !isMe ? 'coolGray.100' : 'coolGray.50'
              }}>
              {props.createdAt && formatDistanceToNow(new Date(props.createdAt), { addSuffix: true })}
            </Text>
          </HStack>
        </VStack>
      ) : (
        <Box flex='1' alignItems='flex-end'>
          <HStack position='absolute' bottom='1' right='2' zIndex={2} space='1'>
            <Text fontSize='xs' color='coolGray.50'>
              {props.createdAt}
            </Text>
          </HStack>
        </Box>
      )}
    </Box>
  );
}

function ChatInput() {
  const [textInput, setTextInput] = useState('');
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  const onMessageSubmit = () => {
    // TODO (MinhLuan): Send message to server
  };

  return (
    <Box w='100%' _light={{ bg: 'white' }} _dark={{ bg: 'coolGray.800' }}>
      <Divider />
      <HStack alignItems='center' p='3' space={3}>
        <Input
          flex={1}
          _android={{ py: '0.5', px: '4' }}
          py='2'
          px='3'
          size='xl'
          variant='outline'
          rounded='md'
          placeholder='Type a message'
          placeholderTextColor='coolGray.400'
          value={textInput}
          onChangeText={setTextInput}
          InputLeftElement={
            <Pressable onPress={() => console.log('Attach file')}>
              <Icon ml={4} color='coolGray.700' title={'Attach file'} as={Entypo} name='attachment' size='5' />
            </Pressable>
          }
          _light={{
            bg: 'coolGray.100',
            borderColor: 'white'
          }}
          _dark={{
            bg: 'coolGray.700',
            borderColor: 'coolGray.800'
          }}
        />

        <Pressable onPress={() => onMessageSubmit()}>
          <Icon as={MaterialCommunityIcons} name='send' size='6' color={'primary.500'} />
        </Pressable>
      </HStack>
    </Box>
  );
}

function MainContent() {
  const messages = useAppSelector(selectAllMessages);

  return (
    <Box flex='1' _light={{ bg: 'white' }} _dark={{ bg: 'coolGray.800' }}>
      {messages.length && messages.length > 0 ? (
        <ScrollView showsVerticalScrollIndicator={false} bounces={false}>
          <VStack
            _light={{
              bg: { md: 'white' }
            }}
            _dark={{
              bg: { md: 'coolGray.800' }
            }}
            space={{ base: 2, md: 3 }}>
            {messages.map((item, index) => {
              return <ChatItemComponent {...item} key={index} />;
            })}
          </VStack>
        </ScrollView>
      ) : (
        <VStack h={'calc(100% - 64px)'} justifyContent={'center'} alignItems={'center'}>
          <Icon color='primary.600' as={FontAwesome} name='wechat' size='24' />
          <Text color={'coolGray.500'}>Send a message to start a conversation</Text>
        </VStack>
      )}
      <ChatInput />
    </Box>
  );
}

const ChatScreen = () => {
  return <MainContent />;
};

export default ChatScreen;
