import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, Hidden, HStack, Icon, Input, Pressable, Spinner, Text, VStack } from 'native-base';
import DashboardLayout from '../../layouts/DashboardLayout';
import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { MainStackParamList } from '../../types/Stacks';
import { RootStackScreensEnum } from '../../navigation/enum';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import AccountMobileFooter from './AccountMobileFooter';

const Changeform = () => {
  const [show, setShow] = useState(false);
  const navigation = useNavigation<NativeStackNavigationProp<MainStackParamList, RootStackScreensEnum.ChangePassword>>();
  const { errorMessage, setErrorMessage, successMessage, setSuccessMessage, changePassword } = useAuth();
  const route = useRoute();
  // Reset error message and success message when the component is unmounted
  useEffect(() => {
    return () => {
      setErrorMessage('');
      setSuccessMessage('');
    };
  }, [setErrorMessage, setSuccessMessage]);

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old password is required'),
    newPassword: Yup.string()
      .notOneOf([Yup.ref('oldPassword')], 'New password must not be the same as old password')
      .required('New password is required')
      .min(8, 'Password must be at least 8 characters'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm password is required')
  });

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  const onSubmit = async (values: any) => {
    const { oldPassword, newPassword } = values;
    // const { email } = route.params as any;
    try {
      await changePassword(oldPassword, newPassword);
      values.oldPassword = '';
      values.newPassword = '';
      values.confirmPassword = '';
    } catch (error) {
      console.log('Code verification error', error);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ touched, handleChange, handleBlur, handleSubmit, isSubmitting, dirty, values, errors }) => (
        <VStack mt={8} alignSelf={'center'} w={{ base: '80%', md: '45%' }} space={4}>
          {/*BEGIN: Success or error message. Success message in green, error message in red*/}
          {successMessage && (
            <Box bg='green.400' px={4} py={3} rounded='md' _text={{ color: 'white' }}>
              {successMessage}
            </Box>
          )}
          {errorMessage && (
            <Box bg='red.400' px={4} py={3} rounded='md' _text={{ color: 'white' }}>
              {errorMessage}
            </Box>
          )}
          {/* END: Success or error message */}
          <FormControl isInvalid={'oldPassword' in errors && touched.oldPassword}>
            <FormControl.Label>Old Password</FormControl.Label>
            <Input
              rounded={'lg'}
              onBlur={handleBlur('oldPassword')}
              placeholder='Enter your old password'
              onChangeText={handleChange('oldPassword')}
              value={values.oldPassword}
              type={show ? 'text' : 'password'}
              InputRightElement={
                <Pressable onPress={() => setShow(!show)}>
                  <Icon as={<MaterialIcons name={show ? 'visibility' : 'visibility-off'} />} size={5} mr='2' color='muted.400' />
                </Pressable>
              }
            />
            <FormControl.ErrorMessage>{errors.oldPassword}</FormControl.ErrorMessage>
          </FormControl>
          <FormControl isInvalid={'newPassword' in errors && touched.newPassword}>
            <FormControl.Label>New Password</FormControl.Label>
            <Input
              rounded={'lg'}
              onBlur={handleBlur('newPassword')}
              placeholder='Enter your new password'
              onChangeText={handleChange('newPassword')}
              value={values.newPassword}
              type={show ? 'text' : 'password'}
              InputRightElement={
                <Pressable onPress={() => setShow(!show)}>
                  <Icon as={<MaterialIcons name={show ? 'visibility' : 'visibility-off'} />} size={5} mr='2' color='muted.400' />
                </Pressable>
              }
            />
            <FormControl.ErrorMessage>{errors.newPassword}</FormControl.ErrorMessage>
          </FormControl>

          <FormControl isInvalid={'confirmPassword' in errors && touched.confirmPassword}>
            <FormControl.Label>ConfirmPassword</FormControl.Label>
            <Input
              onKeyPress={(event) => {
                if (event.nativeEvent.key === 'Enter') {
                  handleSubmit();
                }
              }}
              rounded={'lg'}
              onBlur={handleBlur('confirmPassword')}
              placeholder='Enter your password'
              onChangeText={handleChange('confirmPassword')}
              value={values.confirmPassword}
              type={show ? 'text' : 'password'}
              InputRightElement={
                <Pressable onPress={() => setShow(!show)}>
                  <Icon as={<MaterialIcons name={show ? 'visibility' : 'visibility-off'} />} size={5} mr='2' color='muted.400' />
                </Pressable>
              }
            />
            <FormControl.ErrorMessage>{errors.confirmPassword}</FormControl.ErrorMessage>
          </FormControl>
          <Button
            w={{ base: '65%', md: '40%' }}
            h={'10'}
            mt={2}
            rounded={'xl'}
            alignSelf={'center'}
            disabled={isSubmitting || !dirty}
            onPress={() => handleSubmit()}
            borderRadius='15'
            bg='#66CDAA'
            _text={{ color: 'white', fontSize: 'xl', fontWeight: 'bold', fontFamily: 'Sketch_Block' }}>
            {isSubmitting ? (
              <Spinner accessibilityLabel='Loading' size='lg' color={'white'} fontWeight={'bold'} fontFamily={'Sketch_Block'} />
            ) : (
              <Text fontSize={'xl'} fontWeight={'bold'} fontFamily={'Patrickhand_Regular'} color={'white'}>
                Submit
              </Text>
            )}
          </Button>
        </VStack>
      )}
    </Formik>
  );
};

export default function ChangePassword() {
  const navigation = useNavigation();
  return (
    <DashboardLayout title='Account' displayMenuButton displayScreenTitle={false} displayAlternateMobileHeader rightPanelMobileHeader={true}>
      <HStack ml={5} mt={10} alignItems={'center'}>
        <Hidden from={'md'}>
          <Pressable mr={2} ml={4} onPress={() => navigation.goBack()}>
            <Icon as={MaterialCommunityIcons} name='arrow-left' size={'xl'} color={'coolGray.700'} />
          </Pressable>
        </Hidden>
        <Text fontSize={{ base: '20', md: '24' }} fontWeight={'bold'}>
          Change Password
        </Text>
      </HStack>
      <Changeform />
      <Hidden from={'md'}>
        <AccountMobileFooter />
      </Hidden>
    </DashboardLayout>
  );
}