import React, { useEffect, useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Box, Button, Center, FormControl, Hidden, HStack, Icon, Image, Input, Pressable, Spinner, Text, VStack } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import GuestLayout from '../../layouts/GuestLayout';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RootStackScreensEnum } from '../../navigation/enum';
import { AuthStackParamList } from '../../types/Stacks';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

function MobileHeader() {
  return (
    <Hidden from='md'>
      <VStack px='4' mt='4' mb='8' space='9'>
        <HStack space='2' style={{ alignItems: 'center' }}></HStack>
        <Text fontSize='4xl' fontWeight='bold' color='coolGray.50'>
          Create new password
        </Text>
      </VStack>
    </Hidden>
  );
}

function SideContainerWeb() {
  return (
    <Center
      flex='1'
      _light={{ bg: '#E0FFFF' }}
      _dark={{ bg: 'primary.700' }}
      borderTopLeftRadius={{ md: 'xl' }}
      borderBottomLeftRadius={{ md: 'xl' }}>
      <VStack style={{ alignItems: 'center' }}>
        <Image source={require('../../assets/logoApp.png')} size={400} alt='Alternate Text' />
      </VStack>
    </Center>
  );
}

const ResetForm = () => {
  const [show, setShow] = useState(false);
  const navigation = useNavigation<NativeStackNavigationProp<AuthStackParamList, RootStackScreensEnum.ResetPassword>>();
  const { errorMessage, setErrorMessage, successMessage, setSuccessMessage, forgotPasswordSubmit } = useAuth();
  const route = useRoute();
  // Reset error message and success message when the component is unmounted
  useEffect(() => {
    return () => {
      setErrorMessage('');
      setSuccessMessage('');
    };
  }, [setErrorMessage, setSuccessMessage]);

  const validationSchema = Yup.object().shape({
    verificationCode: Yup.string()
      .required('Code is required')
      .matches(/^\d{6}$/, 'Invalid code'),
    password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm password is required')
  });

  const initialValues = {
    verificationCode: '',
    password: '',
    confirmPassword: ''
  };

  const onSubmit = async (values: any) => {
    const { verificationCode, password } = values;
    const { email } = route.params as any;
    try {
      await forgotPasswordSubmit(email, verificationCode, password);

      // Redirect to code verification page
      navigation.replace(RootStackScreensEnum.SignIn);
    } catch (error) {
      console.log('Code verification error', error);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ touched, handleChange, handleBlur, handleSubmit, isSubmitting, dirty, values, errors }) => (
        <VStack space={4}>
          {/*BEGIN: Success or error message. Success message in green, error message in red*/}
          {successMessage && (
            <Box bg='green.400' px={4} py={3} rounded='md' _text={{ color: 'white' }}>
              {successMessage}
            </Box>
          )}
          {errorMessage && (
            <Box bg='red.400' px={4} py={3} rounded='md' _text={{ color: 'white' }}>
              {errorMessage}
            </Box>
          )}
          {/* END: Success or error message */}
          <FormControl isInvalid={'verificationCode' in errors && touched.verificationCode}>
            <FormControl.Label>Verification code</FormControl.Label>
            <Input
              onBlur={handleBlur('email')}
              placeholder='Enter your verification code'
              onChangeText={handleChange('verificationCode')}
              value={values.verificationCode}
            />
            <FormControl.ErrorMessage>{errors.verificationCode}</FormControl.ErrorMessage>
          </FormControl>
          <FormControl isInvalid={'password' in errors && touched.password}>
            <FormControl.Label>Password</FormControl.Label>
            <Input
              onBlur={handleBlur('password')}
              placeholder='Enter your password'
              onChangeText={handleChange('password')}
              value={values.password}
              type={show ? 'text' : 'password'}
              InputRightElement={
                <Pressable onPress={() => setShow(!show)}>
                  <Icon as={<MaterialIcons name={show ? 'visibility' : 'visibility-off'} />} size={5} mr='2' color='muted.400' />
                </Pressable>
              }
            />
            <FormControl.ErrorMessage>{errors.password}</FormControl.ErrorMessage>
          </FormControl>

          <FormControl isInvalid={'confirmPassword' in errors && touched.confirmPassword}>
            <FormControl.Label>ConfirmPassword</FormControl.Label>
            <Input
              onKeyPress={(event) => {
                if (event.nativeEvent.key === 'Enter') {
                  handleSubmit();
                }
              }}
              onBlur={handleBlur('confirmPassword')}
              placeholder='Enter your password'
              onChangeText={handleChange('confirmPassword')}
              value={values.confirmPassword}
              type={show ? 'text' : 'password'}
              InputRightElement={
                <Pressable onPress={() => setShow(!show)}>
                  <Icon as={<MaterialIcons name={show ? 'visibility' : 'visibility-off'} />} size={5} mr='2' color='muted.400' />
                </Pressable>
              }
            />
            <FormControl.ErrorMessage>{errors.confirmPassword}</FormControl.ErrorMessage>
          </FormControl>
          <Button
            w={'50%'}
            h={'10'}
            rounded={'xl'}
            alignSelf={'center'}
            disabled={isSubmitting || !dirty}
            onPress={() => handleSubmit()}
            borderRadius='15'
            bg='#66CDAA'
            _text={{ color: 'white', fontSize: 'xl', fontWeight: 'bold', fontFamily: 'Sketch_Block' }}>
            {isSubmitting ? (
              <Spinner accessibilityLabel='Loading' size='lg' color={'white'} fontWeight={'bold'} fontFamily={'Sketch_Block'} />
            ) : (
              <Text fontSize={'xl'} fontWeight={'bold'} fontFamily={'Patrickhand_Regular'} color={'white'}>
                Submit
              </Text>
            )}
          </Button>
        </VStack>
      )}
    </Formik>
  );
};

function ResetComponent() {
  const navigation = useNavigation<NativeStackNavigationProp<AuthStackParamList, RootStackScreensEnum.ResetPassword>>();
  return (
    <>
      <MobileHeader />
      <Box
        flex='1'
        px={{ base: 4, md: 8 }}
        py='8'
        _light={{ bg: 'white' }}
        _dark={{ bg: 'coolGray.800' }}
        justifyContent='space-between'
        borderTopRightRadius={{ base: '2xl', md: 'xl' }}
        borderBottomRightRadius={{ base: '0', md: 'xl' }}
        borderTopLeftRadius={{ base: '2xl', md: '0' }}>
        <Text
          textAlign='center'
          fontSize='5xl'
          fontWeight='bold'
          fontFamily='Patrickhand_Regular'
          _light={{ color: '#66CDAA' }}
          _dark={{ color: 'coolGray.50' }}
          mb={'8'}>
          Create new password
        </Text>
        <ResetForm />
      </Box>
    </>
  );
}

export default function SignUp() {
  return (
    <GuestLayout>
      <Hidden till='md'>
        <SideContainerWeb />
      </Hidden>
      <ResetComponent />
    </GuestLayout>
  );
}
