import { createNativeStackNavigator, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { DoctorStackParamList } from '../types/Stacks';
import { RootStackScreensEnum } from './enum';
import React, { useEffect } from 'react';
import { useAuth } from '../components/auth/AmplifyAuthProvider';
import { RootStackParamList } from './types';
import DashboardDoctor from '../screens/DoctorScreen/DashboarDoctor';
import { useNavigation } from '@react-navigation/native';
import ChatWithPatient from '../screens/DoctorScreen/ChatWithPatient';
import patientList_Doctor from '../screens/DoctorScreen/patientList_Doctor';
import PatientAppointment from '../screens/DoctorScreen/PatientAppointment';
import Roles from '../components/auth/role';

const DoctorStack = createNativeStackNavigator<DoctorStackParamList>();

const DoctorStackScreen = () => {
  const { isSignedIn, checkRole, isReady } = useAuth();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList, RootStackScreensEnum.Main>>();

  // Navigate to AuthStack if user is not signed in
  useEffect(() => {
    (async () => {
      if (isReady) {
        const role = await checkRole();
        if (!isSignedIn) {
          navigation.replace(RootStackScreensEnum.Auth, { screen: RootStackScreensEnum.SignIn });
        }
        if (role === Roles.Admin) {
          navigation.replace(RootStackScreensEnum.Main, {
            screen: RootStackScreensEnum.Admin,
            params: { screen: RootStackScreensEnum.Dashboard }
          });
        }
        if (role === Roles.Patient) {
          navigation.replace(RootStackScreensEnum.Main, {
            screen: RootStackScreensEnum.Patient,
            params: { screen: RootStackScreensEnum.DashboardPatient }
          });
        }
      }
    })();
  }, [isSignedIn, navigation, checkRole, isReady]);

  return (
    <DoctorStack.Navigator
      screenOptions={{
        headerShown: false
      }}
      initialRouteName={RootStackScreensEnum.DashboardDoctor}>
      <DoctorStack.Screen name={RootStackScreensEnum.DashboardDoctor} component={DashboardDoctor} />
      <DoctorStack.Screen name={RootStackScreensEnum.ChatWithPatient} component={ChatWithPatient} />
      <DoctorStack.Screen name={RootStackScreensEnum.patientList_Doctor} component={patientList_Doctor} />
      <DoctorStack.Screen name={RootStackScreensEnum.PatientAppointment} component={PatientAppointment} />
    </DoctorStack.Navigator>
  );
};

export default DoctorStackScreen;
