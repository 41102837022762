import React, { useState } from 'react';
import { Box, Button, FlatList, Hidden, HStack, Icon, Image, Modal, Pressable, Text, Tooltip, VStack } from 'native-base';
import Title from './Title';
import { ImageSourcePropType } from 'react-native';
import { Feather, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';

export type Appointment = {
  id: number;
  name: string;
  creator: string;
  imageUri: ImageSourcePropType;
  disease?: string;
  age?: string;
  datetime?: string;
};

const appointment: Appointment[] = [
  {
    id: 1,
    name: 'Thomas Edison',
    creator: 'Dr John',
    imageUri: {
      uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.43034024544922556.jpg'
    },
    age: '32 years old',
    disease: 'Sleep Problem',
    datetime: '14:00 24/12/2022'
  },
  {
    id: 2,
    name: 'Rose George ',
    creator: 'Dr John',
    imageUri: require('../assets/Rose.png'),
    age: '25 years old',
    disease: 'Sleep Problem',
    datetime: '9:00 25/12/2022'
  },
  {
    id: 3,
    name: 'Samantha Smith',
    creator: 'Dr John',
    imageUri: {
      uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.40549212462773987.jpg'
    },
    age: '27 years old',
    disease: 'Sleep Problem',
    datetime: '8:00 26/12/2022'
  },
  {
    id: 4,
    name: 'John Doe',
    creator: 'Dr John',
    imageUri: {
      uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.5295602336621994.jpg'
    },
    age: '28 years old',
    disease: 'Sleep Problem',
    datetime: '15:00 27/12/2021'
  },
  {
    id: 5,
    name: 'Steve Jobs',
    creator: 'Dr John',
    imageUri: require('../assets/Steven.png'),
    age: '29 years old',
    disease: 'Sleep Problem',
    datetime: '19:00 12/12/2021'
  },
  {
    id: 6,
    name: 'Thomas Edison',
    creator: 'Dr John',
    imageUri: {
      uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.43034024544922556.jpg'
    },
    age: '32 years old',
    disease: 'Sleep Problem',
    datetime: '19:00 12/12/2021'
  },
  {
    id: 7,
    name: 'Thomas Edison',
    creator: 'Dr John',
    imageUri: {
      uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.43034024544922556.jpg'
    },
    age: '32 years old',
    disease: 'Sleep Problem',
    datetime: '19:00 12/12/2021'
  },
  {
    id: 1,
    name: 'Thomas Edison',
    creator: 'Dr John',
    imageUri: {
      uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.43034024544922556.jpg'
    },
    age: '32 years old',
    disease: 'Sleep Problem',
    datetime: '19:00 12/12/2021'
  }
];

const AppointmentWithPatient = () => {
  return (
    <>
      <Title title='Meeting Schedule With Patient' hasSeeAllButton={true} />
      <FlatList
        data={appointment}
        showsVerticalScrollIndicator={true}
        renderItem={({ item }: { item: Appointment }) => {
          return <Card appointment={item} />;
        }}
        ItemSeparatorComponent={() => <Box h={{ base: '8', md: '12' }} />}
      />
    </>
  );
};

const Card = ({ appointment }: { appointment: Appointment }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [micActive, setMicActive] = useState(false);
  const [videoCamActive, setVideoCamActive] = useState(false);
  const [chatActive, setChatActive] = useState(false);
  const [recordActive, setRecordActive] = useState(false);
  const [presentActive, setPresentActive] = useState(false);
  const [moreActive, setMoreActive] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleMicPress = () => {
    setMicActive(!micActive);
  };

  const handleVideoCamPress = () => {
    setVideoCamActive(!videoCamActive);
  };

  const handleChatPress = () => {
    setChatActive(!chatActive);
  };

  const handleRecordPress = () => {
    setRecordActive(!recordActive);
  };

  const handlePresentPress = () => {
    setPresentActive(!presentActive);
  };

  const handleMorePress = () => {
    setMoreActive(!moreActive);
  };
  return (
    <>
      <Hidden till='md'>
        <HStack>
          <HStack mr={{ base: '4', md: '8' }}>
            <Box h='20' overflow='hidden' rounded='full' w='20' position='relative' borderWidth={1} borderColor={'gray.200'}>
              <Image flex='1' source={appointment.imageUri} alt='Alternate Text' />
            </Box>
          </HStack>

          <HStack mt={2} flex='1' justifyContent='space-between' flexWrap='wrap'>
            <VStack>
              <HStack>
                <VStack ml={10}>
                  <Text fontSize='lg' fontWeight='bold' color={'primary.800'}>
                    Appointment Name
                  </Text>
                  <Text fontWeight='bold' fontSize='md'>
                    {appointment.name}
                  </Text>
                </VStack>
                <VStack ml={10}>
                  <Text fontSize='lg' fontWeight='bold' color={'primary.800'}>
                    Creator
                  </Text>
                  <Text fontWeight='bold' fontSize='md'>
                    {appointment.creator}
                  </Text>
                </VStack>
                <VStack ml={10}>
                  <Text fontSize='lg' fontWeight='bold' color={'primary.800'}>
                    Disease
                  </Text>
                  <Text fontWeight='bold' fontSize='md'>
                    {appointment.disease}
                  </Text>
                </VStack>
                <VStack ml={10}>
                  <Text fontSize='lg' fontWeight='bold' color={'primary.800'}>
                    Date
                  </Text>
                  <Text fontWeight='bold' fontSize='md'>
                    {appointment.datetime}
                  </Text>
                </VStack>
              </HStack>
            </VStack>
            <VStack mt={3}>
              <Button variant='ghost' py='1' px='10' borderWidth={1} borderColor='primary.700' rounded='md' bg='primary.600' onPress={openModal}>
                <Text color='white'>Create Meeting</Text>
              </Button>
              <Modal isOpen={isOpen} onClose={closeModal}>
                <Modal.Content maxWidth={{ base: '80%', md: '80%' }} height={{ base: '80%', md: '90%' }} backgroundColor={'coolGray.900'}>
                  <HStack justifyContent='space-between' mb={'20'}>
                    <Box rounded={'lg'} ml={5} mt={5}>
                      <Image rounded={'lg'} source={require('../assets/Steven.png')} alt='Doctor Avatar' size='xl' />
                    </Box>
                  </HStack>
                  <Box alignSelf={'center'} my={32}>
                    <Text fontSize={'xl'} fontWeight={'bold'} color={'white'}>
                      Creating a meeting...
                    </Text>
                  </Box>
                  <HStack mt={232} width={'35%'} justifyContent={'space-around'} alignSelf={'center'} alignItems={'center'}>
                    <Tooltip label={'Mic'} placement='top' bg='black' color='white'>
                      <Pressable
                        width={'12'}
                        height={'12'}
                        borderWidth={1}
                        borderColor='coolGray.600'
                        rounded='full'
                        bg={micActive ? 'red.500' : 'coolGray.500'}
                        onPress={handleMicPress}>
                        <Icon
                          m={'auto'}
                          as={micActive ? <MaterialIcons name='mic-off' /> : <MaterialIcons name='mic' />}
                          size={{ base: '5', md: '7' }}
                          color='white'
                        />
                      </Pressable>
                    </Tooltip>
                    <Tooltip label={'Video cam'} placement='top' bg='black' color='white'>
                      <Pressable
                        width={'12'}
                        height={'12'}
                        borderWidth={1}
                        borderColor='coolGray.600'
                        rounded='full'
                        bg={videoCamActive ? 'red.500' : 'coolGray.500'}
                        onPress={handleVideoCamPress}>
                        <Icon
                          m={'auto'}
                          as={videoCamActive ? <MaterialIcons name='videocam-off' /> : <MaterialIcons name='videocam' />}
                          size={{ base: '5', md: '7' }}
                          color='white'
                        />
                      </Pressable>
                    </Tooltip>
                    <Tooltip label={'Chat'} placement='top' bg='black' color='white'>
                      <Pressable
                        width={'12'}
                        height={'12'}
                        borderWidth={1}
                        borderColor='coolGray.600'
                        rounded='full'
                        bg={chatActive ? 'red.500' : 'coolGray.500'}
                        onPress={handleChatPress}>
                        <Icon m={'auto'} as={<MaterialIcons name='chat' />} size={{ base: '5', md: '7' }} color='white' />
                      </Pressable>
                    </Tooltip>
                    <Tooltip label={'Record'} placement='top' bg='black' color='white'>
                      <Pressable
                        width={'12'}
                        height={'12'}
                        borderWidth={1}
                        borderColor='coolGray.600'
                        rounded='full'
                        bg={recordActive ? 'red.500' : 'coolGray.500'}
                        onPress={handleRecordPress}>
                        <Icon m={'auto'} as={<MaterialCommunityIcons name='record-circle' />} size={{ base: '5', md: '7' }} color='white' />
                      </Pressable>
                    </Tooltip>
                    <Tooltip label={'Present'} placement='top' bg='black' color='white'>
                      <Pressable
                        width={'12'}
                        height={'12'}
                        borderWidth={1}
                        borderColor='coolGray.600'
                        rounded='full'
                        bg={presentActive ? 'red.500' : 'coolGray.500'}
                        onPress={handlePresentPress}>
                        <Icon m={'auto'} as={<MaterialIcons name='present-to-all' />} size={{ base: '5', md: '7' }} color='white' />
                      </Pressable>
                    </Tooltip>
                    <Tooltip label={'More'} placement='top' bg='black' color='white'>
                      <Pressable
                        width={'12'}
                        height={'12'}
                        borderWidth={1}
                        borderColor='coolGray.600'
                        rounded='full'
                        bg={moreActive ? 'red.500' : 'coolGray.500'}
                        onPress={handleMorePress}>
                        <Icon m={'auto'} as={<Feather name='more-vertical' />} size={{ base: '5', md: '7' }} color='white' />
                      </Pressable>
                    </Tooltip>
                    <Tooltip label={'Leave meeting'} placement='top' bg='black' color='white'>
                      <Button
                        _hover={{ bg: 'red.400' }}
                        py='1'
                        px={{ base: '2', md: '10' }}
                        borderWidth={1}
                        borderColor='red.600'
                        rounded='lg'
                        bg='red.500'
                        onPress={closeModal}>
                        <Icon m={'auto'} as={<MaterialIcons name='call-end' />} size={{ base: '5', md: '10' }} color='white' />
                      </Button>
                    </Tooltip>
                  </HStack>
                </Modal.Content>
              </Modal>
            </VStack>
          </HStack>
        </HStack>
      </Hidden>
      <Hidden from='md'>
        <HStack
          shadow={3}
          alignItems={'center'}
          justifyContent={'space-between'}
          rounded={'md'}
          borderWidth={'2'}
          borderColor={'coolGray.200'}
          px={2}
          py={2}>
          <HStack mr={{ base: '6', md: '8' }}>
            <Box h='20' overflow='hidden' rounded='full' w='20' position='relative' borderWidth={1} borderColor={'gray.200'}>
              <Image flex='1' source={appointment.imageUri} alt='Alternate Text' />
            </Box>
          </HStack>

          <HStack mt={2} flex='1' justifyContent='space-between' flexWrap='wrap'>
            <VStack>
              <HStack alignItems={'center'}>
                <Text fontSize='md' fontWeight='medium'>
                  Name:
                </Text>
                <Text fontSize='md'>
                  {''} {appointment.name}
                </Text>
              </HStack>
              <HStack alignItems={'center'}>
                <Text fontSize='md' fontWeight='medium'>
                  Disease:
                </Text>
                <Text fontSize='md'>
                  {''} {appointment.disease}
                </Text>
              </HStack>
              <HStack alignItems={'center'}>
                <Text fontSize='md' fontWeight='medium'>
                  Date:
                </Text>
                <Text fontSize='md'>
                  {''} {appointment.datetime}
                </Text>
              </HStack>
            </VStack>
            <VStack mt={3} alignSelf={'flex-end'}>
              <Button variant='ghost' py='1' px='10' borderWidth={1} borderColor='primary.700' rounded='md' bg='primary.600' onPress={openModal}>
                <Text color='white'>Create Meeting</Text>
              </Button>
              <Modal isOpen={isOpen} onClose={closeModal}>
                <Modal.Content maxWidth={{ base: '80%', md: '80%' }} height={{ base: '80%', md: '90%' }} backgroundColor={'coolGray.900'}>
                  <HStack justifyContent='space-between' mb={'20'}>
                    <Box rounded={'lg'} ml={5} mt={5}>
                      <Image rounded={'lg'} source={require('../assets/Steven.png')} alt='Doctor Avatar' size='lg' />
                    </Box>
                  </HStack>
                  <Box alignSelf={'center'} my={24}>
                    <Text fontSize={'xl'} fontWeight={'bold'} color={'white'}>
                      Creating a meeting...
                    </Text>
                  </Box>
                  <HStack mt={16} width={'90%'} justifyContent={'space-around'} alignSelf={'center'} alignItems={'center'}>
                    <Tooltip label={'Mic'} placement='top' bg='black' color='white'>
                      <Pressable
                        width={'10'}
                        height={'10'}
                        borderWidth={1}
                        borderColor='coolGray.600'
                        rounded='full'
                        bg={micActive ? 'red.500' : 'coolGray.500'}
                        onPress={handleMicPress}>
                        <Icon
                          m={'auto'}
                          as={micActive ? <MaterialIcons name='mic-off' /> : <MaterialIcons name='mic' />}
                          size={{ base: '5', md: '7' }}
                          color='white'
                        />
                      </Pressable>
                    </Tooltip>
                    <Tooltip label={'Video cam'} placement='top' bg='black' color='white'>
                      <Pressable
                        width={'10'}
                        height={'10'}
                        borderWidth={1}
                        borderColor='coolGray.600'
                        rounded='full'
                        bg={videoCamActive ? 'red.500' : 'coolGray.500'}
                        onPress={handleVideoCamPress}>
                        <Icon
                          m={'auto'}
                          as={videoCamActive ? <MaterialIcons name='videocam-off' /> : <MaterialIcons name='videocam' />}
                          size={{ base: '5', md: '7' }}
                          color='white'
                        />
                      </Pressable>
                    </Tooltip>
                    <Tooltip label={'More'} placement='top' bg='black' color='white'>
                      <Pressable
                        width={'10'}
                        height={'10'}
                        borderWidth={1}
                        borderColor='coolGray.600'
                        rounded='full'
                        bg={moreActive ? 'red.500' : 'coolGray.500'}
                        onPress={handleMorePress}>
                        <Icon m={'auto'} as={<Feather name='more-vertical' />} size={{ base: '5', md: '7' }} color='white' />
                      </Pressable>
                    </Tooltip>
                    <Tooltip label={'Leave meeting'} placement='top' bg='black' color='white'>
                      <Button
                        _hover={{ bg: 'red.400' }}
                        py='1'
                        px={{ base: '8', md: '10' }}
                        borderWidth={1}
                        borderColor='red.600'
                        rounded='lg'
                        bg='red.500'
                        onPress={closeModal}>
                        <Icon m={'auto'} as={<MaterialIcons name='call-end' />} size={{ base: '7', md: '10' }} color='white' />
                      </Button>
                    </Tooltip>
                  </HStack>
                </Modal.Content>
              </Modal>
            </VStack>
          </HStack>
        </HStack>
      </Hidden>
    </>
  );
};

export default AppointmentWithPatient;
