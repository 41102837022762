import React, { useEffect } from 'react';
import { Box, HStack, Icon, Pressable, Text, VStack } from 'native-base';
import { Fontisto } from '@expo/vector-icons';
import { FlatList } from 'react-native';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAllMedicalRecords } from '../../../store/hospital/medical-record/medical-records-slice';
import { formatSimpleDate } from '../../../utils/time_utils';
import { imagesComparationModalActions } from '../../../store/hospital/medical-record/images-comparation-modal-slice';

type MedicalListProps = {
  setFirstItemId: (id: string) => void;
  firstItemId: string;
  isLeft?: boolean;
  selectedMedicalRecordId: string;
};

const MedicalList = ({ setFirstItemId, firstItemId, isLeft = true, selectedMedicalRecordId }: MedicalListProps) => {
  const records = useAppSelector((state) => selectAllMedicalRecords(state.hospital.medicalRecords));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (records && records.length > 0 && records[0].id) {
      dispatch(imagesComparationModalActions.setSelectedMedicalRecordLeft(records[0].id));
      dispatch(imagesComparationModalActions.setSelectedMedicalRecordRight(records[0].id));
    }
  }, [records]);

  const handlePressItem = (id: string) => {
    if (isLeft) {
      dispatch(imagesComparationModalActions.setSelectedMedicalRecordLeft(id));
    } else {
      dispatch(imagesComparationModalActions.setSelectedMedicalRecordRight(id));
    }
  };

  return (
    <>
      <VStack w={{ base: '35%', md: '100%' }} h={'100%'} backgroundColor={'white'} rounded={'lg'} space='2'>
        <Text fontWeight={'bold'} fontSize={'lg'} color={'black'} p={2}>
          Medical Records
        </Text>
        <FlatList
          showsVerticalScrollIndicator={false}
          data={records}
          renderItem={({ item }) => (
            <Pressable
              rounded={'lg'}
              _hover={{ backgroundColor: 'blue.100' }}
              pl={2}
              mb={2}
              mx={2}
              backgroundColor={selectedMedicalRecordId === item.id ? 'blue.100' : firstItemId === item.id ? 'blue.100' : 'white'}
              onPress={() => {
                handlePressItem(item.id);
                if (firstItemId === item.id) return;
                setFirstItemId(item.id);
              }}>
              <HStack alignItems={'center'} py={2}>
                <Box mr={4} backgroundColor={'#4EA0F7'} width={8} height={8} rounded={'full'} justifyContent={'center'} alignItems={'center'}>
                  <Icon size={6} alignSelf={'center'} name={'bed-patient'} color={'white'} as={Fontisto} />
                </Box>
                <VStack flex={1}>
                  <Text color={'black'} fontSize={'lg'} fontWeight={'bold'} numberOfLines={1}>
                    {item.diagnosis}
                  </Text>
                  <Text color={'coolGray.600'} fontSize={'md'}>
                    {formatSimpleDate(item.timestamp)}
                  </Text>
                </VStack>
              </HStack>
            </Pressable>
          )}
          keyExtractor={(item) => item.id.toString()}
        />
      </VStack>
    </>
  );
};

export default MedicalList;
