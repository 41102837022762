import React from 'react';
import { Icon, Input } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';

type SearchInputProps = {
  placeholder: string;
};

const SearchInput = ({ placeholder }: SearchInputProps) => {
  return (
    // <Input
    //   py={4}
    //   placeholder={placeholder}
    //   placeholderTextColor={'gray.700'}
    //   borderWidth={1}
    //   bg='coolGray.100'
    //   mt='4'
    //   rounded={'full'}
    //   InputLeftElement={<Icon as={MaterialIcons} name='search' size='6' ml={3} mr={2} />}
    // />
    <Input
      backgroundColor={'white'}
      width={'100%'}
      height={'15%'}
      // onChangeText={handleSearchTextChange}
      // value={textInput}
      rounded={'lg'}
      flex={1}
      py={3}
      _stack={{
        px: 3
      }}
      _light={{
        placeholderTextColor: 'coolGray.500'
      }}
      _dark={{
        bg: 'coolGray.700',
        borderColor: 'coolGray.500',
        color: 'coolGray.100'
      }}
      InputRightElement={
        <Icon
          as={<MaterialIcons name='search' />}
          size={7}
          _light={{
            color: 'coolGray.700'
          }}
          _dark={{
            color: 'coolGray.300'
          }}
        />
      }
      placeholder={placeholder}
    />
  );
};

export default SearchInput;
