import React, { useEffect, useState } from 'react';
import { Box, FlatList, HStack, Image, Pressable, ScrollView, Text, Tooltip, VStack } from 'native-base';
import { Fontisto } from '@expo/vector-icons';
import { useAppSelector } from '../../../store/hooks';
import { selectAllMedicalRecords } from '../../../store/hospital/medical-record/medical-records-slice';
import { IMedicalRecord } from '../../../types/IMedicalRecord';
import { formatDate } from '../../../utils/time_utils';
import { gqlGetTestResultsByMedicalRecordID } from '../../../utils/graphql/test_result_utils';
import { ITestResult } from '../../../types/ITestResult';
import { TouchableOpacity } from 'react-native';

type LabTestResultsProps = {
  setSelectedImage: (image: string) => void;
  image: string;
  selectedMedicalRecordId: string;
};

const LabTestResults = ({ setSelectedImage, image, selectedMedicalRecordId }: LabTestResultsProps) => {
  const medicalRecordList = useAppSelector((state) => selectAllMedicalRecords(state.hospital.medicalRecords));
  const [record, setRecord] = useState<IMedicalRecord>(medicalRecordList[0]);
  const [labTestResult, setLabTestResult] = useState<ITestResult[]>();
  const [firstImageSelected, setFirstImageSelected] = useState('');

  useEffect(() => {
    (async () => {
      if (!record) return;
      const testResultList = await gqlGetTestResultsByMedicalRecordID(record.id);
      const firstImage = testResultList[0]?.images && testResultList[0].images[0];
      setFirstImageSelected(firstImage || '');
      setSelectedImage(firstImage || '');
      setLabTestResult(testResultList);
    })();
  }, [record]);

  useEffect(() => {
    if (medicalRecordList.length > 0 && selectedMedicalRecordId) {
      const recordSelected = medicalRecordList.find((item) => item.id === selectedMedicalRecordId);
      if (recordSelected) {
        setRecord(recordSelected);
      }
    }
  }, [medicalRecordList, selectedMedicalRecordId]);

  const renderItem = ({ item }: { item: string }) => {
    return (
      <TouchableOpacity onPress={() => setSelectedImage(item)}>
        <Image
          source={{ uri: item }}
          style={[
            { width: 100, height: 100, marginRight: 10, borderRadius: 10 },
            (!image ? firstImageSelected === item : image === item) && { borderWidth: 2, borderColor: 'red' }
          ]}
          alt={'test result image'}
        />
      </TouchableOpacity>
    );
  };

  return (
    <VStack w={{ base: '65%', md: '100%' }} backgroundColor={'white'} rounded={'lg'} space='2' h={'100%'}>
      <VStack px={5} h={'full'}>
        <Text fontWeight={'bold'} fontSize={'xl'} my={2}>
          Lab Test Results
        </Text>
        <FlatList
          data={labTestResult}
          renderItem={({ item }) => (
            <Pressable rounded={'lg'} mb={3}>
              <VStack>
                <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                  <HStack alignItems={'center'}>
                    <Box w={8}>
                      <Fontisto name='blood-test' size={24} color='#4EA0F7' />
                    </Box>
                    <VStack mx={2}>
                      <Text fontWeight={'bold'} color={'black'} fontSize={'lg'}>
                        {item.testType}
                      </Text>
                      <Text color={'black'} fontSize={'lg'}>
                        {item && formatDate(item.timestamp)}
                      </Text>
                      <Tooltip label={item.result}>
                        <Text fontWeight={'medium'} color={'black'} fontSize={'lg'}>
                          {item.result.length > 22 ? `${item.result.substring(0, 22)}...` : item.result}
                        </Text>
                      </Tooltip>
                    </VStack>
                    {item.images && <FlatList data={item.images} horizontal keyExtractor={(image) => image} renderItem={renderItem} />}
                  </HStack>
                </ScrollView>
              </VStack>
            </Pressable>
          )}
          keyExtractor={(item) => item.id.toString()}
          showsHorizontalScrollIndicator={false}
          ItemSeparatorComponent={() => <Box w={4} />}
        />
      </VStack>
    </VStack>
  );
};
export default LabTestResults;
