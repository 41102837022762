import React from 'react';
import { Box, VStack, ScrollView, StatusBar, HStack, Pressable, Icon, Text, Hidden } from 'native-base';
import { AntDesign, MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import MenuProfile from '../components/MenuProfile';
import { useAuth } from '../components/auth/AmplifyAuthProvider';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackScreensEnum } from '../navigation/enum';
import HeaderPatient from './HeaderPatient';
import { DoctorStackParamList } from '../types/Stacks';

type DashboardLayoutProps = {
  scrollable?: boolean;
  displayScreenTitle?: boolean;
  displayBackButton?: boolean;
  showIcons?: boolean;
  displaySearchButton?: boolean;
  displayNotificationButton?: boolean;
  displayMenuButton?: boolean;
  displayAlternateMobileHeader?: boolean;
  maxWidth?: number;
  header?: {
    searchbar: boolean;
  };
  mobileHeader?: {
    backButton: boolean;
  };
  title: string;
  subTitle?: string;
  children?: React.ReactNode;
  showGroupInfoHeader?: boolean;
  displayBackIcon?: boolean;
  rightPanelMobileHeader?: boolean;
  rightPanelHasLocation?: boolean;
};

type MainContentProps = DashboardLayoutProps;

type MobileHeaderProps = {
  title: string;
  subTitle?: string;
  backButton: boolean;
  rightPanel?: boolean;
  rightPanelHasLocation?: boolean;
};

function MainContent(props: MainContentProps) {
  const navigation = useNavigation<NavigationProp<DoctorStackParamList>>();
  return (
    <VStack maxW={props.maxWidth} flex={1} width='100%'>
      {props.displayScreenTitle && (
        <Hidden till='md'>
          <HStack mb='4' space={4}>
            <Pressable onPress={() => navigation.navigate(RootStackScreensEnum.DashboardDoctor)}>
              <Icon size='6' pt='0.5' as={AntDesign} name={'arrowleft'} _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }} />
            </Pressable>
            <VStack>
              <Text fontSize='lg' fontWeight='medium' _dark={{ color: 'coolGray.50' }} _light={{ color: 'coolGray.800' }}>
                {props.title}
              </Text>
              <Text _dark={{ color: 'coolGray.50' }} _light={{ color: 'coolGray.800' }} fontSize='sm' fontWeight='normal'>
                {props.subTitle}
              </Text>
            </VStack>
          </HStack>
        </Hidden>
      )}
      {props.children}
    </VStack>
  );
}

export function MobileHeader(props: MobileHeaderProps) {
  const { user } = useAuth();
  const navigation = useNavigation<NavigationProp<DoctorStackParamList>>();
  return (
    <Box px='1' pt='6' pb='2' bg='white'>
      <HStack justifyContent='space-between'>
        <HStack flex='1' justifyContent='space-between' style={{ alignItems: 'center' }} px={2}>
          <>
            {props.title !== 'Dashboard' && props.backButton ? (
              <HStack style={{ alignItems: 'center' }} space={2}>
                <Pressable onPress={() => navigation.navigate(RootStackScreensEnum.DashboardDoctor)}>
                  <Icon size='6' as={AntDesign} name='arrowleft' />
                </Pressable>
                <Text fontSize='md' fontWeight={'medium'}>
                  {props.title}
                </Text>
              </HStack>
            ) : (
              <HStack style={{ alignItems: 'center' }} space='1'>
                <MenuProfile />
                <HStack space='1' ml={1} style={{ alignItems: 'center' }}>
                  <Text fontSize='md' textTransform='uppercase' fontWeight='bold'>
                    {user?.firstName} {user?.lastName}
                  </Text>
                  <Icon as={<MaterialIcons name='keyboard-arrow-down' />} size={7} />
                </HStack>
              </HStack>
            )}

            {/* right panel */}
            {props.rightPanel &&
              (!props.rightPanelHasLocation ? (
                <Icon as={<MaterialCommunityIcons name='clock-time-four-outline' />} size={7} color='coolGray.500' />
              ) : (
                <HStack space='1' style={{ alignItems: 'center' }}>
                  <Text fontSize='md' fontWeight='medium' numberOfLines={1} ellipsizeMode={'tail'} maxW={{ base: 16, sm: 32 }}>
                    Ho Chi Minh City
                  </Text>
                  <Icon as={<MaterialIcons name='location-pin' />} size={5} color='red.500' />
                </HStack>
              ))}
          </>
        </HStack>
      </HStack>
    </Box>
  );
}
export default function DashboardLayoutDoctor({
  displayScreenTitle = true,
  header = {
    searchbar: false
  },
  maxWidth = 2000,
  mobileHeader = {
    backButton: true
  },
  ...props
}: DashboardLayoutProps) {
  return (
    <>
      <StatusBar translucent backgroundColor='transparent' barStyle='light-content' />
      <Box safeAreaTop _light={{ bg: 'primary.900' }} _dark={{ bg: 'coolGray.900' }} />
      <VStack flex={1} _light={{ bg: 'primary.200' }} _dark={{ bg: 'customGray' }}>
        <Hidden from='md'>
          <MobileHeader
            title={props.title}
            subTitle={props.subTitle}
            backButton={mobileHeader.backButton}
            rightPanel={props.rightPanelMobileHeader}
            rightPanelHasLocation={props.rightPanelHasLocation}
          />
        </Hidden>
        <Hidden till='md'>
          <HeaderPatient title={props.title} subTitle={props.subTitle} searchbar={header.searchbar} />
        </Hidden>

        <Box flex={1} safeAreaBottom flexDirection={{ base: 'column', md: 'row' }} bgColor='white'>
          <Hidden till='md'>
            <ScrollView flex={1} p={{ md: 8 }} contentContainerStyle={{ alignItems: 'center', flexGrow: 1 }} showsVerticalScrollIndicator={true}>
              <MainContent {...props} displayScreenTitle={displayScreenTitle} />
            </ScrollView>
          </Hidden>

          <Hidden from='md'>
            <ScrollView showsVerticalScrollIndicator={false}>
              <MainContent {...props} displayScreenTitle={displayScreenTitle} maxWidth={maxWidth} />
            </ScrollView>
          </Hidden>
        </Box>
      </VStack>
    </>
  );
}
