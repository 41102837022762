import React, { useEffect, useRef } from 'react';
import { HStack, Icon, VStack, Button, Hidden, Box, Text, Image, Pressable } from 'native-base';
import { Feather, FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import { Category } from '../../components/CategoriesDoctor';
import DashboardLayoutDoctor from '../../layouts/DashboardLayoutDoctor';
import { useNavigation } from '@react-navigation/native';
import { RootStackScreensEnum } from '../../navigation/enum';
import SearchInput from '../../components/SearchInput';
import { Animated } from 'react-native';
import { DoctorStackParamList } from '../../types/Stacks';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

type Icon = {
  name: string;
  text: string;
};

const services: Category[] = [
  {
    id: 1,
    imageUri: require('../../assets/images/patient-white.png'),
    text: 'Patient List',
    icon: 'doctor',
    iconColor: 'customBlue'
  },
  {
    id: 2,
    imageUri: require('../../assets/images/patient-blue.png'),
    text: `Appointment List`,
    icon: 'calendar',
    iconColor: 'customTurquoise'
  }
];
const footerIcons: Icon[] = [
  { name: 'home', text: 'Home' },
  { name: 'clipboard', text: 'History' },
  { name: 'user', text: 'Profile' },
  { name: 'inbox', text: 'Inbox' },
  { name: 'more-horizontal', text: 'More' }
];

const sites = [
  {
    id: 1,
    text: 'FAQ',
    url: 'https://www.google.com'
  },
  {
    id: 2,
    text: 'Blog',
    url: 'https://www.google.com'
  },
  {
    id: 3,
    text: 'Term & Conditions',
    url: 'https://www.google.com'
  },
  {
    id: 4,
    text: 'Privacy Policy',
    url: 'https://www.google.com'
  },
  {
    id: 5,
    text: 'Promo',
    url: 'https://www.google.com'
  },
  {
    id: 6,
    text: 'Career',
    url: 'https://www.google.com'
  }
];

const contacts = [
  {
    id: 1,
    text: 'Phone: +62 21 12345678'
  },
  {
    id: 2,
    text: 'Email: example@gmail.com'
  }
];

const MobileFooter = () => {
  return (
    <HStack
      justifyContent='space-between'
      safeAreaBottom
      h='20'
      width='100%'
      overflow='hidden'
      alignSelf='center'
      _light={{ backgroundColor: 'coolGray.50' }}
      _dark={{ backgroundColor: 'coolGray.800' }}>
      {footerIcons.map((item, index) => {
        return (
          <Button
            key={index}
            variant='ghost'
            colorScheme='coolGray'
            _stack={{
              flexDirection: 'column'
            }}
            startIcon={
              <Icon
                as={Feather}
                name={item.name}
                size='5'
                _light={{
                  color: index === 0 ? 'primary.900' : 'coolGray.400'
                }}
                _dark={{
                  color: index === 0 ? 'primary.500' : 'coolGray.400'
                }}
              />
            }
            _text={{
              _light: {
                color: index === 0 ? 'primary.900' : 'coolGray.400'
              },
              _dark: {
                color: index === 0 ? 'primary.500' : 'coolGray.400'
              }
            }}>
            {item.text}
          </Button>
        );
      })}
    </HStack>
  );
};

const Footer = () => {
  return (
    <Box h='80' mb={-8} py={10} pl={64} _light={{ backgroundColor: 'coolGray.700' }} _dark={{ backgroundColor: 'coolGray.900' }}>
      <HStack width='100vw' space={40}>
        <VStack>
          <Image source={require('../../assets/logoApp.png')} size={40} alt='Alternate Text' />
        </VStack>
        <VStack>
          <Text fontWeight={'medium'} fontSize={'lg'} color={'coolGray.50'} mb={4}>
            Site Map
          </Text>
          {sites.map((item) => {
            return (
              <Pressable key={item.id} mb={2} onPress={() => console.log(item.url)}>
                <Text color={'coolGray.50'}>{item.text}</Text>
              </Pressable>
            );
          })}
        </VStack>
        <VStack>
          <Text fontWeight={'medium'} fontSize={'lg'} color={'coolGray.50'} mb={4}>
            Contact Us
          </Text>
          {contacts.map((item) => {
            return (
              <Text key={item.id} mb={2} color={'coolGray.50'}>
                {item.text}
              </Text>
            );
          })}
        </VStack>
      </HStack>
    </Box>
  );
};

const DashboardDoctor = () => {
  const navigation = useNavigation<NativeStackNavigationProp<DoctorStackParamList, RootStackScreensEnum.DashboardDoctor>>();
  const onPressCategory = (category: Category) => {
    switch (category.text) {
      case 'Patient List':
        navigation.navigate(RootStackScreensEnum.patientList_Doctor);
        break;
      case 'Appointment List':
        navigation.navigate(RootStackScreensEnum.PatientAppointment);
        break;
      default:
        navigation.navigate(RootStackScreensEnum.DashboardDoctor);
        break;
    }
  };
  const animatedValue = useRef(new Animated.Value(-200)).current;

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: 10,
      duration: 3000,
      useNativeDriver: false
    }).start();
  }, [animatedValue]);

  const animatedValue2 = useRef(new Animated.Value(2600)).current;

  useEffect(() => {
    Animated.timing(animatedValue2, {
      toValue: 10,
      duration: 3000,
      useNativeDriver: false
    }).start();
  }, [animatedValue2]);
  return (
    <DashboardLayoutDoctor
      title='Dashboard'
      displayMenuButton
      displayScreenTitle={false}
      displayAlternateMobileHeader
      rightPanelMobileHeader={true}
      rightPanelHasLocation={true}>
      <HStack mx={-8} mt={-8} w={'calc(100% + 64px)'} h={'600'} position={'relative'} justifyContent={'center'}>
        <Image position={'absolute'} source={require('../../assets/images/hospital_doctor.png')} w={'100%'} h={'100%'} alt='image base' />
        <VStack zIndex={100} mt={'300'} alignItems={'center'} w={'30%'}>
          <Text textTransform={'uppercase'} fontSize={'4xl'} color={'#FF3300'} textAlign={'center'} fontWeight={'bold'}>
            Comprehensive health care medical base
          </Text>
          <Box w={'80%'}>
            <SearchInput placeholder={'Search for a patient'} />
          </Box>
          <Button
            variant='ghost'
            py='4'
            px='6'
            mt={4}
            bg={'primary.600'}
            rounded={'3xl'}
            shadow={9}
            _light={{
              _text: { color: 'white' },
              _icon: { color: 'coolGray.800' }
            }}
            _dark={{
              _text: { color: 'coolGray.50' },
              _icon: { color: 'coolGray.50' }
            }}
            _text={{
              fontSize: 'xl',
              fontWeight: 'medium',
              textTransform: 'uppercase'
            }}
            _hover={{
              _light: {
                bg: 'secondary.800'
              },
              _dark: {
                bg: 'coolGray.800'
              }
            }}
            _pressed={{
              _light: {
                bg: 'secondary.600'
              },
              _dark: {
                bg: 'coolGray.800'
              }
            }}
            leftIcon={<Icon size='4' mr='2' as={FontAwesome} name={'calendar'} color={'white'} />}>
            Create appointment
          </Button>
        </VStack>
      </HStack>

      <VStack
        px={{ base: 4, md: 32 }}
        pt={{ base: 39, md: 8 }}
        pb={{ base: 10, md: 8 }}
        _light={{ bg: 'white' }}
        _dark={{ bg: 'coolGray.800' }}
        rounded={{ md: 'sm' }}
        w={'100%'}>
        {/*<HStack justifyContent={'center'}>*/}
        <HStack justifyContent={'center'} w={'100%'} mb={12}>
          {services.map((item) => {
            return (
              <Animated.View
                key={item.id}
                style={{
                  marginLeft: item.id === 1 ? animatedValue : animatedValue2
                }}>
                <Box key={item.id} w={{ base: 48, md: '650' }} h={{ base: 56, md: '400' }} rounded={'full'} position={'relative'} shadow={9} m={5}>
                  <Text w={'55%'} fontSize={{ base: 'md', md: '2xl' }} color={'#fff'} zIndex={100} fontWeight={'bold'} pl={8} pt={8}>
                    {item.text}
                  </Text>
                  <Box zIndex={100} position={'absolute'} bottom={8} left={8} p={5} rounded={'full'} bg={'white'}>
                    <Icon size={'3xl'} as={MaterialCommunityIcons} name={item.icon} color={item.iconColor} />
                  </Box>
                  <Pressable position={'absolute'} w={'100%'} h={'100%'} onPress={() => onPressCategory(item)}>
                    <Image rounded={'3xl'} source={item.imageUri} alt={'Image'} w={'100%'} h={'100%'} />
                  </Pressable>
                </Box>
              </Animated.View>
            );
          })}
        </HStack>
      </VStack>

      <Hidden till={'md'}>
        <Footer />
      </Hidden>
      <Hidden from={'md'}>
        <MobileFooter />
      </Hidden>
    </DashboardLayoutDoctor>
  );
};

export default DashboardDoctor;
