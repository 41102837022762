// Create current timestamp with UTC timezone, ISO 8601 format
export const currentTimestampString = () => {
  return new Date().toISOString();
};

export const formatSimpleDate = (isoDateString: string) => {
  return new Date(isoDateString).toISOString().slice(0, 10);
};

// Format date as "Sun, 1 Jan 2020, 12:00 AM"
export const formatDate = (isoDateString: string) => {
  const date = new Date(isoDateString);
  if (isNaN(date.getTime())) {
    return '';
  }
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const dayOfMonth = date.getDate();
  const monthOfYear = monthsOfYear[date.getMonth()];
  const year = date.getFullYear();

  let hour = date.getHours();
  let amPm = 'AM';
  if (hour > 12) {
    hour -= 12;
    amPm = 'PM';
  }
  const minute = date.getMinutes().toString().padStart(2, '0');

  return `${dayOfWeek}, ${dayOfMonth} ${monthOfYear} ${year}, ${hour}:${minute} ${amPm}`;
};

// Calculate age in years or months
export const calculateAge = (birthday: string) => {
  const birthdayDate = new Date(birthday);
  const today = new Date();
  const ageInYears = today.getFullYear() - birthdayDate.getFullYear();
  const ageInMonths = today.getMonth() - birthdayDate.getMonth();
  const totalAgeInMonths = ageInYears * 12 + ageInMonths;

  // Determine whether to use months or years
  if (Math.abs(totalAgeInMonths) < 24) {
    return `${totalAgeInMonths}-month`;
  } else {
    return `${ageInYears}-year`;
  }
};

export const calculateDistanceBetween2DateInDays = (date1: Date, date2: Date) => {
  if (!date1 || !date2) {
    return 0;
  }
  // calculate the difference in milliseconds between the two dates
  const distance = Math.abs(date1.getTime() - date2.getTime());

  // convert the difference to days
  return distance / (1000 * 60 * 60 * 24);
};
