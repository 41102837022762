import { createEntityAdapter, createSlice, PayloadAction, Update } from '@reduxjs/toolkit';
import { IPatientDisease } from '../../types/IPatientDisease';

const patientsDiseasesEntityAdapter = createEntityAdapter<IPatientDisease>({
  selectId: (patientDisease) => patientDisease.patientId
});

const initialState = patientsDiseasesEntityAdapter.getInitialState();

type IPatientsDiseasesState = typeof initialState;

const patientsDiseasesSlice = createSlice({
  name: 'patientsDiseases',
  initialState,
  reducers: {
    addPatientDisease: (state: IPatientsDiseasesState, action: PayloadAction<IPatientDisease>) => {
      patientsDiseasesEntityAdapter.upsertOne(state, action.payload);
    },
    addPatientsDiseases: (state: IPatientsDiseasesState, action: PayloadAction<IPatientDisease[]>) => {
      patientsDiseasesEntityAdapter.upsertMany(state, action.payload);
    },
    removePatientDisease: (state: IPatientsDiseasesState, action: PayloadAction<string>) => {
      patientsDiseasesEntityAdapter.removeOne(state, action.payload);
    },
    removePatientsDiseases: (state: IPatientsDiseasesState, action: PayloadAction<string[]>) => {
      patientsDiseasesEntityAdapter.removeMany(state, action.payload);
    },
    updatePatientDisease: (state: IPatientsDiseasesState, action: PayloadAction<Update<IPatientDisease>>) => {
      patientsDiseasesEntityAdapter.updateOne(state, action.payload);
    },
    updatePatientsDiseases: (state: IPatientsDiseasesState, action: PayloadAction<Update<IPatientDisease>[]>) => {
      patientsDiseasesEntityAdapter.updateMany(state, action.payload);
    },
    resetPatientsDiseases: () => {
      return initialState;
    }
  }
});

export const patientsDiseasesActions = patientsDiseasesSlice.actions;
export default patientsDiseasesSlice;
