import CryptoES from 'crypto-es';
import { DES, enc, mode } from 'crypto-js';
import axios from 'axios';
import Constants from 'expo-constants';
// import * as https from 'https';

// TODO (MinhLuan): get from the current user session
const DEFAULT_IV = 'sfx^c0de';

// Action ID enum
export enum ActionID {
  GET_CONTACT_LIST = 3040,
  GET_CONTACT_INFO = 3070,
  ADD_CONTACT = 3050,
  REMOVE_CONTACT = 3060,
  CHANGE_CONTACT_GROUP_NAME = 3055,
  REMOVE_CONTACT_GROUP = 3065,
  GET_USER_BASIC_INFO = 140,
  UPDATE_USER_BASIC_INFO = 130,
  CHANGE_USER_PASSWORD = 135,
  GET_USER_LIST = 120,
  UPLOAD_OR_REMOVE_USER_AVATAR = 145,
  GET_USER_GROUP_TREE = 3100,
  GET_USER_GROUP_TREE2 = 3500,
  GET_GROUP_USER = 3105,
  CHECK_APP_VERSION = 3300,
  GET_LIST_MEETING = 745
}

const encryptDES = (data: string, key: string, iv: string) => {
  const keyHex = enc.Utf8.parse(key);
  const ivHex = enc.Utf8.parse(iv);

  return DES.encrypt(data, keyHex, {
    iv: ivHex,
    mode: mode.CBC
  });
};

const decryptDES = (data: string, key: string, iv: string) => {
  const keyHex = enc.Utf8.parse(key);
  const ivHex = enc.Utf8.parse(iv);

  return DES.decrypt(data, keyHex, {
    iv: ivHex,
    mode: mode.CBC
  });
};

const getKeyFromIv = (ivStr: string): string => {
  // Encode ivStr to utf8, then calculate the md5 hash of the encoded ivStr
  // then hex encode the hash, then take characters 8-24 of the hex encoded hash,
  // then take the even characters of the 16 characters
  // Final result is a key of string of 8 characters
  return CryptoES.MD5(ivStr)
    .toString()
    .slice(8, 24)
    .match(/.{2}/g)!
    .map((x) => x[0])
    .join('');
};

const encodeAndEncrypt = (ivStr: string, x: string): string => {
  // Get key from ivStr
  const key = getKeyFromIv(ivStr);

  // Encrypt x using MD5 and then concat x with the encrypted x
  const newX = CryptoES.MD5(x).toString() + x;

  // Encrypt the new x using DES, then convert to base64
  const ct_bytes = encryptDES(newX, key, ivStr);
  return enc.Base64.stringify(ct_bytes.ciphertext);
};

const decryptAndDecode = (ivStr: string, x: string): string => {
  // Get key from ivStr
  const key = getKeyFromIv(ivStr);

  // Decrypt x using DES
  const pt = decryptDES(x, key, ivStr);

  // Convert decrypted x to string
  const ptStr = enc.Utf8.stringify(pt);

  // ptStr is in the format of md5(x) + x, x is a JSON string
  // Get the x from ptStr
  const xStr = ptStr.slice(32);

  // Get the md5(x) from ptStr
  const md5x = ptStr.slice(0, 32);

  // Calculate the md5 of xStr
  const md5xStr = CryptoES.MD5(xStr).toString();

  // Compare the md5 of x with the md5(x) from ptStr
  if (md5x !== md5xStr) {
    throw new Error('md5(x) does not match');
  }

  return xStr;
};

interface ISfWebServiceLoginResponse {
  sfAccessToken: string;
  decodedAndDecryptedStr: string;
}

const sfWebServiceLogin = async (username: string, password: string) => {
  // // Make a json object
  // const json = {
  //   c: DEFAULT_IV,
  //   u: username,
  //   p: password
  // };
  //
  // // Convert the json object to a string
  // const jsonStr = JSON.stringify(json);
  //
  // // Encode and encrypt the string
  // const encodedAndEncryptedStr = encodeAndEncrypt(DEFAULT_IV, jsonStr);
  //
  // // Login to the web service using axios POST multipart/form-data
  // const formData = new FormData();
  // formData.append('x', encodedAndEncryptedStr);
  // const response = await axios.post(`${Constants.expoConfig?.extra?.sfWebServiceUrl}/oauth/x.php`, formData, {
  //   headers: {
  //     'Content-Type': 'multipart/form-data',
  //     'Allow-Control-Allow-Origin': '*',
  //     'Allow-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
  //     'Allow-Control-Allow-Headers': 'X-Requested-With,content-type'
  //   }
  //   // httpsAgent: new https.Agent({
  //   //   rejectUnauthorized: false
  //   // })
  // });

  // // Get the response data
  // const responseData = response.data;
  //
  // // Decrypt and decode the response data
  // if (responseData && responseData.i.toUpperCase() === 'OK') {
  //   const decodedAndDecryptedStr = decryptAndDecode(DEFAULT_IV, responseData.x);
  //   const decodedAndDecryptedJson = JSON.parse(decodedAndDecryptedStr);
  //   return { sfAccessToken: decodedAndDecryptedJson.access_token, decodedAndDecryptedStr };
  // } else {
  //   throw new Error('Cannot login to web service');
  // }
  const response = await axios.post(`/auth/signin-web-service`, {
    username,
    password
  });
  const responseData = response.data;
  if (responseData?.sfAccessToken && responseData.decodedAndDecryptedStr) {
    return { sfAccessToken: responseData.sfAccessToken, decodedAndDecryptedStr: responseData.decodedAndDecryptedStr };
  } else {
    throw new Error('Cannot login to web service');
  }
};

const sfWebServiceAPIs = async (sfWebServiceUser: string, sfWebServiceAccessToken: string, actionID: ActionID) => {
  // Make a json object
  const json = {
    x: actionID.toString(),
    user_id: sfWebServiceUser,
    json: '1'
  };

  // Convert the json object to a string
  const jsonStr = JSON.stringify(json);

  // IV is the MD5 hash of the sfWebServiceAccessToken, taking first 8 characters
  const iv = CryptoES.MD5(sfWebServiceAccessToken).toString().slice(0, 8);

  // Encode and encrypt the string
  const encodedAndEncryptedStr = encodeAndEncrypt(iv, jsonStr);

  // Create a form data
  const formData = new FormData();
  formData.append('x', encodedAndEncryptedStr);

  const response = await axios.post(`${Constants.expoConfig?.extra?.sfWebServiceUrl}/service/api.php`, formData, {
    headers: {
      'SF-ACCESS-TOKEN': sfWebServiceAccessToken,
      'SF-USER-ID': sfWebServiceUser
    }
  });

  // Get the response data
  // const responseData = response.data;
  if (response.status === 200 && response.data && response.data.code === 1) {
    return response.data;
  }

  return null;
};

const sfWebServiceMeetingAPIs = async (sfWebServiceUser: string, sfWebServiceAccessToken: string, actionID: ActionID) => {
  // Make a json object
  const json = {
    x: actionID.toString(),
    user_id: sfWebServiceUser,
    recent_meeting: '1'
  };

  // Convert the json object to a string
  const jsonStr = JSON.stringify(json);

  // IV is the MD5 hash of the sfWebServiceAccessToken, taking first 8 characters
  const iv = CryptoES.MD5(sfWebServiceAccessToken).toString().slice(0, 8);

  // Encode and encrypt the string
  const encodedAndEncryptedStr = encodeAndEncrypt(iv, jsonStr);

  // Create a form data
  const formData = new FormData();
  formData.append('x', encodedAndEncryptedStr);

  const response = await axios.post(`${Constants.expoConfig?.extra?.sfWebServiceUrl}/service/api.php`, formData, {
    headers: {
      'SF-ACCESS-TOKEN': sfWebServiceAccessToken,
      'SF-USER-ID': sfWebServiceUser
    }
  });

  // Get the response data
  // const responseData = response.data;
  if (response.status === 200 && response.data && response.data.code === 1) {
    return response.data;
  }

  return null;
};

const sfWebServiceGetClosedUserGroupTrees = async (sfWebServiceUser: string, sfWebServiceAccessToken: string) => {
  return await sfWebServiceAPIs(sfWebServiceUser, sfWebServiceAccessToken, ActionID.GET_USER_GROUP_TREE2);
};

const sfWebServiceGetContactList = async (sfWebServiceUser: string, sfWebServiceAccessToken: string) => {
  return await sfWebServiceAPIs(sfWebServiceUser, sfWebServiceAccessToken, ActionID.GET_CONTACT_LIST);
};

const sfWebServiceGetMeetingList = async (sfWebServiceUser: string, sfWebServiceAccessToken: string) => {
  return await sfWebServiceMeetingAPIs(sfWebServiceUser, sfWebServiceAccessToken, ActionID.GET_LIST_MEETING);
};

export { sfWebServiceLogin, sfWebServiceGetClosedUserGroupTrees, sfWebServiceGetContactList, sfWebServiceGetMeetingList };
