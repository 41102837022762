import React from 'react';
import { VStack } from 'native-base';
import Title from './Title';

type WrapperProps = {
  title?: string;
  subTitle?: string;
  hasSeeAllTitle?: boolean;
  children: React.ReactNode;
};

const Wrapper = ({ title, subTitle, hasSeeAllTitle = false, children }: WrapperProps) => {
  return (
    <VStack mb={{ base: 10, md: 16 }}>
      <Title title={title} subTitle={subTitle} hasSeeAllButton={hasSeeAllTitle} />
      {children}
    </VStack>
  );
};

export default Wrapper;
