import { AgentTypeEnglish, englishDoctorAssistantDefaultMessage, englishDoctorAssistantPrompt } from './prompts/english_prompts';
import {
  AgentTypeSimplifiedChinese,
  simplifiedChineseDoctorAssistantDefaultMessage,
  simplifiedChineseDoctorAssistantPrompt
} from './prompts/simplified_chinese_prompts';
import {
  AgentTypeTraditionalChinese,
  traditionalChineseDoctorAssistantDefaultMessage,
  traditionalChineseDoctorAssistantPrompt
} from './prompts/traditional_chinese_prompts';
import { AgentTypeJapanese, japaneseDoctorAssistantDefaultMessage, japaneseDoctorAssistantPrompt } from './prompts/japanese_prompts';
import { AgentTypeKorean, koreanDoctorAssistantDefaultMessage, koreanDoctorAssistantPrompt } from './prompts/korean_prompts';
import { AgentTypeFrench, frenchDoctorAssistantDefaultMessage, frenchDoctorAssistantPrompt } from './prompts/french_prompts';
import { AgentTypeVietnamese, vietnameseDoctorAssistantDefaultMessage, vietnameseDoctorAssistantPrompt } from './prompts/vietnamese_prompts';

export enum AgentLanguage {
  English = 'English',
  SimplifiedChinese = 'SimplifiedChinese',
  TraditionalChinese = 'TraditionalChinese',
  Japanese = 'Japanese',
  Korean = 'Korean',
  French = 'French',
  Vietnamese = 'Vietnamese',
  EnglishVicuna = 'EnglishVicuna',
  TraditionalChineseVicuna = 'TraditionalChineseVicuna'
}

export enum AgentType {
  DoctorAssistant = 'Doctor Assistant'
}

export function enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value) ? (value as unknown as T) : undefined;
}

export const getAgentName = (language: AgentLanguage, agentType: AgentType) => {
  switch (language) {
    case AgentLanguage.English: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return AgentTypeEnglish.DoctorAssistant;
        default:
          return undefined;
      }
    }
    case AgentLanguage.SimplifiedChinese: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return AgentTypeSimplifiedChinese.DoctorAssistant;
        default:
          return undefined;
      }
    }
    case AgentLanguage.TraditionalChinese: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return AgentTypeTraditionalChinese.DoctorAssistant;
        default:
          return undefined;
      }
    }
    case AgentLanguage.Japanese: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return AgentTypeJapanese.DoctorAssistant;
        default:
          return undefined;
      }
    }
    case AgentLanguage.Korean: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return AgentTypeKorean.DoctorAssistant;
        default:
          return undefined;
      }
    }
    case AgentLanguage.French: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return AgentTypeFrench.DoctorAssistant;
        default:
          return undefined;
      }
    }
    case AgentLanguage.Vietnamese: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return AgentTypeVietnamese.DoctorAssistant;
        default:
          return undefined;
      }
    }
    default:
      return undefined;
  }
};

export const getAgentType = (agentName: string, language: string) => {
  switch (language) {
    case AgentLanguage.English: {
      switch (agentName) {
        case AgentTypeEnglish.DoctorAssistant:
          return AgentType.DoctorAssistant;
        default:
          return undefined;
      }
    }
    case AgentLanguage.SimplifiedChinese: {
      switch (agentName) {
        case AgentTypeSimplifiedChinese.DoctorAssistant:
          return AgentType.DoctorAssistant;
        default:
          return undefined;
      }
    }
    case AgentLanguage.TraditionalChinese: {
      switch (agentName) {
        case AgentTypeTraditionalChinese.DoctorAssistant:
          return AgentType.DoctorAssistant;
        default:
          return undefined;
      }
    }
    case AgentLanguage.Japanese: {
      switch (agentName) {
        case AgentTypeJapanese.DoctorAssistant:
          return AgentType.DoctorAssistant;
        default:
          return undefined;
      }
    }
    case AgentLanguage.Korean: {
      switch (agentName) {
        case AgentTypeKorean.DoctorAssistant:
          return AgentType.DoctorAssistant;
        default:
          return undefined;
      }
    }
    case AgentLanguage.French: {
      switch (agentName) {
        case AgentTypeFrench.DoctorAssistant:
          return AgentType.DoctorAssistant;
        default:
          return undefined;
      }
    }
    case AgentLanguage.Vietnamese: {
      switch (agentName) {
        case AgentTypeVietnamese.DoctorAssistant:
          return AgentType.DoctorAssistant;
        default:
          return undefined;
      }
    }
    default:
      return undefined;
  }
};

export const getPrompt = (language: AgentLanguage, agentType: AgentType) => {
  switch (language) {
    case AgentLanguage.English: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return englishDoctorAssistantPrompt;
        default:
          return undefined;
      }
    }
    case AgentLanguage.SimplifiedChinese: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return simplifiedChineseDoctorAssistantPrompt;
        default:
          return undefined;
      }
    }
    case AgentLanguage.TraditionalChinese: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return traditionalChineseDoctorAssistantPrompt;
        default:
          return undefined;
      }
    }
    case AgentLanguage.Japanese: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return japaneseDoctorAssistantPrompt;
        default:
          return undefined;
      }
    }
    case AgentLanguage.Korean: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return koreanDoctorAssistantPrompt;
        default:
          return undefined;
      }
    }
    case AgentLanguage.French: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return frenchDoctorAssistantPrompt;
        default:
          return undefined;
      }
    }
    case AgentLanguage.Vietnamese: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return vietnameseDoctorAssistantPrompt;
        default:
          return undefined;
      }
    }
    default:
      return undefined;
  }
};

export const getDefaultMessage = (language: AgentLanguage, agentType: AgentType) => {
  switch (language) {
    case AgentLanguage.English: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return englishDoctorAssistantDefaultMessage;
        default:
          return undefined;
      }
    }
    case AgentLanguage.SimplifiedChinese: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return simplifiedChineseDoctorAssistantDefaultMessage;
        default:
          return undefined;
      }
    }
    case AgentLanguage.TraditionalChinese: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return traditionalChineseDoctorAssistantDefaultMessage;
        default:
          return undefined;
      }
    }
    case AgentLanguage.Japanese: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return japaneseDoctorAssistantDefaultMessage;
        default:
          return undefined;
      }
    }
    case AgentLanguage.Korean: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return koreanDoctorAssistantDefaultMessage;
        default:
          return undefined;
      }
    }
    case AgentLanguage.French: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return frenchDoctorAssistantDefaultMessage;
        default:
          return undefined;
      }
    }
    case AgentLanguage.Vietnamese: {
      switch (agentType) {
        case AgentType.DoctorAssistant:
          return vietnameseDoctorAssistantDefaultMessage;
        default:
          return undefined;
      }
    }
    default:
      return undefined;
  }
};

export const getDefaultMessageByAgentName = (language: string, agentName: string) => {
  const languageType = enumFromStringValue<AgentLanguage>(AgentLanguage, language);
  switch (languageType) {
    case AgentLanguage.English: {
      switch (agentName) {
        case AgentTypeEnglish.DoctorAssistant:
          return englishDoctorAssistantDefaultMessage;
        default:
          return undefined;
      }
    }
    case AgentLanguage.SimplifiedChinese: {
      switch (agentName) {
        case AgentTypeSimplifiedChinese.DoctorAssistant:
          return simplifiedChineseDoctorAssistantDefaultMessage;
        default:
          return undefined;
      }
    }
    case AgentLanguage.TraditionalChinese: {
      switch (agentName) {
        case AgentTypeTraditionalChinese.DoctorAssistant:
          return traditionalChineseDoctorAssistantDefaultMessage;
        default:
          return undefined;
      }
    }
    case AgentLanguage.Japanese: {
      switch (agentName) {
        case AgentTypeJapanese.DoctorAssistant:
          return japaneseDoctorAssistantDefaultMessage;
        default:
          return undefined;
      }
    }
    case AgentLanguage.Korean: {
      switch (agentName) {
        case AgentTypeKorean.DoctorAssistant:
          return koreanDoctorAssistantDefaultMessage;
        default:
          return undefined;
      }
    }
    case AgentLanguage.French: {
      switch (agentName) {
        case AgentTypeFrench.DoctorAssistant:
          return frenchDoctorAssistantDefaultMessage;
        default:
          return undefined;
      }
    }
    case AgentLanguage.Vietnamese: {
      switch (agentName) {
        case AgentTypeVietnamese.DoctorAssistant:
          return vietnameseDoctorAssistantDefaultMessage;
        default:
          return undefined;
      }
    }
    default:
      return undefined;
  }
};

export const getFriendlyLanguage = (language: string) => {
  const languageType = enumFromStringValue<AgentLanguage>(AgentLanguage, language);
  switch (languageType) {
    case AgentLanguage.English:
      return 'English';
    case AgentLanguage.SimplifiedChinese:
      return '简体中文';
    case AgentLanguage.TraditionalChinese:
      return '繁體中文';
    case AgentLanguage.Japanese:
      return '日本語';
    case AgentLanguage.Korean:
      return '한국어';
    case AgentLanguage.French:
      return 'Français';
    case AgentLanguage.Vietnamese:
      return 'Tiếng Việt';
    case AgentLanguage.EnglishVicuna:
      return 'English (Vicuna model)';
    case AgentLanguage.TraditionalChineseVicuna:
      return '繁體中文 (Vicuna model)';
    default:
      return undefined;
  }
};
