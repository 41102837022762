import React from 'react';
import { HStack, Icon, Input, Pressable, Tooltip } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';

const SearchBarWeb = ({
  handleSearchTextChange = () => {
    // do nothing
  },
  handleToggleSearch = () => {
    // close search input and clear search text
  },
  textInput
}: any) => {
  return (
    <HStack justifyContent='space-between' alignItems='center' space={4}>
      <Input
        width={'100%'}
        h={'15%'}
        onChangeText={handleSearchTextChange}
        value={textInput}
        rounded={'lg'}
        flex={1}
        py={3}
        _stack={{
          px: 3
        }}
        _light={{
          placeholderTextColor: 'coolGray.400'
        }}
        _dark={{
          bg: 'coolGray.700',
          borderColor: 'coolGray.500',
          color: 'coolGray.100'
        }}
        InputRightElement={
          <Tooltip label='Close'>
            <Pressable p={4} alignItems='center' justifyContent='center' size={'8'} rounded='full' _hover={{ bg: '#E4EBE4' }}>
              <Icon onPress={handleToggleSearch} as={<MaterialIcons name='close' />} size={6} color='coolGray.900' />
            </Pressable>
          </Tooltip>
        }
        placeholder='Search'
        fontWeight='medium'
        fontSize='14'
      />
    </HStack>
  );
};

export default SearchBarWeb;
