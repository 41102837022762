import React, { useEffect, useState } from 'react';
import { Box, Divider, FlatList, HStack, Icon, Pressable, ScrollView, Text, VStack } from 'native-base';
import { FontAwesome, FontAwesome5, Fontisto, Foundation, MaterialCommunityIcons, SimpleLineIcons } from '@expo/vector-icons';
import { useAppSelector } from '../../store/hooks';
import { selectAllMedicalRecords } from '../../store/hospital/medical-record/medical-records-slice';
import { IMedicalRecord } from '../../types/IMedicalRecord';
import { formatDate } from '../../utils/time_utils';
import { gqlGetTestResultsByMedicalRecordID } from '../../utils/graphql/test_result_utils';
import { ITestResult } from '../../types/ITestResult';
import LabTestImageModal from './LabTestImageModal';
import { useNavigation, useRoute } from '@react-navigation/native';

const MedicalDetailMobile = () => {
  const medicalRecordList = useAppSelector((state) => selectAllMedicalRecords(state.hospital.medicalRecords));
  const [record, setRecord] = useState<IMedicalRecord>(medicalRecordList[0]);
  const [labTestResult, setLabTestResult] = useState<ITestResult[]>();
  const route = useRoute();
  const { medicalRecordId } = route.params as any;
  const navigation = useNavigation();

  useEffect(() => {
    (async () => {
      if (!record) return;
      const testResultList = await gqlGetTestResultsByMedicalRecordID(record.id);
      setLabTestResult(testResultList);
    })();
  }, [record]);

  useEffect(() => {
    if (medicalRecordList.length > 0 && medicalRecordId) {
      const a = medicalRecordList.find((item) => item.id === medicalRecordId);
      if (a) {
        setRecord(a);
      }
    }
  }, [medicalRecordList, medicalRecordId]);

  const noInfo = 'No information';

  return (
    <>
      <VStack w={'100%'} backgroundColor={'white'} rounded={'lg'} space='2' h={'100%'} py={5}>
        <HStack mt={1} alignItems={'center'}>
          <Pressable mr={2} ml={4} onPress={() => navigation.goBack()}>
            <Icon as={MaterialCommunityIcons} name='arrow-left' size={'xl'} color={'coolGray.700'} />
          </Pressable>
          <Text fontWeight={'bold'} fontSize={'20'} color={'coolGray.900'}>
            Records Detail
          </Text>
        </HStack>
        <ScrollView w={'100%'} h={'100%'}>
          <VStack space={6} px={'5'}>
            <Text color={'coolGray.900'} fontWeight={'bold'} fontSize={'20'} mt={5}>
              {/*Created at [item.timestamp]*/}
              Created at {record && formatDate(record.timestamp)}
              {/*Created at 21 August 2020, 12:45 AM*/}
            </Text>
            <VStack space={2}>
              <VStack>
                <HStack mb={2}>
                  <Box w={10}>
                    <SimpleLineIcons name='speedometer' size={24} color='#4EA0F7' />
                  </Box>
                  <Text color={'coolGray.900'} fontSize={'16'}>
                    Blood Pressure: {record?.bloodPressure ? `${record.bloodPressure} mmHg` : noInfo}
                  </Text>
                </HStack>
                <HStack mb={2}>
                  <Box w={10}>
                    <FontAwesome name='heartbeat' size={24} color='#4EA0F7' />
                  </Box>
                  <Text color={'coolGray.900'} fontSize={'16'}>
                    Pulse Rate: {record?.pulseRate ? `${record.pulseRate} bpm` : noInfo}
                  </Text>
                </HStack>
              </VStack>
              <HStack mb={2}>
                <Box w={10}>
                  <FontAwesome5 name='lungs' size={24} color='#4EA0F7' />
                </Box>
                <Text color={'coolGray.900'} fontSize={'16'}>
                  Respiratory Rate: {record?.respiratoryRate ? `${record.respiratoryRate} bpm` : noInfo}
                </Text>
              </HStack>
              <HStack mb={2}>
                <Box w={10}>
                  <FontAwesome5 name='temperature-high' size={24} color='#4EA0F7' />
                </Box>
                <Text color={'coolGray.900'} fontSize={'16'}>
                  Body Temperature: {record?.bodyTemperature ? `${record.bodyTemperature} °C` : noInfo}
                </Text>
              </HStack>
            </VStack>
          </VStack>
          <Divider />
          <VStack px={5} pt={5} pb={2}>
            <Text color={'coolGray.900'} fontWeight={'bold'} fontSize={'20'}>
              Details Information
            </Text>
            <HStack mt={4}>
              <VStack mt={2} justifyContent={'space-between'}>
                <HStack mb={3} alignItems={'center'}>
                  <Box w={8}>
                    <FontAwesome5 name='head-side-cough' size={24} color='#4EA0F7' />
                  </Box>
                  <Text ml={3} color={'coolGray.900'} fontSize={'16'}>
                    Symptoms: {''}
                    {record && record.symptoms && record.symptoms.length > 20 ? `${record.symptoms.slice(0, 20)}...` : record?.symptoms ?? noInfo}
                  </Text>
                </HStack>
                <HStack mb={3} alignItems={'center'}>
                  <Box w={8}>
                    <FontAwesome5 name='notes-medical' size={24} color='#4EA0F7' />
                  </Box>
                  <Text ml={3} color={'coolGray.900'} fontSize={'16'}>
                    Diagnosis: {''}
                    {record && record.diagnosis && record.diagnosis.length > 20 ? `${record.diagnosis.slice(0, 20)}...` : record?.diagnosis ?? noInfo}
                  </Text>
                </HStack>
                <HStack mb={3} alignItems={'center'}>
                  <Box w={8}>
                    <FontAwesome5 name='capsules' size={24} color='#4EA0F7' />
                  </Box>
                  <Text ml={3} color={'coolGray.900'} fontSize={'16'}>
                    Medication: Prozac (Fluoxetine)...
                  </Text>
                </HStack>
                <HStack mb={3} alignItems={'center'}>
                  <Box w={8}>
                    <MaterialCommunityIcons name='doctor' size={24} color='#4EA0F7' />
                  </Box>
                  <Text ml={3} color={'coolGray.900'} fontSize={'16'}>
                    Discharge Instruction:{' '}
                    {record && record.dischargeInstruction && record.dischargeInstruction.length > 10
                      ? `${record.dischargeInstruction.slice(0, 10)}...`
                      : record?.dischargeInstruction ?? noInfo}
                  </Text>
                </HStack>
              </VStack>
            </HStack>
          </VStack>

          <Divider />

          <VStack space={2} mt={5} px={5}>
            {/*<ComparedImagesModalButton />*/}
            <Text color={'coolGray.900'} fontWeight={'bold'} fontSize={'20'}>
              Lab Test Results
            </Text>
            <FlatList
              data={labTestResult}
              renderItem={({ item }) => (
                <Pressable rounded={'lg'} px={2} mb={2}>
                  <VStack>
                    <HStack alignItems={'center'} py={2}>
                      <Box w={8}>
                        <Fontisto name='blood-test' size={24} color='#4EA0F7' />
                      </Box>
                      <VStack ml={2}>
                        <Text fontWeight={'medium'} color={'coolGray.900'} fontSize={'16'}>
                          {item.testType}
                        </Text>
                        <Text color={'coolGray.900'} fontSize={'16'}>
                          {item && formatDate(item.timestamp)}
                        </Text>
                        <Text fontWeight={'medium'} color={'coolGray.900'} fontSize={'16'}>
                          {item && item?.result && item?.result?.length > 20 ? `${item?.result?.slice(0, 20)}...` : item?.result ?? noInfo}
                        </Text>
                      </VStack>
                    </HStack>
                    {item.images && item.images.length > 0 && <LabTestImageModal images={item.images} type={item.testType} />}
                  </VStack>
                </Pressable>
              )}
              keyExtractor={(item) => item.id.toString()}
              showsHorizontalScrollIndicator={false}
              ItemSeparatorComponent={() => <Box w={4} />}
            />
          </VStack>

          <Divider />

          <VStack px={5} space={4} mb={{ base: '12', md: '0' }}>
            <Text color={'coolGray.900'} fontWeight={'bold'} fontSize={'20'}>
              Billing Information
            </Text>
            <HStack>
              <Box w={8}>
                <Foundation name='clipboard-notes' size={24} color='#4EA0F7' />
              </Box>
              <Text ml={2} color={'coolGray.900'} fontSize={'16'}>
                {record?.billingInfo ? `Total of $ ${record.billingInfo}` : noInfo}
              </Text>
            </HStack>
          </VStack>
        </ScrollView>
      </VStack>
    </>
  );
};
export default MedicalDetailMobile;
