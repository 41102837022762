// import { cloneTestResult, ICreateTestResultInput, ITestResult } from '../../types/ITestResult';
import { ICreateTestResultInput, ITestResult } from '../../types/ITestResult';
import { API, graphqlOperation } from 'aws-amplify';
import { CreateTestResultMutation, GetTestResultQuery, TestResultsByMedicalRecordIDQuery } from '../../API';
import { GraphQLResult } from '@aws-amplify/api';
import { getTestResult, testResultsByMedicalRecordID } from '../../graphql/queries';
import { createTestResult } from '../../graphql/mutations';

export const gqlCreateTestResult = async (testResult: ICreateTestResultInput) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to add test result ${retry} time(s)`);
    }

    try {
      const ret = (await API.graphql(
        graphqlOperation(createTestResult, {
          input: testResult
        })
      )) as GraphQLResult<CreateTestResultMutation>;

      if (ret.data?.createTestResult) {
        // return cloneTestResult(ret.data.createTestResult);
        return ret.data.createTestResult;
      } else {
        throw new Error('Failed to add test result');
      }
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to add test result');
  }

  return null;
};

export const gqlGetTestResult = async (testResultID: string) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get test result ${retry} time(s)`);
    }

    try {
      const ret = (await API.graphql(
        graphqlOperation(getTestResult, {
          id: testResultID
        })
      )) as GraphQLResult<GetTestResultQuery>;

      return ret.data?.getTestResult && !ret.data.getTestResult._deleted ? ret.data.getTestResult : null;
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to get test result');
  }

  return null;
};

export const gqlGetTestResultsByMedicalRecordID = async (medicalRecordID: string): Promise<ITestResult[]> => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log(`Retrying to get test results by medical record id ${retry} time(s)`);
    }

    try {
      let nextToken = null;
      const allTestResults = [];
      do {
        const ret = (await API.graphql(
          graphqlOperation(testResultsByMedicalRecordID, {
            medicalRecordID,
            nextToken
          })
        )) as GraphQLResult<TestResultsByMedicalRecordIDQuery>;
        if (ret.data?.testResultsByMedicalRecordID?.items) {
          allTestResults.push(...ret.data.testResultsByMedicalRecordID.items.filter((testResult) => testResult && !testResult._deleted));
          nextToken = ret.data.testResultsByMedicalRecordID.nextToken;
        }
      } while (nextToken);

      return allTestResults as ITestResult[];
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to get test results by medical record id');
  }

  return [];
};
