import React from 'react';
import { Box, FlatList, Image, Pressable, Text } from 'native-base';
import Title from './Title';
import { Doctor } from './RecommendedDoctors';

const doctors: Doctor[] = [
  {
    id: 1,
    name: 'Dr. Rama Dani Putra',
    imageUri: require('../screens/assets/doctor.png'),
    isOnline: false
  },
  {
    id: 2,
    name: 'Dr. Rama Dani Putra',
    imageUri: require('../screens/assets/doctor.png'),
    isOnline: true
  },
  {
    id: 3,
    name: 'Dr. Rama Dani Putra',
    imageUri: require('../screens/assets/doctor.png'),
    isOnline: true
  }
];

const RecentDoctors = () => {
  return (
    <>
      <Title title={'Your Recent Doctors'} />
      <FlatList
        data={doctors}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }: { item: Doctor }) => {
          return (
            <Pressable>
              <Card doctor={item} />
            </Pressable>
          );
        }}
        ItemSeparatorComponent={() => <Box w={{ base: '4', md: '8' }} />}
      />
    </>
  );
};

const Card = ({ doctor }: { doctor: Doctor }) => {
  return (
    <Box w={{ base: 48, md: 72 }} h={{ base: 56, md: 96 }} rounded='xl' borderWidth={1} borderColor={'gray.50'}>
      <Image flex='1' source={doctor.imageUri} alt='Alternate Text' borderBottomWidth={1} borderColor={'gray.200'} />
      <Text px='3' py='3' fontSize='md' numberOfLines={2} lineHeight='20'>
        {doctor.name}
      </Text>
    </Box>
  );
};

export default RecentDoctors;
