import React, { useEffect } from 'react';
import { Box, Hidden, HStack, Image, Spinner, Text, VStack } from 'native-base';
import WaitingRoomModal from './WaitingRoomModal';
import NoMeetingModal from './NoMeetingModal';
import axios from 'axios';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import { IAppointment } from '../../types/IAppointment';

const ShowMeetingList = ({ MeetingListProps }: any) => {
  // console.log('MeetingListProps', MeetingListProps);
  const [appointments, setAppointments] = React.useState<IAppointment[]>([]);
  const [appointmentsWithDoctorData, setAppointmentsWithDoctorData] = React.useState<IAppointment[]>([]);
  const { user } = useAuth();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const ret = await axios.get(`/appointment/patient/${user?.id}`, {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user?.idToken}` }
        });
        if (ret.status === 200 && ret.data) {
          // console.log('ret.data', ret.data);
          setAppointments(ret.data);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        console.log('e', e);
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (appointments?.length) {
        const a = await Promise.all(
          appointments.map(async (item: IAppointment) => {
            try {
              const doctor = await axios.get(`/doctor/user/${item.doctorID}`, {
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user?.idToken}` }
              });
              if (doctor.status === 200 && doctor.data) {
                try {
                  const userDoctor = await axios.get(`/user/${doctor.data.doctorUserId}`, {
                    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user?.idToken}` }
                  });
                  if (userDoctor.status === 200 && userDoctor.data) {
                    return {
                      ...item,
                      doctor: {
                        ...doctor.data,
                        user: userDoctor.data
                      }
                    };
                  } else {
                    setIsLoading(false);
                    return item;
                  }
                } catch (e) {
                  setIsLoading(false);
                  return item;
                }
              } else {
                setIsLoading(false);
                return item;
              }
            } catch (e) {
              setIsLoading(false);
              return item;
            }
          })
        );
        setIsLoading(false);
        setAppointmentsWithDoctorData(a);
        console.log('a', a);
      }
    })();
  }, [appointments]);
  return (
    <>
      {isLoading ? (
        <Spinner size={'lg'} />
      ) : (
        <VStack w={'100%'} pt={6} pb={12} space={4}>
          {!appointmentsWithDoctorData?.length ? (
            <HStack
              w={{ base: '95%', md: '80%' }}
              maxW={{ base: '450px', md: '900px' }}
              alignItems={'center'}
              borderWidth={0.5}
              borderBottomWidth={0}
              borderColor={'coolGray.200'}
              shadow={3}
              rounded={'xl'}
              alignSelf={'center'}
              px={{ base: 4, md: 8 }}
              py={4}
              justifyContent={'space-between'}>
              <HStack alignItems={'center'}>
                <Text fontSize={{ base: '20', md: '24' }} fontWeight={'medium'} color={'black'}>
                  No Meeting
                </Text>
              </HStack>
              <HStack>
                <NoMeetingModal />
              </HStack>
            </HStack>
          ) : (
            appointmentsWithDoctorData?.map((item: any, index: number) => {
              return (
                <Box key={index}>
                  <Hidden till={'md'}>
                    <HStack
                      w={'80%'}
                      maxW={'900px'}
                      key={item?.id}
                      alignItems={'center'}
                      alignSelf={'center'}
                      borderWidth={0.5}
                      borderBottomWidth={0}
                      borderColor={'coolGray.200'}
                      shadow={3}
                      rounded={'xl'}
                      px={8}
                      py={4}
                      justifyContent={'space-between'}>
                      <HStack space={4} alignItems={'center'}>
                        {item?.doctor?.user?.imageUrl ? (
                          <Image rounded={'full'} source={item.doctor.user.imageUrl} alt='Alternate Text' size='12' />
                        ) : (
                          <Box rounded={'full'} bg={'coolGray.600'} size={'12'} alignItems={'center'} justifyContent={'center'}>
                            <Text fontSize={'xl'} fontWeight={'bold'} color={'white'}>
                              {item?.doctor?.user?.lastName.charAt(0).toUpperCase()}
                            </Text>
                          </Box>
                        )}
                        <Text fontSize={'lg'} fontWeight={'bold'}>
                          {item?.doctor?.user?.firstName + ' ' + item?.doctor?.user?.lastName}
                        </Text>
                        <Text fontSize={'lg'}>{item?.specialist}</Text>
                        <Text fontSize={'lg'} fontWeight={'bold'}>
                          {item?.date}
                        </Text>
                        <Text fontSize={'lg'} fontWeight={'bold'}>
                          {/* eslint-disable-next-line react/no-unescaped-entities */}
                          at {item?.hour} o'clock
                        </Text>
                      </HStack>
                      <HStack justifyContent={'end'}>
                        <HStack h={'80%'}>
                          <WaitingRoomModal item={item} />
                        </HStack>
                      </HStack>
                    </HStack>
                  </Hidden>
                  <Hidden from={'md'}>
                    <HStack
                      w={'95%'}
                      maxW={'450px'}
                      key={item?.id}
                      alignItems={'center'}
                      alignSelf={'center'}
                      borderWidth={0.5}
                      borderBottomWidth={0}
                      borderColor={'coolGray.200'}
                      shadow={3}
                      rounded={'xl'}
                      mb={8}
                      px={'4'}
                      py={'2'}>
                      <VStack w={'100%'}>
                        <HStack>
                          {item?.doctor?.user?.imageUrl ? (
                            <Image rounded={'lg'} source={item.doctor.user?.imageUrl} alt='Alternate Text' height={'112px'} width={'112px'} />
                          ) : (
                            <Box rounded={'lg'} bg={'coolGray.600'} size={'12'} alignItems={'center'} justifyContent={'center'}>
                              <Text fontSize={'xl'} fontWeight={'bold'} color={'white'}>
                                {item?.doctor?.user?.lastName.charAt(0).toUpperCase()}
                              </Text>
                            </Box>
                          )}
                          <VStack ml={8}>
                            <Text fontSize={'20'} fontWeight={'bold'} color={'coolGray.900'}>
                              {item?.doctor?.user?.firstName + ' ' + item?.doctor?.user?.lastName}
                            </Text>
                            <Text fontSize={'16'} fontWeight={'medium'} color={'coolGray.900'}>
                              {/* eslint-disable-next-line react/no-unescaped-entities */}
                              {item?.date} at {item?.hour} o'clock
                            </Text>
                            <Text fontSize={'16'} color={'coolGray.900'}>
                              {item?.doctor?.specialist}
                            </Text>
                            <HStack my={2} justifyContent={'end'}>
                              <HStack>
                                <WaitingRoomModal item={item} />
                              </HStack>
                            </HStack>
                          </VStack>
                        </HStack>
                      </VStack>
                    </HStack>
                  </Hidden>
                </Box>
              );
            })
          )}
        </VStack>
      )}
    </>
  );
};
export default ShowMeetingList;
