import React from 'react';
import { Box, Divider, Hidden, HStack, View, VStack } from 'native-base';
import MedicalDetail from './MedicalDetail';
import MedicalList from './MedicalList';
import MedicalListMobile from './MedicalListMobile';
import { useAppSelector } from '../../store/hooks';
import { selectAllMedicalRecords } from '../../store/hospital/medical-record/medical-records-slice';

const MedicalHistory = () => {
  const records = useAppSelector((state) => selectAllMedicalRecords(state.hospital.medicalRecords));
  return (
    <>
      <Hidden till={'md'}>
        <View px={4} w='100%'>
          {records.length > 0 ? (
            <HStack alignSelf={'center'} width={'full'} height={'full'}>
              <Box w={['35%', '35%', '25%']} height={'100%'}>
                <MedicalList />
              </Box>
              <Divider orientation='vertical' />
              <Box width={['65%', '65%', '75%']} height={'100%'}>
                <MedicalDetail />
              </Box>
            </HStack>
          ) : (
            <HStack alignItems={'center'} justifyContent={'center'} width={'full'}>
              <MedicalList />
            </HStack>
          )}
        </View>
      </Hidden>
      <Hidden from={'md'}>
        <View w='100%'>
          <VStack mt={4} width={'100%'}>
            <MedicalListMobile />
          </VStack>
        </View>
      </Hidden>
    </>
  );
};

export default MedicalHistory;
