/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateMedicineInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  _version?: number | null,
};

export type ModelMedicineConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelMedicineConditionInput | null > | null,
  or?: Array< ModelMedicineConditionInput | null > | null,
  not?: ModelMedicineConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type Medicine = {
  __typename: "Medicine",
  id: string,
  name: string,
  description?: string | null,
  prescriptions?: ModelPrescriptionConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelPrescriptionConnection = {
  __typename: "ModelPrescriptionConnection",
  items:  Array<Prescription | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Prescription = {
  __typename: "Prescription",
  id: string,
  instruction: string,
  quantity: number,
  medicalRecordID: string,
  medicalRecord?: MedicalRecord | null,
  medicineID: string,
  medicine?: Medicine | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type MedicalRecord = {
  __typename: "MedicalRecord",
  id: string,
  timestamp: string,
  status: MedicalRecordStatus,
  pulseRate?: string | null,
  bloodPressure?: string | null,
  bodyTemperature?: string | null,
  respiratoryRate?: string | null,
  symptoms?: string | null,
  diagnosis?: string | null,
  dischargeInstruction?: string | null,
  billingInfo?: string | null,
  doctorID: string,
  doctor?: Doctor | null,
  patientID: string,
  patient?: Patient | null,
  prescriptions?: ModelPrescriptionConnection | null,
  testResults?: ModelTestResultConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum MedicalRecordStatus {
  NEWLY_CREATED = "NEWLY_CREATED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}


export type Doctor = {
  __typename: "Doctor",
  id: string,
  specialist: string,
  user?: User | null,
  doctorUserId: string,
  patients?: ModelDoctorPatientConnection | null,
  medicalRecords?: ModelMedicalRecordConnection | null,
  departmentID?: string | null,
  department?: Department | null,
  workingTimeSpans?: ModelWorkingTimeSpanConnection | null,
  appointments?: ModelAppointmentConnection | null,
  reviews?: ModelReviewConnection | null,
  introduction?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type User = {
  __typename: "User",
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber?: string | null,
  imageUrl?: string | null,
  address?: string | null,
  birthday?: string | null,
  gender?: Gender | null,
  sFUser?: SFUser | null,
  doctor?: Doctor | null,
  patient?: Patient | null,
  userDoctorId?: string | null,
  chatRooms?: ModelChatRoomConnection | null,
  messages?: ModelMessageConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  userSFUserId?: string | null,
  userPatientId?: string | null,
  owner?: string | null,
};

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}


export type SFUser = {
  __typename: "SFUser",
  id: string,
  userName: string,
  password: string,
  group: string,
  sFUserUserId: string,
  user?: User | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type Patient = {
  __typename: "Patient",
  id: string,
  checkInTime?: string | null,
  status: PatientStatus,
  ethnicity?: string | null,
  healthHistory?: string | null,
  allergies?: string | null,
  immunizations?: string | null,
  user?: User | null,
  patientUserId: string,
  doctors?: ModelDoctorPatientConnection | null,
  diseases?: ModelPatientDiseaseConnection | null,
  medicalRecords?: ModelMedicalRecordConnection | null,
  appointments?: ModelAppointmentConnection | null,
  reviews?: ModelReviewConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export enum PatientStatus {
  UNKNOWN = "UNKNOWN",
  PENDING = "PENDING",
  ON_RECOVERY = "ON_RECOVERY",
  RECOVERED = "RECOVERED",
}


export type ModelDoctorPatientConnection = {
  __typename: "ModelDoctorPatientConnection",
  items:  Array<DoctorPatient | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type DoctorPatient = {
  __typename: "DoctorPatient",
  id: string,
  patientId: string,
  doctorId: string,
  patient: Patient,
  doctor: Doctor,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type ModelPatientDiseaseConnection = {
  __typename: "ModelPatientDiseaseConnection",
  items:  Array<PatientDisease | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type PatientDisease = {
  __typename: "PatientDisease",
  id: string,
  patientId: string,
  diseaseId: string,
  patient: Patient,
  disease: Disease,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type Disease = {
  __typename: "Disease",
  id: string,
  name: string,
  description?: string | null,
  patients?: ModelPatientDiseaseConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelMedicalRecordConnection = {
  __typename: "ModelMedicalRecordConnection",
  items:  Array<MedicalRecord | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAppointmentConnection = {
  __typename: "ModelAppointmentConnection",
  items:  Array<Appointment | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Appointment = {
  __typename: "Appointment",
  id: string,
  date: string,
  hour: number,
  order: number,
  description?: string | null,
  doctorID: string,
  doctor?: Doctor | null,
  patientID: string,
  patient?: Patient | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelReviewConnection = {
  __typename: "ModelReviewConnection",
  items:  Array<Review | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Review = {
  __typename: "Review",
  id: string,
  rating: number,
  comment?: string | null,
  patient?: Patient | null,
  patientID: string,
  doctorID: string,
  doctor?: Doctor | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelChatRoomConnection = {
  __typename: "ModelChatRoomConnection",
  items:  Array<ChatRoom | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ChatRoom = {
  __typename: "ChatRoom",
  id: string,
  chatRoomName: string,
  lastMessageText?: string | null,
  lastMessageTimestamp?: string | null,
  messages?: ModelMessageConnection | null,
  userID: string,
  user?: User | null,
  agentID?: string | null,
  agent?: Agent | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items:  Array<Message | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Message = {
  __typename: "Message",
  id: string,
  timestamp: string,
  text?: string | null,
  translatedText?: string | null,
  image?: string | null,
  isDefaultMessage: boolean,
  completionTokens?: number | null,
  chatRoomID: string,
  chatRoom?: ChatRoom | null,
  userID: string,
  user?: User | null,
  ownerUserID?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Agent = {
  __typename: "Agent",
  id: string,
  name: string,
  prompt: string,
  model: string,
  language: string,
  avatar?: string | null,
  chatRooms?: ModelChatRoomConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Department = {
  __typename: "Department",
  id: string,
  name: string,
  description?: string | null,
  doctors?: ModelDoctorConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelDoctorConnection = {
  __typename: "ModelDoctorConnection",
  items:  Array<Doctor | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelWorkingTimeSpanConnection = {
  __typename: "ModelWorkingTimeSpanConnection",
  items:  Array<WorkingTimeSpan | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type WorkingTimeSpan = {
  __typename: "WorkingTimeSpan",
  id: string,
  dayOfWeek: number,
  date: string,
  hours: Array< number | null >,
  doctorID: string,
  doctor?: Doctor | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelTestResultConnection = {
  __typename: "ModelTestResultConnection",
  items:  Array<TestResult | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type TestResult = {
  __typename: "TestResult",
  id: string,
  timestamp: string,
  testType: string,
  description: string,
  result: string,
  images?: Array< string | null > | null,
  medicalRecordID: string,
  medicalRecord?: MedicalRecord | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateMedicineInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  _version?: number | null,
};

export type DeleteMedicineInput = {
  id: string,
  _version?: number | null,
};

export type CreatePrescriptionInput = {
  id?: string | null,
  instruction: string,
  quantity: number,
  medicalRecordID: string,
  medicineID: string,
  _version?: number | null,
};

export type ModelPrescriptionConditionInput = {
  instruction?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  medicalRecordID?: ModelIDInput | null,
  medicineID?: ModelIDInput | null,
  and?: Array< ModelPrescriptionConditionInput | null > | null,
  or?: Array< ModelPrescriptionConditionInput | null > | null,
  not?: ModelPrescriptionConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdatePrescriptionInput = {
  id: string,
  instruction?: string | null,
  quantity?: number | null,
  medicalRecordID?: string | null,
  medicineID?: string | null,
  _version?: number | null,
};

export type DeletePrescriptionInput = {
  id: string,
  _version?: number | null,
};

export type CreateMedicalRecordInput = {
  id?: string | null,
  timestamp: string,
  status: MedicalRecordStatus,
  pulseRate?: string | null,
  bloodPressure?: string | null,
  bodyTemperature?: string | null,
  respiratoryRate?: string | null,
  symptoms?: string | null,
  diagnosis?: string | null,
  dischargeInstruction?: string | null,
  billingInfo?: string | null,
  doctorID: string,
  patientID: string,
  _version?: number | null,
};

export type ModelMedicalRecordConditionInput = {
  timestamp?: ModelStringInput | null,
  status?: ModelMedicalRecordStatusInput | null,
  pulseRate?: ModelStringInput | null,
  bloodPressure?: ModelStringInput | null,
  bodyTemperature?: ModelStringInput | null,
  respiratoryRate?: ModelStringInput | null,
  symptoms?: ModelStringInput | null,
  diagnosis?: ModelStringInput | null,
  dischargeInstruction?: ModelStringInput | null,
  billingInfo?: ModelStringInput | null,
  doctorID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  and?: Array< ModelMedicalRecordConditionInput | null > | null,
  or?: Array< ModelMedicalRecordConditionInput | null > | null,
  not?: ModelMedicalRecordConditionInput | null,
};

export type ModelMedicalRecordStatusInput = {
  eq?: MedicalRecordStatus | null,
  ne?: MedicalRecordStatus | null,
};

export type UpdateMedicalRecordInput = {
  id: string,
  timestamp?: string | null,
  status?: MedicalRecordStatus | null,
  pulseRate?: string | null,
  bloodPressure?: string | null,
  bodyTemperature?: string | null,
  respiratoryRate?: string | null,
  symptoms?: string | null,
  diagnosis?: string | null,
  dischargeInstruction?: string | null,
  billingInfo?: string | null,
  doctorID?: string | null,
  patientID?: string | null,
  _version?: number | null,
};

export type DeleteMedicalRecordInput = {
  id: string,
  _version?: number | null,
};

export type CreateTestResultInput = {
  id?: string | null,
  timestamp: string,
  testType: string,
  description: string,
  result: string,
  images?: Array< string | null > | null,
  medicalRecordID: string,
  _version?: number | null,
};

export type ModelTestResultConditionInput = {
  timestamp?: ModelStringInput | null,
  testType?: ModelStringInput | null,
  description?: ModelStringInput | null,
  result?: ModelStringInput | null,
  images?: ModelStringInput | null,
  medicalRecordID?: ModelIDInput | null,
  and?: Array< ModelTestResultConditionInput | null > | null,
  or?: Array< ModelTestResultConditionInput | null > | null,
  not?: ModelTestResultConditionInput | null,
};

export type UpdateTestResultInput = {
  id: string,
  timestamp?: string | null,
  testType?: string | null,
  description?: string | null,
  result?: string | null,
  images?: Array< string | null > | null,
  medicalRecordID?: string | null,
  _version?: number | null,
};

export type DeleteTestResultInput = {
  id: string,
  _version?: number | null,
};

export type CreatePatientInput = {
  id?: string | null,
  checkInTime?: string | null,
  status: PatientStatus,
  ethnicity?: string | null,
  healthHistory?: string | null,
  allergies?: string | null,
  immunizations?: string | null,
  patientUserId: string,
  _version?: number | null,
};

export type ModelPatientConditionInput = {
  checkInTime?: ModelStringInput | null,
  status?: ModelPatientStatusInput | null,
  ethnicity?: ModelStringInput | null,
  healthHistory?: ModelStringInput | null,
  allergies?: ModelStringInput | null,
  immunizations?: ModelStringInput | null,
  patientUserId?: ModelIDInput | null,
  and?: Array< ModelPatientConditionInput | null > | null,
  or?: Array< ModelPatientConditionInput | null > | null,
  not?: ModelPatientConditionInput | null,
};

export type ModelPatientStatusInput = {
  eq?: PatientStatus | null,
  ne?: PatientStatus | null,
};

export type UpdatePatientInput = {
  id: string,
  checkInTime?: string | null,
  status?: PatientStatus | null,
  ethnicity?: string | null,
  healthHistory?: string | null,
  allergies?: string | null,
  immunizations?: string | null,
  patientUserId?: string | null,
  _version?: number | null,
};

export type DeletePatientInput = {
  id: string,
  _version?: number | null,
};

export type CreateDoctorInput = {
  id?: string | null,
  specialist: string,
  doctorUserId: string,
  departmentID?: string | null,
  introduction?: string | null,
  _version?: number | null,
};

export type ModelDoctorConditionInput = {
  specialist?: ModelStringInput | null,
  doctorUserId?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  introduction?: ModelStringInput | null,
  and?: Array< ModelDoctorConditionInput | null > | null,
  or?: Array< ModelDoctorConditionInput | null > | null,
  not?: ModelDoctorConditionInput | null,
};

export type UpdateDoctorInput = {
  id: string,
  specialist?: string | null,
  doctorUserId?: string | null,
  departmentID?: string | null,
  introduction?: string | null,
  _version?: number | null,
};

export type DeleteDoctorInput = {
  id: string,
  _version?: number | null,
};

export type CreateReviewInput = {
  id?: string | null,
  rating: number,
  comment?: string | null,
  patientID: string,
  doctorID: string,
  _version?: number | null,
};

export type ModelReviewConditionInput = {
  rating?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  patientID?: ModelIDInput | null,
  doctorID?: ModelIDInput | null,
  and?: Array< ModelReviewConditionInput | null > | null,
  or?: Array< ModelReviewConditionInput | null > | null,
  not?: ModelReviewConditionInput | null,
};

export type UpdateReviewInput = {
  id: string,
  rating?: number | null,
  comment?: string | null,
  patientID?: string | null,
  doctorID?: string | null,
  _version?: number | null,
};

export type DeleteReviewInput = {
  id: string,
  _version?: number | null,
};

export type CreateDepartmentInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  _version?: number | null,
};

export type ModelDepartmentConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelDepartmentConditionInput | null > | null,
  or?: Array< ModelDepartmentConditionInput | null > | null,
  not?: ModelDepartmentConditionInput | null,
};

export type UpdateDepartmentInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  _version?: number | null,
};

export type DeleteDepartmentInput = {
  id: string,
  _version?: number | null,
};

export type CreateWorkingTimeSpanInput = {
  id?: string | null,
  dayOfWeek: number,
  date: string,
  hours: Array< number | null >,
  doctorID: string,
  _version?: number | null,
};

export type ModelWorkingTimeSpanConditionInput = {
  dayOfWeek?: ModelIntInput | null,
  date?: ModelStringInput | null,
  hours?: ModelIntInput | null,
  doctorID?: ModelIDInput | null,
  and?: Array< ModelWorkingTimeSpanConditionInput | null > | null,
  or?: Array< ModelWorkingTimeSpanConditionInput | null > | null,
  not?: ModelWorkingTimeSpanConditionInput | null,
};

export type UpdateWorkingTimeSpanInput = {
  id: string,
  dayOfWeek?: number | null,
  date?: string | null,
  hours?: Array< number | null > | null,
  doctorID?: string | null,
  _version?: number | null,
};

export type DeleteWorkingTimeSpanInput = {
  id: string,
  _version?: number | null,
};

export type CreateAppointmentInput = {
  id?: string | null,
  date: string,
  hour: number,
  order: number,
  description?: string | null,
  doctorID: string,
  patientID: string,
  _version?: number | null,
};

export type ModelAppointmentConditionInput = {
  date?: ModelStringInput | null,
  hour?: ModelIntInput | null,
  order?: ModelIntInput | null,
  description?: ModelStringInput | null,
  doctorID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  and?: Array< ModelAppointmentConditionInput | null > | null,
  or?: Array< ModelAppointmentConditionInput | null > | null,
  not?: ModelAppointmentConditionInput | null,
};

export type UpdateAppointmentInput = {
  id: string,
  date?: string | null,
  hour?: number | null,
  order?: number | null,
  description?: string | null,
  doctorID?: string | null,
  patientID?: string | null,
  _version?: number | null,
};

export type DeleteAppointmentInput = {
  id: string,
  _version?: number | null,
};

export type CreateDiseaseInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  _version?: number | null,
};

export type ModelDiseaseConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelDiseaseConditionInput | null > | null,
  or?: Array< ModelDiseaseConditionInput | null > | null,
  not?: ModelDiseaseConditionInput | null,
};

export type UpdateDiseaseInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  _version?: number | null,
};

export type DeleteDiseaseInput = {
  id: string,
  _version?: number | null,
};

export type CreateMessageInput = {
  id?: string | null,
  timestamp: string,
  text?: string | null,
  translatedText?: string | null,
  image?: string | null,
  isDefaultMessage: boolean,
  completionTokens?: number | null,
  chatRoomID: string,
  userID: string,
  ownerUserID?: string | null,
  _version?: number | null,
};

export type ModelMessageConditionInput = {
  timestamp?: ModelStringInput | null,
  text?: ModelStringInput | null,
  translatedText?: ModelStringInput | null,
  image?: ModelStringInput | null,
  isDefaultMessage?: ModelBooleanInput | null,
  completionTokens?: ModelIntInput | null,
  chatRoomID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  ownerUserID?: ModelIDInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  or?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateMessageInput = {
  id: string,
  timestamp?: string | null,
  text?: string | null,
  translatedText?: string | null,
  image?: string | null,
  isDefaultMessage?: boolean | null,
  completionTokens?: number | null,
  chatRoomID?: string | null,
  userID?: string | null,
  ownerUserID?: string | null,
  _version?: number | null,
};

export type DeleteMessageInput = {
  id: string,
  _version?: number | null,
};

export type CreateChatRoomInput = {
  id?: string | null,
  chatRoomName: string,
  lastMessageText?: string | null,
  lastMessageTimestamp?: string | null,
  userID: string,
  agentID?: string | null,
  _version?: number | null,
};

export type ModelChatRoomConditionInput = {
  chatRoomName?: ModelStringInput | null,
  lastMessageText?: ModelStringInput | null,
  lastMessageTimestamp?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  agentID?: ModelIDInput | null,
  and?: Array< ModelChatRoomConditionInput | null > | null,
  or?: Array< ModelChatRoomConditionInput | null > | null,
  not?: ModelChatRoomConditionInput | null,
};

export type UpdateChatRoomInput = {
  id: string,
  chatRoomName?: string | null,
  lastMessageText?: string | null,
  lastMessageTimestamp?: string | null,
  userID?: string | null,
  agentID?: string | null,
  _version?: number | null,
};

export type DeleteChatRoomInput = {
  id: string,
  _version?: number | null,
};

export type CreateSFUserInput = {
  id?: string | null,
  userName: string,
  password: string,
  group: string,
  sFUserUserId: string,
  _version?: number | null,
};

export type ModelSFUserConditionInput = {
  userName?: ModelStringInput | null,
  password?: ModelStringInput | null,
  group?: ModelStringInput | null,
  sFUserUserId?: ModelIDInput | null,
  and?: Array< ModelSFUserConditionInput | null > | null,
  or?: Array< ModelSFUserConditionInput | null > | null,
  not?: ModelSFUserConditionInput | null,
};

export type UpdateSFUserInput = {
  id: string,
  userName?: string | null,
  password?: string | null,
  group?: string | null,
  sFUserUserId?: string | null,
  _version?: number | null,
};

export type DeleteSFUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserInput = {
  id?: string | null,
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber?: string | null,
  imageUrl?: string | null,
  address?: string | null,
  birthday?: string | null,
  gender?: Gender | null,
  userDoctorId?: string | null,
  _version?: number | null,
  userSFUserId?: string | null,
  userPatientId?: string | null,
};

export type ModelUserConditionInput = {
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  address?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  userDoctorId?: ModelIDInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  userSFUserId?: ModelIDInput | null,
  userPatientId?: ModelIDInput | null,
};

export type ModelGenderInput = {
  eq?: Gender | null,
  ne?: Gender | null,
};

export type UpdateUserInput = {
  id: string,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  phoneNumber?: string | null,
  imageUrl?: string | null,
  address?: string | null,
  birthday?: string | null,
  gender?: Gender | null,
  userDoctorId?: string | null,
  _version?: number | null,
  userSFUserId?: string | null,
  userPatientId?: string | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateAgentInput = {
  id?: string | null,
  name: string,
  prompt: string,
  model: string,
  language: string,
  avatar?: string | null,
  _version?: number | null,
};

export type ModelAgentConditionInput = {
  name?: ModelStringInput | null,
  prompt?: ModelStringInput | null,
  model?: ModelStringInput | null,
  language?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  and?: Array< ModelAgentConditionInput | null > | null,
  or?: Array< ModelAgentConditionInput | null > | null,
  not?: ModelAgentConditionInput | null,
};

export type UpdateAgentInput = {
  id: string,
  name?: string | null,
  prompt?: string | null,
  model?: string | null,
  language?: string | null,
  avatar?: string | null,
  _version?: number | null,
};

export type DeleteAgentInput = {
  id: string,
  _version?: number | null,
};

export type CreateDoctorPatientInput = {
  id?: string | null,
  patientId: string,
  doctorId: string,
  _version?: number | null,
};

export type ModelDoctorPatientConditionInput = {
  patientId?: ModelIDInput | null,
  doctorId?: ModelIDInput | null,
  and?: Array< ModelDoctorPatientConditionInput | null > | null,
  or?: Array< ModelDoctorPatientConditionInput | null > | null,
  not?: ModelDoctorPatientConditionInput | null,
};

export type UpdateDoctorPatientInput = {
  id: string,
  patientId?: string | null,
  doctorId?: string | null,
  _version?: number | null,
};

export type DeleteDoctorPatientInput = {
  id: string,
  _version?: number | null,
};

export type CreatePatientDiseaseInput = {
  id?: string | null,
  patientId: string,
  diseaseId: string,
  _version?: number | null,
};

export type ModelPatientDiseaseConditionInput = {
  patientId?: ModelIDInput | null,
  diseaseId?: ModelIDInput | null,
  and?: Array< ModelPatientDiseaseConditionInput | null > | null,
  or?: Array< ModelPatientDiseaseConditionInput | null > | null,
  not?: ModelPatientDiseaseConditionInput | null,
};

export type UpdatePatientDiseaseInput = {
  id: string,
  patientId?: string | null,
  diseaseId?: string | null,
  _version?: number | null,
};

export type DeletePatientDiseaseInput = {
  id: string,
  _version?: number | null,
};

export type ModelMedicineFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelMedicineFilterInput | null > | null,
  or?: Array< ModelMedicineFilterInput | null > | null,
  not?: ModelMedicineFilterInput | null,
};

export type ModelMedicineConnection = {
  __typename: "ModelMedicineConnection",
  items:  Array<Medicine | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPrescriptionFilterInput = {
  id?: ModelIDInput | null,
  instruction?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  medicalRecordID?: ModelIDInput | null,
  medicineID?: ModelIDInput | null,
  and?: Array< ModelPrescriptionFilterInput | null > | null,
  or?: Array< ModelPrescriptionFilterInput | null > | null,
  not?: ModelPrescriptionFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelMedicalRecordFilterInput = {
  id?: ModelIDInput | null,
  timestamp?: ModelStringInput | null,
  status?: ModelMedicalRecordStatusInput | null,
  pulseRate?: ModelStringInput | null,
  bloodPressure?: ModelStringInput | null,
  bodyTemperature?: ModelStringInput | null,
  respiratoryRate?: ModelStringInput | null,
  symptoms?: ModelStringInput | null,
  diagnosis?: ModelStringInput | null,
  dischargeInstruction?: ModelStringInput | null,
  billingInfo?: ModelStringInput | null,
  doctorID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  and?: Array< ModelMedicalRecordFilterInput | null > | null,
  or?: Array< ModelMedicalRecordFilterInput | null > | null,
  not?: ModelMedicalRecordFilterInput | null,
};

export type ModelTestResultFilterInput = {
  id?: ModelIDInput | null,
  timestamp?: ModelStringInput | null,
  testType?: ModelStringInput | null,
  description?: ModelStringInput | null,
  result?: ModelStringInput | null,
  images?: ModelStringInput | null,
  medicalRecordID?: ModelIDInput | null,
  and?: Array< ModelTestResultFilterInput | null > | null,
  or?: Array< ModelTestResultFilterInput | null > | null,
  not?: ModelTestResultFilterInput | null,
};

export type ModelPatientFilterInput = {
  id?: ModelIDInput | null,
  checkInTime?: ModelStringInput | null,
  status?: ModelPatientStatusInput | null,
  ethnicity?: ModelStringInput | null,
  healthHistory?: ModelStringInput | null,
  allergies?: ModelStringInput | null,
  immunizations?: ModelStringInput | null,
  patientUserId?: ModelIDInput | null,
  and?: Array< ModelPatientFilterInput | null > | null,
  or?: Array< ModelPatientFilterInput | null > | null,
  not?: ModelPatientFilterInput | null,
};

export type ModelPatientConnection = {
  __typename: "ModelPatientConnection",
  items:  Array<Patient | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDoctorFilterInput = {
  id?: ModelIDInput | null,
  specialist?: ModelStringInput | null,
  doctorUserId?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  introduction?: ModelStringInput | null,
  and?: Array< ModelDoctorFilterInput | null > | null,
  or?: Array< ModelDoctorFilterInput | null > | null,
  not?: ModelDoctorFilterInput | null,
};

export type ModelReviewFilterInput = {
  id?: ModelIDInput | null,
  rating?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  patientID?: ModelIDInput | null,
  doctorID?: ModelIDInput | null,
  and?: Array< ModelReviewFilterInput | null > | null,
  or?: Array< ModelReviewFilterInput | null > | null,
  not?: ModelReviewFilterInput | null,
};

export type ModelDepartmentFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelDepartmentFilterInput | null > | null,
  or?: Array< ModelDepartmentFilterInput | null > | null,
  not?: ModelDepartmentFilterInput | null,
};

export type ModelDepartmentConnection = {
  __typename: "ModelDepartmentConnection",
  items:  Array<Department | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelWorkingTimeSpanFilterInput = {
  id?: ModelIDInput | null,
  dayOfWeek?: ModelIntInput | null,
  date?: ModelStringInput | null,
  hours?: ModelIntInput | null,
  doctorID?: ModelIDInput | null,
  and?: Array< ModelWorkingTimeSpanFilterInput | null > | null,
  or?: Array< ModelWorkingTimeSpanFilterInput | null > | null,
  not?: ModelWorkingTimeSpanFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelAppointmentFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  hour?: ModelIntInput | null,
  order?: ModelIntInput | null,
  description?: ModelStringInput | null,
  doctorID?: ModelIDInput | null,
  patientID?: ModelIDInput | null,
  and?: Array< ModelAppointmentFilterInput | null > | null,
  or?: Array< ModelAppointmentFilterInput | null > | null,
  not?: ModelAppointmentFilterInput | null,
};

export type ModelDiseaseFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelDiseaseFilterInput | null > | null,
  or?: Array< ModelDiseaseFilterInput | null > | null,
  not?: ModelDiseaseFilterInput | null,
};

export type ModelDiseaseConnection = {
  __typename: "ModelDiseaseConnection",
  items:  Array<Disease | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  timestamp?: ModelStringInput | null,
  text?: ModelStringInput | null,
  translatedText?: ModelStringInput | null,
  image?: ModelStringInput | null,
  isDefaultMessage?: ModelBooleanInput | null,
  completionTokens?: ModelIntInput | null,
  chatRoomID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  ownerUserID?: ModelIDInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  or?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
};

export type ModelChatRoomFilterInput = {
  id?: ModelIDInput | null,
  chatRoomName?: ModelStringInput | null,
  lastMessageText?: ModelStringInput | null,
  lastMessageTimestamp?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  agentID?: ModelIDInput | null,
  and?: Array< ModelChatRoomFilterInput | null > | null,
  or?: Array< ModelChatRoomFilterInput | null > | null,
  not?: ModelChatRoomFilterInput | null,
};

export type ModelSFUserFilterInput = {
  id?: ModelIDInput | null,
  userName?: ModelStringInput | null,
  password?: ModelStringInput | null,
  group?: ModelStringInput | null,
  sFUserUserId?: ModelIDInput | null,
  and?: Array< ModelSFUserFilterInput | null > | null,
  or?: Array< ModelSFUserFilterInput | null > | null,
  not?: ModelSFUserFilterInput | null,
};

export type ModelSFUserConnection = {
  __typename: "ModelSFUserConnection",
  items:  Array<SFUser | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  address?: ModelStringInput | null,
  birthday?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  userDoctorId?: ModelIDInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  userSFUserId?: ModelIDInput | null,
  userPatientId?: ModelIDInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAgentFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  prompt?: ModelStringInput | null,
  model?: ModelStringInput | null,
  language?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  and?: Array< ModelAgentFilterInput | null > | null,
  or?: Array< ModelAgentFilterInput | null > | null,
  not?: ModelAgentFilterInput | null,
};

export type ModelAgentConnection = {
  __typename: "ModelAgentConnection",
  items:  Array<Agent | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDoctorPatientFilterInput = {
  id?: ModelIDInput | null,
  patientId?: ModelIDInput | null,
  doctorId?: ModelIDInput | null,
  and?: Array< ModelDoctorPatientFilterInput | null > | null,
  or?: Array< ModelDoctorPatientFilterInput | null > | null,
  not?: ModelDoctorPatientFilterInput | null,
};

export type ModelPatientDiseaseFilterInput = {
  id?: ModelIDInput | null,
  patientId?: ModelIDInput | null,
  diseaseId?: ModelIDInput | null,
  and?: Array< ModelPatientDiseaseFilterInput | null > | null,
  or?: Array< ModelPatientDiseaseFilterInput | null > | null,
  not?: ModelPatientDiseaseFilterInput | null,
};

export type CreateMedicineMutationVariables = {
  input: CreateMedicineInput,
  condition?: ModelMedicineConditionInput | null,
};

export type CreateMedicineMutation = {
  createMedicine?:  {
    __typename: "Medicine",
    id: string,
    name: string,
    description?: string | null,
    prescriptions?:  {
      __typename: "ModelPrescriptionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMedicineMutationVariables = {
  input: UpdateMedicineInput,
  condition?: ModelMedicineConditionInput | null,
};

export type UpdateMedicineMutation = {
  updateMedicine?:  {
    __typename: "Medicine",
    id: string,
    name: string,
    description?: string | null,
    prescriptions?:  {
      __typename: "ModelPrescriptionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMedicineMutationVariables = {
  input: DeleteMedicineInput,
  condition?: ModelMedicineConditionInput | null,
};

export type DeleteMedicineMutation = {
  deleteMedicine?:  {
    __typename: "Medicine",
    id: string,
    name: string,
    description?: string | null,
    prescriptions?:  {
      __typename: "ModelPrescriptionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePrescriptionMutationVariables = {
  input: CreatePrescriptionInput,
  condition?: ModelPrescriptionConditionInput | null,
};

export type CreatePrescriptionMutation = {
  createPrescription?:  {
    __typename: "Prescription",
    id: string,
    instruction: string,
    quantity: number,
    medicalRecordID: string,
    medicalRecord?:  {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    medicineID: string,
    medicine?:  {
      __typename: "Medicine",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePrescriptionMutationVariables = {
  input: UpdatePrescriptionInput,
  condition?: ModelPrescriptionConditionInput | null,
};

export type UpdatePrescriptionMutation = {
  updatePrescription?:  {
    __typename: "Prescription",
    id: string,
    instruction: string,
    quantity: number,
    medicalRecordID: string,
    medicalRecord?:  {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    medicineID: string,
    medicine?:  {
      __typename: "Medicine",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePrescriptionMutationVariables = {
  input: DeletePrescriptionInput,
  condition?: ModelPrescriptionConditionInput | null,
};

export type DeletePrescriptionMutation = {
  deletePrescription?:  {
    __typename: "Prescription",
    id: string,
    instruction: string,
    quantity: number,
    medicalRecordID: string,
    medicalRecord?:  {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    medicineID: string,
    medicine?:  {
      __typename: "Medicine",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMedicalRecordMutationVariables = {
  input: CreateMedicalRecordInput,
  condition?: ModelMedicalRecordConditionInput | null,
};

export type CreateMedicalRecordMutation = {
  createMedicalRecord?:  {
    __typename: "MedicalRecord",
    id: string,
    timestamp: string,
    status: MedicalRecordStatus,
    pulseRate?: string | null,
    bloodPressure?: string | null,
    bodyTemperature?: string | null,
    respiratoryRate?: string | null,
    symptoms?: string | null,
    diagnosis?: string | null,
    dischargeInstruction?: string | null,
    billingInfo?: string | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    prescriptions?:  {
      __typename: "ModelPrescriptionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    testResults?:  {
      __typename: "ModelTestResultConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMedicalRecordMutationVariables = {
  input: UpdateMedicalRecordInput,
  condition?: ModelMedicalRecordConditionInput | null,
};

export type UpdateMedicalRecordMutation = {
  updateMedicalRecord?:  {
    __typename: "MedicalRecord",
    id: string,
    timestamp: string,
    status: MedicalRecordStatus,
    pulseRate?: string | null,
    bloodPressure?: string | null,
    bodyTemperature?: string | null,
    respiratoryRate?: string | null,
    symptoms?: string | null,
    diagnosis?: string | null,
    dischargeInstruction?: string | null,
    billingInfo?: string | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    prescriptions?:  {
      __typename: "ModelPrescriptionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    testResults?:  {
      __typename: "ModelTestResultConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMedicalRecordMutationVariables = {
  input: DeleteMedicalRecordInput,
  condition?: ModelMedicalRecordConditionInput | null,
};

export type DeleteMedicalRecordMutation = {
  deleteMedicalRecord?:  {
    __typename: "MedicalRecord",
    id: string,
    timestamp: string,
    status: MedicalRecordStatus,
    pulseRate?: string | null,
    bloodPressure?: string | null,
    bodyTemperature?: string | null,
    respiratoryRate?: string | null,
    symptoms?: string | null,
    diagnosis?: string | null,
    dischargeInstruction?: string | null,
    billingInfo?: string | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    prescriptions?:  {
      __typename: "ModelPrescriptionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    testResults?:  {
      __typename: "ModelTestResultConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTestResultMutationVariables = {
  input: CreateTestResultInput,
  condition?: ModelTestResultConditionInput | null,
};

export type CreateTestResultMutation = {
  createTestResult?:  {
    __typename: "TestResult",
    id: string,
    timestamp: string,
    testType: string,
    description: string,
    result: string,
    images?: Array< string | null > | null,
    medicalRecordID: string,
    medicalRecord?:  {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTestResultMutationVariables = {
  input: UpdateTestResultInput,
  condition?: ModelTestResultConditionInput | null,
};

export type UpdateTestResultMutation = {
  updateTestResult?:  {
    __typename: "TestResult",
    id: string,
    timestamp: string,
    testType: string,
    description: string,
    result: string,
    images?: Array< string | null > | null,
    medicalRecordID: string,
    medicalRecord?:  {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTestResultMutationVariables = {
  input: DeleteTestResultInput,
  condition?: ModelTestResultConditionInput | null,
};

export type DeleteTestResultMutation = {
  deleteTestResult?:  {
    __typename: "TestResult",
    id: string,
    timestamp: string,
    testType: string,
    description: string,
    result: string,
    images?: Array< string | null > | null,
    medicalRecordID: string,
    medicalRecord?:  {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePatientMutationVariables = {
  input: CreatePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type CreatePatientMutation = {
  createPatient?:  {
    __typename: "Patient",
    id: string,
    checkInTime?: string | null,
    status: PatientStatus,
    ethnicity?: string | null,
    healthHistory?: string | null,
    allergies?: string | null,
    immunizations?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    patientUserId: string,
    doctors?:  {
      __typename: "ModelDoctorPatientConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    diseases?:  {
      __typename: "ModelPatientDiseaseConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    medicalRecords?:  {
      __typename: "ModelMedicalRecordConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdatePatientMutationVariables = {
  input: UpdatePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type UpdatePatientMutation = {
  updatePatient?:  {
    __typename: "Patient",
    id: string,
    checkInTime?: string | null,
    status: PatientStatus,
    ethnicity?: string | null,
    healthHistory?: string | null,
    allergies?: string | null,
    immunizations?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    patientUserId: string,
    doctors?:  {
      __typename: "ModelDoctorPatientConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    diseases?:  {
      __typename: "ModelPatientDiseaseConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    medicalRecords?:  {
      __typename: "ModelMedicalRecordConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeletePatientMutationVariables = {
  input: DeletePatientInput,
  condition?: ModelPatientConditionInput | null,
};

export type DeletePatientMutation = {
  deletePatient?:  {
    __typename: "Patient",
    id: string,
    checkInTime?: string | null,
    status: PatientStatus,
    ethnicity?: string | null,
    healthHistory?: string | null,
    allergies?: string | null,
    immunizations?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    patientUserId: string,
    doctors?:  {
      __typename: "ModelDoctorPatientConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    diseases?:  {
      __typename: "ModelPatientDiseaseConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    medicalRecords?:  {
      __typename: "ModelMedicalRecordConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateDoctorMutationVariables = {
  input: CreateDoctorInput,
  condition?: ModelDoctorConditionInput | null,
};

export type CreateDoctorMutation = {
  createDoctor?:  {
    __typename: "Doctor",
    id: string,
    specialist: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    doctorUserId: string,
    patients?:  {
      __typename: "ModelDoctorPatientConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    medicalRecords?:  {
      __typename: "ModelMedicalRecordConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID?: string | null,
    department?:  {
      __typename: "Department",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    workingTimeSpans?:  {
      __typename: "ModelWorkingTimeSpanConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    introduction?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDoctorMutationVariables = {
  input: UpdateDoctorInput,
  condition?: ModelDoctorConditionInput | null,
};

export type UpdateDoctorMutation = {
  updateDoctor?:  {
    __typename: "Doctor",
    id: string,
    specialist: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    doctorUserId: string,
    patients?:  {
      __typename: "ModelDoctorPatientConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    medicalRecords?:  {
      __typename: "ModelMedicalRecordConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID?: string | null,
    department?:  {
      __typename: "Department",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    workingTimeSpans?:  {
      __typename: "ModelWorkingTimeSpanConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    introduction?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDoctorMutationVariables = {
  input: DeleteDoctorInput,
  condition?: ModelDoctorConditionInput | null,
};

export type DeleteDoctorMutation = {
  deleteDoctor?:  {
    __typename: "Doctor",
    id: string,
    specialist: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    doctorUserId: string,
    patients?:  {
      __typename: "ModelDoctorPatientConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    medicalRecords?:  {
      __typename: "ModelMedicalRecordConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID?: string | null,
    department?:  {
      __typename: "Department",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    workingTimeSpans?:  {
      __typename: "ModelWorkingTimeSpanConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    introduction?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateReviewMutationVariables = {
  input: CreateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type CreateReviewMutation = {
  createReview?:  {
    __typename: "Review",
    id: string,
    rating: number,
    comment?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    patientID: string,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateReviewMutationVariables = {
  input: UpdateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type UpdateReviewMutation = {
  updateReview?:  {
    __typename: "Review",
    id: string,
    rating: number,
    comment?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    patientID: string,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteReviewMutationVariables = {
  input: DeleteReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type DeleteReviewMutation = {
  deleteReview?:  {
    __typename: "Review",
    id: string,
    rating: number,
    comment?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    patientID: string,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDepartmentMutationVariables = {
  input: CreateDepartmentInput,
  condition?: ModelDepartmentConditionInput | null,
};

export type CreateDepartmentMutation = {
  createDepartment?:  {
    __typename: "Department",
    id: string,
    name: string,
    description?: string | null,
    doctors?:  {
      __typename: "ModelDoctorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDepartmentMutationVariables = {
  input: UpdateDepartmentInput,
  condition?: ModelDepartmentConditionInput | null,
};

export type UpdateDepartmentMutation = {
  updateDepartment?:  {
    __typename: "Department",
    id: string,
    name: string,
    description?: string | null,
    doctors?:  {
      __typename: "ModelDoctorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDepartmentMutationVariables = {
  input: DeleteDepartmentInput,
  condition?: ModelDepartmentConditionInput | null,
};

export type DeleteDepartmentMutation = {
  deleteDepartment?:  {
    __typename: "Department",
    id: string,
    name: string,
    description?: string | null,
    doctors?:  {
      __typename: "ModelDoctorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateWorkingTimeSpanMutationVariables = {
  input: CreateWorkingTimeSpanInput,
  condition?: ModelWorkingTimeSpanConditionInput | null,
};

export type CreateWorkingTimeSpanMutation = {
  createWorkingTimeSpan?:  {
    __typename: "WorkingTimeSpan",
    id: string,
    dayOfWeek: number,
    date: string,
    hours: Array< number | null >,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateWorkingTimeSpanMutationVariables = {
  input: UpdateWorkingTimeSpanInput,
  condition?: ModelWorkingTimeSpanConditionInput | null,
};

export type UpdateWorkingTimeSpanMutation = {
  updateWorkingTimeSpan?:  {
    __typename: "WorkingTimeSpan",
    id: string,
    dayOfWeek: number,
    date: string,
    hours: Array< number | null >,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteWorkingTimeSpanMutationVariables = {
  input: DeleteWorkingTimeSpanInput,
  condition?: ModelWorkingTimeSpanConditionInput | null,
};

export type DeleteWorkingTimeSpanMutation = {
  deleteWorkingTimeSpan?:  {
    __typename: "WorkingTimeSpan",
    id: string,
    dayOfWeek: number,
    date: string,
    hours: Array< number | null >,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAppointmentMutationVariables = {
  input: CreateAppointmentInput,
  condition?: ModelAppointmentConditionInput | null,
};

export type CreateAppointmentMutation = {
  createAppointment?:  {
    __typename: "Appointment",
    id: string,
    date: string,
    hour: number,
    order: number,
    description?: string | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAppointmentMutationVariables = {
  input: UpdateAppointmentInput,
  condition?: ModelAppointmentConditionInput | null,
};

export type UpdateAppointmentMutation = {
  updateAppointment?:  {
    __typename: "Appointment",
    id: string,
    date: string,
    hour: number,
    order: number,
    description?: string | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAppointmentMutationVariables = {
  input: DeleteAppointmentInput,
  condition?: ModelAppointmentConditionInput | null,
};

export type DeleteAppointmentMutation = {
  deleteAppointment?:  {
    __typename: "Appointment",
    id: string,
    date: string,
    hour: number,
    order: number,
    description?: string | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDiseaseMutationVariables = {
  input: CreateDiseaseInput,
  condition?: ModelDiseaseConditionInput | null,
};

export type CreateDiseaseMutation = {
  createDisease?:  {
    __typename: "Disease",
    id: string,
    name: string,
    description?: string | null,
    patients?:  {
      __typename: "ModelPatientDiseaseConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDiseaseMutationVariables = {
  input: UpdateDiseaseInput,
  condition?: ModelDiseaseConditionInput | null,
};

export type UpdateDiseaseMutation = {
  updateDisease?:  {
    __typename: "Disease",
    id: string,
    name: string,
    description?: string | null,
    patients?:  {
      __typename: "ModelPatientDiseaseConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDiseaseMutationVariables = {
  input: DeleteDiseaseInput,
  condition?: ModelDiseaseConditionInput | null,
};

export type DeleteDiseaseMutation = {
  deleteDisease?:  {
    __typename: "Disease",
    id: string,
    name: string,
    description?: string | null,
    patients?:  {
      __typename: "ModelPatientDiseaseConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    timestamp: string,
    text?: string | null,
    translatedText?: string | null,
    image?: string | null,
    isDefaultMessage: boolean,
    completionTokens?: number | null,
    chatRoomID: string,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      chatRoomName: string,
      lastMessageText?: string | null,
      lastMessageTimestamp?: string | null,
      userID: string,
      agentID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    ownerUserID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?:  {
    __typename: "Message",
    id: string,
    timestamp: string,
    text?: string | null,
    translatedText?: string | null,
    image?: string | null,
    isDefaultMessage: boolean,
    completionTokens?: number | null,
    chatRoomID: string,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      chatRoomName: string,
      lastMessageText?: string | null,
      lastMessageTimestamp?: string | null,
      userID: string,
      agentID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    ownerUserID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?:  {
    __typename: "Message",
    id: string,
    timestamp: string,
    text?: string | null,
    translatedText?: string | null,
    image?: string | null,
    isDefaultMessage: boolean,
    completionTokens?: number | null,
    chatRoomID: string,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      chatRoomName: string,
      lastMessageText?: string | null,
      lastMessageTimestamp?: string | null,
      userID: string,
      agentID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    ownerUserID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateChatRoomMutationVariables = {
  input: CreateChatRoomInput,
  condition?: ModelChatRoomConditionInput | null,
};

export type CreateChatRoomMutation = {
  createChatRoom?:  {
    __typename: "ChatRoom",
    id: string,
    chatRoomName: string,
    lastMessageText?: string | null,
    lastMessageTimestamp?: string | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    agentID?: string | null,
    agent?:  {
      __typename: "Agent",
      id: string,
      name: string,
      prompt: string,
      model: string,
      language: string,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateChatRoomMutationVariables = {
  input: UpdateChatRoomInput,
  condition?: ModelChatRoomConditionInput | null,
};

export type UpdateChatRoomMutation = {
  updateChatRoom?:  {
    __typename: "ChatRoom",
    id: string,
    chatRoomName: string,
    lastMessageText?: string | null,
    lastMessageTimestamp?: string | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    agentID?: string | null,
    agent?:  {
      __typename: "Agent",
      id: string,
      name: string,
      prompt: string,
      model: string,
      language: string,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteChatRoomMutationVariables = {
  input: DeleteChatRoomInput,
  condition?: ModelChatRoomConditionInput | null,
};

export type DeleteChatRoomMutation = {
  deleteChatRoom?:  {
    __typename: "ChatRoom",
    id: string,
    chatRoomName: string,
    lastMessageText?: string | null,
    lastMessageTimestamp?: string | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    agentID?: string | null,
    agent?:  {
      __typename: "Agent",
      id: string,
      name: string,
      prompt: string,
      model: string,
      language: string,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSFUserMutationVariables = {
  input: CreateSFUserInput,
  condition?: ModelSFUserConditionInput | null,
};

export type CreateSFUserMutation = {
  createSFUser?:  {
    __typename: "SFUser",
    id: string,
    userName: string,
    password: string,
    group: string,
    sFUserUserId: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateSFUserMutationVariables = {
  input: UpdateSFUserInput,
  condition?: ModelSFUserConditionInput | null,
};

export type UpdateSFUserMutation = {
  updateSFUser?:  {
    __typename: "SFUser",
    id: string,
    userName: string,
    password: string,
    group: string,
    sFUserUserId: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteSFUserMutationVariables = {
  input: DeleteSFUserInput,
  condition?: ModelSFUserConditionInput | null,
};

export type DeleteSFUserMutation = {
  deleteSFUser?:  {
    __typename: "SFUser",
    id: string,
    userName: string,
    password: string,
    group: string,
    sFUserUserId: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber?: string | null,
    imageUrl?: string | null,
    address?: string | null,
    birthday?: string | null,
    gender?: Gender | null,
    sFUser?:  {
      __typename: "SFUser",
      id: string,
      userName: string,
      password: string,
      group: string,
      sFUserUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    userDoctorId?: string | null,
    chatRooms?:  {
      __typename: "ModelChatRoomConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userSFUserId?: string | null,
    userPatientId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber?: string | null,
    imageUrl?: string | null,
    address?: string | null,
    birthday?: string | null,
    gender?: Gender | null,
    sFUser?:  {
      __typename: "SFUser",
      id: string,
      userName: string,
      password: string,
      group: string,
      sFUserUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    userDoctorId?: string | null,
    chatRooms?:  {
      __typename: "ModelChatRoomConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userSFUserId?: string | null,
    userPatientId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber?: string | null,
    imageUrl?: string | null,
    address?: string | null,
    birthday?: string | null,
    gender?: Gender | null,
    sFUser?:  {
      __typename: "SFUser",
      id: string,
      userName: string,
      password: string,
      group: string,
      sFUserUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    userDoctorId?: string | null,
    chatRooms?:  {
      __typename: "ModelChatRoomConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userSFUserId?: string | null,
    userPatientId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateAgentMutationVariables = {
  input: CreateAgentInput,
  condition?: ModelAgentConditionInput | null,
};

export type CreateAgentMutation = {
  createAgent?:  {
    __typename: "Agent",
    id: string,
    name: string,
    prompt: string,
    model: string,
    language: string,
    avatar?: string | null,
    chatRooms?:  {
      __typename: "ModelChatRoomConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAgentMutationVariables = {
  input: UpdateAgentInput,
  condition?: ModelAgentConditionInput | null,
};

export type UpdateAgentMutation = {
  updateAgent?:  {
    __typename: "Agent",
    id: string,
    name: string,
    prompt: string,
    model: string,
    language: string,
    avatar?: string | null,
    chatRooms?:  {
      __typename: "ModelChatRoomConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAgentMutationVariables = {
  input: DeleteAgentInput,
  condition?: ModelAgentConditionInput | null,
};

export type DeleteAgentMutation = {
  deleteAgent?:  {
    __typename: "Agent",
    id: string,
    name: string,
    prompt: string,
    model: string,
    language: string,
    avatar?: string | null,
    chatRooms?:  {
      __typename: "ModelChatRoomConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDoctorPatientMutationVariables = {
  input: CreateDoctorPatientInput,
  condition?: ModelDoctorPatientConditionInput | null,
};

export type CreateDoctorPatientMutation = {
  createDoctorPatient?:  {
    __typename: "DoctorPatient",
    id: string,
    patientId: string,
    doctorId: string,
    patient:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    doctor:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateDoctorPatientMutationVariables = {
  input: UpdateDoctorPatientInput,
  condition?: ModelDoctorPatientConditionInput | null,
};

export type UpdateDoctorPatientMutation = {
  updateDoctorPatient?:  {
    __typename: "DoctorPatient",
    id: string,
    patientId: string,
    doctorId: string,
    patient:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    doctor:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteDoctorPatientMutationVariables = {
  input: DeleteDoctorPatientInput,
  condition?: ModelDoctorPatientConditionInput | null,
};

export type DeleteDoctorPatientMutation = {
  deleteDoctorPatient?:  {
    __typename: "DoctorPatient",
    id: string,
    patientId: string,
    doctorId: string,
    patient:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    doctor:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreatePatientDiseaseMutationVariables = {
  input: CreatePatientDiseaseInput,
  condition?: ModelPatientDiseaseConditionInput | null,
};

export type CreatePatientDiseaseMutation = {
  createPatientDisease?:  {
    __typename: "PatientDisease",
    id: string,
    patientId: string,
    diseaseId: string,
    patient:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    disease:  {
      __typename: "Disease",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdatePatientDiseaseMutationVariables = {
  input: UpdatePatientDiseaseInput,
  condition?: ModelPatientDiseaseConditionInput | null,
};

export type UpdatePatientDiseaseMutation = {
  updatePatientDisease?:  {
    __typename: "PatientDisease",
    id: string,
    patientId: string,
    diseaseId: string,
    patient:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    disease:  {
      __typename: "Disease",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeletePatientDiseaseMutationVariables = {
  input: DeletePatientDiseaseInput,
  condition?: ModelPatientDiseaseConditionInput | null,
};

export type DeletePatientDiseaseMutation = {
  deletePatientDisease?:  {
    __typename: "PatientDisease",
    id: string,
    patientId: string,
    diseaseId: string,
    patient:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    disease:  {
      __typename: "Disease",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type GetMedicineQueryVariables = {
  id: string,
};

export type GetMedicineQuery = {
  getMedicine?:  {
    __typename: "Medicine",
    id: string,
    name: string,
    description?: string | null,
    prescriptions?:  {
      __typename: "ModelPrescriptionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMedicinesQueryVariables = {
  filter?: ModelMedicineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMedicinesQuery = {
  listMedicines?:  {
    __typename: "ModelMedicineConnection",
    items:  Array< {
      __typename: "Medicine",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMedicinesQueryVariables = {
  filter?: ModelMedicineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMedicinesQuery = {
  syncMedicines?:  {
    __typename: "ModelMedicineConnection",
    items:  Array< {
      __typename: "Medicine",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPrescriptionQueryVariables = {
  id: string,
};

export type GetPrescriptionQuery = {
  getPrescription?:  {
    __typename: "Prescription",
    id: string,
    instruction: string,
    quantity: number,
    medicalRecordID: string,
    medicalRecord?:  {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    medicineID: string,
    medicine?:  {
      __typename: "Medicine",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPrescriptionsQueryVariables = {
  filter?: ModelPrescriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPrescriptionsQuery = {
  listPrescriptions?:  {
    __typename: "ModelPrescriptionConnection",
    items:  Array< {
      __typename: "Prescription",
      id: string,
      instruction: string,
      quantity: number,
      medicalRecordID: string,
      medicineID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPrescriptionsQueryVariables = {
  filter?: ModelPrescriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPrescriptionsQuery = {
  syncPrescriptions?:  {
    __typename: "ModelPrescriptionConnection",
    items:  Array< {
      __typename: "Prescription",
      id: string,
      instruction: string,
      quantity: number,
      medicalRecordID: string,
      medicineID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PrescriptionsByMedicalRecordIDQueryVariables = {
  medicalRecordID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPrescriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PrescriptionsByMedicalRecordIDQuery = {
  prescriptionsByMedicalRecordID?:  {
    __typename: "ModelPrescriptionConnection",
    items:  Array< {
      __typename: "Prescription",
      id: string,
      instruction: string,
      quantity: number,
      medicalRecordID: string,
      medicineID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PrescriptionsByMedicineIDQueryVariables = {
  medicineID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPrescriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PrescriptionsByMedicineIDQuery = {
  prescriptionsByMedicineID?:  {
    __typename: "ModelPrescriptionConnection",
    items:  Array< {
      __typename: "Prescription",
      id: string,
      instruction: string,
      quantity: number,
      medicalRecordID: string,
      medicineID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMedicalRecordQueryVariables = {
  id: string,
};

export type GetMedicalRecordQuery = {
  getMedicalRecord?:  {
    __typename: "MedicalRecord",
    id: string,
    timestamp: string,
    status: MedicalRecordStatus,
    pulseRate?: string | null,
    bloodPressure?: string | null,
    bodyTemperature?: string | null,
    respiratoryRate?: string | null,
    symptoms?: string | null,
    diagnosis?: string | null,
    dischargeInstruction?: string | null,
    billingInfo?: string | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    prescriptions?:  {
      __typename: "ModelPrescriptionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    testResults?:  {
      __typename: "ModelTestResultConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMedicalRecordsQueryVariables = {
  filter?: ModelMedicalRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMedicalRecordsQuery = {
  listMedicalRecords?:  {
    __typename: "ModelMedicalRecordConnection",
    items:  Array< {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMedicalRecordsQueryVariables = {
  filter?: ModelMedicalRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMedicalRecordsQuery = {
  syncMedicalRecords?:  {
    __typename: "ModelMedicalRecordConnection",
    items:  Array< {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MedicalRecordsByDoctorIDQueryVariables = {
  doctorID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMedicalRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MedicalRecordsByDoctorIDQuery = {
  medicalRecordsByDoctorID?:  {
    __typename: "ModelMedicalRecordConnection",
    items:  Array< {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MedicalRecordsByPatientIDQueryVariables = {
  patientID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMedicalRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MedicalRecordsByPatientIDQuery = {
  medicalRecordsByPatientID?:  {
    __typename: "ModelMedicalRecordConnection",
    items:  Array< {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTestResultQueryVariables = {
  id: string,
};

export type GetTestResultQuery = {
  getTestResult?:  {
    __typename: "TestResult",
    id: string,
    timestamp: string,
    testType: string,
    description: string,
    result: string,
    images?: Array< string | null > | null,
    medicalRecordID: string,
    medicalRecord?:  {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTestResultsQueryVariables = {
  filter?: ModelTestResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTestResultsQuery = {
  listTestResults?:  {
    __typename: "ModelTestResultConnection",
    items:  Array< {
      __typename: "TestResult",
      id: string,
      timestamp: string,
      testType: string,
      description: string,
      result: string,
      images?: Array< string | null > | null,
      medicalRecordID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTestResultsQueryVariables = {
  filter?: ModelTestResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTestResultsQuery = {
  syncTestResults?:  {
    __typename: "ModelTestResultConnection",
    items:  Array< {
      __typename: "TestResult",
      id: string,
      timestamp: string,
      testType: string,
      description: string,
      result: string,
      images?: Array< string | null > | null,
      medicalRecordID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TestResultsByMedicalRecordIDQueryVariables = {
  medicalRecordID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTestResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TestResultsByMedicalRecordIDQuery = {
  testResultsByMedicalRecordID?:  {
    __typename: "ModelTestResultConnection",
    items:  Array< {
      __typename: "TestResult",
      id: string,
      timestamp: string,
      testType: string,
      description: string,
      result: string,
      images?: Array< string | null > | null,
      medicalRecordID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPatientQueryVariables = {
  id: string,
};

export type GetPatientQuery = {
  getPatient?:  {
    __typename: "Patient",
    id: string,
    checkInTime?: string | null,
    status: PatientStatus,
    ethnicity?: string | null,
    healthHistory?: string | null,
    allergies?: string | null,
    immunizations?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    patientUserId: string,
    doctors?:  {
      __typename: "ModelDoctorPatientConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    diseases?:  {
      __typename: "ModelPatientDiseaseConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    medicalRecords?:  {
      __typename: "ModelMedicalRecordConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListPatientsQueryVariables = {
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientsQuery = {
  listPatients?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPatientsQueryVariables = {
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPatientsQuery = {
  syncPatients?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PatientByUserIdQueryVariables = {
  patientUserId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PatientByUserIdQuery = {
  patientByUserId?:  {
    __typename: "ModelPatientConnection",
    items:  Array< {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDoctorQueryVariables = {
  id: string,
};

export type GetDoctorQuery = {
  getDoctor?:  {
    __typename: "Doctor",
    id: string,
    specialist: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    doctorUserId: string,
    patients?:  {
      __typename: "ModelDoctorPatientConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    medicalRecords?:  {
      __typename: "ModelMedicalRecordConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID?: string | null,
    department?:  {
      __typename: "Department",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    workingTimeSpans?:  {
      __typename: "ModelWorkingTimeSpanConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    introduction?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDoctorsQueryVariables = {
  filter?: ModelDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDoctorsQuery = {
  listDoctors?:  {
    __typename: "ModelDoctorConnection",
    items:  Array< {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDoctorsQueryVariables = {
  filter?: ModelDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDoctorsQuery = {
  syncDoctors?:  {
    __typename: "ModelDoctorConnection",
    items:  Array< {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DoctorByUserIdQueryVariables = {
  doctorUserId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DoctorByUserIdQuery = {
  doctorByUserId?:  {
    __typename: "ModelDoctorConnection",
    items:  Array< {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DoctorsByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDoctorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DoctorsByDepartmentIDQuery = {
  doctorsByDepartmentID?:  {
    __typename: "ModelDoctorConnection",
    items:  Array< {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetReviewQueryVariables = {
  id: string,
};

export type GetReviewQuery = {
  getReview?:  {
    __typename: "Review",
    id: string,
    rating: number,
    comment?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    patientID: string,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListReviewsQueryVariables = {
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewsQuery = {
  listReviews?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      rating: number,
      comment?: string | null,
      patientID: string,
      doctorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncReviewsQueryVariables = {
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncReviewsQuery = {
  syncReviews?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      rating: number,
      comment?: string | null,
      patientID: string,
      doctorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ReviewsByPatientIDQueryVariables = {
  patientID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewsByPatientIDQuery = {
  reviewsByPatientID?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      rating: number,
      comment?: string | null,
      patientID: string,
      doctorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ReviewsByDoctorIDQueryVariables = {
  doctorID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewsByDoctorIDQuery = {
  reviewsByDoctorID?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      rating: number,
      comment?: string | null,
      patientID: string,
      doctorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDepartmentQueryVariables = {
  id: string,
};

export type GetDepartmentQuery = {
  getDepartment?:  {
    __typename: "Department",
    id: string,
    name: string,
    description?: string | null,
    doctors?:  {
      __typename: "ModelDoctorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDepartmentsQueryVariables = {
  filter?: ModelDepartmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDepartmentsQuery = {
  listDepartments?:  {
    __typename: "ModelDepartmentConnection",
    items:  Array< {
      __typename: "Department",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDepartmentsQueryVariables = {
  filter?: ModelDepartmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDepartmentsQuery = {
  syncDepartments?:  {
    __typename: "ModelDepartmentConnection",
    items:  Array< {
      __typename: "Department",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetWorkingTimeSpanQueryVariables = {
  id: string,
};

export type GetWorkingTimeSpanQuery = {
  getWorkingTimeSpan?:  {
    __typename: "WorkingTimeSpan",
    id: string,
    dayOfWeek: number,
    date: string,
    hours: Array< number | null >,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListWorkingTimeSpansQueryVariables = {
  filter?: ModelWorkingTimeSpanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkingTimeSpansQuery = {
  listWorkingTimeSpans?:  {
    __typename: "ModelWorkingTimeSpanConnection",
    items:  Array< {
      __typename: "WorkingTimeSpan",
      id: string,
      dayOfWeek: number,
      date: string,
      hours: Array< number | null >,
      doctorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncWorkingTimeSpansQueryVariables = {
  filter?: ModelWorkingTimeSpanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncWorkingTimeSpansQuery = {
  syncWorkingTimeSpans?:  {
    __typename: "ModelWorkingTimeSpanConnection",
    items:  Array< {
      __typename: "WorkingTimeSpan",
      id: string,
      dayOfWeek: number,
      date: string,
      hours: Array< number | null >,
      doctorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type WorkingTimeSpansByDoctorAndDateQueryVariables = {
  doctorID: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkingTimeSpanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WorkingTimeSpansByDoctorAndDateQuery = {
  workingTimeSpansByDoctorAndDate?:  {
    __typename: "ModelWorkingTimeSpanConnection",
    items:  Array< {
      __typename: "WorkingTimeSpan",
      id: string,
      dayOfWeek: number,
      date: string,
      hours: Array< number | null >,
      doctorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAppointmentQueryVariables = {
  id: string,
};

export type GetAppointmentQuery = {
  getAppointment?:  {
    __typename: "Appointment",
    id: string,
    date: string,
    hour: number,
    order: number,
    description?: string | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAppointmentsQueryVariables = {
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppointmentsQuery = {
  listAppointments?:  {
    __typename: "ModelAppointmentConnection",
    items:  Array< {
      __typename: "Appointment",
      id: string,
      date: string,
      hour: number,
      order: number,
      description?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAppointmentsQueryVariables = {
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAppointmentsQuery = {
  syncAppointments?:  {
    __typename: "ModelAppointmentConnection",
    items:  Array< {
      __typename: "Appointment",
      id: string,
      date: string,
      hour: number,
      order: number,
      description?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AppointmentsByDoctorAndDateQueryVariables = {
  doctorID: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AppointmentsByDoctorAndDateQuery = {
  appointmentsByDoctorAndDate?:  {
    __typename: "ModelAppointmentConnection",
    items:  Array< {
      __typename: "Appointment",
      id: string,
      date: string,
      hour: number,
      order: number,
      description?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AppointmentsByPatientIDQueryVariables = {
  patientID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAppointmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AppointmentsByPatientIDQuery = {
  appointmentsByPatientID?:  {
    __typename: "ModelAppointmentConnection",
    items:  Array< {
      __typename: "Appointment",
      id: string,
      date: string,
      hour: number,
      order: number,
      description?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDiseaseQueryVariables = {
  id: string,
};

export type GetDiseaseQuery = {
  getDisease?:  {
    __typename: "Disease",
    id: string,
    name: string,
    description?: string | null,
    patients?:  {
      __typename: "ModelPatientDiseaseConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDiseasesQueryVariables = {
  filter?: ModelDiseaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDiseasesQuery = {
  listDiseases?:  {
    __typename: "ModelDiseaseConnection",
    items:  Array< {
      __typename: "Disease",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDiseasesQueryVariables = {
  filter?: ModelDiseaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDiseasesQuery = {
  syncDiseases?:  {
    __typename: "ModelDiseaseConnection",
    items:  Array< {
      __typename: "Disease",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMessageQueryVariables = {
  id: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "Message",
    id: string,
    timestamp: string,
    text?: string | null,
    translatedText?: string | null,
    image?: string | null,
    isDefaultMessage: boolean,
    completionTokens?: number | null,
    chatRoomID: string,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      chatRoomName: string,
      lastMessageText?: string | null,
      lastMessageTimestamp?: string | null,
      userID: string,
      agentID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    ownerUserID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMessagesQuery = {
  listMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      timestamp: string,
      text?: string | null,
      translatedText?: string | null,
      image?: string | null,
      isDefaultMessage: boolean,
      completionTokens?: number | null,
      chatRoomID: string,
      userID: string,
      ownerUserID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMessagesQueryVariables = {
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMessagesQuery = {
  syncMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      timestamp: string,
      text?: string | null,
      translatedText?: string | null,
      image?: string | null,
      isDefaultMessage: boolean,
      completionTokens?: number | null,
      chatRoomID: string,
      userID: string,
      ownerUserID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChatRoomMessagesQueryVariables = {
  chatRoomID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatRoomMessagesQuery = {
  chatRoomMessages?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      timestamp: string,
      text?: string | null,
      translatedText?: string | null,
      image?: string | null,
      isDefaultMessage: boolean,
      completionTokens?: number | null,
      chatRoomID: string,
      userID: string,
      ownerUserID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MessagesByUserIDQueryVariables = {
  userID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesByUserIDQuery = {
  messagesByUserID?:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      timestamp: string,
      text?: string | null,
      translatedText?: string | null,
      image?: string | null,
      isDefaultMessage: boolean,
      completionTokens?: number | null,
      chatRoomID: string,
      userID: string,
      ownerUserID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetChatRoomQueryVariables = {
  id: string,
};

export type GetChatRoomQuery = {
  getChatRoom?:  {
    __typename: "ChatRoom",
    id: string,
    chatRoomName: string,
    lastMessageText?: string | null,
    lastMessageTimestamp?: string | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    agentID?: string | null,
    agent?:  {
      __typename: "Agent",
      id: string,
      name: string,
      prompt: string,
      model: string,
      language: string,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListChatRoomsQueryVariables = {
  filter?: ModelChatRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChatRoomsQuery = {
  listChatRooms?:  {
    __typename: "ModelChatRoomConnection",
    items:  Array< {
      __typename: "ChatRoom",
      id: string,
      chatRoomName: string,
      lastMessageText?: string | null,
      lastMessageTimestamp?: string | null,
      userID: string,
      agentID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncChatRoomsQueryVariables = {
  filter?: ModelChatRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncChatRoomsQuery = {
  syncChatRooms?:  {
    __typename: "ModelChatRoomConnection",
    items:  Array< {
      __typename: "ChatRoom",
      id: string,
      chatRoomName: string,
      lastMessageText?: string | null,
      lastMessageTimestamp?: string | null,
      userID: string,
      agentID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChatRoomByNameQueryVariables = {
  chatRoomName: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatRoomByNameQuery = {
  chatRoomByName?:  {
    __typename: "ModelChatRoomConnection",
    items:  Array< {
      __typename: "ChatRoom",
      id: string,
      chatRoomName: string,
      lastMessageText?: string | null,
      lastMessageTimestamp?: string | null,
      userID: string,
      agentID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChatRoomsByUserQueryVariables = {
  userID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatRoomsByUserQuery = {
  chatRoomsByUser?:  {
    __typename: "ModelChatRoomConnection",
    items:  Array< {
      __typename: "ChatRoom",
      id: string,
      chatRoomName: string,
      lastMessageText?: string | null,
      lastMessageTimestamp?: string | null,
      userID: string,
      agentID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChatRoomsByAgentIDQueryVariables = {
  agentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChatRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChatRoomsByAgentIDQuery = {
  chatRoomsByAgentID?:  {
    __typename: "ModelChatRoomConnection",
    items:  Array< {
      __typename: "ChatRoom",
      id: string,
      chatRoomName: string,
      lastMessageText?: string | null,
      lastMessageTimestamp?: string | null,
      userID: string,
      agentID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSFUserQueryVariables = {
  id: string,
};

export type GetSFUserQuery = {
  getSFUser?:  {
    __typename: "SFUser",
    id: string,
    userName: string,
    password: string,
    group: string,
    sFUserUserId: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListSFUsersQueryVariables = {
  filter?: ModelSFUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSFUsersQuery = {
  listSFUsers?:  {
    __typename: "ModelSFUserConnection",
    items:  Array< {
      __typename: "SFUser",
      id: string,
      userName: string,
      password: string,
      group: string,
      sFUserUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSFUsersQueryVariables = {
  filter?: ModelSFUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSFUsersQuery = {
  syncSFUsers?:  {
    __typename: "ModelSFUserConnection",
    items:  Array< {
      __typename: "SFUser",
      id: string,
      userName: string,
      password: string,
      group: string,
      sFUserUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SFUsersByUserNameQueryVariables = {
  userName: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SFUsersByUserNameQuery = {
  sFUsersByUserName?:  {
    __typename: "ModelSFUserConnection",
    items:  Array< {
      __typename: "SFUser",
      id: string,
      userName: string,
      password: string,
      group: string,
      sFUserUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SFUsersByGroupQueryVariables = {
  group: string,
  sFUserUserId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SFUsersByGroupQuery = {
  sFUsersByGroup?:  {
    __typename: "ModelSFUserConnection",
    items:  Array< {
      __typename: "SFUser",
      id: string,
      userName: string,
      password: string,
      group: string,
      sFUserUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SFUserByUserIDQueryVariables = {
  sFUserUserId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSFUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SFUserByUserIDQuery = {
  sFUserByUserID?:  {
    __typename: "ModelSFUserConnection",
    items:  Array< {
      __typename: "SFUser",
      id: string,
      userName: string,
      password: string,
      group: string,
      sFUserUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber?: string | null,
    imageUrl?: string | null,
    address?: string | null,
    birthday?: string | null,
    gender?: Gender | null,
    sFUser?:  {
      __typename: "SFUser",
      id: string,
      userName: string,
      password: string,
      group: string,
      sFUserUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    userDoctorId?: string | null,
    chatRooms?:  {
      __typename: "ModelChatRoomConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userSFUserId?: string | null,
    userPatientId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type UserByDoctorIDQueryVariables = {
  userDoctorId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByDoctorIDQuery = {
  userByDoctorID?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAgentQueryVariables = {
  id: string,
};

export type GetAgentQuery = {
  getAgent?:  {
    __typename: "Agent",
    id: string,
    name: string,
    prompt: string,
    model: string,
    language: string,
    avatar?: string | null,
    chatRooms?:  {
      __typename: "ModelChatRoomConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAgentsQueryVariables = {
  filter?: ModelAgentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgentsQuery = {
  listAgents?:  {
    __typename: "ModelAgentConnection",
    items:  Array< {
      __typename: "Agent",
      id: string,
      name: string,
      prompt: string,
      model: string,
      language: string,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAgentsQueryVariables = {
  filter?: ModelAgentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAgentsQuery = {
  syncAgents?:  {
    __typename: "ModelAgentConnection",
    items:  Array< {
      __typename: "Agent",
      id: string,
      name: string,
      prompt: string,
      model: string,
      language: string,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AgentByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAgentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AgentByNameQuery = {
  agentByName?:  {
    __typename: "ModelAgentConnection",
    items:  Array< {
      __typename: "Agent",
      id: string,
      name: string,
      prompt: string,
      model: string,
      language: string,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDoctorPatientQueryVariables = {
  id: string,
};

export type GetDoctorPatientQuery = {
  getDoctorPatient?:  {
    __typename: "DoctorPatient",
    id: string,
    patientId: string,
    doctorId: string,
    patient:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    doctor:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListDoctorPatientsQueryVariables = {
  filter?: ModelDoctorPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDoctorPatientsQuery = {
  listDoctorPatients?:  {
    __typename: "ModelDoctorPatientConnection",
    items:  Array< {
      __typename: "DoctorPatient",
      id: string,
      patientId: string,
      doctorId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDoctorPatientsQueryVariables = {
  filter?: ModelDoctorPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDoctorPatientsQuery = {
  syncDoctorPatients?:  {
    __typename: "ModelDoctorPatientConnection",
    items:  Array< {
      __typename: "DoctorPatient",
      id: string,
      patientId: string,
      doctorId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DoctorPatientsByPatientIDQueryVariables = {
  patientID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDoctorPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DoctorPatientsByPatientIDQuery = {
  doctorPatientsByPatientID?:  {
    __typename: "ModelDoctorPatientConnection",
    items:  Array< {
      __typename: "DoctorPatient",
      id: string,
      patientId: string,
      doctorId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DoctorPatientsByDoctorIDQueryVariables = {
  doctorID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDoctorPatientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DoctorPatientsByDoctorIDQuery = {
  doctorPatientsByDoctorID?:  {
    __typename: "ModelDoctorPatientConnection",
    items:  Array< {
      __typename: "DoctorPatient",
      id: string,
      patientId: string,
      doctorId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPatientDiseaseQueryVariables = {
  id: string,
};

export type GetPatientDiseaseQuery = {
  getPatientDisease?:  {
    __typename: "PatientDisease",
    id: string,
    patientId: string,
    diseaseId: string,
    patient:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    disease:  {
      __typename: "Disease",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListPatientDiseasesQueryVariables = {
  filter?: ModelPatientDiseaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientDiseasesQuery = {
  listPatientDiseases?:  {
    __typename: "ModelPatientDiseaseConnection",
    items:  Array< {
      __typename: "PatientDisease",
      id: string,
      patientId: string,
      diseaseId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPatientDiseasesQueryVariables = {
  filter?: ModelPatientDiseaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPatientDiseasesQuery = {
  syncPatientDiseases?:  {
    __typename: "ModelPatientDiseaseConnection",
    items:  Array< {
      __typename: "PatientDisease",
      id: string,
      patientId: string,
      diseaseId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PatientDiseasesByPatientIDQueryVariables = {
  patientID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientDiseaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PatientDiseasesByPatientIDQuery = {
  patientDiseasesByPatientID?:  {
    __typename: "ModelPatientDiseaseConnection",
    items:  Array< {
      __typename: "PatientDisease",
      id: string,
      patientId: string,
      diseaseId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PatientDiseasesByDiseaseIDQueryVariables = {
  diseaseID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientDiseaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PatientDiseasesByDiseaseIDQuery = {
  patientDiseasesByDiseaseID?:  {
    __typename: "ModelPatientDiseaseConnection",
    items:  Array< {
      __typename: "PatientDisease",
      id: string,
      patientId: string,
      diseaseId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateMedicineSubscription = {
  onCreateMedicine?:  {
    __typename: "Medicine",
    id: string,
    name: string,
    description?: string | null,
    prescriptions?:  {
      __typename: "ModelPrescriptionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMedicineSubscription = {
  onUpdateMedicine?:  {
    __typename: "Medicine",
    id: string,
    name: string,
    description?: string | null,
    prescriptions?:  {
      __typename: "ModelPrescriptionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMedicineSubscription = {
  onDeleteMedicine?:  {
    __typename: "Medicine",
    id: string,
    name: string,
    description?: string | null,
    prescriptions?:  {
      __typename: "ModelPrescriptionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePrescriptionSubscription = {
  onCreatePrescription?:  {
    __typename: "Prescription",
    id: string,
    instruction: string,
    quantity: number,
    medicalRecordID: string,
    medicalRecord?:  {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    medicineID: string,
    medicine?:  {
      __typename: "Medicine",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePrescriptionSubscription = {
  onUpdatePrescription?:  {
    __typename: "Prescription",
    id: string,
    instruction: string,
    quantity: number,
    medicalRecordID: string,
    medicalRecord?:  {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    medicineID: string,
    medicine?:  {
      __typename: "Medicine",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePrescriptionSubscription = {
  onDeletePrescription?:  {
    __typename: "Prescription",
    id: string,
    instruction: string,
    quantity: number,
    medicalRecordID: string,
    medicalRecord?:  {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    medicineID: string,
    medicine?:  {
      __typename: "Medicine",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMedicalRecordSubscription = {
  onCreateMedicalRecord?:  {
    __typename: "MedicalRecord",
    id: string,
    timestamp: string,
    status: MedicalRecordStatus,
    pulseRate?: string | null,
    bloodPressure?: string | null,
    bodyTemperature?: string | null,
    respiratoryRate?: string | null,
    symptoms?: string | null,
    diagnosis?: string | null,
    dischargeInstruction?: string | null,
    billingInfo?: string | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    prescriptions?:  {
      __typename: "ModelPrescriptionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    testResults?:  {
      __typename: "ModelTestResultConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMedicalRecordSubscription = {
  onUpdateMedicalRecord?:  {
    __typename: "MedicalRecord",
    id: string,
    timestamp: string,
    status: MedicalRecordStatus,
    pulseRate?: string | null,
    bloodPressure?: string | null,
    bodyTemperature?: string | null,
    respiratoryRate?: string | null,
    symptoms?: string | null,
    diagnosis?: string | null,
    dischargeInstruction?: string | null,
    billingInfo?: string | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    prescriptions?:  {
      __typename: "ModelPrescriptionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    testResults?:  {
      __typename: "ModelTestResultConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMedicalRecordSubscription = {
  onDeleteMedicalRecord?:  {
    __typename: "MedicalRecord",
    id: string,
    timestamp: string,
    status: MedicalRecordStatus,
    pulseRate?: string | null,
    bloodPressure?: string | null,
    bodyTemperature?: string | null,
    respiratoryRate?: string | null,
    symptoms?: string | null,
    diagnosis?: string | null,
    dischargeInstruction?: string | null,
    billingInfo?: string | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    prescriptions?:  {
      __typename: "ModelPrescriptionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    testResults?:  {
      __typename: "ModelTestResultConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTestResultSubscription = {
  onCreateTestResult?:  {
    __typename: "TestResult",
    id: string,
    timestamp: string,
    testType: string,
    description: string,
    result: string,
    images?: Array< string | null > | null,
    medicalRecordID: string,
    medicalRecord?:  {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTestResultSubscription = {
  onUpdateTestResult?:  {
    __typename: "TestResult",
    id: string,
    timestamp: string,
    testType: string,
    description: string,
    result: string,
    images?: Array< string | null > | null,
    medicalRecordID: string,
    medicalRecord?:  {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTestResultSubscription = {
  onDeleteTestResult?:  {
    __typename: "TestResult",
    id: string,
    timestamp: string,
    testType: string,
    description: string,
    result: string,
    images?: Array< string | null > | null,
    medicalRecordID: string,
    medicalRecord?:  {
      __typename: "MedicalRecord",
      id: string,
      timestamp: string,
      status: MedicalRecordStatus,
      pulseRate?: string | null,
      bloodPressure?: string | null,
      bodyTemperature?: string | null,
      respiratoryRate?: string | null,
      symptoms?: string | null,
      diagnosis?: string | null,
      dischargeInstruction?: string | null,
      billingInfo?: string | null,
      doctorID: string,
      patientID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePatientSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreatePatientSubscription = {
  onCreatePatient?:  {
    __typename: "Patient",
    id: string,
    checkInTime?: string | null,
    status: PatientStatus,
    ethnicity?: string | null,
    healthHistory?: string | null,
    allergies?: string | null,
    immunizations?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    patientUserId: string,
    doctors?:  {
      __typename: "ModelDoctorPatientConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    diseases?:  {
      __typename: "ModelPatientDiseaseConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    medicalRecords?:  {
      __typename: "ModelMedicalRecordConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdatePatientSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdatePatientSubscription = {
  onUpdatePatient?:  {
    __typename: "Patient",
    id: string,
    checkInTime?: string | null,
    status: PatientStatus,
    ethnicity?: string | null,
    healthHistory?: string | null,
    allergies?: string | null,
    immunizations?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    patientUserId: string,
    doctors?:  {
      __typename: "ModelDoctorPatientConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    diseases?:  {
      __typename: "ModelPatientDiseaseConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    medicalRecords?:  {
      __typename: "ModelMedicalRecordConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeletePatientSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeletePatientSubscription = {
  onDeletePatient?:  {
    __typename: "Patient",
    id: string,
    checkInTime?: string | null,
    status: PatientStatus,
    ethnicity?: string | null,
    healthHistory?: string | null,
    allergies?: string | null,
    immunizations?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    patientUserId: string,
    doctors?:  {
      __typename: "ModelDoctorPatientConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    diseases?:  {
      __typename: "ModelPatientDiseaseConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    medicalRecords?:  {
      __typename: "ModelMedicalRecordConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateDoctorSubscription = {
  onCreateDoctor?:  {
    __typename: "Doctor",
    id: string,
    specialist: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    doctorUserId: string,
    patients?:  {
      __typename: "ModelDoctorPatientConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    medicalRecords?:  {
      __typename: "ModelMedicalRecordConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID?: string | null,
    department?:  {
      __typename: "Department",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    workingTimeSpans?:  {
      __typename: "ModelWorkingTimeSpanConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    introduction?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDoctorSubscription = {
  onUpdateDoctor?:  {
    __typename: "Doctor",
    id: string,
    specialist: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    doctorUserId: string,
    patients?:  {
      __typename: "ModelDoctorPatientConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    medicalRecords?:  {
      __typename: "ModelMedicalRecordConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID?: string | null,
    department?:  {
      __typename: "Department",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    workingTimeSpans?:  {
      __typename: "ModelWorkingTimeSpanConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    introduction?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDoctorSubscription = {
  onDeleteDoctor?:  {
    __typename: "Doctor",
    id: string,
    specialist: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    doctorUserId: string,
    patients?:  {
      __typename: "ModelDoctorPatientConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    medicalRecords?:  {
      __typename: "ModelMedicalRecordConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID?: string | null,
    department?:  {
      __typename: "Department",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    workingTimeSpans?:  {
      __typename: "ModelWorkingTimeSpanConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    appointments?:  {
      __typename: "ModelAppointmentConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    reviews?:  {
      __typename: "ModelReviewConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    introduction?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateReviewSubscription = {
  onCreateReview?:  {
    __typename: "Review",
    id: string,
    rating: number,
    comment?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    patientID: string,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateReviewSubscription = {
  onUpdateReview?:  {
    __typename: "Review",
    id: string,
    rating: number,
    comment?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    patientID: string,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteReviewSubscription = {
  onDeleteReview?:  {
    __typename: "Review",
    id: string,
    rating: number,
    comment?: string | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    patientID: string,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDepartmentSubscription = {
  onCreateDepartment?:  {
    __typename: "Department",
    id: string,
    name: string,
    description?: string | null,
    doctors?:  {
      __typename: "ModelDoctorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDepartmentSubscription = {
  onUpdateDepartment?:  {
    __typename: "Department",
    id: string,
    name: string,
    description?: string | null,
    doctors?:  {
      __typename: "ModelDoctorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDepartmentSubscription = {
  onDeleteDepartment?:  {
    __typename: "Department",
    id: string,
    name: string,
    description?: string | null,
    doctors?:  {
      __typename: "ModelDoctorConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateWorkingTimeSpanSubscription = {
  onCreateWorkingTimeSpan?:  {
    __typename: "WorkingTimeSpan",
    id: string,
    dayOfWeek: number,
    date: string,
    hours: Array< number | null >,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateWorkingTimeSpanSubscription = {
  onUpdateWorkingTimeSpan?:  {
    __typename: "WorkingTimeSpan",
    id: string,
    dayOfWeek: number,
    date: string,
    hours: Array< number | null >,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteWorkingTimeSpanSubscription = {
  onDeleteWorkingTimeSpan?:  {
    __typename: "WorkingTimeSpan",
    id: string,
    dayOfWeek: number,
    date: string,
    hours: Array< number | null >,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAppointmentSubscription = {
  onCreateAppointment?:  {
    __typename: "Appointment",
    id: string,
    date: string,
    hour: number,
    order: number,
    description?: string | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAppointmentSubscription = {
  onUpdateAppointment?:  {
    __typename: "Appointment",
    id: string,
    date: string,
    hour: number,
    order: number,
    description?: string | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAppointmentSubscription = {
  onDeleteAppointment?:  {
    __typename: "Appointment",
    id: string,
    date: string,
    hour: number,
    order: number,
    description?: string | null,
    doctorID: string,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patientID: string,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDiseaseSubscription = {
  onCreateDisease?:  {
    __typename: "Disease",
    id: string,
    name: string,
    description?: string | null,
    patients?:  {
      __typename: "ModelPatientDiseaseConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDiseaseSubscription = {
  onUpdateDisease?:  {
    __typename: "Disease",
    id: string,
    name: string,
    description?: string | null,
    patients?:  {
      __typename: "ModelPatientDiseaseConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDiseaseSubscription = {
  onDeleteDisease?:  {
    __typename: "Disease",
    id: string,
    name: string,
    description?: string | null,
    patients?:  {
      __typename: "ModelPatientDiseaseConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMessageSubscriptionVariables = {
  ownerUserID?: string | null,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    timestamp: string,
    text?: string | null,
    translatedText?: string | null,
    image?: string | null,
    isDefaultMessage: boolean,
    completionTokens?: number | null,
    chatRoomID: string,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      chatRoomName: string,
      lastMessageText?: string | null,
      lastMessageTimestamp?: string | null,
      userID: string,
      agentID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    ownerUserID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMessageSubscriptionVariables = {
  ownerUserID?: string | null,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?:  {
    __typename: "Message",
    id: string,
    timestamp: string,
    text?: string | null,
    translatedText?: string | null,
    image?: string | null,
    isDefaultMessage: boolean,
    completionTokens?: number | null,
    chatRoomID: string,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      chatRoomName: string,
      lastMessageText?: string | null,
      lastMessageTimestamp?: string | null,
      userID: string,
      agentID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    ownerUserID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMessageSubscriptionVariables = {
  ownerUserID?: string | null,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?:  {
    __typename: "Message",
    id: string,
    timestamp: string,
    text?: string | null,
    translatedText?: string | null,
    image?: string | null,
    isDefaultMessage: boolean,
    completionTokens?: number | null,
    chatRoomID: string,
    chatRoom?:  {
      __typename: "ChatRoom",
      id: string,
      chatRoomName: string,
      lastMessageText?: string | null,
      lastMessageTimestamp?: string | null,
      userID: string,
      agentID?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    ownerUserID?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateChatRoomSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateChatRoomSubscription = {
  onCreateChatRoom?:  {
    __typename: "ChatRoom",
    id: string,
    chatRoomName: string,
    lastMessageText?: string | null,
    lastMessageTimestamp?: string | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    agentID?: string | null,
    agent?:  {
      __typename: "Agent",
      id: string,
      name: string,
      prompt: string,
      model: string,
      language: string,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateChatRoomSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateChatRoomSubscription = {
  onUpdateChatRoom?:  {
    __typename: "ChatRoom",
    id: string,
    chatRoomName: string,
    lastMessageText?: string | null,
    lastMessageTimestamp?: string | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    agentID?: string | null,
    agent?:  {
      __typename: "Agent",
      id: string,
      name: string,
      prompt: string,
      model: string,
      language: string,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteChatRoomSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteChatRoomSubscription = {
  onDeleteChatRoom?:  {
    __typename: "ChatRoom",
    id: string,
    chatRoomName: string,
    lastMessageText?: string | null,
    lastMessageTimestamp?: string | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    userID: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    agentID?: string | null,
    agent?:  {
      __typename: "Agent",
      id: string,
      name: string,
      prompt: string,
      model: string,
      language: string,
      avatar?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSFUserSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateSFUserSubscription = {
  onCreateSFUser?:  {
    __typename: "SFUser",
    id: string,
    userName: string,
    password: string,
    group: string,
    sFUserUserId: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateSFUserSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateSFUserSubscription = {
  onUpdateSFUser?:  {
    __typename: "SFUser",
    id: string,
    userName: string,
    password: string,
    group: string,
    sFUserUserId: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteSFUserSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteSFUserSubscription = {
  onDeleteSFUser?:  {
    __typename: "SFUser",
    id: string,
    userName: string,
    password: string,
    group: string,
    sFUserUserId: string,
    user?:  {
      __typename: "User",
      id: string,
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber?: string | null,
      imageUrl?: string | null,
      address?: string | null,
      birthday?: string | null,
      gender?: Gender | null,
      userDoctorId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      userSFUserId?: string | null,
      userPatientId?: string | null,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber?: string | null,
    imageUrl?: string | null,
    address?: string | null,
    birthday?: string | null,
    gender?: Gender | null,
    sFUser?:  {
      __typename: "SFUser",
      id: string,
      userName: string,
      password: string,
      group: string,
      sFUserUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    userDoctorId?: string | null,
    chatRooms?:  {
      __typename: "ModelChatRoomConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userSFUserId?: string | null,
    userPatientId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber?: string | null,
    imageUrl?: string | null,
    address?: string | null,
    birthday?: string | null,
    gender?: Gender | null,
    sFUser?:  {
      __typename: "SFUser",
      id: string,
      userName: string,
      password: string,
      group: string,
      sFUserUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    userDoctorId?: string | null,
    chatRooms?:  {
      __typename: "ModelChatRoomConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userSFUserId?: string | null,
    userPatientId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber?: string | null,
    imageUrl?: string | null,
    address?: string | null,
    birthday?: string | null,
    gender?: Gender | null,
    sFUser?:  {
      __typename: "SFUser",
      id: string,
      userName: string,
      password: string,
      group: string,
      sFUserUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    doctor?:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    patient?:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    userDoctorId?: string | null,
    chatRooms?:  {
      __typename: "ModelChatRoomConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    messages?:  {
      __typename: "ModelMessageConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    userSFUserId?: string | null,
    userPatientId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateAgentSubscription = {
  onCreateAgent?:  {
    __typename: "Agent",
    id: string,
    name: string,
    prompt: string,
    model: string,
    language: string,
    avatar?: string | null,
    chatRooms?:  {
      __typename: "ModelChatRoomConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAgentSubscription = {
  onUpdateAgent?:  {
    __typename: "Agent",
    id: string,
    name: string,
    prompt: string,
    model: string,
    language: string,
    avatar?: string | null,
    chatRooms?:  {
      __typename: "ModelChatRoomConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAgentSubscription = {
  onDeleteAgent?:  {
    __typename: "Agent",
    id: string,
    name: string,
    prompt: string,
    model: string,
    language: string,
    avatar?: string | null,
    chatRooms?:  {
      __typename: "ModelChatRoomConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDoctorPatientSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateDoctorPatientSubscription = {
  onCreateDoctorPatient?:  {
    __typename: "DoctorPatient",
    id: string,
    patientId: string,
    doctorId: string,
    patient:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    doctor:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateDoctorPatientSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateDoctorPatientSubscription = {
  onUpdateDoctorPatient?:  {
    __typename: "DoctorPatient",
    id: string,
    patientId: string,
    doctorId: string,
    patient:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    doctor:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteDoctorPatientSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteDoctorPatientSubscription = {
  onDeleteDoctorPatient?:  {
    __typename: "DoctorPatient",
    id: string,
    patientId: string,
    doctorId: string,
    patient:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    doctor:  {
      __typename: "Doctor",
      id: string,
      specialist: string,
      doctorUserId: string,
      departmentID?: string | null,
      introduction?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreatePatientDiseaseSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreatePatientDiseaseSubscription = {
  onCreatePatientDisease?:  {
    __typename: "PatientDisease",
    id: string,
    patientId: string,
    diseaseId: string,
    patient:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    disease:  {
      __typename: "Disease",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdatePatientDiseaseSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdatePatientDiseaseSubscription = {
  onUpdatePatientDisease?:  {
    __typename: "PatientDisease",
    id: string,
    patientId: string,
    diseaseId: string,
    patient:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    disease:  {
      __typename: "Disease",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeletePatientDiseaseSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeletePatientDiseaseSubscription = {
  onDeletePatientDisease?:  {
    __typename: "PatientDisease",
    id: string,
    patientId: string,
    diseaseId: string,
    patient:  {
      __typename: "Patient",
      id: string,
      checkInTime?: string | null,
      status: PatientStatus,
      ethnicity?: string | null,
      healthHistory?: string | null,
      allergies?: string | null,
      immunizations?: string | null,
      patientUserId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    disease:  {
      __typename: "Disease",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};
