export default {
  defaultProps: {
    _text: {
      fontSize: 'sm',
      fontWeight: 'normal'
    },
    py: '3',
    rounded: 'sm',
    px: '3',
    _dark: {
      borderColor: 'coolGray.700',
      color: 'coolGray.400',
      bg: 'coolGray.800',
      _hover: {
        bg: 'coolGray.800'
      },
      _focus: {
        bg: 'coolGray.800'
      }
    },
    _light: {
      borderColor: 'coolGray.300',
      color: 'coolGray.500',
      bg: 'white',
      _hover: {
        bg: 'white'
      },
      _focus: {
        bg: 'white'
      }
    }
  }
};
