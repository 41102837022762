import { createEntityAdapter, createSlice, PayloadAction, Update } from '@reduxjs/toolkit';
import { IPatient } from '../../../types/IPatient';

const patientsEntityAdapter = createEntityAdapter<IPatient>({
  selectId: (patient) => patient.id
});

const initialState = patientsEntityAdapter.getInitialState();

type IPatientsState = typeof initialState;

const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    addPatient: (state: IPatientsState, action: PayloadAction<IPatient>) => {
      patientsEntityAdapter.upsertOne(state, action.payload);
    },
    addPatients: (state: IPatientsState, action: PayloadAction<IPatient[]>) => {
      patientsEntityAdapter.upsertMany(state, action.payload);
    },
    removePatient: (state: IPatientsState, action: PayloadAction<string>) => {
      patientsEntityAdapter.removeOne(state, action.payload);
    },
    removePatients: (state: IPatientsState, action: PayloadAction<string[]>) => {
      patientsEntityAdapter.removeMany(state, action.payload);
    },
    updatePatient: (state: IPatientsState, action: PayloadAction<Update<IPatient>>) => {
      patientsEntityAdapter.updateOne(state, action.payload);
    },
    updatePatients: (state: IPatientsState, action: PayloadAction<Update<IPatient>[]>) => {
      patientsEntityAdapter.updateMany(state, action.payload);
    },
    resetPatients: () => {
      return initialState;
    }
  }
});

export const selectAllPatients = patientsEntityAdapter.getSelectors().selectAll;
export const selectPatientById = patientsEntityAdapter.getSelectors().selectById;

export const patientsActions = patientsSlice.actions;
export default patientsSlice;
