import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMedicalRecord } from '../../../types/IMedicalRecord';
import { RootState } from '../../index';

const entityAdapter = createEntityAdapter<IMedicalRecord>({
  selectId: (record) => record.id,
  sortComparer: (a, b) => {
    return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
  }
});

const initialState = entityAdapter.getInitialState({
  selectedMedicalRecordIdLeft: '',
  selectedMedicalRecordIdRight: ''
});

type IMedicalRecordState = typeof initialState;

const imagesComparationModalSlice = createSlice({
  name: 'medicalRecords',
  initialState,
  reducers: {
    setSelectedMedicalRecordLeft: (state: IMedicalRecordState, action: PayloadAction<string>) => {
      state.selectedMedicalRecordIdLeft = action.payload;
    },
    setSelectedMedicalRecordRight: (state: IMedicalRecordState, action: PayloadAction<string>) => {
      state.selectedMedicalRecordIdRight = action.payload;
    }
  }
});

export const selectSelectedMedicalRecordLeft = (state: RootState) => {
  return state.hospital.imagesComparationModal.selectedMedicalRecordIdLeft;
};
export const selectSelectedMedicalRecordRight = (state: RootState) => {
  return state.hospital.imagesComparationModal.selectedMedicalRecordIdRight;
};
export const imagesComparationModalActions = imagesComparationModalSlice.actions;
export default imagesComparationModalSlice;
