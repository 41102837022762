import React from 'react';
import { Box, Divider, Hidden, HStack, Spinner, VStack } from 'native-base';
import DashboardLayout from '../../layouts/DashboardLayout';
import MedicalRecordDetail from './MedicalRecordDetail';
import MedicalRecordList from './MedicalRecordList';
import { useAppSelector } from '../../store/hooks';
import { selectAllPatients } from '../../store/hospital/patients/patients-slice';
import AdminMobileFooter from './AdminMobileFooter';
import MedicalRecordListMobile from './MedicalRecordListMobile';

const MedicalRecordScreen = () => {
  const patients = useAppSelector((state) => selectAllPatients(state.hospital.patients));
  return (
    <DashboardLayout title='' displayMenuButton displayScreenTitle={false} displayAlternateMobileHeader rightPanelMobileHeader={true}>
      <Hidden till={'md'}>
        <>
          {patients && patients.length > 0 ? (
            <HStack alignSelf={'center'} width={'full'} height={'full'}>
              <Box w={'15%'} height={'100%'}>
                <MedicalRecordList />
              </Box>
              <Divider orientation='vertical' />
              <Box width={'85%'} height={'100%'}>
                <MedicalRecordDetail />
              </Box>
            </HStack>
          ) : (
            <Spinner />
          )}
        </>
      </Hidden>
      <Hidden from={'md'}>
        <Box w={'100%'} height={'100%'}>
          <MedicalRecordListMobile />
        </Box>
      </Hidden>
      <AdminMobileFooter />
    </DashboardLayout>
  );
};
export default MedicalRecordScreen;
