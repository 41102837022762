// import { keys } from 'ts-transformer-keys';
// import { cloneObject } from '../types/object_clone';
import { Agent } from '../API';

// IUser type is extended from User type, omitting __typename, createdAt, updatedAt, _version, _deleted, _lastChangedAt and owner fields
export type IAgent = Omit<Agent, 'updatedAt' | 'createdAt' | 'owner' | '_version' | '_deleted' | '_lastChangedAt' | '__typename' | 'chatRooms'>;

export const cloneAgent = (agent: Agent): IAgent => {
  return {
    id: agent.id,
    name: agent.name,
    prompt: agent.prompt,
    model: agent.model,
    language: agent.language,
    avatar: agent.avatar
  };
  // return cloneObject<Agent, IAgent>(agent, keys<Agent>(), keys<IAgent>());
};
