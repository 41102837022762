import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IContact } from '../../../types/IContact';
import { RootState } from '../../index';
import { selectContactGroup } from '../contact-groups/contact-groups-slice';

const contactsAdapter = createEntityAdapter<IContact>({
  selectId: (contact) => contact.id
});

export const loadContacts = createAsyncThunk('contacts/loadContacts', async () => {
  // TODO (MinhLuan): Load contacts from the server
  return [];
});

export const addContact = createAsyncThunk('contacts/addContact', async (contact: IContact, { dispatch, getState }) => {
  const state = getState() as RootState;

  // If the groupId is specified, check if the group exists
  if (contact.groupId) {
    const group = selectContactGroup(state, contact.groupId);
    if (group) {
      // Add the contact to the group
      await dispatch(contactsActions.addContact(contact));
      return contact;
    } else {
      console.log('Contact group', contact.groupId, 'does not exist. This should not happen.');
      return null;
    }
  } else {
    // Add the contact to the default group
    await dispatch(contactsActions.addContact(contact));
    return contact;
  }
});

export const addContacts = createAsyncThunk('contacts/addContacts', async (contacts: IContact[], { dispatch, getState }) => {
  const state = getState() as RootState;

  // Make sure all contacts are for the same group
  const allContactsForSameGroup = contacts.every((contact) => {
    return contact.groupId === contacts[0].groupId;
  });

  if (allContactsForSameGroup) {
    // If the groupId is specified, check if the group exists
    if (contacts[0].groupId) {
      const group = selectContactGroup(state, contacts[0].groupId);
      if (group) {
        // Add the contacts to the group
        await dispatch(contactsActions.addContacts(contacts));
        return contacts;
      } else {
        console.log('Contact group', contacts[0].groupId, 'does not exist. This should not happen.');
        return null;
      }
    } else {
      // Add the contacts to the default group
      await dispatch(contactsActions.addContacts(contacts));
      return contacts;
    }
  }
});

export const removeContact = createAsyncThunk('contacts/removeContact', async (contact: IContact, { dispatch, getState }) => {
  const state = getState() as RootState;

  // If the groupId is specified, check if the group exists
  if (contact.groupId) {
    const group = selectContactGroup(state, contact.groupId);
    if (group) {
      // Remove the contact from the group
      await dispatch(contactsActions.removeContact(contact.id));
      return contact;
    } else {
      console.log('Contact group', contact.groupId, 'does not exist. This should not happen.');
      return null;
    }
  } else {
    // Remove the contact from the default group
    await dispatch(contactsActions.removeContact(contact.id));
    return contact;
  }
});

export const removeAllContactsFromGroup = createAsyncThunk('contacts/removeAllContactsFromGroup', async (groupId: string, { dispatch, getState }) => {
  const state = getState() as RootState;

  // If the groupId is specified, check if the group exists
  if (groupId) {
    const group = selectContactGroup(state, groupId);
    if (group) {
      // Remove all contacts from the group
      await dispatch(contactsActions.removeAllContactsFromGroup(groupId));
      return groupId;
    } else {
      console.log('Contact group', groupId, 'does not exist. This should not happen.');
      return null;
    }
  } else {
    // Remove all contacts from the default group
    await dispatch(contactsActions.removeAllContactsFromGroup(groupId));
    return groupId;
  }
});

const contactsSlice = createSlice({
  name: 'contacts',
  initialState: contactsAdapter.getInitialState(),
  reducers: {
    addContact: (state, action: PayloadAction<IContact>) => {
      contactsAdapter.upsertOne(state, action.payload);
    },
    addContacts: (state, action: PayloadAction<IContact[]>) => {
      contactsAdapter.upsertMany(state, action.payload);
    },
    removeContact: (state, action: PayloadAction<string>) => {
      contactsAdapter.removeOne(state, action.payload);
    },
    removeAllContactsFromGroup: (state, action: PayloadAction<string>) => {
      const contactsToRemove = contactsAdapter
        .getSelectors()
        .selectAll(state)
        .filter((contact) => contact.groupId === action.payload);
      contactsAdapter.removeMany(
        state,
        contactsToRemove.map((contact) => contact.id)
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadContacts.fulfilled, (state, action) => {
      contactsAdapter.upsertMany(state, action.payload);
      console.log('Contacts loaded:', action.payload);
    });
    builder.addCase(addContact.fulfilled, (state, action) => {
      if (action.payload) {
        console.log('Contact added:', action.payload);
      }
    });
    builder.addCase(addContacts.fulfilled, (state, action) => {
      if (action.payload) {
        const contactIds = action.payload.map((contact) => contact.id);
        const contactGroupId = contactsAdapter.getSelectors().selectById(state, contactIds[0])?.groupId;
        console.log('Contacts added:', contactIds, 'to group', contactGroupId);
      }
    });
    builder.addCase(removeContact.fulfilled, (state, action) => {
      if (action.payload) {
        console.log('Contact removed:', action.payload);
      }
    });
  }
});

// Selectors
export const selectContact = (state: RootState, contactId: string) => {
  return contactsAdapter.getSelectors().selectById(state.contact.contacts, contactId);
};

export const selectAllContacts = (state: RootState) => {
  return contactsAdapter.getSelectors().selectAll(state.contact.contacts);
};

export const selectContactsByGroup = (state: RootState, groupId: string) => {
  return selectAllContacts(state).filter((contact) => contact.groupId === groupId);
};

const contactsActions = contactsSlice.actions;
export default contactsSlice;
