import { Box, Button, HStack, Icon, Input, Text, VStack } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import React from 'react';
import MenuProfile from '../components/MenuProfile';
import { RootStackScreensEnum } from '../navigation/enum';
import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { PatientStackParamList } from '../types/Stacks';

type HeaderProps = {
  title: string;
  subTitle?: string;
  searchbar: boolean;
};
export type Patient = {
  iconName: string;
  iconText: string;
};

type Icon = {
  iconName: string;
  iconText: string;
  route?: RootStackScreensEnum;
};

const list: Icon[] = [
  { iconName: 'home', iconText: 'Home', route: RootStackScreensEnum.DashboardPatient },
  { iconName: 'clipboard', iconText: 'Records', route: RootStackScreensEnum.PatientHistory },
  { iconName: 'user', iconText: 'Profile', route: RootStackScreensEnum.PatientProfile },
  { iconName: 'settings', iconText: 'Settings', route: RootStackScreensEnum.ChangePassword }
];

const HeaderPatient = (props: HeaderProps) => {
  const route = useRoute();
  // @ts-ignore
  const navigation = useNavigation<NativeStackNavigationProp<PatientStackParamList, RootStackScreensEnum.Patient>>();
  const onPressCategory = (category: Patient) => {
    switch (category.iconText) {
      case 'Records':
        // @ts-ignore
        navigation.navigate(RootStackScreensEnum.Patient, { screen: RootStackScreensEnum.PatientHistory });
        break;
      case 'Profile':
        // @ts-ignore
        navigation.navigate(RootStackScreensEnum.Patient, { screen: RootStackScreensEnum.PatientProfile });
        break;
      case 'Settings':
        // @ts-ignore
        navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.ChangePassword });
        break;
      default:
        // @ts-ignore
        navigation.navigate(RootStackScreensEnum.Patient, { screen: RootStackScreensEnum.DashboardPatient });
        break;
    }
  };
  return (
    <Box
      px='6'
      py='4px'
      borderBottomWidth='1'
      _dark={{ bg: 'coolGray.900', borderColor: 'coolGray.800' }}
      _light={{
        bg: { base: 'primary.900', md: '#0564C8' },
        borderColor: 'coolGray.200'
      }}>
      <VStack alignSelf='center' width='100%'>
        <HStack alignItems='center' justifyContent='flex-end'>
          {props.searchbar && (
            <Input
              px='4'
              w='30%'
              size='sm'
              placeholder='Search'
              InputLeftElement={
                <Icon
                  px='2'
                  size='4'
                  name={'search'}
                  as={FontAwesome}
                  _light={{
                    color: 'coolGray.400'
                  }}
                  _dark={{
                    color: 'coolGray.100'
                  }}
                />
              }
            />
          )}
          <Box flex={1}>
            <Text color={'white'} fontSize={36} fontFamily={'Sketch_Block'} fontWeight={'bole'}>
              Teledoc
            </Text>
          </Box>
          <HStack space='5' alignItems='center'>
            {list.map((item, idx) => {
              return (
                <Button
                  onPress={() => onPressCategory(item)}
                  key={idx}
                  variant='ghost'
                  fontWeight={'bold'}
                  justifyContent='flex-start'
                  py='0'
                  px='5'
                  backgroundColor={item.route === route.name ? 'primary.100' : 'transparent'}
                  _light={{
                    _text: { color: item.route === route.name ? '#0564C8' : 'white' },
                    _icon: { color: item.route === route.name ? '#0564C8' : 'white' }
                  }}
                  _dark={{
                    _text: { color: 'white' },
                    _icon: { color: 'coolGray.50' }
                  }}
                  _text={{
                    fontSize: 'lg',
                    fontWeight: 'medium'
                  }}
                  _hover={{
                    _text: {
                      _light: {
                        color: '#0564C8'
                      },
                      _dark: {
                        color: 'primary.500'
                      }
                    },

                    _icon: {
                      _light: {
                        color: '#0564C8'
                      },
                      _dark: {
                        color: 'primary.500'
                      }
                    },
                    _light: {
                      bg: 'primary.100'
                    },
                    _dark: {
                      bg: 'coolGray.800'
                    }
                  }}>
                  {item.iconText}
                </Button>
              );
            })}

            <MenuProfile />
          </HStack>
        </HStack>
      </VStack>
    </Box>
  );
};

export default HeaderPatient;
