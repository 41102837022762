import { NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../navigation/types';
import { Button, Divider, Image, Menu, Tooltip } from 'native-base';
import React from 'react';
import { RootStackScreensEnum } from '../navigation/enum';

const MenuProfile = () => {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const [isOpen, setIsOpen] = React.useState(false);
  const onLogout = () => {
    navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.Logout });
  };
  const onAccount = () => {
    navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.ChangePassword });
  };

  return (
    <Menu
      isOpen={isOpen}
      closeOnSelect={true}
      width={{ base: '100%', md: '100%' }}
      placement='bottom right'
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={(triggerProps) => {
        return (
          <Tooltip label='Profile'>
            <Button variant='unstyled' p='0' {...triggerProps}>
              <Image
                rounded={'full'}
                width={{ base: 8, md: 8 }}
                height={{ base: 8, md: 8 }}
                source={{
                  uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.40549212462773987.jpg'
                }}
                alt='Alternate Text'
                size='xs'
              />
            </Button>
          </Tooltip>
        );
      }}
      _dark={{ bg: 'coolGray.800', borderColor: 'coolGray.700' }}>
      <Menu.Item onPress={onAccount}>Settings</Menu.Item>
      <Divider mt='3' w='100%' _dark={{ bg: 'coolGray.700' }} />
      <Menu.Item onPress={onLogout}>Logout</Menu.Item>
    </Menu>
  );
};

export default MenuProfile;
