import { useReducer, useRef, useState } from 'react';
import { strophReducer } from './stropheReducer';
import { ConnectionFunctionType, executeFunction } from './utils/helpers';
import {
  setConnectedAction,
  setConnectingAction,
  setConnectionAction,
  setConnFailAction,
  setDisconnectedAction,
  setDisconnectingAction,
  setDomainNameAction
} from './stropheActionGenerator';
import { StropheReducerState } from './strophe.types';

type HandlerType = {
  handlerFunc: (_payload: Element) => boolean;
  matcher: {
    namespace?: string;
    name: 'message' | 'iq' | 'presence';
    type?: string;
    id?: string;
    from?: string;
  };
};

type PropType = {
  showLogs?: boolean;
  onConnect?: ConnectionFunctionType;
  onConnecting?: ConnectionFunctionType;
  onDisconnect?: ConnectionFunctionType;
  onDisconnecting?: ConnectionFunctionType;
  onConnFail?: ConnectionFunctionType;
  onMessageQuery?: (_msg: Element) => boolean;
  onPresence?: (_presence: Element) => boolean;
  connection: Strophe.Connection;
  handlers?: HandlerType[];
};

const initialState: StropheReducerState = {
  connect(): void {
    // do nothing
  },
  disconnect(reason: string): void {
    // do nothing
  },
  setCredentials(email: string, password: string, xmppUrl): void {
    // do nothing
  },
  connecting: false,
  connected: false,
  disconnecting: false,
  disconnected: false,
  connFail: false,
  reason: null
};

const useStrophe = ({
  showLogs = false,
  onConnecting,
  onConnect,
  onDisconnecting,
  onDisconnect,
  onConnFail,
  connection,
  onMessageQuery,
  onPresence,
  handlers = []
}: PropType) => {
  const [{ connected, connecting, disconnected, disconnecting, reason, connFail, domainName, stropheConnection }, dispatch] = useReducer(
    strophReducer,
    initialState
  );
  const [jid, setJid] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [xmppUrl, setXmppUrl] = useState<string | null>(null);

  const stropheHandlers = useRef<any[] | null>(null);

  const setCredentials = (jid: string, password: string, xmppUrl: string) => {
    setJid(jid);
    setPassword(password);
    setXmppUrl(xmppUrl);
  };

  const showLog = (eventText: string, element: Element) => {
    if (showLogs) {
      console.log(eventText, element);
    }
    return true;
  };

  const connectHandlers = () => {
    connection.addHandler(
      typeof onMessageQuery === 'function'
        ? onMessageQuery
        : (msg: Element) => {
            return showLog('onMessage', msg);
          },
      'jabber:client',
      'message'
    );

    connection.addHandler(
      typeof onPresence === 'function'
        ? onPresence
        : (presence) => {
            return showLog('onPresence', presence);
          },
      '',
      'presence'
    );

    stropheHandlers.current = handlers.map(({ handlerFunc, matcher: { namespace = '', name, type, id, from } }) => {
      connection.addHandler(handlerFunc, namespace, name, type, id, from);
    });
  };

  const disconnectHandlers = () => {
    if (stropheHandlers.current && Array.isArray(stropheHandlers.current)) {
      stropheHandlers.current.forEach((handler) => {
        connection.deleteHandler(handler);
      });
    }
    stropheHandlers.current = null;
  };

  const handleConnectionStatus = (status: number, reason: string) => {
    switch (status) {
      case Strophe.Status.CONNECTING: {
        dispatch(setConnectingAction(reason));
        executeFunction({ func: onConnecting, reason });
        break;
      }
      case Strophe.Status.CONNECTED: {
        dispatch(setConnectedAction(reason));
        const connectedDomainName = Strophe.getDomainFromJid(connection.jid);
        connection.send($pres().c('priority').t('1'));
        dispatch(setDomainNameAction({ domainName: connectedDomainName }));
        dispatch(setConnectionAction({ stropheConnection: connection }));
        executeFunction({ func: onConnect, reason });
        connectHandlers();
        break;
      }
      case Strophe.Status.DISCONNECTING: {
        dispatch(setDisconnectingAction(reason));
        executeFunction({ func: onDisconnecting, reason });
        break;
      }
      case Strophe.Status.DISCONNECTED: {
        dispatch(setDisconnectedAction(reason));
        executeFunction({ func: onDisconnect, reason });
        disconnectHandlers();
        break;
      }
      case Strophe.Status.CONNFAIL: {
        dispatch(setConnFailAction(reason));
        executeFunction({ func: onConnFail, reason });
        break;
      }
      default:
        break;
    }
  };

  const connect = () => {
    if (!jid || !password || !xmppUrl) {
      throw new Error('JID and password are required to connect');
    }

    connection.connect(jid, password, (status, stropheReason: string) => {
      handleConnectionStatus(status, stropheReason);
      if (!showLogs) {
        return;
      }
      const valueStatusArray = Object.values(Strophe.Status);
      if (valueStatusArray.includes(status)) {
        console.info(`Strophe status: ${status} reason ${stropheReason}`);
      }
    });
  };

  const disconnect = (disconnectingReason: string) => {
    connection.disconnect(disconnectingReason);
  };

  return {
    setCredentials,
    connect,
    disconnect,
    connected,
    connecting,
    disconnected,
    disconnecting,
    reason,
    connFail,
    domainName,
    stropheConnection,
    bareJid: Strophe.getBareJidFromJid(connection.jid),
    resource: Strophe.getResourceFromJid(connection.jid),
    jid
  };
};

export default useStrophe;
