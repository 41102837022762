import { createEntityAdapter, createSlice, PayloadAction, Update } from '@reduxjs/toolkit';
import { IAgent } from '../../utils/IAgent';

const entityAdapter = createEntityAdapter<IAgent>({
  selectId: (agent: IAgent) => agent.id
});

const initialState = entityAdapter.getInitialState();

export const agentsSlice = createSlice({
  name: 'agents',
  initialState: initialState,
  reducers: {
    setAgents: (state, action: PayloadAction<IAgent[]>) => {
      entityAdapter.setAll(state, action.payload);
    },
    addAgent: (state, action: PayloadAction<IAgent>) => {
      entityAdapter.upsertOne(state, action.payload);
    },
    addAgents: (state, action: PayloadAction<IAgent[]>) => {
      entityAdapter.upsertMany(state, action.payload);
    },
    updateAgent: (state, action: PayloadAction<Update<IAgent>>) => {
      entityAdapter.updateOne(state, action.payload);
    },
    addOrUpdateAgents: (state, action: PayloadAction<IAgent[]>) => {
      entityAdapter.upsertMany(state, action.payload);
    },
    removeAgent: (state, action: PayloadAction<string>) => {
      entityAdapter.removeOne(state, action.payload);
    },
    resetAgents: (state) => {
      return initialState;
    }
  }
});

export const { selectAll: selectAllAgents, selectById: selectAgentById } = entityAdapter.getSelectors((state: any) => state.agents);

export const agentsActions = agentsSlice.actions;
export default agentsSlice;
