import { createEntityAdapter, createSlice, PayloadAction, Update } from '@reduxjs/toolkit';
import { IDoctor } from '../../../types/IDoctor';
import { IDoctorWithUser } from '../../../types/IDoctorWithUser';

const doctorsEntityAdapter = createEntityAdapter<IDoctorWithUser>({
  selectId: (doctor) => doctor.id
});

const initialState = doctorsEntityAdapter.getInitialState();

type IDoctorsState = typeof initialState;

const doctorsSlice = createSlice({
  name: 'doctors',
  initialState,
  reducers: {
    addDoctor: (state: IDoctorsState, action: PayloadAction<IDoctorWithUser>) => {
      doctorsEntityAdapter.upsertOne(state, action.payload);
    },
    addDoctors: (state: IDoctorsState, action: PayloadAction<IDoctorWithUser[]>) => {
      doctorsEntityAdapter.upsertMany(state, action.payload);
    },
    removeDoctor: (state: IDoctorsState, action: PayloadAction<string>) => {
      doctorsEntityAdapter.removeOne(state, action.payload);
    },
    removeDoctors: (state: IDoctorsState, action: PayloadAction<string[]>) => {
      doctorsEntityAdapter.removeMany(state, action.payload);
    },
    updateDoctor: (state: IDoctorsState, action: PayloadAction<Update<IDoctorWithUser>>) => {
      doctorsEntityAdapter.updateOne(state, action.payload);
    },
    updateDoctors: (state: IDoctorsState, action: PayloadAction<Update<IDoctorWithUser>[]>) => {
      doctorsEntityAdapter.updateMany(state, action.payload);
    },
    resetDoctors: () => {
      return initialState;
    }
  }
});

export const selectAllDoctors = doctorsEntityAdapter.getSelectors().selectAll;
export const selectDoctorById = doctorsEntityAdapter.getSelectors().selectById;

export const doctorsActions = doctorsSlice.actions;
export default doctorsSlice;
