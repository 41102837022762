import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IContactGroup } from '../../../types/IContactGroup';
import { IContactGroupTree } from '../../../types/IContactGroupTree';
import { RootState } from '../../index';
import { addContacts, removeAllContactsFromGroup } from '../contacts/contacts-slice';
import { IAuthUser } from '../../../components/auth/IAuthUser';
import { sfWebServiceGetClosedUserGroupTrees, sfWebServiceGetContactList } from '../../../utils/web_service/sf_web_service_utils';
import Constants from 'expo-constants';

// Async thunk to fetch contact groups from the backend
export const fetchContactGroups = createAsyncThunk('contactGroups/fetch', async (user: IAuthUser, { dispatch, getState }) => {
  const addCUGContactGroup = async (contactGroup: any) => {
    await dispatch(
      addContactGroup({
        id: contactGroup.group_id,
        groupName: contactGroup.group_name,
        parentGroupId: contactGroup.parent_id === 0 ? null : contactGroup.parent_id
      })
    );

    // User list
    const userList = contactGroup.group_users.users;
    if (userList) {
      const contacts = userList.map((contact: any) => {
        return {
          id: contact.user_id,
          groupId: contactGroup.group_id,
          accountName: contact.accountName ? contact.accountName : '',
          email: contact.user_email,
          phone: contact.user_phone,
          avatar: contact.avatar,
          priority: contact.priority
        };
      });

      await dispatch(addContacts(contacts));
    }

    // Group list
    const groupList = contactGroup.sub_group;
    if (groupList) {
      for (const group of groupList) {
        await addCUGContactGroup(group);
      }
    }
  };

  // Get closed user group trees from the SF web service
  const closedUserGroupTrees = await sfWebServiceGetClosedUserGroupTrees(user.sfWebServiceUserId, user.sfWebServiceToken);
  if (closedUserGroupTrees && closedUserGroupTrees.code === 1 && closedUserGroupTrees.msg === 'Done') {
    // my_group is a string of comma-separated contact group IDs to which the user belongs
    const my_group = closedUserGroupTrees.my_group;
    const my_group_array = my_group.split(',').map((id: string) => id.trim());
    console.log('The current user belongs to the following contact groups:', my_group_array);

    // group is an array of root contact groups
    const rootContactGroups = closedUserGroupTrees.group;

    // Add the root contact groups to the store
    for (const rootContactGroup of rootContactGroups) {
      await addCUGContactGroup(rootContactGroup);
    }
  }

  // Get the normal contact lists (Friends, Family, Colleagues, Blacklist, ...) from SF web service
  const contactGroups = await sfWebServiceGetContactList(user.sfWebServiceUserId, user.sfWebServiceToken);
  if (contactGroups && contactGroups.code === 1) {
    // group_num is the number of contact groups (except the 'Blacklist' group, which has ID = -1)
    const group_num = contactGroups.group_num;

    // group_contacts is a list of contact groups
    const group_contacts = contactGroups.group_contacts;

    // Loop through the contact groups and add them to the store
    if (group_contacts) {
      // Check if group_num is equal to the number of contact groups in group_contacts, except the 'Blacklist' group
      const groupContactsNum = group_contacts.filter((group: any) => group.group_id !== -1).length;
      if (group_num !== groupContactsNum) {
        console.error('The number of contact groups does not match!');
        return;
      }

      for (const contactGroup of group_contacts) {
        await dispatch(
          addContactGroup({
            id: contactGroup.group_id,
            groupName: contactGroup.group,
            parentGroupId: null
          })
        );

        // Add the contacts to the store
        const contacts = contactGroup.contacts;
        if (contacts) {
          const contactsToAdd = contacts.map((user: any) => {
            return {
              id: user.user_id,
              groupId: contactGroup.group_id,
              accountName: user.accountName ? user.accountName : '',
              email: user.user_email,
              phone: user.user_phone,
              avatar: user.avatar,
              priority: user.priority
            };
          });

          await dispatch(addContacts(contactsToAdd));
        }
      }

      // There is also a 'contacts' field in the response, which contains all contacts which by default belong to the 'Friends' group
      // Add them to the 'Friends' group also
      // First, find the 'Friends' group ID
      const friendsGroupId = group_contacts.find((group: any) => group.group === 'Friends')?.group_id;
      if (friendsGroupId) {
        // Add the contacts to the store
        const contacts = contactGroups.contacts;
        if (contacts) {
          const contactsToAdd = contacts.map((user: any) => {
            return {
              id: user.user_id,
              groupId: friendsGroupId,
              accountName: user.accountName ? user.accountName : '',
              email: user.user_email,
              phone: user.user_phone,
              avatar: user.avatar,
              priority: user.priority
            };
          });

          await dispatch(addContacts(contactsToAdd));
        }
      } else {
        console.error('The "Friends" group is not found!');
      }
    }
  }
});

// Entity adapter for contact groups
const contactGroupsAdapter = createEntityAdapter<IContactGroup>({
  // Specify the property on the entities that will be used as the ID
  selectId: (group) => group.id
});

// Initial state for the contact groups slice
const contactGroupsInitialState = contactGroupsAdapter.getInitialState();

// Add a contact group to the store
export const addContactGroup = createAsyncThunk('contactGroups/add', async (contactGroup: IContactGroup, { dispatch, getState }) => {
  const state = getState() as RootState;

  // Check if the parent group exists
  if (contactGroup.parentGroupId) {
    const parentGroup = selectContactGroup(state, contactGroup.parentGroupId);
    if (parentGroup) {
      // Add the contact group to the store
      await dispatch(contactGroupsActions.addContactGroup(contactGroup));
      return contactGroup;
    } else {
      console.log('Parent contact group', contactGroup.parentGroupId, 'does not exist. This should not happen.');
      return null;
    }
  } else {
    // This is the root contact group. Add it to the store
    await dispatch(contactGroupsActions.addContactGroup(contactGroup));
  }
});

// Define a function to recursively generate the tree based on a root contact group
const generateTree = (rootContactGroup: IContactGroup): IContactGroupTree => {
  // Find all child contact groups of the root contact group
  const childContactGroups = contactGroupsAdapter
    .getSelectors()
    .selectAll(contactGroupsInitialState)
    .filter((group) => group.parentGroupId === rootContactGroup.id);

  // Recursively generate the tree for each child contact group
  const childTrees = childContactGroups.map((group) => generateTree(group));

  // Return the root contact group with the child trees
  return {
    root: rootContactGroup,
    childContactGroups: childTrees
  };
};

// Generate a tree of contact groups
export const generateContactGroupTrees = () => {
  // First, find the root contact groups
  const rootContactGroups = contactGroupsAdapter
    .getSelectors()
    .selectAll(contactGroupsInitialState)
    .filter((group) => group.parentGroupId === null);

  // Generate the tree for each root contact group
  return rootContactGroups.map((group) => generateTree(group));
};

// Remove contact group
export const removeContactGroup = createAsyncThunk('contactGroups/remove', async (groupId: string, { dispatch, getState }) => {
  const state = getState() as RootState;
  const group = selectContactGroup(state, groupId);
  if (group) {
    // Remove the group and all its children
    const contactGroupsTree = generateTree(group);

    // Loop through the contact groups tree and remove each group
    const removeContactGroupTree = async (contactGroupsTree: IContactGroupTree) => {
      // First, remove all contacts in the group
      await dispatch(removeAllContactsFromGroup(contactGroupsTree.root.id));

      // Then, remove the group
      await dispatch(contactGroupsActions.removeContactGroup(contactGroupsTree.root.id));

      // Finally, remove all child groups
      contactGroupsTree.childContactGroups.forEach((child) => removeContactGroupTree(child));
    };

    await removeContactGroupTree(contactGroupsTree);
  }
});

// Create the contact groups slice
const contactGroupsSlice = createSlice({
  name: 'contacts/contactGroups',
  initialState: contactGroupsInitialState,
  reducers: {
    // Add a contact group
    addContactGroup(state, action: PayloadAction<IContactGroup>) {
      contactGroupsAdapter.upsertOne(state, action.payload);
    },
    // Remove a contact group
    removeContactGroup(state, action: PayloadAction<string>) {
      contactGroupsAdapter.removeOne(state, action.payload);
    }
  },
  extraReducers: (builder) => {
    // Add reducer for fetching contact groups
    builder.addCase(fetchContactGroups.fulfilled, (state, action) => {
      console.log('Contact groups fetched successfully');
    });
  }
});

// Selectors for contact groups
export const selectContactGroup = (state: RootState, contactGroupID: string) => {
  return contactGroupsAdapter.getSelectors().selectById(state.contact.groups, contactGroupID);
};

// Export the reducer
const contactGroupsActions = contactGroupsSlice.actions;
export default contactGroupsSlice;
