/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMedicine = /* GraphQL */ `
  query GetMedicine($id: ID!) {
    getMedicine(id: $id) {
      id
      name
      description
      prescriptions {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMedicines = /* GraphQL */ `
  query ListMedicines(
    $filter: ModelMedicineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedicines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMedicines = /* GraphQL */ `
  query SyncMedicines(
    $filter: ModelMedicineFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMedicines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPrescription = /* GraphQL */ `
  query GetPrescription($id: ID!) {
    getPrescription(id: $id) {
      id
      instruction
      quantity
      medicalRecordID
      medicalRecord {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      medicineID
      medicine {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPrescriptions = /* GraphQL */ `
  query ListPrescriptions(
    $filter: ModelPrescriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrescriptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        instruction
        quantity
        medicalRecordID
        medicineID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPrescriptions = /* GraphQL */ `
  query SyncPrescriptions(
    $filter: ModelPrescriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPrescriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        instruction
        quantity
        medicalRecordID
        medicineID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const prescriptionsByMedicalRecordID = /* GraphQL */ `
  query PrescriptionsByMedicalRecordID(
    $medicalRecordID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPrescriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    prescriptionsByMedicalRecordID(
      medicalRecordID: $medicalRecordID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instruction
        quantity
        medicalRecordID
        medicineID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const prescriptionsByMedicineID = /* GraphQL */ `
  query PrescriptionsByMedicineID(
    $medicineID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPrescriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    prescriptionsByMedicineID(
      medicineID: $medicineID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instruction
        quantity
        medicalRecordID
        medicineID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMedicalRecord = /* GraphQL */ `
  query GetMedicalRecord($id: ID!) {
    getMedicalRecord(id: $id) {
      id
      timestamp
      status
      pulseRate
      bloodPressure
      bodyTemperature
      respiratoryRate
      symptoms
      diagnosis
      dischargeInstruction
      billingInfo
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patientID
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      prescriptions {
        nextToken
        startedAt
      }
      testResults {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMedicalRecords = /* GraphQL */ `
  query ListMedicalRecords(
    $filter: ModelMedicalRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedicalRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMedicalRecords = /* GraphQL */ `
  query SyncMedicalRecords(
    $filter: ModelMedicalRecordFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMedicalRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const medicalRecordsByDoctorID = /* GraphQL */ `
  query MedicalRecordsByDoctorID(
    $doctorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMedicalRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    medicalRecordsByDoctorID(
      doctorID: $doctorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const medicalRecordsByPatientID = /* GraphQL */ `
  query MedicalRecordsByPatientID(
    $patientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMedicalRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    medicalRecordsByPatientID(
      patientID: $patientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTestResult = /* GraphQL */ `
  query GetTestResult($id: ID!) {
    getTestResult(id: $id) {
      id
      timestamp
      testType
      description
      result
      images
      medicalRecordID
      medicalRecord {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTestResults = /* GraphQL */ `
  query ListTestResults(
    $filter: ModelTestResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        testType
        description
        result
        images
        medicalRecordID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTestResults = /* GraphQL */ `
  query SyncTestResults(
    $filter: ModelTestResultFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTestResults(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        timestamp
        testType
        description
        result
        images
        medicalRecordID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const testResultsByMedicalRecordID = /* GraphQL */ `
  query TestResultsByMedicalRecordID(
    $medicalRecordID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTestResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testResultsByMedicalRecordID(
      medicalRecordID: $medicalRecordID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        testType
        description
        result
        images
        medicalRecordID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      checkInTime
      status
      ethnicity
      healthHistory
      allergies
      immunizations
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      patientUserId
      doctors {
        nextToken
        startedAt
      }
      diseases {
        nextToken
        startedAt
      }
      medicalRecords {
        nextToken
        startedAt
      }
      appointments {
        nextToken
        startedAt
      }
      reviews {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPatients = /* GraphQL */ `
  query SyncPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPatients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const patientByUserId = /* GraphQL */ `
  query PatientByUserId(
    $patientUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientByUserId(
      patientUserId: $patientUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getDoctor = /* GraphQL */ `
  query GetDoctor($id: ID!) {
    getDoctor(id: $id) {
      id
      specialist
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      doctorUserId
      patients {
        nextToken
        startedAt
      }
      medicalRecords {
        nextToken
        startedAt
      }
      departmentID
      department {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      workingTimeSpans {
        nextToken
        startedAt
      }
      appointments {
        nextToken
        startedAt
      }
      reviews {
        nextToken
        startedAt
      }
      introduction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDoctors = /* GraphQL */ `
  query ListDoctors(
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDoctors = /* GraphQL */ `
  query SyncDoctors(
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDoctors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const doctorByUserId = /* GraphQL */ `
  query DoctorByUserId(
    $doctorUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorByUserId(
      doctorUserId: $doctorUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const doctorsByDepartmentID = /* GraphQL */ `
  query DoctorsByDepartmentID(
    $departmentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorsByDepartmentID(
      departmentID: $departmentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      rating
      comment
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      patientID
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rating
        comment
        patientID
        doctorID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReviews = /* GraphQL */ `
  query SyncReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        rating
        comment
        patientID
        doctorID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const reviewsByPatientID = /* GraphQL */ `
  query ReviewsByPatientID(
    $patientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByPatientID(
      patientID: $patientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rating
        comment
        patientID
        doctorID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const reviewsByDoctorID = /* GraphQL */ `
  query ReviewsByDoctorID(
    $doctorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByDoctorID(
      doctorID: $doctorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rating
        comment
        patientID
        doctorID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDepartment = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      name
      description
      doctors {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDepartments = /* GraphQL */ `
  query ListDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDepartments = /* GraphQL */ `
  query SyncDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDepartments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorkingTimeSpan = /* GraphQL */ `
  query GetWorkingTimeSpan($id: ID!) {
    getWorkingTimeSpan(id: $id) {
      id
      dayOfWeek
      date
      hours
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listWorkingTimeSpans = /* GraphQL */ `
  query ListWorkingTimeSpans(
    $filter: ModelWorkingTimeSpanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkingTimeSpans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dayOfWeek
        date
        hours
        doctorID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkingTimeSpans = /* GraphQL */ `
  query SyncWorkingTimeSpans(
    $filter: ModelWorkingTimeSpanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkingTimeSpans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        dayOfWeek
        date
        hours
        doctorID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const workingTimeSpansByDoctorAndDate = /* GraphQL */ `
  query WorkingTimeSpansByDoctorAndDate(
    $doctorID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkingTimeSpanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workingTimeSpansByDoctorAndDate(
      doctorID: $doctorID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dayOfWeek
        date
        hours
        doctorID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      date
      hour
      order
      description
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patientID
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        hour
        order
        description
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAppointments = /* GraphQL */ `
  query SyncAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAppointments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        hour
        order
        description
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const appointmentsByDoctorAndDate = /* GraphQL */ `
  query AppointmentsByDoctorAndDate(
    $doctorID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentsByDoctorAndDate(
      doctorID: $doctorID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        hour
        order
        description
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const appointmentsByPatientID = /* GraphQL */ `
  query AppointmentsByPatientID(
    $patientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appointmentsByPatientID(
      patientID: $patientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        hour
        order
        description
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDisease = /* GraphQL */ `
  query GetDisease($id: ID!) {
    getDisease(id: $id) {
      id
      name
      description
      patients {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDiseases = /* GraphQL */ `
  query ListDiseases(
    $filter: ModelDiseaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiseases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDiseases = /* GraphQL */ `
  query SyncDiseases(
    $filter: ModelDiseaseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDiseases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      timestamp
      text
      translatedText
      image
      isDefaultMessage
      completionTokens
      chatRoomID
      chatRoom {
        id
        chatRoomName
        lastMessageText
        lastMessageTimestamp
        userID
        agentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      ownerUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        text
        translatedText
        image
        isDefaultMessage
        completionTokens
        chatRoomID
        userID
        ownerUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        timestamp
        text
        translatedText
        image
        isDefaultMessage
        completionTokens
        chatRoomID
        userID
        ownerUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const chatRoomMessages = /* GraphQL */ `
  query ChatRoomMessages(
    $chatRoomID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomMessages(
      chatRoomID: $chatRoomID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        text
        translatedText
        image
        isDefaultMessage
        completionTokens
        chatRoomID
        userID
        ownerUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const messagesByUserID = /* GraphQL */ `
  query MessagesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        text
        translatedText
        image
        isDefaultMessage
        completionTokens
        chatRoomID
        userID
        ownerUserID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      chatRoomName
      lastMessageText
      lastMessageTimestamp
      messages {
        nextToken
        startedAt
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listChatRooms = /* GraphQL */ `
  query ListChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chatRoomName
        lastMessageText
        lastMessageTimestamp
        userID
        agentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChatRooms = /* GraphQL */ `
  query SyncChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatRooms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        chatRoomName
        lastMessageText
        lastMessageTimestamp
        userID
        agentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const chatRoomByName = /* GraphQL */ `
  query ChatRoomByName(
    $chatRoomName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomByName(
      chatRoomName: $chatRoomName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomName
        lastMessageText
        lastMessageTimestamp
        userID
        agentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const chatRoomsByUser = /* GraphQL */ `
  query ChatRoomsByUser(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomsByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomName
        lastMessageText
        lastMessageTimestamp
        userID
        agentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const chatRoomsByAgentID = /* GraphQL */ `
  query ChatRoomsByAgentID(
    $agentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomsByAgentID(
      agentID: $agentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomName
        lastMessageText
        lastMessageTimestamp
        userID
        agentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSFUser = /* GraphQL */ `
  query GetSFUser($id: ID!) {
    getSFUser(id: $id) {
      id
      userName
      password
      group
      sFUserUserId
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listSFUsers = /* GraphQL */ `
  query ListSFUsers(
    $filter: ModelSFUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        password
        group
        sFUserUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSFUsers = /* GraphQL */ `
  query SyncSFUsers(
    $filter: ModelSFUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSFUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userName
        password
        group
        sFUserUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const sFUsersByUserName = /* GraphQL */ `
  query SFUsersByUserName(
    $userName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSFUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sFUsersByUserName(
      userName: $userName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        password
        group
        sFUserUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const sFUsersByGroup = /* GraphQL */ `
  query SFUsersByGroup(
    $group: String!
    $sFUserUserId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSFUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sFUsersByGroup(
      group: $group
      sFUserUserId: $sFUserUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        password
        group
        sFUserUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const sFUserByUserID = /* GraphQL */ `
  query SFUserByUserID(
    $sFUserUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSFUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sFUserByUserID(
      sFUserUserId: $sFUserUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        password
        group
        sFUserUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      firstName
      lastName
      phoneNumber
      imageUrl
      address
      birthday
      gender
      sFUser {
        id
        userName
        password
        group
        sFUserUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userDoctorId
      chatRooms {
        nextToken
        startedAt
      }
      messages {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSFUserId
      userPatientId
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const userByDoctorID = /* GraphQL */ `
  query UserByDoctorID(
    $userDoctorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByDoctorID(
      userDoctorId: $userDoctorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getAgent = /* GraphQL */ `
  query GetAgent($id: ID!) {
    getAgent(id: $id) {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAgents = /* GraphQL */ `
  query ListAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAgents = /* GraphQL */ `
  query SyncAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAgents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const agentByName = /* GraphQL */ `
  query AgentByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    agentByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDoctorPatient = /* GraphQL */ `
  query GetDoctorPatient($id: ID!) {
    getDoctorPatient(id: $id) {
      id
      patientId
      doctorId
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listDoctorPatients = /* GraphQL */ `
  query ListDoctorPatients(
    $filter: ModelDoctorPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctorPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        doctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDoctorPatients = /* GraphQL */ `
  query SyncDoctorPatients(
    $filter: ModelDoctorPatientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDoctorPatients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        patientId
        doctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const doctorPatientsByPatientID = /* GraphQL */ `
  query DoctorPatientsByPatientID(
    $patientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorPatientsByPatientID(
      patientID: $patientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        doctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const doctorPatientsByDoctorID = /* GraphQL */ `
  query DoctorPatientsByDoctorID(
    $doctorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorPatientsByDoctorID(
      doctorID: $doctorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        doctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPatientDisease = /* GraphQL */ `
  query GetPatientDisease($id: ID!) {
    getPatientDisease(id: $id) {
      id
      patientId
      diseaseId
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      disease {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listPatientDiseases = /* GraphQL */ `
  query ListPatientDiseases(
    $filter: ModelPatientDiseaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatientDiseases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        diseaseId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPatientDiseases = /* GraphQL */ `
  query SyncPatientDiseases(
    $filter: ModelPatientDiseaseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPatientDiseases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        patientId
        diseaseId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const patientDiseasesByPatientID = /* GraphQL */ `
  query PatientDiseasesByPatientID(
    $patientID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPatientDiseaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientDiseasesByPatientID(
      patientID: $patientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        diseaseId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const patientDiseasesByDiseaseID = /* GraphQL */ `
  query PatientDiseasesByDiseaseID(
    $diseaseID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPatientDiseaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientDiseasesByDiseaseID(
      diseaseID: $diseaseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        diseaseId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
