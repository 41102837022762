import React, { useEffect, useRef } from 'react';
import { Button, Hidden, HStack, Icon, Image, Modal, Pressable, Text, VStack } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import { Animated } from 'react-native';
import { Course } from './ResumeCourses';
import { getSFUserByUserId } from '../utils/graphql/sf_user_utils';
import axios from 'axios';
import { useAuth } from './auth/AmplifyAuthProvider';
import { gqlGetAppointmentByDoctorID } from '../utils/graphql/appointment_utils';
import Constants from 'expo-constants';

const ResumeCourses2 = ({ courses }: { courses: Course[] }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [appointments, setAppointments] = React.useState<any>();
  const { user } = useAuth();

  useEffect(() => {
    (async () => {
      if (user?.id) {
        const appointmentList = await gqlGetAppointmentByDoctorID(user.id);
        if (appointmentList) {
          setAppointments(appointmentList);
        }
      }
    })();
  }, [user]);
  const openModal = (item: Course) => {
    if (item.id === 3) {
      setShowModal(true);
    }
  };
  const getMeeting = async () => {
    if (!user) {
      return;
    }
    try {
      const dataToGetMeeting = {
        vMeetId: user.sfWebServiceUserId,
        x: 745,
        user_secret: user.sfWebServiceUserSecret,
        recent_meeting: 1
      };
      const getMeetingRequest = await axios.post('/meeting/create', dataToGetMeeting, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user.idToken}` }
      });
      if (getMeetingRequest.status === 201 && getMeetingRequest.data && getMeetingRequest.data.meetings) {
        if (getMeetingRequest.data.meetings.length > 0) {
          const meeting = getMeetingRequest.data.meetings[0];
          if (`0${meeting.chairman_id}` === user.sfWebServiceUserId && meeting.meeting_id) {
            const dataToCancelMeeting = {
              act: 'cancel',
              x: 192,
              meeting_id: meeting.meeting_id,
              user_id: user.sfWebServiceUserId,
              user_secret: user.sfWebServiceUserSecret
            };
            const cancelMeetingRequest = await axios.post('/meeting/create', dataToCancelMeeting, {
              headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user.idToken}` }
            });
            if (cancelMeetingRequest.status === 201 && cancelMeetingRequest.data && cancelMeetingRequest.data.result) {
              await createMeeting();
            }
          }
        } else {
          await createMeeting();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const createMeeting = async () => {
    const sFUserIdList: string[] = [];
    await Promise.all(
      appointments.map(async (appointment: any) => {
        try {
          const sFUser = await getSFUserByUserId(appointment.patientID);
          if (sFUser) {
            sFUserIdList.push(sFUser.userName);
          }
        } catch (err) {
          console.log(err);
        }
      })
    );
    const uniqueStrings = [...new Set(sFUserIdList)];
    const vMeetId = uniqueStrings.join(',0,').concat(',0');
    try {
      const data = {
        act: 'create',
        x: 192,
        topic: 'Meeting of Test',
        chairman_vmeetid: user?.sfWebServiceUserId,
        duration: 3600,
        timezone: 25200,
        start_now: 1,
        vmeetid_list: vMeetId
      };
      const ret = await axios.post('/meeting/create', data, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user?.idToken}` }
      });
      if (ret.status === 201 && ret.data && ret.data.meeting && user) {
        const meeting = ret.data.meeting;
        const meetingIdParam = `meeting_id=${meeting.meeting_id}`;
        const uidParam = `uid=${user.sfWebServiceUserId}`;
        const passwordParam = `password=${user.sfWebServicePassword}`;
        const serverParam = `server=${Constants.expoConfig?.extra?.sfWebServiceUrl}`;
        const res = await axios.post(
          `${Constants.expoConfig?.extra?.sfWebServiceUrl}/api/url.php?x=set&c=esfp://join?${encodeURIComponent(
            `${meetingIdParam}&${uidParam}&${passwordParam}&${serverParam}`
          )}`
        );
        if (res.data && res.data.success && res.data.url) {
          window.open(
            `${Constants.expoConfig?.extra?.meetingUrl}/meeting/?url=${encodeURIComponent(res.data.url.replace('http', 'https'))}`,
            '_blank'
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <VStack justifyContent={'center'} alignSelf={'center'} width={{ base: '90%', md: '48%' }} mx={{ base: 0, md: 4 }}>
      {courses.map((item: Course) => {
        return (
          <Pressable onPress={() => openModal(item)} key={item.id}>
            <Card course={item} />
          </Pressable>
        );
      })}
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Content maxWidth='300px' maxHeight='200px'>
          <Modal.CloseButton />
          <Modal.Header fontWeight={'bold'}>Appointments</Modal.Header>
          <Modal.Body>
            <Text>
              You have {appointments?.length} patient{appointments?.length > 1 && 's'} waiting
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <HStack justifyContent={'center'}>
              <Button bg={'primary.600'} rounded={'2xl'} px={4} py={2} onPress={getMeeting}>
                <Text color={'white'} fontWeight={'bold'}>
                  Call
                </Text>
              </Button>
            </HStack>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </VStack>
  );
};

const Card = ({ course }: { course: Course }) => {
  const animatedValue = useRef(new Animated.Value(1400)).current;

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: -10,
      duration: 3000,
      useNativeDriver: false
    }).start();
  }, [animatedValue]);
  return (
    <VStack p={{ base: 2, md: 4 }} width={{ base: '100%', md: '100%' }} height={{ base: '100%', md: '100%' }}>
      <HStack
        height={{ base: '100%', md: '100%' }}
        backgroundColor={course.backgroundColor}
        rounded={'2xl'}
        px='3'
        py='2'
        justifyContent='space-between'
        style={{ alignItems: 'center' }}>
        <VStack ml={2}>
          <Text
            ml={{ base: 0, md: 5 }}
            fontSize={{ base: 'md', md: '2xl' }}
            _light={{ color: 'white' }}
            _dark={{ color: 'coolGray.100' }}
            fontWeight='bold'>
            {course.chapter}
          </Text>
          <Text
            ml={{ base: 0, md: 5 }}
            fontSize={{ base: 'sm', md: '2xl' }}
            fontWeight='extrabold'
            _light={{ color: 'white' }}
            _dark={{ color: 'coolGray.100' }}>
            {course.name}
          </Text>
          <Hidden till={'md'}>
            <Icon
              ml={{ base: 0, md: 5 }}
              size='6'
              pt='0.5'
              as={FontAwesome}
              name={'line-chart'}
              _light={{ color: 'white' }}
              _dark={{ color: 'coolGray.50' }}
            />
          </Hidden>
        </VStack>
        <Image rounded={'full'} borderColor={'#fff'} borderWidth={2} size={{ base: '10', md: '20' }} source={course.imageUri} alt='Alternate Text' />
      </HStack>
    </VStack>
    // </Animated.View>
  );
};

export default ResumeCourses2;
