import React from 'react';
import { Box, Divider, Hidden, HStack } from 'native-base';
import ChatList from './ChatList';
import ChatScreen from './ChatScreen';
import { selectAllChatRooms } from '../../store/chat/chat-rooms/chat-rooms-slice';
import { useAppSelector } from '../../store/hooks';
import ModalLanguageForm from './ModalLanguageForm';
import ChatScreenMobile from './ChatScreenMobile';

const ChatTotalMobile = () => {
  const chatRooms = useAppSelector(selectAllChatRooms);
  return (
    <>
      <Hidden till={'md'}>
        {chatRooms && chatRooms.length > 0 ? (
          <HStack alignSelf={'center'} width={'full'} height={'full'}>
            <Box w={['35%', '35%', '25%']} height={'100%'}>
              <ChatList />
            </Box>
            <Divider orientation='vertical' />
            <Box width={['65%', '65%', '75%']} height={'100%'}>
              <ChatScreen />
            </Box>
          </HStack>
        ) : (
          // Floating Button to create a new chat room
          <Box shadow={9} position='absolute' bottom={4} right={8}>
            <ModalLanguageForm />
          </Box>
        )}
      </Hidden>
      <Hidden from={'md'}>
        {chatRooms && chatRooms.length > 0 ? (
          <Box w={'100%'} height={'100%'}>
            <ChatScreenMobile />
          </Box>
        ) : (
          // Floating Button to create a new chat room
          <Box>
            <ModalLanguageForm />
          </Box>
        )}
      </Hidden>
    </>
  );
};

export default ChatTotalMobile;
