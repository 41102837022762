import React, { useState } from 'react';
import { Box, Divider, Hidden, HStack, Icon, Pressable, ScrollView, Text, VStack } from 'native-base';
import DashboardLayoutPatient from '../../layouts/DashboardLayoutPatient';
import SearchInput from '../../components/SearchInput';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import CategoriesPatient, { Category } from '../../components/CategoriesPatient';
import WorldDiabeticDay from '../../components/WorldDiabeticDay';
import Wrapper from '../../components/Wrapper';
import { RootStackScreensEnum } from '../../navigation/enum';
import SearchBarPatient from './SearchBarPatient';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { MainStackParamList } from '../../types/Stacks';

const categories: Category[] = [
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'COVID-19'
  },
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'Cough and Flu'
  },
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'Subscriptions'
  },
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'Bulk Saving'
  },
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'Digestive System'
  },
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'Specialty Milk'
  },
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'Erha'
  },
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'Feminine Care'
  },
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'Mom & Kids'
  }
];

const HealthStore = () => {
  const navigation = useNavigation<NativeStackNavigationProp<MainStackParamList, RootStackScreensEnum.Patient>>();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [textInput, setTextInput] = useState('');

  const handleSearchTextChange = (text: string) => {
    setTextInput(text);
  };

  const handleToggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    setTextInput('');
  };
  return (
    <>
      <Hidden till={'md'}>
        <DashboardLayoutPatient displayScreenTitle={true} title='Health Store' rightPanelMobileHeader={true}>
          <VStack px={{ base: 4, md: 0, lg: 8 }}>
            <HStack space='1' alignItems='center' mt={3}>
              <Icon as={<MaterialIcons name='location-pin' />} size={5} color='blue.400' />
              <Text fontSize='md' fontWeight='medium'>
                Ho Chi Minh City
              </Text>
              <HStack flex={1} justifyContent='flex-end'>
                <Icon as={<MaterialIcons name='edit' />} size={5} color='gray.700' />
              </HStack>
            </HStack>
            <Wrapper>
              <SearchInput placeholder='Example: Lifebuoy' />
            </Wrapper>
            <Wrapper title='Browse by Category' hasSeeAllTitle={true}>
              <CategoriesPatient categories={categories} numColumns={3} />
            </Wrapper>
            <Wrapper title={'World Diabetic Day'} subTitle={'Save up to 50% + FREE delivery'} hasSeeAllTitle={true}>
              <WorldDiabeticDay />
            </Wrapper>
          </VStack>
        </DashboardLayoutPatient>
      </Hidden>
      <Hidden from={'md'}>
        <>
          <HStack alignItems={'center'} backgroundColor={'#fff'} justifyContent={'space-between'} height={'64px'} w={'100%'}>
            {!isSearchOpen && (
              <HStack alignItems={'center'} w={'100%'}>
                <Pressable
                  mt={1}
                  ml={'4'}
                  onPress={() => navigation.navigate(RootStackScreensEnum.Patient, { screen: RootStackScreensEnum.DashboardPatient })}
                  mr={1}>
                  <Icon as={Ionicons} name='arrow-back' size='xl' color={'coolGray.700'} />
                </Pressable>
                <Text w={'95%'} fontSize='20' fontWeight='bold' _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }}>
                  Health Store
                </Text>
                <Pressable
                  onPress={handleToggleSearch}
                  w={'5%'}
                  mr={4}
                  p={4}
                  alignItems='center'
                  justifyContent='center'
                  size={'10'}
                  rounded='full'
                  _hover={{ bg: '#E4EBE4' }}>
                  <Icon as={<MaterialIcons name='search' />} size={8} color='coolGray.900' />
                </Pressable>
              </HStack>
            )}
            <Box w={'100%'} px={2}>
              {isSearchOpen && (
                <SearchBarPatient textInput={textInput} handleSearchTextChange={handleSearchTextChange} handleToggleSearch={handleToggleSearch} />
              )}
            </Box>
          </HStack>
          <Divider />
          <ScrollView showsVerticalScrollIndicator={false} backgroundColor={'#fff'} px={{ base: 4, md: 0, lg: 8 }}>
            {/*<HStack space='1' alignItems='center' mt={3}>*/}
            {/*  <Icon as={<MaterialIcons name='location-pin' />} size={5} color='blue.400' />*/}
            {/*  <Text fontSize='md' fontWeight='medium'>*/}
            {/*    Ho Chi Minh City*/}
            {/*  </Text>*/}
            {/*  <HStack flex={1} justifyContent='flex-end'>*/}
            {/*    <Icon as={<MaterialIcons name='edit' />} size={5} color='gray.700' />*/}
            {/*  </HStack>*/}
            {/*</HStack>*/}
            {/*<Wrapper>*/}
            {/*  <SearchInput placeholder='Example: Lifebuoy' />*/}
            {/*</Wrapper>*/}
            <Box mt={4} />
            <Wrapper title='Browse by Category' hasSeeAllTitle={true}>
              <CategoriesPatient categories={categories} numColumns={3} />
            </Wrapper>
            <Wrapper title={'World Diabetic Day'} subTitle={'Save up to 50% + FREE delivery'} hasSeeAllTitle={true}>
              <WorldDiabeticDay />
            </Wrapper>
          </ScrollView>
        </>
      </Hidden>
    </>
  );
};

export default HealthStore;
