import { createEntityAdapter, createSlice, PayloadAction, Update } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { IMessageContact } from '../../../types/IMessageContact';

const entityAdapter = createEntityAdapter<IMessageContact>({
  selectId: (message) => message.id,
  sortComparer: (a, b) => {
    // Sort the message with empty createdAt to the bottom
    if (!a.createdAt && b.createdAt) {
      return 1;
    } else if (a.createdAt && !b.createdAt) {
      return -1;
    } else if (!a.createdAt && !b.createdAt) {
      return 0;
    }

    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  }
});

const initialState = entityAdapter.getInitialState({
  fetchedMessages: [] as IMessageContact[],
  isFetchedMessagesComplete: false,
  beforeMessageId: '',
  canLoadMore: true
});

const removeOldMessages = (state: typeof initialState) => {
  // Only limit 1000 messages per chat room
  if (state.ids.length > 1000) {
    console.log('Too many messages, removing old messages');
    const idsToRemove = state.ids.slice(0, state.ids.length - 1000);
    entityAdapter.removeMany(state, idsToRemove);
  }
};

const chatRoomContactSlice = createSlice({
  name: 'chat/chatRoomContact',
  initialState,
  reducers: {
    fetchMessagesFromXmppServer: (state, action: PayloadAction<IMessageContact>) => {
      state.fetchedMessages.push(action.payload);

      // Make sure we don't have too many messages
      removeOldMessages(state);
    },
    setMessages: (state) => {
      entityAdapter.setMany(state, [...state.fetchedMessages]);
      state.fetchedMessages = [];

      // Make sure we don't have too many messages
      removeOldMessages(state);
    },
    setBeforeMessageId: (state, action: PayloadAction<string>) => {
      state.beforeMessageId = action.payload;
    },
    addMessages: (state, action: PayloadAction<IMessageContact>) => {
      entityAdapter.upsertOne(state, action.payload);

      // Make sure we don't have too many messages
      removeOldMessages(state);
    },
    setMessagesComplete: (state, action: PayloadAction<boolean>) => {
      state.isFetchedMessagesComplete = action.payload;
    },
    setCanLoadMore: (state, action: PayloadAction<boolean>) => {
      state.canLoadMore = action.payload;
    },
    removeMessage: (state, action: PayloadAction<string>) => {
      entityAdapter.removeOne(state, action.payload);
    },
    updateMessage: (state, action: PayloadAction<Update<IMessageContact>>) => {
      entityAdapter.updateOne(state, action.payload);
    },
    resetChatRoom: () => {
      return initialState;
    }
  }
});

export const selectAllMessages = (state: RootState) => {
  return entityAdapter.getSelectors().selectAll(state.chat.chatRoomContact);
};

export const selectIsSetMessagesComplete = (state: RootState) => {
  return state.chat.chatRoomContact.isFetchedMessagesComplete;
};

export const selectCanLoadMore = (state: RootState) => {
  return state.chat.chatRoomContact.canLoadMore;
};

export const selectBeforeMessageId = (state: RootState) => {
  return state.chat.chatRoomContact.beforeMessageId;
};

export default chatRoomContactSlice;
export const chatRoomContactActions = chatRoomContactSlice.actions;
