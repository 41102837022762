import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  FormControl,
  Hidden,
  HStack,
  Icon,
  Image,
  Input,
  Link,
  Pressable,
  Spinner,
  Text,
  VStack
} from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import GuestLayout from '../../layouts/GuestLayout';
import * as Yup from 'yup';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import { Formik } from 'formik';
import { useNavigation } from '@react-navigation/native';
import { RootStackScreensEnum } from '../../navigation/enum';
import { AuthStackParamList } from '../../types/Stacks';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

interface FormikState<Values> {
  isSubmitting: boolean;
}

const SignInForm = () => {
  const [show, setShow] = React.useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signIn, errorMessage, setErrorMessage, successMessage, setSuccessMessage } = useAuth();
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('You must enter a valid email'),
    password: Yup.string().required('Password is required').min(8, 'Password is too short - must be at least 8 chars.')
  });
  const navigation = useNavigation<NativeStackNavigationProp<AuthStackParamList, RootStackScreensEnum.SignIn>>();

  useEffect(() => {
    return () => {
      setErrorMessage('');
      setSuccessMessage('');
    };
  }, [setErrorMessage, setSuccessMessage]);

  // Navigate to code verification page if needed
  useEffect(() => {
    if (errorMessage === 'You must confirm your email address before you can log in.' && email && password) {
      navigation.navigate(RootStackScreensEnum.OtpVerification, {
        email: email
      });
    }
  }, [email, errorMessage, navigation, password]);

  const onSubmit = async (values: any) => {
    const { email, password } = values;
    if (!email || !password) {
      return;
    }
    setEmail(email);
    setPassword(password);
    await signIn(email, password);
  };
  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      {({ touched, handleChange, handleBlur, handleSubmit, values, errors, isSubmitting, dirty }) => (
        <VStack space={4}>
          {/*BEGIN: Success or error message. Success message in green, error message in red*/}
          {successMessage && (
            <Box bg='green.400' px={4} py={3} rounded='md' _text={{ color: 'white' }}>
              {successMessage}
            </Box>
          )}
          {errorMessage && (
            <Box bg='red.400' px={4} py={3} rounded='md' _text={{ color: 'white' }}>
              {errorMessage}
            </Box>
          )}
          {/* END: Success or error message */}

          <FormControl isInvalid={'email' in errors && touched.email}>
            <FormControl.Label>Email</FormControl.Label>
            <Input
              onKeyPress={(event) => {
                if (event.nativeEvent.key === 'Enter') {
                  handleSubmit();
                }
              }}
              onBlur={handleBlur('email')}
              placeholder='Enter your email'
              onChangeText={handleChange('email')}
              value={values.email}
            />
            <FormControl.ErrorMessage>{errors.email}</FormControl.ErrorMessage>
          </FormControl>

          <FormControl isInvalid={'password' in errors && touched.password}>
            <FormControl.Label>Password</FormControl.Label>
            <Input
              onKeyPress={(event) => {
                if (event.nativeEvent.key === 'Enter') {
                  handleSubmit();
                }
              }}
              onBlur={handleBlur('password')}
              placeholder='Enter your password'
              onChangeText={handleChange('password')}
              value={values.password}
              type={show ? 'text' : 'password'}
              InputRightElement={
                <Pressable onPress={() => setShow(!show)}>
                  <Icon as={<MaterialIcons name={show ? 'visibility' : 'visibility-off'} />} size={5} mr='2' />
                </Pressable>
              }
            />
            <FormControl.ErrorMessage>{errors.password}</FormControl.ErrorMessage>
          </FormControl>
          <HStack justifyContent='space-between'>
            <Checkbox value='' my={2}>
              Remember me
            </Checkbox>
            <Text fontSize='sm' color='blue.500' onPress={() => navigation.navigate(RootStackScreensEnum.ForgotPassword)}>
              Forgot password?
            </Text>
          </HStack>
          <Button
            w={'50%'}
            rounded={'xl'}
            alignSelf={'center'}
            disabled={isSubmitting || !dirty}
            onPress={() => handleSubmit()}
            bg='#66CDAA'
            _text={{ color: 'white', fontSize: 'xl', fontWeight: 'bold', fontFamily: 'Sketch_Block' }}>
            {isSubmitting ? (
              <Spinner accessibilityLabel='Loading' color={'white'} size={'lg'} fontWeight={'bold'} fontFamily={'Sketch_Block'} />
            ) : (
              <Text fontSize={'xl'} fontWeight={'bold'} fontFamily={'Patrickhand_Regular'} color={'white'}>
                Login
              </Text>
            )}
          </Button>
        </VStack>
      )}
    </Formik>
  );
};

const SignInComponent = () => {
  const navigation = useNavigation<NativeStackNavigationProp<AuthStackParamList, RootStackScreensEnum.SignIn>>();

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{
        flexGrow: 1
      }}
      style={{ flex: 1 }}
      bounces={false}>
      <MobileHeader />
      <Box
        py={8}
        px={{ base: 4, md: 8 }}
        flex={1}
        _light={{ bg: 'white' }}
        _dark={{ bg: 'coolGray.800' }}
        borderTopLeftRadius={{ base: '2xl', md: 0 }}
        borderTopRightRadius={{ base: '2xl', md: 'xl' }}
        borderBottomRightRadius={{ base: 0, md: 'xl' }}>
        <Text
          fontSize='5xl'
          fontWeight='bold'
          _light={{ color: '#66CDAA' }}
          _dark={{ color: '#66CDAA' }}
          textAlign='center'
          fontFamily='Patrickhand_Regular'>
          Login
        </Text>
        <SignInForm />
        <HStack my={4} space='2' style={{ alignItems: 'center' }} justifyContent='center'>
          <Divider w='30%' _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }} />
          <Text
            fontWeight='medium'
            _light={{
              color: 'coolGray.400'
            }}
            _dark={{
              color: 'coolGray.300'
            }}>
            or
          </Text>
          <Divider w='30%' _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }} />
        </HStack>
        <HStack justifyContent='center' style={{ alignItems: 'center' }} space='4'>
          <Link href='https://nativebase.io'>
            <Image width='6' height='6' source={require('../../assets/facebook.png')} alt='Alternate Text' />
          </Link>
          <Link href='https://nativebase.io'>
            <Image width='6' height='6' source={require('../../assets/GoogleLogo.png')} alt='Alternate Text' />
          </Link>
        </HStack>
        <HStack
          space='1'
          safeAreaBottom
          style={{ alignItems: 'center' }}
          justifyContent='center'
          mt={{ base: '8', md: '8' }}
          mb={{ base: '24', md: '0' }}>
          <Text fontSize='sm' fontWeight='normal' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }}>
            Don&apos;t have an account?
          </Text>
          <Pressable onPress={() => navigation.replace(RootStackScreensEnum.SignUp)}>
            <Link
              _text={{
                fontSize: { base: 'sm', md: 'xs' },
                fontWeight: 'bold',
                textDecoration: 'none'
              }}
              _light={{
                _text: {
                  color: '#66CDAA'
                }
              }}
              _dark={{
                _text: {
                  color: 'primary.500'
                }
              }}>
              Sign Up
            </Link>
          </Pressable>
        </HStack>
      </Box>
    </KeyboardAwareScrollView>
  );
};

function SideContainerWeb() {
  return (
    <Center flex='1' _light={{ bg: '#E0FFFF' }} _dark={{ bg: '#00688B' }} borderTopLeftRadius={{ md: 'xl' }} borderBottomLeftRadius={{ md: 'xl' }}>
      <VStack style={{ alignItems: 'center' }}>
        <Image source={require('../../assets/logoApp.png')} height='400' width='400' alt='Alternate Text' />
      </VStack>
    </Center>
  );
}

function MobileHeader() {
  return (
    <Hidden from='md'>
      <VStack px='4' mt={24} mb='2' space='9'>
        <VStack space={0.5} mb={4}>
          <Text fontSize='4xl' fontWeight='bold' color='white'>
            Let&apos;s get started now!
          </Text>
          <Text
            fontSize='md'
            fontWeight='normal'
            _dark={{
              color: 'white'
            }}
            _light={{
              color: 'white'
            }}>
            Or create an account if not registered yet
          </Text>
        </VStack>
      </VStack>
    </Hidden>
  );
}

export default function SignIn() {
  return (
    <GuestLayout>
      <Hidden till='md'>
        <SideContainerWeb />
      </Hidden>

      <SignInComponent />
    </GuestLayout>
  );
}
