import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { MainStackParamList } from '../types/Stacks';
import { RootStackScreensEnum } from './enum';
import Logout from '../screens/AuthScreen/Logout';
import ChangePassword from '../screens/Settings/ChangePassword';
import React from 'react';
import AdminStackScreen from './AdminStackScreen';
import DoctorStackScreen from './DoctorStackScreen';
import PatientStackScreen from './PatientStackScreen';
import { ChatProvider } from '../screens/Chat/ChatProvider';
import Profile from '../screens/Settings/Profile';
import Language from '../screens/Settings/Language';
import Notifications from '../screens/Settings/Notifications';
import DisableAccount from '../screens/Settings/DisableAccount';

const MainStack = createNativeStackNavigator<MainStackParamList>();

const MainStackScreen = () => {
  return (
    <ChatProvider>
      <MainStack.Navigator
        screenOptions={{
          headerShown: false
        }}>
        <MainStack.Screen
          name='Admin'
          component={AdminStackScreen}
          options={{
            headerShown: false
          }}
        />
        <MainStack.Screen
          name='Doctor'
          component={DoctorStackScreen}
          options={{
            headerShown: false
          }}
        />
        <MainStack.Screen
          name='Patient'
          component={PatientStackScreen}
          options={{
            headerShown: false
          }}
        />
        {/*<MainStack.Screen name={RootStackScreensEnum.ChatTotal} component={ChatTotal} />*/}
        {/*<MainStack.Screen name={RootStackScreensEnum.ChatList} component={ChatList} />*/}
        {/*<MainStack.Screen name={RootStackScreensEnum.ChatScreen} component={ChatScreen} />*/}
        <MainStack.Screen name={RootStackScreensEnum.Logout} component={Logout} />
        <MainStack.Screen name={RootStackScreensEnum.Profile} component={Profile} />
        <MainStack.Screen name={RootStackScreensEnum.ChangePassword} component={ChangePassword} />
        <MainStack.Screen name={RootStackScreensEnum.Language} component={Language} />
        <MainStack.Screen name={RootStackScreensEnum.Notifications} component={Notifications} />
        <MainStack.Screen name={RootStackScreensEnum.DisableAccount} component={DisableAccount} />
      </MainStack.Navigator>
    </ChatProvider>
  );
};

export default MainStackScreen;
