/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMedicine = /* GraphQL */ `
  subscription OnCreateMedicine {
    onCreateMedicine {
      id
      name
      description
      prescriptions {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateMedicine = /* GraphQL */ `
  subscription OnUpdateMedicine {
    onUpdateMedicine {
      id
      name
      description
      prescriptions {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteMedicine = /* GraphQL */ `
  subscription OnDeleteMedicine {
    onDeleteMedicine {
      id
      name
      description
      prescriptions {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePrescription = /* GraphQL */ `
  subscription OnCreatePrescription {
    onCreatePrescription {
      id
      instruction
      quantity
      medicalRecordID
      medicalRecord {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      medicineID
      medicine {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePrescription = /* GraphQL */ `
  subscription OnUpdatePrescription {
    onUpdatePrescription {
      id
      instruction
      quantity
      medicalRecordID
      medicalRecord {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      medicineID
      medicine {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePrescription = /* GraphQL */ `
  subscription OnDeletePrescription {
    onDeletePrescription {
      id
      instruction
      quantity
      medicalRecordID
      medicalRecord {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      medicineID
      medicine {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateMedicalRecord = /* GraphQL */ `
  subscription OnCreateMedicalRecord {
    onCreateMedicalRecord {
      id
      timestamp
      status
      pulseRate
      bloodPressure
      bodyTemperature
      respiratoryRate
      symptoms
      diagnosis
      dischargeInstruction
      billingInfo
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patientID
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      prescriptions {
        nextToken
        startedAt
      }
      testResults {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateMedicalRecord = /* GraphQL */ `
  subscription OnUpdateMedicalRecord {
    onUpdateMedicalRecord {
      id
      timestamp
      status
      pulseRate
      bloodPressure
      bodyTemperature
      respiratoryRate
      symptoms
      diagnosis
      dischargeInstruction
      billingInfo
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patientID
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      prescriptions {
        nextToken
        startedAt
      }
      testResults {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteMedicalRecord = /* GraphQL */ `
  subscription OnDeleteMedicalRecord {
    onDeleteMedicalRecord {
      id
      timestamp
      status
      pulseRate
      bloodPressure
      bodyTemperature
      respiratoryRate
      symptoms
      diagnosis
      dischargeInstruction
      billingInfo
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patientID
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      prescriptions {
        nextToken
        startedAt
      }
      testResults {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTestResult = /* GraphQL */ `
  subscription OnCreateTestResult {
    onCreateTestResult {
      id
      timestamp
      testType
      description
      result
      images
      medicalRecordID
      medicalRecord {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTestResult = /* GraphQL */ `
  subscription OnUpdateTestResult {
    onUpdateTestResult {
      id
      timestamp
      testType
      description
      result
      images
      medicalRecordID
      medicalRecord {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTestResult = /* GraphQL */ `
  subscription OnDeleteTestResult {
    onDeleteTestResult {
      id
      timestamp
      testType
      description
      result
      images
      medicalRecordID
      medicalRecord {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePatient = /* GraphQL */ `
  subscription OnCreatePatient($owner: String) {
    onCreatePatient(owner: $owner) {
      id
      checkInTime
      status
      ethnicity
      healthHistory
      allergies
      immunizations
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      patientUserId
      doctors {
        nextToken
        startedAt
      }
      diseases {
        nextToken
        startedAt
      }
      medicalRecords {
        nextToken
        startedAt
      }
      appointments {
        nextToken
        startedAt
      }
      reviews {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onUpdatePatient = /* GraphQL */ `
  subscription OnUpdatePatient($owner: String) {
    onUpdatePatient(owner: $owner) {
      id
      checkInTime
      status
      ethnicity
      healthHistory
      allergies
      immunizations
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      patientUserId
      doctors {
        nextToken
        startedAt
      }
      diseases {
        nextToken
        startedAt
      }
      medicalRecords {
        nextToken
        startedAt
      }
      appointments {
        nextToken
        startedAt
      }
      reviews {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onDeletePatient = /* GraphQL */ `
  subscription OnDeletePatient($owner: String) {
    onDeletePatient(owner: $owner) {
      id
      checkInTime
      status
      ethnicity
      healthHistory
      allergies
      immunizations
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      patientUserId
      doctors {
        nextToken
        startedAt
      }
      diseases {
        nextToken
        startedAt
      }
      medicalRecords {
        nextToken
        startedAt
      }
      appointments {
        nextToken
        startedAt
      }
      reviews {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onCreateDoctor = /* GraphQL */ `
  subscription OnCreateDoctor {
    onCreateDoctor {
      id
      specialist
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      doctorUserId
      patients {
        nextToken
        startedAt
      }
      medicalRecords {
        nextToken
        startedAt
      }
      departmentID
      department {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      workingTimeSpans {
        nextToken
        startedAt
      }
      appointments {
        nextToken
        startedAt
      }
      reviews {
        nextToken
        startedAt
      }
      introduction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDoctor = /* GraphQL */ `
  subscription OnUpdateDoctor {
    onUpdateDoctor {
      id
      specialist
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      doctorUserId
      patients {
        nextToken
        startedAt
      }
      medicalRecords {
        nextToken
        startedAt
      }
      departmentID
      department {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      workingTimeSpans {
        nextToken
        startedAt
      }
      appointments {
        nextToken
        startedAt
      }
      reviews {
        nextToken
        startedAt
      }
      introduction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDoctor = /* GraphQL */ `
  subscription OnDeleteDoctor {
    onDeleteDoctor {
      id
      specialist
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      doctorUserId
      patients {
        nextToken
        startedAt
      }
      medicalRecords {
        nextToken
        startedAt
      }
      departmentID
      department {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      workingTimeSpans {
        nextToken
        startedAt
      }
      appointments {
        nextToken
        startedAt
      }
      reviews {
        nextToken
        startedAt
      }
      introduction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateReview = /* GraphQL */ `
  subscription OnCreateReview {
    onCreateReview {
      id
      rating
      comment
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      patientID
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateReview = /* GraphQL */ `
  subscription OnUpdateReview {
    onUpdateReview {
      id
      rating
      comment
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      patientID
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteReview = /* GraphQL */ `
  subscription OnDeleteReview {
    onDeleteReview {
      id
      rating
      comment
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      patientID
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDepartment = /* GraphQL */ `
  subscription OnCreateDepartment {
    onCreateDepartment {
      id
      name
      description
      doctors {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDepartment = /* GraphQL */ `
  subscription OnUpdateDepartment {
    onUpdateDepartment {
      id
      name
      description
      doctors {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDepartment = /* GraphQL */ `
  subscription OnDeleteDepartment {
    onDeleteDepartment {
      id
      name
      description
      doctors {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateWorkingTimeSpan = /* GraphQL */ `
  subscription OnCreateWorkingTimeSpan {
    onCreateWorkingTimeSpan {
      id
      dayOfWeek
      date
      hours
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateWorkingTimeSpan = /* GraphQL */ `
  subscription OnUpdateWorkingTimeSpan {
    onUpdateWorkingTimeSpan {
      id
      dayOfWeek
      date
      hours
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteWorkingTimeSpan = /* GraphQL */ `
  subscription OnDeleteWorkingTimeSpan {
    onDeleteWorkingTimeSpan {
      id
      dayOfWeek
      date
      hours
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAppointment = /* GraphQL */ `
  subscription OnCreateAppointment {
    onCreateAppointment {
      id
      date
      hour
      order
      description
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patientID
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAppointment = /* GraphQL */ `
  subscription OnUpdateAppointment {
    onUpdateAppointment {
      id
      date
      hour
      order
      description
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patientID
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAppointment = /* GraphQL */ `
  subscription OnDeleteAppointment {
    onDeleteAppointment {
      id
      date
      hour
      order
      description
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patientID
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDisease = /* GraphQL */ `
  subscription OnCreateDisease {
    onCreateDisease {
      id
      name
      description
      patients {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDisease = /* GraphQL */ `
  subscription OnUpdateDisease {
    onUpdateDisease {
      id
      name
      description
      patients {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDisease = /* GraphQL */ `
  subscription OnDeleteDisease {
    onDeleteDisease {
      id
      name
      description
      patients {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($ownerUserID: String) {
    onCreateMessage(ownerUserID: $ownerUserID) {
      id
      timestamp
      text
      translatedText
      image
      isDefaultMessage
      completionTokens
      chatRoomID
      chatRoom {
        id
        chatRoomName
        lastMessageText
        lastMessageTimestamp
        userID
        agentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      ownerUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($ownerUserID: String) {
    onUpdateMessage(ownerUserID: $ownerUserID) {
      id
      timestamp
      text
      translatedText
      image
      isDefaultMessage
      completionTokens
      chatRoomID
      chatRoom {
        id
        chatRoomName
        lastMessageText
        lastMessageTimestamp
        userID
        agentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      ownerUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($ownerUserID: String) {
    onDeleteMessage(ownerUserID: $ownerUserID) {
      id
      timestamp
      text
      translatedText
      image
      isDefaultMessage
      completionTokens
      chatRoomID
      chatRoom {
        id
        chatRoomName
        lastMessageText
        lastMessageTimestamp
        userID
        agentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      ownerUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateChatRoom = /* GraphQL */ `
  subscription OnCreateChatRoom($userID: String) {
    onCreateChatRoom(userID: $userID) {
      id
      chatRoomName
      lastMessageText
      lastMessageTimestamp
      messages {
        nextToken
        startedAt
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateChatRoom = /* GraphQL */ `
  subscription OnUpdateChatRoom($userID: String) {
    onUpdateChatRoom(userID: $userID) {
      id
      chatRoomName
      lastMessageText
      lastMessageTimestamp
      messages {
        nextToken
        startedAt
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteChatRoom = /* GraphQL */ `
  subscription OnDeleteChatRoom($userID: String) {
    onDeleteChatRoom(userID: $userID) {
      id
      chatRoomName
      lastMessageText
      lastMessageTimestamp
      messages {
        nextToken
        startedAt
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSFUser = /* GraphQL */ `
  subscription OnCreateSFUser($owner: String) {
    onCreateSFUser(owner: $owner) {
      id
      userName
      password
      group
      sFUserUserId
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onUpdateSFUser = /* GraphQL */ `
  subscription OnUpdateSFUser($owner: String) {
    onUpdateSFUser(owner: $owner) {
      id
      userName
      password
      group
      sFUserUserId
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onDeleteSFUser = /* GraphQL */ `
  subscription OnDeleteSFUser($owner: String) {
    onDeleteSFUser(owner: $owner) {
      id
      userName
      password
      group
      sFUserUserId
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String) {
    onCreateUser(owner: $owner) {
      id
      email
      firstName
      lastName
      phoneNumber
      imageUrl
      address
      birthday
      gender
      sFUser {
        id
        userName
        password
        group
        sFUserUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userDoctorId
      chatRooms {
        nextToken
        startedAt
      }
      messages {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSFUserId
      userPatientId
      owner
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String) {
    onUpdateUser(owner: $owner) {
      id
      email
      firstName
      lastName
      phoneNumber
      imageUrl
      address
      birthday
      gender
      sFUser {
        id
        userName
        password
        group
        sFUserUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userDoctorId
      chatRooms {
        nextToken
        startedAt
      }
      messages {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSFUserId
      userPatientId
      owner
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String) {
    onDeleteUser(owner: $owner) {
      id
      email
      firstName
      lastName
      phoneNumber
      imageUrl
      address
      birthday
      gender
      sFUser {
        id
        userName
        password
        group
        sFUserUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userDoctorId
      chatRooms {
        nextToken
        startedAt
      }
      messages {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSFUserId
      userPatientId
      owner
    }
  }
`;
export const onCreateAgent = /* GraphQL */ `
  subscription OnCreateAgent {
    onCreateAgent {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAgent = /* GraphQL */ `
  subscription OnUpdateAgent {
    onUpdateAgent {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAgent = /* GraphQL */ `
  subscription OnDeleteAgent {
    onDeleteAgent {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDoctorPatient = /* GraphQL */ `
  subscription OnCreateDoctorPatient($owner: String) {
    onCreateDoctorPatient(owner: $owner) {
      id
      patientId
      doctorId
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onUpdateDoctorPatient = /* GraphQL */ `
  subscription OnUpdateDoctorPatient($owner: String) {
    onUpdateDoctorPatient(owner: $owner) {
      id
      patientId
      doctorId
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onDeleteDoctorPatient = /* GraphQL */ `
  subscription OnDeleteDoctorPatient($owner: String) {
    onDeleteDoctorPatient(owner: $owner) {
      id
      patientId
      doctorId
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onCreatePatientDisease = /* GraphQL */ `
  subscription OnCreatePatientDisease($owner: String) {
    onCreatePatientDisease(owner: $owner) {
      id
      patientId
      diseaseId
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      disease {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onUpdatePatientDisease = /* GraphQL */ `
  subscription OnUpdatePatientDisease($owner: String) {
    onUpdatePatientDisease(owner: $owner) {
      id
      patientId
      diseaseId
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      disease {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const onDeletePatientDisease = /* GraphQL */ `
  subscription OnDeletePatientDisease($owner: String) {
    onDeletePatientDisease(owner: $owner) {
      id
      patientId
      diseaseId
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      disease {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
