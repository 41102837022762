import React, { useEffect } from 'react';
import { Box, extendTheme, NativeBaseProvider, Spinner, theme as nbTheme } from 'native-base';
import Config from './nativebase.config';
import { Platform } from 'react-native';
import { Button, Divider, Input, Radio, TextArea } from './src/themes';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { AmplifyAuthProvider, useAuth } from './src/components/auth/AmplifyAuthProvider';
import './configureAmplify';
import { Provider } from 'react-redux';
import { RootStackParamList } from './src/navigation/types';
import store from './src/store';
import { useFonts } from 'expo-font';
import { RootStackScreensEnum } from './src/navigation/enum';
import { stropheBuilder, useStrophe } from './src/hooks/useStrophe';
import { fetchContactGroups } from './src/store/chat/contact-groups/contact-groups-slice';
import { useAppDispatch, useAppSelector } from './src/store/hooks';
import MainStackScreen from './src/navigation/MainStackScreen';
import AuthStackScreen from './src/navigation/AuthStackScreen';
import { ConnectionProvider, useConnection } from './src/components/xmpp/ConnectionProvider';
import linking from './src/navigation/LinkingConfiguration';
import { Hub } from 'aws-amplify';
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { ChatRoomSubscription } from './src/utils/graphql/chat_room_utils';
import { MessageSubscription } from './src/utils/graphql/message_utils';
import { selectActiveChatRoomID } from './src/store/chat/chat-room/chat-room-slice';
import { gqlFetchAllAgentsAndDispatch } from './src/utils/graphql/agent_utils';
import { DoctorSubscription, gqlListAllDoctors } from './src/utils/graphql/doctor_utils';
import { chatRoomContactActions } from './src/store/chat/chat-room-contact/chat-room-contact-slice';
import { generate_uuid } from './src/utils/uuid_generator';
import axios from 'axios';
import Constants from 'expo-constants';
import Roles from './src/components/auth/role';
import * as cornerstone3D from '@cornerstonejs/core';
import * as cornerstone3DTools from '@cornerstonejs/tools';
import { initDemo } from './src/utils/demo';

// const chatServer = Constants.expoConfig!.extra!.xmppUrl;
// const connection = new Strophe.Connection(chatServer);

const customTheme = extendTheme({
  config: {
    initialColorMode: 'light'
  },
  colors: {
    primary: nbTheme.colors.blue,
    customTurquoise: '#1DCBB6',
    customBlue: '#2320D4',
    customHeader: '#0564C8',
    secondary: nbTheme.colors.green
  },
  sizes: {
    container: '1016px',
    50: '12.5rem',
    60: '15rem',
    88: '22rem'
  },
  fonts: {
    normal: 'NotoSans_Light',
    medium: 'Notosans_Medium',
    bold: 'Notosans_Bold',
    italic: 'NotoSans_Italic'
  },
  components: {
    Button,
    Radio,
    Divider,
    Input,
    TextArea
  }
});

const Root = () => {
  const dispatch = useAppDispatch();
  const { user, isSignedIn, isReady, checkRole } = useAuth();
  const currentChatRoomID = useAppSelector(selectActiveChatRoomID);
  const strophe = useConnection();

  // Move this to index.html later
  useEffect(() => {
    if (!user || !isSignedIn) {
      console.log('User is not signed in');
      return;
    }

    strophe.setCredentials(user.sfWebServiceUserId + '@sip', user.sfWebServicePassword, 'wss://xmpp.luanpham.vntek.org/ws');

    if (Platform.OS === 'web') {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    }

    // Chat room subscription
    let chatRoomSubscription: ChatRoomSubscription | null = null;
    let doctorSubscription: DoctorSubscription | null = null;
    let listener: ReturnType<typeof Hub.listen> | null = null;

    (async () => {
      // Listen to API events
      listener = Hub.listen('api', (data) => {
        const { payload } = data;
        // TODO (MinhLuan): if there is some sudden disconnection, we need to reload the data
        if (payload.event === CONNECTION_STATE_CHANGE) {
          console.log('API event: ', payload.data.connectionState as ConnectionState);
        }
      });

      // Subscribe to chat rooms
      chatRoomSubscription = new ChatRoomSubscription(dispatch, user.id);
      await chatRoomSubscription.subscribe();

      // Subscribe to doctors
      if ((await checkRole()) !== Roles.Patient) {
        doctorSubscription = new DoctorSubscription(dispatch);
        await doctorSubscription.subscribe();
      } else {
        // Query all doctors without user
        await gqlListAllDoctors();
      }

      // Query all agents and dispatch
      await gqlFetchAllAgentsAndDispatch(dispatch);
    })();

    return () => {
      // Unsubscribe from chat rooms
      if (chatRoomSubscription) {
        chatRoomSubscription.unsubscribe();
      }

      // Unsubscribe from doctors
      if (doctorSubscription) {
        doctorSubscription.unsubscribe();
      }

      // Remove Hub listener
      if (listener) {
        if (listener) listener();
      }
    };
  }, [user, isSignedIn]);

  useEffect(() => {
    if (!strophe.jid) {
      return;
    }
    console.log('User is signed in');
    strophe.connect();
    return () => {
      // Disconnect from XMPP server
      strophe.disconnect('unmounted');
    };
  }, [strophe.jid]);

  // Query and dispatch messages when chat room changes
  useEffect(() => {
    let messagesSubscription: MessageSubscription | null = null;

    (async () => {
      if (!currentChatRoomID) {
        return;
      }

      // Subscribe and dispatch messages
      messagesSubscription = new MessageSubscription(dispatch, currentChatRoomID, user!.id);
      await messagesSubscription.subscribe();
    })();

    return () => {
      if (messagesSubscription) {
        messagesSubscription.unsubscribe();
      }
    };
  }, [currentChatRoomID, dispatch]);

  // Load contact information
  // Load contact groups
  useEffect(() => {
    (async () => {
      if (!dispatch || !user || !isSignedIn) {
        return;
      }

      // Only load contact groups once
      await dispatch(fetchContactGroups(user));
    })();
  }, [dispatch, user, isSignedIn]);

  const Stack = createNativeStackNavigator<RootStackParamList>();
  return (
    <NativeBaseProvider theme={customTheme} config={Config}>
      {isReady ? (
        <NavigationContainer linking={linking}>
          <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={RootStackScreensEnum.Main}>
            <Stack.Screen
              name={RootStackScreensEnum.Main}
              component={MainStackScreen}
              options={{
                headerShown: false
              }}
            />
            <Stack.Screen name={RootStackScreensEnum.Auth} component={AuthStackScreen} options={{ headerShown: false }} />
          </Stack.Navigator>
        </NavigationContainer>
      ) : (
        <Box flex={1} justifyContent='center' style={{ alignItems: 'center' }}>
          <Spinner size={'lg'} />
        </Box>
      )}
    </NativeBaseProvider>
  );
};

const AppConfigure = () => {
  const connection = new Strophe.Connection('wss://xmpp.luanpham.vntek.org/ws');
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  const strophe = useStrophe({
    connection,
    handlers: [
      {
        handlerFunc: (iq) => {
          const complete = iq.querySelector('fin')?.getAttribute('complete');
          if (complete) {
            // if (complete === 'false') {
            //   dispatch(chatRoomContactActions.setBeforeMessageId(iq.querySelector('first')?.textContent || ''));
            //   dispatch(chatRoomContactActions.setMessages());
            //   dispatch(chatRoomContactActions.setMessagesComplete(true));
            // }
            dispatch(chatRoomContactActions.setMessages());
            dispatch(chatRoomContactActions.setMessagesComplete(true));
          }
          if (iq.querySelector('error')) {
            dispatch(chatRoomContactActions.setMessagesComplete(true));
          }
          const id = iq.getAttribute('id');
          const ping = stropheBuilder.iq({
            to: 'sip',
            type: 'result',
            id: id || ''
          });
          try {
            connection.send(ping);
          } catch (err: any) {
            console.log('error', err.message);
          }
          return true;
        },
        matcher: {
          namespace: '',
          name: 'iq'
        }
      }
    ],
    onMessageQuery: (message) => {
      if (message.querySelector('description')?.textContent === 'You have a meeting') {
        const data = message.querySelector('body')?.textContent;
        const dataJSON = JSON.parse(data || '');
        const { mcc_event } = dataJSON.data;
        if (mcc_event === '1') {
          if (!user) {
            return true;
          }
          try {
            const dataToGetMeeting = {
              vMeetId: user.sfWebServiceUserId,
              x: 745,
              user_secret: user.sfWebServiceUserSecret,
              recent_meeting: 1
            };
            axios
              .post('/meeting/create', dataToGetMeeting, {
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user.idToken}` }
              })
              .then((res) => {
                if (res.status === 201 && res.data && res.data.meetings) {
                  if (res.data.meetings.length > 0) {
                    const meeting = res.data.meetings[0];
                    if (`0${meeting.chairman_id}` !== user.sfWebServiceUserId && meeting.meeting_id) {
                      const meetingIdParam = `meeting_id=${meeting.meeting_id}`;
                      const uidParam = `uid=${user.sfWebServiceUserId}`;
                      const passwordParam = `password=${user.sfWebServicePassword}`;
                      const serverParam = `server=${Constants.expoConfig?.extra?.sfWebServiceUrl}`;
                      axios
                        .post(
                          `${Constants.expoConfig?.extra?.sfWebServiceUrl}/api/url.php?x=set&c=esfp://join?${encodeURIComponent(
                            `${meetingIdParam}&${uidParam}&${passwordParam}&${serverParam}`
                          )}`
                        )
                        .then((response) => {
                          if (response.data && response.data.success && response.data.url) {
                            window.open(
                              `${Constants.expoConfig?.extra?.meetingUrl}/meeting/?url=${encodeURIComponent(
                                response.data.url.replace('http', 'https')
                              )}`,
                              '_blank'
                            );
                          }
                        });
                    }
                  }
                }
              })
              .catch((err) => {
                console.log('err', err);
              });
          } catch (err) {
            console.log(err);
          }
        }
      }
      const from = message.getAttribute('from');
      if (message.hasChildNodes() && from !== 'service.sip') {
        const messageChild = message.querySelector('archived');
        if (messageChild) {
          const from = message.querySelector('message')?.getAttribute('from')?.split('@')[0];
          const bodyElement = message.querySelector('body');
          const content = bodyElement?.textContent;
          const timestamp = message.querySelector('delay')?.getAttribute('stamp');
          if (message.querySelector('result')) {
            dispatch(
              chatRoomContactActions.fetchMessagesFromXmppServer({
                id: generate_uuid(),
                text: content || '',
                createdAt: timestamp || new Date().toISOString(),
                from: from || ''
              })
            );
            dispatch(chatRoomContactActions.setMessagesComplete(false));
          } else {
            dispatch(
              chatRoomContactActions.addMessages({
                id: generate_uuid(),
                text: content || '',
                createdAt: timestamp || new Date().toISOString(),
                from: from || ''
              })
            );
          }
        }
      }
      return true;
    },
    onPresence: (message) => {
      console.log('onPresence', message);
      return true;
    }
  });
  return (
    <ConnectionProvider strophe={strophe}>
      <Root />
    </ConnectionProvider>
  );
};

const App = () => {
  const [loaded] = useFonts({
    Patrickhand_Regular: require('./src/assets/fonts/Patrickhand_Regular.ttf'),
    Sketch_Block: require('./src/assets/fonts/Sketch_Block.ttf'),
    NotoSans_Medium: require('./src/assets/fonts/NotoSans_Medium.ttf')
  });

  useEffect(() => {
    (async () => {
      await cornerstone3D.init();
      await cornerstone3DTools.init();
      await initDemo();
    })();
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <Provider store={store}>
      <AmplifyAuthProvider>
        <AppConfigure />
      </AmplifyAuthProvider>
    </Provider>
  );
};

export default App;
