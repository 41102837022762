import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  FormControl,
  Hidden,
  HStack,
  Icon,
  Image,
  Input,
  Link,
  Pressable,
  ScrollView,
  Text,
  VStack
} from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import GuestLayout from '../../layouts/GuestLayout';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RootStackScreensEnum } from '../../navigation/enum';
import { AuthStackParamList } from '../../types/Stacks';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

function MobileHeader() {
  return (
    <Hidden from='md'>
      <VStack px='4' mt='4' mb='8' space='9'>
        <HStack space='2' alignItems='center'></HStack>
        <VStack space={0.5}>
          <Text fontSize='4xl' fontWeight='bold' color='coolGray.50'>
            Create doctor account
          </Text>
          <Text
            fontSize='md'
            fontWeight='normal'
            _dark={{
              color: 'white'
            }}
            _light={{
              color: 'white'
            }}>
            Please enter your information to create an account
          </Text>
        </VStack>
      </VStack>
    </Hidden>
  );
}

function SideContainerWeb() {
  return (
    <Center
      flex='1'
      _light={{ bg: '#E0FFFF' }}
      _dark={{ bg: 'primary.700' }}
      borderTopLeftRadius={{ md: 'xl' }}
      borderBottomLeftRadius={{ md: 'xl' }}>
      <VStack alignItems='center'>
        <Image source={require('../../assets/logoApp.png')} size={400} alt='Alternate Text' />
      </VStack>
    </Center>
  );
}

const SignUpForm = () => {
  const [show, setShow] = useState(false);
  const { signUp } = useAuth();
  const navigation = useNavigation<NativeStackNavigationProp<AuthStackParamList, RootStackScreensEnum.SignUp>>();
  const { errorMessage, setErrorMessage, successMessage, setSuccessMessage } = useAuth();

  // Reset error message and success message when the component is unmounted
  useEffect(() => {
    return () => {
      setErrorMessage('');
      setSuccessMessage('');
    };
  }, [setErrorMessage, setSuccessMessage]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm password is required')
  });

  const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: ''
  };

  const onSubmit = async (values: any) => {
    const { email, firstName, lastName, password } = values;

    try {
      await signUp(email, password, firstName, lastName, 'Doctor');

      // Redirect to code verification page
      navigation.navigate(RootStackScreensEnum.OtpVerification, {
        email: email
      });
    } catch (err) {
      // Handle error
      console.log('Sign up error: ', err);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ touched, handleChange, handleBlur, handleSubmit, values, errors }) => (
        <VStack space={4}>
          {/*BEGIN: Success or error message. Success message in green, error message in red*/}
          {successMessage && (
            <Box bg='green.400' px={4} py={3} rounded='md' _text={{ color: 'white' }}>
              {successMessage}
            </Box>
          )}
          {errorMessage && (
            <Box bg='red.400' px={4} py={3} rounded='md' _text={{ color: 'white' }}>
              {errorMessage}
            </Box>
          )}
          {/* END: Success or error message */}
          <FormControl isInvalid={'firstName' in errors && touched.firstName}>
            <FormControl.Label>First Name</FormControl.Label>
            <Input
              onBlur={handleBlur('firstName')}
              placeholder='Enter your First Name'
              onChangeText={handleChange('firstName')}
              value={values.firstName}
            />
            <FormControl.ErrorMessage>{errors.firstName}</FormControl.ErrorMessage>
          </FormControl>

          <FormControl isInvalid={'lastName' in errors && touched.lastName}>
            <FormControl.Label>Last Name</FormControl.Label>
            <Input
              onBlur={handleBlur('lastName')}
              placeholder='Enter your Last Name'
              onChangeText={handleChange('lastName')}
              value={values.lastName}
            />
            <FormControl.ErrorMessage>{errors.lastName}</FormControl.ErrorMessage>
          </FormControl>

          <FormControl isInvalid={'email' in errors && touched.email}>
            <FormControl.Label>Email</FormControl.Label>
            <Input onBlur={handleBlur('email')} placeholder='Enter your email' onChangeText={handleChange('email')} value={values.email} />
            <FormControl.ErrorMessage>{errors.email}</FormControl.ErrorMessage>
          </FormControl>

          <FormControl isInvalid={'password' in errors && touched.password}>
            <FormControl.Label>Password</FormControl.Label>
            <Input
              onBlur={handleBlur('password')}
              placeholder='Enter your password'
              onChangeText={handleChange('password')}
              value={values.password}
              type={show ? 'text' : 'password'}
              InputRightElement={
                <Pressable onPress={() => setShow(!show)}>
                  <Icon as={<MaterialIcons name={show ? 'visibility' : 'visibility-off'} />} size={5} mr='2' color='muted.400' />
                </Pressable>
              }
            />
            <FormControl.ErrorMessage>{errors.password}</FormControl.ErrorMessage>
          </FormControl>

          <FormControl isInvalid={'confirmPassword' in errors && touched.confirmPassword}>
            <FormControl.Label>ConfirmPassword</FormControl.Label>
            <Input
              onBlur={handleBlur('confirmPassword')}
              placeholder='Enter your password'
              onChangeText={handleChange('confirmPassword')}
              value={values.confirmPassword}
              type={show ? 'text' : 'password'}
              InputRightElement={
                <Pressable onPress={() => setShow(!show)}>
                  <Icon as={<MaterialIcons name={show ? 'visibility' : 'visibility-off'} />} size={5} mr='2' color='muted.400' />
                </Pressable>
              }
            />
            <FormControl.ErrorMessage>{errors.confirmPassword}</FormControl.ErrorMessage>
          </FormControl>
          <Box marginY='3'>
            <Checkbox
              _dark={{
                value: 'demo',
                _checked: {
                  value: 'demo',
                  bg: 'primary.700',
                  borderColor: 'primary.700',
                  _icon: { color: 'white' }
                }
              }}
              _light={{
                value: 'demo',
                _checked: {
                  value: 'demo',
                  bg: 'primary.900',
                  borderColor: 'primary.900'
                }
              }}
              defaultIsChecked
              value='demo'
              accessibilityLabel='Remember me'>
              <HStack alignItems='center'>
                <Text fontSize='sm' fontWeight='normal' _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.400' }} pl='2'>
                  I accept the{' '}
                </Text>
                <Link
                  href='https://nativebase.io'
                  _text={{
                    fontSize: 'sm',
                    fontWeight: 'bold',
                    textDecoration: 'none'
                  }}
                  _light={{
                    _text: {
                      color: '#66CDAA'
                    }
                  }}
                  _dark={{
                    _text: {
                      color: 'primary.500',
                      fontSize: 'sm',
                      fontWeight: 'medium'
                    }
                  }}>
                  Terms of Use{' '}
                </Link>
                <Text fontSize='sm' _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.300' }}>
                  &{' '}
                </Text>
                <Link
                  href='https://nativebase.io'
                  _text={{
                    fontSize: 'sm',
                    fontWeight: 'bold',
                    textDecoration: 'none'
                  }}
                  _light={{
                    _text: {
                      color: '#66CDAA'
                    }
                  }}
                  _dark={{
                    _text: {
                      color: 'primary.500'
                    }
                  }}>
                  Privacy Policy
                </Link>
              </HStack>
            </Checkbox>
          </Box>
          <Button
            onPress={() => handleSubmit()}
            borderRadius='15'
            bg='#66CDAA'
            _text={{ color: 'white', fontSize: 'lg', fontWeight: 'bold', fontFamily: 'Patrickhand_Regular' }}>
            SIGN UP
          </Button>
        </VStack>
      )}
    </Formik>
  );
};

function SignUpComponent() {
  const navigation = useNavigation<NativeStackNavigationProp<AuthStackParamList, RootStackScreensEnum.SignUp>>();

  return (
    // <KeyboardAwareScrollView
    //   contentContainerStyle={{
    //     flexGrow: 1
    //   }}
    //   style={{ width: '100%', height: '100%' }}
    //   bounces={false}
    // >
    <>
      <MobileHeader />
      <Box
        flex='1'
        px={{ base: 4, md: 8 }}
        py='8'
        _light={{ bg: 'white' }}
        _dark={{ bg: 'coolGray.800' }}
        justifyContent='space-between'
        borderTopRightRadius={{ base: '2xl', md: 'xl' }}
        borderBottomRightRadius={{ base: '0', md: 'xl' }}
        borderTopLeftRadius={{ base: '2xl', md: '0' }}>
        <Text
          textAlign='center'
          fontSize='5xl'
          fontWeight='bold'
          fontFamily='Patrickhand_Regular'
          _light={{ color: '#66CDAA' }}
          _dark={{ color: 'coolGray.50' }}
          mb={'8'}>
          Sign Up
        </Text>
        <SignUpForm />
        <HStack space='2' mt={{ base: 2, md: 4 }} alignItems='center' justifyContent='center'>
          <Divider w='30%' _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }} />
          <Text
            fontWeight='medium'
            _light={{
              color: 'coolGray.400'
            }}
            _dark={{
              color: 'coolGray.300'
            }}>
            or
          </Text>
          <Divider w='30%' _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'coolGray.700' }} />
        </HStack>
        <HStack space='4' mt={{ base: 4, md: 4 }} mb={{ base: 4, md: 6 }} alignItems='center' justifyContent='center'>
          <Link href='https://nativebase.io'>
            <Image width='6' height='6' source={require('../../assets/facebook.png')} alt='Alternate Text' />
          </Link>
          <Link href='https://nativebase.io'>
            <Image width='6' height='6' source={require('../../assets/GoogleLogo.png')} alt='Alternate Text' />
          </Link>
        </HStack>
        <HStack space='1' alignItems='center' justifyContent='center' mb={{ base: 8 }}>
          <Text fontSize='sm' color='coolGray.500' fontWeight='normal' _dark={{ color: 'coolGray.400' }}>
            Already have an account?
          </Text>
          <Pressable onPress={() => navigation.replace(RootStackScreensEnum.SignIn)}>
            <Link
              _text={{
                fontSize: { base: 'sm', md: 'xs' },
                fontWeight: 'bold',
                textDecoration: 'none'
              }}
              _light={{
                _text: {
                  color: '#66CDAA'
                }
              }}
              _dark={{
                _text: {
                  color: 'primary.500'
                }
              }}>
              Sign In
            </Link>
          </Pressable>
        </HStack>
      </Box>
    </>
  );
}

export default function SignUpDoctor() {
  return (
    <ScrollView w={{ base: '100%', md: '100%' }} h={{ base: '100%', md: '100%' }}>
      <GuestLayout>
        <Hidden till='md'>
          <SideContainerWeb />
        </Hidden>
        <Box flex='1' width={'100%'} h={{ base: 1000, md: 1000 }}>
          <SignUpComponent />
        </Box>
      </GuestLayout>
    </ScrollView>
  );
}
