import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { IAuthUser } from './IAuthUser';
import { sfWebServiceGetMeetingList, sfWebServiceLogin } from '../../utils/web_service/sf_web_service_utils';
import { getSFUserByUserId } from '../../utils/graphql/sf_user_utils';
import { useConnection } from '../xmpp/ConnectionProvider';
import Roles from './role';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useInterval } from 'usehooks-ts';
import { Platform } from 'react-native';

interface IAuthContext {
  isReady: boolean;
  isSignedIn: boolean;
  user: IAuthUser | null;
  errorMessage: string;
  setErrorMessage: (message: string) => void;
  successMessage: string;
  setSuccessMessage: (message: string) => void;
  signIn: (email: string, password: string) => Promise<IAuthUser | null>;
  signUp: (email: string, password: string, firstName: string, lastName: string, group?: string) => Promise<void>;
  signOut: () => Promise<void>;
  codeVerify: (code: string, email: string) => Promise<void>;
  resendSignUp: (email: string) => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  forgotPasswordSubmit: (email: string, code: string, password: string) => Promise<void>;
  isAdmin: () => Promise<boolean>;
  checkRole: () => Promise<string>;
}

const AuthContext = createContext<IAuthContext>({
  isReady: false,
  isSignedIn: false,
  user: null,
  errorMessage: '',
  setErrorMessage: () => {
    console.warn('setErrorMessage not implemented');
  },
  successMessage: '',
  setSuccessMessage: () => {
    console.warn('setSuccessMessage not implemented');
  },
  signIn: () => {
    console.warn('signIn not implemented');
    return Promise.resolve(null);
  },
  signUp: () => {
    console.warn('signUp not implemented');
    return Promise.resolve();
  },
  signOut: () => {
    console.warn('signOut not implemented');
    return Promise.resolve();
  },
  resendSignUp: () => {
    console.warn('resendLink not implemented');
    return Promise.resolve();
  },
  codeVerify: () => {
    console.warn('codeVerify not implemented');
    return Promise.resolve();
  },
  changePassword: () => {
    console.warn('changePassword not implemented');
    return Promise.resolve();
  },
  forgotPassword: () => {
    console.warn('forgotPassword not implemented');
    return Promise.resolve();
  },
  forgotPasswordSubmit: () => {
    console.warn('forgotPasswordSubmit not implemented');
    return Promise.resolve();
  },
  isAdmin: () => {
    console.warn('isAdmin not implemented');
    return Promise.resolve(false);
  },
  checkRole: () => {
    console.warn('checkRole not implemented');
    return Promise.resolve('');
  }
});

interface IAmplifyAuthProviderProps {
  children: React.ReactNode;
}

// Wrap your app with <AmplifyAuthProvider />
export const AmplifyAuthProvider = ({ children }: IAmplifyAuthProviderProps) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

// Access auth values and functions with custom useAuth hook
export const useAuth = () => useContext(AuthContext);

const useProvideAuth = () => {
  const [user, setUser] = useState<IAuthUser | null>(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [sfLoginDelay, setSfLoginDelay] = useState<number | null>(null);
  const [needLoggingInToSFWebService, setNeedLoggingInToSFWebService] = useState(false);
  const [sfWebServiceDecryptedStr, setSfWebServiceDecryptedStr] = useState('');

  useInterval(() => {
    // Check if we need to login to SF web service
    if (needLoggingInToSFWebService) {
      // @ts-ignore
      if (Platform.OS === 'web' && window.sfLogin2) {
        // Login to SF web service
        // @ts-ignore
        if (sfWebServiceDecryptedStr) {
          // @ts-ignore
          window.sfLogin2(sfWebServiceDecryptedStr);
        }
        setNeedLoggingInToSFWebService(false);
        setSfLoginDelay(null);
      }
    } else {
      // Stop the interval
      setSfLoginDelay(null);
    }
  }, sfLoginDelay);

  const updateUsersAfterAuth = useCallback(async (session: CognitoUserSession, successLogMessage: string) => {
    // Query the SF user corresponding to the signed-in user
    const correspondingSFUser = await getSFUserByUserId(session.getIdToken().payload.sub);
    if (correspondingSFUser?.userName && correspondingSFUser.password) {
      try {
        // Login to SF web service
        const { sfAccessToken, decodedAndDecryptedStr } = await sfWebServiceLogin(correspondingSFUser.userName, correspondingSFUser.password);

        // Define your user schema per your needs
        const newUser: IAuthUser = {
          id: session.getIdToken().payload.sub,
          email: session.getIdToken().payload.email,
          firstName: session.getIdToken().payload.name,
          lastName: session.getIdToken().payload.family_name,
          accessToken: session.getAccessToken().getJwtToken(),
          idToken: session.getIdToken().getJwtToken(),
          sfWebServiceToken: sfAccessToken,
          sfWebServiceUserId: correspondingSFUser.userName,
          sfWebServicePassword: correspondingSFUser.password,
          sfWebServiceUserSecret: JSON.parse(decodedAndDecryptedStr).token,
          avatar: 'assets/images/avatars/male-01.jpg',
          status: 'online'
        };

        const a = sfWebServiceGetMeetingList(JSON.parse(decodedAndDecryptedStr).vmeet_id, sfAccessToken);
        a.then((res) => {
          console.log('res', res);
        });

        if (Platform.OS === 'web') {
          console.log(JSON.parse(decodedAndDecryptedStr));
          // Login to SF web service
          setSfWebServiceDecryptedStr(decodedAndDecryptedStr);
          setNeedLoggingInToSFWebService(true);
          setSfLoginDelay(1000);
        }

        if (successLogMessage) {
          console.log(successLogMessage);
        }

        setIsSignedIn(true);
        setIsReady(true);
        setUser(newUser);
        setErrorMessage('');
        setSuccessMessage('');
      } catch (error) {
        console.log(error);
        setIsSignedIn(false);
        setIsReady(true);
        setUser(null);
        setErrorMessage('Cannot login to SF web service');
        setSuccessMessage('');
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const session = await Auth.currentSession();
        await updateUsersAfterAuth(session, 'Session updated successfully');
      } catch (error) {
        // Maybe the refresh token expired, so we need to sign out
        await signOut();
        console.log('Session expired, signed out successfully');
      }
    })();
  }, []);

  useInterval(async () => {
    // Refresh the user's session
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      if (cognitoUser) {
        const currentSession = cognitoUser.getSignInUserSession();
        cognitoUser.refreshSession(currentSession.refreshToken, (err: any, session: CognitoUserSession) => {
          if (err) {
            console.log('Error refreshing session: ', err);
            return;
          }

          if (!session) {
            console.log('No session returned');
            return;
          }

          // Define your user schema per your needs
          updateUsersAfterAuth(session, 'Session refreshed successfully');
        });
      }
    } catch (err) {
      console.log('Error refreshing session', err);

      // Maybe the refresh token expired, so we need to sign out
      await signOut();
      console.log('Session expired, signed out successfully');
    }
  }, 30 * 60 * 1000); // 30 minutes

  const signIn = async (email: string, password: string) => {
    try {
      const cognitoUser = await Auth.signIn(email, password);

      // Set user data and access token to memory
      const {
        attributes,
        signInUserSession: { accessToken, idToken }
      } = cognitoUser;

      // Query the SF user corresponding to the signed-in user
      const correspondingSFUser = await getSFUserByUserId(attributes.sub);
      if (correspondingSFUser && correspondingSFUser.userName && correspondingSFUser.password) {
        // Login to SF web service
        const { sfAccessToken, decodedAndDecryptedStr } = await sfWebServiceLogin(correspondingSFUser.userName, correspondingSFUser.password);

        // Define your user schema per your needs
        const newUser: IAuthUser = {
          id: attributes.sub,
          email: attributes.email,
          firstName: attributes.name,
          lastName: attributes.family_name,
          accessToken: accessToken.getJwtToken(),
          idToken: idToken.getJwtToken(),
          sfWebServiceUserId: correspondingSFUser.userName,
          sfWebServiceToken: sfAccessToken,
          sfWebServicePassword: correspondingSFUser.password,
          sfWebServiceUserSecret: JSON.parse(decodedAndDecryptedStr).token,
          avatar: 'assets/images/avatars/male-01.jpg',
          status: 'online'
        };

        if (Platform.OS === 'web') {
          console.log(JSON.parse(decodedAndDecryptedStr));

          // Login to SF web service
          setSfWebServiceDecryptedStr(decodedAndDecryptedStr);
          setNeedLoggingInToSFWebService(true);
          setSfLoginDelay(1000);
        }

        setIsSignedIn(true);
        setIsReady(true);
        setUser(newUser);

        console.log('Logged in successfully');
        setErrorMessage('');
        setSuccessMessage('Logged in successfully');

        return newUser;
      } else {
        console.log('No SF user found for this Cognito user');
        setErrorMessage('No SF user found for this Cognito user');
        setSuccessMessage('');
        return null;
      }
    } catch (err: any) {
      switch (err.code) {
        case 'UserNotConfirmedException':
          setErrorMessage('You must confirm your email address before you can log in.');
          setSuccessMessage('');
          break;
        case 'NotAuthorizedException':
        case 'UserNotFoundException':
          setErrorMessage('The username or password is incorrect.');
          setSuccessMessage('');
          break;
        default:
          setErrorMessage('An unexpected error occurred.');
          setSuccessMessage('');
          break;
      }

      return null;
    }
  };

  const signUp = async (email: string, password: string, firstName: string, lastName: string, group?: string) => {
    try {
      const result = await Auth.signUp({
        username: email,
        password,
        attributes: {
          name: firstName,
          family_name: lastName,
          email: email,
          'custom:group': group ? group : 'Patient'
        }
      });

      console.log('signUp result', result);

      const user = {
        id: result.userSub,
        email,
        firstName,
        lastName,
        accessToken: '',
        idToken: '',
        sfWebServiceToken: '',
        sfWebServiceUserId: '',
        sfWebServicePassword: '',
        sfWebServiceUserSecret: '',
        avatar: '',
        status: ''
      };

      // Need to wait for confirmation
      setIsSignedIn(false);
      setIsReady(true);
      setUser(user);

      setSuccessMessage('Account created successfully, please confirm your email');
      setErrorMessage('');
    } catch (err: any) {
      switch (err.code) {
        case 'UsernameExistsException':
          setErrorMessage('An account with this email address already exists.');
          setSuccessMessage('');
          break;
        default:
          setErrorMessage('Sign up failed, please try again.');
          setSuccessMessage('');
          break;
      }

      // Throw error to parent component
      throw err;
    }
  };

  const signOut = async () => {
    // Sign out of Amplify
    await Auth.signOut();

    // Clear auth state
    setIsSignedIn(false);
    setIsReady(true);
    setUser(null);
  };
  const resendSignUp = async (email: string) => {
    try {
      await Auth.resendSignUp(email);
      setSuccessMessage('Confirmation email resent successfully');
      setErrorMessage('');
    } catch (err: any) {
      console.error('Error resending code: ', err);
      setErrorMessage('Error resending code');
      setSuccessMessage('');

      // Throw error to parent component
      throw err;
    }
  };
  const codeVerify = async (code: string, email: string) => {
    try {
      console.log('Verifying code, user email: ', email, 'code: ', code);
      await Auth.confirmSignUp(email, code);
      setSuccessMessage('Account confirmed successfully');
      setErrorMessage('');
    } catch (err) {
      console.error('Error confirming sign up', err);
      setErrorMessage('Error confirming sign up');
      setSuccessMessage('');

      // Throw error to parent component
      throw err;
    }
  };
  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, oldPassword, newPassword);
      setSuccessMessage('Password changed successfully');
      setErrorMessage('');
    } catch (err: any) {
      switch (err.code) {
        case 'NotAuthorizedException':
          setErrorMessage('The old password is incorrect.');
          setSuccessMessage('');
          break;
        default:
          setErrorMessage('Password change failed, please try again.');
          setSuccessMessage('');
          break;
      }

      // Throw error to parent component
      throw err;
    }
  };
  const forgotPassword = async (email: string) => {
    try {
      await Auth.forgotPassword(email);
      setSuccessMessage('Password reset code sent successfully');
      setErrorMessage('');
    } catch (err) {
      console.error('Error sending password reset code', err);
      setErrorMessage('Error sending password reset code');
      setSuccessMessage('');

      // Throw error to parent component
      throw err;
    }
  };

  const forgotPasswordSubmit = async (email: string, code: string, password: string) => {
    try {
      console.log('Resetting password, user email: ', email, 'code: ', code, 'password: ', password);
      await Auth.forgotPasswordSubmit(email, code, password);
      setSuccessMessage('Password reset successfully');
      setErrorMessage('');
    } catch (err) {
      console.error('Error resetting password', err);
      setErrorMessage('Error resetting password');
      setSuccessMessage('');

      // Throw error to parent component
      throw err;
    }
  };

  // Check if the current user is in Admin group
  const isAdmin = async () => {
    if (!isSignedIn) {
      return false;
    }

    const currentUser = await Auth.currentAuthenticatedUser();
    const groups = currentUser.signInUserSession.accessToken.payload['cognito:groups'];
    return groups && groups.includes('Admin');
  };

  // Check role
  const checkRole = async () => {
    if (!isSignedIn) {
      return false;
    }

    const currentUser = await Auth.currentAuthenticatedUser();
    const groups = currentUser.signInUserSession.accessToken.payload['cognito:groups'];
    if (groups && groups.length === 1) {
      return groups[0];
    }
    if (groups && groups.length > 1 && groups.includes(Roles.Admin)) {
      return Roles.Admin;
    }
    return '';
  };

  return {
    user,
    isReady,
    isSignedIn,
    errorMessage,
    setErrorMessage,
    successMessage,
    setSuccessMessage,
    signIn,
    signUp,
    signOut,
    resendSignUp,
    codeVerify,
    changePassword,
    forgotPassword,
    forgotPasswordSubmit,
    isAdmin,
    checkRole
  };
};
