import React from 'react';
import Title from './Title';
import { Box, Button, FlatList, HStack, Icon, Image, Text, VStack } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import { ImageSourcePropType } from 'react-native';

export type Patient = {
  id: number;
  name: string;
  imageUri: ImageSourcePropType;
  isOnline: boolean;
  disease?: string;
  age?: string;
  status?: string;
};

const patients: Patient[] = [
  {
    id: 1,
    name: 'Thomas Edison',
    imageUri: {
      uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.43034024544922556.jpg'
    },
    isOnline: false,
    disease: 'Sleep Problem',
    age: '32 years old',
    status: 'Pending'
  },
  {
    id: 2,
    name: 'Rose George ',
    imageUri: require('../assets/Rose.png'),
    isOnline: true,
    disease: 'Sleep Problem',
    age: '28 years old',
    status: 'On Recovery'
  },
  {
    id: 3,
    name: 'Samantha Smith',
    imageUri: {
      uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.40549212462773987.jpg'
    },
    isOnline: true,
    disease: 'Sleep Problem',
    age: '22 years old',
    status: 'Rejected'
  },
  {
    id: 4,
    name: 'John Hallan',
    imageUri: {
      uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.5295602336621994.jpg'
    },
    isOnline: true,
    disease: 'Sleep Problem',
    age: '29 years old',
    status: 'Recovered'
  },
  {
    id: 5,
    name: 'Steven Jobs',
    imageUri: require('../assets/Steven.png'),
    isOnline: false,
    disease: 'Sleep Problem',
    age: '30 years old',
    status: 'On Recovery'
  }
];

const PatientChatAdd = () => {
  return (
    <>
      <Title title='Add New Chat With Patient' subTitle='Consult online with your patient' hasSeeAllButton={true} />
      <FlatList
        data={patients}
        showsVerticalScrollIndicator={true}
        renderItem={({ item }: { item: Patient }) => {
          return <Card patient={item} />;
        }}
        ItemSeparatorComponent={() => <Box h={{ base: '8', md: '12' }} />}
      />
    </>
  );
};

const Card = ({ patient }: { patient: Patient }) => {
  return (
    <HStack>
      <HStack mr={{ base: '4', md: '8' }}>
        <Box h='40' overflow='hidden' rounded='lg' w='32' position='relative' borderWidth={1} borderColor={'gray.200'}>
          <Box
            w='10px'
            h='10px'
            bg={patient.isOnline ? 'green.600' : 'gray.400'}
            position='absolute'
            top='2'
            right='2'
            zIndex='100'
            rounded='full'></Box>
          <Image flex='1' source={patient.imageUri} alt='Alternate Text' />
        </Box>
      </HStack>

      <HStack flex='1' justifyContent='space-between' flexWrap='wrap'>
        <VStack>
          <Text fontWeight='bold' fontSize='md'>
            {patient.name}
          </Text>
          <Text color={'#369DC9'}>{patient.disease}</Text>
          <HStack mt='2' mb='3'>
            <HStack style={{ alignItems: 'center' }} bg='gray.100' px='3' py='1' mr='2' rounded='md'>
              <Icon as={FontAwesome} name='plus' size='4' mr='2' color='gray.700' />
              <Text fontWeight='medium'>{patient.age}</Text>
            </HStack>
          </HStack>
          <VStack justifyContent='flex-end' mt='4'>
            <Text fontWeight='bold' color={'coolGray.500'}>
              {patient.status}
            </Text>
          </VStack>
        </VStack>

        <VStack justifyContent='flex-end' mt='3'>
          <Button variant='ghost' py='1' px='10' borderWidth={1} borderColor='blue.500' rounded='md' bg='secondary.800'>
            <Text color='white'>Chat</Text>
          </Button>
        </VStack>
      </HStack>
    </HStack>
  );
};

export default PatientChatAdd;
