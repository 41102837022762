/**
 * Authorization Roles
 */
enum Roles {
  Admin = 'Admin',
  Doctor = 'Doctor',
  Patient = 'Patient'
}

export default Roles;
