import React, { useEffect } from 'react';
import { Box, Text, VStack } from 'native-base';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import { useNavigation } from '@react-navigation/native';
import { RootStackScreensEnum } from '../../navigation/enum';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { AuthStackParamList } from '../../types/Stacks';

function Logout() {
  const navigation = useNavigation<NativeStackNavigationProp<AuthStackParamList, RootStackScreensEnum.SignIn>>();
  const onLogout = () => {
    navigation.replace(RootStackScreensEnum.SignIn);
  };
  const { signOut } = useAuth();
  useEffect(() => {
    (async () => {
      await signOut();
      onLogout();
    })();
  }, [signOut, onLogout]);

  return (
    <Box>
      <VStack backgroundColor={'#66CDAA'} space={2} style={{ alignItems: 'center' }}>
        <Text fontWeight={'bold'} color={'white'} fontSize='3xl'>
          Logging out...
        </Text>
      </VStack>
    </Box>
  );
}

export default Logout;
