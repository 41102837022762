import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook } from 'react-redux';
import chatReducers from './chat';
import contactReducers from './contact';
import hospitalReducers from './hospital';
import agentsSlice from './agents/agents-slice';

const store = configureStore({
  reducer: {
    chat: chatReducers,
    contact: contactReducers,
    hospital: hospitalReducers,
    agents: agentsSlice.reducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppSelector = TypedUseSelectorHook<RootState>;

// Simple Thunk type
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

export default store;
