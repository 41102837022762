import { User } from '../API';
// import { cloneObject } from './object_clone';
// import { keys } from 'ts-transformer-keys';

export type IUser = Omit<
  User,
  'updatedAt' | 'owner' | '_version' | '_deleted' | '_lastChangedAt' | '__typename' | 'sFUser' | 'doctor' | 'chatRooms' | 'messages'
>;

export const cloneUser = (user: User): IUser => {
  return {
    id: user.id,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
    imageUrl: user.imageUrl,
    address: user.address,
    birthday: user.birthday,
    createdAt: user.createdAt,
    gender: user.gender,
    userDoctorId: user.userDoctorId,
    userSFUserId: user.userSFUserId
  };
  // return cloneObject<User, IUser>(user, keys<User>(), keys<IUser>());
};
