/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMedicine = /* GraphQL */ `
  mutation CreateMedicine(
    $input: CreateMedicineInput!
    $condition: ModelMedicineConditionInput
  ) {
    createMedicine(input: $input, condition: $condition) {
      id
      name
      description
      prescriptions {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMedicine = /* GraphQL */ `
  mutation UpdateMedicine(
    $input: UpdateMedicineInput!
    $condition: ModelMedicineConditionInput
  ) {
    updateMedicine(input: $input, condition: $condition) {
      id
      name
      description
      prescriptions {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMedicine = /* GraphQL */ `
  mutation DeleteMedicine(
    $input: DeleteMedicineInput!
    $condition: ModelMedicineConditionInput
  ) {
    deleteMedicine(input: $input, condition: $condition) {
      id
      name
      description
      prescriptions {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPrescription = /* GraphQL */ `
  mutation CreatePrescription(
    $input: CreatePrescriptionInput!
    $condition: ModelPrescriptionConditionInput
  ) {
    createPrescription(input: $input, condition: $condition) {
      id
      instruction
      quantity
      medicalRecordID
      medicalRecord {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      medicineID
      medicine {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePrescription = /* GraphQL */ `
  mutation UpdatePrescription(
    $input: UpdatePrescriptionInput!
    $condition: ModelPrescriptionConditionInput
  ) {
    updatePrescription(input: $input, condition: $condition) {
      id
      instruction
      quantity
      medicalRecordID
      medicalRecord {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      medicineID
      medicine {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePrescription = /* GraphQL */ `
  mutation DeletePrescription(
    $input: DeletePrescriptionInput!
    $condition: ModelPrescriptionConditionInput
  ) {
    deletePrescription(input: $input, condition: $condition) {
      id
      instruction
      quantity
      medicalRecordID
      medicalRecord {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      medicineID
      medicine {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMedicalRecord = /* GraphQL */ `
  mutation CreateMedicalRecord(
    $input: CreateMedicalRecordInput!
    $condition: ModelMedicalRecordConditionInput
  ) {
    createMedicalRecord(input: $input, condition: $condition) {
      id
      timestamp
      status
      pulseRate
      bloodPressure
      bodyTemperature
      respiratoryRate
      symptoms
      diagnosis
      dischargeInstruction
      billingInfo
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patientID
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      prescriptions {
        nextToken
        startedAt
      }
      testResults {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMedicalRecord = /* GraphQL */ `
  mutation UpdateMedicalRecord(
    $input: UpdateMedicalRecordInput!
    $condition: ModelMedicalRecordConditionInput
  ) {
    updateMedicalRecord(input: $input, condition: $condition) {
      id
      timestamp
      status
      pulseRate
      bloodPressure
      bodyTemperature
      respiratoryRate
      symptoms
      diagnosis
      dischargeInstruction
      billingInfo
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patientID
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      prescriptions {
        nextToken
        startedAt
      }
      testResults {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMedicalRecord = /* GraphQL */ `
  mutation DeleteMedicalRecord(
    $input: DeleteMedicalRecordInput!
    $condition: ModelMedicalRecordConditionInput
  ) {
    deleteMedicalRecord(input: $input, condition: $condition) {
      id
      timestamp
      status
      pulseRate
      bloodPressure
      bodyTemperature
      respiratoryRate
      symptoms
      diagnosis
      dischargeInstruction
      billingInfo
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patientID
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      prescriptions {
        nextToken
        startedAt
      }
      testResults {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTestResult = /* GraphQL */ `
  mutation CreateTestResult(
    $input: CreateTestResultInput!
    $condition: ModelTestResultConditionInput
  ) {
    createTestResult(input: $input, condition: $condition) {
      id
      timestamp
      testType
      description
      result
      images
      medicalRecordID
      medicalRecord {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTestResult = /* GraphQL */ `
  mutation UpdateTestResult(
    $input: UpdateTestResultInput!
    $condition: ModelTestResultConditionInput
  ) {
    updateTestResult(input: $input, condition: $condition) {
      id
      timestamp
      testType
      description
      result
      images
      medicalRecordID
      medicalRecord {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTestResult = /* GraphQL */ `
  mutation DeleteTestResult(
    $input: DeleteTestResultInput!
    $condition: ModelTestResultConditionInput
  ) {
    deleteTestResult(input: $input, condition: $condition) {
      id
      timestamp
      testType
      description
      result
      images
      medicalRecordID
      medicalRecord {
        id
        timestamp
        status
        pulseRate
        bloodPressure
        bodyTemperature
        respiratoryRate
        symptoms
        diagnosis
        dischargeInstruction
        billingInfo
        doctorID
        patientID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      id
      checkInTime
      status
      ethnicity
      healthHistory
      allergies
      immunizations
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      patientUserId
      doctors {
        nextToken
        startedAt
      }
      diseases {
        nextToken
        startedAt
      }
      medicalRecords {
        nextToken
        startedAt
      }
      appointments {
        nextToken
        startedAt
      }
      reviews {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $input: UpdatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    updatePatient(input: $input, condition: $condition) {
      id
      checkInTime
      status
      ethnicity
      healthHistory
      allergies
      immunizations
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      patientUserId
      doctors {
        nextToken
        startedAt
      }
      diseases {
        nextToken
        startedAt
      }
      medicalRecords {
        nextToken
        startedAt
      }
      appointments {
        nextToken
        startedAt
      }
      reviews {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deletePatient = /* GraphQL */ `
  mutation DeletePatient(
    $input: DeletePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    deletePatient(input: $input, condition: $condition) {
      id
      checkInTime
      status
      ethnicity
      healthHistory
      allergies
      immunizations
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      patientUserId
      doctors {
        nextToken
        startedAt
      }
      diseases {
        nextToken
        startedAt
      }
      medicalRecords {
        nextToken
        startedAt
      }
      appointments {
        nextToken
        startedAt
      }
      reviews {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createDoctor = /* GraphQL */ `
  mutation CreateDoctor(
    $input: CreateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    createDoctor(input: $input, condition: $condition) {
      id
      specialist
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      doctorUserId
      patients {
        nextToken
        startedAt
      }
      medicalRecords {
        nextToken
        startedAt
      }
      departmentID
      department {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      workingTimeSpans {
        nextToken
        startedAt
      }
      appointments {
        nextToken
        startedAt
      }
      reviews {
        nextToken
        startedAt
      }
      introduction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDoctor = /* GraphQL */ `
  mutation UpdateDoctor(
    $input: UpdateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    updateDoctor(input: $input, condition: $condition) {
      id
      specialist
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      doctorUserId
      patients {
        nextToken
        startedAt
      }
      medicalRecords {
        nextToken
        startedAt
      }
      departmentID
      department {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      workingTimeSpans {
        nextToken
        startedAt
      }
      appointments {
        nextToken
        startedAt
      }
      reviews {
        nextToken
        startedAt
      }
      introduction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDoctor = /* GraphQL */ `
  mutation DeleteDoctor(
    $input: DeleteDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    deleteDoctor(input: $input, condition: $condition) {
      id
      specialist
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      doctorUserId
      patients {
        nextToken
        startedAt
      }
      medicalRecords {
        nextToken
        startedAt
      }
      departmentID
      department {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      workingTimeSpans {
        nextToken
        startedAt
      }
      appointments {
        nextToken
        startedAt
      }
      reviews {
        nextToken
        startedAt
      }
      introduction
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      rating
      comment
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      patientID
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      rating
      comment
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      patientID
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      rating
      comment
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      patientID
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDepartment = /* GraphQL */ `
  mutation CreateDepartment(
    $input: CreateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    createDepartment(input: $input, condition: $condition) {
      id
      name
      description
      doctors {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDepartment = /* GraphQL */ `
  mutation UpdateDepartment(
    $input: UpdateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    updateDepartment(input: $input, condition: $condition) {
      id
      name
      description
      doctors {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDepartment = /* GraphQL */ `
  mutation DeleteDepartment(
    $input: DeleteDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    deleteDepartment(input: $input, condition: $condition) {
      id
      name
      description
      doctors {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createWorkingTimeSpan = /* GraphQL */ `
  mutation CreateWorkingTimeSpan(
    $input: CreateWorkingTimeSpanInput!
    $condition: ModelWorkingTimeSpanConditionInput
  ) {
    createWorkingTimeSpan(input: $input, condition: $condition) {
      id
      dayOfWeek
      date
      hours
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateWorkingTimeSpan = /* GraphQL */ `
  mutation UpdateWorkingTimeSpan(
    $input: UpdateWorkingTimeSpanInput!
    $condition: ModelWorkingTimeSpanConditionInput
  ) {
    updateWorkingTimeSpan(input: $input, condition: $condition) {
      id
      dayOfWeek
      date
      hours
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteWorkingTimeSpan = /* GraphQL */ `
  mutation DeleteWorkingTimeSpan(
    $input: DeleteWorkingTimeSpanInput!
    $condition: ModelWorkingTimeSpanConditionInput
  ) {
    deleteWorkingTimeSpan(input: $input, condition: $condition) {
      id
      dayOfWeek
      date
      hours
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment(
    $input: CreateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    createAppointment(input: $input, condition: $condition) {
      id
      date
      hour
      order
      description
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patientID
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment(
    $input: UpdateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    updateAppointment(input: $input, condition: $condition) {
      id
      date
      hour
      order
      description
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patientID
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment(
    $input: DeleteAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    deleteAppointment(input: $input, condition: $condition) {
      id
      date
      hour
      order
      description
      doctorID
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patientID
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDisease = /* GraphQL */ `
  mutation CreateDisease(
    $input: CreateDiseaseInput!
    $condition: ModelDiseaseConditionInput
  ) {
    createDisease(input: $input, condition: $condition) {
      id
      name
      description
      patients {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDisease = /* GraphQL */ `
  mutation UpdateDisease(
    $input: UpdateDiseaseInput!
    $condition: ModelDiseaseConditionInput
  ) {
    updateDisease(input: $input, condition: $condition) {
      id
      name
      description
      patients {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDisease = /* GraphQL */ `
  mutation DeleteDisease(
    $input: DeleteDiseaseInput!
    $condition: ModelDiseaseConditionInput
  ) {
    deleteDisease(input: $input, condition: $condition) {
      id
      name
      description
      patients {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      timestamp
      text
      translatedText
      image
      isDefaultMessage
      completionTokens
      chatRoomID
      chatRoom {
        id
        chatRoomName
        lastMessageText
        lastMessageTimestamp
        userID
        agentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      ownerUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      timestamp
      text
      translatedText
      image
      isDefaultMessage
      completionTokens
      chatRoomID
      chatRoom {
        id
        chatRoomName
        lastMessageText
        lastMessageTimestamp
        userID
        agentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      ownerUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      timestamp
      text
      translatedText
      image
      isDefaultMessage
      completionTokens
      chatRoomID
      chatRoom {
        id
        chatRoomName
        lastMessageText
        lastMessageTimestamp
        userID
        agentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      ownerUserID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createChatRoom = /* GraphQL */ `
  mutation CreateChatRoom(
    $input: CreateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    createChatRoom(input: $input, condition: $condition) {
      id
      chatRoomName
      lastMessageText
      lastMessageTimestamp
      messages {
        nextToken
        startedAt
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChatRoom = /* GraphQL */ `
  mutation UpdateChatRoom(
    $input: UpdateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    updateChatRoom(input: $input, condition: $condition) {
      id
      chatRoomName
      lastMessageText
      lastMessageTimestamp
      messages {
        nextToken
        startedAt
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteChatRoom = /* GraphQL */ `
  mutation DeleteChatRoom(
    $input: DeleteChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    deleteChatRoom(input: $input, condition: $condition) {
      id
      chatRoomName
      lastMessageText
      lastMessageTimestamp
      messages {
        nextToken
        startedAt
      }
      userID
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      agentID
      agent {
        id
        name
        prompt
        model
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSFUser = /* GraphQL */ `
  mutation CreateSFUser(
    $input: CreateSFUserInput!
    $condition: ModelSFUserConditionInput
  ) {
    createSFUser(input: $input, condition: $condition) {
      id
      userName
      password
      group
      sFUserUserId
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateSFUser = /* GraphQL */ `
  mutation UpdateSFUser(
    $input: UpdateSFUserInput!
    $condition: ModelSFUserConditionInput
  ) {
    updateSFUser(input: $input, condition: $condition) {
      id
      userName
      password
      group
      sFUserUserId
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteSFUser = /* GraphQL */ `
  mutation DeleteSFUser(
    $input: DeleteSFUserInput!
    $condition: ModelSFUserConditionInput
  ) {
    deleteSFUser(input: $input, condition: $condition) {
      id
      userName
      password
      group
      sFUserUserId
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        imageUrl
        address
        birthday
        gender
        userDoctorId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSFUserId
        userPatientId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phoneNumber
      imageUrl
      address
      birthday
      gender
      sFUser {
        id
        userName
        password
        group
        sFUserUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userDoctorId
      chatRooms {
        nextToken
        startedAt
      }
      messages {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSFUserId
      userPatientId
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phoneNumber
      imageUrl
      address
      birthday
      gender
      sFUser {
        id
        userName
        password
        group
        sFUserUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userDoctorId
      chatRooms {
        nextToken
        startedAt
      }
      messages {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSFUserId
      userPatientId
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      phoneNumber
      imageUrl
      address
      birthday
      gender
      sFUser {
        id
        userName
        password
        group
        sFUserUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      userDoctorId
      chatRooms {
        nextToken
        startedAt
      }
      messages {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSFUserId
      userPatientId
      owner
    }
  }
`;
export const createAgent = /* GraphQL */ `
  mutation CreateAgent(
    $input: CreateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    createAgent(input: $input, condition: $condition) {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAgent = /* GraphQL */ `
  mutation UpdateAgent(
    $input: UpdateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    updateAgent(input: $input, condition: $condition) {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAgent = /* GraphQL */ `
  mutation DeleteAgent(
    $input: DeleteAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    deleteAgent(input: $input, condition: $condition) {
      id
      name
      prompt
      model
      language
      avatar
      chatRooms {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDoctorPatient = /* GraphQL */ `
  mutation CreateDoctorPatient(
    $input: CreateDoctorPatientInput!
    $condition: ModelDoctorPatientConditionInput
  ) {
    createDoctorPatient(input: $input, condition: $condition) {
      id
      patientId
      doctorId
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateDoctorPatient = /* GraphQL */ `
  mutation UpdateDoctorPatient(
    $input: UpdateDoctorPatientInput!
    $condition: ModelDoctorPatientConditionInput
  ) {
    updateDoctorPatient(input: $input, condition: $condition) {
      id
      patientId
      doctorId
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteDoctorPatient = /* GraphQL */ `
  mutation DeleteDoctorPatient(
    $input: DeleteDoctorPatientInput!
    $condition: ModelDoctorPatientConditionInput
  ) {
    deleteDoctorPatient(input: $input, condition: $condition) {
      id
      patientId
      doctorId
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      doctor {
        id
        specialist
        doctorUserId
        departmentID
        introduction
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createPatientDisease = /* GraphQL */ `
  mutation CreatePatientDisease(
    $input: CreatePatientDiseaseInput!
    $condition: ModelPatientDiseaseConditionInput
  ) {
    createPatientDisease(input: $input, condition: $condition) {
      id
      patientId
      diseaseId
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      disease {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updatePatientDisease = /* GraphQL */ `
  mutation UpdatePatientDisease(
    $input: UpdatePatientDiseaseInput!
    $condition: ModelPatientDiseaseConditionInput
  ) {
    updatePatientDisease(input: $input, condition: $condition) {
      id
      patientId
      diseaseId
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      disease {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deletePatientDisease = /* GraphQL */ `
  mutation DeletePatientDisease(
    $input: DeletePatientDiseaseInput!
    $condition: ModelPatientDiseaseConditionInput
  ) {
    deletePatientDisease(input: $input, condition: $condition) {
      id
      patientId
      diseaseId
      patient {
        id
        checkInTime
        status
        ethnicity
        healthHistory
        allergies
        immunizations
        patientUserId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      disease {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
