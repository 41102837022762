import {
  DomainNameType,
  SetDomainNameAction,
  ReasonType,
  StropheConnectedAction,
  StropheConnectingAction,
  StropheConnFailAction,
  StropheDisconnectedAction,
  StropheDisconnectingAction,
  SetConnectionAction,
  ConnectionType
} from './strophe.types';
import { CONNECTED, CONNECTING, CONNFAIL, DISCONNECTED, DISCONNECTING, SET_DOMAIN_NAME, SET_CONNECTION } from './stropheActions';

export const setConnectingAction = (reason: ReasonType): StropheConnectingAction => ({
  type: CONNECTING,
  payload: { reason }
});

export const setConnectedAction = (reason: ReasonType): StropheConnectedAction => ({
  type: CONNECTED,
  payload: { reason }
});

export const setConnFailAction = (reason: ReasonType): StropheConnFailAction => ({
  type: CONNFAIL,
  payload: { reason }
});

export const setDisconnectingAction = (reason: string): StropheDisconnectingAction => ({
  type: DISCONNECTING,
  payload: { reason }
});

export const setDisconnectedAction = (reason: string): StropheDisconnectedAction => ({
  type: DISCONNECTED,
  payload: { reason }
});

export const setDomainNameAction = (payload: DomainNameType): SetDomainNameAction => ({
  type: SET_DOMAIN_NAME,
  payload
});

export const setConnectionAction = (payload: ConnectionType): SetConnectionAction => ({
  type: SET_CONNECTION,
  payload
});
