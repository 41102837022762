import { createNativeStackNavigator, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { PatientStackParamList } from '../types/Stacks';
import { RootStackScreensEnum } from './enum';
import React, { useEffect } from 'react';
import { useAuth } from '../components/auth/AmplifyAuthProvider';
import { RootStackParamList } from './types';
import { useNavigation } from '@react-navigation/native';
import DashboardPatient from '../screens/PatientScreen/DashboardPatient';
import ChatWithDoctor from '../screens/PatientScreen/ChatWithDoctor';
import AppointmentList from '../screens/PatientScreen/AppointmentList';
import HealthStore from '../screens/PatientScreen/HealthStore';
import Roles from '../components/auth/role';
import PatientHistory from '../screens/PatientScreen/PatientHistory';
import PatientProfile from '../screens/PatientScreen/PatientProfile';
import QAndAScreen from '../screens/PatientScreen/QAndAScreen';
import DoctorDetailsPatient from '../screens/PatientScreen/DoctorDetailsPatient';
import DoctorListPatient from '../screens/PatientScreen/DoctorListPatient';
import MedicalDetailMobile from '../screens/AdminScreen/MedicalDetailMobile';
import ChatScreenMobile from '../screens/Chat/ChatScreenMobile';
import ChatTotalMobile from '../screens/Chat/ChatTotalMobile';
import ChatTotal from '../screens/Chat/ChatTotal';

const PatientStack = createNativeStackNavigator<PatientStackParamList>();

const PatientStackScreen = () => {
  const { isSignedIn, checkRole, isReady } = useAuth();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList, RootStackScreensEnum.Main>>();

  // Navigate to AuthStack if user is not signed in
  useEffect(() => {
    (async () => {
      if (isReady) {
        const role = await checkRole();
        if (!isSignedIn) {
          navigation.replace(RootStackScreensEnum.Auth, { screen: RootStackScreensEnum.SignIn });
        }
        if (role === Roles.Admin) {
          navigation.replace(RootStackScreensEnum.Main, {
            screen: RootStackScreensEnum.Admin,
            params: { screen: RootStackScreensEnum.Dashboard }
          });
        }
        if (role === Roles.Doctor) {
          navigation.replace(RootStackScreensEnum.Main, {
            screen: RootStackScreensEnum.Doctor,
            params: { screen: RootStackScreensEnum.DashboardDoctor }
          });
        }
      }
    })();
  }, [isSignedIn, navigation, checkRole, isReady]);

  return (
    <PatientStack.Navigator
      screenOptions={{
        headerShown: false
      }}
      initialRouteName={RootStackScreensEnum.DashboardPatient}>
      <PatientStack.Screen name={RootStackScreensEnum.DashboardPatient} component={DashboardPatient} />
      <PatientStack.Screen name={RootStackScreensEnum.HealthStore} component={HealthStore} />
      <PatientStack.Screen name={RootStackScreensEnum.ChatWithDoctor} component={ChatWithDoctor} />
      <PatientStack.Screen name={RootStackScreensEnum.AppointmentList} component={AppointmentList} />
      <PatientStack.Screen name={RootStackScreensEnum.PatientHistory} component={PatientHistory} />
      <PatientStack.Screen name={RootStackScreensEnum.PatientProfile} component={PatientProfile} />
      <PatientStack.Screen name={RootStackScreensEnum.DoctorDetailsPatient} component={DoctorDetailsPatient} />
      <PatientStack.Screen name={RootStackScreensEnum.DoctorListPatient} component={DoctorListPatient} />
      <PatientStack.Screen name={RootStackScreensEnum.QAndAScreen} component={QAndAScreen} />
      <PatientStack.Screen name={RootStackScreensEnum.MedicalDetailMobile} component={MedicalDetailMobile} />
      <PatientStack.Screen name={RootStackScreensEnum.ChatTotalMobile} component={ChatTotalMobile} />
      <PatientStack.Screen name={RootStackScreensEnum.ChatScreenMobile} component={ChatScreenMobile} />
    </PatientStack.Navigator>
  );
};

export default PatientStackScreen;
