// import React from 'react';
// import DashboardLayoutPatient from '../../layouts/DashboardLayoutPatient';
// import { Text, VStack } from 'native-base';
//
// const Content = () => {
//   return (
//     <>
//       <VStack px={5} pt={5} pb={10}>
//         <Text fontSize={20} fontWeight={'bold'} color={'coolGray.800'}>
//           What is Teledoc ?
//         </Text>
//         <Text mt={5} fontSize={16} fontWeight={'medium'} color={'coolGray.600'}>
//           Teledoc is an innovative hospital management app that enables patients to access remote healthcare services and manage their medical needs
//           from anywhere. With advanced features such as AI assistance, speech to text, and intuitive user interfaces, Teledoc is designed to
//           streamline the healthcare experience and make it more accessible to patients.
//         </Text>
//
//         <Text fontSize={20} fontWeight={'bold'} color={'coolGray.800'}>
//           What features does Teledoc have?
//         </Text>
//         <Text mt={5} fontSize={16} fontWeight={'medium'} color={'coolGray.600'}>
//           With Teledoc, patients can meet with doctors remotely via video calls, schedule appointments, and wait for their turn in a virtual waiting
//           room. Patients can also chat with assistants and doctors, manage their EHR, and access a range of other features that make managing
//           healthcare needs more convenient.
//         </Text>
//
//         <Text fontSize={20} fontWeight={'bold'} color={'coolGray.800'}>
//           Who can use Teledoc?
//         </Text>
//         <Text mt={5} fontSize={16} fontWeight={'medium'} color={'coolGray.600'}>
//           The target audience for Teledoc includes: Doctors and other healthcare professionals who will be using the app to provide remote care to
//           patients. Patients who will be using the app to schedule appointments, have meetings with doctors, and manage their medical records.
//           Administrators who will be responsible for managing the app and ensuring its smooth operation.
//         </Text>
//
//         <Text fontSize={20} fontWeight={'bold'} color={'coolGray.800'}>
//           How to make an appointment?
//         </Text>
//         <Text mt={5} fontSize={16} fontWeight={'medium'} color={'coolGray.600'}>
//           Teledoc allows patients to request appointments with doctors through its user-friendly interface. To request an appointment, follow these
//           steps: {`\n`}
//           1. The patient can select the department they wish to see and choose a preferred date and time for their appointment. Teledoc will
//           find the available doctors within the selected department and preferred date/time. 2. If an available doctor is found, their information and
//           available time slots will be displayed to the patient. The patient can then confirm their preferred appointment time. 3. If no doctor is
//           available at the selected department and preferred date/time, the system will inform the patient and ask them to choose another time or
//           department. 4. Patients can add an optional description of their health condition or reason for the appointment to help doctors prepare
//           better for the consultation.
//         </Text>
//
//         <Text fontSize={20} fontWeight={'bold'} color={'coolGray.800'}>
//           Can we cancel an appointment?
//         </Text>
//         <Text mt={5} fontSize={16} fontWeight={'medium'} color={'coolGray.600'}>
//           To view the details of an appointment, the patient can click on the appointment in the list. The appointment details page will display the
//           appointment date and time, the doctor's name, and the department. If the patient needs to cancel the appointment, they can do so from this
//           page as well.
//         </Text>
//       </VStack>
//     </>
//   );
// };
//
// const QAndAScreen = () => {
//   return (
//     <DashboardLayoutPatient
//       title='Dashboard'
//       displayMenuButton
//       displayScreenTitle={false}
//       displayAlternateMobileHeader
//       rightPanelMobileHeader={true}
//       rightPanelHasLocation={true}>
//       <Content />
//     </DashboardLayoutPatient>
//   );
// };
//
// export default QAndAScreen;
// Dựa vào các ví dụ trên, hãy tạo một component mới có tên là QAndAScreen và có nội dung như sau:
// Đây là một màn hình có chứa 5 câu hỏi được hiện ra đầu tiên
// Mỗi câu sẽ được bỏ vào một pressable component
// Khi người dùng nhấn vào câu hỏi thì đáp án tương ứng của câu hỏi đó sẽ hiện ra ở dưới
// Các câu hỏi còn lại vẫn được hiển thị như bình thường
// Khi người nhấn vào câu hỏi đã được hiển thị đáp án, thì đáp án sẽ được ẩn đi
// Các câu hỏi khác vẫn được hiển thị như bình thường
// Hãy thực hiện các yêu cầu trên giúp tôi
import React, { useState } from 'react';
import DashboardLayoutPatient from '../../layouts/DashboardLayoutPatient';
import { Text, VStack, Pressable, HStack, Icon, Box, Divider, ScrollView } from 'native-base';
import { AntDesign, Entypo, Ionicons, MaterialIcons } from '@expo/vector-icons';
import { RootStackScreensEnum } from '../../navigation/enum';
import SearchBarPatient from './SearchBarPatient';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { MainStackParamList } from '../../types/Stacks';

const Content = () => {
  const [showAnswer, setShowAnswer] = useState<boolean[]>([]);

  const toggleAnswer = (index: number) => {
    setShowAnswer((prev) => {
      const newShowAnswer = [...prev];
      newShowAnswer[index] = !newShowAnswer[index];
      return newShowAnswer;
    });
  };

  const questions = [
    {
      question: '1. What is Teledoc?',
      answer:
        'Teledoc is an innovative hospital management app that enables patients to access remote healthcare services and manage their medical needs from anywhere. With advanced features such as AI assistance, speech to text, and intuitive user interfaces, Teledoc is designed to streamline the healthcare experience and make it more accessible to patients.'
    },
    {
      question: '2. What features does Teledoc have?',
      answer:
        'With Teledoc, patients can meet with doctors remotely via video calls, schedule appointments, and wait for their turn in a virtual waiting room. Patients can also chat with assistants and doctors, manage their EHR, and access a range of other features that make managing healthcare needs more convenient.'
    },
    {
      question: '3. Who can use Teledoc?',
      answer:
        'The target audience for Teledoc includes: Doctors and other healthcare professionals who will be using the app to provide remote care to patients. Patients who will be using the app to schedule appointments, have meetings with doctors, and manage their medical records. Administrators who will be responsible for managing the app and ensuring its smooth operation.'
    },
    {
      question: '4. How to make an appointment?',
      answer:
        'Teledoc allows patients to request appointments with doctors through its user-friendly interface. To request an appointment, follow these steps:\n\n1. The patient can select the department they wish to see and choose a preferred date and time for their appointment. Teledoc will find the available doctors within the selected department and preferred date/time. \n\n2. If an available doctor is found, their information and available time slots will be displayed to the patient. The patient can then confirm their preferred appointment time. \n\n3. If no doctor is available at the selected department and preferred date/time, the system will inform the patient and ask them to choose another time or department. \n\n4. Patients can add an optional description of their health condition or reason for the appointment to help doctors prepare better for the consultation.'
    },
    {
      question: '5. Can we cancel an appointment?',
      answer:
        "To view the details of an appointment, the patient can click on the appointment in the list. The appointment details page will display the appointment date and time, the doctor's name, and the department. If the patient needs to cancel the appointment, they can do so from this page as well."
    }
  ];

  return (
    <>
      <VStack px={5} pt={5} pb={10}>
        {questions.map((item, index) => (
          <Pressable bg={'primary.100'} rounded={'lg'} key={index} onPress={() => toggleAnswer(index)} mb={5} _pressed={{ opacity: 0.8 }}>
            <HStack justifyContent={'space-between'} alignItems={'center'}>
              <Text p={2} fontSize={20} fontWeight={'medium'} color={'coolGray.800'}>
                {item.question}
              </Text>
              <Icon as={Entypo} name='chevron-down' size='lg' color={'coolGray.800'} ml={5} />
            </HStack>
            {showAnswer[index] && (
              <Text p={2} mt={1} fontSize={16} fontWeight={'medium'} color={'coolGray.800'}>
                {item.answer}
              </Text>
            )}
          </Pressable>
        ))}
      </VStack>
    </>
  );
};
export default function QAndAScreen() {
  const navigation = useNavigation<NativeStackNavigationProp<MainStackParamList, RootStackScreensEnum.Patient>>();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [textInput, setTextInput] = useState('');

  const handleSearchTextChange = (text: string) => {
    setTextInput(text);
  };

  const handleToggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    setTextInput('');
  };
  return (
    <ScrollView showsVerticalScrollIndicator={false} backgroundColor={'#fff'} w={'100%'} h={'100%'}>
      <HStack alignItems={'center'} backgroundColor={'#fff'} justifyContent={'space-between'} height={'64px'} w={'100%'}>
        {!isSearchOpen && (
          <HStack alignItems={'center'} w={'100%'}>
            <Pressable
              mt={1}
              ml={'4'}
              onPress={() => navigation.navigate(RootStackScreensEnum.Patient, { screen: RootStackScreensEnum.DashboardPatient })}
              mr={1}>
              <Icon as={Ionicons} name='arrow-back' size='xl' color={'coolGray.700'} />
            </Pressable>
            <Text w={'95%'} fontSize='20' fontWeight='bold' _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }}>
              Frequently Questions
            </Text>
            <Pressable
              onPress={handleToggleSearch}
              w={'5%'}
              mr={4}
              p={4}
              alignItems='center'
              justifyContent='center'
              size={'10'}
              rounded='full'
              _hover={{ bg: '#E4EBE4' }}>
              <Icon as={<MaterialIcons name='search' />} size={8} color='coolGray.900' />
            </Pressable>
          </HStack>
        )}
        <Box w={'100%'} px={2}>
          {isSearchOpen && (
            <SearchBarPatient textInput={textInput} handleSearchTextChange={handleSearchTextChange} handleToggleSearch={handleToggleSearch} />
          )}
        </Box>
      </HStack>
      <Divider />
      <Content />
    </ScrollView>
  );
}
