import React, { useState } from 'react';
import { HStack, Icon, Pressable, Slide, useColorModeValue, VStack, Image, Text, Box } from 'native-base';
import { useWindowDimensions } from 'react-native';
import { AntDesign, MaterialIcons } from '@expo/vector-icons';
import ChatPanel from './Chat/ChatPanel';

function ChatBoxAI() {
  const dimensions = useWindowDimensions();
  const [isSlideOpen, setSlideOpen] = useState(false);

  return (
    <Pressable onPress={() => setSlideOpen(!isSlideOpen)}>
      <VStack
        justifyContent={'center'}
        alignItems={'center'}
        safeAreaBottom
        shadow={9}
        position='absolute'
        zIndex={1}
        w={{ base: 16, md: 20 }}
        h={{ base: 16, md: 20 }}
        bottom={{ base: 12, md: 10 }}
        right={{ base: 6, md: 10 }}
        rounded={'full'}
        bg={useColorModeValue('primary.600', 'blueGray.900')}>
        <Icon
          as={MaterialIcons}
          name={'chat'}
          size={{ base: 7, md: 10 }}
          _light={{
            color: 'white'
          }}
          _dark={{
            color: 'coolGray.400'
          }}
        />
      </VStack>
      <HStack flex={1} justifyContent='center'>
        <Slide
          zIndex='50'
          in={isSlideOpen}
          placement='right'
          rounded={'3xl'}
          bg={useColorModeValue('white', 'blueGray.900')}
          h={'50%'}
          mt={{ base: '0', md: `${dimensions.height / 2}` }}
          shadow={9}>
          <CustomDrawerContent onClose={() => setSlideOpen(false)} />
        </Slide>
      </HStack>
    </Pressable>
  );
}
export default ChatBoxAI;

function CustomDrawerContent({ onClose }: { onClose: () => void }) {
  const dimensions = useWindowDimensions();
  return (
    <VStack h={'100%'} w={{ base: (dimensions.width * 3) / 4, md: 88 }}>
      <HStack
        px={6}
        justifyContent={'space-between'}
        alignItems={'center'}
        roundedTopLeft={'3xl'}
        bg={useColorModeValue('primary.600', 'blueGray.700')}
        h={16}>
        <HStack alignItems={'center'}>
          <Image source={require('../assets/images/robot.png')} alt='logo' size={'xs'} />
          <VStack ml={2}>
            <Text color={'white'} fontSize={'lg'} fontWeight={'medium'} lineHeight={'22'}>
              Bot
            </Text>
            <Text color={'white'} fontSize={'sm'}>
              We&apos;re here to help you
            </Text>
          </VStack>
        </HStack>

        <Pressable onPress={onClose}>
          <Icon size='6' as={AntDesign} name='close' color={'white'} />
        </Pressable>
      </HStack>
      <Box h={'calc(100% - 64px)'} w={'100%'}>
        <ChatPanel />
      </Box>
    </VStack>
  );
}
