import React from 'react';
import { Box, FlatList, HStack, Icon, Image, Text, VStack } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';

const doctor = [
  {
    id: 1,
    name: 'Dr. Tom Thomas',
    specialty: 'Dentist',
    rating: 4.9,
    image: {
      uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.43034024544922556.jpg'
    },
    address: '123 Main Street, Anytown USA'
  },
  {
    id: 2,
    name: 'Dr. Glee Samantha',
    specialty: 'Pediatrician',
    rating: 4.8,
    image: {
      uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.40549212462773987.jpg'
    },
    address: '456 Oak Avenue, Anytown USA'
  },
  {
    id: 3,
    name: 'Dr. Steven Smith',
    specialty: 'Neurologist',
    rating: 4.7,
    image: require('../assets/Steven.png'),
    address: '789 Elm Street, Anytown USA'
  },
  {
    id: 4,
    name: 'Dr. Robert Johnson',
    specialty: 'Neurologist',
    rating: 4.7,
    image: {
      uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.5295602336621994.jpg'
    },
    address: '789 Elm Street, Anytown USA'
  },
  {
    id: 5,
    name: 'Dr. Rose Smith',
    specialty: 'Neurologist',
    rating: 4.7,
    image: require('../assets/Rose.png'),
    address: '789 Elm Street, Anytown USA'
  }
];

const data = [
  { id: 1, name: 'Aziz Bakree', age: 24, status: 'Pending', image: require('../assets/pannel.png') },
  { id: 2, name: 'Griezerman', age: 24, status: 'On Recovery', image: require('../assets/laura.png') },
  { id: 3, name: 'Oconner', age: 24, status: 'Rejected', image: require('../assets/wade.png') },
  { id: 4, name: 'Uli Trumb', age: 24, status: 'Recovered', image: require('../assets/women.jpg') },
  { id: 5, name: 'Aziz Bakree', age: 24, status: 'Pending', image: require('../assets/tinybaby.png') },
  { id: 6, name: 'Elon Musk', age: 24, status: 'Pending', image: require('../assets/women.jpg') }
];
const Categories = () => {
  return (
    <>
      <HStack justifyContent={'space-between'} width={'100%'}>
        <VStack rounded={'2xl'} _light={{ bg: '#fff' }} _dark={{ bg: { base: 'coolGray.900', md: 'coolGray.800' } }} width={'60%'}>
          <Text mt={2} ml={10} fontWeight={'bold'} fontSize={'lg'}>
            Top Rated Doctors
          </Text>
          <HStack mr={10} ml={10} py={5} rounded={'lg'} height={'full'}>
            <FlatList
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              data={doctor}
              keyExtractor={(item) => item.id.toString()}
              renderItem={({ item }) => (
                <VStack key={item.id} mr={10} backgroundColor={'coolGray.100'} rounded={'2xl'} width={'210'} height={'350'}>
                  <VStack my={5} alignItems={'center'}>
                    <Image rounded={'full'} source={item.image} alt='Alternate Text' size='32' />
                    <Text fontWeight={'bold'} mt={2} fontSize={'lg'}>
                      {item.name}
                    </Text>
                    <HStack
                      mt={2}
                      justifyContent={'center'}
                      alignItems={'center'}
                      backgroundColor={'#0564C8'}
                      width={16}
                      height={6}
                      borderRadius='full'
                      borderColor={'#0564C8'}
                      borderWidth={'2'}>
                      <Text fontWeight={'bold'} fontSize={'md'} color={'#fff'}>
                        {item.rating}
                      </Text>
                      <Icon pl={1} size='4' name={'star'} color={'#fff'} as={FontAwesome} />
                    </HStack>
                    <Text mt={2} w='80%' color={'#4EA0F7'} fontSize={'md'}>
                      {item.specialty}
                    </Text>
                    <Text mt={2} w='80%' color={'coolGray.500'} fontSize={'md'}>
                      {item.address}
                    </Text>
                  </VStack>
                </VStack>
              )}
            />
          </HStack>
        </VStack>

        <VStack px={10} rounded={'2xl'} _light={{ bg: '#fff' }} _dark={{ bg: { base: 'coolGray.900', md: 'coolGray.800' } }} width={'35%'}>
          <Text mt={2} mb={5} fontWeight={'bold'} fontSize={'lg'}>
            Recent Patient
          </Text>
          <VStack backgroundColor={'coolGray.100'} rounded={'2xl'} width={'100%'} height={'350'}>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={data}
              keyExtractor={(item) => item.id.toString()}
              renderItem={({ item }) => (
                <>
                  <HStack px={5} width={'80%'}>
                    <Image rounded={'lg'} source={item.image} alt={'Alternate Text'} size={'12'} borderRadius={'full'} />
                    <VStack mx={2}>
                      <Text fontWeight={'bold'} fontSize={'lg'}>
                        {item.name}
                      </Text>
                      <HStack>
                        <Text width={'170'} color={'coolGray.700'} fontSize={'md'}>
                          {item.age} years
                        </Text>
                        <Text fontSize={'lg'} color={'coolGray.700'}>
                          {item.status}
                        </Text>
                      </HStack>
                    </VStack>
                  </HStack>
                  <Box alignSelf={'center'} backgroundColor={'#0564C8'} w={'95%'} height={0.1} />
                </>
              )}
            />
          </VStack>
        </VStack>
      </HStack>
    </>
  );
};

export default Categories;
