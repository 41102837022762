import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  Hidden,
  HStack,
  Icon,
  Image,
  Input,
  Modal,
  Pressable,
  ScrollView,
  Select,
  Text,
  Tooltip,
  VStack
} from 'native-base';
import { Feather, FontAwesome, FontAwesome5, Foundation, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import DashboardLayout from '../../layouts/DashboardLayout';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectPatientById } from '../../store/hospital/patients/patients-slice';
import { useNavigation, useRoute } from '@react-navigation/native';
import { selectUserById } from '../../store/hospital/users/users-slice';
import * as ImagePicker from 'expo-image-picker';
import { Storage } from 'aws-amplify';
import { graphQLUpdateUserImage } from '../../utils/graphql/user_utils';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import MedicalHistory from './MedicalHistory';
import { gqlGetMedicalRecord, gqlGetMedicalRecordByPatientID } from '../../utils/graphql/medical_record_utils';
import { medicalRecordActions, selectAllMedicalRecords } from '../../store/hospital/medical-record/medical-records-slice';
import { calculateAge, formatDate } from '../../utils/time_utils';
import { IMedicalRecord } from '../../types/IMedicalRecord';
import WebFooter from './WebFooter';
import { ITestResult } from '../../types/ITestResult';
import { IFhirPatient } from '../../types/fhir/IFhirPatient';
import { gqlGetTestResultsByMedicalRecordID } from '../../utils/graphql/test_result_utils';
import { MedicalRecordStatus } from '../../API';
import { saveAs } from 'file-saver';

interface IFieldLayout {
  children: any;
  width: string;
  field: string;
  value?: string;
}

function FieldLayout({ children, width, field, value }: IFieldLayout) {
  return (
    <HStack w={width} alignItems={'center'}>
      <Box justifyContent={'center'} alignItems='center' width={12} height={12} borderRadius='full' borderColor={'#4EA0F7'} borderWidth={'2'}>
        {children}
      </Box>
      <VStack ml={4} flex={1}>
        <Text color={'coolGray.300'} fontSize={'lg'}>
          {field}
        </Text>
        <Text color={'coolGray.500'} fontSize={'lg'}>
          {value ?? 'No Information'}
        </Text>
      </VStack>
    </HStack>
  );
}

interface IProp {
  patientId: string;
}

function Banner({ patientId }: IProp) {
  const patient = useAppSelector((state) => selectPatientById(state.hospital.patients, patientId));
  const patientUser = useAppSelector((state) => selectUserById(state.hospital.users, patient?.patientUserId || ''));
  const recordList = useAppSelector((state) => selectAllMedicalRecords(state.hospital.medicalRecords));
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [awsUri, setAwsUri] = useState<string>('');
  const [previousAvatarUri, setPreviousAvatarUri] = useState<string>('');
  const { user } = useAuth();
  const navigation = useNavigation();
  ///////upload image ///////
  const fetchImageUri = async (uri: string) => {
    const respone = await fetch(uri);
    return await respone.blob();
  };
  const deleteOldImageCallback = useCallback(async (oldImageUri: string) => {
    const oldImageKey = `${oldImageUri.substring(oldImageUri.lastIndexOf('/') + 1)}`;
    try {
      await Storage.remove(oldImageKey, { level: 'public' });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const uploadFile = useCallback(
    async (file: any) => {
      if (!user) {
        return;
      }
      const oldImageUri = previousAvatarUri || patientUser?.imageUrl;
      const img = await fetchImageUri(file.uri);
      try {
        const res = await Storage.put(`my-image-patient-filename${Math.random()}.jpg`, img, {
          level: 'public',
          contentType: 'image/jpeg',
          progressCallback(uploadProgress) {
            console.log('PROGRESS--', uploadProgress.loaded + '/' + uploadProgress.total);
          }
        });
        const result = await Storage.get(res.key);
        const awsImageUri = result.substring(0, result.indexOf('?'));
        if (previousAvatarUri) {
          await deleteOldImageCallback(previousAvatarUri);
        } else if (patientUser?.imageUrl) {
          await deleteOldImageCallback(patientUser.imageUrl);
        }
        setAwsUri(awsImageUri);
        setPreviousAvatarUri(awsImageUri);
      } catch (e) {
        console.log(e);
      }
    },
    [deleteOldImageCallback, patientUser?.imageUrl, previousAvatarUri, user]
  );

  useEffect(() => {
    (async () => {
      if (awsUri) {
        const updatedUser = await graphQLUpdateUserImage(patient?.patientUserId || '', awsUri);
        console.log('UPDATED USER --', updatedUser, 'AWS URI --', awsUri);
      }
    })();
  }, [awsUri, patient?.patientUserId]);

  const openImagePickerAsync = useCallback(async () => {
    const pickerResult = await ImagePicker.launchImageLibraryAsync();
    if (pickerResult.canceled || !pickerResult.uri) {
      return;
    }
    await uploadFile(pickerResult);
    setSelectedImage({ localUri: pickerResult.uri });
  }, [uploadFile]);

  const [isModalEdit, setIsModalEdit] = useState(false);
  const [gender, setGender] = useState('');
  const [isModalDelete, setIsModalDelete] = useState(false);
  const toggleModal = () => {
    setIsModalDelete(!isModalDelete);
  };
  const deleteAccount = () => {
    // Hàm xử lý việc xóa tài khoản
    // ...
    toggleModal();
  };

  // export to FHIR file
  const exportFile = async () => {
    if (!patient || !patientUser) {
      return;
    }
    const fhirPatient: IFhirPatient = {
      resourceType: 'Patient',
      id: patient.id.toString(),
      identifier: [
        {
          use: 'official',
          system: 'https://teledoc.vntek.org/',
          value: patient.id.toString()
        }
      ],
      name: [
        {
          use: 'official',
          given: [patientUser?.firstName ?? ''],
          family: patientUser?.lastName ?? ''
        }
      ],
      gender: patientUser?.gender ?? '',
      address: [
        {
          text: patientUser?.address ?? ''
        }
      ],
      telecom: [
        {
          system: 'phone',
          value: patientUser?.phoneNumber ?? '',
          rank: 1
        },
        {
          system: 'email',
          value: patientUser?.email ?? '',
          rank: 2
        }
      ],
      birthDate: patientUser?.birthday ?? '',
      active: true,
      deceasedBoolean: false,
      multipleBirthBoolean: false,
      photo: [
        {
          contentType: patientUser?.imageUrl ? patientUser.imageUrl.split('.')[1] : '',
          url: patientUser?.imageUrl ?? ''
        }
      ]
    };
    let allTestResults: ITestResult[] = [];
    const fhirRecordList = await Promise.all(
      recordList.map(async (record: IMedicalRecord) => {
        const testResultsByRecordId = await gqlGetTestResultsByMedicalRecordID(record.id);
        allTestResults = allTestResults.concat(testResultsByRecordId);
        return {
          resourceType: 'Encounter',
          id: record.id.toString(),
          meta: {
            versionId: record._version,
            lastUpdated: record.updatedAt
            // source: '#y4ub39AOBxSv2Mta',
            // profile: ['http://fhir.mimic.mit.edu/StructureDefinition/mimic-encounter']
          },
          // identifier: [
          //   {
          //     use: 'usual',
          //     system: 'http://fhir.mimic.mit.edu/identifier/encounter',
          //     value: '29163082',
          //     assigner: {
          //       reference: 'Organization/ee172322-118b-5716-abbc-18e4c5437e15'
          //     }
          //   }
          // ],
          status: record.status === MedicalRecordStatus.COMPLETED ? 'completed ' : 'in-progress',
          // class: {
          //   system: 'http://fhir.mimic.mit.edu/CodeSystem/admission-class',
          //   code: 'outpatient'
          // },
          // type: [
          //   {
          //     coding: [
          //       {
          //         system: 'http://snomed.info/sct',
          //         code: '453701000124103',
          //         display: 'In-person encounter (procedure)'
          //       }
          //     ]
          //   }
          // ],
          // serviceType: {
          //   coding: [
          //     {
          //       system: 'http://fhir.mimic.mit.edu/CodeSystem/services',
          //       code: 'CMED'
          //     }
          //   ]
          // },
          // priority: {
          //   coding: [
          //     {
          //       system: 'http://fhir.mimic.mit.edu/CodeSystem/admission-type',
          //       code: 'URGENT'
          //     }
          //   ]
          // },
          subject: {
            reference: `Patient/${record.patientID}`
          },
          period: {
            start: record.timestamp,
            end: record.timestamp
          }
          // hospitalization: {
          //   admitSource: {
          //     coding: [
          //       {
          //         system: 'http://fhir.mimic.mit.edu/CodeSystem/admit-source',
          //         code: 'TRANSFER FROM HOSPITAL'
          //       }
          //     ]
          //   },
          //   dischargeDisposition: {
          //     coding: [
          //       {
          //         system: 'http://fhir.mimic.mit.edu/CodeSystem/discharge-disposition',
          //         code: 'HOME HEALTH CARE'
          //       }
          //     ]
          //   }
          // },
          // location: [
          //   {
          //     location: {
          //       reference: 'Location/25fdf8f3-5c5e-517b-b732-8f4501f25c33'
          //     },
          //     period: {
          //       start: '2142-08-27T21:06:05-04:00',
          //       end: '2142-08-30T13:20:56-04:00'
          //     }
          //   },
          //   {
          //     location: {
          //       reference: 'Location/30fa4243-10dc-5b14-8819-e4785f8dd4a7'
          //     },
          //     period: {
          //       start: '2142-08-30T13:20:56-04:00',
          //       end: '2142-08-31T18:19:36-04:00'
          //     }
          //   },
          //   {
          //     location: {
          //       reference: 'Location/25fdf8f3-5c5e-517b-b732-8f4501f25c33'
          //     },
          //     period: {
          //       start: '2142-08-31T18:19:36-04:00',
          //       end: '2142-09-05T17:33:15-04:00'
          //     }
          //   }
          // ],
          // serviceProvider: {
          //   reference: 'Organization/ee172322-118b-5716-abbc-18e4c5437e15'
          // }
        };
      })
    );
    const fhirTestResultList = await Promise.all(
      allTestResults.map(async (testResult: ITestResult) => {
        const medicalRecord = await gqlGetMedicalRecord(testResult.medicalRecordID);
        return {
          resourceType: 'Observation',
          id: testResult.id.toString(),
          meta: {
            versionId: testResult._version,
            lastUpdated: testResult.updatedAt
            // source: '#7M8zn2gwwTJ9YcGY',
            // profile: ['http://fhir.mimic.mit.edu/StructureDefinition/mimic-observation-micro-test']
          },
          // identifier: [
          //   {
          //     system: 'http://fhir.mimic.mit.edu/identifier/observation-micro-test',
          //     value: '66421-90189'
          //   }
          // ],
          status: 'final',
          // category: [
          //   {
          //     coding: [
          //       {
          //         system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          //         code: 'laboratory'
          //       }
          //     ]
          //   }
          // ],
          code: {
            coding: [
              {
                // system: 'http://fhir.mimic.mit.edu/CodeSystem/microbiology-test',
                // code: '90189',
                display: testResult.testType
              }
            ]
          },
          subject: {
            reference: `Patient/${medicalRecord?.patientID}}`
          },
          encounter: {
            reference: `Encounter/${testResult.medicalRecordID}`
          },
          effectiveDateTime: testResult.timestamp,
          valueString: testResult.result
          // specimen: {
          //   reference: 'Specimen/9f30ca2a-00a0-59a0-ba8e-f35344fd9d6f'
          // }
        };
      })
    );
    const ndjsonPatientsContent = JSON.stringify(fhirPatient);
    const ndjsonRecordsContent = fhirRecordList.map((item) => JSON.stringify(item)).join('\n');
    const ndjsonTestResultsContent = fhirTestResultList.map((item) => JSON.stringify(item)).join('\n');
    const ndjsonContent = ndjsonPatientsContent + '\n' + ndjsonRecordsContent + '\n' + ndjsonTestResultsContent;
    const blob = new Blob([ndjsonContent]);
    saveAs(blob, 'fhir-resource.ndjson');
  };

  return (
    <VStack
      _light={{ bg: { base: '#fff', md: '' } }}
      _dark={{ bg: { base: 'coolGray.900', md: 'coolGray.800' } }}
      zIndex={2}
      borderRadius={{ md: 10 }}
      px={{ base: 0, md: 4 }}
      mb={{ md: 20 }}>
      {/*-start-header*/}
      <HStack mt={{ base: 0, md: 5 }} w={'full'} alignItems={'center'}>
        <Hidden from={'md'}>
          <HStack>
            <Pressable ml={4} onPress={() => navigation.goBack()} mt={1}>
              <Icon as={Ionicons} name='arrow-back' size='xl' color={'coolGray.700'} />
            </Pressable>
          </HStack>
        </Hidden>
        <Hidden till={'md'}>
          <HStack justifyContent={'space-between'} w={'full'}>
            <Box>
              <Text mb={5} fontSize={'2xl'} fontWeight={'bold'}>
                Information of Patient
              </Text>
            </Box>
            <Button onPress={() => exportFile()} borderRadius='15' bg='#66CDAA' _text={{ color: 'white', fontSize: 'lg', fontWeight: 'bold' }}>
              Export
            </Button>
          </HStack>
        </Hidden>
      </HStack>
      <HStack pb={15} alignItems='center' justifyContent='space-between' w={'100%'}>
        <VStack space='2' w={{ base: '55%', md: '100%' }}>
          <HStack justifyContent={'space-between'}>
            <Hidden till={'md'}>
              <VStack>
                <HStack width={'60%'}>
                  <Box backgroundColor={'#ff9900'} w={'30%'} height={1}>
                    <Text mt={3} fontSize={'md'} fontWeight={'bold'} color={'#ff9900'}>
                      Pending
                    </Text>
                    <Text mt={3} fontSize={'md'} color={'coolGray.700'}>
                      21/08/2020, 12:45 AM
                    </Text>
                  </Box>
                  <Box backgroundColor={'#5e72e4'} w={'30%'} height={1}>
                    <Text mt={3} fontSize={'md'} fontWeight={'bold'} color={'#5e72e4'}>
                      On Recovery
                    </Text>
                    <Text mt={3} fontSize={'md'} color={'coolGray.700'}>
                      21/08/2020, 12:45 AM
                    </Text>
                  </Box>
                  <Box backgroundColor={'#4EA0F7'} w={'30%'} height={1}>
                    <Text mt={3} fontSize={'md'} fontWeight={'bold'} color={'#4EA0F7'}>
                      Recovered
                    </Text>
                    <Text mt={3} fontSize={'md'} color={'coolGray.700'}>
                      21/08/2020, 12:45 AM
                    </Text>
                  </Box>
                </HStack>
                <HStack>
                  <HStack mb='15'>
                    <Tooltip label={'Edit Info'}>
                      <Pressable
                        onPress={() => setIsModalEdit(true)}
                        shadow={7}
                        position='absolute'
                        right={{ base: -60, md: 40 }}
                        rounded='full'
                        _hover={{ bg: '#4EA0F7' }}
                        _light={{ bg: '#0564C8' }}
                        _dark={{ bg: 'primary.500' }}
                        w={{ base: 16, md: 24 }}
                        h={{ base: 8, md: 10 }}
                        alignItems='center'
                        justifyContent='center'>
                        <HStack px={2} alignItems={'center'}>
                          <Icon size={{ base: 4, md: 5 }} name='edit' color='#fff' as={MaterialIcons} />
                          <Text pl={2} fontSize={{ base: 'sm', md: 'md' }} color='#fff'>
                            Edit
                          </Text>
                        </HStack>
                      </Pressable>
                    </Tooltip>
                    <Modal isOpen={isModalEdit} onClose={() => setIsModalEdit(false)}>
                      <Modal.Content>
                        <Modal.Header>Edit Info</Modal.Header>
                        <Tooltip label='Close'>
                          <Pressable
                            px={4}
                            right={2}
                            top={2}
                            position={'absolute'}
                            alignItems='center'
                            justifyContent='center'
                            size={'8'}
                            rounded='full'
                            _hover={{ bg: '#E4EBE4' }}>
                            <Icon onPress={() => setIsModalEdit(false)} as={<MaterialIcons name='close' />} size={6} color='coolGray.900' />
                          </Pressable>
                        </Tooltip>
                        <Modal.Body>
                          <FormControl>
                            <FormControl.Label>Name</FormControl.Label>
                            <Input placeholder='Enter your name' />
                          </FormControl>
                          <FormControl>
                            <FormControl.Label>Gender</FormControl.Label>
                            <Select selectedValue={gender} onValueChange={(value) => setGender(value)} placeholder='Select gender'>
                              <Select.Item label='Male' value='male' />
                              <Select.Item label='Female' value='female' />
                              <Select.Item label='Other' value='other' />
                            </Select>
                          </FormControl>
                          <FormControl>
                            <FormControl.Label>Phone number</FormControl.Label>
                            <Input placeholder='Enter your phone number' />
                          </FormControl>
                          <FormControl>
                            <FormControl.Label>Email</FormControl.Label>
                            <Input placeholder='Enter your email' />
                          </FormControl>
                          <FormControl>
                            <FormControl.Label>Address</FormControl.Label>
                            <Input placeholder='Enter your address' />
                          </FormControl>
                          <FormControl>
                            <FormControl.Label>Avatar</FormControl.Label>
                            <Button backgroundColor={'white'} onPress={openImagePickerAsync}>
                              <Image
                                source={
                                  selectedImage?.localUri
                                    ? { uri: selectedImage?.localUri }
                                    : patientUser?.imageUrl
                                    ? { uri: patientUser?.imageUrl }
                                    : require('../../assets/default-avatar.jpg')
                                }
                                alt='Alternate Text'
                                size='32'
                                rounded={'lg'}
                              />
                            </Button>
                          </FormControl>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button.Group variant='ghost'>
                            <Button onPress={() => setIsModalEdit(false)}>Save</Button>
                          </Button.Group>
                        </Modal.Footer>
                      </Modal.Content>
                    </Modal>
                    <Tooltip label={'Delete'}>
                      <Pressable
                        onPress={toggleModal}
                        shadow={7}
                        position='absolute'
                        right={{ base: -150, md: 8 }}
                        // zIndex={1}
                        rounded='full'
                        _hover={{ bg: 'red.300' }}
                        _light={{ bg: 'red.700' }}
                        _dark={{ bg: 'primary.500' }}
                        w={{ base: 16, md: 24 }}
                        h={{ base: 8, md: 10 }}
                        alignItems='center'
                        justifyContent='center'>
                        <HStack alignItems={'center'} px={2}>
                          <Icon size={{ base: 4, md: 5 }} name='delete' color='#fff' as={MaterialIcons} />
                          <Text pl={1} fontSize={{ base: 'sm', md: 'md' }} color='#fff'>
                            Delete
                          </Text>
                        </HStack>
                      </Pressable>
                    </Tooltip>
                    <Modal isOpen={isModalDelete} onClose={toggleModal}>
                      <Modal.Content>
                        <Modal.Header>Confirm Delete</Modal.Header>
                        <Tooltip label='Close'>
                          <Pressable
                            px={4}
                            right={2}
                            top={2}
                            position={'absolute'}
                            alignItems='center'
                            justifyContent='center'
                            size={'8'}
                            rounded='full'
                            _hover={{ bg: '#E4EBE4' }}>
                            <Icon onPress={toggleModal} as={<MaterialIcons name='close' />} size={6} color='coolGray.900' />
                          </Pressable>
                        </Tooltip>
                        <Modal.Body>
                          <Center>
                            <Text>Are you sure to delete this account?</Text>
                          </Center>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button.Group variant='ghost'>
                            <Button onPress={deleteAccount}>Delete</Button>
                          </Button.Group>
                        </Modal.Footer>
                      </Modal.Content>
                    </Modal>
                  </HStack>
                </HStack>
              </VStack>
            </Hidden>
          </HStack>
        </VStack>
      </HStack>
      {/*-end-header*/}
      {/*-start-profile*/}
      <Hidden till={'md'}>
        <HStack mt={5}>
          <VStack w={{ base: '65%', md: '65%' }} px={8} py={6} backgroundColor={'white'} rounded={'lg'} space='2' h={'100%'}>
            <HStack w={'full'}>
              <Button backgroundColor={'white'} onPress={openImagePickerAsync} p={0}>
                <Image
                  source={
                    selectedImage?.localUri
                      ? { uri: selectedImage?.localUri }
                      : patientUser?.imageUrl
                      ? { uri: patientUser?.imageUrl }
                      : require('../../assets/default-avatar.jpg')
                  }
                  alt='Alternate Text'
                  size='32'
                  rounded={'lg'}
                />
              </Button>

              <VStack flex={1} ml={4} space={2} justifyContent={'center'}>
                <Text fontWeight={'bold'} fontSize={'xl'}>
                  {patientUser?.firstName + ' ' + patientUser?.lastName}
                </Text>
                <Text color={'coolGray.500'} fontSize={'lg'}>
                  Check In date {patient?.checkInTime && formatDate(patient.checkInTime)}
                </Text>
                <HStack alignItems={'center'} space={8}>
                  {patientUser?.gender && (
                    <HStack justifyContent={'center'} alignItems={'center'} py={2} px={6} rounded={'full'} backgroundColor={'coolGray.200'}>
                      <Icon
                        size='9'
                        name={patientUser.gender === 'MALE' ? 'male-symbol' : 'female-symbol'}
                        color={patientUser.gender === 'MALE' ? '#4EA0F7' : 'pink.400'}
                        as={Foundation}
                      />
                      <Text fontWeight={'bold'} fontSize={'lg'}>
                        {patientUser.gender}
                      </Text>
                    </HStack>
                  )}
                  {patientUser?.birthday && (
                    <Text color={'coolGray.500'} fontSize={'lg'}>
                      {calculateAge(patientUser.birthday)}-old
                    </Text>
                  )}
                  {patient?.ethnicity && (
                    <Text color={'coolGray.500'} fontSize={'lg'}>
                      {patient.ethnicity}
                    </Text>
                  )}
                </HStack>
              </VStack>
            </HStack>

            <Divider orientation='horizontal' alignSelf={'center'} backgroundColor={'coolGray.200'} h={0.5} w={'95%'} my={4} />

            <VStack space={4}>
              <HStack space={4} alignItems={'start'}>
                <FieldLayout width={'50%'} field={'Address'} value={patientUser?.address ? patientUser.address : ''}>
                  <Icon size='6' name={'location-outline'} color={'#4EA0F7'} as={Ionicons} />
                </FieldLayout>
                {/*<HStack w={'50%'} alignItems={'center'}>*/}
                {/*  <Box*/}
                {/*    justifyContent={'center'}*/}
                {/*    alignItems='center'*/}
                {/*    width={12}*/}
                {/*    height={12}*/}
                {/*    borderRadius='full'*/}
                {/*    borderColor={'#4EA0F7'}*/}
                {/*    borderWidth={'2'}>*/}
                {/*    <Icon size='6' name={'location-outline'} color={'#4EA0F7'} as={Ionicons} />*/}
                {/*  </Box>*/}
                {/*  <VStack ml={4} flex={1}>*/}
                {/*    <Text color={'coolGray.300'} fontSize={'xl'}>*/}
                {/*      Address*/}
                {/*    </Text>*/}
                {/*    <Text color={'coolGray.500'} fontSize={'lg'}>*/}
                {/*      795 Folsom Ave, Suite 600 San Francisco, CADGE 94107*/}
                {/*    </Text>*/}
                {/*  </VStack>*/}
                {/*</HStack>*/}
                <Box w={'50%'}>
                  <Image rounded={'xl'} width='96' height='32' source={require('../../assets/map.png')} alt='Alternate Text' />
                </Box>
              </HStack>

              <HStack space={4}>
                <FieldLayout width={'50%'} field={'Phone'} value={patientUser?.phoneNumber ? '0931 723 755' : ''}>
                  <Icon size='6' name={'phone'} color={'#4EA0F7'} as={Feather} />
                </FieldLayout>
                <FieldLayout width={'50%'} field={'Email'} value={patientUser?.email ? patientUser.email : ''}>
                  <Icon size='6' name={'email'} color={'#4EA0F7'} as={MaterialIcons} />
                </FieldLayout>
              </HStack>
            </VStack>

            <Divider orientation='horizontal' alignSelf={'center'} backgroundColor={'coolGray.200'} h={0.5} w={'95%'} my={4} />

            <VStack space={4}>
              <HStack>
                <Text color={'coolGray.800'} fontSize={'2xl'}>
                  Health Overview
                </Text>
              </HStack>
              <FieldLayout width={'100%'} field={'Health History'} value={patient?.healthHistory ? patient.healthHistory : ''}>
                <FontAwesome5 name='notes-medical' size={24} color='#4EA0F7' />
              </FieldLayout>
              <FieldLayout width={'100%'} field={'Allergies'} value={patient?.allergies ? patient.allergies : ''}>
                <MaterialCommunityIcons name='allergy' size={24} color='#4EA0F7' />
              </FieldLayout>
              <FieldLayout width={'100%'} field={'Immunization Status'} value={patient?.immunizations ? patient.immunizations : ''}>
                <FontAwesome5 name='syringe' size={24} color='#4EA0F7' />
              </FieldLayout>
            </VStack>
          </VStack>
          {/*-end-*/}
          {/*start-assigned doctor*/}
          <VStack ml={4} px={8} py={6} w={{ base: '50%', md: '35%' }} backgroundColor={'white'} rounded={'lg'} space='2' h={'100%'}>
            {/**/}
            <HStack alignItems={'center'}>
              <VStack>
                <HStack>
                  <Image rounded={'lg'} source={require('../../assets/default-avatar.jpg')} alt='Alternate Text' size='32' />
                  <VStack pl={5} justifyContent={'space-around'}>
                    <Text fontWeight={'bold'} fontSize={'lg'} color={'black'} my={5}>
                      Assigned Doctor
                    </Text>
                    <Text fontWeight={'bold'} fontSize={'xl'}>
                      Dr. Samantha
                    </Text>
                    <Text color={'#4EA0F7'} fontSize={'lg'}>
                      Physical Therapy
                    </Text>
                    {/*  */}
                  </VStack>
                </HStack>
                {/**/}
                <VStack>
                  <Box
                    mt={2}
                    ml={5}
                    backgroundColor={'#4EA0F7'}
                    justifyContent={'center'}
                    alignItems='center'
                    width={20}
                    height={8}
                    borderRadius='2xl'
                    borderColor={'#4EA0F7'}>
                    <Text fontWeight={'bold'} fontSize={'xl'} color={'#fff'}>
                      4.5
                    </Text>
                  </Box>
                  <HStack mt={2}>
                    <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                    <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                    <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                    <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                    <Icon size='6' name={'star-half-empty'} color={'#f1c40f'} as={FontAwesome} />
                  </HStack>
                </VStack>
              </VStack>
              {/*-end-*/}
            </HStack>
          </VStack>
          {/**/}
        </HStack>
      </Hidden>
      <Hidden from={'md'}>
        <VStack h={'100%'}>
          <VStack w={'100%'} py={6} backgroundColor={'white'} rounded={'lg'}>
            <HStack>
              <Button backgroundColor={'white'} onPress={openImagePickerAsync}>
                <Image
                  source={patientUser?.imageUrl ? { uri: patientUser?.imageUrl } : undefined}
                  rounded={'md'}
                  size={32}
                  accessibilityLabel={patientUser?.lastName.charAt(0).toUpperCase()}
                  alt={patientUser?.lastName.charAt(0).toUpperCase()}
                />
              </Button>
              <VStack mt={2} w={'45%'}>
                <Text fontWeight={'bold'} fontSize={'20'} color={'coolGray.900'}>
                  {patientUser?.firstName + ' ' + patientUser?.lastName}
                </Text>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  Check In date: {patient?.checkInTime && formatDate(patient.checkInTime)}
                </Text>
              </VStack>
            </HStack>

            <VStack shadow={1} mt={3} w={'90%'} p={3} mx={5} borderWidth={1} rounded={'lg'} borderColor={'coolGray.300'}>
              <HStack mb={2} alignItems={'center'}>
                <MaterialCommunityIcons name='clipboard-text-outline' size={28} color='#4EA0F7' />
                <Text ml={2} color={'coolGray.900'} fontSize={'20'} fontWeight={'bold'}>
                  Patient Info
                </Text>
              </HStack>
              <VStack flex={1} space={2}>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  Gender: {patientUser?.gender}
                </Text>
                {patientUser?.birthday && (
                  <Text color={'coolGray.500'} fontSize={'16'}>
                    Age: {calculateAge(patientUser.birthday)}-old
                  </Text>
                )}
                {patient?.ethnicity && (
                  <Text color={'coolGray.500'} fontSize={'16'}>
                    Ethnicity: {patient.ethnicity}
                  </Text>
                )}
                <Text color={'coolGray.500'} fontSize={'16'}>
                  Status: {patient?.status}
                </Text>
              </VStack>
            </VStack>

            <VStack shadow={1} mt={3} w={'90%'} p={3} mx={5} borderWidth={1} rounded={'lg'} borderColor={'coolGray.300'}>
              <HStack mb={2} alignItems={'center'}>
                <Ionicons name='home' size={28} color='#4EA0F7' />
                <Text ml={2} color={'coolGray.900'} fontSize={'20'} fontWeight={'bold'}>
                  Address
                </Text>
              </HStack>
              <VStack space={2}>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  Address: {patientUser?.address}
                </Text>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  Phone: {patientUser?.phoneNumber ? '0931 723 755' : ''}
                </Text>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  Email: {patientUser?.email}
                </Text>
              </VStack>
            </VStack>

            <VStack shadow={1} mt={3} w={'90%'} p={3} mx={5} borderWidth={1} rounded={'lg'} borderColor={'coolGray.300'}>
              <HStack mb={2} alignItems={'center'}>
                <FontAwesome5 name='notes-medical' size={28} color='#4EA0F7' />
                <Text ml={2} color={'coolGray.900'} fontSize={'20'} fontWeight={'bold'}>
                  Health Overview
                </Text>
              </HStack>
              <VStack>
                <Text color={'coolGray.300'} fontSize={'16'}>
                  Health History
                </Text>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  {patient?.healthHistory ? patient.healthHistory : ''}
                </Text>
              </VStack>
              <VStack>
                <Text color={'coolGray.300'} fontSize={'16'}>
                  Allergies
                </Text>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  {patient?.allergies ? patient.allergies : ''}
                </Text>
              </VStack>
              <VStack>
                <Text color={'coolGray.300'} fontSize={'16'}>
                  Immunizations Status
                </Text>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  {patient?.immunizations ? patient.immunizations : ''}
                </Text>
              </VStack>
            </VStack>
          </VStack>
          {/*-end-*/}
          {/*start-assigned doctor*/}
          <VStack mt={4} w={'100%'} py={2} backgroundColor={'white'} rounded={'lg'}>
            {/**/}
            <VStack alignItems={'center'}>
              <VStack>
                <HStack alignItems={'center'}>
                  <Image rounded={'md'} size={32} source={require('../../assets/default-avatar.jpg')} alt='Alternate Text' />
                  <VStack ml={5} space={2} mb={7}>
                    <Text fontWeight={'bold'} fontSize={'20'} color={'coolGray.900'} my={5}>
                      Assigned Doctor
                    </Text>
                    <Text fontWeight={'medium'} fontSize={'20'} color={'coolGray.500'}>
                      Dr. Samantha
                    </Text>
                    <Text color={'#4EA0F7'} fontSize={'16'}>
                      Physical Therapy
                    </Text>
                    {/*  */}
                  </VStack>
                </HStack>
                {/**/}
                <VStack>
                  <Box
                    mt={1}
                    ml={7}
                    backgroundColor={'#4EA0F7'}
                    justifyContent={'center'}
                    alignItems='center'
                    width={16}
                    height={8}
                    borderRadius='2xl'
                    borderColor={'#4EA0F7'}>
                    <Text fontWeight={'medium'} fontSize={'16'} color={'#fff'}>
                      4.5
                    </Text>
                  </Box>
                  <HStack mt={2} ml={3}>
                    <Icon size='5' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                    <Icon size='5' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                    <Icon size='5' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                    <Icon size='5' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                    <Icon size='5' name={'star-half-empty'} color={'#f1c40f'} as={FontAwesome} />
                  </HStack>
                </VStack>
              </VStack>
              {/*-end-*/}
            </VStack>
          </VStack>
          {/**/}
        </VStack>
      </Hidden>
      {/*end-profile*/}
    </VStack>
  );
}

export default function PatientDetails() {
  const route = useRoute();
  const { patientId } = route.params as any;
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (patientId) {
        const recordList = (await gqlGetMedicalRecordByPatientID(patientId)) as IMedicalRecord[];
        if (recordList && recordList.length > 0) {
          dispatch(medicalRecordActions.setMedicalRecords(recordList));
        } else {
          dispatch(medicalRecordActions.setMedicalRecords([]));
        }
        dispatch(medicalRecordActions.isFhirResource(false));
      }
    })();
  }, [patientId]);

  return (
    <>
      <DashboardLayout title='' displayMenuButton displayScreenTitle={false} displayAlternateMobileHeader rightPanelMobileHeader={true}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Banner patientId={patientId} />
          <MedicalHistory />
        </ScrollView>
        <WebFooter />
      </DashboardLayout>
    </>
  );
}
