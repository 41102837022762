import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Center, HStack, Icon, ScrollView, Spinner, Text, VStack } from 'native-base';
import { FontAwesome, Foundation } from '@expo/vector-icons';
import { useAppDispatch } from '../../store/hooks';
import { useRoute } from '@react-navigation/native';
import MedicalHistory from './MedicalHistory';
import { gqlGetMedicalRecordByPatientID } from '../../utils/graphql/medical_record_utils';
import { medicalRecordActions } from '../../store/hospital/medical-record/medical-records-slice';
import { calculateAge, formatDate } from '../../utils/time_utils';
import { IMedicalRecord } from '../../types/IMedicalRecord';
import WebFooter from './WebFooter';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import Role from '../../components/auth/role';
import { graphQLGetPatientByUserID } from '../../utils/graphql/patient_utils';
import axios from 'axios';
import { IPatient } from '../../types/IPatient';
import { IUser } from '../../types/IUser';

interface IProp {
  patient: IPatient;
  patientUser: IUser;
}

function PatientInfo({ patient, patientUser }: IProp) {
  return (
    <>
      {patient && patientUser ? (
        <VStack
          _light={{ bg: '' }}
          _dark={{ bg: { base: 'coolGray.900', md: 'coolGray.800' } }}
          zIndex={2}
          borderRadius={{ md: 10 }}
          px={{ base: 4, md: 4 }}
          pt={{ base: 4, md: 0 }}
          mb={{ md: 4 }}>
          {/*-start-header*/}
          <HStack pb={15} alignItems='center' justifyContent='space-between'>
            <VStack space='2' w={{ base: '55%', md: '100%' }}>
              <Text mt={{ base: 4, md: 4 }} mb={{ base: 4, md: 0 }} fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color='#4EA0F7'>
                Medical Records
              </Text>
            </VStack>
          </HStack>
          {/*-end-header*/}
          {/*-start-profile*/}
          <HStack mt={2}>
            <VStack w={{ base: '65%', md: '65%' }} px={8} py={6} backgroundColor={'white'} rounded={'lg'} space='2' h={'100%'}>
              <HStack w={'full'}>
                <Button backgroundColor={'white'}>
                  <Avatar source={patientUser?.imageUrl ? { uri: patientUser?.imageUrl } : undefined} size='32'>
                    <Text fontWeight={'bold'} fontSize={'32'} color={'white'}>
                      {patientUser?.lastName.charAt(0).toUpperCase()}
                    </Text>
                  </Avatar>
                </Button>

                <VStack flex={1} ml={4} space={2} justifyContent={'center'}>
                  <Text fontWeight={'bold'} fontSize={'xl'}>
                    {patientUser?.firstName + ' ' + patientUser?.lastName}
                  </Text>
                  <Text color={'coolGray.500'} fontSize={'lg'}>
                    Check In date {patient?.checkInTime && formatDate(patient.checkInTime)}
                  </Text>
                  <HStack alignItems={'center'} space={8}>
                    {patientUser?.gender && (
                      <HStack justifyContent={'center'} alignItems={'center'} py={2} px={6} rounded={'full'} backgroundColor={'coolGray.200'}>
                        <Icon
                          size='9'
                          name={patientUser.gender === 'MALE' ? 'male-symbol' : 'female-symbol'}
                          color={patientUser.gender === 'MALE' ? '#4EA0F7' : 'pink.400'}
                          as={Foundation}
                        />
                        <Text fontWeight={'bold'} fontSize={'lg'}>
                          {patientUser.gender}
                        </Text>
                      </HStack>
                    )}
                    {patientUser?.birthday && (
                      <Text color={'coolGray.500'} fontSize={'lg'}>
                        {calculateAge(patientUser.birthday)}-old
                      </Text>
                    )}
                    {patient?.ethnicity && (
                      <Text color={'coolGray.500'} fontSize={'lg'}>
                        {patient.ethnicity}
                      </Text>
                    )}
                  </HStack>
                </VStack>
              </HStack>
            </VStack>
            {/*-end-*/}
            {/*start-assigned doctor*/}
            <VStack ml={4} px={8} py={6} w={{ base: '50%', md: '35%' }} backgroundColor={'white'} rounded={'lg'} space='2' h={'100%'}>
              {/**/}
              <HStack alignItems={'center'}>
                <VStack>
                  <HStack>
                    <Avatar
                      source={{
                        uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.40549212462773987.jpg'
                      }}
                      size='32'
                    />
                    <VStack pl={5} justifyContent={'space-around'}>
                      <Text fontWeight={'bold'} fontSize={'lg'} color={'black'} my={5}>
                        Assigned Doctor
                      </Text>
                      <Text fontWeight={'bold'} fontSize={'xl'}>
                        Dr. Samantha
                      </Text>
                      <Text color={'#4EA0F7'} fontSize={'lg'}>
                        Physical Therapy
                      </Text>
                      {/*  */}
                    </VStack>
                  </HStack>
                  {/**/}
                  <VStack>
                    <Box
                      mt={2}
                      ml={5}
                      backgroundColor={'#4EA0F7'}
                      justifyContent={'center'}
                      alignItems='center'
                      width={20}
                      height={8}
                      borderRadius='2xl'
                      borderColor={'#4EA0F7'}>
                      <Text fontWeight={'bold'} fontSize={'xl'} color={'#fff'}>
                        4.5
                      </Text>
                    </Box>
                    <HStack mt={2}>
                      <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                      <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                      <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                      <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                      <Icon size='6' name={'star-half-empty'} color={'#f1c40f'} as={FontAwesome} />
                    </HStack>
                  </VStack>
                </VStack>
                {/*-end-*/}
              </HStack>
            </VStack>
            {/**/}
          </HStack>
          {/*end-profile*/}
        </VStack>
      ) : (
        <Spinner />
      )}
    </>
  );
}

const MedicalRecordDetail = () => {
  const route = useRoute();
  const params = route.params as any;
  const dispatch = useAppDispatch();
  const { checkRole, user } = useAuth();
  const [patientId, setPatientId] = useState<string | undefined>(undefined);
  const [patient, setPatient] = useState<IPatient>();
  const [patientUser, setPatientUser] = useState<IUser>();

  const getAndDispatchRecordList = async (id: string) => {
    const recordList = (await gqlGetMedicalRecordByPatientID(id)) as IMedicalRecord[];
    if (recordList && recordList.length > 0) {
      dispatch(medicalRecordActions.setMedicalRecords(recordList));
    } else {
      dispatch(medicalRecordActions.setMedicalRecords([]));
    }
  };

  useEffect(() => {
    (async () => {
      if (user && patientId) {
        const res = await axios.get(`/patient/${patientId}`, {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user?.idToken}` }
        });
        if (res.status === 200 && res.data) {
          setPatient(res.data);
          setPatientUser(res.data.user);
        }
      }
    })();
  }, [user, patientId]);

  useEffect(() => {
    (async () => {
      if ((await checkRole()) === Role.Patient && user?.id) {
        const patient = await graphQLGetPatientByUserID(user.id);
        if (patient) {
          setPatientId(patient.id);
          await getAndDispatchRecordList(patient.id);
        }
      }
      if (params?.patientId) {
        setPatientId(params.patientId);
        await getAndDispatchRecordList(params.patientId);
      }
    })();
  }, [params]);

  return (
    <>
      {patientId && patient && patientUser ? (
        <>
          <ScrollView showsVerticalScrollIndicator={false}>
            <PatientInfo patient={patient} patientUser={patientUser} />
            <MedicalHistory />
          </ScrollView>
          <WebFooter />
        </>
      ) : (
        <Center flex={1}>
          <Spinner size={'lg'} />
        </Center>
      )}
    </>
  );
};
export default MedicalRecordDetail;
