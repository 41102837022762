import React, { useCallback, useEffect, useState } from 'react';
import { CalendarDate } from 'react-native-paper-dates/src/Date/Calendar';
import { FormControl, Hidden, HStack, Icon, Input, Pressable, Select, TextArea, VStack } from 'native-base';
import { Feather, FontAwesome, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import { IDepartment } from '../../types/IDepartment';

type Time = {
  hours: number;
  minutes: number;
};

interface ICreateDepartment {
  formik: any;
  departments: IDepartment[];
  messageNotAvailableDoctor: string;
}

const CreateAppointmentForm = ({ formik, departments, messageNotAvailableDoctor }: ICreateDepartment) => {
  const { touched, handleChange, handleBlur, values, errors, setFieldError } = formik;
  const [date, setDate] = useState<CalendarDate>(new Date());
  const [time, setTime] = useState<Time>({ hours: new Date().getHours() + 1, minutes: 0 });
  const [visibleCalendar, setVisibleCalendar] = useState(false);
  const [visibleTime, setVisibleTime] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState('');

  const onDismissCalendar = useCallback(() => {
    setVisibleCalendar(false);
  }, [setVisibleCalendar]);

  const onConfirmCalendar = useCallback(
    (params: { date: CalendarDate }) => {
      setVisibleCalendar(false);
      setDate(params.date);
    },
    [setVisibleCalendar, setDate]
  );
  useEffect(() => {
    values.date = date?.toISOString().split('T')[0];
    values.dayOfWeek = date?.getDay();
  }, [date]);

  const onDismissTime = useCallback(() => {
    setVisibleTime(false);
  }, [setVisibleTime]);

  const onConfirmTime = useCallback(
    ({ hours }: Time) => {
      setVisibleTime(false);
      setTime({ hours, minutes: 0 });
    },
    [setVisibleTime]
  );
  useEffect(() => {
    values.time = time?.hours;
  }, [time]);

  useEffect(() => {
    values.department = selectedDepartment;
  }, [selectedDepartment]);

  // const [selected, setSelected] = useState<string[]>([]);
  // useEffect(() => {
  //   if (!values.listDoctor.length) {
  //     setFieldError('listDoctor', 'Choose at least one doctor');
  //     return;
  //   }
  //   setFieldError('listDoctor', undefined);
  // }, [values.listDoctor, setFieldError]);

  // const onSelectedItemsChange = (selected: string[]) => {
  //   setSelected(selected);
  //   values.listDoctor = selected;
  // };

  return (
    <VStack space={5}>
      <FormControl isInvalid={'department' in errors && touched.department}>
        <HStack alignItems={'center'}>
          <Icon as={<Feather name={'user-plus'} />} size={7} mr='2' color='gray.600' />
          <Pressable
            mt={values.type === 'schedule' ? '2px' : '1px'}
            flex={1}
            style={{ borderColor: '#D1D5DB', borderWidth: 1 }}
            _hover={{ style: { borderColor: '#2563EB', borderWidth: 1 } }}
            onBlur={handleBlur('listDoctor')}>
            <Select
              selectedValue={selectedDepartment}
              minWidth={200}
              accessibilityLabel='Select Department'
              placeholder='Select Department'
              onValueChange={(itemValue) => setSelectedDepartment(itemValue)}
              >
              {departments.map((department) => (
                <Select.Item key={department.id} label={department.name} value={department.id} />
              ))}
            </Select>
          </Pressable>
        </HStack>
        <FormControl.ErrorMessage ml={9}>{errors.listDoctor}</FormControl.ErrorMessage>
      </FormControl>

      <FormControl isInvalid={'type' in errors && touched.type}>
        <HStack alignItems={'center'}>
          <Icon as={<MaterialCommunityIcons name={'lock-clock'} />} size={7} mr='2' color='gray.600' />
          <FormControl isInvalid={'time' in errors && touched.time}>
            <Hidden till={'md'}>
              <HStack alignItems={'center'}>
                <HStack justifyContent={'space-between'}>
                  <Pressable onPress={() => setVisibleCalendar(true)} mr={4}>
                    <Input
                      value={date?.toLocaleDateString()}
                      InputRightElement={<Icon as={<FontAwesome name={'calendar'} />} size={4} mr='2' color='primary.600' />}
                    />
                  </Pressable>
                  <DatePickerModal
                    locale='en'
                    mode='single'
                    visible={visibleCalendar}
                    onDismiss={onDismissCalendar}
                    date={date}
                    onConfirm={onConfirmCalendar}
                  />
                  <Pressable onPress={() => setVisibleTime(true)}>
                    <Input
                      value={`${time.hours.toString()}:00`}
                      InputRightElement={<Icon as={<Feather name={'clock'} />} size={4} mr='2' color='primary.600' />}
                    />
                    <TimePickerModal
                      visible={visibleTime}
                      onDismiss={onDismissTime}
                      onConfirm={onConfirmTime}
                      hours={time.hours}
                      minutes={time.minutes}
                    />
                  </Pressable>
                </HStack>
              </HStack>
            </Hidden>
            <Hidden from={'md'}>
              <VStack mr={9}>
                <Pressable onPress={() => setVisibleCalendar(true)} mb={2}>
                  <Input
                    value={date?.toLocaleDateString()}
                    InputRightElement={<Icon as={<FontAwesome name={'calendar'} />} size={4} mr='2' color='primary.600' />}
                  />
                </Pressable>
                <DatePickerModal
                  locale='en'
                  mode='single'
                  visible={visibleCalendar}
                  onDismiss={onDismissCalendar}
                  date={date}
                  onConfirm={onConfirmCalendar}
                />
                <Pressable onPress={() => setVisibleTime(true)}>
                  <Input
                    value={`${time.hours.toString()}:00`}
                    InputRightElement={<Icon as={<Feather name={'clock'} />} size={4} mr='2' color='primary.600' />}
                  />
                  <TimePickerModal
                    visible={visibleTime}
                    onDismiss={onDismissTime}
                    onConfirm={onConfirmTime}
                    hours={time.hours}
                    minutes={time.minutes}
                  />
                </Pressable>
              </VStack>
            </Hidden>
            <FormControl.ErrorMessage>{errors.time}</FormControl.ErrorMessage>
          </FormControl>
          {/*)}*/}
        </HStack>
        <FormControl.ErrorMessage>{errors.type}</FormControl.ErrorMessage>
      </FormControl>

      <FormControl>
        <HStack alignItems={'center'}>
          <Icon as={<MaterialIcons name={'description'} />} size={7} mr='2' color='gray.600' />
          <TextArea
            fontSize={'medium'}
            flex={1}
            numberOfLines={5}
            placeholder='Enter your description'
            value={values.description}
            onBlur={handleBlur('description')}
            onChangeText={handleChange('description')}
            autoCompleteType='off'
          />
        </HStack>
        <FormControl.ErrorMessage>{errors.description}</FormControl.ErrorMessage>
      </FormControl>
      {/*<Text color={'red.500'}>{messageNotAvailableDoctor}</Text>*/}
    </VStack>
  );
};
export default CreateAppointmentForm;