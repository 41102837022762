import React, { useEffect, useRef } from 'react';
import { Box, FlatList, Hidden, HStack, Icon, Image, Pressable, Text, VStack } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Category } from '../../components/CategoriesPatient';
import { RootStackScreensEnum } from '../../navigation/enum';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { PatientStackParamList } from '../../types/Stacks';
import { Animated } from 'react-native';

const services: Category[] = [
  {
    id: 1,
    imageUri: require('../../assets/images/doctor-blue.jpg'),
    text: 'Doctor List',
    icon: 'doctor',
    iconColor: 'customBlue'
  },
  {
    id: 2,
    imageUri: require('../../assets/images/doctor-green.jpg'),
    text: 'Health Store',
    icon: 'store',
    iconColor: 'primary.600'
  },
  {
    id: 3,
    imageUri: require('../../assets/images/doctor-turquoise.jpg'),
    text: 'Contact With Doctor',
    icon: 'calendar',
    iconColor: 'customTurquoise'
  },
  {
    id: 4,
    imageUri: require('../../assets/images/doctor-pink.jpg'),
    text: `Health care services`,
    icon: 'heart',
    iconColor: 'red.600'
  }
];
const ServiceCard = () => {
  const navigation = useNavigation<NavigationProp<PatientStackParamList, RootStackScreensEnum.DashboardPatient>>();
  const onPressCategory = (category: Category) => {
    switch (category.text) {
      case 'Doctor List':
        navigation.navigate(RootStackScreensEnum.DoctorListPatient);
        break;
      case 'Health Store':
        navigation.navigate(RootStackScreensEnum.HealthStore);
        break;
      case 'Contact With Doctor':
        navigation.navigate(RootStackScreensEnum.ChatWithDoctor);
        break;
      default:
        navigation.navigate(RootStackScreensEnum.DashboardPatient);
        break;
    }
  };
  const animatedValue = useRef(new Animated.Value(2000)).current;

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: 10,
      duration: 1700,
      useNativeDriver: false
    }).start();
  }, [animatedValue]);
  return (
    <>
      <Hidden from={'md'}>
        <>
          <Text ml={5} mt={3} fontSize={'20'} fontWeight={'bold'} color={'coolGray.900'}>
            Popular Services
          </Text>
          <FlatList
            data={services}
            horizontal
            w={'100%'}
            m={'auto'}
            showsHorizontalScrollIndicator={false}
            keyExtractor={(item) => item.id?.toString() ?? ''}
            renderItem={({ item }) => {
              return (
                <>
                  <Pressable
                    shadow={5}
                    w={'90vw'}
                    h={'200px'}
                    borderWidth={1}
                    borderColor={'coolGray.200'}
                    rounded={'xl'}
                    flex={1}
                    my={3}
                    mx={5}
                    key={item.id}
                    onPress={() => onPressCategory(item)}>
                    <HStack alignItems='Center' position={'relative'}>
                      <Box position={'absolute'} w={'100%'} h={'100%'}>
                        <Image source={item.imageUri ?? ''} rounded={'md'} w={'100%'} h={'200px'} />
                      </Box>
                      <VStack>
                        <Text w={'100%'} fontSize={'16'} color={'primary'} zIndex={100} fontWeight={'bold'} pl={4} pt={6}>
                          {item.text}
                        </Text>
                        <Box zIndex={100} p={2} position={'absolute'} top={32} left={4} rounded={'full'} bg={'white'}>
                          <Icon size={'10'} as={MaterialCommunityIcons} name={item.icon} color={item.iconColor} />
                        </Box>
                      </VStack>
                    </HStack>
                  </Pressable>
                </>
              );
            }}
          />
        </>
      </Hidden>
      <Hidden till={'md'}>
        <>
          <Text textTransform={'uppercase'} fontSize={'3xl'} fontWeight={'bold'} py={{ base: '2', md: '30' }}>
            Popular services
          </Text>
          <HStack justifyContent={'center'} w={'100%'} mb={12}>
            {services.map((item) => {
              return (
                <Animated.View
                  key={item.id}
                  style={{
                    marginTop: animatedValue
                  }}>
                  <Box key={item.id} w={{ base: 48, md: '330' }} h={{ base: 56, md: '440' }} rounded={'3xl'} position={'relative'} shadow={9} m={5}>
                    <Text w={'55%'} fontSize={{ base: 'md', md: '2xl' }} color={'primary.900'} zIndex={100} fontWeight={'medium'} pl={4} pt={6}>
                      {item.text}
                    </Text>
                    <Box zIndex={100} position={'absolute'} bottom={8} left={8} p={5} rounded={'full'} bg={'white'}>
                      <Icon size={'3xl'} as={MaterialCommunityIcons} name={item.icon} color={item.iconColor} />
                    </Box>
                    <Pressable position={'absolute'} w={'100%'} h={'100%'} onPress={() => onPressCategory(item)}>
                      <Image rounded={'3xl'} source={item.imageUri} alt={'Image'} w={'100%'} h={'100%'} />
                    </Pressable>
                  </Box>
                </Animated.View>
              );
            })}
          </HStack>
        </>
      </Hidden>
    </>
  );
};
export default ServiceCard;
