import React from 'react';
import { VStack } from 'native-base';
import PatientChatAdd from '../../components/PatientChatAdd';
import SearchInput from '../../components/SearchInput';
import Wrapper from '../../components/Wrapper';
import DashboardLayoutDoctor from '../../layouts/DashboardLayoutDoctor';

const ChatWithPatient = () => {
  return (
    <DashboardLayoutDoctor displayScreenTitle={true} title='Chat List With Patient' rightPanelMobileHeader={true}>
      <VStack alignItems={''} flex={1} px={{ base: 4, md: 0, lg: 8 }}>
        <Wrapper>
          <SearchInput placeholder='Example: Dr.Lia or Gynecologist' />
        </Wrapper>
        <Wrapper>
          <PatientChatAdd />
        </Wrapper>
      </VStack>
    </DashboardLayoutDoctor>
  );
};

export default ChatWithPatient;
