// Get SFUser by ID
import { GetSFUserQuery, SFUser, SFUserByUserIDQuery } from '../../API';
import { API, graphqlOperation } from 'aws-amplify';
import { getSFUser, sFUserByUserID } from '../../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';

export const getSFUserById = async (id: string): Promise<SFUser | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(getSFUser, {
        id
      })
    )) as GraphQLResult<GetSFUserQuery>;

    if (result.data?.getSFUser) {
      return !result.data.getSFUser._deleted ? result.data.getSFUser : null;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const getSFUserByUserId = async (userID: string): Promise<SFUser | null> => {
  try {
    const result = (await API.graphql(
      graphqlOperation(sFUserByUserID, {
        sFUserUserId: userID
      })
    )) as GraphQLResult<SFUserByUserIDQuery>;

    if (result.data?.sFUserByUserID?.items && !result.data.sFUserByUserID.items[0]?._deleted) {
      return result.data.sFUserByUserID.items[0];
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};
