import { Message } from '../API';

// Add an 'ok' field of type boolean as optional
export type IMessage = Omit<
  Message,
  'updatedAt' | 'owner' | '_version' | '_deleted' | '_lastChangedAt' | '__typename' | 'user' | 'chatRoom' | 'ownerUserID'
> & {
  ok?: boolean;
  contentHash?: string;
  isTranslated?: boolean;
};

export const cloneMessage = (message: Message): IMessage => {
  return {
    id: message.id,
    timestamp: message.timestamp,
    text: message.text,
    translatedText: message.translatedText,
    image: message.image,
    isDefaultMessage: message.isDefaultMessage,
    completionTokens: message.completionTokens,
    chatRoomID: message.chatRoomID,
    userID: message.userID,
    createdAt: message.createdAt
  };
  // return cloneObject<Message, IMessage>(message, keys<Message>(), keys<IMessage>());
};

export const createEmptyMessage = (): IMessage => {
  return {
    id: '',
    createdAt: '',
    text: '',
    translatedText: '',
    image: '',
    chatRoomID: '',
    userID: '',
    timestamp: '',
    completionTokens: 0,
    isDefaultMessage: false,
    ok: true,
    contentHash: ''
  };
};
