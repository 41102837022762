import React from 'react';
import { Hidden, Text, VStack } from 'native-base';
import DashboardLayout from '../../layouts/DashboardLayout';
import AccountMobileFooter from './AccountMobileFooter';

const Profile = () => {
  return (
    <DashboardLayout title='Account' displayMenuButton displayScreenTitle={false} displayAlternateMobileHeader rightPanelMobileHeader={true}>
      <VStack>
        <Text mx={5} mt={10} fontSize={{ base: '20', md: '24' }} fontWeight={'bold'} color={'#2D2D2D'}>
          {' '}
          Profile{' '}
        </Text>
      </VStack>
      <Hidden from={'md'}>
        <AccountMobileFooter />
      </Hidden>
    </DashboardLayout>
  );
};
export default Profile;
