import React, { useEffect, useState } from 'react';
import { Avatar, Box, Divider, Hidden, HStack, Icon, Input, Pressable, ScrollView, Text, Tooltip, VStack } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import { RootStackScreensEnum } from '../../navigation/enum';
import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { AdminStackParamList } from '../../types/Stacks';
import { useAppSelector } from '../../store/hooks';
import { selectAllPatients } from '../../store/hospital/patients/patients-slice';
import { useDebounce } from '../../utils/debounce';
import { selectAllUsers } from '../../store/hospital/users/users-slice';
import { IUser } from '../../types/IUser';
import { IPatient } from '../../types/IPatient';
import AdminMobileFooter from './AdminMobileFooter';
import { formatDate } from '../../utils/time_utils';
import SearchBarWeb from './SearchBarWeb';

interface IMyComponentProps {
  data: IPatient[];
  patientIdParam: string;
}

const MyComponent = ({ data, patientIdParam }: IMyComponentProps) => {
  const navigation = useNavigation<NativeStackNavigationProp<AdminStackParamList, RootStackScreensEnum.PatientList>>();
  const users = useAppSelector((state) => selectAllUsers(state.hospital.users));

  useEffect(() => {
    console.log('patientIdParam', patientIdParam);
  }, [patientIdParam]);
  return (
    <Box backgroundColor={'white'} borderRadius={'xl'} mb={12}>
      {data.map((item: IPatient, index) => {
        const patientUser: IUser | undefined = users.find((user: IUser) => user.id === item.patientUserId);
        return (
          <Pressable
            onPress={() => navigation.navigate(RootStackScreensEnum.MedicalRecordDetailMobile, { patientId: item.id })}
            flex={1}
            key={item.id.toString()}>
            <HStack
              borderBottomWidth={1}
              px={4}
              py={2}
              space={2}
              alignItems='center'
              _light={{ borderBottomColor: 'coolGray.200' }}
              _dark={{ borderBottomColor: 'coolGray.700' }}>
              <Avatar source={patientUser?.imageUrl ? { uri: patientUser?.imageUrl } : undefined} height={10} width={10}>
                {patientUser?.lastName.charAt(0).toUpperCase()}
              </Avatar>
              <VStack>
                <Text ml={2} fontWeight='medium' fontSize='16' lineHeight='21' _light={{ color: 'coolGray.900' }} _dark={{ color: 'coolGray.400' }}>
                  {patientUser?.firstName + ' ' + patientUser?.lastName}
                </Text>
                <Text ml={2} fontSize='16' lineHeight='21' _light={{ color: '#4EA0F7' }} _dark={{ color: 'coolGray.400' }}>
                  Status: {item?.status}
                </Text>
              </VStack>
            </HStack>
          </Pressable>
        );
      })}
    </Box>
  );
};
const MedicalRecordListMobile = () => {
  const patients = useAppSelector((state) => selectAllPatients(state.hospital.patients));
  const users = useAppSelector((state) => selectAllUsers(state.hospital.users));
  const route = useRoute();
  const [textInput, setTextInput] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<IPatient[]>([]);
  const textSearch = useDebounce(textInput, 1000);
  const [patientId, setPatientId] = useState('');
  const navigation = useNavigation<any>();

  useEffect(() => {
    const patientIdParam = route.params as { patientId: string };
    if (patientIdParam?.patientId) {
      setPatientId(patientIdParam.patientId);
    } else {
      navigation.navigate(RootStackScreensEnum.MedicalRecordScreen, { patientId: searchResults[0]?.id });
    }
  }, [route.params, searchResults.length, patients.length]);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const handleToggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    setTextInput('');
    // setSearchResults(patients);
  };
  const handleSearchTextChange = (text: string) => {
    setTextInput(text);
  };

  useEffect(() => {
    const searchValue = textInput.toLowerCase();
    const arr = patients.filter((item) => {
      // Find the user by firstName + lastName
      const foundNameUsers = users.filter((user: IUser) => {
        const fullName = user.firstName + ' ' + user.lastName;
        return fullName.toLowerCase().includes(searchValue);
      });

      // Find the user by email
      const foundEmailUsers = users.filter((user: IUser) => {
        return user.email.toLowerCase().includes(searchValue);
      });

      // Return the list of patients that has patientUserId in the list of found users
      return foundNameUsers.some((user) => user.id === item.patientUserId) || foundEmailUsers.some((user) => user.id === item.patientUserId);
    });
    console.log(arr);
    setSearchResults(arr);
  }, [textSearch]);
  return (
    <>
      <HStack alignItems={'center'} justifyContent={'space-between'} height={'64px'} w={'100%'}>
        {!isSearchOpen && (
          <HStack alignItems={'center'} w={'100%'}>
            <Text w={'95%'} fontSize='20' fontWeight='bold' pl={4} _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }}>
              Select Patient
            </Text>
            {/*<Hidden till='md'>*/}
            <Tooltip label='Filter by chat content'>
              <Pressable
                onPress={handleToggleSearch}
                w={'5%'}
                mr={4}
                p={4}
                alignItems='center'
                justifyContent='center'
                size={'10'}
                rounded='full'
                _hover={{ bg: '#E4EBE4' }}>
                <Icon as={<MaterialIcons name='search' />} size={8} color='coolGray.900' />
              </Pressable>
            </Tooltip>
            {/*</Hidden>*/}
          </HStack>
        )}
        <Box w={'100%'} px={2}>
          {isSearchOpen && (
            <SearchBarWeb textInput={textInput} handleSearchTextChange={handleSearchTextChange} handleToggleSearch={handleToggleSearch} />
          )}
        </Box>
      </HStack>
      <Divider />
      <ScrollView showsHorizontalScrollIndicator={false}>
        <MyComponent patientIdParam={patientId} data={searchResults || []} />
      </ScrollView>
      {/*</VStack>*/}
      <AdminMobileFooter />
    </>
  );
};
export default MedicalRecordListMobile;
