import React, { useRef, useState, useEffect } from 'react';
import { FlatList, HStack, Icon, Image, View } from 'native-base';
import { Dimensions, TouchableOpacity } from 'react-native';
import { AntDesign } from '@expo/vector-icons';

const images = [
  {
    id: 1,
    uri: 'https://images.unsplash.com/photo-1586773860418-d37222d8fce3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80'
  },
  {
    id: 2,
    uri: 'https://images.unsplash.com/photo-1538108149393-fbbd81895907?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1528&q=80'
  },
  {
    id: 3,
    uri: 'https://images.unsplash.com/photo-1519494026892-80bbd2d6fd0d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1453&q=80'
  },
  {
    id: 4,
    uri: 'https://images.unsplash.com/photo-1504813184591-01572f98c85f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80'
  },
  {
    id: 5,
    uri: 'https://images.unsplash.com/photo-1629909613654-28e377c37b09?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1468&q=80'
  }
];

type ImageItem = {
  id: number;
  uri: string;
};

const ImageSlide = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const flatListRef = useRef<any>(null);
  const screenWidth = Dimensions.get('window').width;

  const handleNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const renderItem = ({ item }: any) => <Image source={{ uri: item.uri }} style={{ width: screenWidth, height: 200 }} />;
  return (
    <View>
      <FlatList
        ref={flatListRef}
        horizontal
        pagingEnabled
        data={images}
        keyExtractor={(item) => item.id.toString()}
        renderItem={renderItem}
        onMomentumScrollEnd={(event) => {
          const newIndex = Math.round(event.nativeEvent.contentOffset.x / screenWidth);
          setCurrentIndex(newIndex);
        }}
      />
      {/*<HStack justifyContent='space-between' position='absolute' top='50%' left={0} right={0}>*/}
      {/*  <TouchableOpacity onPress={handlePrevImage}>*/}
      {/*    <Icon as={AntDesign} name='left' size={12} />*/}
      {/*  </TouchableOpacity>*/}
      {/*  <TouchableOpacity onPress={handleNextImage}>*/}
      {/*    <Icon as={AntDesign} name='right' size={12} />*/}
      {/*  </TouchableOpacity>*/}
      {/*</HStack>*/}
    </View>
  );
};
export default ImageSlide;
