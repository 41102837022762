import React from 'react';
import { Text, VStack, FlatList, Center, Pressable, Image, Button, Box } from 'native-base';
import { ImageSourcePropType } from 'react-native';

export type Category = {
  id?: number | string;
  imageUri: ImageSourcePropType;
  text: string;
  icon?: string;
  iconColor?: string;
};

type CategoryProps = {
  categories: Category[];
  hasSeeAllFooter?: boolean;
  numColumns?: number;
  imageSize?: number;
  onPressCategory?: (item: Category) => void;
};

const CategoriesPatient = ({ categories, hasSeeAllFooter = false, numColumns = 4, imageSize = 64, onPressCategory }: CategoryProps) => {
  const onPress = (item: Category) => {
    if (onPressCategory) {
      onPressCategory(item);
    }
  };

  return (
    <Box mt={1}>
      <FlatList
        data={categories}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => {
          return (
            <Pressable
              onPress={() => onPress(item)}
              _hover={{ shadow: 7 }}
              p={{ base: 2, md: 4 }}
              m={2}
              borderWidth={1}
              borderColor={'coolGray.200'}
              shadow={3}>
              <VStack flex={1} w={{ base: `${imageSize + 32}px`, md: `${imageSize + 44}px` }} justifyContent={'space-between'}>
                <Center
                  rounded='full'
                  w={{ base: `${imageSize}px`, md: `${imageSize + 16}px` }}
                  h={{ base: `${imageSize}px`, md: `${imageSize + 16}px` }}
                  mx='auto'>
                  <Image source={item.imageUri} alt='Alternate Text' size={{ base: `${imageSize}px`, md: `${imageSize + 16}px` }} />
                </Center>
                <VStack flex={1} justifyContent={'center'}>
                  <Text mt='1' fontSize={{ base: 'xs', md: 'sm' }} textAlign='center'>
                    {item.text}
                  </Text>
                </VStack>
              </VStack>
            </Pressable>
          );
        }}
      />
      {hasSeeAllFooter && (
        <Button
          borderColor='red.700'
          borderWidth={1}
          rounded='md'
          variant='ghost'
          mt={6}
          py='3'
          _text={{
            color: 'red.700'
          }}
          _hover={{
            _text: {
              color: 'white'
            },
            bg: 'red.700'
          }}>
          See All
        </Button>
      )}
    </Box>
  );
};

export default CategoriesPatient;
