import React, { useEffect } from 'react';
import { Box, HStack, Icon, Pressable, Text, VStack } from 'native-base';
import { Fontisto } from '@expo/vector-icons';
import { FlatList } from 'react-native';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { medicalRecordActions, selectAllMedicalRecords } from '../../store/hospital/medical-record/medical-records-slice';
import { formatDate } from '../../utils/time_utils';
import { useNavigation, useRoute } from '@react-navigation/native';
import { RootStackScreensEnum } from '../../navigation/enum';

const MedicalListMobile = () => {
  const records = useAppSelector((state) => selectAllMedicalRecords(state.hospital.medicalRecords));
  const dispatch = useAppDispatch();
  // const route = useRoute();
  // const { patientId } = route.params as any;
  const navigation = useNavigation<any>();

  useEffect(() => {
    if (records && records.length > 0 && records[0].id) {
      dispatch(medicalRecordActions.setSelectedMedicalRecord(records[0].id));
    }
  }, [records]);

  const handlePressItem = (id: string) => {
    // dispatch(medicalRecordActions.setSelectedMedicalRecord(id));
    // to do: navigate to MedicalDetailMobile component by passing id
    navigation.navigate(RootStackScreensEnum.MedicalDetailMobile, { medicalRecordId: id });
  };

  return (
    <>
      <VStack w={{ base: '100%', md: '100%' }} h={'100%'} backgroundColor={'white'} mb={{ base: '12', md: '0' }} rounded={'lg'} space='2'>
        <VStack mt={3} w={'90%'} p={3} mx={5}>
          <Text fontWeight={'bold'} fontSize={'20'} color={'coolGray.900'} mb={5}>
            Medical Records
          </Text>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={records}
            renderItem={({ item }) => (
              <Pressable
                shadow={5}
                borderWidth={1}
                borderColor={'coolGray.200'}
                rounded={'xl'}
                flex={1}
                mb={3}
                px={2}
                onPress={() => {
                  handlePressItem(item.id);
                }}>
                <HStack alignItems={'center'} py={2}>
                  <Box mr={5} backgroundColor={'#4EA0F7'} width={10} height={10} rounded={'full'} justifyContent={'center'} alignSelf={'center'}>
                    <Icon size='6' alignSelf={'center'} name={'bed-patient'} color={'white'} as={Fontisto} />
                  </Box>
                  <VStack>
                    <Text color={'coolGray.900'} fontSize={'16'}>
                      {item.diagnosis}
                    </Text>
                    <Text color={'coolGray.500'} fontSize={'14'}>
                      {formatDate(item.timestamp)}
                    </Text>
                  </VStack>
                </HStack>
              </Pressable>
            )}
            keyExtractor={(item) => item.id.toString()}
          />
        </VStack>
      </VStack>
    </>
  );
};
export default MedicalListMobile;
