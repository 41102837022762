import React from 'react';
import { Avatar, Box, Hidden, HStack, Icon, Image, Text, VStack } from 'native-base';
import { Foundation, Ionicons } from '@expo/vector-icons';

const WaitingRoomModalContent = ({ item }: any) => {
  return (
    <>
      <Hidden till={'md'}>
        <VStack>
          {/* Patient Information */}
          <HStack justifyContent={'space-around'}>
            {/* Meeting Information */}
            <HStack mt={4} alignItems='center'>
              <Icon size={32} name='calendar' color='#4EA0F7' as={Foundation} />
              <VStack>
                <HStack>
                  <Text fontSize='xl'>You have a meeting at</Text>
                  <Text ml={2} fontWeight='bold' fontSize='xl'>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    at {item?.hour} o'clock
                  </Text>
                </HStack>
                <HStack>
                  <Text fontSize='xl'>Date:</Text>
                  <Text ml={2} fontWeight='bold' fontSize='xl'>
                    {item?.date}
                  </Text>
                </HStack>
              </VStack>
            </HStack>
            {/* Assigned Doctor */}
            <HStack mt={4} alignItems='center'>
              {item?.doctor?.user?.imageUrl ? (
                <Avatar source={item.doctor.user.imageUrl} size='32' />
              ) : (
                <Box rounded={'full'} bg={'coolGray.600'} size='32' alignItems={'center'} justifyContent={'center'}>
                  <Text fontSize={'xl'} fontWeight={'bold'} color={'white'}>
                    {item?.doctor?.user?.lastName.charAt(0).toUpperCase()}
                  </Text>
                </Box>
              )}
              <VStack pl={5} justifyContent='space-around'>
                <Text fontSize='lg' color='black'>
                  Assigned Doctor
                </Text>
                <Text fontWeight='bold' fontSize='xl'>
                  {item?.doctor?.user?.firstName + ' ' + item?.doctor?.user?.lastName}
                </Text>
                <Text color='black' fontSize='lg'>
                  {item?.doctor?.specialist}
                </Text>
              </VStack>
            </HStack>
          </HStack>

          {/* Waiting Patients */}
          <VStack mx={10} alignSelf={'center'}>
            <HStack mt={20}>
              <Text fontWeight='bold' fontSize='lg' color='black'>
                03
              </Text>
              <Text ml={2} fontSize='lg' color='black'>
                patients are before you. Please wait for your turn.
              </Text>
            </HStack>
            <HStack alignSelf={'center'} mb={10} mt={2}>
              <Icon size={24} name='person-outline' color='#4EA0F7' as={Ionicons} />
              <Icon size={24} name='person-outline' color='#4EA0F7' as={Ionicons} />
              <Icon size={24} name='person-outline' color='#4EA0F7' as={Ionicons} />
            </HStack>
          </VStack>
        </VStack>
      </Hidden>
      <Hidden from={'md'}>
        <VStack>
          {/* Patient Information */}
          <VStack>
            {/* Meeting Information */}
            <HStack mt={4} alignItems='center'>
              <Icon size={20} name='calendar' color='#4EA0F7' as={Foundation} />
              <VStack>
                <VStack>
                  <Text fontSize='16'>You have a meeting at</Text>
                  <Text fontWeight='bold' fontSize='16'>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    at {item?.hour} o'clock
                  </Text>
                </VStack>
                <VStack>
                  <Text fontSize='16'>Date:</Text>
                  <Text fontWeight='bold' fontSize='16'>
                    {item?.date}
                  </Text>
                </VStack>
              </VStack>
            </HStack>
            {/* Assigned Doctor */}
            <HStack mt={4} alignItems='center'>
              {item?.doctor?.user?.imageUrl ? (
                <Image source={item?.doctor?.user?.imageUrl} size='56px' />
              ) : (
                <Box rounded={'full'} bg={'coolGray.600'} size='20' alignItems={'center'} justifyContent={'center'}>
                  <Text fontSize={'20'} fontWeight={'bold'} color={'white'}>
                    {item?.doctor?.user?.lastName.charAt(0).toUpperCase()}
                  </Text>
                </Box>
              )}
              <VStack ml={5} justifyContent='space-around'>
                <Text fontSize='16' color='coolGray.900'>
                  Assigned Doctor
                </Text>
                <Text fontWeight='bold' fontSize='16' color={'coolGray.900'}>
                  {item?.doctor?.user?.firstName + ' ' + item?.doctor?.user?.lastName}
                </Text>
                <Text color='coolGray.900' fontSize='16'>
                  Specialist
                </Text>
                <Text color='coolGray.900' fontSize='16' fontWeight={'bold'}>
                  {item?.doctor?.specialist}
                </Text>
              </VStack>
            </HStack>
          </VStack>

          {/* Waiting Patients */}
          <VStack alignSelf={'center'}>
            <HStack mt={10}>
              <Text mr={1} fontWeight='bold' fontSize='16' color='black'>
                03
              </Text>
              <Text fontSize='16' color='black'>
                patients are before you. Please wait for your turn.
              </Text>
            </HStack>
            <HStack alignSelf={'center'} mb={10} mt={2}>
              <Icon size={16} name='person-outline' color='#4EA0F7' as={Ionicons} />
              <Icon size={16} name='person-outline' color='#4EA0F7' as={Ionicons} />
              <Icon size={16} name='person-outline' color='#4EA0F7' as={Ionicons} />
            </HStack>
          </VStack>
        </VStack>
      </Hidden>
    </>
  );
};

export default WaitingRoomModalContent;
