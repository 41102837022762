import React, { useState } from 'react';
import { Box, VStack, ScrollView, StatusBar, HStack, Pressable, Icon, Text, Hidden, useColorModeValue, Slide, Image } from 'native-base';
import { AntDesign, MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import MenuProfile from '../components/MenuProfile';
import { useAuth } from '../components/auth/AmplifyAuthProvider';
import { useNavigation } from '@react-navigation/native';
import { RootStackScreensEnum } from '../navigation/enum';
import HeaderPatient from './HeaderPatient';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { MainStackParamList, PatientStackParamList } from '../types/Stacks';
import { useWindowDimensions } from 'react-native';
import ChatPanel from '../screens/Chat/ChatPanel';

type DashboardLayoutProps = {
  scrollable?: boolean;
  displayScreenTitle?: boolean;
  displayBackButton?: boolean;
  showIcons?: boolean;
  displaySearchButton?: boolean;
  displayNotificationButton?: boolean;
  displayMenuButton?: boolean;
  displayAlternateMobileHeader?: boolean;
  maxWidth?: number;
  header?: {
    searchbar: boolean;
  };
  mobileHeader?: {
    backButton: boolean;
  };
  title: string;
  subTitle?: string;
  children?: React.ReactNode;
  showGroupInfoHeader?: boolean;
  displayBackIcon?: boolean;
  rightPanelMobileHeader?: boolean;
  rightPanelHasLocation?: boolean;
  scrollValue?: number;
};

type MainContentProps = DashboardLayoutProps;

type MobileHeaderProps = {
  title: string;
  subTitle?: string;
  backButton: boolean;
  rightPanel?: boolean;
  rightPanelHasLocation?: boolean;
};

function MainContent(props: MainContentProps) {
  const navigation = useNavigation<NativeStackNavigationProp<PatientStackParamList, RootStackScreensEnum.DashboardPatient>>();
  return (
    <VStack maxW={props.maxWidth} flex={1} width='100%'>
      {props.displayScreenTitle && (
        <Hidden till='md'>
          <HStack mb='4' space={4}>
            <Pressable onPress={() => navigation.navigate(RootStackScreensEnum.DashboardPatient)}>
              <Icon size='6' pt='0.5' as={AntDesign} name={'arrowleft'} _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }} />
            </Pressable>
            <VStack>
              <Text fontSize='lg' fontWeight='medium' _dark={{ color: 'coolGray.50' }} _light={{ color: 'coolGray.800' }}>
                {props.title}
              </Text>
              <Text _dark={{ color: 'coolGray.50' }} _light={{ color: 'coolGray.800' }} fontSize='sm' fontWeight='normal'>
                {props.subTitle}
              </Text>
            </VStack>
          </HStack>
        </Hidden>
      )}
      {props.children}
    </VStack>
  );
}

export function MobileHeader(props: MobileHeaderProps) {
  const { user } = useAuth();
  const navigation = useNavigation<NativeStackNavigationProp<PatientStackParamList, RootStackScreensEnum.DashboardPatient>>();
  return (
    <Box px='1' py={3} bg='white'>
      <HStack justifyContent='space-between'>
        <HStack flex='1' justifyContent='space-between' alignItems='center' px={2}>
          <>
            <HStack alignItems='center' space='1'>
              {/*<Icon as={<Ionicons name='menu' />} size={6} color={'black'} />*/}
              <Box flex={1}>
                <Text color={'#4485FD'} fontSize={24} fontFamily={'Sketch_Block'} fontWeight={'bole'}>
                  Teledoc
                </Text>
              </Box>
              <VStack>
                <Text color='coolGray.50' fontSize='lg'>
                  {props.title}
                </Text>
                {props.subTitle ? (
                  <Text color='coolGray.50' fontSize='sm' fontWeight='normal'>
                    {props.subTitle}
                  </Text>
                ) : undefined}
              </VStack>
            </HStack>


            {props.title !== 'Dashboard' && props.backButton ? (
              <HStack alignItems='center' space={2}>
                <Pressable onPress={() => navigation.navigate(RootStackScreensEnum.DashboardPatient)}>
                  <Icon size='6' as={AntDesign} name='arrowleft' />
                </Pressable>
                <Text fontSize='md' fontWeight={'medium'}>
                  {props.title}
                </Text>
              </HStack>
            ) : (
              <HStack alignItems='center' space='1'>
                <MenuProfile />
                <HStack space='1' ml={1} alignItems='center'>
                  <Text fontSize='md' textTransform='uppercase' fontWeight='bold'>
                    {user?.firstName}
                  </Text>
                  {/*<Icon as={<MaterialIcons name='search' />} size={7} color='coolGray.700' />*/}
                </HStack>
              </HStack>
            )}

            {/* right panel */}
            {/*{props.rightPanel &&*/}
            {/*  (!props.rightPanelHasLocation ? (*/}
            {/*    <Icon as={<MaterialCommunityIcons name='clock-time-four-outline' />} size={7} color='coolGray.500' />*/}
            {/*  ) : (*/}
            {/*    <HStack space='1' alignItems='center'>*/}
            {/*      <Icon as={<MaterialIcons name='search' />} size={7} color='coolGray.700' />*/}
            {/*    </HStack>*/}
            {/*  ))}*/}
          </>
        </HStack>
      </HStack>
    </Box>
  );
}

export default function DashboardLayoutPatient({
  displayScreenTitle = true,
  header = {
    searchbar: false
  },
  maxWidth = 2000,
  mobileHeader = {
    backButton: true
  },
  ...props
}: DashboardLayoutProps) {
  return (
    <>
      <StatusBar translucent backgroundColor='transparent' barStyle='light-content' />
      <Box safeAreaTop _light={{ bg: 'primary.900' }} _dark={{ bg: 'coolGray.900' }} />
      <VStack flex={1} _light={{ bg: 'primary.200' }} _dark={{ bg: 'customGray' }}>
        <Hidden from='md'>
          <MobileHeader
            title={props.title}
            subTitle={props.subTitle}
            backButton={mobileHeader.backButton}
            rightPanel={props.rightPanelMobileHeader}
            rightPanelHasLocation={props.rightPanelHasLocation}
          />
        </Hidden>
        <Hidden till='md'>
          <HeaderPatient title={props.title} subTitle={props.subTitle} searchbar={header.searchbar} />
        </Hidden>

        <Box flex={1} safeAreaBottom flexDirection={{ base: 'column', md: 'row' }} bgColor='white'>
          <Hidden till='md'>
            <>
              <ScrollView flex={1} p={{ md: 8 }} contentContainerStyle={{ alignItems: 'center', flexGrow: 1 }} showsVerticalScrollIndicator={true}>
                <MainContent {...props} displayScreenTitle={displayScreenTitle} maxWidth={maxWidth} />
              </ScrollView>
            </>
          </Hidden>

          <Hidden from='md'>
            <ScrollView showsVerticalScrollIndicator={false}>
              <MainContent {...props} displayScreenTitle={displayScreenTitle} />
            </ScrollView>
          </Hidden>
        </Box>
      </VStack>
    </>
  );
}
