import React from 'react';
import { Box, Button, HStack, Text, VStack } from 'native-base';

type TitleProps = {
  title?: string;
  subTitle?: string;
  hasSeeAllButton?: boolean;
};

const Title = ({ title, subTitle, hasSeeAllButton = false }: TitleProps) => {
  return title ? (
    <HStack mb={{ base: 4, md: 6 }} justifyContent='space-between' alignItems='center'>
      <VStack>
        {title && (
          <Text fontSize={{ base: 'md', md: 'lg' }} fontWeight='bold'>
            {title}
          </Text>
        )}
        {subTitle && <Text fontSize={{ base: 'sm', md: 'md' }}>{subTitle}</Text>}
      </VStack>
      {hasSeeAllButton && (
        <Box>
          <Button variant='ghost' justifyContent='flex-start' py='1' px='2' borderWidth={1} borderColor='blue.600' rounded='lg'>
            <Text fontSize='sm' fontWeight='medium' color='blue.500'>
              See All
            </Text>
          </Button>
        </Box>
      )}
    </HStack>
  ) : null;
};

export default Title;
