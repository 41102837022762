import React, { useEffect } from 'react';
import { Box, Button, Center, FormControl, Hidden, HStack, Image, Input, Link, Pressable, Spinner, Text, VStack } from 'native-base';
import GuestLayout from '../../layouts/GuestLayout';
import * as Yup from 'yup';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import { Formik } from 'formik';
import { useNavigation } from '@react-navigation/native';
import { RootStackScreensEnum } from '../../navigation/enum';
import { AuthStackParamList } from '../../types/Stacks';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

interface FormikState<Values> {
  isSubmitting: boolean;
}

const ForgotForm = () => {
  const { forgotPassword, errorMessage, setErrorMessage, successMessage, setSuccessMessage } = useAuth();
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('You must enter a valid email')
  });
  const navigation = useNavigation<NativeStackNavigationProp<AuthStackParamList, RootStackScreensEnum.SignIn>>();

  useEffect(() => {
    return () => {
      setErrorMessage('');
      setSuccessMessage('');
    };
  }, [setErrorMessage, setSuccessMessage]);

  const onSubmit = async (values: any) => {
    const { email } = values;

    try {
      await forgotPassword(email);

      // Redirect to code verification page
      navigation.navigate(RootStackScreensEnum.ResetPassword, {
        email: email
      });
    } catch (err) {
      // Handle error
      console.log('Forgot password error: ', err);
    }
  };
  return (
    <Formik
      initialValues={{
        email: ''
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      {({ touched, handleChange, handleBlur, handleSubmit, values, errors, isSubmitting, dirty }) => (
        <VStack space={4}>
          {/*BEGIN: Success or error message. Success message in green, error message in red*/}
          {successMessage && (
            <Box bg='green.400' px={4} py={3} rounded='md' _text={{ color: 'white' }}>
              {successMessage}
            </Box>
          )}
          {errorMessage && (
            <Box bg='red.400' px={4} py={3} rounded='md' _text={{ color: 'white' }}>
              {errorMessage}
            </Box>
          )}
          {/* END: Success or error message */}

          <FormControl isInvalid={'email' in errors && touched.email}>
            <FormControl.Label>Email</FormControl.Label>
            <Input
              onKeyPress={(event) => {
                if (event.nativeEvent.key === 'Enter') {
                  handleSubmit();
                }
              }}
              onBlur={handleBlur('email')}
              placeholder='Enter your email'
              onChangeText={handleChange('email')}
              value={values.email}
            />
            <FormControl.ErrorMessage>{errors.email}</FormControl.ErrorMessage>
          </FormControl>

          <Button
            w={'50%'}
            h={'10'}
            rounded={'xl'}
            alignSelf={'center'}
            // onPress={() => navigation.navigate(RootStackScreensEnum.ResetPassword)}
            disabled={isSubmitting || !dirty}
            onPress={() => handleSubmit()}
            bg='#66CDAA'
            _text={{ color: 'white', fontSize: 'xl', fontWeight: 'bold', fontFamily: 'Sketch_Block' }}>
            {isSubmitting ? (
              <Spinner accessibilityLabel='Loading' color={'white'} size={'lg'} fontWeight={'bold'} fontFamily={'Sketch_Block'} />
            ) : (
              <Text fontSize={'xl'} fontWeight={'bold'} fontFamily={'Patrickhand_Regular'} color={'white'}>
                Submit
              </Text>
            )}
          </Button>
        </VStack>
      )}
    </Formik>
  );
};

const ForgetComponent = () => {
  const navigation = useNavigation<NativeStackNavigationProp<AuthStackParamList, RootStackScreensEnum.SignIn>>();

  return (
    <>
      <MobileHeader />
      <Box
        py={8}
        px={{ base: 4, md: 8 }}
        flex={1}
        _light={{ bg: 'white' }}
        _dark={{ bg: 'coolGray.800' }}
        borderTopLeftRadius={{ base: '2xl', md: 0 }}
        borderTopRightRadius={{ base: '2xl', md: 'xl' }}
        borderBottomRightRadius={{ base: 0, md: 'xl' }}>
        <Text
          fontSize='5xl'
          fontWeight='bold'
          _light={{ color: '#66CDAA' }}
          _dark={{ color: '#66CDAA' }}
          textAlign='center'
          fontFamily='Patrickhand_Regular'>
          Forgot Password
        </Text>
        <ForgotForm />
        <HStack
          space='1'
          safeAreaBottom
          style={{ alignItems: 'center' }}
          justifyContent='center'
          mt={{ base: '8', md: '8' }}
          mb={{ base: '12', md: '0' }}>
          <Text fontSize='sm' fontWeight='normal' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }}>
            Back to
          </Text>
          <Pressable onPress={() => navigation.replace(RootStackScreensEnum.SignIn)}>
            <Link
              _text={{
                fontSize: { base: 'sm', md: 'xs' },
                fontWeight: 'bold',
                textDecoration: 'none'
              }}
              _light={{
                _text: {
                  color: '#66CDAA'
                }
              }}
              _dark={{
                _text: {
                  color: 'primary.500'
                }
              }}>
              Sign In
            </Link>
          </Pressable>
        </HStack>
      </Box>
    </>
  );
};

function SideContainerWeb() {
  return (
    <Center flex='1' _light={{ bg: '#E0FFFF' }} _dark={{ bg: '#00688B' }} borderTopLeftRadius={{ md: 'xl' }} borderBottomLeftRadius={{ md: 'xl' }}>
      <VStack style={{ alignItems: 'center' }}>
        <Image source={require('../../assets/logoApp.png')} height='400' width='400' alt='Alternate Text' />
      </VStack>
    </Center>
  );
}

function MobileHeader() {
  return (
    <Hidden from='md'>
      <VStack px='4' mt={24} mb='2' space='9'>
        <VStack space={0.5} mb={4}>
          <Text fontSize='4xl' fontWeight='bold' color='white'>
            Let&apos;s get started now!
          </Text>
          <Text
            fontSize='md'
            fontWeight='normal'
            _dark={{
              color: 'white'
            }}
            _light={{
              color: 'white'
            }}>
            Or create an account if not registered yet
          </Text>
        </VStack>
      </VStack>
    </Hidden>
  );
}

export default function SignIn() {
  return (
    <GuestLayout>
      <Hidden till='md'>
        <SideContainerWeb />
      </Hidden>

      <ForgetComponent />
    </GuestLayout>
  );
}
