import { createNativeStackNavigator, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { AdminStackParamList } from '../types/Stacks';
import { RootStackScreensEnum } from './enum';
import Dashboard from '../screens/AdminScreen/Dashboard';
import PatientList from '../screens/AdminScreen/PatientList';
import DoctorList from '../screens/AdminScreen/DoctorList';
import React, { useEffect } from 'react';
import { useAuth } from '../components/auth/AmplifyAuthProvider';
import { RootStackParamList } from './types';
import PatientDetails from '../screens/AdminScreen/PatientDetails';
import DoctorDetails from '../screens/AdminScreen/DoctorDetails';
import { useNavigation } from '@react-navigation/native';
import { graphQLFetchPatientsAndDispatch } from '../utils/graphql/patient_utils';
import { useAppDispatch } from '../store/hooks';
import { graphQLFetchUsersAndDispatch } from '../utils/graphql/user_utils';
import Roles from '../components/auth/role';
import MedicalRecordScreen from '../screens/AdminScreen/MedicalRecordScreen';
import ChatTotal from '../screens/Chat/ChatTotal';
import ContactChat from '../screens/Chat/ContactChat';
import MedicalDetailMobile from '../screens/AdminScreen/MedicalDetailMobile';
import ContactScreenMobile from '../screens/Chat/ContactScreenMobile';
import ChatScreenMobile from '../screens/Chat/ChatScreenMobile';
import DoctorTopDetailMobile from '../screens/AdminScreen/DoctorTopDetailMobile';
import MedicalRecordDetailMobile from '../screens/AdminScreen/MedicalRecordDetailMobile';
import DicomViewer from '../screens/AdminScreen/DicomViewer';
import FhirPatientDetails from '../screens/AdminScreen/FhirPatientDetails';

const AdminStack = createNativeStackNavigator<AdminStackParamList>();

const AdminStackScreen = () => {
  const { isSignedIn, checkRole, isReady } = useAuth();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList, RootStackScreensEnum.Main>>();
  const dispatch = useAppDispatch();

  // Navigate to AuthStack if user is not signed in or navigate to screen by role
  useEffect(() => {
    (async () => {
      if (isReady) {
        if (!isSignedIn) {
          navigation.replace(RootStackScreensEnum.Auth, { screen: RootStackScreensEnum.SignIn });
        }
        const role = await checkRole();
        if (role === Roles.Doctor) {
          navigation.replace(RootStackScreensEnum.Main, {
            screen: RootStackScreensEnum.Doctor,
            params: { screen: RootStackScreensEnum.DashboardDoctor }
          });
        }
        if (role === Roles.Patient) {
          navigation.replace(RootStackScreensEnum.Main, {
            screen: RootStackScreensEnum.Patient,
            params: { screen: RootStackScreensEnum.DashboardPatient }
          });
        }
      }
    })();
  }, [isSignedIn, navigation, checkRole]);

  // Pull patients and doctors using GraphQL
  useEffect(() => {
    (async () => {
      const role = await checkRole();
      if (!isSignedIn || role === Roles.Patient) {
        return;
      }
      if (role === Roles.Admin) {
        // Pull patients and dispatch to store
        await graphQLFetchPatientsAndDispatch(dispatch);

        // Pull users and dispatch to store
        await graphQLFetchUsersAndDispatch(dispatch);
      }
    })();
  }, [checkRole, dispatch, isSignedIn, isReady]);

  return (
    <AdminStack.Navigator
      screenOptions={{
        headerShown: false
      }}
      initialRouteName={RootStackScreensEnum.Dashboard}>
      <AdminStack.Screen name={RootStackScreensEnum.Dashboard} component={Dashboard} />
      <AdminStack.Screen name={RootStackScreensEnum.PatientList} component={PatientList} />
      <AdminStack.Screen name={RootStackScreensEnum.PatientDetails} component={PatientDetails} />
      <AdminStack.Screen name={RootStackScreensEnum.FhirPatientDetails} component={FhirPatientDetails} />
      <AdminStack.Screen name={RootStackScreensEnum.DoctorList} component={DoctorList} />
      <AdminStack.Screen name={RootStackScreensEnum.DoctorDetails} component={DoctorDetails} />
      <AdminStack.Screen name={RootStackScreensEnum.DoctorTopDetailMobile} component={DoctorTopDetailMobile} />
      <AdminStack.Screen name={RootStackScreensEnum.MedicalRecordScreen} component={MedicalRecordScreen} />
      <AdminStack.Screen name={RootStackScreensEnum.DicomViewer} component={DicomViewer} />
      <AdminStack.Screen name={RootStackScreensEnum.MedicalRecordDetailMobile} component={MedicalRecordDetailMobile} />
      <AdminStack.Screen name={RootStackScreensEnum.MedicalDetailMobile} component={MedicalDetailMobile} />
      <AdminStack.Screen name={RootStackScreensEnum.ContactScreenMobile} component={ContactScreenMobile} />
      <AdminStack.Screen name={RootStackScreensEnum.ChatTotal} component={ChatTotal} />
      <AdminStack.Screen name={RootStackScreensEnum.ChatScreenMobile} component={ChatScreenMobile} />
      <AdminStack.Screen name={RootStackScreensEnum.Contacts} component={ContactChat} />
    </AdminStack.Navigator>
  );
};

export default AdminStackScreen;
