import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Center, Divider, HStack, Icon, Input, Menu, Pressable, Spinner, Text, Tooltip, VStack } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { findNextChatRoom, selectAllChatRooms, setActiveChatRoom } from '../../store/chat/chat-rooms/chat-rooms-slice';
import { IChatRoom } from '../../types/IChatRoom';
import { friendlyTimestamp } from '../../utils/friendly_timestamp';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import { useChat } from './ChatProvider';
import { FlatList } from 'react-native';
import { useDebounce } from '../../utils/debounce';
import { selectActiveChatRoom, selectActiveChatRoomID } from '../../store/chat/chat-room/chat-room-slice';
import axios from 'axios';
import ModalLanguageForm from './ModalLanguageForm';
import { useNavigation, useRoute } from '@react-navigation/native';
import { RootStackScreensEnum } from '../../navigation/enum';
import SearchBarWeb from '../AdminScreen/SearchBarWeb';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const imageUri = require('../../assets/images/robot.png');

interface IChatItemProps {
  chatRoom: IChatRoom;
}

interface IChatItemProps {
  chatRoom: IChatRoom;
  roomRemoveHandler: (id: string) => Promise<void>;
}

const ChatItem = ({ chatRoom, roomRemoveHandler }: IChatItemProps) => {
  // const navigation = useNavigation<NativeStackNavigationProp<MainStackParamList, RootStackScreensEnum.ChatList>>();
  const activeChatRoomID = useAppSelector(selectActiveChatRoomID);
  const dispatch = useAppDispatch();
  const [opacity, setOpacity] = useState(0);
  const navigation = useNavigation();
  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  // TODO (MinhLuan): handle these cases
  const isUnRead = false;
  const unReadCount = 0;
  const isLastMessagePhoto = false;
  const isLastMessageRead = true;

  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  const handleEdit = () => {
    console.log('Edit clicked');
    setShowMenu(false);
  };

  const handleDelete = async () => {
    await roomRemoveHandler(chatRoom.id);
    setShowMenu(false);
  };

  // TODO (MinhLuan): check this
  const goToChatScreen = () => {
    // First, set the active chat room
    dispatch(setActiveChatRoom(chatRoom.id));
    // Then, navigate to the chat screen
    // @ts-ignore
    navigation.navigate(RootStackScreensEnum.ChatScreenMobile);
  };

  // TODO (MinhLuan): replace with real image from database
  return (
    <Pressable
      onPress={goToChatScreen}
      onHoverOut={handleMouseLeave}
      onHoverIn={handleMouseEnter}
      style={{
        borderRadius: 8,
        backgroundColor: activeChatRoomID === chatRoom.id ? '#DBEAFE' : 'white'
      }}>
      <HStack pl={2} w='100%' space={2} alignItems='center' py={2}>
        <Avatar source={imageUri} height={10} width={10}>
          JD
        </Avatar>
        <VStack flex={1} space={0.5}>
          <HStack alignItems='center' justifyContent={'space-between'}>
            <Text fontSize='sm' fontWeight='medium' _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }}>
              {chatRoom.chatRoomName}
            </Text>
          </HStack>
          <HStack alignItems='center' space={1}>
            {isLastMessagePhoto ? (
              <Icon size={4} _light={{ color: 'coolGray.500' }} as={MaterialIcons} name={'photo-size-select-actual'} />
            ) : (
              isLastMessageRead && (
                <Icon size={6} _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }} as={MaterialIcons} name={'done-all'} />
              )
            )}
            <Text fontSize='sm' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }} isTruncated>
              {isLastMessagePhoto ? 'Photo' : chatRoom.lastMessageText ? chatRoom.lastMessageText.substring(0, 30) : ''}...{' '}
              {chatRoom.lastMessageTimestamp ? friendlyTimestamp(chatRoom.lastMessageTimestamp) : ''}
            </Text>
            {/*<Text*/}
            {/*  px={2}*/}
            {/*  alignSelf={'end'}*/}
            {/*  fontSize='xs'*/}
            {/*  width={12}*/}
            {/*  fontWeight='medium'*/}
            {/*  _light={{ color: 'coolGray.800' }}*/}
            {/*  _dark={{ color: 'coolGray.50' }}*/}
            {/*  mb={isUnRead ? 0 : 2.5}>*/}
            {/*  {chatRoom.lastMessageTimestamp ? friendlyTimestamp(chatRoom.lastMessageTimestamp) : ''}*/}
            {/*</Text>*/}
          </HStack>
        </VStack>
        <VStack space={2} alignItems='flex-end'>
          {isUnRead && (
            <Center mt={isUnRead ? 0 : 1} width={5} height={5} rounded='full' _light={{ bg: 'primary.900' }} _dark={{ bg: 'primary.500' }}>
              <Text fontSize='xs' color='white'>
                {unReadCount}
              </Text>
            </Center>
          )}
        </VStack>
        <Menu
          isOpen={showMenu}
          onClose={() => setShowMenu(false)}
          trigger={(triggerProps) => (
            <Tooltip label={'More'}>
              <Button
                style={{
                  backgroundColor: activeChatRoomID === chatRoom.id ? 'white' : '#DDDDDD'
                }}
                mr={4}
                alignItems={'center'}
                rounded={'full'}
                size={8}
                _hover={{ opacity: 1 }}
                opacity={opacity}
                {...triggerProps}>
                <Icon
                  onPress={handleMenuToggle}
                  size={6}
                  _light={{ color: 'coolGray.800' }}
                  _dark={{ color: 'coolGray.50' }}
                  as={MaterialIcons}
                  name={'more-horiz'}
                />
              </Button>
            </Tooltip>
          )}>
          {/*<Menu.Item onPress={handleEdit}>*/}
          {/*  <Icon size={6} _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }} as={MaterialIcons} name={'edit'} />*/}
          {/*</Menu.Item>*/}
          <Menu.Item onPress={handleDelete}>
            <Icon size={6} _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }} as={MaterialIcons} name={'delete'} />
          </Menu.Item>
        </Menu>
      </HStack>
    </Pressable>
  );
};

const ChatListMobile = () => {
  const { user } = useAuth();
  const chatRooms = useAppSelector(selectAllChatRooms);
  const activeChatRoom = useAppSelector(selectActiveChatRoom);
  const nextChatRoom = useAppSelector((state) => findNextChatRoom(state, activeChatRoom.id));
  const dispatch = useAppDispatch();
  const { socket, sendMessage } = useChat();
  const [textInput, setTextInput] = useState('');
  const [searchResults, setSearchResults] = useState<IChatRoom[]>([]);
  const textSearch = useDebounce(textInput, 1000);

  useEffect(() => {
    if (chatRooms) {
      setSearchResults(chatRooms);
    }
  }, [chatRooms]);

  useEffect(() => {
    const results = chatRooms.filter((chatRoom) => {
      return chatRoom.lastMessageText?.toLowerCase().includes(textInput.toLowerCase());
    });
    setSearchResults(results);
  }, [textSearch, chatRooms]);

  const handleSearchTextChange = (text: string) => {
    setTextInput(text);
  };

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const handleToggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    setTextInput('');
  };

  const removeChatRoomHandler = async (chatRoomID: string) => {
    const chatRoom = chatRooms.find((room) => room.id === chatRoomID);
    if (!chatRoom) {
      console.warn('Chat room not found');
      return;
    } else {
      console.log('Chat room to be deleted: name = ' + chatRoom.chatRoomName, 'id = ' + chatRoom.id);
    }

    if (!user) {
      console.warn('User is not logged in, this should not happen');
      return;
    }

    axios
      .delete(`/chat_rooms/${chatRoom.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.idToken}`
        }
      })
      .then((response) => {
        if (response.status === 200) {
          console.log('Chat room deleted successfully');
        } else {
          console.error('Error deleting chat room: ' + response.data);
        }
      })
      .catch((error) => {
        console.error('Error deleting chat room: ' + error);
      });

    // If the chat room to be deleted is the active chat room, set the active chat room to the next chat room
    if (activeChatRoom.id === chatRoomID) {
      // Set the active chat room to the next chat room in the list
      console.log('Next chat room', nextChatRoom);
      if (nextChatRoom) {
        dispatch(setActiveChatRoom(nextChatRoom.id));
      }
    }
  };

  if (!socket || !user) {
    return (
      <Box flex='1' _light={{ bg: 'white' }} _dark={{ bg: 'coolGray.800' }} rounded={{ md: 'sm' }} alignItems={'center'} justifyContent='center'>
        <Spinner accessibilityLabel='Loading' size='lg' />
      </Box>
    );
  }

  return (
    <>
      <HStack alignItems={'center'} justifyContent={'space-between'} height={'64px'} w={'100%'}>
        {!isSearchOpen && (
          <HStack alignItems={'center'} w={'100%'}>
            <Text w={'95%'} fontSize='xl' fontWeight='bold' pl={4} _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }}>
              Chats
            </Text>
            {/*<Hidden till='md'>*/}
            <Tooltip label='Filter by chat content'>
              <Pressable
                onPress={handleToggleSearch}
                w={'5%'}
                mr={4}
                p={4}
                alignItems='center'
                justifyContent='center'
                size={'10'}
                rounded='full'
                _hover={{ bg: '#E4EBE4' }}>
                <Icon as={<MaterialIcons name='search' />} size={8} color='coolGray.900' />
              </Pressable>
            </Tooltip>
            {/*</Hidden>*/}
          </HStack>
        )}
        <Box w={'100%'} px={2}>
          {isSearchOpen && (
            <SearchBarWeb textInput={textInput} handleSearchTextChange={handleSearchTextChange} handleToggleSearch={handleToggleSearch} />
          )}
        </Box>
      </HStack>
      <Divider />
      {/*Floating Button to create a new chat room*/}
      <Box shadow={9} position='absolute' bottom={4} right={4} zIndex={1}>
        <ModalLanguageForm />
      </Box>
      <Box px={{ base: 4, md: 2 }} py={{ base: 4, md: 2 }} _light={{ bg: 'white' }} _dark={{ bg: 'coolGray.800' }} rounded={{ md: 'sm' }} flex={1}>
        <FlatList
          showsVerticalScrollIndicator={false}
          data={chatRooms}
          renderItem={({ item }) => <ChatItem chatRoom={item} roomRemoveHandler={removeChatRoomHandler} />}
          keyExtractor={(item) => item.id}
          ItemSeparatorComponent={() => <Divider />}></FlatList>
      </Box>
    </>
  );
};
export default ChatListMobile;
