import { Box, HStack, MoonIcon, Pressable, SunIcon, Text, Tooltip, useColorMode, useColorModeValue } from 'native-base';
import React from 'react';
import MenuProfile from '../components/MenuProfile';

type HeaderProps = {
  title: string;
  subTitle?: string;
  toggleSidebar: () => void;
  menuButton: boolean;
  searchbar: boolean;
};

const Header = (props: HeaderProps) => {
  const { toggleColorMode } = useColorMode();

  return (
    <HStack
      px='6'
      py='4px'
      borderBottomWidth='1'
      _dark={{ bg: 'coolGray.900', borderColor: 'coolGray.800' }}
      _light={{
        bg: '#0564C8',
        borderColor: 'coolGray.200'
      }}>
      <HStack alignSelf='center' width='100%'>
        <Box flex={1}>
          <Text color={'white'} fontSize={36} fontFamily={'Sketch_Block'} fontWeight={'bole'}>
            Teledoc
          </Text>
        </Box>
        <HStack space='2' style={{ alignItems: 'center' }}>
          <Tooltip label='Mode'>
            <Pressable onPress={toggleColorMode}>
              {useColorModeValue(<MoonIcon style={{ color: 'white' }} size='7' />, <SunIcon style={{ color: 'white' }} size='7' />)}
            </Pressable>
          </Tooltip>
          <MenuProfile />
        </HStack>
        {/*<HStack style={{ alignItems: 'center' }} justifyContent='flex-end'>*/}
        {/*  {props.searchbar && (*/}
        {/*    <Input*/}
        {/*      px='4'*/}
        {/*      w='30%'*/}
        {/*      size='sm'*/}
        {/*      placeholder='Search'*/}
        {/*      InputLeftElement={*/}
        {/*        <Icon*/}
        {/*          px='2'*/}
        {/*          size='4'*/}
        {/*          name={'search'}*/}
        {/*          as={FontAwesome}*/}
        {/*          _light={{*/}
        {/*            color: 'coolGray.400'*/}
        {/*          }}*/}
        {/*          _dark={{*/}
        {/*            color: 'coolGray.100'*/}
        {/*          }}*/}
        {/*        />*/}
        {/*      }*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</HStack>*/}
      </HStack>
    </HStack>
  );
};

export default Header;
