import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Divider,
  Hidden,
  HStack,
  Icon,
  IconButton,
  Menu,
  Pressable,
  Spinner,
  Text,
  TextArea,
  View,
  VStack,
  Tooltip,
  Button,
  Popover,
  ScrollView
} from 'native-base';
import { AntDesign, Feather, Fontisto, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { Audio } from 'expo-av';
import axios from 'axios';
import { Animated, NativeScrollEvent, NativeSyntheticEvent } from 'react-native';
import AnimatedValue = Animated.AnimatedValue;
import { generate_uuid } from '../../utils/uuid_generator';
import { selectAllDoctors } from '../../store/hospital/doctors/doctors-slice';
import { useNavigation, useRoute } from '@react-navigation/native';
import { IDoctorWithUser } from '../../types/IDoctorWithUser';
import { getSFUserById } from '../../utils/graphql/sf_user_utils';
import { SFUser } from '../../API';
import { stropheBuilder } from '../../hooks/useStrophe';
import { useConnection } from '../../components/xmpp/ConnectionProvider';
import {
  chatRoomContactActions,
  selectAllMessages,
  selectBeforeMessageId,
  selectCanLoadMore,
  selectIsSetMessagesComplete
} from '../../store/chat/chat-room-contact/chat-room-contact-slice';
import { IMessageContact } from '../../types/IMessageContact';
import { RootStackScreensEnum } from '../../navigation/enum';

interface IChatHeader {
  sFUser: SFUser | null;
  contact: IDoctorWithUser | undefined;
}

function ChatHeader({ sFUser, contact }: IChatHeader) {
  const navigation = useNavigation();
  const callAudio = async () => {
    // setTimeout(() => {
    //   // @ts-ignore
    //   window.sfJoinMeeting('400', 'd41d8cd98f00b204e9800998ecf8427e', '3633303533');
    // }, 1000);
    // @ts-ignore
    global.sfCallP2P(sFUser?.userName, true);
    setTimeout(() => {
      document.querySelector<HTMLElement>('flt-glass-pane')!.style.visibility = 'visible';
    }, 3000);
  };

  const callVideo = async () => {
    // @ts-ignore
    global.sfCallP2P(sFUser?.userName, false);
    setTimeout(() => {
      document.querySelector<HTMLElement>('flt-glass-pane')!.style.visibility = 'visible';
    }, 3000);
  };

  return (
    <HStack borderTopRadius='sm' _light={{ bg: 'white' }} _dark={{ bg: 'coolGray.900' }} alignItems='center' space='3' px='4' py='2'>
      <Pressable onPress={() => navigation.goBack()}>
        <Icon as={<Ionicons name='arrow-back' />} size='7' />
      </Pressable>
      <Avatar alignItems={'center'} source={contact?.user.imageUrl ? { uri: contact.user.imageUrl } : undefined} size='8'>
        <Text color='#fff' fontSize='16' fontWeight={'bold'}>
          {contact?.user.firstName.charAt(0).toUpperCase()}
        </Text>
      </Avatar>
      <Box>
        <Text color='black' fontSize='16' fontWeight={'bold'}>
          {contact?.user?.firstName} {contact?.user?.lastName}
        </Text>
      </Box>
      <HStack alignItems='center' space='3' ml='auto' pr={1}>
        <Tooltip label='Start audio call'>
          <Pressable
            p={2}
            alignItems='center'
            justifyContent='center'
            size={'7'}
            rounded='full'
            _hover={{ bg: '#E4EBE4' }}
            _light={{ bg: 'white' }}
            _dark={{ bg: 'primary.500' }}
            onPress={() => callAudio()}>
            <Icon as={<MaterialIcons name='call' />} size={6} color='#0564C8' />
          </Pressable>
        </Tooltip>
        <Tooltip label='Start video call'>
          <Pressable
            p={2}
            alignItems='center'
            justifyContent='center'
            size={'7'}
            rounded='full'
            _hover={{ bg: '#E4EBE4' }}
            _light={{ bg: 'white' }}
            _dark={{ bg: 'primary.500' }}
            onPress={() => callVideo()}>
            <Icon as={<Feather name='video' />} size={6} color='#0564C8' />
          </Pressable>
        </Tooltip>
        <Tooltip label='Option'>
          <Pressable
            p={2}
            alignItems='center'
            justifyContent='center'
            size={'7'}
            rounded='full'
            _hover={{ bg: '#E4EBE4' }}
            _light={{ bg: 'white' }}
            _dark={{ bg: 'primary.500' }}>
            <Icon as={<MaterialIcons name='more-vert' />} size={6} color='#0564C8' />
          </Pressable>
        </Tooltip>
      </HStack>
    </HStack>
  );
}

interface IChatItem {
  messageContact: IMessageContact;
  contact: IDoctorWithUser | undefined;
}

function ChatItemComponent({ messageContact, contact }: IChatItem) {
  const { user } = useAuth();
  const isMe = messageContact.from === user?.sfWebServiceUserId;
  return (
    <Box py={2} w={'100%'}>
      <HStack alignItems={'end'} flexDirection={!isMe ? 'row' : 'row-reverse'} w={'100%'}>
        <Box px={2}>
          {isMe ? (
            <Avatar source={user?.imageUrl ? { uri: user.imageUrl } : undefined} size={8}>
              {user?.firstName.charAt(0).toUpperCase()}
            </Avatar>
          ) : (
            <Avatar source={contact?.user.imageUrl ? { uri: contact.user.imageUrl } : undefined} size={8}>
              {contact?.user.firstName.charAt(0).toUpperCase()}
            </Avatar>
          )}
        </Box>
        <VStack
          minW='56'
          maxW='80%'
          borderRadius='16'
          pt='2'
          pb='1'
          px='5'
          space='0.5'
          _light={{
            bg: !isMe ? '#E4EBE4' : '#2563EB'
          }}
          _dark={{
            bg: !isMe ? 'coolGray.700' : 'coolGray.600'
          }}>
          <Text
            fontSize='md'
            _light={{
              color: !isMe ? 'black' : 'white'
            }}
            _dark={{
              color: !isMe ? 'coolGray.200' : 'coolGray.100'
            }}>
            {messageContact.text}
          </Text>
          <HStack space='1' justifyContent='flex-end' alignItems='center'>
            <Text
              fontSize='xs'
              _light={{
                color: !isMe ? 'coolGray.700' : 'coolGray.50'
              }}
              _dark={{
                color: !isMe ? 'coolGray.100' : 'coolGray.50'
              }}>
              {messageContact.createdAt !== '' && formatDistanceToNow(new Date(messageContact.createdAt), { addSuffix: true })}
            </Text>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
}

type SoundWaveAnimationProps = {
  cancelRecording: () => void;
  sendRecording: () => void;
  isRecording: boolean;
  toggleRecording: (isRecord: boolean) => void;
  isToggling: boolean;
  isSending: boolean;
};

const SoundWaveAnimation = (props: SoundWaveAnimationProps) => {
  const { cancelRecording, sendRecording, isRecording, toggleRecording, isToggling, isSending = false } = props;
  const wave1 = useRef(new Animated.Value(0)).current;
  const wave2 = useRef(new Animated.Value(0)).current;
  const wave3 = useRef(new Animated.Value(0)).current;
  const wave4 = useRef(new Animated.Value(0)).current;
  const [waveArray, setWaveArray] = useState<string[]>([]);

  useEffect(() => {
    setWaveArray(new Array(12).fill(''));
  }, []);

  useEffect(() => {
    const animateWave = (wave: AnimatedValue, duration = 0) => {
      Animated.loop(
        Animated.sequence([
          Animated.timing(wave, {
            toValue: 1,
            duration: 700 + duration,
            useNativeDriver: true
          }),
          Animated.timing(wave, {
            toValue: 0,
            duration: 700 + duration,
            useNativeDriver: true
          })
        ])
      ).start();
    };

    animateWave(wave1);
    animateWave(wave2, 100);
    animateWave(wave3, 200);
    animateWave(wave4, 500);
  }, [wave1, wave2, wave3, wave4, isRecording]);

  const waveStyle = {
    backgroundColor: '#0564C8',
    borderRadius: 10,
    height: 30,
    width: '4%',
    marginHorizontal: '1%',
    transform: [{ scaleY: wave1 }]
  };

  const waveStyle1 = {
    backgroundColor: '#0564C8',
    borderRadius: 10,
    height: 30,
    width: '4%',
    marginHorizontal: '1%',
    transform: [{ scaleY: wave2 }]
  };

  const waveStyle2 = {
    backgroundColor: '#0564C8',
    borderRadius: 10,
    height: 30,
    width: '4%',
    marginHorizontal: '1%',
    transform: [{ scaleY: wave3 }]
  };

  const waveStyle3 = {
    backgroundColor: '#0564C8',
    borderRadius: 10,
    height: 30,
    width: '4%',
    marginHorizontal: '1%',
    transform: [{ scaleY: wave4 }]
  };

  return (
    <VStack space={3}>
      <HStack justifyContent={'right'} alignItems={'center'}>
        <Pressable
          isDisabled={isSending}
          rounded='full'
          _hover={{ bg: '#E4EBE4' }}
          p={1}
          alignItems='center'
          justifyContent='center'
          onPress={() => {
            cancelRecording();
          }}>
          <Icon as={<MaterialCommunityIcons name='close' />} size={4} color={'black'} />
        </Pressable>
      </HStack>
      <HStack justifyContent={'center'} alignItems={'center'}>
        <Tooltip label={isRecording ? 'Stop' : 'Record'}>
          <Pressable
            isDisabled={isSending}
            p={2}
            alignItems='center'
            justifyContent='center'
            rounded='full'
            _hover={{ bg: '#E4EBE4' }}
            _light={{ bg: isRecording ? '#ffdad8' : 'white' }}
            _dark={{ bg: 'primary.500' }}
            onPress={() => toggleRecording(isRecording)}>
            {isToggling ? (
              <Spinner></Spinner>
            ) : (
              <Icon as={<Ionicons name={isRecording ? 'stop' : 'mic-outline'} />} size={5} color={isRecording ? '#ff4444' : 'coolGray.900'} />
            )}
          </Pressable>
        </Tooltip>
        {isRecording ? (
          <HStack flex={1} justifyContent={'center'} alignItems={'center'}>
            <Animated.View style={waveStyle2} />
            <Animated.View style={waveStyle} />
            <Animated.View style={waveStyle2} />
            <Animated.View style={waveStyle1} />
            <Animated.View style={waveStyle} />
            <Animated.View style={waveStyle3} />
            <Animated.View style={waveStyle2} />
            <Animated.View style={waveStyle3} />
            <Animated.View style={waveStyle1} />
            <Animated.View style={waveStyle1} />
            <Animated.View style={waveStyle} />
            <Animated.View style={waveStyle3} />
          </HStack>
        ) : (
          <HStack flex={1} justifyContent={'center'} alignItems={'center'}>
            {waveArray.map((item, index) => {
              return (
                <View
                  key={index}
                  style={{
                    backgroundColor: '#9ca3af',
                    borderRadius: 10,
                    height: 30,
                    width: '4%',
                    marginHorizontal: '1%'
                  }}></View>
              );
            })}
          </HStack>
        )}
      </HStack>
      <HStack flex={1} justifyContent={'right'} alignItems={'center'}>
        <Button bgColor={'#0564C8'} py={1} w={12} onPress={sendRecording}>
          {isSending ? <Spinner color={'white'}></Spinner> : <Text color={'white'}>Send</Text>}
        </Button>
      </HStack>
    </VStack>
  );
};

type ModalRecordButtonProps = {
  convertRecordingToText: (text: string) => void;
};

const ModalRecordButton = (props: ModalRecordButtonProps) => {
  const audioRecorder = useRef(new Audio.Recording());
  const [audioPermission, setAudioPermission] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isClickBackDrop, setIsClickBackDrop] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isToggling, setIsToggling] = useState(false);
  const [audioBlobUri, setAudioBlobUri] = useState<string | null>('');
  const fileName = generate_uuid();

  // Initial Load to get the audio permission
  useEffect(() => {
    getPermission();
  }, []);

  useEffect(() => {
    (async () => {
      if (audioBlobUri && isSending) {
        const response = await fetch(audioBlobUri);
        const blob = await response.blob();
        const fileOfBlob = new File([blob], `audio-${fileName}`);
        const formData = new FormData();
        formData.append('audio_data', fileOfBlob);
        await axios
          .post('https://meeting.sfvmeet.com/transcribe', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            timeout: 10000
          })
          .then((res) => {
            const messageText = res.data.text.trim();
            if (!messageText) {
              return;
            }
            props.convertRecordingToText(messageText);
            setIsSending(false);
          })
          .catch((err) => {
            console.log(err);
            setIsSending(false);
          });
        setIsOpen(false);
        resetAudioRecorder();
      }
    })();
  }, [isSending, audioBlobUri]);

  // Function to get the audio permission
  const getPermission = async () => {
    const getAudioPerm = await Audio.requestPermissionsAsync();
    setAudioPermission(getAudioPerm.granted);
  };

  // Function to start recording
  const startRecording = async () => {
    setIsOpen(true);
    setAudioBlobUri('');
    try {
      // Check if user has given the permission to record
      if (audioPermission) {
        try {
          // Prepare the Audio Recorder
          await audioRecorder.current.prepareToRecordAsync(Audio.RecordingOptionsPresets.HIGH_QUALITY);

          // Start recording
          await audioRecorder.current.startAsync();
        } catch (error) {
          console.log(error);
        }
      } else {
        // If user has not given the permission to record, then ask for permission
        getPermission();
      }
      setIsRecording(true);
      setIsToggling(false);
    } catch (error) {
      setIsRecording(false);
      /* empty */
    }
  };

  // Reset the Audio Recorder
  const resetAudioRecorder = () => {
    audioRecorder.current = new Audio.Recording();
  };

  // Function to stop recording
  const stopRecording = async () => {
    try {
      // Stop recording
      await audioRecorder.current.stopAndUnloadAsync();

      // Get the recorded URI here
      const result = audioRecorder.current.getURI();
      setAudioBlobUri(result);
      resetAudioRecorder();
      setIsRecording(false);
      setIsToggling(false);
    } catch (error) {
      resetAudioRecorder();
      setIsRecording(false);
      setIsToggling(false);
      /* empty */
    }
  };

  const cancelRecording = () => {
    stopRecording();
    setIsOpen(false);
  };

  const sendRecording = () => {
    stopRecording();
    setIsSending(true);
  };

  const toggleRecording = (isRecord: boolean) => {
    setIsToggling(true);
    if (isRecord) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const changeBorderStyle = (isArrow = false) => {
    if (!isClickBackDrop) {
      return {
        borderColor: '#D4D4D4'
      };
    }
    return isArrow
      ? {
          boxShadow: '4px 4px 0 0 #5AC8FA'
        }
      : {
          boxShadow: '0 0 0 4px #5AC8FA'
        };
  };

  return (
    <Popover
      trigger={(triggerProps) => {
        return (
          <Tooltip label='Send audio clip'>
            <Pressable
              {...triggerProps}
              size={12}
              alignItems='center'
              justifyContent='center'
              rounded='full'
              _hover={{ bg: '#E4EBE4' }}
              _light={{ bg: 'white' }}
              _dark={{ bg: 'primary.500' }}
              onPress={startRecording}>
              <Icon as={<Ionicons name='mic-outline' />} size={8} color={'coolGray.900'} />
            </Pressable>
          </Tooltip>
        );
      }}
      placement={'top right'}
      isOpen={isOpen}
      onClose={() => {
        setIsClickBackDrop(true);
        setTimeout(() => {
          setIsClickBackDrop(false);
        }, 2000);
      }}>
      <Popover.Content style={changeBorderStyle()} accessibilityLabel='Record' w='72'>
        <Popover.Arrow style={changeBorderStyle(true)}></Popover.Arrow>
        <Popover.Body>
          <SoundWaveAnimation
            cancelRecording={cancelRecording}
            sendRecording={sendRecording}
            isRecording={isRecording}
            toggleRecording={toggleRecording}
            isToggling={isToggling}
            isSending={isSending}
          />
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};

function MainContent() {
  const dispatch = useAppDispatch();
  const scrollViewRef = React.useRef<MutableRefObject<any>>();
  const refInput = React.useRef<any>();
  const strophe = useConnection();
  const doctorsWithUser = useAppSelector((state) => selectAllDoctors(state.hospital.doctors));
  const route = useRoute();
  const [contact, setContact] = useState<IDoctorWithUser>();
  const [sFUser, setSFUser] = useState<SFUser | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const messages = useAppSelector(selectAllMessages);
  const isSetMessagesComplete = useAppSelector(selectIsSetMessagesComplete);
  const canLoadMore = useAppSelector(selectCanLoadMore);
  const beforeMessageId = useAppSelector(selectBeforeMessageId);

  const findDoctorWithUser = (contactId: string) => {
    const doctorWithUser = doctorsWithUser.find((doctor) => doctor.id === contactId);
    return doctorWithUser || doctorsWithUser[0];
  };

  const handleSFUser = (responseSFUser: SFUser | null) => {
    setSFUser(responseSFUser);
    if (responseSFUser?.userName && strophe?.stropheConnection) {
      const queryId = `${responseSFUser?.userName}@sip`;
      const maxResults = 50;
      const iq = iqBuilder(queryId, maxResults);
      strophe.stropheConnection.send(iq);
    } else {
      dispatch(chatRoomContactActions.setMessagesComplete(true));
    }
  };

  const handleDoctorUser = (doctor: IDoctorWithUser) => {
    if (doctor.user.userSFUserId) {
      getSFUserById(doctor.user.userSFUserId)
        .then(handleSFUser)
        .catch(() => {
          dispatch(chatRoomContactActions.setMessagesComplete(true));
        });
    } else {
      dispatch(chatRoomContactActions.setMessagesComplete(true));
    }
  };

  useEffect(() => {
    if (doctorsWithUser.length > 0) {
      const contactIdParam = route.params as { contactId: string };
      const doctor = findDoctorWithUser(contactIdParam.contactId);
      setContact(doctor);
      handleDoctorUser(doctor);
    }
  }, [route.params, doctorsWithUser, strophe.stropheConnection]);

  const iqBuilder = (queryId: string, maxResults: number, beforeId = '') => {
    return stropheBuilder
      .iq({
        type: 'set',
        id: queryId
      })
      .c('query', {
        xmlns: 'urn:xmpp:mam:2'
      })
      .c('x', {
        xmlns: 'jabber:x:data',
        type: 'submit'
      })
      .c('field', {
        var: 'FORM_TYPE',
        type: 'hidden'
      })
      .c('value')
      .t('urn:xmpp:mam:2')
      .up()
      .up()
      .c('field', {
        var: 'with'
      })
      .c('value')
      .t(queryId)
      .up()
      .up()
      .up()
      .c('set', {
        xmlns: 'http://jabber.org/protocol/rsm'
      })
      .c('max')
      .t(maxResults.toString())
      .up()
      .c('before')
      .t(beforeId);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const sendMessage = (to: string, from: string, body: string, field1: string, field2: string) => {
    const m = stropheBuilder.message({ to, from, type: 'chat' }).c('body').t(body);
    // custom data
    m.up().c('data', { xmlns: 'jabber:client', field1: field1, field2: field2 });
    strophe.stropheConnection && strophe.stropheConnection.send(m);
    dispatch(
      chatRoomContactActions.addMessages({
        id: generate_uuid(),
        text: body || '',
        createdAt: new Date().toISOString(),
        from: from.split('@')[0] || ''
      })
    );
  };

  const handleSend = () => {
    const messageText = refInput?.current?.value?.trim();
    if (!messageText || !strophe.jid || !sFUser?.userName) {
      return;
    }
    sendMessage(`${sFUser.userName}@sip`, strophe.jid, messageText, 'test', 'test');
    // @ts-ignore
    refInput.current?.clear();
    // @ts-ignore
    refInput.current?.focus();
  };

  const convertRecordingToText = (text: string) => {
    refInput.current.value = text;
  };

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    // const scrollYOffset = event.nativeEvent.contentOffset.y;
    // if (scrollYOffset <= 50 && strophe.stropheConnection && canLoadMore && sFUser?.userName && !isLoading) {
    //   const queryId = `${sFUser.userName}@sip`;
    //   const maxResults = 20;
    //   const iq = iqBuilder(queryId, maxResults, beforeMessageId);
    //   setIsLoading(true);
    //   strophe.stropheConnection?.send(iq);
    // }
  };

  return (
    <>
      {isSetMessagesComplete ? (
        <Box flex='1' _light={{ bg: 'white' }} _dark={{ bg: 'coolGray.800' }} rounded={{ md: 'sm' }} h={'100%'}>
          <ChatHeader sFUser={sFUser} contact={contact} />
          <Divider />

          <VStack
            flex={1}
            px={{ base: 4, md: 4 }}
            // py={{ base: 4, md: 10 }}
            _light={{
              bg: { md: 'white' }
            }}
            _dark={{
              bg: { md: 'coolGray.800' }
            }}
            space={{ base: 2, md: 3 }}>
            <ScrollView
              ref={scrollViewRef}
              onScroll={handleScroll}
              scrollEventThrottle={16}
              // @ts-ignore
              onContentSizeChange={() => scrollViewRef.current?.scrollToEnd({ animated: false })}
              style={{ height: 550 }}
              showsVerticalScrollIndicator={false}>
              {messages?.length > 0 &&
                messages.map((item) => {
                  return <ChatItemComponent messageContact={item} key={item.id} contact={contact} />;
                })}
            </ScrollView>
          </VStack>

          <Box w='100%' borderBottomRadius={{ md: 4 }} _light={{ bg: 'white' }} _dark={{ bg: 'coolGray.800' }}>
            <Divider />
            <HStack alignItems='center' space={1} py='4' px={{ base: 4, md: 8 }}>
              {/*<Hidden till='md'>*/}
              {/*  <HStack alignItems='center' space='3' justifyContent={'space-between'}>*/}
              {/*<Tooltip label='Choose icon'>*/}
              {/*  <Pressable p={4} alignItems='center' justifyContent='center' size={'6'} rounded='full' _hover={{ bg: '#E4EBE4'}} _light={{ bg: 'white' }} _dark={{ bg: 'primary.500' }}>*/}
              {/*    <Icon as={<Fontisto name='smiley' />} size={5} color='coolGray.700' />*/}
              {/*  </Pressable>*/}
              {/*</Tooltip>*/}
              {/*<Tooltip label='Send picture'>*/}
              {/*  <Pressable p={4} alignItems='center' justifyContent='center' size={'6'} rounded='full' _hover={{ bg: '#E4EBE4'}} _light={{ bg: 'white' }} _dark={{ bg: 'primary.500' }}>*/}
              {/*    <Icon as={<MaterialCommunityIcons name='camera-outline' />} size={6} color='coolGray.700' />*/}
              {/*  </Pressable>*/}
              {/*</Tooltip>*/}
              {/*<Tooltip label='Attach file'>*/}
              {/*  <Pressable p={4} alignItems='center' justifyContent='center' size={'6'} rounded='full' _hover={{ bg: '#E4EBE4'}} _light={{ bg: 'white' }} _dark={{ bg: 'primary.500' }}>*/}
              {/*    <Icon as={<MaterialIcons name='attach-file' />} size={5} color='coolGray.700' />*/}
              {/*  </Pressable>*/}
              {/*</Tooltip>*/}
              {/*  </HStack>*/}
              {/*</Hidden>*/}
              <Hidden from='md'>
                <Menu
                  p='0'
                  trigger={(triggerProps) => {
                    return (
                      <IconButton
                        variant='ghost'
                        p='0'
                        {...triggerProps}
                        icon={<Icon size='6' color='coolGray.700' name='pluscircle' as={AntDesign} />}
                      />
                    );
                  }}
                  placement='top left'>
                  <Menu.Item _dark={{ bg: 'coolGray.900' }}>
                    <HStack space='2' alignItems='center'>
                      <IconButton p={0} variant='unstyled' icon={<Icon color='coolGray.700' as={MaterialIcons} name='attach-file' size='6' />} />
                      <Text bold={true}>Attach File</Text>
                    </HStack>
                  </Menu.Item>
                  <Menu.Item _dark={{ bg: 'coolGray.900' }}>
                    <HStack space='2' alignItems='center'>
                      <IconButton
                        p={0}
                        variant='unstyled'
                        icon={<Icon color='coolGray.700' as={MaterialCommunityIcons} name='camera-outline' size='6' />}
                      />
                      <Text bold={true}>Camera</Text>
                    </HStack>
                  </Menu.Item>
                  <Menu.Item _dark={{ bg: 'coolGray.900' }}>
                    <HStack space='2' alignItems='center'>
                      <IconButton p={0.5} variant='unstyled' icon={<Icon color='coolGray.700' as={Fontisto} name='smiley' size='5' />} />
                      <Text bold={true}>Emotion</Text>
                    </HStack>
                  </Menu.Item>
                </Menu>
              </Hidden>
              <TextArea
                multiline={true}
                h={{ base: 10, md: 12 }}
                scrollEnabled={false}
                flex={1}
                autoCompleteType='off'
                _android={{ py: '0.5', px: '4' }}
                py='2'
                px='4'
                size='xl'
                variant='outline'
                rounded='md'
                placeholder='Type a message'
                placeholderTextColor='coolGray.400'
                ref={refInput}
                onChangeText={(value) => (refInput.current.value = value)}
                onKeyPress={handleKeyPress}
                _light={{
                  bg: 'coolGray.100',
                  borderColor: 'white',
                  color: 'black'
                }}
                _dark={{
                  bg: 'coolGray.700',
                  borderColor: 'coolGray.800',
                  color: 'white'
                }}
              />

              <HStack alignItems='center'>
                <ModalRecordButton convertRecordingToText={convertRecordingToText} />
              </HStack>

              <HStack alignItems='center'>
                <Tooltip label='Press enter to send'>
                  <Pressable
                    onPress={handleSend}
                    size={12}
                    alignItems='center'
                    justifyContent='center'
                    rounded='full'
                    _hover={{ bg: '#E4EBE4' }}
                    _light={{ bg: 'white' }}
                    _dark={{ bg: 'primary.500' }}>
                    <Icon as={<MaterialCommunityIcons name='send' />} size={7} color='#0564C8' />
                  </Pressable>
                </Tooltip>
              </HStack>
            </HStack>
          </Box>
        </Box>
      ) : (
        <Box justifyContent={'center'} alignItems={'center'} flex='1' _light={{ bg: 'white' }} _dark={{ bg: 'coolGray.800' }} rounded={{ md: 'sm' }}>
          <Spinner size={36}></Spinner>
        </Box>
      )}
    </>
  );
}

const ContactScreenMobile = () => {
  const { user } = useAuth();

  if (!user) {
    // Spinner
    return (
      <Box flex='1' _light={{ bg: 'white' }} _dark={{ bg: 'coolGray.800' }} rounded={{ md: 'sm' }}>
        <Spinner accessibilityLabel='User is not logged in' />
      </Box>
    );
  }

  return <View h={'100%'}>{<MainContent />}</View>;
};

export default ContactScreenMobile;
