export default {
  defaultProps: {
    _light: {
      _checked: {
        _icon: { color: 'primary.900' },
        borderColor: 'primary.900'
      },
      borderColor: 'coolGray.300',
      bg: 'coolGray.50'
    },
    _dark: {
      _checked: {
        _icon: { color: 'primary.700' },
        borderColor: 'primary.700'
      },
      borderColor: 'coolGray.500',
      bg: 'coolGray.800'
    }
  }
};
