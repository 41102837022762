import React from 'react';
import { Box, Hidden, HStack, Icon, Input, ScrollView, Text, VStack } from 'native-base';
import DashboardLayout from '../../layouts/DashboardLayout';
import ResumeCourses, { Course } from '../../components/ResumeCourses';
import ResumeCourses2 from '../../components/ResumeCourses2';
import Categories from '../../components/Categories';
import AdminMobileFooter from './AdminMobileFooter';
import { MaterialIcons } from '@expo/vector-icons';
import CardMobile from './CardMobile';
import DoctorTopMobile from './DoctorTopMobile';
import RecentPatientMobile from './RecentPatientMobile';
import WebFooter from './WebFooter';

const resumedCourses: Course[] = [
  {
    id: 1,
    chapter: 'Patients',
    name: '783K',
    backgroundColor: '#ff9900',
    imageUri: require('../../assets/images/patient-heart.png')
  },
  {
    id: 2,
    chapter: 'Doctors',
    backgroundColor: '#0564C8',
    name: '76',
    imageUri: require('../../assets/images/doctor.png')
  }
];

const resumedCourses2: Course[] = [
  {
    id: 3,
    chapter: 'Appointment',
    backgroundColor: '#4EA0F7',
    name: '76',
    imageUri: require('../../assets/images/appointment.png')
  },
  {
    id: 4,
    chapter: 'Hospital',
    backgroundColor: '#bb86fc',
    name: '$56k',
    imageUri: require('../../assets/images/dolar.png')
  }
];

function Banner() {
  return (
    <VStack
      // _light={{ bg: '#4cd964' }}
      _dark={{ bg: { base: 'coolGray.900', md: 'coolGray.800' } }}
      zIndex={2}
      borderRadius={{ md: 10 }}
      px={{ base: 4, md: 8 }}
      pt={{ base: 4, md: 0 }}
      pb={{ base: 4, md: 0 }}
      mb={{ md: 2 }}>
      <Hidden till={'md'}>
        <VStack space='2' w={'70%'}>
          <Text mt={{ base: 0, md: 0 }} fontSize={{ base: 'xl', md: '5xl' }} color='black' fontWeight='bold'>
            Welcome to Teledoc!
          </Text>
          <Text
            mb={{ base: 0, md: 2 }}
            fontSize={{ base: 'md', md: 'lg' }}
            _light={{
              color: 'coolGray.700'
            }}
            _dark={{
              color: 'coolGray.200'
            }}>
            Hospital Admin Dashboard for managing patients and doctors.
          </Text>
        </VStack>
      </Hidden>
      <Hidden from={'md'}>
        <VStack space='2' w={'100%'}>
          <HStack>
            <Text fontSize={'20'} color='black' fontWeight='bold'>
              Find{' '}
            </Text>
            <Text fontSize={'20'} color='coolGray.400' fontWeight='bold'>
              Patients or Doctors
            </Text>
          </HStack>
          <Input
            width={'100%'}
            height={'15%'}
            // onChangeText={handleSearchTextChange}
            // value={textInput}
            rounded={'lg'}
            flex={1}
            py={3}
            _stack={{
              px: 3
            }}
            _light={{
              placeholderTextColor: 'coolGray.400',
              size: '14'
            }}
            _dark={{
              bg: 'coolGray.700',
              borderColor: 'coolGray.500',
              color: 'coolGray.100'
            }}
            InputRightElement={
              <Icon
                as={<MaterialIcons name='search' />}
                size={7}
                _light={{
                  color: 'coolGray.700'
                }}
                _dark={{
                  color: 'coolGray.300'
                }}
              />
            }
            placeholder='Search doctor, patient, etc...'
          />
        </VStack>
      </Hidden>
    </VStack>
  );
}

export default function Dashboard() {
  return (
    <DashboardLayout title='' displayMenuButton displayScreenTitle={false} displayAlternateMobileHeader rightPanelMobileHeader={true}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Banner />
        <Hidden till={'md'}>
          <HStack w={'100%'} my={{ base: 4, md: 8 }} _dark={{ bg: 'coolGray.800' }} rounded={{ md: 'lg' }} justifyContent={'center'}>
            <ResumeCourses courses={resumedCourses} />
            <ResumeCourses2 courses={resumedCourses2} />
          </HStack>
        </Hidden>
        <Hidden from={'md'}>
          <CardMobile />
        </Hidden>
        <Hidden till={'md'}>
          <Categories />
        </Hidden>
        <Hidden from={'md'}>
          <VStack space='2' w={'100%'}>
            <Box w={'100%'}>
              <DoctorTopMobile />
            </Box>
            <Box w={'100%'} mt={2}>
              <RecentPatientMobile />
            </Box>
          </VStack>
        </Hidden>
      </ScrollView>
      <WebFooter />
      <AdminMobileFooter />
    </DashboardLayout>
  );
}
