// import { keys } from 'ts-transformer-keys';
// import { cloneObject } from './object_clone';
import { ChatRoom, OnCreateChatRoomSubscription } from '../API';

export type IChatRoom = Omit<
  ChatRoom,
  'updatedAt' | 'owner' | '_version' | '_deleted' | '_lastChangedAt' | '__typename' | 'user' | 'messages' | 'agent'
> & {
  // Used for hiding chat room from user while it is being deleted
  hidden?: boolean;
};

// export const cloneChatRoom = (chatRoom: ChatRoom): IChatRoom => {
//   return cloneObject<ChatRoom, IChatRoom>(chatRoom, keys<ChatRoom>(), keys<IChatRoom>());
// };

type SubscribeChatRoom = NonNullable<OnCreateChatRoomSubscription['onCreateChatRoom']>;
export const cloneSubscribeChatRoom = (chatRoom: SubscribeChatRoom): IChatRoom => {
  return {
    id: chatRoom.id,
    chatRoomName: chatRoom.chatRoomName,
    lastMessageText: chatRoom.lastMessageText,
    lastMessageTimestamp: chatRoom.lastMessageTimestamp,
    createdAt: chatRoom.createdAt,
    agentID: chatRoom.agentID,
    userID: chatRoom.userID
  };
  // return cloneObject<SubscribeChatRoom, IChatRoom>(chatRoom, keys<SubscribeChatRoom>(), keys<IChatRoom>());
};
