import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Divider, Hidden, HStack, Icon, Image, Pressable, ScrollView, Text, VStack } from 'native-base';
import { Feather, FontAwesome, FontAwesome5, Foundation, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import DashboardLayout from '../../layouts/DashboardLayout';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import * as DocumentPicker from 'expo-document-picker';
import { DocumentResult } from 'expo-document-picker';
import MedicalHistory from './MedicalHistory';
import { medicalRecordActions, selectAllMedicalRecords } from '../../store/hospital/medical-record/medical-records-slice';
import { calculateAge, formatDate } from '../../utils/time_utils';
import WebFooter from './WebFooter';
import { ITelecom } from '../../types/fhir/IFhirCommonType';

interface IFieldLayout {
  children: any;
  width: string;
  field: string;
  value?: string;
}

function FieldLayout({ children, width, field, value }: IFieldLayout) {
  return (
    <HStack w={width} alignItems={'center'}>
      <Box justifyContent={'center'} alignItems='center' width={12} height={12} borderRadius='full' borderColor={'#4EA0F7'} borderWidth={'2'}>
        {children}
      </Box>
      <VStack ml={4} flex={1}>
        <Text color={'coolGray.300'} fontSize={'lg'}>
          {field}
        </Text>
        <Text color={'coolGray.500'} fontSize={'lg'}>
          {value ?? 'No Information'}
        </Text>
      </VStack>
    </HStack>
  );
}

enum TelecomSystem {
  PHONE = 'phone',
  EMAIL = 'email'
}

function PatientInfo() {
  const dispatch = useAppDispatch();
  const [patient, setPatient] = useState<any>();
  const [showDefaultData, setShowDefaultData] = useState(true);

  const fetchFileUri = async (uri: string) => {
    const response = await fetch(uri);
    return await response.blob();
  };

  const filterType = (array: any[], type: string) => {
    return array.filter((item: any) => item.resourceType === type);
  };

  const onHandleFile = useCallback(
    async (file: DocumentResult) => {
      if (file.type !== 'success') {
        return;
      }
      const blob = await fetchFileUri(file.uri);
      const fileWithNdjsonFormat = await readFileContent(blob);
      const arrayContent = handleFileWithNdjsonFormat(fileWithNdjsonFormat);
      setPatient(arrayContent[0]);
      const medicalRecords = filterType(arrayContent, 'Encounter');
      const observations = filterType(arrayContent, 'Observation');
      const medicalRecordListByPatientId = medicalRecords.filter((item: any) => item.subject.reference === `Patient/${arrayContent[0].id}`);
      const medicalRecordListByPatientIdWithObservations = medicalRecordListByPatientId.map((item: any) => {
        const observationList = observations.filter((observation: any) => observation.encounter?.reference === `Encounter/${item.id}`);
        return { ...item, observationList };
      });
      dispatch(medicalRecordActions.setMedicalRecords(medicalRecordListByPatientIdWithObservations));
      dispatch(medicalRecordActions.isFhirResource(true));
      setShowDefaultData(false);
    },
    [dispatch]
  );

  const readFileContent = (uri: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const fileContent = event.target?.result;
        resolve(fileContent);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(uri);
    });
  };

  const handleFileWithNdjsonFormat = (fileContent: any) => {
    const lines = fileContent.trim().split(/\r?\n/);
    const jsonArray = lines.map((line: string) => {
      console.log('line', line);
      return JSON.parse(line);
    });
    return Array.isArray(jsonArray) ? jsonArray : jsonArray[0];
  };

  const openDocumentPicker = useCallback(async () => {
    const pickerResult = await DocumentPicker.getDocumentAsync({
      type: '.json, .ndjson',
      copyToCacheDirectory: true,
      multiple: false
    });
    await onHandleFile(pickerResult);
  }, [onHandleFile]);

  const getTelecomValue = (telecomList: ITelecom[], system: TelecomSystem) => {
    if (telecomList.length > 0) {
      const telecom = telecomList.find((item: any) => item.system === system);
      if (telecom) {
        return telecom.value;
      }
      return '';
    }
    return '';
  };

  return (
    <VStack
      _light={{ bg: { base: '#fff', md: '' } }}
      _dark={{ bg: { base: 'coolGray.900', md: 'coolGray.800' } }}
      zIndex={2}
      borderRadius={{ md: 10 }}
      px={{ base: 0, md: 4 }}
      mb={{ md: 20 }}>
      {/*-start-header*/}
      <HStack mt={{ base: 0, md: 5 }} w={{ base: '100%', md: '50%' }} alignItems={'center'}>
        <Hidden from={'md'}>
          <HStack>
            <Pressable ml={4} mt={1}>
              <Icon as={Ionicons} name='arrow-back' size='xl' color={'coolGray.700'} />
            </Pressable>
          </HStack>
        </Hidden>
        <Hidden till={'md'}>
          <Text mb={5} fontSize={'2xl'} fontWeight={'bold'}>
            Information of Patient
          </Text>
        </Hidden>
      </HStack>
      <HStack>
        <Button onPress={openDocumentPicker}>Upload FHIR file</Button>
      </HStack>
      {/*-start-profile*/}
      <Hidden till={'md'}>
        <HStack mt={5}>
          <VStack w={{ base: '65%', md: '65%' }} px={8} py={6} backgroundColor={'white'} rounded={'lg'} space='2' h={'100%'}>
            <HStack w={'full'}>
              <Button backgroundColor={'white'} p={0}>
                <Image
                  source={patient?.photo && patient.photo[0].url ? { uri: patient.photo[0].url } : require('../../assets/default-avatar.jpg')}
                  alt='Alternate Text'
                  size='32'
                  rounded={'lg'}
                />
              </Button>

              <VStack flex={1} ml={4} space={2} justifyContent={'center'}>
                <Text fontWeight={'bold'} fontSize={'xl'}>
                  {patient?.name && patient.name[0].given ? patient.name[0].given[0] : ''}{' '}
                  {patient?.name && patient?.name[0].family ? patient.name[0].family : ''}
                </Text>
                <Text color={'coolGray.500'} fontSize={'lg'}>
                  {patient?.checkInTime && `Check In date ${formatDate(patient.checkInTime)}`}
                </Text>
                <HStack alignItems={'center'} space={8}>
                  {patient?.gender && (
                    <HStack justifyContent={'center'} alignItems={'center'} py={2} px={6} rounded={'full'} backgroundColor={'coolGray.200'}>
                      <Icon
                        size='9'
                        name={patient.gender.toUpperCase() === 'MALE' ? 'male-symbol' : 'female-symbol'}
                        color={patient.gender.toUpperCase() === 'MALE' ? '#4EA0F7' : 'pink.400'}
                        as={Foundation}
                      />
                      <Text fontWeight={'bold'} fontSize={'lg'}>
                        {patient.gender}
                      </Text>
                    </HStack>
                  )}
                  {patient?.birthDate && (
                    <Text color={'coolGray.500'} fontSize={'lg'}>
                      {calculateAge(patient.birthDate)}-old
                    </Text>
                  )}
                  {patient?.ethnicity && (
                    <Text color={'coolGray.500'} fontSize={'lg'}>
                      {patient.ethnicity}
                    </Text>
                  )}
                </HStack>
              </VStack>
            </HStack>

            <Divider orientation='horizontal' alignSelf={'center'} backgroundColor={'coolGray.200'} h={0.5} w={'95%'} my={4} />

            <VStack space={4}>
              <HStack space={4} alignItems={'start'}>
                <FieldLayout width={'50%'} field={'Address'} value={patient?.address ? patient.address[0].text : ''}>
                  <Icon size='6' name={'location-outline'} color={'#4EA0F7'} as={Ionicons} />
                </FieldLayout>
              </HStack>

              <HStack space={4}>
                <FieldLayout width={'50%'} field={'Phone'} value={patient?.telecom ? getTelecomValue(patient.telecom, TelecomSystem.PHONE) : ''}>
                  <Icon size='6' name={'phone'} color={'#4EA0F7'} as={Feather} />
                </FieldLayout>
                <FieldLayout width={'50%'} field={'Email'} value={patient?.telecom ? getTelecomValue(patient.telecom, TelecomSystem.EMAIL) : ''}>
                  <Icon size='6' name={'email'} color={'#4EA0F7'} as={MaterialIcons} />
                </FieldLayout>
              </HStack>
            </VStack>

            <Divider orientation='horizontal' alignSelf={'center'} backgroundColor={'coolGray.200'} h={0.5} w={'95%'} my={4} />

            <VStack space={4}>
              <HStack>
                <Text color={'coolGray.800'} fontSize={'2xl'}>
                  Health Overview
                </Text>
              </HStack>
              <FieldLayout width={'100%'} field={'Health History'} value={patient?.healthHistory ? patient.healthHistory : ''}>
                <FontAwesome5 name='notes-medical' size={24} color='#4EA0F7' />
              </FieldLayout>
              <FieldLayout width={'100%'} field={'Allergies'} value={patient?.allergies ? patient.allergies : ''}>
                <MaterialCommunityIcons name='allergy' size={24} color='#4EA0F7' />
              </FieldLayout>
              <FieldLayout width={'100%'} field={'Immunization Status'} value={patient?.immunizations ? patient.immunizations : ''}>
                <FontAwesome5 name='syringe' size={24} color='#4EA0F7' />
              </FieldLayout>
            </VStack>
          </VStack>
          {/*-end-*/}
          {/*start-assigned doctor*/}
          <VStack ml={4} px={8} py={6} w={{ base: '50%', md: '35%' }} backgroundColor={'white'} rounded={'lg'} space='2' h={'100%'}>
            {/**/}
            <HStack alignItems={'center'}>
              <VStack>
                <HStack>
                  <Image rounded={'lg'} source={require('../../assets/default-avatar.jpg')} alt='Alternate Text' size='32' />
                  <VStack pl={5} justifyContent={'space-around'}>
                    <Text fontWeight={'bold'} fontSize={'lg'} color={'black'} my={5}>
                      Assigned Doctor
                    </Text>
                    {showDefaultData ? (
                      <></>
                    ) : (
                      patient?.assignedDoctor && (
                        <>
                          <Text fontWeight={'bold'} fontSize={'xl'}>
                            Dr. Samantha
                          </Text>
                          <Text color={'#4EA0F7'} fontSize={'lg'}>
                            Physical Therapy
                          </Text>
                        </>
                      )
                    )}
                    {/*  */}
                  </VStack>
                </HStack>
                {/**/}
                {showDefaultData ? (
                  <></>
                ) : (
                  patient?.assignedDoctor && (
                    <VStack>
                      <Box
                        mt={2}
                        ml={5}
                        backgroundColor={'#4EA0F7'}
                        justifyContent={'center'}
                        alignItems='center'
                        width={20}
                        height={8}
                        borderRadius='2xl'
                        borderColor={'#4EA0F7'}>
                        <Text fontWeight={'bold'} fontSize={'xl'} color={'#fff'}>
                          4.5
                        </Text>
                      </Box>
                      <HStack mt={2}>
                        <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                        <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                        <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                        <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                        <Icon size='6' name={'star-half-empty'} color={'#f1c40f'} as={FontAwesome} />
                      </HStack>
                    </VStack>
                  )
                )}
              </VStack>
              {/*-end-*/}
            </HStack>
          </VStack>
          {/**/}
        </HStack>
      </Hidden>
      <Hidden from={'md'}>
        <VStack h={'100%'}>
          <VStack w={'100%'} py={6} backgroundColor={'white'} rounded={'lg'}>
            <HStack>
              {/*<Button backgroundColor={'white'} onPress={openImagePickerAsync}>*/}
              {/*  <Image*/}
              {/*    source={patientUser?.imageUrl ? { uri: patientUser?.imageUrl } : undefined}*/}
              {/*    rounded={'md'}*/}
              {/*    size={32}*/}
              {/*    accessibilityLabel={patientUser?.lastName.charAt(0).toUpperCase()}*/}
              {/*    alt={patientUser?.lastName.charAt(0).toUpperCase()}*/}
              {/*  />*/}
              {/*</Button>*/}
              <VStack mt={2} w={'45%'}>
                <Text fontWeight={'bold'} fontSize={'20'} color={'coolGray.900'}>
                  {/*{patientUser?.firstName + ' ' + patientUser?.lastName}*/}
                </Text>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  {/*Check In date: {patient?.checkInTime && formatDate(patient.checkInTime)}*/}
                </Text>
              </VStack>
            </HStack>

            <VStack shadow={1} mt={3} w={'90%'} p={3} mx={5} borderWidth={1} rounded={'lg'} borderColor={'coolGray.300'}>
              <HStack mb={2} alignItems={'center'}>
                <MaterialCommunityIcons name='clipboard-text-outline' size={28} color='#4EA0F7' />
                <Text ml={2} color={'coolGray.900'} fontSize={'20'} fontWeight={'bold'}>
                  Patient Info
                </Text>
              </HStack>
              <VStack flex={1} space={2}>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  {/*Gender: {patientUser?.gender}*/}
                </Text>
                {/*{patientUser?.birthday && (*/}
                {/*  <Text color={'coolGray.500'} fontSize={'16'}>*/}
                {/*    Age: {calculateAge(patientUser.birthday)}-old*/}
                {/*  </Text>*/}
                {/*)}*/}
                {/*{patient?.ethnicity && (*/}
                {/*  <Text color={'coolGray.500'} fontSize={'16'}>*/}
                {/*    Ethnicity: {patient.ethnicity}*/}
                {/*  </Text>*/}
                {/*)}*/}
                <Text color={'coolGray.500'} fontSize={'16'}>
                  {/*Status: {patient?.status}*/}
                </Text>
              </VStack>
            </VStack>

            <VStack shadow={1} mt={3} w={'90%'} p={3} mx={5} borderWidth={1} rounded={'lg'} borderColor={'coolGray.300'}>
              <HStack mb={2} alignItems={'center'}>
                <Ionicons name='home' size={28} color='#4EA0F7' />
                <Text ml={2} color={'coolGray.900'} fontSize={'20'} fontWeight={'bold'}>
                  Address
                </Text>
              </HStack>
              <VStack space={2}>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  {/*Address: {patientUser?.address}*/}
                </Text>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  {/*Phone: {patientUser?.phoneNumber ? '0931 723 755' : ''}*/}
                </Text>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  {/*Email: {patientUser?.email}*/}
                </Text>
              </VStack>
            </VStack>

            <VStack shadow={1} mt={3} w={'90%'} p={3} mx={5} borderWidth={1} rounded={'lg'} borderColor={'coolGray.300'}>
              <HStack mb={2} alignItems={'center'}>
                <FontAwesome5 name='notes-medical' size={28} color='#4EA0F7' />
                <Text ml={2} color={'coolGray.900'} fontSize={'20'} fontWeight={'bold'}>
                  Health Overview
                </Text>
              </HStack>
              <VStack>
                <Text color={'coolGray.300'} fontSize={'16'}>
                  Health History
                </Text>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  {/*{patient?.healthHistory ? patient.healthHistory : ''}*/}
                </Text>
              </VStack>
              <VStack>
                <Text color={'coolGray.300'} fontSize={'16'}>
                  Allergies
                </Text>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  {/*{patient?.allergies ? patient.allergies : ''}*/}
                </Text>
              </VStack>
              <VStack>
                <Text color={'coolGray.300'} fontSize={'16'}>
                  Immunizations Status
                </Text>
                <Text color={'coolGray.500'} fontSize={'16'}>
                  {/*{patient?.immunizations ? patient.immunizations : ''}*/}
                </Text>
              </VStack>
            </VStack>
          </VStack>
          {/*-end-*/}
          {/*start-assigned doctor*/}
          <VStack mt={4} w={'100%'} py={2} backgroundColor={'white'} rounded={'lg'}>
            {/**/}
            <VStack alignItems={'center'}>
              <VStack>
                <HStack alignItems={'center'}>
                  <Image
                    rounded={'md'}
                    size={32}
                    source={{
                      uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.40549212462773987.jpg'
                    }}
                    alt='Alternate Text'
                  />
                  <VStack ml={5} space={2} mb={7}>
                    <Text fontWeight={'bold'} fontSize={'20'} color={'coolGray.900'} my={5}>
                      Assigned Doctor
                    </Text>
                    <Text fontWeight={'medium'} fontSize={'20'} color={'coolGray.500'}>
                      Dr. Samantha
                    </Text>
                    <Text color={'#4EA0F7'} fontSize={'16'}>
                      Physical Therapy
                    </Text>
                    {/*  */}
                  </VStack>
                </HStack>
                {/**/}
                <VStack>
                  <Box
                    mt={1}
                    ml={7}
                    backgroundColor={'#4EA0F7'}
                    justifyContent={'center'}
                    alignItems='center'
                    width={16}
                    height={8}
                    borderRadius='2xl'
                    borderColor={'#4EA0F7'}>
                    <Text fontWeight={'medium'} fontSize={'16'} color={'#fff'}>
                      4.5
                    </Text>
                  </Box>
                  <HStack mt={2} ml={3}>
                    <Icon size='5' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                    <Icon size='5' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                    <Icon size='5' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                    <Icon size='5' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                    <Icon size='5' name={'star-half-empty'} color={'#f1c40f'} as={FontAwesome} />
                  </HStack>
                </VStack>
              </VStack>
              {/*-end-*/}
            </VStack>
          </VStack>
          {/**/}
        </VStack>
      </Hidden>
      {/*end-profile*/}
    </VStack>
  );
}

export default function FhirPatientDetails() {
  const records = useAppSelector((state) => selectAllMedicalRecords(state.hospital.medicalRecords));
  const [didRemoveAllRecords, setDidRemoveAllRecords] = useState(false);
  const dispatch = useAppDispatch();

  // reset medical records
  useEffect(() => {
    dispatch(medicalRecordActions.setMedicalRecords([]));
  }, [dispatch]);

  // set first loading
  useEffect(() => {
    if (records.length === 0) {
      setDidRemoveAllRecords(true);
    }
  }, [records]);

  return (
    <>
      <DashboardLayout title='' displayMenuButton displayScreenTitle={false} displayAlternateMobileHeader rightPanelMobileHeader={true}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <PatientInfo />
          {didRemoveAllRecords && <MedicalHistory />}
        </ScrollView>
        <WebFooter />
      </DashboardLayout>
    </>
  );
}
