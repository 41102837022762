import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMedicalRecord } from '../../../types/IMedicalRecord';
import { RootState } from '../../index';

const entityAdapter = createEntityAdapter<IMedicalRecord>({
  selectId: (record) => record.id,
  sortComparer: (a, b) => {
    return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
  }
});

const initialState = entityAdapter.getInitialState({
  selectedMedicalRecordId: '',
  isFhirResource: false
});

type IMedicalRecordState = typeof initialState;

const medicalRecordsSlice = createSlice({
  name: 'medicalRecords',
  initialState,
  reducers: {
    setMedicalRecords: (state: IMedicalRecordState, action: PayloadAction<IMedicalRecord[]>) => {
      entityAdapter.setAll(state, action.payload);
    },
    setSelectedMedicalRecord: (state: IMedicalRecordState, action: PayloadAction<string>) => {
      state.selectedMedicalRecordId = action.payload;
    },
    deleteMedicalRecord: (state: IMedicalRecordState, action: PayloadAction<string>) => {
      entityAdapter.removeOne(state, action.payload);
    },
    isFhirResource: (state: IMedicalRecordState, action: PayloadAction<boolean>) => {
      state.isFhirResource = action.payload;
    }
  }
});

export const selectAllMedicalRecords = entityAdapter.getSelectors().selectAll;
export const selectMedicalRecordById = entityAdapter.getSelectors().selectById;
export const selectSelectedMedicalRecord = (state: RootState) => {
  return state.hospital.medicalRecords.selectedMedicalRecordId;
};
export const medicalRecordActions = medicalRecordsSlice.actions;
export default medicalRecordsSlice;
