import { combineReducers } from '@reduxjs/toolkit';
import contactGroupsSlice from './chat/contact-groups/contact-groups-slice';
import contactsSlice from './chat/contacts/contacts-slice';

const contactReducers = combineReducers({
  groups: contactGroupsSlice.reducer,
  contacts: contactsSlice.reducer
});

export default contactReducers;
