import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  FormControl,
  Hidden,
  HStack,
  Icon,
  Image,
  Input,
  Modal,
  Pressable,
  ScrollView,
  Select,
  Text,
  Tooltip,
  VStack
} from 'native-base';
import { Feather, FontAwesome, Foundation, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import DashboardLayout from '../../layouts/DashboardLayout';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useAppSelector } from '../../store/hooks';
import { selectDoctorById } from '../../store/hospital/doctors/doctors-slice';
import { selectUserById } from '../../store/hospital/users/users-slice';
import * as ImagePicker from 'expo-image-picker';
import { Storage } from 'aws-amplify';
import { graphQLUpdateUserImage } from '../../utils/graphql/user_utils';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import { calculateAge } from '../../utils/time_utils';
import { RootStackScreensEnum } from '../../navigation/enum';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { MainStackParamList } from '../../types/Stacks';

function Banner() {
  const route = useRoute();
  const { doctorId } = route.params as any;
  const doctor = useAppSelector((state) => selectDoctorById(state.hospital.doctors, doctorId));
  const doctorUser = useAppSelector((state) => selectUserById(state.hospital.users, doctor?.doctorUserId || ''));
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [awsUri, setAwsUri] = useState<string>('');
  const [previousAvatarUri, setPreviousAvatarUri] = useState<string>('');
  const { user } = useAuth();
  const navigation = useNavigation<NativeStackNavigationProp<MainStackParamList, RootStackScreensEnum.Admin>>();
  // upload image
  const fetchImageUri = async (uri: string) => {
    const respone = await fetch(uri);
    const blob = await respone.blob();
    return blob;
  };
  const deleteOldImageCallback = useCallback(async (oldImageUri: string) => {
    const oldImageKey = `${oldImageUri.substring(oldImageUri.lastIndexOf('/') + 1)}`;
    console.log('OLD IMAGE KEY --', oldImageKey);
    try {
      await Storage.remove(oldImageKey, { level: 'public' });
      console.log('DELETED OLD IMAGE --', oldImageKey);
    } catch (e) {
      console.log(e);
    }
  }, []);
  const uploadFile = useCallback(
    async (file: any) => {
      if (!user) {
        return;
      }
      console.log('image', doctorUser?.imageUrl);
      const oldImageUri = previousAvatarUri || doctorUser?.imageUrl;
      const img = await fetchImageUri(file.uri);
      try {
        const res = await Storage.put(`my-image-doctor-filename${Math.random()}.jpg`, img, {
          level: 'public',
          contentType: 'image/jpeg',
          progressCallback(uploadProgress) {
            console.log('PROGRESS--', uploadProgress.loaded + '/' + uploadProgress.total);
          }
        });
        const result = await Storage.get(res.key);
        console.log('RESULT --- ', result);
        const awsImageUri = result.substring(0, result.indexOf('?'));
        if (previousAvatarUri) {
          await deleteOldImageCallback(previousAvatarUri);
        } else if (doctorUser?.imageUrl) {
          await deleteOldImageCallback(doctorUser.imageUrl);
        }
        setAwsUri(awsImageUri);
        setPreviousAvatarUri(awsImageUri);
      } catch (e) {
        console.log(e);
      }
    },
    [deleteOldImageCallback, doctorUser?.imageUrl, previousAvatarUri, user]
  );
  ////end upload img ////
  const openImagePickerAsync = async () => {
    const pickerResult = await ImagePicker.launchImageLibraryAsync();
    if (pickerResult.canceled) {
      return;
    }
    console.log(pickerResult);
    uploadFile(pickerResult);
    setSelectedImage({ localUri: pickerResult.uri });
  };

  useEffect(() => {
    (async () => {
      if (awsUri) {
        const updatedUser = await graphQLUpdateUserImage(doctor?.doctorUserId || '', awsUri);
        console.log('UPDATED USER --', updatedUser, 'AWS URI --', awsUri);
      }
    })();
  }, [awsUri, doctor?.doctorUserId]);

  const [isModalEdit, setIsModalEdit] = useState(false);
  const [gender, setGender] = useState('');
  const [isModalDelete, setIsModalDelete] = useState(false);
  const toggleModal = () => {
    setIsModalDelete(!isModalDelete);
  };
  const deleteAccount = () => {
    // Hàm xử lý việc xóa tài khoản
    // ...
    toggleModal();
  };
  // @ts-ignore
  return (
    <>
      <VStack
        _light={{ bg: { base: '#fff', md: '' } }}
        _dark={{ bg: { base: 'coolGray.900', md: 'coolGray.800' } }}
        zIndex={2}
        borderRadius={{ md: 10 }}
        px={{ base: 0, md: 4 }}
        // pb={{ base: 4, md: 0 }}
        mb={{ md: 4 }}>
        <HStack mt={{ base: 0, md: 5 }} w={{ base: '100%', md: '50%' }} alignItems={'center'}>
          <Hidden from={'md'}>
            <HStack>
              <Pressable ml={4} onPress={() => navigation.navigate(RootStackScreensEnum.Admin, { screen: RootStackScreensEnum.DoctorList })} mt={1}>
                <Icon as={Ionicons} name='arrow-back' size='xl' color={'coolGray.700'} />
              </Pressable>
            </HStack>
          </Hidden>
          <Hidden till={'md'}>
            <Text mb={5} fontSize={'2xl'} fontWeight={'bold'}>
              Information of Doctor
            </Text>
          </Hidden>
        </HStack>
        <HStack alignItems='center' justifyContent='space-between'>
          <VStack space='2' w={{ base: '100%', md: '100%' }}>
            <Hidden till={'md'}>
              <VStack>
                <HStack justifyContent={'space-between'}>
                  <Hidden till={'md'}>
                    <Button
                      variant='ghost'
                      justifyContent={'center'}
                      alignItems={'center'}
                      width={{ base: 0, md: '56' }}
                      height={{ base: 0, md: '12' }}
                      rounded={'full'}
                      borderColor={'#4EA0F7'}
                      borderWidth={2}>
                      <HStack>
                        <Icon size='6' name={'calendar'} color='#4EA0F7' as={FontAwesome} />
                        <Text pl={2} fontSize={'lg'} color='#4EA0F7'>
                          + New Appointment
                        </Text>
                      </HStack>
                    </Button>
                  </Hidden>
                  <HStack>
                    <HStack justifyContent={'space-between'} mb='15'>
                      <Tooltip label={'Edit Info'}>
                        <Pressable
                          onPress={() => setIsModalEdit(true)}
                          shadow={7}
                          position='absolute'
                          right={{ base: 100, md: 40 }}
                          rounded='full'
                          _hover={{ bg: '#4EA0F7' }}
                          _light={{ bg: '#0564C8' }}
                          _dark={{ bg: 'primary.500' }}
                          w={{ base: 16, md: 24 }}
                          h={{ base: 8, md: 10 }}
                          alignItems='center'
                          justifyContent='center'>
                          <HStack px={2} alignItems={'center'}>
                            <Icon size={{ base: 4, md: 5 }} name='edit' color='#fff' as={MaterialIcons} />
                            <Text pl={2} fontSize={{ base: 'sm', md: 'md' }} color='#fff'>
                              Edit
                            </Text>
                          </HStack>
                        </Pressable>
                      </Tooltip>
                      <Modal isOpen={isModalEdit} onClose={() => setIsModalEdit(false)}>
                        <Modal.Content>
                          <Modal.Header>Edit Info</Modal.Header>
                          <Tooltip label='Close'>
                            <Pressable
                              px={4}
                              right={2}
                              top={2}
                              position={'absolute'}
                              alignItems='center'
                              justifyContent='center'
                              size={'8'}
                              rounded='full'
                              _hover={{ bg: '#E4EBE4' }}>
                              <Icon onPress={() => setIsModalEdit(false)} as={<MaterialIcons name='close' />} size={6} color='coolGray.900' />
                            </Pressable>
                          </Tooltip>
                          <Modal.Body>
                            <FormControl>
                              <FormControl.Label>Name</FormControl.Label>
                              <Input placeholder='Enter your name' />
                            </FormControl>
                            <FormControl>
                              <FormControl.Label>Gender</FormControl.Label>
                              <Select selectedValue={gender} onValueChange={(value) => setGender(value)} placeholder='Select gender'>
                                <Select.Item label='Male' value='male' />
                                <Select.Item label='Female' value='female' />
                                <Select.Item label='Other' value='other' />
                              </Select>
                            </FormControl>
                            <FormControl>
                              <FormControl.Label>Phone number</FormControl.Label>
                              <Input placeholder='Enter your phone number' />
                            </FormControl>
                            <FormControl>
                              <FormControl.Label>Email</FormControl.Label>
                              <Input placeholder='Enter your email' />
                            </FormControl>
                            <FormControl>
                              <FormControl.Label>Address</FormControl.Label>
                              <Input placeholder='Enter your address' />
                            </FormControl>
                            <FormControl>
                              <FormControl.Label>Avatar</FormControl.Label>
                              <Button backgroundColor={'white'} onPress={openImagePickerAsync}>
                                <Image
                                  source={
                                    selectedImage?.localUri
                                      ? { uri: selectedImage?.localUri }
                                      : doctorUser?.imageUrl
                                      ? { uri: doctorUser?.imageUrl }
                                      : require('../../assets/Rose.png')
                                  }
                                  alt='Alternate Text'
                                  size='32'
                                  rounded={'lg'}
                                />
                              </Button>
                            </FormControl>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button.Group variant='ghost'>
                              <Button onPress={() => setIsModalEdit(false)}>Save</Button>
                            </Button.Group>
                          </Modal.Footer>
                        </Modal.Content>
                      </Modal>
                      <Tooltip label={'Delete'}>
                        <Pressable
                          onPress={toggleModal}
                          shadow={7}
                          position='absolute'
                          right={{ base: 5, md: 8 }}
                          // zIndex={1}
                          rounded='full'
                          _hover={{ bg: 'red.300' }}
                          _light={{ bg: 'red.700' }}
                          _dark={{ bg: 'primary.500' }}
                          w={{ base: 16, md: 24 }}
                          h={{ base: 8, md: 10 }}
                          alignItems='center'
                          justifyContent='center'>
                          <HStack alignItems={'center'} px={2}>
                            <Icon size={{ base: 4, md: 5 }} name='delete' color='#fff' as={MaterialIcons} />
                            <Text pl={1} fontSize={{ base: 'sm', md: 'md' }} color='#fff'>
                              Delete
                            </Text>
                          </HStack>
                        </Pressable>
                      </Tooltip>
                      <Modal isOpen={isModalDelete} onClose={toggleModal}>
                        <Modal.Content>
                          <Modal.Header>Confirm Delete</Modal.Header>
                          <Tooltip label='Close'>
                            <Pressable
                              px={4}
                              right={2}
                              top={2}
                              position={'absolute'}
                              alignItems='center'
                              justifyContent='center'
                              size={'8'}
                              rounded='full'
                              _hover={{ bg: '#E4EBE4' }}>
                              <Icon onPress={toggleModal} as={<MaterialIcons name='close' />} size={6} color='coolGray.900' />
                            </Pressable>
                          </Tooltip>
                          <Modal.Body>
                            <Center>
                              <Text>Are you sure to delete this account?</Text>
                            </Center>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button.Group variant='ghost'>
                              <Button onPress={deleteAccount}>Delete</Button>
                            </Button.Group>
                          </Modal.Footer>
                        </Modal.Content>
                      </Modal>
                    </HStack>
                  </HStack>
                </HStack>
                {/*Appointment Schdule */}
                <HStack justifyContent={'space-between'}>
                  <VStack mt={5} pb={5} backgroundColor={'white'} rounded={'lg'} space='2' w={'30%'} h={{ base: 0, md: '450' }}>
                    <Text pl={6} fontSize={'lg'} fontWeight={'bold'}>
                      Appointment Schdule
                    </Text>
                    <ScrollView mt={5} mx={5} w={'90%'} h='80%' showsVerticalScrollIndicator={false}>
                      <VStack alignSelf={'center'} rounded={'lg'} space='2' w={'100%'} borderColor={'coolGray.300'} borderWidth={1} shadow={3} mb={3}>
                        <HStack pl={3} pt={3}>
                          <Image rounded={'full'} source={require('../../assets/Steven.png')} alt='Alternate Text' size='12' />
                          <VStack pl={2}>
                            <Text fontSize={'lg'}>Cive Slauw</Text>
                            <Text color={'#4EA0F7'} fontSize={'lg'}>
                              Physical Therapy
                            </Text>
                          </VStack>
                        </HStack>
                        <Text color={'coolGray.800'} pl={3} fontWeight={'bold'} fontSize={'lg'}>
                          Sat,23/08/2020
                        </Text>
                        <Text color={'coolGray.800'} pl={3} fontWeight={'bold'} fontSize={'lg'}>
                          08:00 - 09:30 AM
                        </Text>
                      </VStack>

                      <VStack alignSelf={'center'} rounded={'lg'} space='2' w={'100%'} borderColor={'coolGray.300'} borderWidth={1} shadow={3} mb={3}>
                        <HStack pl={3} pt={3}>
                          <Image rounded={'full'} source={require('../../assets/Steven.png')} alt='Alternate Text' size='12' />
                          <VStack pl={2}>
                            <Text fontSize={'lg'}>Cive Slauw</Text>
                            <Text color={'#4EA0F7'} fontSize={'lg'}>
                              Physical Therapy
                            </Text>
                          </VStack>
                        </HStack>
                        <Text color={'coolGray.800'} pl={3} fontWeight={'bold'} fontSize={'lg'}>
                          Sat,23/08/2020
                        </Text>
                        <Text color={'coolGray.800'} pl={3} fontWeight={'bold'} fontSize={'lg'}>
                          08:00 - 09:30 AM
                        </Text>
                      </VStack>

                      <VStack alignSelf={'center'} rounded={'lg'} space='2' w={'100%'} borderColor={'coolGray.300'} borderWidth={1} shadow={3} mb={3}>
                        <HStack pl={3} pt={3}>
                          <Image rounded={'full'} source={require('../../assets/Steven.png')} alt='Alternate Text' size='12' />
                          <VStack pl={2}>
                            <Text fontSize={'lg'}>Cive Slauw</Text>
                            <Text color={'#4EA0F7'} fontSize={'lg'}>
                              Physical Therapy
                            </Text>
                          </VStack>
                        </HStack>
                        <Text color={'coolGray.800'} pl={3} fontWeight={'bold'} fontSize={'lg'}>
                          Sat,23/08/2020
                        </Text>
                        <Text color={'coolGray.800'} pl={3} fontWeight={'bold'} fontSize={'lg'}>
                          08:00 - 09:30 AM
                        </Text>
                      </VStack>

                      <VStack alignSelf={'center'} rounded={'lg'} space='2' w={'100%'} borderColor={'coolGray.300'} borderWidth={1} shadow={3} mb={3}>
                        <HStack pl={3} pt={3}>
                          <Image rounded={'full'} source={require('../../assets/Steven.png')} alt='Alternate Text' size='12' />
                          <VStack pl={2}>
                            <Text fontSize={'lg'}>Cive Slauw</Text>
                            <Text color={'#4EA0F7'} fontSize={'lg'}>
                              Physical Therapy
                            </Text>
                          </VStack>
                        </HStack>
                        <Text color={'coolGray.800'} pl={3} fontWeight={'bold'} fontSize={'lg'}>
                          Sat,23/08/2020
                        </Text>
                        <Text color={'coolGray.800'} pl={3} fontWeight={'bold'} fontSize={'lg'}>
                          08:00 - 09:30 AM
                        </Text>
                      </VStack>

                      <VStack alignSelf={'center'} rounded={'lg'} space='2' w={'100%'} borderColor={'coolGray.300'} borderWidth={1} shadow={3} mb={3}>
                        <HStack pl={3} pt={3}>
                          <Image rounded={'full'} source={require('../../assets/Steven.png')} alt='Alternate Text' size='12' />
                          <VStack pl={2}>
                            <Text fontSize={'lg'}>Cive Slauw</Text>
                            <Text color={'#4EA0F7'} fontSize={'lg'}>
                              Physical Therapy
                            </Text>
                          </VStack>
                        </HStack>
                        <Text color={'coolGray.800'} pl={3} fontWeight={'bold'} fontSize={'lg'}>
                          Sat,23/08/2020
                        </Text>
                        <Text color={'coolGray.800'} pl={3} fontWeight={'bold'} fontSize={'lg'}>
                          08:00 - 09:30 AM
                        </Text>
                      </VStack>

                      <VStack alignSelf={'center'} rounded={'lg'} space='2' w={'100%'} borderColor={'coolGray.300'} borderWidth={1} shadow={3} mb={3}>
                        <HStack pl={3} pt={3}>
                          <Image rounded={'full'} source={require('../../assets/Steven.png')} alt='Alternate Text' size='12' />
                          <VStack pl={2}>
                            <Text fontSize={'lg'}>Cive Slauw</Text>
                            <Text color={'#4EA0F7'} fontSize={'lg'}>
                              Physical Therapy
                            </Text>
                          </VStack>
                        </HStack>
                        <Text color={'coolGray.800'} pl={3} fontWeight={'bold'} fontSize={'lg'}>
                          Sat,23/08/2020
                        </Text>
                        <Text color={'coolGray.800'} pl={3} fontWeight={'bold'} fontSize={'lg'}>
                          08:00 - 09:30 AM
                        </Text>
                      </VStack>
                    </ScrollView>
                  </VStack>

                  {/*Profile*/}
                  <VStack w={{ base: '55%', md: '65%' }} mt={5} backgroundColor={'white'} rounded={'lg'} space='2' h={{ base: 0, md: '450' }}>
                    <HStack px={10} py={5}>
                      <Button backgroundColor={'white'} onPress={openImagePickerAsync}>
                        <Image
                          source={
                            selectedImage?.localUri
                              ? { uri: selectedImage?.localUri }
                              : doctorUser?.imageUrl
                              ? { uri: doctorUser?.imageUrl }
                              : require('../../assets/Rose.png')
                          }
                          alt='Alternate Text'
                          size='32'
                          rounded={'lg'}
                        />
                      </Button>
                      <VStack justifyContent={'center'} space={2} flex={1} pl={5}>
                        <Text fontWeight={'bold'} fontSize={'lg'}>
                          {doctorUser?.firstName + ' ' + doctorUser?.lastName}
                        </Text>
                        <Text color={'#F4A460'} fontSize={'lg'}>
                          {doctor?.specialist}
                        </Text>
                        <HStack alignItems={'center'} space={8}>
                          {doctorUser?.gender && (
                            <HStack justifyContent={'center'} alignItems={'center'} py={2} px={6} rounded={'full'} backgroundColor={'coolGray.200'}>
                              <Icon
                                size='9'
                                name={doctorUser.gender === 'MALE' ? 'male-symbol' : 'female-symbol'}
                                color={doctorUser.gender === 'MALE' ? '#4EA0F7' : 'pink.400'}
                                as={Foundation}
                              />
                              <Text fontWeight={'bold'} fontSize={'lg'}>
                                {doctorUser.gender}
                              </Text>
                            </HStack>
                          )}
                          {doctorUser?.birthday && (
                            <Text color={'coolGray.500'} fontSize={'lg'}>
                              {calculateAge(doctorUser.birthday)}-old
                            </Text>
                          )}
                        </HStack>
                      </VStack>
                    </HStack>

                    <Box alignSelf={'center'} backgroundColor={'coolGray.200'} w={'90%'} height={0.5}></Box>

                    <HStack px={10} py={5}>
                      <VStack justifyContent={'space-between'}>
                        <HStack>
                          <Box
                            justifyContent={'center'}
                            alignItems='center'
                            width={12}
                            height={12}
                            borderRadius='full'
                            borderColor={'#4EA0F7'}
                            borderWidth={'2'}>
                            <Icon size='6' name={'location-outline'} color={'#4EA0F7'} as={Ionicons} />
                          </Box>
                          <VStack pl={3}>
                            <Text color={'coolGray.200'} fontSize={'xl'}>
                              Address
                            </Text>
                            <Text width={50} color={'coolGray.500'} fontSize={'lg'}>
                              {doctorUser?.address}
                            </Text>
                          </VStack>
                        </HStack>
                        <HStack mt={5}>
                          <Box
                            justifyContent={'center'}
                            alignItems='center'
                            width={12}
                            height={12}
                            borderRadius='full'
                            borderColor={'#4EA0F7'}
                            borderWidth={'2'}>
                            <Icon size='6' name={'phone'} color={'#4EA0F7'} as={Feather} />
                          </Box>
                          <VStack mb={1} pl={3}>
                            <Text color={'coolGray.200'} fontSize={'xl'}>
                              Phone
                            </Text>
                            <Text width={50} color={'coolGray.500'} fontSize={'lg'}>
                              {/*{doctorUser?.phoneNumber}*/}
                              0932 253 259
                            </Text>
                          </VStack>
                        </HStack>
                      </VStack>

                      <VStack ml={10}>
                        <Box>
                          <Image rounded={'xl'} width='96' height='32' source={require('../../assets/map.png')} alt='Alternate Text' />
                        </Box>
                        <HStack mt={5}>
                          <Box
                            justifyContent={'center'}
                            alignItems='center'
                            width={12}
                            height={12}
                            borderRadius='full'
                            borderColor={'#4EA0F7'}
                            borderWidth={'2'}>
                            <Icon size='6' name={'email'} color={'#4EA0F7'} as={MaterialIcons} />
                          </Box>
                          <VStack pl={3}>
                            <Text color={'coolGray.200'} fontSize={'xl'}>
                              Email
                            </Text>
                            <Text width={72} color={'coolGray.500'} fontSize={'lg'}>
                              {doctorUser?.email}
                            </Text>
                          </VStack>
                        </HStack>
                      </VStack>
                    </HStack>
                  </VStack>
                </HStack>
              </VStack>
            </Hidden>
            <Hidden from={'md'}>
              <VStack h={'100%'}>
                <VStack w={'100%'} py={6} backgroundColor={'white'} rounded={'lg'}>
                  <HStack>
                    <Button backgroundColor={'white'} onPress={openImagePickerAsync}>
                      <Image
                        source={doctorUser?.imageUrl ? { uri: doctorUser?.imageUrl } : undefined}
                        rounded={'md'}
                        size={32}
                        accessibilityLabel={doctorUser?.lastName.charAt(0).toUpperCase()}
                        alt={doctorUser?.lastName.charAt(0).toUpperCase()}
                      />
                    </Button>
                    <VStack mt={2} w={'45%'}>
                      <Text fontWeight={'bold'} fontSize={'20'}>
                        Dr. {doctorUser?.firstName + ' ' + doctorUser?.lastName}
                      </Text>
                    </VStack>
                  </HStack>
                  <HStack w={'full'}>
                    <VStack shadow={1} mt={3} w={'90%'} p={3} mx={5} borderWidth={1} rounded={'lg'} borderColor={'coolGray.300'}>
                      <HStack mb={2} alignItems={'center'}>
                        <MaterialCommunityIcons name='clipboard-text-outline' size={28} color='#4EA0F7' />
                        <Text ml={2} color={'coolGray.900'} fontSize={'20'} fontWeight={'bold'}>
                          Doctor Info
                        </Text>
                      </HStack>
                      <VStack flex={1} space={2}>
                        {doctorUser?.birthday && (
                          <Text color={'coolGray.500'} fontSize={'16'}>
                            Age: {calculateAge(doctorUser.birthday)}-old
                          </Text>
                        )}
                        <Text color={'coolGray.500'} fontSize={'16'}>
                          Gender: {doctorUser?.gender}
                        </Text>
                        <Text color={'coolGray.500'} fontSize={'16'}>
                          Specialist: {doctor?.specialist}
                        </Text>
                      </VStack>
                    </VStack>
                  </HStack>

                  <VStack shadow={1} mt={3} w={'90%'} p={3} mx={5} borderWidth={1} rounded={'lg'} borderColor={'coolGray.300'}>
                    <HStack mb={2} alignItems={'center'}>
                      <Ionicons name='home' size={28} color='#4EA0F7' />
                      <Text ml={2} color={'coolGray.900'} fontSize={'20'} fontWeight={'bold'}>
                        Address
                      </Text>
                    </HStack>
                    <VStack space={2}>
                      <Text color={'coolGray.500'} fontSize={'16'}>
                        Address: {doctorUser?.address}
                      </Text>
                      <Text color={'coolGray.500'} fontSize={'16'}>
                        Phone: {doctorUser?.phoneNumber ? '0931 723 755' : ''}
                      </Text>
                      <Text color={'coolGray.500'} fontSize={'16'}>
                        Email: {doctorUser?.email}
                      </Text>
                    </VStack>
                  </VStack>

                  {/*appointment*/}
                  <VStack shadow={1} mt={3} w={'90%'} p={3} mx={5} borderWidth={1} rounded={'lg'} borderColor={'coolGray.300'}>
                    <HStack mb={2} alignItems={'center'}>
                      <Foundation name='clipboard-notes' size={28} color='#4EA0F7' />
                      <Text ml={2} color={'coolGray.900'} fontSize={'20'} fontWeight={'bold'}>
                        Appointment Schedule
                      </Text>
                    </HStack>
                    <ScrollView mt={5} w={['100%', '470']} h='100%' showsVerticalScrollIndicator={false}>
                      <VStack alignSelf={'center'} rounded={'lg'} space='2' w={'100%'} borderColor={'coolGray.300'} borderWidth={1} shadow={3} mb={3}>
                        <HStack pl={3} pt={3}>
                          <Image rounded={'full'} source={require('../../assets/Steven.png')} alt='Alternate Text' size='12' />
                          <VStack pl={2}>
                            <Text fontWeight={'medium'} fontSize={'16'}>
                              Cive Slauw
                            </Text>
                            <Text color={'#4EA0F7'} fontSize={'16'}>
                              Physical Therapy
                            </Text>
                          </VStack>
                        </HStack>
                        <Text color={'coolGray.500'} pl={3} fontSize={'16'}>
                          Sat,23/08/2020
                        </Text>
                        <Text color={'coolGray.500'} pl={3} fontSize={'16'}>
                          08:00 - 09:30 AM
                        </Text>
                      </VStack>

                      <VStack alignSelf={'center'} rounded={'lg'} space='2' w={'100%'} borderColor={'coolGray.300'} borderWidth={1} shadow={3} mb={3}>
                        <HStack pl={3} pt={3}>
                          <Image rounded={'full'} source={require('../../assets/Steven.png')} alt='Alternate Text' size='12' />
                          <VStack pl={2}>
                            <Text fontWeight={'medium'} fontSize={'16'}>
                              Cive Slauw
                            </Text>
                            <Text color={'#4EA0F7'} fontSize={'16'}>
                              Physical Therapy
                            </Text>
                          </VStack>
                        </HStack>
                        <Text color={'coolGray.500'} pl={3} fontSize={'16'}>
                          Sat,23/08/2020
                        </Text>
                        <Text color={'coolGray.500'} pl={3} fontSize={'16'}>
                          08:00 - 09:30 AM
                        </Text>
                      </VStack>

                      <VStack alignSelf={'center'} rounded={'lg'} space='2' w={'100%'} borderColor={'coolGray.300'} borderWidth={1} shadow={3} mb={3}>
                        <HStack pl={3} pt={3}>
                          <Image rounded={'full'} source={require('../../assets/Steven.png')} alt='Alternate Text' size='12' />
                          <VStack pl={2}>
                            <Text fontWeight={'medium'} fontSize={'16'}>
                              Cive Slauw
                            </Text>
                            <Text color={'#4EA0F7'} fontSize={'16'}>
                              Physical Therapy
                            </Text>
                          </VStack>
                        </HStack>
                        <Text color={'coolGray.500'} pl={3} fontSize={'16'}>
                          Sat,23/08/2020
                        </Text>
                        <Text color={'coolGray.500'} pl={3} fontSize={'16'}>
                          08:00 - 09:30 AM
                        </Text>
                      </VStack>

                      <VStack alignSelf={'center'} rounded={'lg'} space='2' w={'100%'} borderColor={'coolGray.300'} borderWidth={1} shadow={3} mb={3}>
                        <HStack pl={3} pt={3}>
                          <Image rounded={'full'} source={require('../../assets/Steven.png')} alt='Alternate Text' size='12' />
                          <VStack pl={2}>
                            <Text fontWeight={'medium'} fontSize={'16'}>
                              Cive Slauw
                            </Text>
                            <Text color={'#4EA0F7'} fontSize={'16'}>
                              Physical Therapy
                            </Text>
                          </VStack>
                        </HStack>
                        <Text color={'coolGray.500'} pl={3} fontSize={'16'}>
                          Sat,23/08/2020
                        </Text>
                        <Text color={'coolGray.500'} pl={3} fontSize={'16'}>
                          08:00 - 09:30 AM
                        </Text>
                      </VStack>

                      <VStack alignSelf={'center'} rounded={'lg'} space='2' w={'100%'} borderColor={'coolGray.300'} borderWidth={1} shadow={3} mb={3}>
                        <HStack pl={3} pt={3}>
                          <Image rounded={'full'} source={require('../../assets/Steven.png')} alt='Alternate Text' size='12' />
                          <VStack pl={2}>
                            <Text fontWeight={'medium'} fontSize={'16'}>
                              Cive Slauw
                            </Text>
                            <Text color={'#4EA0F7'} fontSize={'16'}>
                              Physical Therapy
                            </Text>
                          </VStack>
                        </HStack>
                        <Text color={'coolGray.500'} pl={3} fontSize={'16'}>
                          Sat,23/08/2020
                        </Text>
                        <Text color={'coolGray.500'} pl={3} fontSize={'16'}>
                          08:00 - 09:30 AM
                        </Text>
                      </VStack>

                      <VStack alignSelf={'center'} rounded={'lg'} space='2' w={'100%'} borderColor={'coolGray.300'} borderWidth={1} shadow={3} mb={3}>
                        <HStack pl={3} pt={3}>
                          <Image rounded={'full'} source={require('../../assets/Steven.png')} alt='Alternate Text' size='12' />
                          <VStack pl={2}>
                            <Text fontWeight={'medium'} fontSize={'16'}>
                              Cive Slauw
                            </Text>
                            <Text color={'#4EA0F7'} fontSize={'16'}>
                              Physical Therapy
                            </Text>
                          </VStack>
                        </HStack>
                        <Text color={'coolGray.500'} pl={3} fontSize={'16'}>
                          Sat,23/08/2020
                        </Text>
                        <Text color={'coolGray.500'} pl={3} fontSize={'16'}>
                          08:00 - 09:30 AM
                        </Text>
                      </VStack>
                    </ScrollView>
                  </VStack>
                </VStack>
              </VStack>
            </Hidden>
            {/*  -end- */}

            {/*Doctors Abilities*/}
            <Hidden till={'md'}>
              <VStack mt={5} backgroundColor={'white'} rounded={'lg'} space='2' w={'full'} h={'auto'}>
                <Text px={5} py={5} fontWeight={'bold'} fontSize={'lg'}>
                  Doctor&apos;s Abilities
                </Text>
                <HStack px={5} py={10} justifyContent={'space-between'}>
                  <VStack w={'10%'} alignItems={'center'}>
                    <Box borderRadius={'full'} backgroundColor={'#006633'} w={'40%'} height={2}></Box>
                    <Text fontWeight={'bold'} fontSize={'lg'}>
                      Operation
                    </Text>
                  </VStack>
                  <VStack w={'10%'} alignItems={'center'}>
                    <Box borderRadius={'full'} backgroundColor={'#009966'} w={'40%'} height={2}></Box>
                    <Text fontWeight={'bold'} fontSize={'lg'}>
                      Theraphy
                    </Text>
                  </VStack>
                  <VStack w={'10%'} alignItems={'center'}>
                    <Box borderRadius={'full'} backgroundColor={'#4EA0F7'} w={'40%'} height={2}></Box>
                    <Text fontWeight={'bold'} fontSize={'lg'}>
                      Mediation
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            </Hidden>
            {/*-end-*/}

            {/*list of doctors*/}
            <Hidden till={'md'}>
              <HStack px={5} py={5} mt={5} backgroundColor={'white'} rounded={'lg'} w={'full'} h={'auto'}>
                <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                  {/*id1*/}
                  <HStack mr={10} backgroundColor={'coolGray.200'} rounded={'2xl'} width={'16%'}>
                    <Image rounded={'lg'} source={require('../../assets/Samantha.png')} alt='Alternate Text' size='32' />
                    <HStack>
                      <VStack mt={3} ml={5}>
                        <Text fontWeight={'bold'} fontSize={'lg'}>
                          Glee Samantha
                        </Text>
                        <Text mt={2} w='56' color={'coolGray.500'} fontSize={'md'}>
                          I came with my mother, I was very nervous. but after entering here I felf warmed ith smiling
                        </Text>
                      </VStack>
                      <VStack ml={5} mt={5}>
                        <Box
                          backgroundColor={'#4EA0F7'}
                          alignItems='center'
                          width={12}
                          height={8}
                          borderRadius='2xl'
                          borderColor={'#4EA0F7'}
                          borderWidth={'2'}>
                          <Text fontWeight={'bold'} fontSize={'md'} color={'#fff'}>
                            4.5
                          </Text>
                        </Box>
                        <HStack>
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star-half-empty'} color={'#f1c40f'} as={FontAwesome} />
                        </HStack>
                      </VStack>
                    </HStack>
                  </HStack>
                  {/*id2*/}
                  <HStack mr={10} backgroundColor={'coolGray.200'} rounded={'2xl'} width={'16%'}>
                    <Image rounded={'lg'} source={require('../../assets/john.png')} alt='Alternate Text' size='32' />
                    <HStack space={'1'}>
                      <VStack mt={3} ml={5}>
                        <Text fontWeight={'bold'} fontSize={'lg'}>
                          John Beckham
                        </Text>
                        <Text mt={2} w='56' color={'coolGray.500'} fontSize={'md'}>
                          I came with my mother, I was very nervous. but after entering here I felf warmed ith smiling
                        </Text>
                      </VStack>
                      <VStack ml={5} mt={5}>
                        <Box
                          backgroundColor={'#4EA0F7'}
                          alignItems='center'
                          width={12}
                          height={8}
                          borderRadius='2xl'
                          borderColor={'#4EA0F7'}
                          borderWidth={'2'}>
                          <Text fontWeight={'bold'} fontSize={'md'} color={'#fff'}>
                            4.5
                          </Text>
                        </Box>
                        <HStack>
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star-half-empty'} color={'#f1c40f'} as={FontAwesome} />
                        </HStack>
                      </VStack>
                    </HStack>
                  </HStack>
                  {/*id3*/}
                  <HStack mr={10} backgroundColor={'coolGray.200'} rounded={'2xl'} width={'16%'}>
                    <Image rounded={'lg'} source={require('../../assets/Rose.png')} alt='Alternate Text' size='32' />
                    <HStack space={'2'}>
                      <VStack mt={3} ml={5}>
                        <Text fontWeight={'bold'} fontSize={'lg'}>
                          Rose William
                        </Text>
                        <Text mt={2} w='56' color={'coolGray.500'} fontSize={'md'}>
                          I came with my mother, I was very nervous. but after entering here I felf warmed ith smiling
                        </Text>
                      </VStack>
                      <VStack ml={5} mt={5}>
                        <Box
                          backgroundColor={'#4EA0F7'}
                          alignItems='center'
                          width={12}
                          height={8}
                          borderRadius='2xl'
                          borderColor={'#4EA0F7'}
                          borderWidth={'2'}>
                          <Text fontWeight={'bold'} fontSize={'md'} color={'#fff'}>
                            4.5
                          </Text>
                        </Box>
                        <HStack>
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star-half-empty'} color={'#f1c40f'} as={FontAwesome} />
                        </HStack>
                      </VStack>
                    </HStack>
                  </HStack>
                  {/*id4*/}
                  <HStack mr={10} backgroundColor={'coolGray.200'} rounded={'2xl'} width={'16%'}>
                    <Image rounded={'lg'} source={require('../../assets/Steven.png')} alt='Alternate Text' size='32' />
                    <HStack space={'3'}>
                      <VStack mt={3} ml={5}>
                        <Text fontWeight={'bold'} fontSize={'lg'}>
                          Steven James
                        </Text>
                        <Text mt={2} w='56' color={'coolGray.500'} fontSize={'md'}>
                          I came with my mother, I was very nervous. but after entering here I felf warmed ith smiling
                        </Text>
                      </VStack>
                      <VStack ml={5} mt={5}>
                        <Box
                          backgroundColor={'#4EA0F7'}
                          alignItems='center'
                          width={12}
                          height={8}
                          borderRadius='2xl'
                          borderColor={'#4EA0F7'}
                          borderWidth={'2'}>
                          <Text fontWeight={'bold'} fontSize={'md'} color={'#fff'}>
                            4.5
                          </Text>
                        </Box>
                        <HStack>
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star-half-empty'} color={'#f1c40f'} as={FontAwesome} />
                        </HStack>
                      </VStack>
                    </HStack>
                  </HStack>
                  {/*id5*/}
                  <HStack mr={10} backgroundColor={'coolGray.200'} rounded={'2xl'} width={'16%'}>
                    <Image rounded={'lg'} source={require('../../assets/women.jpg')} alt='Alternate Text' size='32' />
                    <HStack space={'4'}>
                      <VStack mt={3} ml={5}>
                        <Text fontWeight={'bold'} fontSize={'lg'}>
                          Glee Smiley
                        </Text>
                        <Text mt={2} w='56' color={'coolGray.500'} fontSize={'md'}>
                          I came with my mother, I was very nervous. but after entering here I felf warmed ith smiling
                        </Text>
                      </VStack>
                      <VStack ml={5} mt={5}>
                        <Box
                          backgroundColor={'#4EA0F7'}
                          alignItems='center'
                          width={12}
                          height={8}
                          borderRadius='2xl'
                          borderColor={'#4EA0F7'}
                          borderWidth={'2'}>
                          <Text fontWeight={'bold'} fontSize={'md'} color={'#fff'}>
                            4.5
                          </Text>
                        </Box>
                        <HStack>
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star-half-empty'} color={'#f1c40f'} as={FontAwesome} />
                        </HStack>
                      </VStack>
                    </HStack>
                  </HStack>
                  {/*id6*/}
                  <HStack backgroundColor={'coolGray.200'} rounded={'2xl'} width={'16%'}>
                    <Image rounded={'lg'} source={require('../../assets/Thomas.png')} alt='Alternate Text' size='32' />
                    <HStack space={'5'}>
                      <VStack mt={3} ml={5}>
                        <Text fontWeight={'bold'} fontSize={'lg'}>
                          Tom Thomas
                        </Text>
                        <Text mt={2} w='56' color={'coolGray.500'} fontSize={'md'}>
                          I came with my mother, I was very nervous. but after entering here I felf warmed ith smiling
                        </Text>
                      </VStack>
                      <VStack ml={5} mt={5}>
                        <Box
                          backgroundColor={'#4EA0F7'}
                          alignItems='center'
                          width={12}
                          height={8}
                          borderRadius='2xl'
                          borderColor={'#4EA0F7'}
                          borderWidth={'2'}>
                          <Text fontWeight={'bold'} fontSize={'md'} color={'#fff'}>
                            4.5
                          </Text>
                        </Box>
                        <HStack>
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                          <Icon size='6' name={'star-half-empty'} color={'#f1c40f'} as={FontAwesome} />
                        </HStack>
                      </VStack>
                    </HStack>
                  </HStack>
                </ScrollView>
              </HStack>
            </Hidden>
          </VStack>
        </HStack>
      </VStack>
    </>
  );
}

export default function DoctorDetailsPatient() {
  return (
    <>
      <DashboardLayout title='' displayMenuButton displayScreenTitle={false} displayAlternateMobileHeader rightPanelMobileHeader={true}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Banner />
        </ScrollView>
      </DashboardLayout>
    </>
  );
}
