import { combineReducers } from '@reduxjs/toolkit';
import chatRoomsSlice from './chat/chat-rooms/chat-rooms-slice';
import chatRoomSlice from './chat/chat-room/chat-room-slice';
import chatRoomContactSlice from './chat/chat-room-contact/chat-room-contact-slice';

const chatReducers = combineReducers({
  chatRooms: chatRoomsSlice.reducer,
  chatRoom: chatRoomSlice.reducer,
  chatRoomContact: chatRoomContactSlice.reducer
});

export default chatReducers;
