import { Box, Hidden, Text } from 'native-base';
import React from 'react';

function WebFooter() {
  return (
    <Hidden till='md'>
      <Box textAlign={'center'}>
        <Text py={2} fontSize={'lg'} color={'black'}>
          Copyright © 2022 Softfoundry International Pte. Ltd. All Rights Reserved.
        </Text>
      </Box>
    </Hidden>
  );
}

export default WebFooter;