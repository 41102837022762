import { Department } from '../API';
// import { cloneObject } from './object_clone';
// import { keys } from 'ts-transformer-keys';

export type IDepartment = Omit<
  Department,
  '_version' | 'createdAt' | 'updatedAt' | 'owner' | '_deleted' | '_lastChangedAt' | '__typename' | 'doctors'
>;

export type ICreateDepartmentInput = Omit<IDepartment, 'id'>;

export const cloneDepartment = (department: Department): IDepartment => {
  return {
    id: department.id,
    name: department.name,
    description: department.description
  };
};

// export type OnCreateDepartment = OnCreateDepartmentSubscription['onCreateDepartment'];
//
// export const cloneCreatedDepartment = (department: OnCreateDepartment): IDepartment => {
//   return cloneObject<OnCreateDepartment, IDepartment>(department, keys<OnCreateDepartment>(), keys<IDepartment>());
// }
