import React, { useEffect, useState } from 'react';
import { Box, Button, Center, Divider, HStack, Icon, Image, Pressable, ScrollView, Spinner, Text, VStack } from 'native-base';
import { FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useNavigation, useRoute } from '@react-navigation/native';
import MedicalHistory from './MedicalHistory';
import { gqlGetMedicalRecordByPatientID } from '../../utils/graphql/medical_record_utils';
import { medicalRecordActions, selectAllMedicalRecords } from '../../store/hospital/medical-record/medical-records-slice';
import { calculateAge, formatDate } from '../../utils/time_utils';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { MainStackParamList } from '../../types/Stacks';
import { RootStackScreensEnum } from '../../navigation/enum';
import { IMedicalRecord } from '../../types/IMedicalRecord';
import { useAuth } from '../../components/auth/AmplifyAuthProvider';
import axios from 'axios';
import { IPatient } from '../../types/IPatient';
import { IUser } from '../../types/IUser';
import Role from '../../components/auth/role';
import { graphQLGetPatientByUserID } from '../../utils/graphql/patient_utils';

interface IProp {
  patient: IPatient;
  patientUser: IUser;
}

function Banner({ patient, patientUser }: IProp) {
  return (
    <VStack _light={{ bg: '' }} _dark={{ bg: 'coolGray.900' }} zIndex={2}>
      {/*-end-header*/}
      {/*-start-profile*/}
      <VStack>
        <VStack w={'100%'} py={3} backgroundColor={'white'} rounded={'lg'}>
          <HStack w={'95%'} mx={2} mb={2} alignItems={'center'}>
            <MaterialCommunityIcons name='clipboard-text-outline' size={28} color='#4EA0F7' />
            <Text ml={2} color={'coolGray.900'} fontSize={'20'} fontWeight={'bold'}>
              Patient Info
            </Text>
          </HStack>
          <HStack w={'95%'} mx={2}>
            <Button backgroundColor={'white'}>
              <Image
                source={patientUser?.imageUrl ? { uri: patientUser?.imageUrl } : undefined}
                rounded={'md'}
                size={24}
                accessibilityLabel={patientUser?.lastName.charAt(0).toUpperCase()}
                alt={patientUser?.lastName.charAt(0).toUpperCase()}
              />
            </Button>
            <VStack flex={1} mt={1}>
              <Text fontWeight={'bold'} fontSize={'20'} color={'coolGray.900'}>
                {patientUser?.firstName + ' ' + patientUser?.lastName}
              </Text>
              <Text color={'coolGray.500'} fontSize={'16'}>
                Age: {patientUser?.birthday && calculateAge(patientUser.birthday)}
              </Text>
              <Text color={'coolGray.500'} fontSize={'16'}>
                Check In date {patient?.checkInTime && formatDate(patient.checkInTime)}
              </Text>
            </VStack>
          </HStack>
        </VStack>
        {/*-end-*/}
        {/*start-assigned doctor*/}
        <VStack mt={4} w={'100%'} py={2} backgroundColor={'white'} rounded={'lg'}>
          {/**/}
          <VStack alignItems={'center'} w={'95%'} py={3}>
            <HStack w={'95%'} mx={2} mb={2} alignItems={'center'}>
              <MaterialCommunityIcons name='doctor' size={28} color='#4EA0F7' />
              <Text ml={2} color={'coolGray.800'} fontSize={'20'} fontWeight={'bold'}>
                Assigned Doctor
              </Text>
            </HStack>
            <VStack w={'95%'} mx={2} mt={3}>
              <HStack mx={2}>
                <VStack alignItems={'center'}>
                  <Image rounded={'md'} size={24} source={require('../../assets/Samantha.png')} alt='Alternate Text' />
                  <VStack alignItems={'center'}>
                    <Box
                      mt={2}
                      backgroundColor={'#4EA0F7'}
                      justifyContent={'center'}
                      alignItems='center'
                      width={16}
                      height={6}
                      borderRadius='2xl'
                      borderColor={'#4EA0F7'}>
                      <Text fontWeight={'medium'} fontSize={'16'} color={'#fff'}>
                        4.5
                      </Text>
                    </Box>
                    <HStack mt={2}>
                      <Icon size='5' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                      <Icon size='5' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                      <Icon size='5' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                      <Icon size='5' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                      <Icon size='5' name={'star-half-empty'} color={'#f1c40f'} as={FontAwesome} />
                    </HStack>
                  </VStack>
                </VStack>
                <VStack ml={3} space={2} mb={7}>
                  <Text fontWeight={'medium'} fontSize={'20'} color={'coolGray.500'}>
                    Dr. Samantha
                  </Text>
                  <Text color={'#4EA0F7'} fontSize={'16'}>
                    Physical Therapy
                  </Text>
                  {/*  */}
                </VStack>
              </HStack>
              {/**/}
            </VStack>
            {/*-end-*/}
          </VStack>
        </VStack>
        {/**/}
      </VStack>
      {/*end-profile*/}
    </VStack>
  );
}

const MedicalRecordDetailMobile = () => {
  const route = useRoute();
  const params = route.params as any;
  const dispatch = useAppDispatch();
  const navigation = useNavigation<NativeStackNavigationProp<MainStackParamList, RootStackScreensEnum.Admin>>();
  const { checkRole, user } = useAuth();
  const [patientId, setPatientId] = useState<string | undefined>(undefined);
  const [patient, setPatient] = useState<IPatient>();
  const [patientUser, setPatientUser] = useState<IUser>();

  const getAndDispatchRecordList = async (id: string) => {
    const recordList = (await gqlGetMedicalRecordByPatientID(id)) as IMedicalRecord[];
    if (recordList && recordList.length > 0) {
      dispatch(medicalRecordActions.setMedicalRecords(recordList));
    } else {
      dispatch(medicalRecordActions.setMedicalRecords([]));
    }
  };

  useEffect(() => {
    (async () => {
      if (user && patientId) {
        const res = await axios.get(`/patient/${patientId}`, {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user?.idToken}` }
        });
        if (res.status === 200 && res.data) {
          setPatient(res.data);
          setPatientUser(res.data.user);
        }
      }
    })();
  }, [user, patientId]);

  useEffect(() => {
    (async () => {
      if ((await checkRole()) === Role.Patient && user?.id) {
        const patient = await graphQLGetPatientByUserID(user.id);
        if (patient) {
          setPatientId(patient.id);
          await getAndDispatchRecordList(patient.id);
        }
      }
      if (params?.patientId) {
        setPatientId(params.patientId);
        await getAndDispatchRecordList(params.patientId);
      }
    })();
  }, [params]);

  return (
    <>
      <HStack my={3} ml={3} alignItems='center'>
        <Pressable
          mr={2}
          onPress={() =>
            navigation.navigate(RootStackScreensEnum.Admin, {
              screen: RootStackScreensEnum.MedicalRecordScreen,
              params: { patientId: '' }
            })
          }>
          <Icon as={MaterialCommunityIcons} name='arrow-left' size='xl' color={'coolGray.700'} />
        </Pressable>
        <Text fontSize={'xl'} fontWeight={'bold'} color='coolGray.900'>
          Medical Records
        </Text>
      </HStack>
      <Divider />
      {patientId && patient && patientUser ? (
        <ScrollView>
          <Banner patient={patient} patientUser={patientUser} />
          <MedicalHistory />
        </ScrollView>
      ) : (
        <Center flex={1}>
          <Spinner size={'lg'} />
        </Center>
      )}
    </>
  );
};
export default MedicalRecordDetailMobile;
