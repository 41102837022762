import React from 'react';
import { HStack, Icon, Pressable, Text, VStack } from 'native-base';
import { FontAwesome, Fontisto, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { RootStackScreensEnum } from '../../navigation/enum';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { MainStackParamList } from '../../types/Stacks';

const CardMobile = () => {
  const navigation = useNavigation<NativeStackNavigationProp<MainStackParamList, RootStackScreensEnum.Admin>>();
  return (
    <>
      <HStack space={'4'} justifyContent={'space-around'} my={'5'} mx={'5'} w={'90%'}>
        <VStack alignItems={'center'} w={'20%'}>
          <Pressable shadow={'3'} justifyContent={'center'} alignItems={'center'} size={12} rounded={'md'} backgroundColor={'#ff9900'}>
            <Icon as={<Ionicons name='home-outline' />} size={6} color={'white'} />
          </Pressable>
          <Text mt={1} fontSize={'14'} fontWeight={'medium'} color={'black'}>
            Features
          </Text>
        </VStack>
        {/*//*/}
        <VStack alignItems={'center'} w={'20%'}>
          <Pressable shadow={'3'} justifyContent={'center'} alignItems={'center'} size={12} rounded={'md'} backgroundColor={'#FF7854'}>
            <Icon as={<FontAwesome name='heartbeat' />} size={6} color={'white'} />
          </Pressable>
          <Text mt={'1'} fontSize={'14'} fontWeight={'medium'} color={'black'}>
            Appointment
          </Text>
        </VStack>
        {/*//*/}
        <VStack alignItems={'center'} w={'20%'}>
          <Pressable
            onPress={() => navigation.navigate(RootStackScreensEnum.Admin, { screen: RootStackScreensEnum.DoctorList })}
            shadow={'3'}
            justifyContent={'center'}
            alignItems={'center'}
            size={12}
            rounded={'md'}
            backgroundColor={'#4485FD'}>
            <Icon as={<Fontisto name='stethoscope' />} size={6} color={'white'} />
          </Pressable>
          <Text mt={'1'} fontSize={'14'} fontWeight={'medium'} color={'black'}>
            Doctors
          </Text>
        </VStack>
        {/*//*/}
        <VStack alignItems={'center'} w={'20%'}>
          <Pressable
            onPress={() => navigation.navigate(RootStackScreensEnum.Admin, { screen: RootStackScreensEnum.PatientList })}
            shadow={'3'}
            justifyContent={'center'}
            alignItems={'center'}
            size={12}
            rounded={'md'}
            backgroundColor={'#bb86fc'}>
            <Icon as={<MaterialCommunityIcons name='tooth-outline' />} size={6} color={'white'} />
          </Pressable>
          <Text mt={'1'} fontSize={'14'} fontWeight={'medium'} color={'black'}>
            Patients
          </Text>
        </VStack>
      </HStack>
    </>
  );
};

export default CardMobile;
