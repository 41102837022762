import React from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import { Box, Divider, Hidden, HStack } from 'native-base';
import ChatList from './ChatList';
import ChatScreen from './ChatScreen';
import { selectAllChatRooms } from '../../store/chat/chat-rooms/chat-rooms-slice';
import { useAppSelector } from '../../store/hooks';
import ModalLanguageForm from './ModalLanguageForm';
import AdminMobileFooter from '../AdminScreen/AdminMobileFooter';
import ChatListMobile from './ChatListMobile';

const ChatTotal = () => {
  const chatRooms = useAppSelector(selectAllChatRooms);
  return (
    <>
      <DashboardLayout title='' displayMenuButton displayScreenTitle={false} displayAlternateMobileHeader rightPanelMobileHeader={true}>
        <Hidden till={'md'}>
          {chatRooms && chatRooms.length > 0 ? (
            <HStack alignSelf={'center'} width={'full'} height={'full'}>
              <Box w={['35%', '35%', '25%']} height={'100%'}>
                <ChatList />
              </Box>
              <Divider orientation='vertical' />
              <Box width={['65%', '65%', '75%']} height={'100%'}>
                <ChatScreen />
              </Box>
            </HStack>
          ) : (
            // Floating Button to create a new chat room
            <Box shadow={9} position='absolute' bottom={4} right={8}>
              <ModalLanguageForm />
            </Box>
          )}
        </Hidden>
        <Hidden from={'md'}>
          {chatRooms && chatRooms.length > 0 ? (
            <Box w={'100%'} height={'100%'}>
              <ChatListMobile />
            </Box>
          ) : (
            // Floating Button to create a new chat room
            <Box>
              <ModalLanguageForm />
            </Box>
          )}
        </Hidden>
      </DashboardLayout>
      <AdminMobileFooter />
    </>
  );
};

export default ChatTotal;
