import { API, graphqlOperation } from 'aws-amplify';
import { ChatRoomsByUserQuery, OnCreateChatRoomSubscription, OnDeleteChatRoomSubscription, OnUpdateChatRoomSubscription } from '../../API';
import { GraphQLResult } from '@aws-amplify/api';
import { Unsubscribe } from '@reduxjs/toolkit';
import { chatRoomsByUser } from '../../graphql/queries';
import { AppDispatch } from '../../store';
import { onCreateChatRoom, onDeleteChatRoom, onUpdateChatRoom } from '../../graphql/subscriptions';
import { cloneSubscribeChatRoom, IChatRoom } from '../../types/IChatRoom';
import { addChatRoom, chatRoomsActions, setChatRoomsReady } from '../../store/chat/chat-rooms/chat-rooms-slice';

// Query for all chat rooms belonging to the current user
export const getChatRoomsByUser = async (userID: string) => {
  let retry = 0;

  while (retry < 3) {
    if (retry > 0) {
      console.log('Retrying getChatRoomsByUser...');
    }

    try {
      const allChatRooms = [];
      let nextToken = null;
      do {
        const result = (await API.graphql(
          graphqlOperation(chatRoomsByUser, {
            userID,
            limit: 1000,
            nextToken
          })
        )) as GraphQLResult<ChatRoomsByUserQuery>;
        if (result.data?.chatRoomsByUser?.items) {
          allChatRooms.push(...result.data.chatRoomsByUser.items);
          nextToken = result.data.chatRoomsByUser.nextToken;
        }
      } while (nextToken);
      return allChatRooms.filter((chatRoom) => chatRoom && !chatRoom._deleted);
    } catch (error) {
      console.log(error);
      retry++;
    }
  }

  if (retry >= 3) {
    throw new Error('Failed to get chat rooms by user');
  }
  return null;
};

// A class that subscribes to the onCreateChatRoom, onUpdateChatRoom, and onDeleteChatRoom subscriptions
export class ChatRoomSubscription {
  _onCreateChatRoomSubscription: { unsubscribe: Unsubscribe } | null = null;
  _onUpdateChatRoomSubscription: { unsubscribe: Unsubscribe } | null = null;
  _onDeleteChatRoomSubscription: { unsubscribe: Unsubscribe } | null = null;

  constructor(private _dispatch: AppDispatch, private _userID: string) {}

  // Subscribe to the onCreateChatRoom subscription
  private _subscribeToOnCreateChatRoomSubscription() {
    // Subscribe to the onCreateChatRoom subscription
    this._onCreateChatRoomSubscription = (
      API.graphql(
        graphqlOperation(onCreateChatRoom, {
          userID: this._userID
        })
      ) as any
    ).subscribe({
      next: ({ value }: { value: GraphQLResult<OnCreateChatRoomSubscription> }) => {
        if (value?.data?.onCreateChatRoom) {
          const createdChatRoom = cloneSubscribeChatRoom(value.data.onCreateChatRoom);
          console.log('onCreateChatRoom: ', createdChatRoom);
          this._dispatch(addChatRoom(createdChatRoom));
        }
      },
      error: (error: any) => {
        console.log('onCreateChatRoom subscription error: ', error);
      }
    });
  }

  // Subscribe to the onUpdateChatRoom subscription
  private _subscribeToOnUpdateChatRoomSubscription() {
    this._onUpdateChatRoomSubscription = (
      API.graphql(
        graphqlOperation(onUpdateChatRoom, {
          userID: this._userID
        })
      ) as any
    ).subscribe({
      next: ({ value }: { value: GraphQLResult<OnUpdateChatRoomSubscription> }) => {
        if (value?.data?.onUpdateChatRoom) {
          const updatedChatRoom = cloneSubscribeChatRoom(value.data.onUpdateChatRoom);
          console.log('onUpdateChatRoom: ', updatedChatRoom);
          this._dispatch(
            chatRoomsActions.updateChatRoom({
              id: updatedChatRoom.id,
              changes: {
                ...updatedChatRoom
              }
            })
          );

          // TODO (MinhLuan): do we need to update the active chat room?
        }
      },
      error: (error: any) => {
        console.log('onUpdateChatRoom subscription error: ', error);
      }
    });
  }

  // Subscribe to the onDeleteChatRoom subscription
  private _subscribeToOnDeleteChatRoomSubscription() {
    this._onDeleteChatRoomSubscription = (
      API.graphql(
        graphqlOperation(onDeleteChatRoom, {
          userID: this._userID
        })
      ) as any
    ).subscribe({
      next: ({ value }: { value: GraphQLResult<OnDeleteChatRoomSubscription> }) => {
        if (value?.data?.onDeleteChatRoom) {
          const deletedChatRoom = cloneSubscribeChatRoom(value.data.onDeleteChatRoom);
          console.log('onDeleteChatRoom: ', deletedChatRoom);
          this._dispatch(chatRoomsActions.removeChatRoom(deletedChatRoom.id));

          // TODO (MinhLuan): do we need to change the active chat room?
        }
      },
      error: (error: any) => {
        console.log('onDeleteChatRoom subscription error: ', error);
      }
    });
  }

  // Subscribe to all subscriptions
  async subscribe() {
    this._subscribeToOnCreateChatRoomSubscription();
    this._subscribeToOnUpdateChatRoomSubscription();
    this._subscribeToOnDeleteChatRoomSubscription();

    // Query for all chat rooms belonging to the current user
    const chatRooms = await getChatRoomsByUser(this._userID);
    if (chatRooms && chatRooms.length > 0) {
      const nonNullChatRooms = chatRooms.filter((chatRoom) => chatRoom !== null) as IChatRoom[];
      // In the initial state, there is no need to check for the active chat room. Just add all chat rooms to the store.
      this._dispatch(chatRoomsActions.addChatRooms(nonNullChatRooms));
    } else {
      console.log('ChatRoomSubscription: no chat rooms found');
    }

    // Set the ready state for the chat rooms
    this._dispatch(setChatRoomsReady());

    // TODO (MinhLuan): maybe we need to check again if the data after subscription is the same as the data from the query
    // Maybe there is some delay between the query and the subscription
  }

  // Unsubscribe from all subscriptions
  unsubscribe() {
    if (this._onCreateChatRoomSubscription) {
      this._onCreateChatRoomSubscription.unsubscribe();
    }
    if (this._onUpdateChatRoomSubscription) {
      this._onUpdateChatRoomSubscription.unsubscribe();
    }
    if (this._onDeleteChatRoomSubscription) {
      this._onDeleteChatRoomSubscription.unsubscribe();
    }
  }
}
