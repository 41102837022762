import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { agentByName, getAgent, listAgents } from '../../graphql/queries';
import { AgentByNameQuery, CreateAgentMutation, GetAgentQuery, ListAgentsQuery } from '../../API';
import { createAgent } from '../../graphql/mutations';
import { cloneAgent } from '../IAgent';
import { agentsActions } from '../../store/agents/agents-slice';

export const gqlGetAgentById = async (agentID: string) => {
  try {
    const result = (await API.graphql(
      graphqlOperation(getAgent, {
        id: agentID
      })
    )) as GraphQLResult<GetAgentQuery>;
    return result.data?.getAgent && !result.data.getAgent._deleted ? result.data.getAgent : null;
  } catch (error) {
    console.log(error);
  }

  return null;
};

export const gqlGetAgentByName = async (agentName: string) => {
  try {
    const result = (await API.graphql(
      graphqlOperation(agentByName, {
        agentName
      })
    )) as GraphQLResult<AgentByNameQuery>;
    const agents = result.data?.agentByName?.items;
    if (agents && agents.length > 0) {
      return agents[0] && !agents[0]._deleted ? agents[0] : null;
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};

export const gqlCreateAgent = async (name: string, prompt: string, model: string, language: string, avatar?: string) => {
  try {
    const result = (await API.graphql(
      graphqlOperation(createAgent, {
        input: {
          name,
          prompt,
          model,
          avatar
        }
      })
    )) as GraphQLResult<CreateAgentMutation>;
    return result.data?.createAgent && !result.data.createAgent._deleted ? result.data.createAgent : null;
  } catch (error) {
    console.log(error);
  }

  return null;
};

export const gqlGetAllAgents = async () => {
  try {
    const allAgents = [];
    let nextToken = null;
    do {
      const result = (await API.graphql(
        graphqlOperation(listAgents, {
          limit: 100,
          nextToken
        })
      )) as GraphQLResult<ListAgentsQuery>;
      if (result.data?.listAgents?.items && result.data.listAgents.items.length > 0) {
        allAgents.push(...result.data.listAgents.items);
        nextToken = result.data.listAgents.nextToken;
      }
    } while (nextToken);
    return allAgents.filter((agent) => agent && !agent._deleted);
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const gqlFetchAllAgentsAndDispatch = async (dispatch: any) => {
  const allAgents = (await gqlGetAllAgents()).map((agent) => cloneAgent(agent!));
  dispatch(agentsActions.setAgents(allAgents));
};
