import React from 'react';
import { Button, Divider, Hidden, HStack, Icon, Image, Pressable, Text, VStack } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useAppSelector } from '../../store/hooks';
import { selectDoctorById } from '../../store/hospital/doctors/doctors-slice';
import { selectUserById } from '../../store/hospital/users/users-slice';
import { RootStackScreensEnum } from '../../navigation/enum';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { MainStackParamList } from '../../types/Stacks';

function DoctorTopDetailMobile() {
  const route = useRoute();
  const { doctorId } = route.params as any;
  const doctor = useAppSelector((state) => selectDoctorById(state.hospital.doctors, doctorId));
  const doctorUser = useAppSelector((state) => selectUserById(state.hospital.users, doctor?.doctorUserId || ''));
  const navigation = useNavigation<NativeStackNavigationProp<MainStackParamList, RootStackScreensEnum.Admin>>();

  return (
    <>
      <VStack
        h={'100%'}
        _light={{ bg: '#fff' }}
        _dark={{ bg: { base: 'coolGray.900', md: 'coolGray.800' } }}
        zIndex={2}
        borderRadius={{ md: 10 }}
        pt={{ base: 4, md: 0 }}>
        <HStack w={'100%'} alignItems={'center'}>
          <Hidden from={'md'}>
            <Pressable mb={3} onPress={() => navigation.navigate(RootStackScreensEnum.Admin, { screen: RootStackScreensEnum.Dashboard })} ml={4}>
              <Icon as={Ionicons} name='arrow-back' size='xl' color={'coolGray.700'} />
            </Pressable>
          </Hidden>
        </HStack>
        <VStack backgroundColor={'#fff'} h={'100%'} space='2' w={'100%'}>
          <Hidden from={'md'}>
            <VStack w={'100%'} backgroundColor={'#fff'} rounded={'lg'} space='2' h={'100%'}>
              <Button backgroundColor={'white'} justifyContent={'center'}>
                <Image
                  source={doctorUser?.imageUrl ? { uri: doctorUser?.imageUrl } : undefined}
                  rounded={'md'}
                  size={64}
                  accessibilityLabel={doctorUser?.lastName.charAt(0).toUpperCase()}
                  alt={doctorUser?.lastName.charAt(0).toUpperCase()}
                />
              </Button>
              {/*Profile*/}
              <VStack w={'100%'} backgroundColor={'white'} rounded={'lg'} space='2'>
                <HStack w={'full'}>
                  <VStack mx={'4'} flex={1} space={2}>
                    <Text fontWeight={'bold'} fontSize={'20'} color={'coolGray.900'}>
                      Dr. {doctorUser?.firstName + ' ' + doctorUser?.lastName}
                    </Text>
                    <Text color={'coolGray.500'} fontSize={'16'} fontWeight={'medium'}>
                      Specialist: {doctor?.specialist}
                    </Text>
                    <Text color={'coolGray.500'} fontSize={'14'}>
                      {doctorUser?.firstName + ' ' + doctorUser?.lastName} is one of the best doctors in the Persahabatan Hospital. He has saved 1000
                      patients in the past 3 years. He has also received many awards from domestic and abroad as the best doctors. He is available on
                      a private or schedule.
                    </Text>
                  </VStack>
                </HStack>

                <Divider my={5} />

                <VStack>
                  <HStack alignItems={'center'} justifyContent={'space-around'}>
                    <VStack alignItems={'center'}>
                      <Text color={'coolGray.500'} fontSize={'16'} fontWeight={'medium'}>
                        Experience {''}
                      </Text>
                      <Text fontWeight={'bold'} color={'#4485FD'} fontSize={'16'}>
                        3 years
                      </Text>
                    </VStack>
                    <Divider orientation='vertical' h={'100%'} />
                    <VStack alignItems={'center'}>
                      <Text color={'coolGray.500'} fontSize={'16'} fontWeight={'medium'}>
                        Patient {''}
                      </Text>
                      <Text fontWeight={'bold'} color={'#4485FD'} fontSize={'16'}>
                        1221 ps
                      </Text>
                    </VStack>
                    <Divider orientation='vertical' />
                    <VStack alignItems={'center'}>
                      <Text color={'coolGray.500'} fontSize={'16'} fontWeight={'medium'}>
                        Rating {''}
                      </Text>
                      <Text fontWeight={'bold'} color={'#4485FD'} fontSize={'16'}>
                        5.0
                      </Text>
                    </VStack>
                  </HStack>
                </VStack>

                <HStack mt={5} px={4} alignItems={'center'} justifyContent={'space-between'}>
                  <Pressable
                    shadow={'3'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    h={'12'}
                    w={'25%'}
                    rounded={'lg'}
                    backgroundColor={'#4485FD'}>
                    <Ionicons name='ios-chatbubble-ellipses-outline' size={32} color='white' />
                  </Pressable>
                  <Pressable
                    shadow={'3'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    w={'70%'}
                    h={'12'}
                    rounded={'lg'}
                    backgroundColor={'secondary.500'}>
                    <Text color={'white'} fontSize={'16'} fontWeight={'medium'}>
                      Make Appointment
                    </Text>
                  </Pressable>
                </HStack>
              </VStack>
            </VStack>
          </Hidden>
        </VStack>
      </VStack>
    </>
  );
}

export default DoctorTopDetailMobile;
