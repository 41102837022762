import React from 'react';
import { HStack, Icon, Pressable, Text, VStack } from 'native-base';
import { AntDesign, Entypo, FontAwesome, Fontisto, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { RootStackScreensEnum } from '../../navigation/enum';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { MainStackParamList } from '../../types/Stacks';

const CardMobilePatient = () => {
  const navigation = useNavigation<NativeStackNavigationProp<MainStackParamList, RootStackScreensEnum.Patient>>();
  return (
    <>
      <HStack space={'4'} justifyContent={'space-around'} mx={'5'} w={'90%'}>
        <VStack alignItems={'center'} w={'20%'}>
          <Pressable
            // onPress={() => navigation.navigate(RootStackScreensEnum.Patient, { screen: RootStackScreensEnum.DoctorListPatient })}
            shadow={'3'}
            justifyContent={'center'}
            alignItems={'center'}
            size={12}
            rounded={'md'}
            backgroundColor={'#4485FD'}>
            <Icon as={<Fontisto name='stethoscope' />} size={6} color={'white'} />
          </Pressable>
          <Text mt={'1'} fontSize={'14'} fontWeight={'medium'} color={'black'}>
            Doctors
          </Text>
        </VStack>
        {/*//*/}
        <VStack alignItems={'center'} w={'20%'}>
          <Pressable
            onPress={() => navigation.navigate(RootStackScreensEnum.Patient, { screen: RootStackScreensEnum.AppointmentList })}
            shadow={'3'}
            justifyContent={'center'}
            alignItems={'center'}
            size={12}
            rounded={'md'}
            backgroundColor={'#bb86fc'}>
            <Icon as={<MaterialCommunityIcons name='calendar' />} size={6} color={'white'} />
          </Pressable>
          <Text mt={'1'} fontSize={'14'} fontWeight={'medium'} color={'black'}>
            Appointment
          </Text>
        </VStack>
        {/*//*/}
        <VStack alignItems={'center'} w={'20%'}>
          <Pressable
            onPress={() => navigation.navigate(RootStackScreensEnum.Patient, { screen: RootStackScreensEnum.ChatTotalMobile })}
            shadow={'3'}
            justifyContent={'center'}
            alignItems={'center'}
            size={12}
            rounded={'md'}
            backgroundColor={'secondary.500'}>
            <Icon as={<AntDesign name='customerservice' />} size={6} color={'white'} />
          </Pressable>
          <Text mt={'1'} fontSize={'14'} fontWeight={'medium'} color={'black'}>
            Support
          </Text>
        </VStack>
        {/*//*/}
        <VStack alignItems={'center'} w={'20%'}>
          <Pressable
            onPress={() => navigation.navigate(RootStackScreensEnum.Patient, { screen: RootStackScreensEnum.QAndAScreen })}
            shadow={'3'}
            justifyContent={'center'}
            alignItems={'center'}
            size={12}
            rounded={'md'}
            backgroundColor={'#ff9900'}>
            <Icon as={<Entypo name='help-with-circle' />} size={6} color={'white'} />
          </Pressable>
          <Text mt={1} fontSize={'14'} fontWeight={'medium'} color={'black'}>
            Q & A
          </Text>
        </VStack>
      </HStack>
    </>
  );
};

export default CardMobilePatient;
