import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, Update } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { IChatRoom } from '../../../types/IChatRoom';
import { chatRoomActions } from '../chat-room/chat-room-slice';

// Normalize the data
const entityAdapter = createEntityAdapter<IChatRoom>({
  selectId: (chatRoom: IChatRoom) => chatRoom.id,
  sortComparer: (a, b) => {
    // We prioritize chat rooms that have undefined lastMessageTimestamp
    if (!a.lastMessageTimestamp && b.lastMessageTimestamp) {
      return -1;
    } else if (a.lastMessageTimestamp && !b.lastMessageTimestamp) {
      return 1;
    } else if (!a.lastMessageTimestamp && !b.lastMessageTimestamp) {
      return 0;
    }

    // Sort the chat rooms by the last message timestamp, with the most recent chat room on top
    const aLastMessageTimestamp = a.lastMessageTimestamp ? new Date(a.lastMessageTimestamp).getTime() : 0;
    const bLastMessageTimestamp = b.lastMessageTimestamp ? new Date(b.lastMessageTimestamp).getTime() : 0;
    return bLastMessageTimestamp - aLastMessageTimestamp;
  }
});

// Set active chat room
export const setActiveChatRoom = createAsyncThunk('chat/chatRooms/setActiveChatRoom', async (chatRoomID: string, { dispatch, getState }) => {
  const state = getState() as RootState;
  // Find the chat room with the given id, and set it as active
  const chatRoom = entityAdapter.getSelectors().selectById(state.chat.chatRooms, chatRoomID);

  if (chatRoom) {
    console.log('Chat room found', chatRoom);
    dispatch(chatRoomActions.setChatRoom(chatRoom));
    return chatRoomID;
  } else {
    console.error(`Chat room with id ${chatRoomID} not found. This should not happen.`);
  }

  return undefined;
});

export const addChatRoom = createAsyncThunk('chat/chatRooms/addChatRoom', async (chatRoom: IChatRoom, { dispatch, getState }) => {
  const state = getState() as RootState;
  // Only add the chat room if it does not already exist
  await dispatch(chatRoomsActions.addChatRoom(chatRoom));

  // Set the chat room as active if needed
  if (state.chat.chatRooms.activeChatRoomID === chatRoom.id) {
    await dispatch(setActiveChatRoom(chatRoom.id));
  }
});

export const setChatRoomsReady = createAsyncThunk<void, void>('chat/chatRooms/setChatRoomsReady', async (_, { dispatch, getState }) => {
  const state = getState() as RootState;

  // Find the first chat room, and set it as active
  const firstChatRoom = entityAdapter.getSelectors().selectAll(state.chat.chatRooms)[0];
  if (firstChatRoom) {
    await dispatch(setActiveChatRoom(firstChatRoom.id));
  }

  // Set the ready state for the chat rooms
  await dispatch(chatRoomsActions.setReady());
});

// Initial state
const initialState = entityAdapter.getInitialState<{
  activeChatRoomID: string | undefined;
  ready: boolean;
}>({
  activeChatRoomID: undefined,
  // NOTE: This is used to indicate that the chat rooms have been loaded from the server
  // We need this to be true, before we can connect to the chat server
  ready: false
});

export const chatRoomsSlice = createSlice({
  name: 'chat/chatRooms',
  initialState: initialState,
  reducers: {
    addChatRoom: (state, action: PayloadAction<IChatRoom>) => {
      // Only add the chat room if it does not already exist
      if (!state.entities[action.payload.id]) {
        entityAdapter.addOne(state, action.payload);
      }
    },
    updateChatRoom: (state, action: PayloadAction<Update<IChatRoom>>) => {
      entityAdapter.updateOne(state, action.payload);
    },
    addChatRooms: (state, action: PayloadAction<IChatRoom[]>) => {
      entityAdapter.addMany(state, action.payload);
    },
    removeChatRoom: (state, action: PayloadAction<string>) => {
      entityAdapter.removeOne(state, action.payload);
    },
    resetChatRooms: () => {
      return initialState;
    },
    setReady: (state) => {
      state.ready = true;
    },
    hideChatRoom: (state, action: PayloadAction<string>) => {
      const chatRoom = entityAdapter.getSelectors().selectById(state, action.payload);
      if (chatRoom) {
        chatRoom.hidden = true;
        entityAdapter.updateOne(state, { id: chatRoom.id, changes: chatRoom });
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveChatRoom.fulfilled, (state, action) => {
      state.activeChatRoomID = action.payload;
    });
  }
});

// Ready selector
export const isChatRoomsReady = (state: RootState) => state.chat.chatRooms.ready;

export const { selectAll: selectAllChatRooms } = entityAdapter.getSelectors((state: RootState) => state.chat.chatRooms);

// Export removeChatRoom action
export const removeChatRoom = chatRoomsSlice.actions.removeChatRoom;

// Find the next chat room (used for activating the next chat room when deleting a chat room)
export const findNextChatRoom = (state: RootState, chatRoomID: string | undefined) => {
  if (!chatRoomID) {
    return undefined;
  }

  const chatRooms = selectAllChatRooms(state);
  const chatRoomIndex = chatRooms.findIndex((chatRoom) => chatRoom.id === chatRoomID);
  if (chatRoomIndex === -1) {
    return undefined;
  }

  // If the chat room is the last chat room, return the previous chat room
  if (chatRoomIndex === chatRooms.length - 1) {
    return chatRooms[chatRoomIndex - 1];
  }

  // Return the next chat room
  return chatRooms[chatRoomIndex + 1];
};

export default chatRoomsSlice;
export const chatRoomsActions = chatRoomsSlice.actions;
