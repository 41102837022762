import { NavigationProp, useNavigation, useRoute } from '@react-navigation/native';
import { RootStackParamList } from '../../navigation/types';
import { RootStackScreensEnum } from '../../navigation/enum';
import { Button, HStack, Icon } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import React from 'react';

type Icon = {
  iconName: string;
  iconText: string;
  route: RootStackScreensEnum;
};
export type Account = {
  iconName: string;
  iconText: string;
};

const list: Icon[] = [
  { iconName: 'key-outline', iconText: 'Change Password', route: RootStackScreensEnum.ChangePassword },
  { iconName: 'md-person-outline', iconText: 'Profile', route: RootStackScreensEnum.Profile },
  { iconName: 'earth-outline', iconText: 'Language', route: RootStackScreensEnum.Language },
  { iconName: 'ios-notifications-outline', iconText: 'Notifications', route: RootStackScreensEnum.Notifications }
  // { iconName: 'lock-closed-outline', iconText: 'Disable Account', route: RootStackScreensEnum.DisableAccount }
];
const MobileFooter = () => {
  const route = useRoute();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const onPressCategory = (category: Account) => {
    switch (category.iconText) {
      case 'Change Password':
        navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.ChangePassword });
        break;
      case 'Profile':
        navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.Profile });
        break;
      case 'Language':
        navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.Language });
        break;
      case 'Notifications':
        navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.Notifications });
        break;
      // case 'Disable Account':
      //   navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.DisableAccount });
      //   break;
      default:
        navigation.navigate(RootStackScreensEnum.Main, { screen: RootStackScreensEnum.Profile });
        break;
    }
  };
  return (
    <HStack
      justifyContent='space-between'
      safeAreaBottom
      h='12'
      width='100%'
      position='absolute'
      left='0'
      right='0'
      bottom='0'
      overflow='hidden'
      alignSelf='center'
      borderTopLeftRadius='20'
      borderTopRightRadius='20'
      _light={{ backgroundColor: 'coolGray.50' }}
      _dark={{ backgroundColor: 'coolGray.800' }}>
      {list.map((item, index) => {
        return (
          <Button
            p={2}
            w={'25%'}
            onPress={() => onPressCategory(item)}
            key={index}
            variant='ghost'
            colorScheme='coolGray'
            _stack={{
              flexDirection: 'column'
            }}
            backgroundColor={item.route === route.name ? 'primary.100' : 'transparent'}
            startIcon={
              <Icon
                as={Ionicons}
                name={item.iconName}
                size='5'
                _light={{
                  color: item.route === route.name ? '#0564C8' : '#2D2D2D'
                }}
                _dark={{
                  color: index === 0 ? 'primary.500' : 'coolGray.400'
                }}
              />
            }
            _text={{
              fontSize: '2xs',
              _light: {
                color: item.route === route.name ? '#0564C8' : '#2D2D2D'
              },
              _dark: {
                color: index === 0 ? 'primary.500' : 'coolGray.400'
              }
            }}>
            {item.iconText}
          </Button>
        );
      })}
    </HStack>
  );
};
export default MobileFooter;
