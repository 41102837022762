import React, { useEffect } from 'react';
import { Box, FlatList, Hidden, HStack, Image, Pressable, ScrollView, Text, VStack } from 'native-base';
import { RootStackScreensEnum } from '../../navigation/enum';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { AdminStackParamList } from '../../types/Stacks';
import { useAppSelector } from '../../store/hooks';
import { useDebounce } from '../../utils/debounce';
import { selectAllUsers } from '../../store/hospital/users/users-slice';
import { selectAllDoctors } from '../../store/hospital/doctors/doctors-slice';
import { IDoctor } from '../../types/IDoctor';
import { IUser } from '../../types/IUser';

interface IMyComponentProps {
  data: IDoctor[];
}

const MyComponent = ({ data }: IMyComponentProps) => {
  const navigation = useNavigation<NativeStackNavigationProp<AdminStackParamList, RootStackScreensEnum.DoctorList>>();
  const users = useAppSelector((state) => selectAllUsers(state.hospital.users));
  const firstFiveDoctors = data.slice(0, 5);
  return (
    <>
      {/*Show on Mobile*/}
      <Hidden from={'md'}>
        <Box backgroundColor={'#fff'} width={'100%'} rounded={'sm'} py={4}>
          <FlatList
            showsHorizontalScrollIndicator={false}
            horizontal={true}
            w={'95%'}
            data={firstFiveDoctors}
            keyExtractor={(item: IDoctor) => item.id.toString()}
            m={'auto'}
            renderItem={({ item }) => {
              const doctorUser: IUser | undefined = users.find((user: IUser) => user.id === item.doctorUserId);
              return (
                <Pressable
                  shadow={5}
                  borderWidth={1}
                  borderColor={'coolGray.200'}
                  rounded={'xl'}
                  flex={1}
                  mx={3}
                  mb={1}
                  p={3}
                  key={item.id.toString()}
                  onPress={() => navigation.navigate(RootStackScreensEnum.DoctorTopDetailMobile, { doctorId: item.id })}>
                  <HStack alignItems='Center'>
                    <Box backgroundColor={'#fff'}>
                      <Image
                        source={doctorUser?.imageUrl ? { uri: doctorUser?.imageUrl } : undefined}
                        rounded={'md'}
                        size={20}
                        accessibilityLabel={doctorUser?.lastName.charAt(0).toUpperCase()}
                        alt={doctorUser?.lastName.charAt(0).toUpperCase()}
                      />
                    </Box>
                    <VStack
                      ml={4}
                      space={2}
                      alignItems='start'
                      _light={{ borderBottomColor: 'coolGray.200' }}
                      _dark={{ borderBottomColor: 'coolGray.700' }}>
                      <Text fontWeight='bold' fontSize='16' lineHeight='21' _light={{ color: 'coolGray.900' }} _dark={{ color: 'coolGray.400' }}>
                        Dr. {doctorUser?.firstName + ' ' + doctorUser?.lastName}
                      </Text>
                      <HStack>
                        <Text fontWeight='medium' fontSize='14' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }}>
                          Specialist:{' '}
                        </Text>
                        <Text fontSize='14' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }}>
                          {item?.specialist}
                        </Text>
                      </HStack>
                      <HStack>
                        <Text fontWeight='medium' fontSize='14' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }}>
                          Email:{' '}
                        </Text>
                        <Text fontSize='14' lineHeight='21' _light={{ color: 'coolGray.500' }} _dark={{ color: 'coolGray.400' }}>
                          {doctorUser && doctorUser.email && doctorUser.email.length > 20 ? `${doctorUser.email.slice(0, 20)}...` : doctorUser?.email}
                        </Text>
                      </HStack>
                    </VStack>
                  </HStack>
                </Pressable>
              );
            }}
          />
        </Box>
      </Hidden>
    </>
  );
};
const DoctorTopMobile = () => {
  const doctors = useAppSelector((state) => selectAllDoctors(state.hospital.doctors));
  const users = useAppSelector((state) => selectAllUsers(state.hospital.users));
  const [searchText, setSearchText] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<IDoctor[]>([]);
  const textSearch = useDebounce(searchText, 1000);

  useEffect(() => {
    const searchValue = searchText.toLowerCase();
    const arr = doctors.filter((item) => {
      // Find the user by firstName + lastName
      const foundNameUsers = users.filter((user: IUser) => {
        const fullName = user.firstName + ' ' + user.lastName;
        return fullName.toLowerCase().includes(searchValue);
      });

      // Find the user by email
      const foundEmailUsers = users.filter((user: IUser) => {
        return user.email.toLowerCase().includes(searchValue);
      });

      // Return the list of patients that has patientUserId in the list of found users
      return foundNameUsers.some((user) => user.id === item.doctorUserId) || foundEmailUsers.some((user) => user.id === item.doctorUserId);
    });
    setSearchResults(arr);
  }, [textSearch]);
  return (
    <>
      <HStack backgroundColor={'#fff'} justifyContent={'space-between'} alignItems={'center'} px={4} py={2}>
        <Text fontSize={'20'} fontWeight={'bold'}>
          Top Doctors
        </Text>
        <Text fontSize={'14'} color={'#4485FD'}>
          View All
        </Text>
      </HStack>
      <ScrollView flex={1} showsHorizontalScrollIndicator={false}>
        <MyComponent data={searchResults || []} />
      </ScrollView>
    </>
  );
};
export default DoctorTopMobile;
