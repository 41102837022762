import React, { useEffect, useState } from 'react';
import { Box, HStack, Icon, Pressable, Text, VStack } from 'native-base';
import { Fontisto, MaterialIcons } from '@expo/vector-icons';
import { FlatList } from 'react-native';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { medicalRecordActions, selectAllMedicalRecords } from '../../store/hospital/medical-record/medical-records-slice';
import { formatDate } from '../../utils/time_utils';
import { MedicalRecordStatus } from '../../API';
import { gqlRemoveMedicalRecord } from '../../utils/graphql/medical_record_utils';

const MedicalList = () => {
  const records = useAppSelector((state) => selectAllMedicalRecords(state.hospital.medicalRecords));
  const selectedMedicalRecordId = useAppSelector((state) => state.hospital.medicalRecords.selectedMedicalRecordId);
  const isFhirResource = useAppSelector((state) => state.hospital.medicalRecords.isFhirResource);
  const [recordsFhir, setRecordsFhir] = useState<any>([...records]);
  const [firstItemId, setFirstItemId] = useState('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (records && records.length > 0 && records[0].id) {
      dispatch(medicalRecordActions.setSelectedMedicalRecord(records[0].id));
    }
    setRecordsFhir(records);
  }, [records]);

  const handlePressItem = (id: string) => {
    dispatch(medicalRecordActions.setSelectedMedicalRecord(id));
  };

  const deleteMedicalRecord = async (id: string) => {
    const ret = await gqlRemoveMedicalRecord(id);
    if (ret) {
      dispatch(medicalRecordActions.deleteMedicalRecord(ret));
    }
  };

  const getDiagnosis = (item: any) => {
    if (isFhirResource) {
      return '[EXTERNAL DATA] ';
    } else if (item.diagnosis) {
      return item.diagnosis;
    } else {
      return '[IN PROGRESS] ';
    }
  };

  return (
    <VStack w={{ base: '100%', md: '100%' }} h={'100%'} backgroundColor={'white'} mb={{ base: '12', md: '0' }} rounded={'lg'} space='2' pb={4}>
      <Text fontWeight={'bold'} fontSize={'lg'} color={'black'} pl={5} py={5}>
        Medical Records
      </Text>
      {recordsFhir.length > 0 ? (
        <FlatList
          showsVerticalScrollIndicator={false}
          data={recordsFhir}
          renderItem={({ item }) => (
            <Pressable
              rounded={'lg'}
              _hover={{ backgroundColor: 'blue.100' }}
              p={2}
              mx={2}
              backgroundColor={selectedMedicalRecordId === item.id ? 'blue.100' : firstItemId === item.id ? 'blue.100' : 'white'}
              onPress={() => {
                handlePressItem(item.id);
                if (firstItemId === item.id) return;
                setFirstItemId(item.id);
              }}>
              <HStack alignItems={'center'} justifyContent={'space-between'}>
                <HStack space={3}>
                  <Box backgroundColor={'#4EA0F7'} width={12} height={12} rounded={'full'} justifyContent={'center'} alignSelf={'center'}>
                    <Icon size='8' alignSelf={'center'} name={'bed-patient'} color={'white'} as={Fontisto} />
                  </Box>
                  <VStack>
                    <Text color={'black'} fontSize={'lg'}>
                      {getDiagnosis(item)}
                    </Text>
                    <Text color={'coolGray.600'} fontSize={'md'}>
                      {!isFhirResource ? formatDate(item.timestamp) : formatDate(item.period?.start)}
                    </Text>
                  </VStack>
                </HStack>
                {item.status !== MedicalRecordStatus.COMPLETED && !isFhirResource && (
                  <Pressable
                    zIndex={10}
                    width={8}
                    height={8}
                    justifyContent={'center'}
                    alignItems={'center'}
                    onPress={() => deleteMedicalRecord(item.id)}>
                    <Icon size='6' name={'delete'} _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }} as={MaterialIcons} />
                  </Pressable>
                )}
              </HStack>
            </Pressable>
          )}
          keyExtractor={(item) => item.id}
          ItemSeparatorComponent={() => <Box h={2} />}
        />
      ) : (
        <Text color={'black'} fontSize={'lg'} alignSelf={'center'}>
          No medical records
        </Text>
      )}
    </VStack>
  );
};

export default MedicalList;
