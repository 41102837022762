import React from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { MainStackParamList } from '../../types/Stacks';
import { RootStackScreensEnum } from '../../navigation/enum';
import { Button, Hidden, HStack, Icon } from 'native-base';
import { Ionicons } from '@expo/vector-icons';

type Icon = {
  name: string;
  text: string;
  route: RootStackScreensEnum;
};

const footerIcons: Icon[] = [
  { name: 'home-outline', text: 'Home', route: RootStackScreensEnum.Dashboard },
  { name: 'call-outline', text: 'Contacts', route: RootStackScreensEnum.Contacts },
  { name: 'md-chatbox-outline', text: 'Chat', route: RootStackScreensEnum.ChatTotal },
  { name: 'medical-outline', text: 'Records', route: RootStackScreensEnum.MedicalRecordScreen }
];

function AdminMobileFooter() {
  const route = useRoute();
  const navigation = useNavigation<NativeStackNavigationProp<MainStackParamList, RootStackScreensEnum.Admin>>();
  const onPressCategory = (category: Icon) => {
    console.log(category);
    switch (category.text) {
      case 'Contacts':
        navigation.navigate(RootStackScreensEnum.Admin, { screen: RootStackScreensEnum.Contacts, params: { contactId: '' } });
        break;
      case 'Chat':
        navigation.navigate(RootStackScreensEnum.Admin, { screen: RootStackScreensEnum.ChatTotal });
        break;
      case 'Records':
        navigation.navigate(RootStackScreensEnum.Admin, {
          screen: RootStackScreensEnum.MedicalRecordScreen,
          params: { patientId: '' }
        });
        break;
      default:
        navigation.navigate(RootStackScreensEnum.Admin, { screen: RootStackScreensEnum.Dashboard });
        break;
    }
  };
  return (
    <Hidden from='md'>
      <HStack
        justifyContent='space-between'
        safeAreaBottom
        h='12'
        width='100%'
        position='absolute'
        left='0'
        right='0'
        bottom='0'
        overflow='hidden'
        alignSelf='center'
        borderTopLeftRadius='20'
        borderTopRightRadius='20'
        _light={{ backgroundColor: 'coolGray.50' }}
        _dark={{ backgroundColor: 'coolGray.800' }}>
        {footerIcons.map((item, index) => {
          return (
            <Button
              w={'25%'}
              onPress={() => onPressCategory(item)}
              key={index}
              variant='ghost'
              colorScheme='coolGray'
              _stack={{
                flexDirection: 'column'
              }}
              backgroundColor={item.route === route.name ? 'primary.100' : 'transparent'}
              startIcon={
                <Icon
                  as={Ionicons}
                  name={item.name}
                  size='6'
                  _light={{
                    color: item.route === route.name ? '#0564C8' : '#2D2D2D'
                  }}
                  _dark={{
                    color: index === 0 ? 'primary.500' : 'coolGray.400'
                  }}
                />
              }
              _text={{
                fontSize: 'xs',
                _light: {
                  color: item.route === route.name ? '#0564C8' : '#2D2D2D'
                },
                _dark: {
                  color: index === 0 ? 'primary.500' : 'coolGray.400'
                }
              }}>
              {item.text}
            </Button>
          );
        })}
      </HStack>
    </Hidden>
  );
}

export default AdminMobileFooter;
