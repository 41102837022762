import React, { useState } from 'react';
import { Box, Button, Center, FormControl, HStack, Icon, Image, Input, Modal, Pressable, Select, Text, Tooltip, VStack } from 'native-base';
import DashboardLayoutPatient from '../../layouts/DashboardLayoutPatient';
import { Feather, FontAwesome, Fontisto, Foundation, Ionicons, MaterialIcons } from '@expo/vector-icons';
import ChatBoxAI from '../ChatBoxAI';

const PatientProfile = () => {
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [gender, setGender] = useState('');

  const [isModalDelete, setIsModalDelete] = useState(false);
  const toggleModal = () => {
    setIsModalDelete(!isModalDelete);
  };
  const deleteAccount = () => {
    // Hàm xử lý việc xóa tài khoản
    // ...
    toggleModal();
  };
  return (
    <>
      <Box shadow={9} position='absolute' bottom={4} right={4} zIndex={1}>
        <ChatBoxAI />
      </Box>
      <DashboardLayoutPatient
        title='Dashboard'
        displayMenuButton
        displayScreenTitle={false}
        displayAlternateMobileHeader
        rightPanelMobileHeader={true}
        rightPanelHasLocation={true}>
        <VStack
          _light={{ bg: '' }}
          _dark={{ bg: { base: 'coolGray.900', md: 'coolGray.800' } }}
          zIndex={2}
          borderRadius={{ md: 10 }}
          px={{ base: 4, md: 4 }}
          pt={{ base: 4, md: 0 }}
          mb={{ md: 20 }}>
          {/*-start-header*/}
          <HStack pb={15} style={{ alignItems: 'center' }} justifyContent='space-between'>
            <VStack space='2' w={{ base: '55%', md: '100%' }}>
              <Text mt={{ base: 4, md: 4 }} mb={{ base: 4, md: 6 }} fontSize={{ base: 'xl', md: 'xl' }} color='#4EA0F7'>
                Settings / Patient
              </Text>
              <HStack justifyContent={'space-between'}>
                <HStack width={'60%'}>
                  <Box backgroundColor={'#ff9900'} w={'30%'} height={1}>
                    <Text mt={3} fontSize={'md'} fontWeight={'bold'} color={'#ff9900'}>
                      Pending
                    </Text>
                    <Text mt={3} fontSize={'md'} color={'coolGray.700'}>
                      21/08/2020, 12:45 AM
                    </Text>
                  </Box>
                  <Box backgroundColor={'#5e72e4'} w={'30%'} height={1}>
                    <Text mt={3} fontSize={'md'} fontWeight={'bold'} color={'#5e72e4'}>
                      On Recovery
                    </Text>
                    <Text mt={3} fontSize={'md'} color={'coolGray.700'}>
                      21/08/2020, 12:45 AM
                    </Text>
                  </Box>
                  <Box backgroundColor={'#4EA0F7'} w={'30%'} height={1}>
                    <Text mt={3} fontSize={'md'} fontWeight={'bold'} color={'#4EA0F7'}>
                      Recovered
                    </Text>
                    <Text mt={3} fontSize={'md'} color={'coolGray.700'}>
                      21/08/2020, 12:45 AM
                    </Text>
                  </Box>
                </HStack>
                <HStack>
                  <HStack justifyContent={'space-between'} mb='15'>
                    <Tooltip label={'Edit Info'}>
                      <Pressable
                        onPress={() => setIsModalEdit(true)}
                        shadow={7}
                        position='absolute'
                        right={40}
                        rounded='full'
                        _hover={{ bg: '#4EA0F7' }}
                        _light={{ bg: '#0564C8' }}
                        _dark={{ bg: 'primary.500' }}
                        p={3}
                        w={24}
                        h={10}
                        style={{ alignItems: 'center' }}
                        justifyContent='center'>
                        <HStack>
                          <Icon size='5' name='edit' color='#fff' as={MaterialIcons} />
                          <Text pl={2} fontSize={'md'} color='#fff'>
                            Edit
                          </Text>
                        </HStack>
                      </Pressable>
                    </Tooltip>
                    <Modal isOpen={isModalEdit} onClose={() => setIsModalEdit(false)}>
                      <Modal.Content>
                        <Modal.Header>Edit Info</Modal.Header>
                        <Tooltip label='Close'>
                          <Pressable
                            px={4}
                            right={2}
                            top={2}
                            position={'absolute'}
                            style={{ alignItems: 'center' }}
                            justifyContent='center'
                            size={'8'}
                            rounded='full'
                            _hover={{ bg: '#E4EBE4' }}>
                            <Icon onPress={() => setIsModalEdit(false)} as={<MaterialIcons name='close' />} size={6} color='coolGray.900' />
                          </Pressable>
                        </Tooltip>
                        <Modal.Body>
                          <FormControl>
                            <FormControl.Label>Name</FormControl.Label>
                            <Input placeholder='Enter your name' />
                          </FormControl>
                          <FormControl>
                            <FormControl.Label>Gender</FormControl.Label>
                            <Select selectedValue={gender} onValueChange={(value) => setGender(value)} placeholder='Select gender'>
                              <Select.Item label='Male' value='male' />
                              <Select.Item label='Female' value='female' />
                              <Select.Item label='Other' value='other' />
                            </Select>
                          </FormControl>
                          <FormControl>
                            <FormControl.Label>Phone number</FormControl.Label>
                            <Input placeholder='Enter your phone number' />
                          </FormControl>
                          <FormControl>
                            <FormControl.Label>Email</FormControl.Label>
                            <Input placeholder='Enter your email' />
                          </FormControl>
                          <FormControl>
                            <FormControl.Label>Address</FormControl.Label>
                            <Input placeholder='Enter your address' />
                          </FormControl>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button.Group variant='ghost'>
                            <Button onPress={() => setIsModalEdit(false)}>Save</Button>
                          </Button.Group>
                        </Modal.Footer>
                      </Modal.Content>
                    </Modal>
                    <Tooltip label={'Delete'}>
                      <Pressable
                        onPress={toggleModal}
                        shadow={7}
                        position='absolute'
                        right={8}
                        // zIndex={1}
                        rounded='full'
                        _hover={{ bg: 'red.300' }}
                        _light={{ bg: 'red.700' }}
                        _dark={{ bg: 'primary.500' }}
                        p={3}
                        w={24}
                        h={10}
                        style={{ alignItems: 'center' }}
                        justifyContent='center'>
                        <HStack>
                          <Icon size='5' name='delete' color='#fff' as={MaterialIcons} />
                          <Text pl={2} fontSize={'md'} color='#fff'>
                            Delete
                          </Text>
                        </HStack>
                      </Pressable>
                    </Tooltip>
                    <Modal isOpen={isModalDelete} onClose={toggleModal}>
                      <Modal.Content>
                        <Modal.Header>Confirm Delete</Modal.Header>
                        <Tooltip label='Close'>
                          <Pressable
                            px={4}
                            right={2}
                            top={2}
                            position={'absolute'}
                            style={{ alignItems: 'center' }}
                            justifyContent='center'
                            size={'8'}
                            rounded='full'
                            _hover={{ bg: '#E4EBE4' }}>
                            <Icon onPress={toggleModal} as={<MaterialIcons name='close' />} size={6} color='coolGray.900' />
                          </Pressable>
                        </Tooltip>
                        <Modal.Body>
                          <Center>
                            <Text>Are you sure to delete this account?</Text>
                          </Center>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button.Group variant='ghost'>
                            <Button onPress={deleteAccount}>Delete</Button>
                          </Button.Group>
                        </Modal.Footer>
                      </Modal.Content>
                    </Modal>
                  </HStack>
                </HStack>
              </HStack>
            </VStack>
          </HStack>
          {/*-end-header*/}
          {/*-start-profile*/}
          <HStack mt={5}>
            <VStack w={{ base: '65%%', md: '65%' }} backgroundColor={'white'} rounded={'lg'} space='2' h={{ base: 0, md: '450' }}>
              <HStack px={10} py={5}>
                <Button backgroundColor={'white'}>
                  <Image
                    source={{
                      uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.5295602336621994.jpg'
                    }}
                    alt='Alternate Text'
                    size='32'
                    rounded={'lg'}
                  />
                </Button>

                <VStack mt={10} pl={5}>
                  <Text fontWeight={'bold'} fontSize={'lg'}>
                    {/*{patientUser?.firstName + ' ' + patientUser?.lastName}*/}
                    John Doe
                  </Text>
                  <Text color={'coolGray.500'} fontSize={'lg'}>
                    Check In date 21 August 2020, 12:45 AM
                    {/*{patient?.checkInTime}*/}
                  </Text>
                  <HStack mt={4}>
                    <Pressable>
                      <HStack
                        justifyContent={'center'}
                        style={{ alignItems: 'center' }}
                        w={32}
                        h={12}
                        rounded={'full'}
                        backgroundColor={'coolGray.200'}>
                        <Icon size='10' name={'male-symbol'} color={'#4EA0F7'} as={Foundation} />
                        <Text fontWeight={'bold'} fontSize={'lg'}>
                          Male
                        </Text>
                      </HStack>
                    </Pressable>
                    <Pressable>
                      <HStack
                        ml={3}
                        justifyContent={'center'}
                        style={{ alignItems: 'center' }}
                        w={40}
                        h={12}
                        rounded={'full'}
                        backgroundColor={'coolGray.200'}>
                        <Icon size='8' name={'bed-patient'} color={'#4EA0F7'} as={Fontisto} />
                        <Text ml={3} fontWeight={'bold'} fontSize={'lg'}>
                          Diabetes
                        </Text>
                      </HStack>
                    </Pressable>
                  </HStack>
                </VStack>
              </HStack>

              <Box alignSelf={'center'} backgroundColor={'coolGray.200'} w={'90%'} height={0.5}></Box>

              <HStack px={10} py={5} space={1}>
                <VStack justifyContent={'space-between'}>
                  <HStack>
                    <Box
                      justifyContent={'center'}
                      style={{ alignItems: 'center' }}
                      width={12}
                      height={12}
                      borderRadius='full'
                      borderColor={'#4EA0F7'}
                      borderWidth={'2'}>
                      <Icon size='6' name={'location-outline'} color={'#4EA0F7'} as={Ionicons} />
                    </Box>
                    <VStack pl={3}>
                      <Text color={'coolGray.300'} fontSize={'xl'}>
                        Address
                      </Text>
                      <Text width={50} color={'coolGray.500'} fontSize={'lg'}>
                        795 Folsom Ave, Suite 600 San Francisco, CADGE 94107
                      </Text>
                    </VStack>
                  </HStack>
                  <HStack mt={5}>
                    <Box
                      justifyContent={'center'}
                      style={{ alignItems: 'center' }}
                      width={12}
                      height={12}
                      borderRadius='full'
                      borderColor={'#4EA0F7'}
                      borderWidth={'2'}>
                      <Icon size='6' name={'phone'} color={'#4EA0F7'} as={Feather} />
                    </Box>
                    <VStack mb={1} pl={3}>
                      <Text color={'coolGray.300'} fontSize={'xl'}>
                        Phone
                      </Text>
                      <Text width={50} color={'coolGray.500'} fontSize={'lg'}>
                        {/*{patientUser?.phoneNumber}*/}
                        0931 723 755
                      </Text>
                    </VStack>
                  </HStack>
                </VStack>

                <VStack ml={10}>
                  <Box>
                    <Image rounded={'xl'} width='96' height='32' source={require('../../assets/map.png')} alt='Alternate Text' />
                  </Box>
                  <HStack mt={5}>
                    <Box
                      justifyContent={'center'}
                      style={{ alignItems: 'center' }}
                      width={12}
                      height={12}
                      borderRadius='full'
                      borderColor={'#4EA0F7'}
                      borderWidth={'2'}>
                      <Icon size='6' name={'email'} color={'#4EA0F7'} as={MaterialIcons} />
                    </Box>
                    <VStack mb={1} pl={3}>
                      <Text color={'coolGray.300'} fontSize={'xl'}>
                        Email
                      </Text>
                      <Text width={72} color={'coolGray.500'} fontSize={'lg'}>
                        {/*{patientUser?.email}*/}
                        quanglanh2508@gmail.com
                      </Text>
                    </VStack>
                  </HStack>
                </VStack>
              </HStack>
            </VStack>
            {/*-end-*/}
            {/*start-assigned doctor*/}
            <VStack ml={4} w={{ base: '50%', md: '35%' }} backgroundColor={'white'} rounded={'lg'} space='2' h={{ base: 0, md: '240' }}>
              {/**/}
              <HStack pl={5} pt={5} style={{ alignItems: 'center' }}>
                <VStack>
                  <HStack>
                    <Image
                      rounded={'lg'}
                      source={{
                        uri: 'https://sfteledoc-storage-dd21029252107-staging.s3.ap-southeast-1.amazonaws.com/public/my-image-doctor-filename0.40549212462773987.jpg'
                      }}
                      alt='Alternate Text'
                      size='32'
                    />
                    <VStack pl={5} justifyContent={'space-around'}>
                      <Text fontWeight={'bold'} fontSize={'lg'} color={'black'} my={5}>
                        Assigned Doctor
                      </Text>
                      <Text fontWeight={'bold'} fontSize={'xl'}>
                        Dr. Samantha
                      </Text>
                      <Text color={'#4EA0F7'} fontSize={'lg'}>
                        Physical Therapy
                      </Text>
                      {/*  */}
                    </VStack>
                  </HStack>
                  {/**/}
                  <VStack>
                    <Box
                      mt={2}
                      ml={5}
                      backgroundColor={'#4EA0F7'}
                      justifyContent={'center'}
                      style={{ alignItems: 'center' }}
                      width={20}
                      height={8}
                      borderRadius='2xl'
                      borderColor={'#4EA0F7'}>
                      <Text fontWeight={'bold'} fontSize={'xl'} color={'#fff'}>
                        4.5
                      </Text>
                    </Box>
                    <HStack mt={2}>
                      <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                      <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                      <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                      <Icon size='6' name={'star'} color={'#f1c40f'} as={FontAwesome} />
                      <Icon size='6' name={'star-half-empty'} color={'#f1c40f'} as={FontAwesome} />
                    </HStack>
                  </VStack>
                </VStack>
                {/*-end-*/}
              </HStack>
            </VStack>
            {/**/}
          </HStack>
          {/*end-profile*/}
        </VStack>
      </DashboardLayoutPatient>
    </>
  );
};

export default PatientProfile;
