import React, { useCallback, useEffect } from 'react';
import { Button, Hidden, Text, View, VStack } from 'native-base';
import DashboardLayout from '../../layouts/DashboardLayout';
import { Enums, imageLoader, metaData, RenderingEngine, Types } from '@cornerstonejs/core';
import * as cornerstoneTools from '@cornerstonejs/tools';
import { convertMultiframeImageIds, prefetchMetadataInformation } from '../../utils/demo/convertMultiframeImageIds';
import * as DocumentPicker from 'expo-document-picker';
// @ts-ignore
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';

const {
  PanTool,
  WindowLevelTool,
  StackScrollMouseWheelTool,
  ZoomTool,
  ToolGroupManager,
  Enums: csToolsEnums,
  addTool,
  removeTool
} = cornerstoneTools;
const { MouseBindings } = csToolsEnums;

const { ViewportType } = Enums;

// const volumeName = 'CT_VOLUME_ID';
const toolGroupId = 'CT_TOOLGROUP';
// const volumeLoaderScheme = 'cornerstoneStreamingImageVolume';
// const volumeId = `${volumeLoaderScheme}:${volumeName}`;
// const segmentationId = 'MY_SEGMENTATION_ID';

const DicomViewer = () => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  const elementRef = React.useRef<HTMLDivElement>(null);
  const [viewport, setViewport] = React.useState<Types.IStackViewport | null>(null);
  const [imageId, setImageId] = React.useState<string>('');
  // const [toolGroup, setToolGroup] = React.useState<cornerstoneTools.Types.IToolGroup | undefined>();
  useEffect(() => {
    (async () => {
      if (elementRef.current) {
        if (ToolGroupManager.getAllToolGroups().length > 0) {
          ToolGroupManager.destroyToolGroup(toolGroupId);
          removeTool(PanTool);
          removeTool(WindowLevelTool);
          removeTool(StackScrollMouseWheelTool);
          removeTool(ZoomTool);
        }
        // addTool(SegmentationDisplayTool);
        // addTool(BrushTool);

        // Define tool groups to add the segmentation display tool to
        // setToolGroup(ToolGroupManager.createToolGroup(toolGroupId));

        // Get Cornerstone imageIds and fetch metadata into RAM
        elementRef.current.oncontextmenu = (e) => e.preventDefault();

        addTool(PanTool);
        addTool(WindowLevelTool);
        addTool(StackScrollMouseWheelTool);
        addTool(ZoomTool);

        // Define a tool group, which defines how mouse events map to tool commands for
        // Any viewport using the group
        const toolGroup = ToolGroupManager.createToolGroup(toolGroupId);

        // Add tools to the tool group
        toolGroup?.addTool(WindowLevelTool.toolName);
        toolGroup?.addTool(PanTool.toolName);
        toolGroup?.addTool(ZoomTool.toolName);
        toolGroup?.addTool(StackScrollMouseWheelTool.toolName);

        // Set the initial state of the tools, here all tools are active and bound to
        // Different mouse inputs
        toolGroup?.setToolActive(WindowLevelTool.toolName, {
          bindings: [
            {
              mouseButton: MouseBindings.Primary // Left Click
            }
          ]
        });
        toolGroup?.setToolActive(PanTool.toolName, {
          bindings: [
            {
              mouseButton: MouseBindings.Auxiliary // Middle Click
            }
          ]
        });
        toolGroup?.setToolActive(ZoomTool.toolName, {
          bindings: [
            {
              mouseButton: MouseBindings.Secondary // Right Click
            }
          ]
        });
        // As the Stack Scroll mouse wheel is a tool using the `mouseWheelCallback`
        // hook instead of mouse buttons, it does not need to assign any mouse button.
        toolGroup?.setToolActive(StackScrollMouseWheelTool.toolName);

        // Get Cornerstone imageIds and fetch metadata into RAM

        // Instantiate a rendering engine
        const renderingEngineId = 'myRenderingEngine';
        const renderingEngine = new RenderingEngine(renderingEngineId);

        // Create a stack viewport
        const viewportId = 'CT_STACK';

        const viewportInput = {
          viewportId,
          type: ViewportType.STACK,
          element: elementRef.current,
          defaultOptions: {
            background: [0.2, 0, 0.2] as Types.Point3
          }
        };

        renderingEngine.enableElement(viewportInput);

        // Get the stack viewport that was created
        setViewport(renderingEngine.getViewport(viewportId) as Types.IStackViewport);

        toolGroup?.addViewport(viewportId, renderingEngineId);
      }
    })();
  }, [elementRef]);

  useEffect(() => {
    (async () => {
      if (!viewport || !imageId) {
        return;
      }
      // if (!imageId || !toolGroup) {
      //   return;
      // }
      // Get Cornerstone imageIds and fetch metadata into RAM
      // const imageIds = await createImageIdsAndCacheMetaData({
      //   StudyInstanceUID: '1.3.6.1.4.1.14519.5.2.1.7009.2403.334240657131972136850343327463',
      //   SeriesInstanceUID: '1.3.6.1.4.1.14519.5.2.1.7009.2403.226151125820845824875394858561',
      //   wadoRsRoot: 'https://d3t6nz73ql33tx.cloudfront.net/dicomweb'
      // });

      // Segmentation Tools
      // toolGroup.addTool(SegmentationDisplayTool.toolName);
      // toolGroup.addTool(BrushTool.toolName);
      // toolGroup.setToolEnabled(SegmentationDisplayTool.toolName);
      //
      // toolGroup.setToolActive(BrushTool.toolName, {
      //   bindings: [{ mouseButton: csToolsEnums.MouseBindings.Primary }]
      // });
      //
      // // Define a volume in memory for CT
      // const volume = await volumeLoader.createAndCacheVolume(volumeId, {
      //   imageIds: [imageId]
      // });
      //
      // // Create a segmentation of the same resolution as the source data for the CT volume
      // await volumeLoader.createAndCacheDerivedVolume(volumeId, {
      //   volumeId: segmentationId
      // });
      //
      // segmentation.addSegmentations([
      //   {
      //     segmentationId,
      //     representation: {
      //       // The type of segmentation
      //       type: csToolsEnums.SegmentationRepresentations.Labelmap,
      //       // The actual segmentation data, in the case of labelmap this is a
      //       // reference to the source volume of the segmentation.
      //       data: {
      //         volumeId: segmentationId
      //       }
      //     }
      //   }
      // ]);
      //
      // // Instantiate a rendering engine
      // const renderingEngineId = 'myRenderingEngine';
      // const renderingEngine = new RenderingEngine(renderingEngineId);
      //
      // // Create the viewports
      // const viewportId1 = 'CT_AXIAL';
      //
      // const viewportInputArray = [
      //   {
      //     viewportId: viewportId1,
      //     type: ViewportType.ORTHOGRAPHIC,
      //     element: elementRef.current as HTMLDivElement,
      //     defaultOptions: {
      //       orientation: Enums.OrientationAxis.AXIAL
      //     }
      //   }
      // ];
      //
      // renderingEngine.setViewports(viewportInputArray);
      //
      // toolGroup.addViewport(viewportId1, renderingEngineId);
      //
      // // Set the volume to load
      // volume.load();
      //
      // // Set volumes on the viewports
      // await setVolumesForViewports(
      //   renderingEngine,
      //   [
      //     {
      //       volumeId
      //     }
      //   ],
      //   [viewportId1]
      // );
      //
      // // // Add the segmentation representation to the toolGroup
      // await segmentation.addSegmentationRepresentations(toolGroupId, [
      //   {
      //     segmentationId,
      //     type: csToolsEnums.SegmentationRepresentations.Labelmap
      //   }
      // ]);
      //
      // // Render the image
      // renderingEngine.renderViewports([viewportId1]);

      await prefetchMetadataInformation([imageId]);
      const stack = convertMultiframeImageIds([imageId]);
      // Set the stack on the viewport
      viewport
        .setStack(stack)
        .then(() => {
          viewport.render();
        })
        .catch((error) => {
          console.log('error', error);
        });
    })();
  }, [viewport, imageId]);

  const fetchFileUri = async (uri: string) => {
    const respone = await fetch(uri);
    return await respone.blob();
  };

  const openFilePickerAsync = useCallback(async () => {
    const pickerResult = await DocumentPicker.getDocumentAsync({
      type: '.dcm, .dicom',
      copyToCacheDirectory: true,
      multiple: false
    });
    if (pickerResult.type !== 'success') {
      return;
    }
    const blob = await fetchFileUri(pickerResult.uri);
    const file = new File([blob], 'test.dcm', { type: blob.type, lastModified: new Date().getTime() });
    const id = cornerstoneDICOMImageLoader.wadouri.fileManager.add(file);

    imageLoader
      .loadAndCacheImage(id)
      .then(function (image) {
        // Read the DICOM header
        const data = metaData.get('sopCommonModule', image.imageId);
      })
      .catch(function (error) {
        console.error(error);
      });

    // const res = await Storage.put(`my-dicom-filename${generate_uuid()}.dcm`, blob, {
    //   level: 'public',
    //   contentType: 'application/dicom',
    //   progressCallback(uploadProgress) {
    //     console.log('PROGRESS--', uploadProgress.loaded + '/' + uploadProgress.total);
    //   }
    // });
    // const result = await Storage.get(res.key);
    // const awsImageUri = result.substring(0, result.indexOf('?'));
    // console.log('awsImageUri', awsImageUri);
    // await fetch(awsImageUri)
    //   .then((response) => response.arrayBuffer())
    //   .then((buffer) => {
    //     // const file = new File([buffer], 'test.dcm', { type: 'application/dicom' });
    //     // const id = cornerstoneDICOMImageLoader.wadouri.fileManager.add(file);
    //     console.log('id', id);
    //     setImageId(id);
    //   });
    setImageId(id);
  }, []);

  // const cacheMetaData = async (series: any) => {
  //   const wadoRsRoot = `${window.location.origin}/dicom-web`;
  //   const client = new DICOMwebClient.api.DICOMwebClient({ url: wadoRsRoot });
  //   const studyInstanceUID = series['0020000D']['Value'][0];
  //   const seriesInstanceUID = series['0020000E']['Value'][0];
  //   const instances = await client.retrieveSeriesMetadata({
  //     studyInstanceUID: studyInstanceUID,
  //     seriesInstanceUID: seriesInstanceUID
  //   });
  //   return instances.map((instanceMetaData) => {
  //     // @ts-ignore
  //     const SeriesInstanceUID = instanceMetaData['0020000E'].Value[0];
  //     // @ts-ignore
  //     const SOPInstanceUID = instanceMetaData['00080018'].Value[0];
  //     const prefix = 'wadors:';
  //     const imageId = `${prefix}${wadoRsRoot}/studies/${studyInstanceUID}/series/${SeriesInstanceUID}/instances/${SOPInstanceUID}/frames/1`;
  //     cornerstoneWADOImageLoader.wadors.metaDataManager.add(imageId, instanceMetaData);
  //     return imageId;
  //   });
  // };

  return (
    <DashboardLayout title='' displayMenuButton displayScreenTitle={false} displayAlternateMobileHeader rightPanelMobileHeader={true}>
      <Hidden till={'md'}>
        <VStack p={6} space={4} alignItems={'center'}>
          <Button onPress={openFilePickerAsync} backgroundColor={'#4EA0F7'} _hover={{ backgroundColor: '#0564C8' }} rounded={'lg'} px={6}>
            <Text fontSize={16} fontWeight={'bold'} color={'white'}>
              Choose a DICOM file
            </Text>
          </Button>
          <View ref={contentRef}>
            <View ref={elementRef} style={{ width: '500px', height: '500px' }}></View>
          </View>
        </VStack>
      </Hidden>
    </DashboardLayout>
  );
};
export default DicomViewer;
