import React, { useState } from 'react';
import { Box, Divider, Hidden, HStack, Icon, Pressable, ScrollView, Text, VStack } from 'native-base';
import DashboardLayoutPatient from '../../layouts/DashboardLayoutPatient';
import RecentDoctors from '../../components/RecentDoctors';
import RecommendedDoctors from '../../components/RecommendedDoctors';
import CategoriesPatient, { Category } from '../../components/CategoriesPatient';
import Wrapper from '../../components/Wrapper';
import { RootStackScreensEnum } from '../../navigation/enum';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { MainStackParamList } from '../../types/Stacks';
import SearchBarPatient from './SearchBarPatient';

const categories: Category[] = [
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'COVID-19'
  },
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'General Practitioner'
  },
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'Subspecialty Doctors'
  },
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'Internal Medicine'
  },
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'Children Specialist'
  },
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'Skin Specialist'
  },
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'Skin Specialist'
  },
  {
    imageUri: require('../../assets/images/covid.png'),
    text: 'Skin Specialist'
  }
];

const ChatWithDoctor = () => {
  const navigation = useNavigation<NativeStackNavigationProp<MainStackParamList, RootStackScreensEnum.Patient>>();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [textInput, setTextInput] = useState('');

  const handleSearchTextChange = (text: string) => {
    setTextInput(text);
  };

  const handleToggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    setTextInput('');
  };
  return (
    <>
      <Hidden till={'md'}>
        <DashboardLayoutPatient displayScreenTitle={true} title='Chat With Doctor' rightPanelMobileHeader={true}>
          <VStack backgroundColor={'#fff'} px={{ base: 4, md: 0, lg: 8 }}>
            {/*<Wrapper>*/}
            {/*  <SearchInput placeholder='Example: Dr.Lia or Gynecologist' />*/}
            {/*</Wrapper>*/}
            <Wrapper>
              <RecentDoctors />
            </Wrapper>
            <Wrapper>
              <RecommendedDoctors />
            </Wrapper>
            <Wrapper title='Find a Specialist' subTitle='Choose from our wide-range of medical specializations'>
              <CategoriesPatient categories={categories} numColumns={3} />
            </Wrapper>
          </VStack>
        </DashboardLayoutPatient>
      </Hidden>
      <Hidden from={'md'}>
        <>
          <HStack alignItems={'center'} backgroundColor={'#fff'} justifyContent={'space-between'} height={'64px'} w={'100%'}>
            {!isSearchOpen && (
              <HStack alignItems={'center'} w={'100%'}>
                <Pressable
                  mt={1}
                  ml={'4'}
                  onPress={() => navigation.navigate(RootStackScreensEnum.Patient, { screen: RootStackScreensEnum.DashboardPatient })}
                  mr={1}>
                  <Icon as={Ionicons} name='arrow-back' size='xl' color={'coolGray.700'} />
                </Pressable>
                <Text w={'95%'} fontSize='20' fontWeight='bold' _light={{ color: 'coolGray.800' }} _dark={{ color: 'coolGray.50' }}>
                  Chat with Doctor
                </Text>
                <Pressable
                  onPress={handleToggleSearch}
                  w={'5%'}
                  mr={4}
                  p={4}
                  alignItems='center'
                  justifyContent='center'
                  size={'10'}
                  rounded='full'
                  _hover={{ bg: '#E4EBE4' }}>
                  <Icon as={<MaterialIcons name='search' />} size={8} color='coolGray.900' />
                </Pressable>
              </HStack>
            )}
            <Box w={'100%'} px={2}>
              {isSearchOpen && (
                <SearchBarPatient textInput={textInput} handleSearchTextChange={handleSearchTextChange} handleToggleSearch={handleToggleSearch} />
              )}
            </Box>
          </HStack>
          <Divider />
          <ScrollView showsVerticalScrollIndicator={false} backgroundColor={'#fff'} px={{ base: 4, md: 0, lg: 8 }}>
            <Box mt={4} />
            <Wrapper>
              <RecentDoctors />
            </Wrapper>
            <Wrapper>
              <RecommendedDoctors />
            </Wrapper>
            <Wrapper title='Find a Specialist' subTitle='Choose from our wide-range of medical specializations'>
              <CategoriesPatient categories={categories} numColumns={3} />
            </Wrapper>
          </ScrollView>
        </>
      </Hidden>
    </>
  );
};

export default ChatWithDoctor;
