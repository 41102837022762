import React from 'react';
import { Box, Button, FlatList, Image, Pressable, Text, VStack } from 'native-base';
import { ImageSourcePropType } from 'react-native';

export type Medicine = {
  id: number;
  name: string;
  quantity: string;
  imageUri: ImageSourcePropType;
  cost?: string;
};

const doctors: Medicine[] = [
  {
    id: 1,
    name: 'Lanavision 12 Kapsul Lanavision 12 Kapsul',
    imageUri: require('../assets/images/medicine.png'),
    quantity: 'Per Box',
    cost: 'Rp37.200 - Rp151.200'
  },
  {
    id: 2,
    name: 'Lanavision 12 Kapsul Lanavision 12 Kapsul',
    imageUri: require('../assets/images/medicine.png'),
    quantity: 'Per 5 Strip',
    cost: 'Rp83.500 - Rp90.000'
  },
  {
    id: 3,
    name: 'Lanavision 12 Kapsul',
    imageUri: require('../assets/images/medicine.png'),
    quantity: 'Per 2 Strip',
    cost: 'Rp100.000 - Rp200.000'
  }
];

const WorldDiabeticDay = () => {
  return (
    <Box mb='8'>
      <FlatList
        mt={4}
        data={doctors}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }: { item: Medicine }) => {
          return (
            <Pressable p={2}>
              <Card doctor={item} />
            </Pressable>
          );
        }}
        ItemSeparatorComponent={() => <Box w={{ base: '4', md: '8' }} />}
      />
    </Box>
  );
};

const Card = ({ doctor }: { doctor: Medicine }) => {
  return (
    <VStack h='88' w='50' justifyContent='space-between' rounded='md' position='relative' borderWidth={1} borderColor={'gray.200'} shadow={3}>
      <Box height='48'>
        <Image flex='1' source={doctor.imageUri} alt='Alternate Text' />
      </Box>
      <VStack px='3' py='3'>
        <Text fontSize='sm' numberOfLines={2} lineHeight='20' fontWeight='medium'>
          {doctor.name}
        </Text>
        <Text fontSize='xs' lineHeight='20' fontWeight='light'>
          {doctor.quantity}
        </Text>
        <Text fontSize='md' fontWeight='medium'>
          {doctor.cost}
        </Text>
      </VStack>
      <Button
        borderColor='red.700'
        borderWidth={1}
        rounded='md'
        variant='ghost'
        py='2'
        mx='3'
        mb='3'
        _text={{
          color: 'red.700'
        }}
        _hover={{
          _text: {
            color: 'white'
          },
          bg: 'red.700'
        }}>
        Add
      </Button>
    </VStack>
  );
};

export default WorldDiabeticDay;
